import * as React from "react";
import { Link } from "react-router-dom";

import { Table } from "../../../../../../components/Functional";

import { UPDATE_CONSIGNMENT_WORK as updateConsignmentWorkMutation } from "../../../../../../graphql/mutation/ConsignmentWork";
import { FETCH_CONSIGNMENT_WORKS as fetchConsignmentWorks } from "../../../../../../graphql/query/ConsignmentWork";
import { FETCH_COMPANIES as fetchCompanies } from "../../../../../../graphql/query/Company";

import SectionHeader from "../../components/SectionHeader";
import AddWork from "./components/AddWork";
import Actions from "./components/Actions";

import Status from "./Status";
import { useQuery } from "@apollo/client";
import useUser from "../../../../../../hooks/useUser";

const getEdition = props => {
  const { instance } = props?.values || {};
  const { edition, editionNumber } = instance || {};

  if (editionNumber && edition && edition?.number > 1) {
    return `${editionNumber} / ${edition?.number}`;
  }
};

const RefPrevRef = ({ values }) => {
  return (
    <div className="flex flex-col">
      <div
        title={values?.instance?.name}
        className={`overflow-hidden text-ellipsis text-base font-medium leading-5 text-black`}
        style={{ maxWidth: `200px` }}
      >
        <Link
          className="underline"
          to={`/instance/${values?.instance?.id}/details`}
        >
          {values?.instance?.name}
        </Link>
      </div>
      <div
        className="text-[15px] italic tracking-wide text-slate-500"
        style={{ maxWidth: `200px` }}
      >
        {values?.instance?.name}
      </div>
    </div>
  );
};

const Artworks = props => {
  const { hasPermission } = useUser();
  const { consignment } = props || {};

  const { data: { companies: { edges: companies = [] } = {} } = {} } = useQuery(
    fetchCompanies(),
    { variables: { input: { contactId: consignment?.contactId } } },
  );

  let label10Caption = "";
  let label11Caption = "Consignee Split";
  const checkVisibility = header => {
    let hiddenHeaders = [];
    if (companies?.[0]?.id && consignment?.consigner?.typeId == 3) {
      label11Caption = "Consignee Split";
      hiddenHeaders.push("conPrice");
    } else {
      label11Caption = "Discount";
      hiddenHeaders.push("conPrice");
    }

    if (consignment?.typeId == 2) {
      label10Caption = "Insurance Value";
      hiddenHeaders = hiddenHeaders.concat([
        "discount",
        "discountRate",
        "fabCost",
        "fabRate",
        "conPrice",
        "share",
        "grossProfit",
      ]);
    } else if (consignment?.typeId == 3) {
      label10Caption = "Purchase Price";
      hiddenHeaders = hiddenHeaders.concat(["conPrice", "grossProfit"]);
    } else {
      label10Caption = "Retail Price";
      hiddenHeaders = hiddenHeaders.concat([]);
    }

    return !hiddenHeaders.includes(header?.name) ? header : {};
  };

  const tableProps = {
    className: "sm:px-0 lg:px-0",
    dataKey: "consignmentWorks",
    FETCH_QUERY: fetchConsignmentWorks({
      instance: {
        description: {},
        edition: { artwork: { artists: {}, images: { imgT: {} } } },
        status: {},
      },
      user: {},
    }),
    headers: [
      { name: "instance.edition.artwork.images[0].imgT", type: "image" },
      { label: ["Ref", "Prevous Ref"], type: "custom", component: RefPrevRef },
      {
        label: "Status",
        name: "instance.status.name",
        type: "custom",
        component: Status,
      },
      {
        label: ["Artwork Title", "Artist Name"],
        name: [
          "instance.edition.artwork.title",
          "instance.edition.artwork.artists[0].name",
        ],
        type: "multi-label",
      },
      {
        label: ["Edition", "Year"],
        name: [getEdition, "instance.edition.year"],
        type: "multi-label",
      },
      checkVisibility({
        inputType: "number-currency",
        label: label11Caption,
        name: "discount",
        type: "input",
        emptyToZero: true,
        disabled: !hasPermission("ADD_CONSIGNMENT_ITEM"),
      }),
      checkVisibility({
        inputType: "number",
        label: "%",
        name: "discountRate",
        type: "input",
        disabled: !hasPermission("ADD_CONSIGNMENT_ITEM"),
      }),
      checkVisibility({
        inputType: "number-currency",
        label: "Fabrication",
        name: "fabCost",
        type: "input",
        emptyToZero: true,
        disabled: !hasPermission("ADD_CONSIGNMENT_ITEM"),
      }),
      checkVisibility({
        inputType: "number",
        label: "%",
        name: "fabRate",
        type: "input",
        disabled: !hasPermission("ADD_CONSIGNMENT_ITEM"),
      }),
      {
        inputType: "number-currency",
        label: label10Caption,
        name: "price",
        type: "input",
        disabled: !hasPermission("ADD_CONSIGNMENT_ITEM"),
      },
      ...(consignment.isSecondary
        ? [
            {
              inputType: "number-currency",
              label: "Net to Client",
              name: "conPrice",
              type: "input",
              disabled: !hasPermission("ADD_CONSIGNMENT_ITEM"),
            },
          ]
        : []),
      checkVisibility({
        designType: "checkbox",
        inputType: "toggle",
        label: "Share",
        name: "share",
        onChange: e => console.log("e", e),
        type: "input",
        disabled: !hasPermission("ADD_CONSIGNMENT_ITEM"),
      }),
      checkVisibility({
        label: "Gross Profit",
        name: "grossProfit",
        type: "currency",
        disabled: !hasPermission("ADD_CONSIGNMENT_ITEM"),
      }),
      { label: "User", name: "user.name", type: "label" },
      { label: "", name: "action", type: "custom", component: Actions },
    ],
    UPDATE_MUTATION: updateConsignmentWorkMutation({
      instance: {
        edition: { artwork: { artists: {}, images: {} } },
        status: {},
      },
      user: {},
    }),
    skip: () => !consignment?.id,
    variables: {
      consignmentId: consignment?.id,
    },
    variant: "dynamicHeight",
  };

  const actions = [
    {
      component: () => <AddWork consignment={consignment} />,
    },
  ];

  return (
    <div className="w-full">
      <SectionHeader title="Artworks" actions={actions} />
      <div className="mb-6 w-full">
        <Table {...tableProps} />
      </div>
    </div>
  );
};

export default Artworks;
