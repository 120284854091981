import { Fragment } from "react";

import Actions from "./components/Actions";
import Header from "./components/HeaderComponent";
import { Table } from "../../../../components/Functional";
import { FETCH_LISTS as fetchLists } from "../../../../graphql/query/List";

const List = () => {
  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "lists",
    headers: [
      { label: "List Name", name: "name", sortKey: "name", type: "label" },
      { label: "Records in List", name: "itemsCount", type: "label" },
      { label: "Type", name: "type.name", type: "label" },
      {
        label: "Date / Time Last Updated",
        name: "updatedAt",
        type: "date-time",
      },
      { label: "", name: "action", type: "custom", component: Actions },
    ],
    inputs: [],
    FETCH_QUERY: fetchLists({ type: {} }),
  };

  return (
    <Fragment>
      <div className="px-8">
        <Header leftText="Select Lists" />
      </div>
      <div className="mt-6 w-full">
        <Table {...tableProps} />
      </div>
    </Fragment>
  );
};

export default List;
