import { gql } from "@apollo/client";
import { InstanceAuction } from "../fragments";

const FETCH_INSTANCE_AUCTIONS = gql`
  query FetchInstanceAuctions($input: InstanceAuctionFilterInput!) {
    instanceAuctions(input: $input) {
      count
      edges {
        ${InstanceAuction}
      }
    }
  }
`;

const FETCH_INSTANCE_AUCTION = gql`
  query FetchInstanceAuction($id: Int) {
    instanceAuction(id: $id) {
      ${InstanceAuction}
    }
  }
`;

export { FETCH_INSTANCE_AUCTION, FETCH_INSTANCE_AUCTIONS };
