const InstanceDocument = `
  id
  fileName
  fileType
  instanceId
  updatedAt
  url
  viewUrl
`;

export default InstanceDocument;
