import { GridInputs } from "../../../../../components/Functional";

const Auctions = props => {
  const { formik } = props || {};

  const inputs = {
    className: "grid grid-cols-4 gap-16 mt-5",
    inputs: [
      {
        className: "flex flex-col gap-4",
        inputs: [
          {
            label: "Auctioneer",
            name: "_instanceAuction.auctioneer",
            type: "text",
          },
        ],
      },
    ],
  };

  const gridInputProps = {
    formik,
    ...inputs,
  };

  return <GridInputs {...gridInputProps} />;
};

export default Auctions;
