import { gql } from "@apollo/client";

const CREATE_TICKET = gql`
  mutation CreateTicket($input: SupportInput!) {
    createTicket(input: $input) {
      id
      number
      name
      description
      state
      priority
      category {
        name
      }
    }
  }
`;

export { CREATE_TICKET };
