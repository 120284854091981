import { getInput } from "../../../../../components/ui/Input";
import { useQuery } from "@apollo/client";
import { FETCH_STATUS } from "../../../../../graphql/query/Status";
import { FETCH_COMPANIES as fetchCompanies } from "../../../../../graphql/query/Company";
import { Button } from "../../../../../components/ui/Button";

const Search = props => {
  const { searchFormik: formik, onSubmit } = props;
  const { data: { status = [] } = {} } = useQuery(FETCH_STATUS);
  const { data: { companies: { edges = [] } = {} } = {} } =
    useQuery(fetchCompanies());

  const inputsProps = [
    { label: "Instance Numbers", name: "search", type: "text" },
    { label: "Artwork Title", name: "title", type: "text" },
    { label: "Artist Name", name: "artistName", type: "text" },
    { label: "Year", name: "year", type: "number" },
    {
      label: "Status",
      name: "statusId",
      type: "multi-select",
      isMulti: true,
      options: [
        { label: "All", value: null },
        ...status.map(item => ({ label: item?.name, value: item?.id })),
      ],
    },
    { label: "Medium", name: "medium", type: "text" },
    {
      label: "Company",
      name: "companyId",
      type: "select",
      options: [
        { label: "All", value: null },
        ...edges.map(item => ({ label: item?.name, value: item?.id })),
      ],
    },
  ];

  return (
    <div className="grid grid-cols-1 gap-4 py-8">
      {inputsProps?.map((item, index) => {
        const inputProps = {
          formik,
          ...item,
        };
        return <div key={index}>{getInput(inputProps)}</div>;
      })}
      <div className="flex justify-end">
        <Button
          label="Update Search"
          action="black"
          onClick={() => onSubmit(formik?.values)}
        />
      </div>
    </div>
  );
};

export default Search;
