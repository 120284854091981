import Event from "./Event";
import EventRsvp from "./EventRsvp";

const EventGuest = ({ event = false, rsvp = false } = {}) => `
  id
  added
  attended
  chaser
  confirmation
  contactId
  contacted
  diet
  email
  emailPref
  ${
    event
      ? `event {
      ${Event}
    }`
      : ""
  }
  eventId
  guestName
  guestNotes
  guests
  invitation
  invitedBy
  notes
  priority
  ${
    rsvp
      ? `rsvp {
      ${EventRsvp}
    }`
      : ""
  }
  seatingNotes
  tableNumber
  updatedAt
`;

export default EventGuest;
