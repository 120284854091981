import React from "react";
import { Link, useParams } from "react-router-dom";

import SectionHeader from "../../components/SectionHeader";
import { Table } from "../../../../../../components/Functional";
import { FETCH_ITEMS_V2 as fetchItemsV2 } from "../../../../../../graphql/query/Item";

const InstanceSales = props => {
  const { id: instanceId } = useParams();

  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "itemsV2",
    headers: [
      {
        label: "Client",
        name: "invoice.contact.name",
        type: "custom",
        component: props => {
          const value = props.label || "";

          return (
            <Link
              className="underline"
              to={`/contacts/${props?.values?.invoice?.contact?.id}/details`}
            >
              {value}
            </Link>
          );
        },
      },
      {
        label: "Invoice",
        name: "invoice.reference",
        type: "custom",
        component: props => {
          const value = props.label || "";

          return (
            <Link
              className="underline"
              to={`/invoice/${props?.values?.invoice?.id}/details`}
            >
              {value}
            </Link>
          );
        },
      },
      {
        label: "Amount",
        type: "custom",
        component: props => (
          <span className="flex gap-1">
            <span className="overflow-hidden text-ellipsis text-base font-medium leading-5 text-black">
              {`${(
                props?.values?.amount - props?.values?.discount
              )?.toLocaleString("en-US")} ${
                props?.values?.invoice?.currency?.code
              }`}
            </span>
          </span>
        ),
      },
      { label: "Date", name: "date", type: "date" },
      { label: "Due", name: "invoice.dueDate", type: "date" },
      { label: "Certificate", name: "instance.certificate", type: "label" },
    ],
    inputs: [],
    FETCH_QUERY: fetchItemsV2({
      instance: {},
      invoice: { contact: {}, currency: {} },
    }),
    variables: {
      instanceId: {
        value: instanceId,
        operator: "eq",
      },
    },
    className: "sm:px-0 lg:px-0",
  };

  return (
    <div className="w-full">
      <SectionHeader title="Sales" />
      <div className="mb-6 w-full">
        <Table {...tableProps} />
      </div>
    </div>
  );
};

export default InstanceSales;
