import { useFormik } from "formik";
import * as Yup from "yup";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { Button, IconButton, Modal } from "../ui";
import { GridInputs } from "../Functional";

const DimensionsModal = ({
  // eslint-disable-next-line
  formik: modelFormik,
  // eslint-disable-next-line
  fieldName,
  // eslint-disable-next-line
  submitOnBlur = false,
}) => {
  const formik = useFormik({
    initialValues: {
      unit: "cm",
    },
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: Yup.object({
      height: Yup.number()
        .positive("Please enter positive number")
        .typeError("Invalid input"),
      width: Yup.number()
        .positive("Please enter positive number")
        .typeError("Invalid input"),
      depth: Yup.number()
        .positive("Please enter positive number")
        .typeError("Invalid input"),
    }),
  });

  const { height, width, depth } = formik.values;

  const inputs = {
    className: "gap-8 mt-5",
    inputs: [
      {
        className: "grid grid-cols-3 gap-16",
        inputs: [
          {
            label: "",
            name: "unit",
            type: "radio",
            variant: "horizontal",
            options: [
              { label: "Centimeters", value: "cm" },
              { label: "Inches", value: "in" },
            ],
          },
        ],
      },
      {
        className: "grid grid-cols-3 gap-16",
        inputs: [
          { label: "Height", name: "height", type: "number-currency" },
          { label: "Width", name: "width", type: "number-currency" },
          { label: "Depth", name: "depth", type: "number-currency" },
        ],
      },
    ],
  };

  const inputProps = {
    formik,
    ...inputs,
  };

  const unitConversion = (value, unit) => {
    if (unit == "in") {
      return value ? (value / 2.54).toFixed(2) : "";
    } else if (unit == "cm") {
      return value ? (value * 2.54).toFixed(2) : "";
    }
  };

  const calculateDimension = closeModal => e => {
    e.preventDefault();
    const { height, width, depth } = formik.values;
    let dimension = "";

    if ((height && width) || (height && depth) || (width && depth)) {
      if (formik.values.unit == "in") {
        dimension = height ? `${height}` : "";
        dimension += width ? (height ? ` x ${width}` : `${width}`) : "";
        dimension += depth ? ` x ${depth}` : "";
        dimension += " in. | (";
        dimension += height ? `${unitConversion(height, "cm")}` : "";
        dimension += width
          ? height
            ? ` x ${unitConversion(width, "cm")}`
            : `${unitConversion(width, "cm")}`
          : "";
        dimension += depth ? ` x ${unitConversion(depth, "cm")}` : "";
        dimension += " cm)";
      } else if (formik.values.unit == "cm") {
        dimension = height ? `${unitConversion(height, "in")}` : "";
        dimension += width
          ? height
            ? ` x ${unitConversion(width, "in")}`
            : `${unitConversion(width, "in")}`
          : "";
        dimension += depth ? ` x ${unitConversion(depth, "in")}` : "";
        dimension += " in. | (";
        dimension += height ? `${height}` : "";
        dimension += width ? (height ? ` x ${width}` : `${width}`) : "";
        dimension += depth ? ` x ${depth}` : "";
        dimension += " cm)";
      }

      if (dimension) {
        // eslint-disable-next-line
        modelFormik.setFieldValue(fieldName, dimension);
        // eslint-disable-next-line
        submitOnBlur && modelFormik.submitForm();
        formik.resetForm();
        closeModal();
      }
    } else {
      console.log("Atleast two fields are required");
    }
  };

  const modalProps = {
    scale: "md",
    hideCloseButton: true,
    closeOnBackdrop: false,
    body: ({ closeModal }) => {
      return (
        <div className="flex w-full flex-col p-6">
          <div className="flex flex-row">
            <div className="flex flex-1 flex-col">
              <div className="text-xl font-bold">Add Dimension</div>
            </div>
            <div className="flex flex-col">
              <div>
                <Button
                  action="default"
                  className="mr-4"
                  label="Cancel"
                  onClick={() => {
                    closeModal();
                  }}
                />
                <Button
                  label="Add"
                  disabled={
                    !formik?.isValid ||
                    !(
                      (height && width) ||
                      (height && depth) ||
                      (width && depth)
                    )
                  }
                  onClick={calculateDimension(closeModal)}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-8">
            <GridInputs {...inputProps} />
          </div>
        </div>
      );
    },
  };

  return (
    <Modal {...modalProps}>
      <IconButton variant="clean" title="Edit Dimension">
        <PencilSquareIcon className={`h-5 w-5 cursor-pointer text-gray-500`} />
      </IconButton>
    </Modal>
  );
};

export default DimensionsModal;
