import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import { useEffect } from "react";
import * as Yup from "yup";
import { Button } from "../../../../../../../components/ui";
import { GridInputs } from "../../../../../../../components/Functional";
import { CREATE_PAYMENT_PLAN as createPaymentPlanMutation } from "../../../../../../../graphql/mutation/PaymentPlan";
import useUser from "../../../../../../../hooks/useUser";

const AddFabrication = props => {
  const { closeModal, values, isOpen, invoiceId, invoice } = props;

  const { user } = useUser();

  const [createPaymentPlan, { loading: loadingCreate }] = useMutation(
    createPaymentPlanMutation(),
    {
      refetchQueries: ["FetchPaymentPlans"],
    },
  );

  const formik = useFormik({
    initialValues: {
      ...values,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      amount: Yup.number()
        .required("Amount is required")
        .typeError("Invalid input"),
      description: Yup.string().required("Description is required"),
      dueDate: Yup.date()
        .required("Due Date is required")
        .max(
          invoice?.dueDate,
          `Cannot set a date beyond payment due date (${new Date(invoice?.dueDate).toLocaleDateString("en-GB")})`,
        ),
    }),
    onSubmit: values => {
      createPaymentPlan({
        variables: {
          input: {
            invoiceId: +invoiceId,
            amount: values.amount,
            cancel: values.cancel,
            description: values.description,
            dueDate: values.dueDate,
            createdById: user?.entityId,
          },
        },
      }).then(() => {
        closeModal();
      });
    },
  });
  const inputs = {
    className: "grid grid-cols-2 gap-12",
    inputs: [
      {
        className: "grid grid-cols-1 gap-4",
        inputs: [
          { label: "Due Date", name: "dueDate", type: "date" },
          { label: "Amount", name: "amount", type: "number-currency" },
          {
            label: "Cancel",
            name: "cancel",
            type: "checkbox",
            singleCheckbox: true,
            options: [{ label: "Cancel", value: -1 }],
          },
        ],
      },
      {
        className: "grid grid-cols-1 gap-4",
        inputs: [
          {
            label: "Description",
            name: "description",
            rows: 4,
            type: "textarea",
          },
        ],
      },
    ],
  };

  const inputProps = {
    ...inputs,
    formik,
  };

  const successLabel = values?.id ? "Edit" : "Add";

  useEffect(() => {
    formik.handleReset();
  }, [isOpen]);

  return (
    <div className="flex w-full flex-col p-8">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          {values?.id ? "Edit" : "Add"} Payment Plan Record
        </div>
        <div className="flex flex-col">
          <div>
            <Button
              action="default"
              className="mr-8"
              label="Cancel"
              onClick={closeModal}
            />
            <Button
              label={`${successLabel}${
                loadingCreate ? "ing" : ""
              } Payment Plan`}
              disabled={loadingCreate || !formik?.isValid}
              onClick={formik.submitForm}
            />
          </div>
        </div>
      </div>
      <div className="mt-12 pb-10">
        <GridInputs {...inputProps} />
      </div>
    </div>
  );
};

export default AddFabrication;
