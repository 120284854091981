import React from "react";

import { getInput } from "../../../../../../../components/ui";
import { useQuery } from "@apollo/client";
import { FETCH_COUNTRIES } from "../../../../../../../graphql/query/Country";

const Delivery = ({
  formik,
  submitOnBlur = false,
  shipmentHasConfirmedItems,
}) => {
  const { data: { countries = [] } = {} } = useQuery(FETCH_COUNTRIES);

  const inputs = [
    {
      label: "",
      name: "delContactId",
      type: "contactSearch",
      submitOnBlur,
      disabled: shipmentHasConfirmedItems,
    },
    {
      label: "Name",
      name: "delName",
      type: "text",
      submitOnBlur,
      disabled: shipmentHasConfirmedItems,
    },
    {
      label: "Company",
      name: "delCompany",
      type: "text",
      submitOnBlur,
      disabled: shipmentHasConfirmedItems,
    },
    {
      label: "Address",
      name: "delAddress",
      type: "text",
      submitOnBlur,
      disabled: shipmentHasConfirmedItems,
    },
    {
      label: "",
      name: "delAddress2",
      type: "text",
      submitOnBlur,
      disabled: shipmentHasConfirmedItems,
    },
    {
      label: "",
      name: "delAddress3",
      type: "text",
      submitOnBlur,
      disabled: shipmentHasConfirmedItems,
    },
    {
      label: "Town",
      name: "delTown",
      type: "text",
      submitOnBlur,
      disabled: shipmentHasConfirmedItems,
    },
    {
      label: "County",
      name: "delCounty",
      type: "text",
      submitOnBlur,
      disabled: shipmentHasConfirmedItems,
    },
    {
      label: "Country",
      name: "delCountryId",
      type: "multi-select",
      options: countries?.map(({ id, name }) => ({ value: id, label: name })),
      submitOnBlur,
      disabled: shipmentHasConfirmedItems,
    },
    {
      label: "Postcode",
      name: "delPostcode",
      type: "text",
      submitOnBlur,
      disabled: shipmentHasConfirmedItems,
    },
  ];

  return (
    <div className="grid-cols-1 gap-4">
      <div>
        <h3 className="mb-4 text-lg font-bold text-gray-900">Delivery</h3>
      </div>
      {inputs?.map((item, index) => {
        const inputProps = {
          formik,
          ...item,
          ...formik?.getFieldProps(item.name),
        };

        return (
          <div key={index} className="pb-6">
            {getInput(inputProps)}
          </div>
        );
      })}
    </div>
  );
};

export default Delivery;
