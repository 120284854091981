import { GridInputs } from "../../../../../../../components/Functional";

const Total = props => {
  const { formik, disableConfig } = props;

  const inputs = {
    className: "grid grid-cols-4 gap-16 my-8",
    inputs: [
      {
        className: "grid-cols-1 gap-4",
        inputs: [
          {
            label: "Net",
            name: "invoiceTotals.net",
            type: "text-currency",
            ...disableConfig,
          },
          {
            label: "Balance",
            name: "invoiceTotals.balance",
            type: "text-currency",
            ...disableConfig,
          },
        ],
      },
      {
        className: "grid-cols-1 gap-4",
        inputs: [
          {
            label: "Tax",
            name: "invoiceTotals.tax",
            type: "text-currency",
            ...disableConfig,
          },
          {
            label: "Date Updated",
            name: "updatedAt",
            type: "date",
            ...disableConfig,
          },
        ],
      },
      {
        className: "grid-cols-1 gap-4",
        inputs: [
          {
            label: "Total",
            name: "invoiceTotals.total",
            type: "text-currency",
            ...disableConfig,
          },
        ],
      },
      {
        className: "grid-cols-1 gap-4",
        inputs: [
          {
            label: "Gross Profit",
            name: "invoiceTotals.grossProfit",
            type: "text-currency",
            ...disableConfig,
          },
        ],
      },
    ],
  };

  const detailInputProps = {
    formik,
    ...inputs,
  };

  return (
    <div className="mt-4 min-w-[300px] py-5">
      <h5 className="font-bold">Totals</h5>
      <GridInputs {...detailInputProps} />
    </div>
  );
};

export default Total;
