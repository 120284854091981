import { gql } from "@apollo/client";
import County from "../fragments/County";

const FETCH_COUNTIES = gql`
  query FetchCounties {
    counties {
      ${County}
    }
  }
`;

export { FETCH_COUNTIES };
