const Country = `
  id
  continent
  digraph
  name
  sagecode
  updatedAt
`;

export default Country;
