import { useQuery } from "@apollo/client";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Button } from "../../Button";
import { getInput } from "../../Input/index";
import { GET_ISSUES_CATEGORIES } from "../../../../graphql/query/Support";

const FormLoggingSupport = props => {
  // eslint-disable-next-line
  const { onSubmit } = props;
  const { data } = useQuery(GET_ISSUES_CATEGORIES);

  const handleOnSubmit = () => {
    const formData = formik?.values;

    const selectedCategory = data?.getIssuesCategories?.find(
      category => category?.id == formData?.issueType,
    );

    let categoryName = "";
    let subCategoryName = "";

    if (selectedCategory?.parent_id) {
      subCategoryName = selectedCategory?.name;
      const parentCategory = data?.getIssuesCategories?.find(
        category => category?.id == selectedCategory.parent_id,
      );
      categoryName = parentCategory?.name;
    } else {
      categoryName = selectedCategory?.name;
    }

    onSubmit({
      ...formData,
      issueType: categories?.find(issue => issue.value === formData.issueType)
        ?.label,
      categoryName,
      subCategoryName,
    });
  };
  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: Yup.object({
      ticketTitle: Yup.string().trim().required("Please enter a ticket title."),
    }),
    onSubmit: handleOnSubmit,
  });

  const categories =
    data?.getIssuesCategories?.map(issue => ({
      label: issue.name,
      value: issue.id,
    })) || [];

  const inputs = [
    {
      type: "text",
      label: "Give this ticket a title",
      name: "ticketTitle",
      placeholder: "XYZ issue",
    },
    {
      type: "select",
      label: "What type of issue are you having?",
      name: "issueType",
      placeholder: "Select from the drop-down",
      options: categories,
    },
    {
      type: "textarea",
      label: "Please provide a detailed description",
      placeholder: "Detailed description goes here.",
      name: "description",
    },
  ];
  const renderInputs = () => {
    return inputs?.map((input, key) => {
      const inputProps = {
        ...input,
        formik,
      };
      return (
        <div className="mb-4" key={key}>
          {getInput(inputProps)}
        </div>
      );
    });
  };
  return (
    <div className="absolute bottom-4 left-4 z-[1] max-h-[450px] w-[450px] rounded border border-black bg-white px-10 py-4">
      <div className="mb-2 text-lg font-bold">Submit a Ticket</div>
      <div className="text-gray-600">
        A help desk agent will respond by email.
      </div>
      <div className="mb-4 mt-4">{renderInputs()}</div>
      <div className="mt-8 flex justify-end">
        <Button onClick={formik.submitForm} label="Submit" action="black" />
      </div>
    </div>
  );
};

export default FormLoggingSupport;
