import { useMutation, useQuery } from "@apollo/client";
import { Button, getInput, Modal } from "../ui";

import {
  FETCH_USER as fetchUser,
  FETCH_USERS as fetchUsers,
} from "../../graphql/query/User";
import { SHARE_DOCUMENT as shareDocumentMutation } from "../../graphql/mutation/Document";
import { useFormik } from "formik";
import { Fragment } from "react";

const SharePDFModal = props => {
  const { children, id } = props || {};
  const {
    data: { user },
  } = useQuery(fetchUser());
  const { data: userData } = useQuery(fetchUsers(), {
    variables: { input: {} },
  });
  const { users } = userData || {};
  const { edges = [] } = users || {};
  const [shareDocument, { loading }] = useMutation(shareDocumentMutation());

  const formik = useFormik({
    initialValues: { receivers: null },
    enableReinitialize: true,
    initialErrors: { errorSharing: false },
  });

  const inputs = [
    {
      name: "receivers",
      type: "multi-select",
      isMulti: true,
      options: [
        ...edges
          .filter(item => item.id !== user.id)
          .map(item => ({
            label: item?.meta?.name || item.email,
            value: item?.id,
          })),
      ],
    },
  ];

  const renderInputs = inputs?.map((item, index) => {
    const inputProps = {
      ...item,
      formik,
    };

    return <Fragment key={index}>{getInput(inputProps)}</Fragment>;
  });

  const onClose = () => {
    formik.resetForm();
  };

  const modalProps = {
    title: "Share PDF",
    scale: "sm",
    description: "Select users to whom you want to share this document",
    closeOnBackdrop: false,
    onClose,
    body: ({ closeModal }) => {
      const handleShare = () => {
        shareDocument({
          variables: {
            input: {
              id: +id,
              receivers: formik.values?.receivers?.map(({ value }) => value),
            },
          },
          update: (cache, { data }) => {
            const { shareDocument: { success } = {} } = data;
            if (success) {
              closeModal();
            } else {
              formik.setErrors({ errorSharing: true });
            }
          },
        });
      };
      return (
        <>
          <div className="mb-5 mt-5">{renderInputs}</div>
          <div className="mt-4 flex">
            <Button
              className="mr-3"
              label={"Cancel"}
              action={"default"}
              onClick={() => {
                closeModal?.();
              }}
            />
            <Button
              label={
                loading
                  ? "Sharing"
                  : formik?.errors?.errorSharing
                    ? "Error Sharing PDF"
                    : "Share"
              }
              disabled={loading || formik?.errors?.errorSharing}
              onClick={handleShare}
            />
          </div>
        </>
      );
    },
  };

  return <Modal {...modalProps}>{children}</Modal>;
};

export default SharePDFModal;
