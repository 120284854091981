import { useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import { getInput } from "../../../../../../../../../components/ui";
import { FETCH_ARTISTS as fetchArtists } from "../../../../../../../../../graphql/query/Artist";
import { GridInputs } from "../../../../../../../../../components/Functional";

import {
  CREATE_ARTIST_ARTWORK as createArtistArtworkMutation,
  DELETE_ARTIST_ARTWORK,
} from "../../../../../../../../../graphql/mutation/ArtistArtwork";

const ArtworkArtists = props => {
  const { artworkId, artworkArtists, disabled } = props || {};
  const { data: { artists = [] } = {} } = useQuery(fetchArtists());
  const [createArtistArtwork] = useMutation(
    createArtistArtworkMutation({ artwork: {} }),
  );
  const [deleteArtistArtwork] = useMutation(DELETE_ARTIST_ARTWORK);

  const formik = useFormik({
    initialValues: {
      artworkArtists:
        artworkArtists?.map(artist => ({
          label: artist?.name,
          value: artist?.id,
        })) || [],
    },
    enableReinitialize: true,
  });

  const handleArtists = (value, actionProps) => {
    const { action, removedValue, removedValues, option } = actionProps || {};

    switch (action) {
      case "select-option":
        createArtistArtwork({
          variables: { input: { artworkId, artistId: +option.value } },
        });
        break;
      case "remove-value":
        deleteArtistArtwork({
          variables: { input: { artworkId, artistId: +removedValue.value } },
        });
        break;
      case "clear":
        removedValues.forEach(item =>
          deleteArtistArtwork({
            variables: { input: { artworkId, id: +item?.value } },
          }),
        );
        break;
    }
  };

  const input = {
    disabled,
    formik,
    handleAction: handleArtists,
    isMulti: true,
    label: "Artist",
    name: "artworkArtists",
    options:
      artists?.map(artist => ({ label: artist.name, value: artist.id })) || [],
    type: "multi-select",
  };

  return <>{getInput(input)}</>;
};

const ArtworkDetailCol1 = ({ disabled, formik, submitOnBlur = false }) => {
  const { artists, id } = formik?.values || {};

  const inputs = {
    className: "gap-4",
    inputs: [
      {
        label: "Title",
        name: "title",
        type: "textarea",
        rows: 5,
        submitOnBlur,
        disabled,
      },
      {
        label: "Medium",
        name: "medium",
        type: "textarea",
        rows: 5,
        submitOnBlur,
        disabled,
      },
    ],
  };

  const detailInputProps = {
    formik,
    ...inputs,
  };

  return (
    <div className="grid gap-4">
      <ArtworkArtists
        artworkId={id}
        artworkArtists={artists}
        disabled={disabled}
      />
      <GridInputs {...detailInputProps} />
    </div>
  );
};

export default ArtworkDetailCol1;
