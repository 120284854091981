import { useQuery } from "@apollo/client";
import { GridInputs } from "../../../../../components/Functional";
import { FETCH_STATUS } from "../../../../../graphql/query/Status";

const Instance = props => {
  const { formik } = props || {};
  const { data: { status = [] } = {} } = useQuery(FETCH_STATUS);

  const inputs = {
    className: "grid grid-cols-4 gap-4",
    inputs: [
      {
        label: "Status",
        name: "_edition._instance.statusId",
        options: status?.map(item => ({ label: item.name, value: item.id })),
        type: "multi-select",
      },
    ],
  };

  const gridInputProps = {
    formik,
    ...inputs,
  };

  return <GridInputs {...gridInputProps} />;
};

export default Instance;
