import { getInput } from "../../../../../components/ui/Input";

const Title = props => {
  const { formik } = props;
  const inputProps = {
    name: "title",
    type: "text",
    formik: {
      ...formik,
      getFieldProps: name => {
        const formikProps = formik.getFieldProps(name);
        const formikOnChange = formikProps.onChange;
        formikProps.onChange = event => {
          event.target.value = event.target.value.trimStart();
          formikOnChange(event);
        };
        return formikProps;
      },
    },
  };
  return (
    <>
      <div className="text-lg font-bold">Assign a Title</div>
      <div className="mt-4 grid grid-cols-3 gap-10">
        <div className="col-span-2">{getInput(inputProps)}</div>
      </div>
    </>
  );
};

export default Title;
