const statusColor = value => {
  switch (value) {
    case "Good":
      return "text-green-750";
    case "Fair":
      return "text-orange-25";
    case "Poor":
      return "text-pink-600";
    default:
      return "text-black";
  }
};

const ConditionStatus = props => {
  const value = props.label || "";
  return (
    <div
      title={value}
      className={`max-w-200 overflow-hidden text-ellipsis text-base font-medium leading-5 ${statusColor(
        value,
      )}`}
    >
      {value}
    </div>
  );
};

export default ConditionStatus;
