const exportModule = {
  name: "Job",
  sections: [
    {
      fields: [
        { label: "Number", name: "id" },
        { label: "Type", name: "JobType.description" },
        { label: "Status", name: "JobStatus.description" },
        { label: "Confirmed By", name: "User.name" },
        { label: "Requested By", name: "RequestUser.name" },
        { label: "Description", name: "description" },
        { label: "Due From", name: "dueDate" },
        { label: "Pickup", name: "pickupId" },
        { label: "Consigment", name: "consignmentId" },
        { label: "Charge Out", name: "chargeOut" },
      ],
      label: "",
      name: "job",
    },
    {
      fields: [
        { label: "Instance Ref", name: "JobItems.Instance.ref" },
        { label: "Title", name: "JobItems.Instance.Edition.Artwork.title" },
        {
          label: "Artist",
          name: "JobItems.Instance.Edition.Artwork.ArtistArtworks.Artist.name",
        },
      ],
      label: "Instance",
      name: "instance",
    },
  ],
};

export default exportModule;
