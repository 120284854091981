import { gql } from "@apollo/client";
import { ConsignmentType } from "../fragments";

const FETCH_CONSIGNMENTS_TYPES = gql`
  query ConsignmentTypes {
    consignmentTypes {
      ${ConsignmentType}
    }
  }
`;

export { FETCH_CONSIGNMENTS_TYPES };
