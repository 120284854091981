import { gql } from "@apollo/client";
import { ViewingRoom } from "../fragments";

const FETCH_VIEWING_ROOM = gql`
  query FetchViewingRoom($input: ViewingRoomListFilter) {
    viewingRooms(input: $input) {
      ${ViewingRoom}
    }
  }
`;

export { FETCH_VIEWING_ROOM };
