import { useQuery } from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom";

import { Breadcrumb, MenuButton } from "../../../components/ui";
import { FETCH_REPORT_TEMPLATE } from "../../../graphql/query/ReportTemplate";

import routes from "../pages/Wizard/routes";
import CreateReportLayout from "./Actions/CreateReportTemplate";

const PrimaryNav = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const pathnames = location?.pathname.split("/");
  // get id from url
  const id = pathnames?.length > 2 ? pathnames[2] : null;
  // fetch invoice details if id is available
  const { data: { reportTemplate } = {} } = useQuery(FETCH_REPORT_TEMPLATE, {
    skip: !id,
    variables: { id: +id },
  });
  // find current submodule route
  const path = pathnames[pathnames?.length - 1];
  const route = routes?.find(item => item?.href?.includes(path));

  // prepare steps for breadcrumbs
  const steps = [
    { label: "All Report Template", onClick: () => navigate(`/reports`) },
    ...(reportTemplate
      ? [
          {
            label: reportTemplate?.id,
            onClick: () => navigate(`${reportTemplate?.id}/edit`),
          },
          {
            label: route?.name,
            onClick: () => navigate(`${reportTemplate?.id}/${route?.href}`),
          },
        ]
      : []),
  ];

  const breadcrumbProps = {
    currentStepIndex: steps?.length - 1,
    onChange: index => steps[index]?.onClick(),
    steps: steps?.map(item => item?.label),
  };

  const actionMenuProps = {
    label: "Actions",
    options: [
      {
        label: "Create Report Template",
        modalProps: {
          body: CreateReportLayout,
          closeOnBackdrop: false,
          hideCloseButton: true,
          scale: "md",
        },
      },
    ],
  };

  return (
    <div className="flex items-center justify-between border-b px-8 py-5">
      <Breadcrumb {...breadcrumbProps} />
      <div className="flex justify-end gap-4">
        <MenuButton {...actionMenuProps} />
      </div>
    </div>
  );
};

export default PrimaryNav;
