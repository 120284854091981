import { useQuery } from "@apollo/client";
import { getInput } from "../../../../../../../components/ui";
import { FETCH_USERS as fetchUsers } from "../../../../../../../graphql/query/User";

const disableConfig = {
  disabled: true,
  inputClassName: "!bg-white !text-gray-400 !border-gray-400",
};
const Header = props => {
  const { formik, submitOnBlur = false } = props || {};

  const { data: userData } = useQuery(fetchUsers(), {
    variables: { input: {} },
  });
  const { users } = userData || {};
  const { edges = [] } = users || {};

  const leftInputs = [
    {
      designType: "checkbox",
      label: "Request sent",
      name: "customsRequest",
      type: "toggle",
      submitOnBlur,
    },
    {
      label: "Request Date",
      name: "customsRequestDate",
      type: "date",
      ...disableConfig,
      submitOnBlur,
    },
    {
      label: "Request User",
      name: "customsRequestUser",
      type: "select",
      options:
        edges?.map(item => ({
          label: item?.meta?.name || item.email,
          value: item?.id,
        })) || [],
      ...disableConfig,
      submitOnBlur,
    },
  ];

  const middleInputs = [
    {
      designType: "checkbox",
      label: "Paperwork Received",
      name: "customsReceived",
      type: "toggle",
      submitOnBlur,
    },
    {
      label: "Received Date",
      name: "customsReceivedDate",
      type: "date",
      ...disableConfig,
      submitOnBlur,
    },
    {
      label: "Received User",
      name: "customsReceivedUser",
      type: "select",
      options:
        edges?.map(item => ({
          label: item?.meta?.name || item.email,
          value: item?.id,
        })) || [],
      ...disableConfig,
      submitOnBlur,
    },
  ];

  const rightInputs = [
    {
      designType: "checkbox",
      label: "Paperwork Checked",
      name: "customsCheck",
      type: "toggle",
      submitOnBlur,
    },
    {
      label: "Checked Date",
      name: "customsCheckDate",
      type: "date",
      ...disableConfig,
      submitOnBlur,
    },
    {
      label: "Checked User",
      name: "customsCheckUser",
      type: "select",
      options:
        edges?.map(item => ({
          label: item?.meta?.name || item.email,
          value: item?.id,
        })) || [],
      ...disableConfig,
      submitOnBlur,
    },
  ];

  return (
    <div className="flex w-full flex-col px-8">
      <div className="grid w-full grid-cols-4 gap-x-20">
        <div className="grid grid-cols-1 gap-y-4">
          <div className="w-full">
            <div className="text-lg font-bold">Custom Paperwork Requested</div>
          </div>
          {leftInputs?.map((item, index) => {
            const inputProps = {
              ...item,
              formik,
            };
            return (
              <div key={index} className={`w-full ${item?.className}`}>
                {getInput(inputProps)}
              </div>
            );
          })}
        </div>
        <div className="grid grid-cols-1 gap-y-4">
          <div className="w-full">
            <div className="text-lg font-bold">Custom Paperwork Received</div>
          </div>
          {middleInputs?.map((item, index) => {
            const inputProps = {
              ...item,
              formik,
            };
            return (
              <div key={index} className={`w-full ${item?.className}`}>
                {getInput(inputProps)}
              </div>
            );
          })}
        </div>
        <div className="grid grid-cols-1 gap-y-4">
          <div className="w-full">
            <div className="text-lg font-bold">Custom Paperwork Checked</div>
          </div>
          {rightInputs?.map((item, index) => {
            const inputProps = {
              ...item,
              formik,
            };
            return (
              <div key={index} className={`w-full ${item?.className}`}>
                {getInput(inputProps)}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Header;
