import {
  ArchiveBoxIcon,
  CalendarIcon,
  ChartPieIcon,
  DocumentTextIcon,
  DocumentPlusIcon,
  EllipsisHorizontalCircleIcon,
  EyeIcon,
  HomeIcon,
  PaperClipIcon,
  PhotoIcon,
  ServerIcon,
} from "@heroicons/react/24/outline";
import Artworks from "./pages/Artworks";
import ConsignmentCosts from "./pages/Costs";
import ConsignmentEstimates from "./pages/Estimate";
import ConsignmentForm from "./pages/ConsignmentForm";
import ConsignmentJobs from "./pages/Jobs";
import ConsignmentTerms from "./pages/Terms";
import Documents from "./pages/Documents";
import Condition from "./pages/Condition";
import Registrar from "./pages/Registrar";
import Schedule from "./pages/Schedule";

const routes = [
  {
    href: "/consignment",
    icon: HomeIcon,
    name: "All Consignment",
  },
  {
    element: ConsignmentForm,
    href: "form",
    icon: DocumentTextIcon,
    name: "Consignment Form",
  },
  {
    element: Artworks,
    href: "artworks",
    icon: PhotoIcon,
    name: "Artworks",
  },
  {
    element: Schedule,
    href: "schedule",
    icon: CalendarIcon,
    name: "Schedule",
  },
  {
    element: ConsignmentJobs,
    href: "jobs",
    icon: ServerIcon,
    name: "Jobs",
  },
  {
    element: Registrar,
    href: "registrar",
    icon: ArchiveBoxIcon,
    name: "Registrar",
  },
  {
    element: ConsignmentTerms,
    href: "terms",
    icon: DocumentPlusIcon,
    name: "Terms",
  },
  {
    element: Documents,
    href: "documents",
    icon: PaperClipIcon,
    name: "Documents",
  },
  {
    element: Condition,
    href: "condition",
    icon: EyeIcon,
    name: "Condition",
  },
  {
    element: ConsignmentCosts,
    href: "costs",
    icon: ChartPieIcon,
    name: "Costs",
  },
  {
    element: ConsignmentEstimates,
    href: "estimate",
    icon: EllipsisHorizontalCircleIcon,
    name: "Estimate",
  },
];

export default routes;
