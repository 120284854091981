import { useQuery } from "@apollo/client";
import { GridInputs } from "../../../../../components/Functional";
import { FETCH_EVENT_RSVPS } from "../../../../../graphql/query/EventRsvp";
import { FETCH_EVENTS } from "../../../../../graphql/query/Event";

const Events = props => {
  const { formik } = props || {};
  const { data: { eventRsvps = [] } = {} } = useQuery(FETCH_EVENT_RSVPS);
  const { data: { events = [] } = {} } = useQuery(FETCH_EVENTS);

  const inputs = {
    className: "grid grid-cols-4 gap-4",
    inputs: [
      {
        label: "Event",
        name: "_eventGuest.eventId",
        options: events?.map(item => ({ label: item.name, value: item.id })),
        type: "multi-select",
      },
      {
        label: "Date From",
        name: "_eventGuest._event.startDate.value.from",
        type: "date",
      },
      {
        label: "Date To",
        name: "_eventGuest._event.startDate.value.to",
        type: "date",
      },
      {
        label: "RSVP",
        name: "_eventGuest.rsvpId",
        options: [
          { label: "All", value: null },
          ...eventRsvps.map(item => ({
            label: item.description,
            value: item.id,
          })),
        ],
        type: "select",
      },
      {
        label: "Invited By",
        name: "_eventGuest.invitedBy",
        type: "text",
      },
    ],
  };

  const gridInputProps = {
    formik,
    ...inputs,
  };

  return <GridInputs {...gridInputProps} />;
};

export default Events;
