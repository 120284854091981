import { useMutation } from "@apollo/client";
import { Button, Modal } from "../../ui";
import { DELETE_LIST_ITEMS } from "../../../graphql/mutation/ListItems";

const RemoveSelected = props => {
  const { ids, listId, onSuccess } = props || {};
  const disabled = !ids?.length > 0;
  const [deleteListItems, { loading }] = useMutation(DELETE_LIST_ITEMS);

  const handleClick = closeModal => async e => {
    e.preventDefault();
    await deleteListItems({
      variables: {
        input: {
          ids,
        },
      },
      update: (cache, { data }) => {
        const { deleteListItems: { success } = {} } = data;
        if (success) {
          ids?.forEach(id => {
            cache.evict({
              id: cache.identify({
                __typename: "ListItem",
                id,
              }),
            });
          });
          // identify list id and update count
          cache.modify({
            id: cache.identify({
              __typename: "List",
              id: listId,
            }),
            fields: {
              itemsCount(value) {
                return value - ids?.length;
              },
            },
          });
          onSuccess?.();
          closeModal();
        }
      },
    });
  };

  const modalProps = {
    title: "",
    scale: "sm",
    hideCloseButton: true,
    closeOnBackdrop: false,
    disabled,
    body: ({ closeModal }) => {
      return (
        <div className={`p-4`}>
          <div className={`flex flex-row items-center justify-between`}>
            <div className={`text-lg`}>Confirmation</div>
            <div className={`flex gap-4`}>
              <Button
                className={`!w-22`}
                action="default"
                label="Cancel"
                onClick={closeModal}
              />
              <Button
                loading={loading}
                className={`!w-22 flex items-center`}
                label={loading ? "Removing..." : "Remove"}
                onClick={handleClick(closeModal)}
              />
            </div>
          </div>
          <div className="mt-8 text-sm font-normal">
            Selected items will be removed from working select list.{" "}
            <b>Confirm?</b>
          </div>
        </div>
      );
    },
  };

  return (
    <Modal {...modalProps}>
      <Button
        label={"Remove Selected"}
        disabled={disabled}
        className={`flex items-center`}
      />
    </Modal>
  );
};

export default RemoveSelected;
