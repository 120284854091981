import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { IconButton, Modal } from "../../../../../../../../../components/ui";

import DeleteAddressModal from "./DeleteAddressModal";
import AddAddressModal from "./AddAddressModal";

const getAddressLabels = address => {
  const labels = [
    address?.use_billing === -1 ? "BILLING" : "",
    address?.use_delivery === -1 ? "DELIVERY" : "",
    address?.use_statement === -1 ? "STATEMENT" : "",
    address?.use_vat === -1 ? "VAT" : "",
    address?.use_catalogue === -1 ? "CATALOGUE" : "",
    address?.use_event === -1 ? "EVENT" : "",
    address?.use_default === -1 ? "DEFAULT" : "",
  ];

  return labels.filter(label => label !== "").join(", ");
};

const DetailView = props => {
  const { contact, contactAddress, disabled = false } = props || {};

  const editMethodModalProps = {
    body: AddAddressModal,
    contact,
    contactAddress,
    closeOnBackdrop: true,
    hideCloseButton: true,
    scale: "md",
  };

  const deleteMethodModalProps = {
    body: DeleteAddressModal,
    contact,
    contactAddress,
    closeOnBackdrop: true,
    hideCloseButton: true,
    scale: "md",
  };

  return (
    <div className="group flex flex-col">
      <div className="w-96 border-b border-gray-500 pb-2 pr-4">
        <div className="flex flex-col">
          <div>{contactAddress?.address ?? ""}</div>
          <div>{contactAddress?.address2 ?? ""}</div>
          <div>{contactAddress?.address3 ?? ""}</div>
          <div>{`${contactAddress?.town ?? ""}${contactAddress?.town ? ", " : ""} ${contactAddress?.county ?? ""}`}</div>
          <div>{`${contactAddress?.postcode ?? ""}${contactAddress?.postcode ? ", " : ""} ${contactAddress?.country?.name ?? ""}`}</div>
        </div>
        {!disabled && (
          <div className="absolute right-0 top-0 flex">
            <Modal {...editMethodModalProps}>
              <div className="hidden group-hover:block">
                <IconButton variant="clean" title="Edit Method">
                  <PencilIcon
                    className={`h-4 w-4 cursor-pointer text-gray-400`}
                  />
                </IconButton>
              </div>
            </Modal>
            <Modal {...deleteMethodModalProps}>
              <div className="hidden group-hover:block">
                <IconButton variant="clean" title="Delete Method">
                  <TrashIcon
                    className={`h-4 w-4 cursor-pointer text-gray-400`}
                  />
                </IconButton>
              </div>
            </Modal>
          </div>
        )}
      </div>
      <div className="flex w-96 flex-row pt-3 text-xs">
        <div className="flex flex-1 flex-col">
          {getAddressLabels(contactAddress)}
        </div>
        <div className="flex flex-col font-bold uppercase">
          {contactAddress?.type?.description}
        </div>
      </div>
      {contactAddress?.do_not_call ? (
        <div className="flex flex-row text-xxs text-red-600">
          DO NOT CONTACT
        </div>
      ) : null}
    </div>
  );
};

export default DetailView;
