import { useMutation, useQuery } from "@apollo/client";
import * as Yup from "yup";

import { Button, getInput } from "../../../../components/ui";
import {
  EXPORT_SAGE_CSV_ACCOUNTS,
  EXPORT_SAGE_CSV_TRANSACTIONS,
} from "../../../../graphql/mutation/Item";
import { useFormik } from "formik";
import { FETCH_COMPANIES as fetchCompanies } from "../../../../graphql/query/Company";

const ExportSageAccounts = props => {
  const { closeModal } = props || {};

  const { data: { companies = [] } = {} } = useQuery(fetchCompanies());

  const [exportSageCsvAccounts, { loading: exportSageCsvAccountsLoading }] =
    useMutation(EXPORT_SAGE_CSV_ACCOUNTS);

  const [
    exportSageCsvTransactions,
    { loading: exportSageCsvTransactionsLoading },
  ] = useMutation(EXPORT_SAGE_CSV_TRANSACTIONS);

  const formik = useFormik({
    initialValues: {
      exportType: "accounts",
    },
    validationSchema: Yup.object({
      companyId: Yup.string().required("Please select a company"),
      toDate: Yup.string().required("Please select a to date"),
    }),
    onSubmit: async values => {
      const { exportType, ...rest } = values;
      if (exportType === "transactions") {
        exportSageCsvTransactions({
          variables: {
            input: {
              ...rest,
            },
          },
        }).then(resp => {
          const {
            data: { exportSageCsvTransactions: { success, url } = {} },
          } = resp || {};
          if (success) {
            window.open(url, "_blank");
            closeModal();
          }
        });
      } else {
        exportSageCsvAccounts({
          variables: {
            input: {
              ...rest,
            },
          },
        }).then(resp => {
          const {
            data: { exportSageCsvAccounts: { success, url } = {} },
          } = resp || {};
          if (success) {
            window.open(url, "_blank");
            closeModal();
          }
        });
      }
    },
  });

  const inputs = [
    {
      label: "Company",
      name: "companyId",
      type: "multi-select",
      options: companies?.edges?.map(item => ({
        label: item.name,
        value: item.id,
      })),
    },
    {
      label: "ToDate",
      name: "toDate",
      type: "date",
    },
    {
      label: "Export Type",
      name: "exportType",
      type: "select",
      options: [
        {
          label: "Accounts",
          value: "accounts",
        },
        {
          label: "Transactions",
          value: "transactions",
        },
      ],
    },
  ];

  return (
    <>
      <div className="w-50 grid grid-cols-2 gap-x-16 gap-y-4 p-6">
        {inputs?.map((item, index) => {
          const inputProps = {
            ...item,
            formik,
          };
          return <div key={index}>{getInput(inputProps)}</div>;
        })}
      </div>
      <div className="mt-4 flex">
        <Button
          className="mr-3"
          label={"Close"}
          action={"primary"}
          onClick={() => {
            closeModal?.();
          }}
        />
        <Button
          label={
            exportSageCsvAccountsLoading || exportSageCsvTransactionsLoading
              ? "Exporting"
              : "Export"
          }
          disabled={
            exportSageCsvAccountsLoading || exportSageCsvTransactionsLoading
          }
          onClick={formik.submitForm}
        />
      </div>
    </>
  );
};

export default ExportSageAccounts;
