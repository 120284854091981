import List from "./pages/List";
// import Wizard from './pages/Wizard';

const routes = [
  {
    path: "*",
    element: List,
    permissions: ["OFFER_MODULE"],
  },
  // {
  //   path: ":id/*",
  //   element: Wizard,
  //   permissions: ['OFFER_MODULE'],
  // },
];

export default routes;
