import { useMutation } from "@apollo/client";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { Button, IconButton, Modal } from "../../../../../components/ui";
import { DELETE_ROLE } from "../../../../../graphql/mutation/Role";

const Actions = props => {
  const { id, values } = props || {};
  const navigate = useNavigate();

  const [deleteRole, { client, loading }] = useMutation(DELETE_ROLE);

  const handleEdit = () => {
    navigate(`/admin/role/${id}/edit`);
  };

  const modalProps = {
    title: "Are you sure?",
    scale: "sm",
    description: `Would you like to delete Role: ${values?.name ?? "n/a"}?`,
    closeOnBackdrop: true,
    body: ({ closeModal }) => {
      const handleDelete = () => {
        deleteRole({
          variables: {
            input: { id },
          },
          update: (cache, { data }) => {
            const { deleteRole: { success } = {} } = data;
            if (success) {
              // TODO: handle this through updating cache to improve performance
              closeModal();
              client.resetStore();
              // cache evict does not work well with paginated results
              // cache.evict({
              //   id: cache.identify({
              //     __typename: 'Role',
              //     id,
              //   }),
              // });
            }
          },
        });
      };

      return (
        <div className="mt-4 flex">
          <Button
            className="mr-3"
            label={"Cancel"}
            action={"default"}
            onClick={() => {
              closeModal?.();
            }}
          />
          <Button
            label={loading ? "Deleting" : "Delete"}
            disabled={loading}
            onClick={handleDelete}
          />
        </div>
      );
    },
  };

  if (typeof id == "number") {
    return (
      <div className="manage-roles-list-actions flex text-right text-sm font-medium">
        {id && (
          <div className="grid grid-cols-4 gap-4">
            {/* <ShareIcon className={`h-4 w-4 cursor-pointer text-gray-400`}/> */}
            <IconButton variant="clean" title="Edit Role">
              <PencilIcon
                className={`h-4 w-4 cursor-pointer text-gray-400`}
                onClick={handleEdit}
              />
            </IconButton>
            <Modal {...modalProps}>
              <IconButton variant="clean" title="Delete Role">
                <TrashIcon className={`h-4 w-4 cursor-pointer text-gray-400`} />
              </IconButton>
            </Modal>
          </div>
        )}
      </div>
    );
  } else {
    return false;
  }
};

export default Actions;
