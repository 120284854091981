import SectionHeader from "../../components/SectionHeader";
import { GridInputs } from "../../../../../../components/Functional";
import useUser from "../../../../../../hooks/useUser";

const BankDetails = props => {
  const { formik } = props;
  const { hasPermission } = useUser();

  const inputs = {
    className: "grid grid-cols-4 gap-8 mt-5",
    inputs: [
      {
        className: "grid-cols-1 gap-4",
        inputs: [
          {
            disabled: true,
            label: "Bank",
            name: "bank.details",
            rows: 20,
            type: "textarea",
            submitOnBlur: true,
          },
        ],
      },
      {
        className: "grid-cols-1 col-span-2 gap-4",
        inputs: [
          {
            label: "Terms",
            name: "terms",
            rows: 20,
            type: "textarea",
            submitOnBlur: true,
            disabled: !hasPermission("UPDATE_INVOICE"),
          },
        ],
      },
    ],
  };

  const detailInputProps = {
    formik,
    ...inputs,
  };

  return (
    <div className="w-full">
      <SectionHeader title="Bank Details" />
      <GridInputs {...detailInputProps} />
    </div>
  );
};

export default BankDetails;
