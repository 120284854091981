import { gql } from "@apollo/client";
import { Bibliography } from "../fragments";

const FETCH_BIBLIOGRAPHY_V2 = gql`
  query FetchBibliographysV2($input: AdvBibliographyFilterInput!) {
    bibliographysV2(input: $input) {
      count
      edges {
        ${Bibliography}
      }
      sqlQuery
    }
  }
`;

export { FETCH_BIBLIOGRAPHY_V2 };
