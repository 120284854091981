import { useQuery } from "@apollo/client";
import { GridInputs } from "../../../../../components/Functional";
import { FETCH_ARTISTS as fetchArtists } from "../../../../../graphql/query/Artist";

const Instance = props => {
  const { formik } = props || {};
  const { data: { artists = [] } = {} } = useQuery(fetchArtists());

  const inputs = {
    className: "grid grid-cols-4 gap-16 mt-5",
    inputs: [
      {
        className: "flex flex-col gap-4",
        inputs: [
          {
            label: "Instance Ref",
            name: "_jobItem._instance.ref",
            type: "number",
          },
        ],
      },
      {
        className: "flex flex-col gap-4",
        inputs: [
          {
            label: "Title",
            name: "_jobItem._instance._edition._artwork.title",
            type: "text",
          },
        ],
      },
      {
        className: "flex flex-col gap-4",
        inputs: [
          {
            label: "Artist",
            name: "_jobItem._instance._edition._artwork._artist.id",
            type: "multi-select",
            options: artists?.map(item => ({
              label: item.name,
              value: item.id,
            })),
          },
        ],
      },
    ],
  };

  const gridInputProps = {
    formik,
    ...inputs,
  };

  return <GridInputs {...gridInputProps} />;
};

export default Instance;
