import { gql } from "@apollo/client";
import { Bibliography } from "../fragments";

const CREATE_BIBLIOGRAPHY = gql`
  mutation CreateBibliography($input: CreateBibliographyInput!) {
    createBibliography(input: $input) {
      success
      error
      bibliography {
        ${Bibliography}
      }
    }
  }
`;

const UPDATE_BIBLIOGRAPHY = gql`
  mutation UpdateBibliography($input: UpdateBibliographyInput!) {
    updateBibliography(input: $input) {
      success
      error
      bibliography {
        ${Bibliography}
      }
    }
  }
`;

export { CREATE_BIBLIOGRAPHY, UPDATE_BIBLIOGRAPHY };
