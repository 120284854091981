import * as React from "react";

const Page = props => {
  const { compiledHtml, onClick, width = 210, height = 297 } = props || {};
  const htmlToBase64 = btoa(unescape(encodeURIComponent(compiledHtml)));

  const initialScale = {
    width: 796,
    height: 1126,
  };

  /*

  width: 797,
    height: 1127

  width: 630,
  height: 891

  width: 792,
  height: 1120

  width: 799,
  height: 1130
   */

  const getDimensions = () => {
    const scalePercent = Math.round((width / initialScale.width) * 100) / 100;
    const proportionalWidth = scalePercent * initialScale.width;
    const proportionalHeight = scalePercent * initialScale.height;
    return {
      transform: `scale(${scalePercent})`,
      width: initialScale.width,
      height: initialScale.height,
      left: -(initialScale.width - proportionalWidth) / 2,
      top: -(initialScale.height - proportionalHeight) / 2,
    };
  };
  const styles = getDimensions();
  return (
    <div
      className="page-preview-container relative mx-auto border border-black"
      style={{ width, height }}
    >
      <embed
        key={htmlToBase64}
        className="embed-pdf-template"
        type="text/html"
        src={`data:text/html;charset=utf-8;base64,${htmlToBase64}`}
        style={styles}
      />
      <div className="absolute inset-0 cursor-pointer" onClick={onClick} />
    </div>
  );
};

export default Page;
