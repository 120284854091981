import { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button } from "../../../../../components/ui";
import { useMutation } from "@apollo/client";
import { getInput } from "../../../../../components/ui/Input";
import { UPDATE_DOCUMENT as updateDocumentMutation } from "../../../../../graphql/mutation/Document";

const EditDocumentTitleModal = props => {
  const { closeModal, pdfTitle, isOpen, documentId } = props;
  const [updateDocument, { loading }] = useMutation(updateDocumentMutation());
  const handleUpdate = () => {
    updateDocument({
      variables: {
        input: { id: +documentId, title: formik.values.title },
      },
    }).then(resp => {
      if (resp.data.updateDocument.success) {
        closeModal();
      }
    });
  };
  const formik = useFormik({
    initialValues: {
      title: pdfTitle,
    },
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: Yup.object({
      title: Yup.string().trim().required("Required"),
    }),
    onSubmit: handleUpdate,
  });

  useEffect(() => {
    formik.setFieldValue("title", pdfTitle);
  }, [isOpen]);

  return (
    <>
      <div className="w-full">
        {getInput({
          formik,
          name: "title",
          label: "Pdf Title (*)",
          bottomLabel: true,
          rightLabel: true,
          type: "text",
          variant: "simple",
          inputClassName: "w-full",
          hideError: true,
        })}
      </div>
      <div className="mt-4 flex">
        <Button
          className="mr-3"
          label={"Cancel"}
          action={"default"}
          onClick={() => {
            closeModal?.();
          }}
        />
        <Button
          label={loading ? "Editing" : "Edit"}
          disabled={loading || !formik.values.title}
          onClick={formik.submitForm}
        />
      </div>
    </>
  );
};

export default EditDocumentTitleModal;
