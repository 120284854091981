import { useMutation, useQuery } from "@apollo/client";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { useNavigate } from "react-router-dom";
import {
  DELETE_DOCUMENT_PAGE,
  DUPLICATE_DOCUMENT_PAGE,
  UPDATE_IMAGE,
} from "../../../../../graphql/mutation/DocumentPage";
import { UPDATE_DOCUMENT as updateDocumentMutation } from "../../../../../graphql/mutation/Document";
import { Bars3Icon } from "@heroicons/react/24/outline";
import Page from "../../CreatePDF/components/Page";
import { Spinner } from "../../../../../components/ui/Spinner";
import { Button } from "../../../../../components/ui/Button";
import { useGetQueryParams } from "../../../../../hooks/queryParams";
import { IconButton, Modal } from "../../../../../components/ui";
import { FETCH_ARTWORK as fetchArtwork } from "../../../../../graphql/query/Artwork";
import Carousel from "../../../../../components/ui/Carousel";
import { useEffect, useRef, useState } from "react";
import { FETCH_DOCUMENT_PAGE_TEMPLATES } from "../../../../../graphql/query/DocumentPageTemplate";

const InstanceImageSummary = props => {
  const {
    artistName,
    title,
    instanceId,
    imageUrl,
    widthHeight,
    action,
    actionText,
    isSelected,
    totalImages,
  } = props;
  const imageStyle = {
    width: widthHeight,
    height: widthHeight,
  };

  if (imageUrl) {
    imageStyle.backgroundImage = `url("${imageUrl}")`;
  } else {
    imageStyle.display = "flex";
    imageStyle.justifyContent = "center";
    imageStyle.alignItems = "center";
  }
  return (
    <>
      <div
        className="page-row instance-image-area group relative flex items-center justify-center border border-gray-400 bg-gray-200 bg-cover"
        style={{
          ...imageStyle,
        }}
      >
        {!imageUrl && (
          <div className="w-[69px] whitespace-normal text-center">
            No Image Available
          </div>
        )}
        {action && !isSelected && totalImages > 1 && (
          <a
            onClick={action}
            className="absolute inline-block hidden rounded bg-black px-4 py-1 text-xs text-white group-hover:block"
          >
            {actionText || "REMOVE"}
          </a>
        )}
        {isSelected && (
          <a className="absolute inline-block rounded bg-black px-4 py-1 text-xs text-white">
            Selected
          </a>
        )}
      </div>
      <div className="full-width instance-description-area py-2 text-xs">
        <div className="truncate" title={artistName}>
          {artistName}
        </div>
        <div className="truncate" title={title}>
          {title}
        </div>
        <div className="truncate">{instanceId}</div>
      </div>
    </>
  );
};

const ArtworkCarousel = props => {
  const {
    artworkId,
    pageId,
    // refetchDocumentPage,
    // isLoading,
    archiveId,
    document,
  } = props;
  const carouselRef = useRef();
  const [numberOfImages, handleNumberOfImages] = useState(3);
  const [updateDocumentPage, { loading }] = useMutation(UPDATE_IMAGE);
  const { data: artworks } = useQuery(fetchArtwork({ images: { imgT: {} } }), {
    variables: {
      id: artworkId,
    },
    skip: !artworkId,
  });
  const handleResize = () => {
    const columWidth = carouselRef?.current?.offsetWidth;
    const columnsCount = columWidth ? Math.floor(columWidth / (150 + 16)) : 0;
    handleNumberOfImages(columnsCount > 0 ? columnsCount : 1);
  };
  const updateImage = archiveId => {
    // isLoading(true);
    updateDocumentPage({
      variables: {
        input: {
          id: pageId,
          archiveId: archiveId,
        },
      },
    }).then(() => {
      // refetchDocumentPage();
      // isLoading(false);
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    handleResize();
  }, [artworks]);
  if (!artworks) {
    return null;
  }
  if (loading) {
    return (
      <div className="flex h-[150px] w-full items-center justify-center">
        <Spinner />
      </div>
    );
  }
  const firstArtworkTitle = artworks?.artwork?.images[0]?.artwork?.title;
  const instanceName =
    document.fieldsData.find(item => item.artwork_title === firstArtworkTitle)
      ?.instance_code || "";
  const CarouselImages = artworks?.artwork?.images?.map((item, key) => {
    return (
      <div
        className="mx-auto h-[140px] w-[140px] border border-black"
        key={key}
      >
        <InstanceImageSummary
          artistName={item?.artwork?.artist?.name}
          title={item?.artwork?.title || ""}
          instanceId={instanceName}
          imageUrl={item.imgT}
          widthHeight={140}
          action={() => updateImage(item?.archiveId)}
          actionText="Add"
          isSelected={
            artworks?.artwork?.images?.length > 1
              ? item?.archiveId === archiveId
              : false
          }
          totalImages={artworks?.artwork?.images?.length}
        />
      </div>
    );
  });
  return (
    <div className="w-full" ref={carouselRef}>
      <Carousel data={CarouselImages} visibleSlides={numberOfImages} />
    </div>
  );
};

const getListStyle = isDraggingOver => ({
  border: isDraggingOver ? "2px dotted #9f9f9f" : "2px solid transparent",
  background: isDraggingOver ? "#f2f2f2" : "#ffffff",
});

const PageListView = props => {
  const {
    pages,
    imageWidth,
    documentId,
    refetchDocumentPage,
    handleSetPagesTemplate,
    bulkUpdatedPage,
    document,
  } = props || {};
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [deleteDocumentPage, { loading }] = useMutation(DELETE_DOCUMENT_PAGE);
  const [duplicateDocumentPage] = useMutation(DUPLICATE_DOCUMENT_PAGE, {
    refetchQueries: ["FetchDocument"],
  });
  const { data: { documentPageTemplates = [] } = {} } = useQuery(
    FETCH_DOCUMENT_PAGE_TEMPLATES,
  );
  const [updateDocument, { loading: updateDocumentLoading }] = useMutation(
    updateDocumentMutation({ pages: {} }),
  );

  const pageHeight = imageWidth ? (1090 * imageWidth) / 780 : 0;
  const pageWidth = imageWidth || 0;
  const pageTitleHeight = 34;

  const pageTemplates = documentPageTemplates?.map((item, index) => {
    const itemProps = {
      ...item,
    };
    const pageWidth = 150;
    return {
      id: itemProps.id,
      name: itemProps.name,
      component: (
        <div key={itemProps?.id} className="mb-5">
          <Page
            key={index}
            {...itemProps}
            width={pageWidth}
            height={pageHeight}
          />
          <div style={{ width: pageWidth }} className="mx-auto mt-2">
            {item.name}
          </div>
        </div>
      ),
    };
  });

  const handleDuplicate = id => {
    duplicateDocumentPage({
      variables: {
        input: {
          id,
        },
      },
    });
  };

  useEffect(() => {
    if (document.id && pages.length === 0 && !bulkUpdatedPage) {
      updateDocument({
        variables: {
          input: {
            templateId:
              documentPageTemplates?.find(
                item => item.name === "Artwork Single Page",
              )?.id || 7,
            id: +documentId,
          },
        },
      }).then(() => {
        refetchDocumentPage();
      });
    }
  }, [pages]);

  return (
    <div className="overflow-auto" style={{ height: "calc(100vh - 400px)" }}>
      {isLoading ||
        (updateDocumentLoading && (
          <div className="absolute left-0 top-0 z-40 flex h-full w-full items-center justify-center bg-gray-50/[.7]">
            <Spinner />
          </div>
        ))}
      <Droppable droppableId="documentPage-list" type="documentPage">
        {provided => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <div role="list" className="flex flex-col">
              {pages?.map((item, index) => {
                const modalProps = {
                  title: "Are you sure?",
                  scale: "sm",
                  description: `Would you like to delete this page?`,
                  closeOnBackdrop: true,
                  body: ({ closeModal }) => {
                    const handleDelete = () => {
                      deleteDocumentPage({
                        variables: {
                          input: {
                            id: item?.id,
                          },
                        },
                        update: (cache, { data }) => {
                          const { deleteDocumentPage: { success } = {} } = data;
                          if (success) {
                            closeModal();
                            cache.evict({
                              id: cache.identify({
                                __typename: "DocumentPage",
                                id: item?.id,
                              }),
                            });
                          }
                        },
                      });
                    };

                    return (
                      <div className="mt-4 flex">
                        <Button
                          className="mr-3"
                          label={"Cancel"}
                          action={"default"}
                          onClick={() => {
                            closeModal?.();
                          }}
                        />
                        <Button
                          label={loading ? "Deleting" : "Delete"}
                          disabled={loading}
                          onClick={handleDelete}
                        />
                      </div>
                    );
                  },
                };

                const pagePreview = (
                  <>
                    <Page
                      compiledHtml={item.compiledHtml}
                      isAddPage={true}
                      width={pageWidth}
                      height={pageHeight}
                    />
                    <div className="small pt-1 text-center text-xs">
                      {item?.name}
                    </div>
                  </>
                );
                const newPageTemplates = [...pageTemplates].filter(
                  template => template.name !== item?.name,
                );
                newPageTemplates.unshift({
                  id: item?.id,
                  name: item?.name,
                  component: pagePreview,
                });
                return (
                  <Draggable
                    key={item?.id}
                    draggableId={String(item?.id)}
                    index={index}
                    type="documentPage"
                  >
                    {provided => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        key={item?.id}
                        className="page-row cursor-pointer border-b border-black"
                      >
                        <div className="flex grid flex-1 flex-grow grid-cols-5 items-center justify-between gap-4 truncate bg-white py-2">
                          <div className="row relative col-span-3 flex xl:col-span-2">
                            <div className="row flex h-fit items-center pt-2">
                              <div {...provided.dragHandleProps}>
                                <IconButton
                                  variant="clean"
                                  title="Reorder Page"
                                >
                                  <Bars3Icon className="h-5 w-5" />
                                </IconButton>
                              </div>
                              <div className="w-[140px] truncate pl-4 text-sm font-medium text-gray-900 hover:text-gray-600">
                                {`Page: ${index + 1}`}
                              </div>
                            </div>
                            <div className="wizard-template-actions absolute left-[40px] top-[35px] flex-col text-sm">
                              <div>
                                <IconButton variant="clean" title="Edit Page">
                                  <div
                                    className="cursor-pointer"
                                    onClick={() =>
                                      navigate(
                                        `/create-pdf/edit-page?document=${documentId}&pageid=${item?.id}`,
                                      )
                                    }
                                  >
                                    Edit Details
                                  </div>
                                </IconButton>
                              </div>
                              <div>
                                <IconButton
                                  variant="clean"
                                  title="Duplicate Page"
                                >
                                  <div onClick={() => handleDuplicate(item.id)}>
                                    Duplicate
                                  </div>
                                </IconButton>
                              </div>
                              <div>
                                <Modal {...modalProps}>
                                  <IconButton
                                    variant="clean"
                                    title="Delete Page"
                                  >
                                    Delete
                                  </IconButton>
                                </Modal>
                              </div>
                            </div>
                            <div className="relative -top-2 -ml-8 truncate pb-4 pt-4 text-xs text-gray-500">
                              <Droppable
                                droppableId={`pagePreview-${item.id}`}
                                direction="horizontal"
                              >
                                {(provided, snapshot) => (
                                  <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={{
                                      ...getListStyle(snapshot.isDraggingOver),
                                      width: pageWidth + 63,
                                      height: pageHeight + pageTitleHeight,
                                    }}
                                    className="overflow-hidden rounded-[4px] p-[2px]"
                                  >
                                    <Carousel
                                      data={newPageTemplates}
                                      visibleSlides={1}
                                      sliderWidth={250}
                                      sliderHeight={
                                        pageHeight + pageTitleHeight
                                      }
                                      onClickChangeSlide={templateId =>
                                        handleSetPagesTemplate(
                                          item.id,
                                          templateId.id,
                                        )
                                      }
                                    />
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Droppable>
                            </div>
                          </div>
                          <div
                            className="col-span-2 -ml-8 flex w-full flex-1 pb-4 pt-4 xl:col-span-3"
                            style={{ height: pageHeight + 48 }}
                          >
                            <ArtworkCarousel
                              artworkId={item?.artworkId}
                              pageId={item.id}
                              refetchDocumentPage={refetchDocumentPage}
                              isLoading={setIsLoading}
                              archiveId={item?.archiveId}
                              document={document}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                );
              })}
            </div>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};

const PageTemplate = props => {
  const {
    document,
    formik,
    isLoadingAssignInstance,
    refetchDocument,
    handleSetPagesTemplate,
  } = props || {};
  const { pages: defaultPages = [], meta } = document || {};
  const navigate = useNavigate();
  const queryParams = useGetQueryParams();
  const documentId = queryParams.get("document");
  return (
    <div className="wizard-template pb-10">
      {!!isLoadingAssignInstance && (
        <div className="absolute left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-gray-100 bg-opacity-75">
          <Spinner />
        </div>
      )}
      <div
        className={`${isLoadingAssignInstance && "loading-instances-image"}`}
      >
        <div className="row relative mb-10 flex items-center justify-between border-0 border-b border-black pb-4">
          <div className="grid w-full grid-cols-5 gap-4">
            <div className="col-span-3 text-base font-bold xl:col-span-2">
              {formik?.values?.title && `${formik.values.title}.pdf`}
            </div>
            <div className="col-span-2 text-base font-bold xl:col-span-3">
              Artwork Images
            </div>
          </div>
          <div className="column absolute right-[16px] flex xl:top-[-10px]">
            <Button
              label="Add Page"
              onClick={() =>
                navigate(`/create-pdf/add-page?document=${documentId}`)
              }
              action="default"
              className="ml-4 px-9"
            />
          </div>
        </div>
        <PageListView
          pages={defaultPages}
          imageWidth={150}
          instances={meta?.instances}
          documentId={documentId}
          refetchDocumentPage={refetchDocument}
          handleSetPagesTemplate={handleSetPagesTemplate}
          bulkUpdatedPage={document?.meta?.bulkUpdatedPage}
          document={document}
        />
      </div>
    </div>
  );
};

export default PageTemplate;
