import {
  UserIcon,
  UsersIcon,
  ExclamationCircleIcon,
  TruckIcon,
  RectangleStackIcon,
  EnvelopeIcon,
  TagIcon,
  HandThumbUpIcon,
  ShieldCheckIcon,
  ShareIcon,
  CalendarIcon,
  ArrowPathIcon,
  Bars4Icon,
  ArrowRightStartOnRectangleIcon,
  CubeIcon,
  AdjustmentsHorizontalIcon,
  FlagIcon,
  PaperClipIcon,
} from "@heroicons/react/24/outline";

import Actions from "./pages/Actions";
import Attributes from "./pages/Attributes";
import Affiliations from "./pages/Affiliations";
import Auctions from "./pages/Auctions";
import BillingDelivery from "./pages/BillingDelivery";
import ClientInformation from "./pages/ClientInformation";
import Collection from "./pages/Collection";
import Consignments from "./pages/Consignments";
import ContactMaterials from "./pages/Materials";
import Details from "./pages/Details";
import Documents from "./pages/Documents";
import Events from "./pages/Events";
import Handover from "./pages/Handover";
import Offers from "./pages/Offers";
import Requests from "./pages/Requests";
import Sales from "./pages/Sales";
import Shipments from "./pages/Shipments";

const routes = [
  {
    href: "/contacts",
    icon: UsersIcon,
    name: "All Contacts",
  },
  {
    element: Details,
    href: "details",
    icon: UserIcon,
    name: "Contact Details",
  },
  {
    element: BillingDelivery,
    href: "billing-delivery",
    icon: TruckIcon,
    name: "Delivery & Billing",
  },
  {
    element: ClientInformation,
    icon: ExclamationCircleIcon,
    href: "client-information",
    name: "Client Information",
    permissions: ["VIEW_CONTACT_SALES"],
  },
  {
    element: Collection,
    href: "collection",
    icon: RectangleStackIcon,
    name: "Collection",
    permissions: ["VIEW_CONTACT_SALES"],
  },
  {
    element: ContactMaterials,
    href: "materials",
    icon: EnvelopeIcon,
    name: "Material Sent",
  },
  {
    element: Sales,
    href: "sales",
    icon: TagIcon,
    name: "Sales",
  },
  {
    element: Offers,
    href: "offers",
    icon: HandThumbUpIcon,
    name: "Offers",
  },
  {
    element: Requests,
    href: "requests",
    icon: ShieldCheckIcon,
    name: "Requests",
  },
  {
    element: Affiliations,
    href: "affiliations",
    icon: ShareIcon,
    name: "Affiliations",
  },
  {
    element: Events,
    href: "events",
    icon: CalendarIcon,
    name: "Events",
  },
  {
    element: Handover,
    href: "handover",
    icon: ArrowPathIcon,
    name: "Handover",
  },
  {
    element: Actions,
    href: "actions",
    icon: Bars4Icon,
    name: "Actions",
  },
  {
    element: Consignments,
    href: "consignments",
    icon: ArrowRightStartOnRectangleIcon,
    name: "Consignments",
  },
  {
    element: Shipments,
    href: "shipments",
    icon: CubeIcon,
    name: "Shipments",
  },
  {
    element: Attributes,
    href: "attributes",
    icon: AdjustmentsHorizontalIcon,
    name: "Attributes",
  },
  {
    element: Auctions,
    href: "auctions",
    icon: FlagIcon,
    name: "Auctions",
  },
  {
    element: Documents,
    href: "documents",
    icon: PaperClipIcon,
    name: "Documents",
  },
];

export default routes;
