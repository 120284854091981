import { useState } from "react";
import { CarouselProvider, Image, Slide, Slider } from "pure-react-carousel";

const ImageSlider = props => {
  const { images, title, previewHeight, sliderHeight, sliderWidth } = props;
  const [imgSrc, setImgSrc] = useState(images?.[0]?.imgT ?? "");
  const [currentSlide, setCurrentSlide] = useState(images?.length > 0 ? 1 : 0);

  const handleSliderClick = index => {
    setCurrentSlide(index + 1);
    setImgSrc(images?.[index]?.imgT);
  };

  return (
    <>
      {title && (
        <h1 className="mb-5 mr-4 mt-1 text-xl font-bold">
          {title} ({currentSlide} / {images?.length || 0})
        </h1>
      )}
      <div className="w-full" style={{ height: `${previewHeight}px` }}>
        <img
          src={imgSrc}
          className="img w-full bg-neutral-200"
          style={{
            padding: "10px",
            height: `${previewHeight}px`,
            objectFit: "contain",
          }}
        />
      </div>
      <CarouselProvider
        visibleSlides={3}
        totalSlides={images?.length}
        naturalSlideWidth={sliderWidth}
        naturalSlideHeight={sliderHeight}
        className="mt-2"
      >
        <Slider>
          {images?.map((image, index) => (
            <Slide
              index={index}
              key={index}
              onClick={() => handleSliderClick(index)}
            >
              <Image
                src={image?.imgT}
                style={{ padding: "10px", objectFit: "cover" }}
              />
            </Slide>
          ))}
        </Slider>
      </CarouselProvider>
    </>
  );
};

export default ImageSlider;
