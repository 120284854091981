import { SearchBar } from "../Input";
import { Filters } from "../Popovers/components/Filters";

const FilterBar = props => {
  const { inputs = [], formik } = props || {};

  const filterProps = {
    inputs,
    formik,
    type: "simple",
  };

  const searchBarProps = {
    name: "search",
    formik,
  };

  return (
    <div className="flex">
      <div className="mr-4">
        <SearchBar {...searchBarProps} />
      </div>
      <Filters {...filterProps} />
    </div>
  );
};

export { FilterBar };
