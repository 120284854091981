import { Switch } from "@headlessui/react";
import { classNames } from "../../../../util";
import ThreeStateCheckbox from "./ThreeStateCheckbox";

const Toggle = props => {
  const {
    disabled,
    error,
    label,
    name,
    value: checked = false,
    onChange,
    className,
    variant = "default",
    designType = "toggle",
  } = props || {};

  const inputProps = {
    checked,
    disabled,
    name,
    onChange,
  };

  const variants = {
    default: {
      container: "relative flex items-center",
      label: "block text-xs font-medium text-black ml-5 mr-5",
    },
    horizontal: {
      container:
        "relative flex items-center flex-row-reverse w-full justify-between",
      label: "block sm13 font-medium text-black ml-5 my-2",
    },
  };

  const classes = variants[variant] || variants.default;

  return (
    <>
      <div className={`${classes.container} ${className || ""}`}>
        {designType === "toggle" ? (
          <Switch
            {...inputProps}
            className={classNames(
              checked ? "bg-black" : "bg-gray-200",
              "relative inline-flex h-4 w-8 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none" +
                "focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",
            )}
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className={classNames(
                checked ? "translate-x-4" : "translate-x-0",
                "pointer-events-none inline-block h-3 w-3 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
              )}
            />
          </Switch>
        ) : designType === "threeStateCheckbox" ? (
          <ThreeStateCheckbox {...inputProps} />
        ) : designType === "checkbox" ? (
          <input
            {...inputProps}
            type="checkbox"
            className={`h-4 w-4 cursor-pointer rounded border-2 focus:ring-0 ${disabled ? "!border-gray-400 !text-gray-400" : "border-gray-800 text-gray-800"}}`}
            onChange={e => onChange(e?.target?.checked ? -1 : 0)}
          />
        ) : (
          <input
            {...inputProps}
            type="checkbox"
            className={`h-4 w-4 cursor-pointer rounded border-2 focus:ring-0 ${disabled ? "!border-gray-400 !text-gray-400" : "border-gray-800 text-gray-800"}}`}
            onChange={e => onChange(e?.target?.checked)}
          />
        )}
        {label && (
          <label htmlFor={name} className={classes.label}>
            {label}
          </label>
        )}
      </div>
      <small className="text-sm text-red-600">{error}</small>
    </>
  );
};

export default Toggle;
