import { gql } from "@apollo/client";
import { Company as company } from "../fragments";

const UPDATE_COMPANY = props => gql`
  mutation UpdateCompany($input: UpdateCompanyInput!) {
    updateCompany(input: $input) {
      success
      error
      company {
        ${company(props)}
      }
    }
  }
`;

export { UPDATE_COMPANY };
