import { gql } from "@apollo/client";
import { EditionType } from "../fragments";

const FETCH_EDITION_TYPES = gql`
  query FetchEditionTypes {
    editionTypes {
      ${EditionType}
    }
  }
`;

export { FETCH_EDITION_TYPES };
