import { useFormik } from "formik";
import { useEffect } from "react";
import { useQuery } from "@apollo/client";

import { Button } from "../../../../components/ui";
import { GridInputs } from "../../../../components/Functional";

import { FETCH_PARTNER } from "../../../../graphql/query/Partner";

const StorageEstimate = props => {
  const { closeModal, instance } = props;
  const { data: { partner = {} } = {} } = useQuery(FETCH_PARTNER);

  const disableConfig = {
    disabled: true,
    inputClassName: "!bg-white !text-gray-400 !border-gray-400",
  };

  const formik = useFormik({
    initialValues: {
      name: instance?.name,
      description: instance?.description,
      volume: instance?.volume / 1000000 || 0,
      minCharge: partner?.meta?.minStorageCharge || 0,
      chargePerM3: partner?.meta?.chargePerM3 || 0,
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    const { chargePerM3, minCharge, volume } = formik?.values || {};

    const volumeCharge = volume * chargePerM3 || 0;
    const thirtyDaysCharge = volumeCharge
      ? Math.max(minCharge, volumeCharge) * 4.285714
      : 0;

    formik?.setFieldValue(
      "volumeCharge",
      `£${(Math.ceil(volumeCharge * 100) / 100).toFixed(2)}`,
    );
    formik?.setFieldValue(
      "thirtyDaysCharge",
      `£${(Math.ceil(thirtyDaysCharge * 100) / 100).toFixed(2)}`,
    );
    formik?.setFieldValue("minChargeDisp", `£${minCharge}`);
    formik?.setFieldValue("chargePerM3Disp", `£${chargePerM3}`);
  }, [formik?.values]);

  const inputs = {
    className: "grid grid-cols-2 gap-12",
    inputs: [
      {
        className: "grid grid-cols-1 gap-4",
        inputs: [
          {
            label: "Storage Estimate For",
            name: "name",
            type: "text",
            ...disableConfig,
          },
          {
            label: "Storage Estimate For",
            name: "description",
            rows: 8,
            type: "textarea",
            ...disableConfig,
          },
          {
            label: "Volume (m3)",
            name: "volume",
            type: "number",
            ...disableConfig,
          },
        ],
      },
      {
        className: "grid grid-cols-1 gap-4",
        inputs: [
          {
            label: "Set Charge per m3 (per week)",
            name: "chargePerM3Disp",
            type: "text",
            ...disableConfig,
          },
          {
            label: "Charge for this work (per week)",
            name: "volumeCharge",
            type: "text",
            ...disableConfig,
          },
          {
            label: "Minimum Charge (per week)",
            name: "minChargeDisp",
            type: "text",
            ...disableConfig,
          },
          {
            label: "30 Days Storage",
            name: "thirtyDaysCharge",
            type: "text",
            ...disableConfig,
          },
        ],
      },
    ],
  };

  const inputProps = {
    ...inputs,
    formik,
  };

  return (
    <div className="flex w-full flex-col p-8">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          Storage Estimate
        </div>
        <div className="flex flex-col">
          <div>
            <Button action="dark" label="close" onClick={closeModal} />
          </div>
        </div>
      </div>
      <div className="mt-12 pb-10">
        <GridInputs {...inputProps} />
      </div>
    </div>
  );
};

export default StorageEstimate;
