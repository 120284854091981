import { useMutation } from "@apollo/client";
import { TrashIcon } from "@heroicons/react/24/outline";
import { Button, IconButton, Modal } from "../../../../../../../components/ui";
import { DELETE_JOB_ITEM } from "../../../../../../../graphql/mutation/JobItem";

const Actions = props => {
  const { id, disabled } = props || {};
  const [deleteJobItem, { loading }] = useMutation(DELETE_JOB_ITEM);

  const deleteModalProps = {
    title: "ARE YOU SURE?",
    scale: "sm",
    description: `Would you like to delete this job item?`,
    closeOnBackdrop: true,
    disabled: disabled,
    body: ({ closeModal }) => {
      const handleDelete = () => {
        deleteJobItem({
          variables: {
            input: {
              id: +id,
            },
          },
          update: (cache, { data }) => {
            const { deleteJobItem: { success } = {} } = data;
            if (success) {
              closeModal();
              cache.evict({
                id: cache.identify({
                  __typename: "JobItem",
                  id,
                }),
              });
            }
          },
        });
      };
      return (
        <div className="flex justify-end mt-4">
          <Button
            className="mr-3"
            label="Cancel"
            onClick={() => {
              closeModal();
            }}
          />
          <Button
            label={loading ? "Deleting" : "Delete"}
            disabled={loading}
            onClick={handleDelete}
          />
        </div>
      );
    },
  };

  if (typeof id == "number") {
    return (
      <div className="flex text-sm font-medium text-right user-list-actions">
        {id && (
          <div className="grid grid-cols-4 gap-4">
            <Modal {...deleteModalProps}>
              <IconButton
                variant="clean"
                disabled={disabled}
                title={
                  disabled
                    ? 'Status must be "pending" in order to remove items'
                    : "Delete Item"
                }
                className={disabled ? "opacity-50" : ""}
              >
                <TrashIcon
                  className={`h-4 w-4 cursor-pointer text-gray-400 ${disabled ? "hover:cursor-not-allowed" : ""}`}
                />
              </IconButton>
            </Modal>
          </div>
        )}
      </div>
    );
  } else {
    return false;
  }
};

export default Actions;
