import { useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Button } from "../../../../../components/ui";
import { GridInputs } from "../../../../../components/Functional";

import { UPDATE_OFFER as updateOfferMutation } from "../../../../../graphql/mutation/Offer";
import { FETCH_CURRENCIES } from "../../../../../graphql/query/Currency";
import { FETCH_OFFER_STATUSES } from "../../../../../graphql/query/OfferStatus";
import { FETCH_ENTITIES } from "../../../../../graphql/query/Entity";

const EditOffer = props => {
  const { offer, closeModal } = props;

  const { data: { currencies = [] } = {} } = useQuery(FETCH_CURRENCIES);
  const { data: { offerStatuses = [] } = {} } = useQuery(FETCH_OFFER_STATUSES);
  const { data: { entities } = {} } = useQuery(FETCH_ENTITIES);

  const [updateOffer, { loading, reset }] = useMutation(
    updateOfferMutation({ contact: {}, currency: {}, status: {} }),
  );

  const formik = useFormik({
    initialValues: {
      amount: offer?.amount,
      contactId: offer?.contactId,
      currencyId: offer?.currencyId,
      date: offer?.date,
      dateClosed: offer?.dateClosed,
      holdDate: offer?.holdDate,
      holdId: offer?.holdId,
      id: offer?.id,
      notes: offer?.notes,
      priority: offer?.priority,
      statusId: offer?.statusId,
      tbiAmount: offer?.tbiAmount,
      tbiDate: offer?.tbiDate,
      user: offer?.user,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      contactId: Yup.number()
        .required("Please select a contact.")
        .typeError("Please select a contact."),
      statusId: Yup.number()
        .required("Please select a status.")
        .typeError("Please select a status."),
      currencyId: Yup.number()
        .required("Please select a currency.")
        .typeError("Please select a currency."),
      holdId: Yup.number().positive().integer().nullable(),
      amount: Yup.number()
        .typeError("Please enter a valid amount.")
        .positive("must be a positive value")
        .nullable(),
      tbiAmount: Yup.number()
        .typeError("Please enter a valid amount.")
        .positive("must be a positive value")
        .nullable(),
    }),
    onSubmit: values => {
      const removeFields = ["date", "dateClosed", "holdDate", "tbiDate"];
      const rest = Object.keys(values).reduce((object, key) => {
        if (!removeFields.includes(key)) {
          object[key] = values[key];
        }
        return object;
      }, {});

      updateOffer({
        variables: {
          input: rest,
        },
      }).then(resp => {
        const {
          data: { updateOffer: { success } = {} },
        } = resp || {};
        if (success) {
          closeModal();
          reset();
          formik?.resetForm();
        }
      });
    },
  });

  const inputs = {
    className: "grid grid-cols-4 gap-8",
    inputs: [
      {
        className: "grid grid-cols-1 gap-4",
        inputs: [
          { label: "Contact", name: "contactId", type: "contactSearch" },
          {
            label: "Status",
            name: "statusId",
            type: "multi-select",
            options: offerStatuses?.map(item => ({
              label: item?.description,
              value: item?.id,
            })),
          },
          {
            label: "Currency",
            name: "currencyId",
            type: "multi-select",
            isMulti: false,
            options: currencies?.map(currency => ({
              label: `${currency.description} (${currency.code})`,
              value: currency.id,
            })),
          },
          {
            name: "priority",
            options: [{ label: "Top Deal", value: -1 }],
            singleCheckbox: true,
            type: "checkbox",
          },
        ],
      },
      {
        className: "grid grid-cols-1 gap-4",
        inputs: [
          { label: "Amount", name: "amount", type: "number-currency" },
          { label: "TBI Amount", name: "tbiAmount", type: "number-currency" },
          { label: "Hold", name: "holdId", type: "instanceSearch" },
          {
            label: "User",
            name: "user",
            type: "multi-select",
            options:
              entities?.edges?.map(item => {
                return { label: item.name, value: item.name };
              }) || [],
          },
        ],
      },
      {
        className: "grid grid-cols-1 gap-4",
        inputs: [
          {
            label: "Droite de Suite / ARR",
            name: "_",
            type: "arr",
            disabled: true,
          },
          { label: "Notes", name: "notes", rows: 5, type: "textarea" },
        ],
      },
      {
        className: "grid grid-cols-1 gap-4",
        inputs: [
          { label: "Date", name: "date", type: "date", disabled: true },
          {
            label: "Date Closed",
            name: "dateClosed",
            type: "date",
            disabled: true,
          },
          { label: "TBI Date", name: "tbiDate", type: "date", disabled: true },
          {
            label: "Hold Date",
            name: "holdDate",
            type: "date",
            disabled: true,
          },
        ],
      },
    ],
  };

  const inputProps = {
    formik,
    ...inputs,
  };

  return (
    <div className="flex w-full flex-col p-8">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          Edit Offer
        </div>
        <div className="flex flex-row">
          <Button
            action="default"
            className="mr-8"
            label="Cancel"
            onClick={() => {
              closeModal();
              formik?.resetForm();
            }}
          />
          <Button
            label={loading ? "Updating" : "Update"}
            disabled={loading || !formik?.isValid}
            onClick={formik.submitForm}
          />
        </div>
      </div>
      <div className="mt-8 pb-8">
        <GridInputs {...inputProps} />
      </div>
    </div>
  );
};

export default EditOffer;
