import ListTypes from "./ListTypes";
import ListItems from "./ListItems";

const List = ({ items = false, type = false } = {}) => `
  id
  ${
    items
      ? `items {
      ${ListItems}
    }`
      : ""
  }
  itemsCount
  name
  ${
    type
      ? `type {
      ${ListTypes}
    }`
      : ""
  }
  typeId
  userId
  updatedAt
`;

export default List;
