import { useMutation, useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import { Button } from "../../../../../components/ui";
import { FETCH_DOCUMENT_PAGE_TEMPLATES } from "../../../../../graphql/query/DocumentPageTemplate";
import { CREATE_DOCUMENT_PAGE } from "../../../../../graphql/mutation/DocumentPage";
import { FETCH_DOCUMENT as fetchDocument } from "../../../../../graphql/query/Document";
import Page from "../../CreatePDF/components/Page";
import { useGetQueryParams } from "../../../../../hooks/queryParams";

const AddPageTemplate = props => {
  const { refetchDocument } = props;
  const navigate = useNavigate();
  const queryParams = useGetQueryParams();
  const documentId = queryParams.get("document");
  const { data: { documentPageTemplates = [] } = {} } = useQuery(
    FETCH_DOCUMENT_PAGE_TEMPLATES,
  );
  const [createDocumentPage] = useMutation(CREATE_DOCUMENT_PAGE, {
    refetchQueries: [fetchDocument()],
  });
  const parentPage = `/create-pdf/page-template?document=${documentId}`;

  const handleCreate = templateId => {
    createDocumentPage({
      variables: {
        input: {
          documentId: +documentId,
          templateId,
        },
      },
    }).then(resp => {
      refetchDocument();
      navigate(parentPage);
    });
  };

  return (
    <div className="py-8">
      <div className="flex items-center justify-between">
        <h2 className="mb-4 text-lg font-medium text-black">
          Add Page Templates
        </h2>
        <Button
          label="Cancel"
          action="default"
          onClick={() => navigate(parentPage)}
        />
      </div>
      <div className="mt-4 flex flex-wrap">
        {documentPageTemplates?.map((item, index) => {
          const itemProps = {
            ...item,
            onClick: () => handleCreate(item?.id),
          };
          return (
            <div key={itemProps?.id} className="mb-5 mr-5">
              <Page key={index} {...itemProps} />
              <div className="mt-2">{item.name}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AddPageTemplate;
