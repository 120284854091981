import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";

import { GridInputs } from "../../../../../../../components/Functional";
import { FETCH_ARTISTS as fetchArtists } from "../../../../../../../graphql/query/Artist";
import { FETCH_AUTH_GROUPS } from "../../../../../../../graphql/query/AuthGroup";
import { FETCH_WAREHOUSE_CATEGORIES } from "../../../../../../../graphql/query/WarehouseCategory";
import { FETCH_WAREHOUSE_PACKINGS } from "../../../../../../../graphql/query/WarehousePacking";
import { FETCH_WAREHOUSE_MGROUPS } from "../../../../../../../graphql/query/WarehouseMGroup";
import { FETCH_WAREHOUSE_WEIGHT_TYPES } from "../../../../../../../graphql/query/WarehouseWeightType";
import { Input } from "../../../../../../../components/ui";

const Information = props => {
  const {
    calculatedDimension,
    formik,
    disableConfig,
    submitOnBlur = false,
    warehouseItem,
  } = props;
  const [mgroupOptions, setMgroupOptions] = useState([]);

  const { data: { artists = [] } = {} } = useQuery(fetchArtists());
  const { data: { authGroups = [] } = {} } = useQuery(FETCH_AUTH_GROUPS);
  const { data: { warehouseCategories = [] } = {} } = useQuery(
    FETCH_WAREHOUSE_CATEGORIES,
  );
  const { data: { warehousePackings = [] } = {} } = useQuery(
    FETCH_WAREHOUSE_PACKINGS,
  );
  const { data: { warehouseWeightTypes = [] } = {} } = useQuery(
    FETCH_WAREHOUSE_WEIGHT_TYPES,
  );
  const { data: { warehouseMGroups = [] } = {} } = useQuery(
    FETCH_WAREHOUSE_MGROUPS,
    { variables: { input: { instanceId: formik?.values?.instanceId } } },
  );

  useEffect(() => {
    setMgroupOptions(
      warehouseMGroups?.map(item => ({
        label: item.mgroup,
        value: item.mgroup,
      })),
    );
  }, [warehouseMGroups]);

  const handleGroupOptions = (value, actionProps) => {
    const { action } = actionProps || {};

    if (action === "create-option") {
      const newOption = { label: +value?.label, value: +value?.value };
      setMgroupOptions([...mgroupOptions, newOption]);
      formik?.setFieldValue("mgroup", newOption?.value);
    }
  };

  const information1Inputs = {
    className: "grid-cols-1 gap-4",
    inputs: [
      {
        label: "Instance",
        name: "instanceId",
        type: "instanceSearch",
        redirect: true,
        submitOnBlur,
        warnOverwrite: true,
      },
      {
        label: "Artist",
        name: "artistId",
        type: "multi-select",
        submitOnBlur,
        options: artists?.map(item => ({ label: item?.name, value: item.id })),
      },
      {
        label: "Category",
        name: "categoryId",
        type: "multi-select",
        submitOnBlur,
        options: warehouseCategories?.map(item => ({
          label: item.description,
          value: item.id,
        })),
      },
      {
        label: "Group",
        title:
          "1 - Fine art items that determine the location of the instance, 2 - Warehouse items that determine the weight on shipping invoices",
        name: "mgroup",
        submitOnBlur,
        isCreatable: true,
        type: "multi-select",
        options: mgroupOptions,
        handleAction: handleGroupOptions,
      },
      {
        label: "Responsible Dept",
        name: "responsibleGroupId",
        type: "multi-select",
        options: authGroups?.map(item => ({
          label: item.name,
          value: item.id,
        })),
        submitOnBlur,
      },
    ],
  };

  const information2Inputs = {
    className: "grid-cols-1 gap-4",
    inputs: [
      {
        label: "Packing Specification",
        name: "packingId",
        type: "multi-select",
        submitOnBlur,
        options: warehousePackings?.map(item => ({
          label: item.description,
          value: item.id,
        })),
      },
      {
        label: "External Storage #",
        name: "externalRef",
        type: "text",
        submitOnBlur,
      },
      {
        label: "Container",
        name: "container",
        type: "text",
        ...disableConfig,
        submitOnBlur,
      },
    ],
  };

  const weightInputs = {
    className: "grid grid-cols-1 gap-4 mt-4",
    inputs: [
      { label: "KG", name: "weight", type: "number", submitOnBlur },
      {
        label: "Type",
        name: "weightTypeId",
        type: "multi-select",
        disabled: warehouseItem.categoryId === 1,
        submitOnBlur,
        options:
          formik?.values?.categoryId === 1
            ? warehouseWeightTypes
                ?.filter(item => item.name === "Unpacked Weight")
                ?.map(item => ({ label: item.name, value: item.id }))
            : warehouseWeightTypes?.map(item => ({
                label: item.name,
                value: item.id,
              })),
      },
    ],
  };

  const dimensionsInputs = {
    className: "grid grid-cols-1 gap-4 mt-4",
    inputs: [
      {
        className: "grid grid-cols-3 gap-4",
        inputs: [
          { label: "Length (cm)", name: "dimd", type: "number", submitOnBlur },
          { label: "Width (cm)", name: "dimw", type: "number", submitOnBlur },
          { label: "Height (cm)", name: "dimh", type: "number", submitOnBlur },
        ],
      },
    ],
  };

  const information1InputProps = {
    formik,
    ...information1Inputs,
  };

  const information2InputProps = {
    formik,
    ...information2Inputs,
  };

  const weightInputProps = {
    formik,
    ...weightInputs,
  };

  const dimensionsInputProps = {
    formik,
    ...dimensionsInputs,
  };

  return (
    <div className="grid grid-cols-4 gap-16">
      <GridInputs {...information1InputProps} />
      <GridInputs {...information2InputProps} />
      <div>
        <h5>Weight</h5>
        <p className="mt-4 text-xs">
          For Fine Art items this MUST be the unpacked weight <br />
          For Containers it SHOULD be the weight of the container or the packed
          weight if only that is known.
        </p>
        <GridInputs {...weightInputProps} />
      </div>
      <div>
        <h5>Dimensions</h5>
        <GridInputs {...dimensionsInputProps} />
        <div className="mt-4 grid h-fit grid-cols-3 gap-4">
          <Input
            type="text"
            label="Length (in)"
            value={calculatedDimension?.depthIn}
            disabled={true}
          />
          <Input
            type="text"
            label="Width (in)"
            value={calculatedDimension?.widthIn}
            disabled={true}
          />
          <Input
            type="text"
            label="Height (in)"
            value={calculatedDimension?.heightIn}
            disabled={true}
          />
        </div>
        <div className="mt-4 grid h-fit grid-cols-2 gap-4">
          <Input
            type="text"
            label="Area (m2)"
            value={calculatedDimension?.area}
            disabled={true}
          />
          <Input
            type="text"
            label="Volume (m3)"
            value={calculatedDimension?.volume}
            disabled={true}
          />
        </div>
      </div>
    </div>
  );
};

export default Information;
