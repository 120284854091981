import { gql } from "@apollo/client";
import Country from "../fragments/Country";

const FETCH_CONTINENTS = gql`
  query FetchContinents {
    continents {
      id
      continent
    }
  }
`;

const FETCH_COUNTRIES = gql`
  query FetchCountries {
    countries {
      ${Country}
    }
  }
`;

const FETCH_SAGECODES = gql`
  query FetchSagecodes {
    sagecodes {
      id
      sagecode
    }
  }
`;

export { FETCH_CONTINENTS, FETCH_COUNTRIES, FETCH_SAGECODES };
