import { gql } from "@apollo/client";
import { ContactType } from "../fragments";

const FETCH_CONTACT_TYPES = gql`
  query FetchContactTypes {
    contactTypes {
      ${ContactType}
    }
  }
`;

export { FETCH_CONTACT_TYPES };
