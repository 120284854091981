import { gql } from "@apollo/client";
import { ContactCatalogue as contactCatalogue } from "../fragments";

const FETCH_CONTACT_CATALOGUES = props => gql`
  query FetchContactCatalogues($input: ContactCatalogueFilterInput!) {
    contactCatalogues(input: $input) {
      count
      edges {
        ${contactCatalogue(props)}
      }
    }
  }
`;

export { FETCH_CONTACT_CATALOGUES };
