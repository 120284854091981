import * as Yup from "yup";
import { useEffect } from "react";
import { useFormik } from "formik";
import { useMutation, useQuery } from "@apollo/client";

import { Button } from "../../../../../../../components/ui";
import { GridInputs } from "../../../../../../../components/Functional";

import { FETCH_CURRENCIES } from "../../../../../../../graphql/query/Currency";
import {
  CREATE_INSTANCE_AUCTION,
  UPDATE_INSTANCE_AUCTION,
} from "../../../../../../../graphql/mutation/InstanceAuction";

const EditAuction = props => {
  const { closeModal, instanceId, isOpen, values } = props;

  const { data: { currencies = [] } = {} } = useQuery(FETCH_CURRENCIES);

  const [updateInstanceAuction, { loading: updateLoading }] = useMutation(
    UPDATE_INSTANCE_AUCTION,
  );
  const [createInstanceAuction, { loading: createLoading }] = useMutation(
    CREATE_INSTANCE_AUCTION,
    { refetchQueries: ["FetchInstanceAuctions"] },
  );

  const loading = updateLoading || createLoading;

  const addAuction = () => {
    const input = {
      auctioneer: formik.values.auctioneer,
      city: formik.values.city,
      currencyId: formik.values.currencyId,
      estimateh: formik.values.estimateh,
      estimatel: formik.values.estimatel,
      event: formik.values.event,
      hammer: formik.values.hammer,
      lot: formik.values.lot,
      notes: formik.values.notes,
      saleRef: formik.values.saleRef,
      saleDate: formik.values.saleDate,
      total: formik.values.total,
      url: formik.values.url,
    };

    if (values?.id) {
      input.id = values?.id;
    } else {
      input.instanceId = instanceId;
    }

    const action = values?.id ? updateInstanceAuction : createInstanceAuction;

    action({
      variables: {
        input,
      },
    }).then(() => {
      closeModal();
      formik.resetForm();
    });
  };

  const formik = useFormik({
    initialValues: values
      ? {
          auctioneer: values.auctioneer,
          city: values.city,
          currencyId: values.currencyId,
          estimateh: values.estimateh,
          estimatel: values.estimatel,
          event: values.event,
          hammer: values.hammer,
          lot: values.lot,
          notes: values.notes,
          saleRef: values.saleRef,
          saleDate: values.saleDate,
          total: values.total,
          url: values.url,
        }
      : {},
    enableReinitialize: true,
    validationSchema: Yup.object({
      auctioneer: Yup.string().trim().required("Required"),
      currencyId: Yup.number().required("Required").typeError("Required"),
      estimateh: Yup.number()
        .positive()
        .required("Required")
        .typeError("Invalid input"),
      estimatel: Yup.number()
        .positive()
        .required("Required")
        .typeError("Invalid input"),
      event: Yup.string().trim().required("Required"),
      hammer: Yup.number()
        .positive()
        .required("Required")
        .typeError("Invalid input"),
      saleDate: Yup.date().required("Required"),
      total: Yup.number().positive().nullable(),
    }),
    onSubmit: addAuction,
  });

  const inputs = {
    className: "grid grid-cols-2 gap-12",
    inputs: [
      {
        className: "grid grid-cols-1 gap-4",
        inputs: [
          { label: "Auctioneer", name: "auctioneer", type: "text" },
          { label: "Event", name: "event", type: "text" },
          { label: "Sale Ref", name: "saleRef", type: "text" },
          { label: "City", name: "city", type: "text" },
          {
            label: "Currency",
            name: "currencyId",
            type: "multi-select",
            options: currencies?.map(currency => ({
              label: `${currency?.description} (${currency?.code})`,
              value: currency?.id,
            })),
          },
          {
            className: "grid grid-cols-2 gap-4",
            inputs: [
              {
                label: "Estimate From",
                name: "estimateh",
                type: "number-currency",
              },
              { label: "To", name: "estimatel", type: "number-currency" },
            ],
          },
        ],
      },
      {
        className: "grid grid-cols-1 gap-4",
        inputs: [
          { label: "Sale Date", name: "saleDate", type: "date" },
          {
            className: "items-end grid grid-cols-2 gap-4",
            inputs: [
              { label: "Lot", name: "lot", type: "text" },
              { label: "", name: "_", type: "text" },
            ],
          },
          { label: "Hammer", name: "hammer", type: "number-currency" },
          { label: "Total", name: "total", type: "number-currency" },
          { label: "Web Link", name: "url", type: "text" },
          { label: "Notes", name: "notes", rows: 3, type: "textarea" },
        ],
      },
    ],
  };

  const inputProps = {
    ...inputs,
    formik,
  };

  const successLabel = values?.id ? "Edit" : "Add";

  useEffect(() => {
    formik.handleReset();
  }, [isOpen]);

  return (
    <div className="flex w-full flex-col p-8">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          {values?.id ? "Edit" : "Add"} Auction Record
        </div>
        <div className="flex flex-col">
          <div>
            <Button
              action="default"
              className="mr-8"
              label="Cancel"
              onClick={closeModal}
            />
            <Button
              label={`${successLabel}${loading ? "ing" : ""} Auction`}
              disabled={loading}
              onClick={formik.submitForm}
            />
          </div>
        </div>
      </div>
      <div className="mt-12 pb-10">
        <GridInputs {...inputProps} />
      </div>
    </div>
  );
};

export default EditAuction;
