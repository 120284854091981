import { useMutation } from "@apollo/client";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Button, Modal } from "../../../../../../../components/ui";
import { IconButton } from "../../../../../../../components/ui/IconButton";
import { DELETE_CONTACT_LINK } from "../../../../../../../graphql/mutation/ContactLink";
import AddAffiliation from "./AddAffiliations";
import useUser from "../../../../../../../hooks/useUser";

const Actions = props => {
  const { id, contact, values } = props || {};
  const [deleteContactLink, { loading }] = useMutation(DELETE_CONTACT_LINK);
  const { hasPermission } = useUser();
  const disabled = !hasPermission("EDIT_CONTACT_LINK");

  const editModalProps = {
    affiliation: values?.rawFields,
    body: AddAffiliation,
    contact,
    closeOnBackdrop: true,
    disabled,
    hideCloseButton: true,
    scale: "md",
  };

  const deleteModalProps = {
    title: "Are you sure?",
    scale: "sm",
    description: `Would you like to delete contact Link ?`,
    closeOnBackdrop: true,
    body: ({ closeModal }) => {
      const handleDelete = () => {
        deleteContactLink({
          variables: {
            input: { id: +values?.rawFields?.id },
          },
          update: (cache, { data }) => {
            const { deleteContactLink: { success } = {} } = data;
            if (success) {
              closeModal();
              cache.evict({
                id: cache.identify({
                  __typename: "ContactLink",
                  id,
                }),
              });
            }
          },
        });
      };

      return (
        <div className="mt-4 flex">
          <Button
            className="mr-3"
            label={"Cancel"}
            action={"default"}
            onClick={() => {
              closeModal?.();
            }}
          />
          <Button
            label={loading ? "Deleting" : "Delete"}
            disabled={loading}
            onClick={handleDelete}
          />
        </div>
      );
    },
  };

  if (typeof id == "number") {
    return (
      <div className="user-list-actions flex text-right text-sm font-medium">
        {id && (
          <div className="grid grid-cols-4 gap-4">
            {/* <IconButton variant="clean" title="Flag Affiliations">
              <FlagIcon className={`h-4 w-4 cursor-pointer text-gray-400`} />
            </IconButton> */}
            {!disabled && (
              <>
                <Modal {...editModalProps}>
                  <IconButton variant="clean" title="Edit Affiliation">
                    <PencilIcon
                      className={`h-4 w-4 cursor-pointer text-gray-400`}
                    />
                  </IconButton>
                </Modal>
                {/* <IconButton variant="clean" title="View Affiliations">
                  <EyeIcon className={`h-4 w-4 cursor-pointer text-gray-400`}/>
                </IconButton> */}
                <Modal {...deleteModalProps}>
                  <IconButton variant="clean" title="Delete Affiliation">
                    <TrashIcon
                      className={`h-4 w-4 cursor-pointer text-gray-400`}
                    />
                  </IconButton>
                </Modal>
              </>
            )}
          </div>
        )}
      </div>
    );
  } else {
    return false;
  }
};

export default Actions;
