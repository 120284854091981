const EmailContact = `
  id
  createdAt
  emailId
  content
  mailingListId
  contactId
  meta
  updatedAt
`;

export default EmailContact;
