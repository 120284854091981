import { useQuery } from "@apollo/client";
import { GridInputs } from "../../../../../components/Functional";
import { FETCH_WAREHOUSE_SITES } from "../../../../../graphql/query/WarehouseSite";
import { FETCH_WAREHOUSE_LOCATIONS } from "../../../../../graphql/query/WarehouseLocation";
import { useEffect } from "react";

const Location = props => {
  const { formik } = props || {};
  const { data: { warehouseSites = [] } = {} } = useQuery(
    FETCH_WAREHOUSE_SITES,
  );
  // Need to show all locations above 0 id
  const { data: { warehouseLocations = [] } = {} } = useQuery(
    FETCH_WAREHOUSE_LOCATIONS,
    {
      variables: {
        input: {
          siteId: {
            value: 0,
            operator: "gt",
          },
        },
      },
    },
  );

  useEffect(() => {
    if (formik?.values?._unlocated) {
      formik.setFieldValue("_warehouseMove.locationId", {
        value: null,
        operator: "eq",
      });
    }
  }, [formik?.values?._unlocated]);

  useEffect(() => {
    if (
      formik?.values?.snapshot &&
      formik?.values?._warehouseMove?.date?.value
    ) {
      formik.setFieldValue("_warehouseMove.date", {
        value: new Date(
          formik?.values?._warehouseMove?.date?.value,
        ).toISOString(), // prevents auto timezone conversion
        operator: "gte",
      });
    } else {
      if (formik?.values?._warehouseMove?.date) {
        formik.setFieldValue("_warehouseMove.date", {
          value: new Date(
            formik?.values?._warehouseMove?.date?.value,
          ).toISOString(), // prevents auto timezone conversion
          operator: "eq",
        });
      } else {
        formik.setFieldValue("_warehouseMove.date", null);
      }
    }
  }, [formik?.values?.snapshot, formik?.values?._warehouseMove?.date?.value]);

  // To disable movement tab
  useEffect(() => {
    if (
      formik?.values?._warehouseMove?._warehouseLocation?.description ||
      formik?.values?._unlocated
    ) {
      formik.setFieldValue("_locationTabActive", true);
    }
  }, [
    formik?.values?._warehouseMove?._warehouseLocation?.description,
    formik?.values?._unlocated,
  ]);

  const inputs = {
    className: "grid grid-cols-4 gap-16",
    inputs: [
      {
        className: "flex flex-col gap-4",
        inputs: [
          {
            label: "Site",
            disabled: formik?.values?._movementTabActive,
            name: "warehouseMoveSiteId",
            type: "multi-select",
            options: warehouseSites?.map(item => ({
              label: item.name,
              value: item.id,
            })),
          },
        ],
      },
      {
        className: "flex flex-col gap-4",
        inputs: [
          {
            label: "Location",
            disabled: formik?.values?._movementTabActive,
            name: "warehouseMoveLocationId",
            type: "multi-select",
            options: warehouseLocations?.map(item => ({
              label: item.description,
              value: item.id,
            })),
          },
          {
            label: "Location Description",
            disabled: formik?.values?._movementTabActive,
            name: "_warehouseMove._warehouseLocation.description",
            type: "text",
          },
        ],
      },
      {
        className: "flex flex-col gap-4",
        inputs: [
          {
            label: "Date",
            disabled: formik?.values?._movementTabActive,
            name: "_warehouseMove.date.value",
            type: "date",
            postLabelInput: {
              designType: "checkbox",
              label: "Snap Shot",
              name: "snapshot",
              type: "toggle",
            },
          },
        ],
      },
      {
        className: "flex flex-col gap-4",
        inputs: [
          {
            disabled: formik?.values?._movementTabActive,
            label: "Unlocated",
            name: "_unlocated",
            type: "toggle",
            designType: "threeStateCheckbox",
          },
        ],
      },
    ],
  };

  const gridInputProps = {
    formik,
    ...inputs,
  };

  return <GridInputs {...gridInputProps} />;
};

export default Location;
