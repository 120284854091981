import { GridInputs } from "../../../../../components/Functional";

const Auctions = props => {
  const { formik } = props || {};

  const inputs = {
    className: "grid grid-cols-4 gap-4",
    inputs: [
      {
        label: "Auctioneer",
        name: "_instanceAuction.auctioneer",
        type: "text",
      },
      {
        label: "Date From",
        name: "_instanceAuction.saleDate.value.from",
        type: "date",
      },
      {
        label: "Date To",
        name: "_instanceAuction.saleDate.value.from",
        type: "date",
      },
      { label: "Event", name: "_instanceAuction.event", type: "text" },
      { label: "Sale Ref", name: "_instanceAuction.saleRef", type: "text" },
      { label: "City", name: "_instanceAuction.city", type: "text" },
      { label: "Lot", name: "_instanceAuction.lot", type: "text" },
      { label: "Bid", name: "_instanceAuction.hammer", type: "number" },
      { label: "Notes", name: "_instanceAuction.notes", type: "text" },
    ],
  };

  const gridInputProps = {
    formik,
    ...inputs,
  };

  return <GridInputs {...gridInputProps} />;
};

export default Auctions;
