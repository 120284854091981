import React from "react";

const Query = props => {
  const { shipmentsV2 } = props || {};
  const { sqlQuery } = shipmentsV2 || {};

  return <div>{sqlQuery}</div>;
};

export default Query;
