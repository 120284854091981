import { gql } from "@apollo/client";
import { InvoiceSageCode } from "../fragments";

const FETCH_INVOICE_SAGECODES = gql`
  query FetchInvoiceSageCodes {
    invoiceSageCodes {
      ${InvoiceSageCode}  
    }
  }
`;

export { FETCH_INVOICE_SAGECODES };
