import { gql } from "@apollo/client";
import { InvoiceType } from "../fragments";

const FETCH_INVOICE_TYPES = gql`
  query FetchInvoiceTypes {
    invoiceTypes {
      ${InvoiceType}
    }
  }
`;

export { FETCH_INVOICE_TYPES };
