import { useQuery } from "@apollo/client";
import { GridInputs } from "../../../../../../components/Functional";
import { FETCH_FOCUSES } from "../../../../../../graphql/query/Focus";

const FocusTags = props => {
  const { formik } = props || {};
  const { data: { focuses } = {} } = useQuery(FETCH_FOCUSES);

  const inputs = {
    className: "grid grid-cols-2 gap-16",
    inputs: [
      {
        isMulti: true,
        label: "Choose Focus / Tags",
        name: "focusId",
        options: focuses?.map(focus => ({
          label: focus.description,
          value: focus.id,
        })),
        type: "multi-select",
      },
    ],
  };

  const gridInputProps = {
    formik,
    ...inputs,
  };

  return <GridInputs {...gridInputProps} />;
};

export default FocusTags;
