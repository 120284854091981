const exportModule = {
  name: "Invoice",
  sections: [
    {
      fields: [
        { label: "Invoiced From", name: "Company.name" },
        { label: "Date", name: "date" },
        { label: "Payment Due", name: "dueDate" },
        { label: "Analysis Code", name: "sagecode" },
        { label: "Sales Person", name: "SalesUser.name" },
        { label: "Id", name: "id" },
        { label: "Client", name: "Contact.name" },
        { label: "Region", name: "Country.sagecode" },
        { label: "Continent", name: "Country.continent" },
        { label: "Country", name: "Country.name" },
        { label: "State / County", name: "county" },
        { label: "Invoice #", name: "number" },
        { label: "Proforma #", name: "proforma" },
        { label: "Type", name: "InvoiceType.description" },
        { label: "Currency", name: "Currency.code" },
        { label: "Tax Area", name: "TaxArea.name" },
        { label: "Tax Code", name: "TaxArea.sageCode" },
        { label: "Approved", name: "approved" },
        { label: "Charge Storage", name: "chargeStorage" },
      ],
      label: "",
      name: "invoice",
    },
    {
      fields: [
        { label: "Amount", name: "netAmount" },
        { label: "Notes", name: "notes" },
        { label: "Terms", name: "terms" },
        { label: "Unpaid", name: "unpaid" },
      ],
      label: "Values",
      name: "invoice",
    },
    {
      fields: [
        { label: "Art Advisor", name: "artAdvisorId" },
        { label: "Channel", name: "InvoiceChannel.description" },
        { label: "Analysis", name: "InvoiceAnalysisEvent.description" },
        { label: "Event", name: "analysisEventId" },
        { label: "Destination", name: "destinationId" },
      ],
      label: "Advisor",
      name: "advisor",
    },
    {
      fields: [
        { label: "Id", name: "registrarId" },
        { label: "Date Proof of Export", name: "proofdate" },
        { label: "On Hold", name: "deliveryHold" },
      ],
      label: "Registrar",
      name: "registrar",
    },
  ],
};

export default exportModule;
