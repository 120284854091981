import { useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import { useEffect, useRef } from "react";
import { Route, Routes as RouterRoutes, useParams } from "react-router-dom";

import routes from "./routes";
import { getChangedFields, Portal } from "../../../../util";
import DetailBar from "./components/DetailBar";
import AuthRoute from "../../../../components/AuthRoute";
import { NavBar, Spinner } from "../../../../components/ui";
import { FETCH_INSTANCE as fetchInstance } from "../../../../graphql/query/Instance";
import { UPDATE_INSTANCE as updateInstanceMutation } from "../../../../graphql/mutation/Instance";

const Body = props => {
  const { loading, ...remainingProps } = props || {};

  return loading ? (
    <div className="h-100% flex w-full items-center justify-center">
      <Spinner />
    </div>
  ) : (
    <RouterRoutes>
      {routes
        ?.filter?.(item => item?.element)
        ?.map((item, index) => {
          return (
            <Route
              key={index}
              path={item?.href}
              element={
                <AuthRoute permissions={item.permissions}>
                  <item.element {...remainingProps} />
                </AuthRoute>
              }
            />
          );
        })}
    </RouterRoutes>
  );
};

const Wizard = props => {
  const abortController = useRef();
  const { id: instanceId } = useParams();
  const { data: { instance } = {}, loading } = useQuery(
    fetchInstance({
      company: {},
      condition: { status: true },
      edition: { artwork: { artists: {}, images: { imgT: {}, imgX: {} } } },
      isBond: true,
      isGuaranteed: true,
      isOnLoan: true,
      isSecondaryMarket: true,
      weight: {},
    }),
    { skip: !instanceId, variables: { id: +instanceId } },
  );

  const [updateInstance] = useMutation(
    updateInstanceMutation({
      company: {},
      condition: { status: true },
      edition: { artwork: { artists: {}, images: { imgT: {}, imgX: {} } } },
      isBond: true,
      isGuaranteed: true,
      isOnLoan: true,
      isSecondaryMarket: true,
      weight: {},
    }),
  );

  const formik = useFormik({
    initialValues: instance,
    enableReinitialize: true,
    onSubmit: values => {
      const changedFields = getChangedFields(values, instance);

      if (Object.keys(changedFields).length > 0) {
        const controller = new AbortController();
        abortController.current = controller;

        updateInstance({
          variables: {
            input: {
              id: +instanceId,
              ...changedFields,
            },
          },
          context: {
            fetchOptions: {
              signal: controller.signal,
            },
          },
        });
      }
    },
  });

  useEffect(() => {
    if (formik?.values && abortController?.current) {
      abortController.current.abort();
    }
  }, [formik?.values]);

  const bodyProps = {
    ...props,
    formik,
    instance,
    instanceId: +instanceId,
    loading,
  };

  return (
    <div className="relative flex min-h-full">
      <Portal id="sidebar">
        <NavBar
          navigation={routes?.filter?.(item => item?.icon)}
          open={props?.open}
        />
      </Portal>
      <DetailBar {...bodyProps} />
      <Body {...bodyProps} />
    </div>
  );
};

export default Wizard;
