import { Button, getInput } from "../../../../../../../components/ui";
import { useFormik } from "formik";
import { useMutation } from "@apollo/client";
import {
  CREATE_INSTANCE_AUCTION,
  UPDATE_INSTANCE_AUCTION,
} from "../../../../../../../graphql/mutation/InstanceAuction";
import * as Yup from "yup";
import { useEffect } from "react";

const EditAuction = props => {
  const { auction, closeModal, contact, isOpen } = props;
  const [updateInstanceAuction, { loading: updateLoading }] = useMutation(
    UPDATE_INSTANCE_AUCTION,
  );
  const [createInstanceAuction, { loading: createLoading }] = useMutation(
    CREATE_INSTANCE_AUCTION,
    { refetchQueries: ["FetchInstanceAuctions"] },
  );

  const loading = updateLoading || createLoading;

  const handleClick = () => {
    const {
      id,
      auctioneer,
      city,
      event,
      hammer,
      lot,
      notes,
      saleRef,
      saleDate,
    } = formik.values;
    (auction
      ? updateInstanceAuction({
          variables: {
            input: {
              id,
              auctioneer,
              city,
              event,
              hammer,
              lot,
              notes,
              saleRef,
              saleDate,
            },
          },
        })
      : createInstanceAuction({
          variables: {
            input: {
              auctioneer,
              city,
              contactId: contact?.id,
              event,
              hammer,
              lot,
              notes,
              saleRef,
              saleDate,
            },
          },
        })
    ).then(resp => {
      const {
        data: {
          createInstanceAuction: { success } = {},
          updateInstanceAuction: { success: updateSuccess } = {},
        },
      } = resp || {};
      if (success || updateSuccess) {
        closeModal();
      }
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...auction,
    },
    validationSchema: Yup.object({
      auctioneer: Yup.string().required("Required"),
      city: Yup.string().required("Required"),
      event: Yup.string().required("Required"),
      hammer: Yup.number().required("Required"),
      lot: Yup.string().required("Required"),
      saleDate: Yup.date().required("Required"),
      saleRef: Yup.string().required("Required"),
    }),
    onSubmit: handleClick,
  });

  const rightInputfields = [
    { label: "Auctioneer", name: "auctioneer", type: "text" },
    { label: "Event", name: "event", type: "text" },
    { label: "Sale Ref", name: "saleRef", type: "text" },
    { label: "City", name: "city", type: "text" },
    { label: "Sale Date", name: "saleDate", type: "date" },
  ];

  const leftInputFields = [
    { label: "Lot", name: "lot", type: "text" },
    { label: "Bid", name: "hammer", type: "number" },
    { label: "Notes", name: "notes", type: "text" },
  ];

  useEffect(() => {
    formik?.resetForm();
  }, [isOpen]);

  return (
    <div className="flex w-full flex-col p-8">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          {auction ? "Update" : "Create"} Auction Record for {contact?.name}
        </div>
        <div className="flex flex-row">
          <Button
            action="default"
            className="mr-8"
            label="Cancel"
            onClick={() => {
              closeModal();
            }}
          />
          <Button
            label={
              loading
                ? auction
                  ? "Updating"
                  : "Creating"
                : auction
                  ? "Update Record"
                  : "Create Record"
            }
            disabled={loading}
            onClick={formik?.submitForm}
          />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-x-10 pb-10 pt-20">
        <div className="grid grid-cols-1 gap-y-4">
          {rightInputfields?.map((item, index) => {
            const inputProps = {
              ...item,
              formik,
            };
            return (
              <div key={index} className={`w-full ${item?.className}`}>
                {getInput(inputProps)}
              </div>
            );
          })}
        </div>
        <div className="grid w-full grid-cols-1 grid-rows-5 gap-x-20 gap-y-4">
          {leftInputFields?.map((item, index) => {
            const inputProps = {
              ...item,
              formik,
            };
            return (
              <div key={index} className={`w-full ${item?.className}`}>
                {getInput(inputProps)}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default EditAuction;
