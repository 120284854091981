import { ApolloClient, InMemoryCache, from, fromPromise } from "@apollo/client";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_URI
    ? process.env.REACT_APP_GRAPHQL_URI
    : "http://localhost:4000/graphql",
});

const getErrorLink = props =>
  onError(({ forward, graphQLErrors, networkError, operation }) => {
    const { requestAccessToken } = props || {};
    if (graphQLErrors)
      for (const error of graphQLErrors) {
        const { message, extensions } = error || {};
        if (message === "Unauthorized") {
          const event = new CustomEvent("Error:Unauthorized", {
            detail: {
              permissions: extensions?.permissions || [],
            },
          });
          window.dispatchEvent(event);
        } else {
          const event = new CustomEvent("Error:GraphQLError", {
            detail: {
              message: message,
            },
          });
          window.dispatchEvent(event);
        }
        if (message === "Context creation failed: InvalidToken") {
          return fromPromise(requestAccessToken?.())
            .filter(value => Boolean(value))
            .flatMap(() => {
              return forward(operation);
            });
        }
      }
    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("user:token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const getClient = props => {
  const errorLink = getErrorLink(props);
  return new ApolloClient({
    link: from([errorLink, authLink.concat(httpLink)]),
    cache: new InMemoryCache(),
  });
};

export default getClient;
