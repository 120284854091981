import { gql } from "@apollo/client";
import { EventGuest as eventGuest } from "../fragments";

const FETCH_EVENT_GUESTS = props => gql`
  query FetchEventGuests($input: EventGuestFilterInput!) {
    eventGuests(input: $input) {
      count
      edges {
        ${eventGuest(props)}
      }
    }
  }
`;

export { FETCH_EVENT_GUESTS };
