import { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { Button, Input, getInput } from "../../../components/ui";
import { CREATE_SHIPMENT as createShipmentMutation } from "../../../graphql/mutation/Shipment";
import { FETCH_CURRENCIES } from "../../../graphql/query/Currency";
import { FETCH_CONTACT as fetchContactQuery } from "../../../graphql/query/Contact";
import { currentLocaleISODate } from "../../../util";
import useUser from "../../../hooks/useUser";

const AddShipment = props => {
  const { closeModal } = props || {};
  const navigate = useNavigate();
  const { hasPermission } = useUser();
  const [createShipment, { loading }] = useMutation(createShipmentMutation());
  const [fetchCurrencies, { data: { currencies = [] } = {} }] =
    useLazyQuery(FETCH_CURRENCIES);
  const [fetchContact] = useLazyQuery(fetchContactQuery());
  const [companyNames, setCompanyNames] = useState({
    consignerCompany: "",
    consigneeCompany: "",
  });

  const isAllowedToViewCurrency = hasPermission("READ_CURRENCY");

  const formik = useFormik({
    initialValues: {
      date: currentLocaleISODate(),
    },
    validationSchema: Yup.object({
      date: Yup.date().required("required"),
      jobId: Yup.number().nullable().integer(),
    }),
    onSubmit: async values => {
      createShipment({
        variables: {
          input: {
            ...values,
          },
        },
      }).then(resp => {
        const {
          data: { createShipment: { success, shipment } = {} },
        } = resp || {};
        if (success) {
          formik.resetForm();
          setCompanyNames({ consignerCompany: "", consigneeCompany: "" });
          closeModal();
          navigate(`/shipments/${shipment?.id}/details`);
        }
      });
    },
  });

  useEffect(() => {
    if (isAllowedToViewCurrency) {
      fetchCurrencies();
    }
  }, [isAllowedToViewCurrency]);

  // fetch company when consigner or consignee is selected
  useEffect(() => {
    if (formik?.values?.consignorId) {
      fetchContact({ variables: { id: formik?.values?.consignorId } }).then(
        ({ data: { contact } }) => {
          setCompanyNames(prevState => ({
            ...prevState,
            consignerCompany: contact?.company,
          }));
        },
      );
    }
    if (formik?.values?.consigneeId) {
      fetchContact({ variables: { id: formik?.values?.consigneeId } }).then(
        ({ data: { contact } }) => {
          setCompanyNames(prevState => ({
            ...prevState,
            consigneeCompany: contact?.company,
          }));
        },
      );
    }
  }, [formik?.values?.consignorId, formik?.values?.consigneeId]);

  const inputs = [
    { label: "Date - Filled", name: "date", type: "date" },
    {
      label: "Shipper",
      name: "shipperId",
      type: "contactSearch",
      fetchType: "shipper",
    },
    {
      label: "Currency",
      name: "currencyId",
      type: "multi-select",
      options: currencies?.map(currency => ({
        label: `${currency?.description} (${currency?.code})`,
        value: currency?.id,
      })),
    },
    { label: "Shipping Ref", name: "shippingRef", type: "text" },
    { label: "Job Reference", name: "jobId", type: "number", allowEmpty: true },
  ];

  const consignerInput = {
    label: "Consigner",
    name: "consignorId",
    type: "contactSearch",
    showType: true,
  };
  const consigneeInput = {
    label: "Consignee",
    name: "consigneeId",
    type: "contactSearch",
    showType: true,
  };

  const hasErrors = Object.keys(formik?.errors || {}).length > 0;

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          <div>Create New Shipment Record</div>
        </div>
        <div className="flex gap-4">
          <Button action="default" label="Cancel" onClick={closeModal} />
          <Button
            label={"Create"}
            loading={loading}
            disabled={loading || hasErrors}
            onClick={formik.submitForm}
          />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div className="grid grid-cols-2 gap-4">
          {inputs?.map((item, index) => {
            const inputProps = {
              ...item,
              formik,
            };
            return <div key={index}>{getInput(inputProps)}</div>;
          })}
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col">
            <div className="rounded border border-black p-4">
              {getInput({ ...consignerInput, formik })}
              <Input
                type="text"
                label="Company"
                className="mt-4"
                value={companyNames?.consignerCompany}
                disabled={true}
              />
            </div>
          </div>
          <div className="flex flex-col">
            <div className="rounded border border-black p-4">
              {getInput({ ...consigneeInput, formik })}
              <Input
                type="text"
                label="Company"
                className="mt-4"
                value={companyNames?.consigneeCompany}
                disabled={true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddShipment;
