import useUser from "../../../../../../../../../hooks/useUser";
import InstanceInputRender from "./InstanceInputRender";

const InstanceDetailCol4 = ({ formik, isDisabled, submitOnBlur = false }) => {
  const { hasPermission } = useUser();

  const inputs = [
    {
      label: "Control Ref",
      row: [
        {
          label: "Control Ref",
          name: "company.code",
          type: "text",
          disabled: true,
        },
        { label: "", name: "ref", type: "text", disabled: true },
        { label: "", name: "name", type: "text", disabled: true },
      ],
    },
    {
      label: "Artists Ref",
      name: "artistRef",
      type: "text",
      disabled: !hasPermission("UPDATE_INSTANCE_ARTIST_REF"),
      submitOnBlur,
    },
    {
      label: "Image Link",
      name: "edition.artwork.photoLink",
      type: "text",
      disabled: isDisabled,
      submitOnBlur,
    },
    {
      label: "Video Link",
      name: "edition.artwork.videoLink",
      type: "text",
      disabled: isDisabled,
      submitOnBlur,
    },
    {
      label: "Ecommerce",
      name: "channelEcommerce",
      type: "select",
      options: [
        { label: "True", value: 1 },
        { label: "False", value: 0 },
      ],
      disabled: !hasPermission("UPDATE_INSTANCE_CHANNEL_ECOMMERCE"),
      submitOnBlur,
    },
    {
      label: "Presentation",
      name: "channelPresentation",
      type: "select",
      options: [
        { label: "None", value: 0 },
        { label: "Public", value: 1 },
        { label: "Private", value: 2 },
      ],
      disabled: !hasPermission("UPDATE_INSTANCE_CHANNEL_PRESENTATION"),
      submitOnBlur,
    },
    {
      singleCheckbox: true,
      name: "artsy",
      type: "checkbox",
      options: [{ label: "Artsy", value: -1 }],
      disabled: isDisabled,
      submitOnBlur,
    },
    {
      singleCheckbox: true,
      name: "ipad",
      type: "checkbox",
      options: [{ label: "Views", value: -1 }],
      disabled: isDisabled,
      submitOnBlur,
    },
    { name: "id", label: "Id", disabled: true, submitOnBlur },
    {
      name: "createdAt",
      label: "Date / Time Created",
      type: "date",
      disabled: true,
      submitOnBlur,
    },
  ];

  return (
    <div className={`grid h-fit grid-cols-1 gap-4`}>
      <InstanceInputRender inputs={inputs} formik={formik} />
    </div>
  );
};

export default InstanceDetailCol4;
