import { useQuery } from "@apollo/client";

import { Table } from "../../../../../../components/Functional";
import { SectionHeader } from "../../../../../../components/ui/Header";
import AddCost from "./components/AddCost";

import { FETCH_FABRICATIONS_V2 as fetchFabricationsV2 } from "../../../../../../graphql/query/Fabrication";
import { UPDATE_FABRICATION as updateFabricationMutation } from "../../../../../../graphql/mutation/Fabrication";
import { FETCH_CURRENCIES } from "../../../../../../graphql/query/Currency";

const Costs = props => {
  const { jobId } = props;

  const { data: { currencies = [] } = {} } = useQuery(FETCH_CURRENCIES);

  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "fabricationsV2",
    headers: [
      { label: "Date", name: "date", type: "input", inputType: "date" },
      {
        label: "Order #",
        name: "po",
        type: "input",
        inputType: "text",
        rowClass: "w-[160px]",
      },
      {
        label: "Invoice #",
        name: "invoice",
        type: "input",
        inputType: "text",
        rowClass: "w-[160px]",
      },
      {
        label: "Description",
        name: "description",
        type: "input",
        inputType: "textarea",
      },
      {
        type: "input",
        label: "Currency",
        name: "currencyId",
        options: currencies?.map(item => ({
          value: item?.id,
          label: item?.code,
        })),
        inputType: "multi-select",
      },
      {
        label: "Net Amount",
        name: "amount",
        type: "input",
        inputType: "number-currency",
        allowNegativeInput: false,
        rowClass: "w-[160px]",
      },
      {
        label: "Tax",
        name: "tax",
        type: "input",
        inputType: "number-currency",
        allowNegativeInput: false,
        rowClass: "w-[160px]",
      },
      {
        label: "Recharge",
        name: "recharge",
        type: "input",
        inputType: "number-currency",
        allowNegativeInput: false,
        rowClass: "w-[160px]",
      },
      {
        label: "Allocation",
        name: "instanceId",
        type: "input",
        inputType: "instanceSearch",
      },
    ],
    inputs: [],
    FETCH_QUERY: fetchFabricationsV2(),
    UPDATE_MUTATION: updateFabricationMutation(),
    variables: {
      jobId,
    },
  };

  const actions = [
    {
      component: () => <AddCost jobId={jobId} />,
    },
  ];

  return (
    <div className="w-full">
      <SectionHeader title="Costs" actions={actions} />
      <div className="mb-6 w-full">
        <Table {...tableProps} />
      </div>
    </div>
  );
};
export default Costs;
