import { useEffect, useState, Fragment } from "react";
import * as Yup from "yup";
import { useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import {
  useLocation,
  useNavigate,
  Routes as RouterRoutes,
  Route,
} from "react-router-dom";
import { Breadcrumb, Button, Modal, Spinner } from "../../../../components/ui";
import ExportPDFModal from "../../../../components/Modals/ExportPDFModal";
import Page from "../CreatePDF/components/Page";
import Title from "./steps/Title";
import Search from "./steps/Search";
import AddPage from "./steps/AddPage";
import EditPage from "./steps/EditPage";
import SelectImages from "./steps/SelectImages";
import { FETCH_INSTANCES as fetchInstances } from "../../../../graphql/query/Instance";
import PageTemplate from "./steps/PageTemplate";
import { useGetQueryParams } from "../../../../hooks/queryParams";
import {
  CREATE_DOCUMENT as createDocumentMutation,
  UPDATE_DOCUMENT as updateDocumentMutation,
} from "../../../../graphql/mutation/Document";
import { FETCH_DOCUMENT as fetchDocument } from "../../../../graphql/query/Document";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import SelectedImages from "./steps/SelectedImages";
import DraggablePageTemplate from "./steps/stepsSharedComponents/DraggablePageTemplate";
import {
  ASSIGN_INSTANCE_DOCUMENT_PAGE,
  UPDATE_DOCUMENT_PAGE_TEMPLATE,
} from "../../../../graphql/mutation/DocumentPage";

const steps = [
  { label: "title", id: "title" },
  { label: "search & select", id: "select-images" },
  { label: "format pages", id: "page-template" },
];

const routes = [
  {
    path: "title",
    element: Title,
  },
  {
    path: "select-images",
    element: SelectImages,
  },
  {
    path: "page-template",
    element: PageTemplate,
  },
  {
    path: "add-page",
    element: AddPage,
  },
  {
    path: "edit-page",
    element: EditPage,
  },
];

const Wizard = () => {
  const [open, setOpen] = useState(true);
  const location = useLocation();
  const queryParams = useGetQueryParams();
  const documentId = queryParams.get("document");
  const navigate = useNavigate();
  const limit = 40;
  const [variables, setVariables] = useState({ limit, offset: 0 });
  const [pagesTemplate, setPagesTemplate] = useState([]);
  const [isLoadingPageUpdate, setIsLoadingPageUpdate] = useState(false);
  const handleSetPagesTemplate = (pageId, templateId) => {
    if (!pagesTemplate.length) {
      return setPagesTemplate([{ id: pageId, templateId }]);
    }
    if (pagesTemplate.find(item => item.id === pageId)) {
      return setPagesTemplate(
        pagesTemplate.map(item => {
          if (item.id === pageId) {
            return { ...item, templateId };
          }
          return item;
        }),
      );
    }
    return setPagesTemplate([...pagesTemplate, { id: pageId, templateId }]);
  };
  const hasSearchValue =
    variables.companyId ||
    variables.medium ||
    variables.search ||
    variables.title ||
    variables.statusId ||
    variables.artistName ||
    variables.year ||
    variables.statusId === null ||
    variables.title === "";
  const [createDocument, { client, loading: onCreateLoading }] = useMutation(
    createDocumentMutation(),
  );
  const {
    loading: loadingGetDocument,
    data: { document = {} } = {},
    refetch,
  } = useQuery(fetchDocument({ fieldsData: {}, pages: {} }), {
    variables: { id: +documentId },
    skip: !documentId,
  });
  const [updateDocumentPageTemplate] = useMutation(
    UPDATE_DOCUMENT_PAGE_TEMPLATE,
  );
  const [updateDocument, { loading: isLoadingUpdate }] = useMutation(
    updateDocumentMutation({ fieldsData: {}, pages: {} }),
  );
  const [assignInstanceDocumentPage, { loading: isLoadingAssignInstance }] =
    useMutation(ASSIGN_INSTANCE_DOCUMENT_PAGE);
  const { data, loading } = useQuery(
    fetchInstances({
      edition: {
        artwork: { imagesSummary: { artwork: { artists: {} }, imgT: {} } },
      },
    }),
    {
      skip: !hasSearchValue,
      variables: {
        input: {
          ...variables,
          statusId: variables?.statusId
            ?.map(item => item.value)
            .filter(item => !!item),
        },
      },
    },
  );
  const savePagesTemplate = () => {
    setIsLoadingPageUpdate(true);
    updateDocumentPageTemplate({
      variables: {
        input: pagesTemplate,
      },
    }).then(() => {
      refetch();
      setIsLoadingPageUpdate(false);
    });
  };
  const step = location?.pathname.split("/")[2];
  const currentStepIndex = steps.findIndex(item => item.id === step);
  const initialValues = {
    title: "",
  };
  const searchInitialValues = {
    title: "",
  };
  const formik = useFormik({
    initialValues,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: Yup.object({
      title: Yup.string().trim().required("Please enter a title."),
    }),
  });
  const searchFormik = useFormik({
    initialValues: searchInitialValues,
  });

  const onSubmit = values => {
    const newValues = Object.keys(values)
      .filter(item => !!values[item])
      .reduce((acc, item) => {
        acc[item] = values[item];
        return acc;
      }, {});
    if (Object.keys(newValues).length === 0) {
      newValues.title = "";
    }
    setVariables({
      limit: variables.limit,
      offset: 0,
      ...newValues,
    });
  };

  const goToStep = stepIndex => {
    if (Object.keys(formik.errors).length) {
      formik.setStatus(true);
      return null;
    }
    if (stepIndex < 0 || stepIndex >= steps.length) return;
    const { path } =
      routes.find(item => item.path === steps[stepIndex].id) || {};
    if (step === "title" && stepIndex === 1) {
      const action = !documentId ? createDocument : updateDocument;
      const body = !documentId
        ? { meta: null, templateId: null, title: formik.values?.title }
        : { id: +documentId, title: formik.values?.title };
      action({
        variables: {
          input: body,
        },
      }).then(resp => {
        const { data } = resp || {};
        const response = data.createDocument || data.updateDocument;
        if (response.success) {
          client.resetStore();
          navigate(`${path}?document=${response.document?.id}`);
        }
      });
    } else {
      navigate(`${path}?document=${documentId}`);
    }
  };

  const previewModalProps = {
    title: "Document Preview",
    description: "This is how your pdf will look like once exported",
    body: () => {
      return (
        <div className="flex flex-1 flex-col overflow-hidden">
          <div className="flex justify-end">
            <ExportPDFModal id={documentId}>
              <Button className="mr-4" label="Download" id={documentId} />
            </ExportPDFModal>
          </div>
          <div className="mt-6 overflow-auto text-center">
            <div className="inline-block">
              {document?.pages?.map(item => (
                <Page key={item?.id} {...item} height={890} width={630} />
              ))}
            </div>
          </div>
        </div>
      );
    },
  };

  const refetchDocument = () => {
    refetch({ id: +documentId });
  };

  const setSelectedImages = instances => {
    const newMeta = {
      ...document?.meta,
      instances,
    };
    if (newMeta.bulkUpdatedPage) {
      delete newMeta.bulkUpdatedPage;
    }
    if (newMeta.templateId) {
      delete newMeta.templateId;
    }
    updateDocument({
      variables: {
        input: {
          id: +documentId,
          meta: newMeta,
        },
      },
    }).then(resp => {
      // refetchDocument();
    });
  };

  useEffect(() => {
    if (documentId && !loadingGetDocument && document?.id) {
      formik.setFieldValue("title", document.title);
    }
  }, [document]);

  const elementProps = {
    formik: formik,
    searchFormik: searchFormik,
    onSubmit: onSubmit,
    instances: data?.instances,
    setVariables: setVariables,
    variables: variables,
    limit: variables.limit,
    setSelected: setSelectedImages,
    isLoading: loading,
    isLoadingUpdate,
    isLoadingAssignInstance,
    document: document,
    refetchDocument: refetchDocument,
    handleSetPagesTemplate: handleSetPagesTemplate,
  };

  const asideComponents = [
    {
      path: "search | select-images",
      element: (
        <>
          <div className="flex w-full items-center justify-between px-10 pt-8">
            <div className="text-lg font-bold">Search</div>
            <div className="cursor-pointer" onClick={() => setOpen(false)}>
              <ChevronLeftIcon className="h-[24px] w-[24px] stroke-black" />
            </div>
          </div>
          <div className="h-[calc(100vh-110px)] overflow-auto px-10">
            <Search {...elementProps} onSubmit={onSubmit} />
          </div>
        </>
      ),
      collapseElement: (
        <div className="mt-[30px] cursor-pointer" onClick={() => setOpen(true)}>
          <div className="cursor-pointer">
            <ChevronRightIcon className="m-auto h-[24px] w-[24px] stroke-black" />
          </div>
          <div className="mt-8 rotate-[270deg] text-sm font-bold ">SEARCH</div>
        </div>
      ),
    },
    {
      path: "page-template",
      element: (
        <>
          <div className="flex w-full items-center justify-between px-10 pt-8">
            <div className="mb-4">
              <div className="text-lg font-bold">Selected Artworks</div>
              <div className="text-sm">
                Drag-and-drop to a page on the right, or the re-order.
              </div>
            </div>
            <div className="cursor-pointer" onClick={() => setOpen(false)}>
              <ChevronLeftIcon className="h-[24px] w-[24px] stroke-black" />
            </div>
          </div>
          <div className="h-[calc(100%-200px)] overflow-auto px-10">
            <SelectedImages {...elementProps} />
          </div>
        </>
      ),
      collapseElement: (
        <div
          className="relative mt-[30px] cursor-pointer"
          onClick={() => setOpen(true)}
        >
          <div className="mb-[70px] cursor-pointer">
            <ChevronRightIcon className="m-auto h-[24px] w-[24px] stroke-black" />
          </div>
          <div className="absolute left-[-48px] w-[145px] rotate-[270deg] text-sm font-bold">
            SELECTED IMAGES
          </div>
        </div>
      ),
      draggableWrapper: true,
    },
  ];

  const asideComponent =
    asideComponents.find(item => item.path.includes(step)) || null;

  const SectionWrapper = asideComponent?.draggableWrapper
    ? DraggablePageTemplate
    : Fragment;

  return (
    <SectionWrapper
      {...(asideComponent?.draggableWrapper
        ? {
            document,
            setSelected: setSelectedImages,
            updateDocument,
            assignInstanceDocumentPage,
          }
        : {})}
    >
      {isLoadingPageUpdate && (
        <div className="absolute left-0 top-0 z-40 flex h-full w-full items-center justify-center bg-gray-50/[.7]">
          <Spinner />
        </div>
      )}
      <div className="flex h-full w-full flex-row flex-nowrap">
        {asideComponent && (
          <div
            className="border-0 border-r"
            style={{
              minWidth: open ? "25%" : "50px",
              maxWidth: open ? "25%" : "50px",
              width: 100,
            }}
          >
            {open ? asideComponent.element : asideComponent.collapseElement}
          </div>
        )}
        <div className="mt-8 w-full px-8">
          <div className="flex items-center">
            <div className="mr-[36px] font-bold">PDF Generator</div>
            <Breadcrumb
              disabled={!documentId}
              steps={steps}
              onChange={goToStep}
              currentStepIndex={currentStepIndex}
              type="tabs"
            />
          </div>
          {currentStepIndex >= 0 && (
            <>
              <div className="mb-[63px] flex flex-row justify-end">
                <Button
                  label="Cancel"
                  onClick={() => navigate("/")}
                  action="default"
                  className="mr-4"
                />
                {currentStepIndex >= 1 && (
                  <Button
                    label="Back"
                    onClick={() => goToStep(currentStepIndex - 1)}
                    action="default"
                    className="mr-4"
                  />
                )}
                {currentStepIndex !== steps.length - 1 && (
                  <Button
                    label="Next"
                    onClick={() => goToStep(currentStepIndex + 1)}
                    action="black"
                    disabled={onCreateLoading || isLoadingUpdate}
                  />
                )}
                {documentId && currentStepIndex === steps.length - 1 && (
                  <Modal {...previewModalProps}>
                    <Button action="default" className="mr-4" label="Preview" />
                  </Modal>
                )}
                {documentId && currentStepIndex === steps.length - 1 && (
                  <ExportPDFModal id={documentId}>
                    <Button
                      action="black"
                      className="mr-4"
                      label="Download"
                      id={+documentId}
                    />
                  </ExportPDFModal>
                )}
                {documentId && currentStepIndex === steps.length - 1 && (
                  <Button
                    action="black"
                    className="mr-4"
                    label="Save"
                    id={+documentId}
                    onClick={savePagesTemplate}
                  />
                )}
              </div>
            </>
          )}
          <RouterRoutes>
            {routes?.map((item, index) => (
              <Route
                key={index}
                path={item?.path}
                element={<item.element {...elementProps} />}
              />
            ))}
          </RouterRoutes>
        </div>
      </div>
    </SectionWrapper>
  );
};

export default Wizard;
