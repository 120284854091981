import contact from "./Contact";
import Currency from "./Currency";
import ValueType from "./ValueType";
import Entity from "./Entity";

const Value = ({
  authorizedBy = false,
  currency = false,
  type = false,
  user = false,
} = {}) => `
  id
  ${
    authorizedBy
      ? `authorizedBy {
      ${contact(authorizedBy)}
    }`
      : ""
  }
  authorisedId
  ${
    currency
      ? `currency {
      ${Currency}
    }`
      : ""
  }
  currencyId
  date
  maxDiscount
  notes
  ${
    type
      ? `type {
      ${ValueType}
    }`
      : ""
  }
  typeId
  ${
    user
      ? `user {
      ${Entity}
    }`
      : ""
  }
  userId
  value
  grossProfit
`;

export default Value;
