import { useMutation } from "@apollo/client";

import { Button } from "../../../../../../../components/ui";
import { REFUSE_OFFERS } from "../../../../../../../graphql/mutation/Offer";

const RefuseOfferModal = props => {
  const { closeModal, contact, values } = props;
  const [refuseOffers, { loading }] = useMutation(REFUSE_OFFERS, {
    refetchQueries: ["FetchOffers"],
  });

  const handleClick = () => {
    const { moveOffers: { ids: refuseIds, select } = {} } = values || {};

    refuseOffers({
      variables: {
        input: {
          contactId: contact?.id,
          ids: Object.keys(refuseIds || {})?.map(key => +key.replace("_", "")),
          refuseAll: select === "all",
        },
      },
    }).then(resp => {
      const { data: { refuseOffers: { success } = {} } = {} } = resp;

      if (success) {
        closeModal();
      }
    });
  };

  return (
    <div className="mt-6 flex w-full flex-col">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col">
          <div className="text-2xl font-bold">Refuse All Offers</div>
          <div className="pt-4 text-base font-normal">
            {`You are about to refuse all offers on ${contact?.name} contact record.`}
            <p>Are you sure?</p>
          </div>
        </div>
        <div className="flex flex-col">
          <div>
            <Button
              action="default"
              className="mr-4"
              label="Cancel"
              onClick={() => {
                closeModal();
              }}
            />
            <Button
              label={loading ? "Refusing" : "Refuse"}
              disabled={loading}
              onClick={handleClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RefuseOfferModal;
