import { gql } from "@apollo/client";

const DELETE_LIST_ITEMS = gql`
  mutation DeleteListItems($input: DeleteListItemsInput!) {
    deleteListItems(input: $input) {
      error
      success
    }
  }
`;

export { DELETE_LIST_ITEMS };
