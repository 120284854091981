import { useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Button, Modal } from "../../../../../../../components/ui";
import { GridInputs } from "../../../../../../../components/Functional";

import { CREATE_OFFER as createOfferMutation } from "../../../../../../../graphql/mutation/Offer";
import { FETCH_CURRENCIES } from "../../../../../../../graphql/query/Currency";
import { FETCH_ENTITIES } from "../../../../../../../graphql/query/Entity";
import { FETCH_OFFER_STATUSES } from "../../../../../../../graphql/query/OfferStatus";
import useUser from "../../../../../../../hooks/useUser";

const AddOfferModal = props => {
  const { closeModal, contact } = props;

  const { data: { currencies = [] } = {} } = useQuery(FETCH_CURRENCIES);
  const { data: { offerStatuses = [] } = {} } = useQuery(FETCH_OFFER_STATUSES);
  const { data: { entities } = {} } = useQuery(FETCH_ENTITIES);

  const [createOffer, { loading }] = useMutation(createOfferMutation(), {
    refetchQueries: ["FetchOffers"],
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      contactId: contact?.id,
    },
    validationSchema: Yup.object({
      artworkId: Yup.number().required("Please select an artwork."),
      statusId: Yup.number().required("Please select a status."),
      currencyId: Yup.number().required("Please select a currency."),
      holdId: Yup.number().positive().integer().nullable(),
      amount: Yup.number()
        .typeError("Please enter a valid amount.")
        .positive()
        .nullable(),
      tbiAmount: Yup.number()
        .typeError("Please enter a valid amount.")
        .positive()
        .nullable(),
    }),
    onSubmit: values => {
      handleAddOffer(values);
    },
  });

  const handleAddOffer = () => {
    createOffer({
      variables: {
        input: formik?.values,
      },
    }).then(resp => {
      const {
        data: { createOffer: { success } = {} },
      } = resp || {};
      if (success) {
        closeModal();
        formik?.resetForm();
      }
    });
  };

  const inputs = {
    className: "grid grid-cols-4 gap-8",
    inputs: [
      {
        className: "grid grid-cols-1 gap-4",
        inputs: [
          {
            label: "Artwork",
            name: "artworkId",
            redirect: true,
            type: "artworkSearch",
          },
          {
            label: "Status",
            name: "statusId",
            type: "multi-select",
            options: offerStatuses?.map(item => ({
              label: item?.description,
              value: item?.id,
            })),
          },
          {
            label: "Currency",
            name: "currencyId",
            type: "multi-select",
            isMulti: false,
            options: currencies?.map(currency => ({
              label: `${currency.description} (${currency.code})`,
              value: currency.id,
            })),
          },
          {
            name: "priority",
            options: [{ label: "Top Deal", value: -1 }],
            singleCheckbox: true,
            type: "checkbox",
          },
        ],
      },
      {
        className: "grid grid-cols-1 gap-4",
        inputs: [
          { label: "Amount", name: "amount", type: "number" },
          { label: "TBI Amount", name: "tbiAmount", type: "number" },
          {
            label: "Hold",
            name: "holdId",
            redirect: true,
            type: "instanceSearch",
            filterInput: [
              { statusId: { operator: "in", value: [1, 4] } },
              {
                _edition: {
                  artworkId: {
                    operator: "eq",
                    value: formik?.values?.artworkId,
                  },
                },
              },
            ],
          },
          {
            label: "User",
            name: "user",
            type: "multi-select",
            options:
              entities?.edges?.map(item => ({
                label: item.name,
                value: item.name,
              })) || [],
          },
        ],
      },
      {
        className: "grid grid-cols-1 gap-4",
        inputs: [
          {
            label: "Droite de Suite / ARR",
            name: "_",
            type: "arr",
            disabled: true,
          },
          { label: "Notes", name: "notes", rows: 5, type: "textarea" },
        ],
      },
      {
        className: "grid grid-cols-1 gap-4",
        inputs: [
          { label: "Date", name: "date", type: "date", disabled: true },
          {
            label: "Date Closed",
            name: "dateClosed",
            type: "date",
            disabled: true,
          },
          { label: "TBI Date", name: "tbiDate", type: "date", disabled: true },
          {
            label: "Hold Date",
            name: "holdDate",
            type: "date",
            disabled: true,
          },
        ],
      },
    ],
  };

  const inputProps = {
    formik,
    ...inputs,
  };

  return (
    <div className="flex w-full flex-col p-8">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">Add Offer</div>
        <div className="flex flex-row">
          <Button
            action="default"
            className="mr-8"
            label="Cancel"
            onClick={() => {
              closeModal();
              formik?.resetForm();
            }}
          />
          <Button
            label={loading ? "Adding" : "Add"}
            disabled={loading || !formik.isValid || !formik.dirty}
            onClick={formik.submitForm}
          />
        </div>
      </div>
      <div className="mt-8 pb-8">
        <GridInputs {...inputProps} />
      </div>
    </div>
  );
};

const AddOffer = props => {
  const { hasPermission } = useUser();
  const { contact } = props || {};

  const modalProps = {
    body: AddOfferModal,
    closeOnBackdrop: false,
    contact,
    hideCloseButton: false,
    scale: "lg",
    disabled: !hasPermission("UPDATE_OFFER"),
  };

  return (
    <Modal {...modalProps}>
      <Button
        label="Add Offer"
        action="black"
        disabled={!hasPermission("UPDATE_OFFER")}
      />
    </Modal>
  );
};

export default AddOffer;
