import JsZip from "jszip";
import { saveAs } from "file-saver";

const zipAndDownloadFiles = (files, fileName) => {
  return new Promise(resolve => {
    if (!files?.length) {
      resolve(false);
    }

    const zipper = new JsZip();

    files.forEach(file => {
      zipper.file(file.name, file, { binary: true });
    });

    zipper.generateAsync({ type: "blob" }).then(content => {
      saveAs(content, fileName);

      resolve(true);
    });
  });
};

export { zipAndDownloadFiles };
