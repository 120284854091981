import { gql } from "@apollo/client";
import { ReportTemplate } from "../fragments";

const CREATE_REPORT_TEMPLATE = gql`
  mutation CreateReportTemplate($input: CreateReportTemplateInput!) {
    createReportTemplate(input: $input) {
      error
      reportTemplate {
        ${ReportTemplate}
      }
      success
    }
  }
`;

const DELETE_REPORT_TEMPLATE = gql`
  mutation DeleteReportTemplate($input: DeleteReportTemplateInput!) {
    deleteReportTemplate(input: $input) {
      error
      success
    }
  }
`;

const UPDATE_REPORT_TEMPLATE = gql`
  mutation UpdateReportTemplate($input: UpdateReportTemplateInput!) {
    updateReportTemplate(input: $input) {
      error
      reportTemplate {
        ${ReportTemplate}
      }
      success
    }
  }
`;

export {
  CREATE_REPORT_TEMPLATE,
  DELETE_REPORT_TEMPLATE,
  UPDATE_REPORT_TEMPLATE,
};
