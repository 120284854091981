import { gql } from "@apollo/client";
import { ChangeLog as changeLog } from "../fragments";

const FETCH_CHANGE_LOG = props => gql`
  query FetchChangeLog($id: ID!) {
    changeLog(id: $id) {
      ${changeLog(props)}
    }
  }
`;

const FETCH_CHANGE_LOGS = props => gql`
  query FetchChangeLogs($input: ChangeLogFilterInput!) {
    changeLogs(input: $input) {
      count
      edges {
        ${changeLog(props)}
      }
    }
  }
`;

export { FETCH_CHANGE_LOG, FETCH_CHANGE_LOGS };
