import { gql } from "@apollo/client";
import { InstanceDocument } from "../fragments";

const FETCH_INSTANCE_DOCUMENTS = gql`
  query FetchInstanceDocuments($input: InstanceDocumentFilterInput!) {
    instanceDocuments(input: $input) {
      count
      edges {
        ${InstanceDocument}
      }
    }
  }
`;

export { FETCH_INSTANCE_DOCUMENTS };
