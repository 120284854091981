import { gql } from "@apollo/client";
import { WarehouseWeightType } from "../fragments";

const FETCH_WAREHOUSE_WEIGHT_TYPES = gql`
  query FetchWarehouseWeightTypes {
    warehouseWeightTypes {
      ${WarehouseWeightType}
    }
  }
`;

export { FETCH_WAREHOUSE_WEIGHT_TYPES };
