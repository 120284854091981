import { gql } from "@apollo/client";
import { ContactCatalogue as contactCatalogue } from "../fragments";

const CREATE_CONTACT_CATALOGUE = props => gql`
  mutation CreateContactCatalogue($input: CreateContactCatalogueInput!) {
    createContactCatalogue(input: $input) {
      success
      error
      contactCatalogue {
        ${contactCatalogue(props)}
      }
    }
  }
`;

const DELETE_CONTACT_CATALOGUE = gql`
  mutation DeleteContactCatalogue($input: DeleteContactCatalogueInput!) {
    deleteContactCatalogue(input: $input) {
      success
      error
    }
  }
`;

const UPDATE_CONTACT_CATALOGUE = props => gql`
  mutation UpdateContactCatalogue($input: UpdateContactCatalogueInput!) {
    updateContactCatalogue(input: $input) {
      success
      error
      contactCatalogue {
        ${contactCatalogue(props)}
      }
    }
  }
`;

export {
  CREATE_CONTACT_CATALOGUE,
  DELETE_CONTACT_CATALOGUE,
  UPDATE_CONTACT_CATALOGUE,
};
