import { Fragment } from "react";
import { Button } from "../../../../../components/ui/Button";

const SectionHeader = props => {
  const { title, actions } = props;
  return (
    <div className="flex w-full flex-row pb-10 font-bold">
      <div className="flex-1 text-2xl">{title}</div>
      <div className="flex gap-4">
        {actions?.map((item, key) => {
          return item?.component ? (
            <Fragment key={key}>{item?.component()}</Fragment>
          ) : (
            <Button
              action={item.type}
              key={key}
              className={`mr-4 ${item.className}`}
              label={item.label}
              onClick={item.action}
            />
          );
        })}
      </div>
    </div>
  );
};

export default SectionHeader;
