import { ArrowRightCircleIcon } from "@heroicons/react/24/outline";
import SearchBar from "../../Input/components/SearchBar";
import { useFormik } from "formik";
import { useQuery } from "@apollo/client";
import { FETCH_SUPPORT_ARTICLES } from "../../../../graphql/query/SupportArticle";
import { Pagination, Spinner } from "../../";
import { useLocation } from "react-router-dom";

const ArticleContent = props => {
  const { meta } = props || {};
  const { title, module, summary, documentLink } = meta || {};

  const buildUrl = () => {
    if (documentLink?.includes("http")) {
      return documentLink;
    } else {
      return `//${documentLink}`;
    }
  };

  return (
    <div
      className="mb-8 cursor-pointer"
      onClick={() => documentLink && window.open(buildUrl(), "_blank")}
    >
      <div className="text-lg font-bold">{title}</div>
      <div className="overflow mb-2">{summary}</div>
      <div className="text-xs font-bold">MODULE: {module}</div>
    </div>
  );
};

const FormLoggingSupport = props => {
  const { nextStep } = props || {};
  const LIMIT = 3;
  const location = useLocation();

  const formik = useFormik({
    initialValues: {
      limit: LIMIT,
      offset: 0,
      search: "",
    },
  });

  const {
    data: { supportArticles: { count, edges = [] } = {} } = {},
    loading,
  } = useQuery(FETCH_SUPPORT_ARTICLES, {
    variables: {
      input: {
        limit: formik?.values.limit,
        page: location?.pathname,
        offset: formik?.values.offset,
        search: formik?.values.search,
      },
    },
  });

  const searchBarProps = {
    formik,
    name: "search",
  };

  const onChange = values => {
    formik?.setValues({ ...formik?.values, ...values });
  };

  const paginationProps = {
    count,
    limit: formik?.values?.limit,
    offset: formik?.values?.offset,
    onChange,
    variant: "xs",
    customPageRange: 2,
  };

  return (
    <div className="absolute bottom-4 left-4 z-[1] h-fit w-[486px] rounded border border-black bg-white px-10 py-8 pb-[52px]">
      <div className="fontbold mb-6 text-lg font-bold">Support</div>
      <div className="pb-8">
        <SearchBar {...searchBarProps} />
      </div>
      <div className="flex h-96 min-h-full flex-col overflow-y-auto">
        {loading ? (
          <div className="flex w-full items-center justify-center">
            <Spinner />
          </div>
        ) : (
          edges?.map((item, index) => {
            return <ArticleContent key={index} {...item} />;
          })
        )}
      </div>
      <Pagination {...paginationProps} />
      <div className="flex items-center pb-8 pt-4">
        <div className="text-lg font-bold">
          Can`t find a helpful article? Submit a Ticket
        </div>
        <ArrowRightCircleIcon
          className="ml-2 h-6 w-6 cursor-pointer"
          onClick={nextStep}
        />
      </div>
    </div>
  );
};

export default FormLoggingSupport;
