import { useMutation, useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";

import Actions from "./components/Actions";

import { Table } from "../../../../components/Functional/Table";
import { Button } from "../../../../components/ui";
import { CREATE_EMAIL as createEmailMutation } from "../../../../graphql/mutation/Email";
import { FETCH_EMAILS as fetchEmails } from "../../../../graphql/query/Email";
import { FETCH_ENTITIES } from "../../../../graphql/query/Entity";

const Body = () => {
  const navigate = useNavigate();
  const [createEmail, { client }] = useMutation(createEmailMutation());
  const { data: { entities } = {} } = useQuery(FETCH_ENTITIES);
  const formik = useFormik({
    initialValues: { active: null },
  });

  const tabProps = {
    onChange: name => {
      name === "all"
        ? formik?.setValues({ status: null, offset: 0 })
        : formik?.setValues({ status: name, offset: 0 });
    },
    tabs: [
      { label: "All", name: "all" },
      { label: "Draft", name: "draft" },
      { label: "Sent", name: "sent" },
      { label: "Archived", name: "archived" },
    ],
    active: formik?.values?.status || "all",
  };

  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "emails",
    headers: [
      { label: "Title", name: "title", sortKey: "title", type: "label" },
      { label: "Sent To", name: "meta.recipient", type: "label" },
      {
        label: "Sent By",
        name: "meta.sender",
        sortKey: "meta.sender",
        type: "label",
      },
      {
        label: "Updated At",
        name: "updatedAt",
        sortKey: "updatedAt",
        type: "date",
      },
      { label: "Status", name: "status", sortKey: "status", type: "tag" },
      { label: "Action", name: "action", type: "custom", component: Actions },
    ],
    inputs: [
      { name: "title", label: "Title", type: "text" },
      {
        name: "sentBy",
        label: "Sent By",
        type: "multi-select",
        options:
          entities?.edges?.map(item => {
            return { label: item.name, value: item.name };
          }) || [],
      },
      {
        name: "status",
        label: "Status",
        type: "multi-select",
        options: [
          { label: "Draft", value: "draft" },
          { label: "Sent", value: "sent" },
          { label: "Scheduled", value: "scheduled" },
          { label: "Sending", value: "sending" },
        ],
      },
      { name: "from", label: "From", type: "date" },
      { name: "to", label: "To", type: "date" },
    ],
    FETCH_QUERY: fetchEmails(),
    tabProps,
    variables: {
      ...formik?.values,
    },
  };

  const handleCreate = () => {
    createEmail({
      variables: {
        input: {
          title: "",
        },
      },
    }).then(resp => {
      const {
        data: { createEmail: { success, email } = {} },
      } = resp || {};
      if (success) {
        // TODO: handle this through updating cache to improve performance
        client.resetStore();
        navigate(`/email/${email?.id}/content`);
      }
    });
  };

  return (
    <div className="mx-auto flex-grow py-5 sm:px-6 lg:px-8">
      <header>
        <div className="mb-8 flex flex-grow items-center justify-between px-4">
          <h1 className="font-bold">MailOuts</h1>
          <Button label="New Email" action="dark" onClick={handleCreate} />
        </div>
      </header>
      <main>
        <Table {...tableProps} />
      </main>
    </div>
  );
};

const ListView = props => {
  return (
    <div className="flex">
      <Body />
    </div>
  );
};

export default ListView;
