import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { FunnelIcon } from "@heroicons/react/24/outline";

import { getInput, IconButton } from "../../../../../components/ui";

const Filters = props => {
  const { inputs, formik, type } = props || {};

  const types = {
    simple: {
      buttonClass: "px-0",
      iconClass: "h-5 w-5",
    },
    default: {
      buttonClass:
        "border border-gray-300 shadow-sm rounded bg-white hover:bg-gray-50",
      iconClass: "h-4 w-4",
    },
  };

  const { buttonClass, iconClass } = types[type] || types.default;

  const renderInputs = (
    <div className="relative grid gap-8 bg-white p-7 lg:grid-cols-2">
      {inputs?.map((item, index) => {
        const inputProps = {
          ...item,
          formik,
        };
        return (
          <div
            className={`col-span-${item.view === "full" ? "2" : "1"}`}
            key={index}
          >
            {getInput(inputProps)}
          </div>
        );
      })}
    </div>
  );

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            className={`${open ? "" : "text-opacity-90"} ${buttonClass} mr-4 inline-flex h-full items-center px-3 focus:outline-none`}
          >
            <IconButton variant="clean" title="Filter">
              <FunnelIcon className={iconClass} />
            </IconButton>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute right-0 z-10 mt-3 w-screen max-w-sm transform px-4 sm:px-0 lg:max-w-xl">
              <div className="overflow-hidden rounded border border-black shadow-lg">
                <div className="">{renderInputs}</div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default Filters;
