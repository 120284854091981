import { gql } from "@apollo/client";
import { Focus } from "../fragments";

const FETCH_FOCUSES = gql`
  query FetchFocuses {
    focuses {
      ${Focus}  
    }
  }
`;

export { FETCH_FOCUSES };
