import { gql } from "@apollo/client";
import { WarehouseCategory } from "../fragments";

const FETCH_WAREHOUSE_CATEGORIES = gql`
  query FetchWarehouseCategories {
    warehouseCategories {
      ${WarehouseCategory}
    }
  }
`;

export { FETCH_WAREHOUSE_CATEGORIES };
