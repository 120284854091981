import { gql } from "@apollo/client";
import { UserRole } from "../fragments";

const CREATE_USER_ROLE = gql`
  mutation CreateUserRole($input: CreateUserRoleInput!) {
    createUserRole(input: $input) {
      success
      error
      userRole {
        ${UserRole}
      }
    }
  }
`;

const DELETE_USER_ROLE = gql`
  mutation DeleteUserRole($input: DeleteUserRoleInput!) {
    deleteUserRole(input: $input) {
      success
      error
    }
  }
`;

export { CREATE_USER_ROLE, DELETE_USER_ROLE };
