import { GridInputs } from "../../../../../components/Functional";

const Exhibitions = props => {
  const { formik } = props || {};

  const inputs = {
    className: "grid grid-cols-4 gap-4",
    inputs: [
      {
        label: "Title Keyword",
        name: "_edition._instance._exhibitionWork._exhibition.title",
        type: "text",
      },
      {
        isMulti: true,
        label: "Title",
        name: "_edition._instance._exhibitionWork._exhibition.id",
        type: "exhibitionSearch",
      },
    ],
  };

  const gridInputProps = {
    formik,
    ...inputs,
  };

  return <GridInputs {...gridInputProps} />;
};

export default Exhibitions;
