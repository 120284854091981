import { useLazyQuery, useQuery } from "@apollo/client";
import React from "react";
import { useNavigate } from "react-router-dom";

import { GridInputs } from "../../../../components/Functional";
import { Button } from "../../../../components/ui";

import { FETCH_COMPANIES as fetchCompanies } from "../../../../graphql/query/Company";
import {
  FETCH_CONTINENTS,
  FETCH_COUNTRIES,
  FETCH_SAGECODES,
} from "../../../../graphql/query/Country";
import { FETCH_CURRENCIES } from "../../../../graphql/query/Currency";
import { FETCH_ENTITIES } from "../../../../graphql/query/Entity";
import { FETCH_INVOICES_V2 as fetchInvoicesV2Query } from "../../../../graphql/query/Invoice";
import { FETCH_INVOICE_TYPES } from "../../../../graphql/query/InvoiceType";
import {
  FETCH_TAX_AREAS,
  FETCH_TAX_AREA_SAGE_CODES,
} from "../../../../graphql/query/TaxArea";
import { FETCH_COUNTIES } from "../../../../graphql/query/County";

import { invoiceFilterStore, useInvoiceFilters } from "../useInvoiceFilters";
import AdvisorSale from "./components/AdvisorSale";
import Query from "./components/Query";
import Registrar from "./components/Registrar";
import Sort from "./components/Sort";
import Tabs from "./components/Tabs";
import Transaction from "./components/Transaction";
import Invoice from "./components/Invoice";
import useUser from "../../../../hooks/useUser";

const InvoiceSearch = props => {
  const { hasPermission } = useUser();
  const { closeModal } = props || {};
  const [fetchInvoicesV2, { loading, data: { invoicesV2 } = {} }] =
    useLazyQuery(fetchInvoicesV2Query());
  const { data: { continents = [] } = {} } = useQuery(FETCH_CONTINENTS);
  const { data: { countries = [] } = {} } = useQuery(FETCH_COUNTRIES);
  const { data: { currencies = [] } = {} } = useQuery(FETCH_CURRENCIES);
  const { data: { entities = [] } = {} } = useQuery(FETCH_ENTITIES);
  const { data: { invoiceTypes = [] } = {} } = useQuery(FETCH_INVOICE_TYPES);
  const { data: { sagecodes = [] } = {} } = useQuery(FETCH_SAGECODES);
  const { data: { taxAreas = [] } = {} } = useQuery(FETCH_TAX_AREAS);
  const { data: { taxAreaSageCodes = [] } = {} } = useQuery(
    FETCH_TAX_AREA_SAGE_CODES,
  );
  const { data: { counties = [] } = {} } = useQuery(FETCH_COUNTIES);
  const { data: { companies = [] } = {} } = useQuery(fetchCompanies(), {
    variables: {
      input: {
        sort1: { value: "name", operator: "DESC" },
      },
    },
  });

  const navigate = useNavigate();
  const { formik } = useInvoiceFilters();
  const input = invoiceFilterStore.get();

  const inputs = {
    className: "grid grid-cols-4 gap-16 mt-5",
    inputs: [
      {
        className: "gap-4",
        inputs: [
          {
            hasOperator: true,
            isMulti: true,
            label: "Invoiced From",
            name: "companyId",
            options: companies?.edges?.map(item => ({
              label: `${item?.code ? item?.code + " |" : ""} ${item?.name || ""}`,
              value: item.id,
            })),
            type: "multi-select",
          },
          { label: "Date From", name: "date.value.from", type: "date" },
          { label: "Date To", name: "date.value.to", type: "date" },
          {
            label: "Payment Due From",
            name: "dueDate.value.from",
            type: "date",
          },
          { label: "Payment Due To", name: "dueDate.value.to", type: "date" },
          { label: "Analysis Code", name: "sagecode", type: "text" },
          {
            label: "Sales Person",
            name: "_invoiceSalesPerson.salesUserId",
            options: entities?.edges?.map(item => ({
              label: item.name,
              value: item.id,
            })),
            type: "multi-select",
          },
          {
            label: "Split",
            name: "_invoiceSalesPerson.split",
            type: "number-max-min",
            step: 0.25,
            min: 0,
            max: 1,
            hasOperator: true,
            operatorOptions: [
              { label: "Equal To", value: "eq" },
              { label: "Less Than", value: "lte" },
            ],
          },
        ],
      },
      {
        className: "grid-cols-1 gap-4",
        inputs: [
          { label: "Id", name: "id", type: "number" },
          {
            hasOperator: true,
            label: "Client",
            name: "contactId",
            type: "contactSearch",
          },
          { label: "Client Name", name: "contactName", type: "text" },
          {
            label: "Region",
            name: "_country.sagecode",
            type: "multi-select",
            options: sagecodes?.map(item => ({
              label: item?.sagecode,
              value: item?.sagecode,
            })),
          },
          {
            label: "Continent",
            name: "_country.continent",
            type: "multi-select",
            options: continents?.map(item => ({
              label: item?.continent,
              value: item?.continent,
            })),
          },
          {
            label: "Country",
            name: "countryId",
            type: "multi-select",
            options: countries?.map(item => ({
              label: item?.name,
              value: item?.id,
            })),
          },
          {
            label: "State / County",
            name: "county",
            type: "multi-select",
            options: counties?.map(item => ({
              label: `${item?.code} - ${item?.description}`,
              value: item?.code,
            })),
          },
        ],
      },
      {
        className: "grid-cols-1 gap-4",
        inputs: [
          { label: "Invoice #", name: "number", type: "number" },
          { label: "Proforma #", name: "proforma", type: "number" },
          {
            label: "Type",
            name: "typeId",
            options: invoiceTypes?.map(item => ({
              label: item.description,
              value: item.id,
            })),
            type: "multi-select",
          },
          {
            label: "Currency",
            name: "currencyId",
            options: currencies?.map(currency => ({
              label: `${currency.description} (${currency.code})`,
              value: currency.id,
            })),
            type: "multi-select",
          },
          {
            label: "Tax Area",
            name: "taxareaId",
            options: taxAreas?.map(item => ({
              label: item?.name,
              value: item?.id,
            })),
            type: "multi-select",
          },
          {
            label: "Tax Code",
            name: "_taxArea.sageCode",
            options: taxAreaSageCodes?.map(item => ({
              label: item?.sageCode,
              value: item?.id,
            })),
            type: "multi-select",
          },
        ],
      },
      {
        className: "grid-cols-1 gap-4",
        inputs: [
          {
            designType: "threeStateCheckbox",
            label: "Approved",
            name: "approved",
            type: "toggle",
            disabled: !hasPermission("UPDATE_INVOICE_SALES_NOTES"),
          },
          {
            designType: "threeStateCheckbox",
            label: "Charge Storage",
            name: "chargeStorage",
            type: "toggle",
          },
          {
            designType: "threeStateCheckbox",
            label: "New Business",
            name: "newBusiness",
            type: "toggle",
          },
        ],
      },
    ],
  };

  const inputProps = {
    formik,
    ...inputs,
  };

  const searchButtonProps = {
    action: "black",
    label: loading ? "Searching" : "Search",
    disabled: loading,
    onClick: e =>
      fetchInvoicesV2({
        variables: {
          input,
        },
      }).then(resp => {
        navigate("/invoice?status=all");
        closeModal();
      }),
  };

  const resetButtonProps = {
    action: "default",
    label: "Reset",
    onClick: formik?.resetForm,
  };

  const tabComponentProps = {
    invoicesV2,
    formik,
  };

  const tabsProps = {
    formik,
    name: "tab",
    options: [
      {
        label: "Invoice",
        value: "invoice",
        component: <Invoice {...tabComponentProps} />,
      },
      {
        label: "Transactions",
        value: "transactions",
        component: <Transaction {...tabComponentProps} />,
      },
      {
        label: "Registrar",
        value: "registrar",
        component: <Registrar {...tabComponentProps} />,
      },
      {
        label: "Advisor Sale",
        value: "advisorSale",
        component: <AdvisorSale {...tabComponentProps} />,
      },
      {
        label: "Sort",
        value: "sort",
        component: <Sort {...tabComponentProps} />,
      },
      {
        label: "Query",
        value: "query",
        component: <Query {...tabComponentProps} />,
      },
    ],
  };

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between">
        <div className="text-xl font-bold">Invoice Search</div>
        <div className="flex gap-4">
          <Button {...resetButtonProps} />
          <Button {...searchButtonProps} />
        </div>
      </div>
      <div className="flex flex-col gap-8">
        <GridInputs {...inputProps} />
        <Tabs {...tabsProps} />
      </div>
    </div>
  );
};

export default InvoiceSearch;
