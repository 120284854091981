import * as React from "react";

import { Button, Modal } from "../../../../../../../../components/ui";

import AddAddressModal from "./components/AddAddressModal";
import DetailView from "./components/DetailView";
import useUser from "../../../../../../../../hooks/useUser";

const ContactAddress = props => {
  const { contact } = props || {};
  const { contactAddresses = [] } = contact || {};
  const { hasPermission } = useUser();
  const disabled = !hasPermission("EDIT_CONTACT_ADDRESS");

  const addAddressModalProps = {
    body: AddAddressModal,
    closeOnBackdrop: true,
    contact,
    disabled,
    hideCloseButton: true,
    scale: "md",
  };

  return (
    <div className="mt-10 w-full">
      <div className="mb-6 flex flex-row justify-between">
        <div className="flex flex-row items-center">
          <h1 className="mr-4 mt-1 text-xl font-bold">Addresses</h1>
        </div>
        <Modal {...addAddressModalProps}>
          <div>
            <Button
              label="Add Address"
              action="default"
              className="ml-20"
              disabled={disabled}
            />
          </div>
        </Modal>
      </div>
      {contactAddresses?.map((item, index) => {
        const detailViewProps = {
          contactAddress: item,
          contact,
          disabled,
        };

        return (
          <div key={index} className="group relative mb-2 flex flex-row">
            <DetailView {...detailViewProps} />
          </div>
        );
      })}
    </div>
  );
};

export default ContactAddress;
