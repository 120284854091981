import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { Button, getInput } from "../../../../components/ui";
import { CREATE_INVOICE as createInvoiceMutation } from "../../../../graphql/mutation/Invoice";
import { FETCH_BANK_ACCOUNTS as fetchBankAccountsQuery } from "../../../../graphql/query/BankAccount";
import { FETCH_COMPANIES as fetchCompanies } from "../../../../graphql/query/Company";
import { FETCH_INVOICES_V2 as fetchInvoicesV2 } from "../../../../graphql/query/Invoice";
import { FETCH_INVOICE_TYPES } from "../../../../graphql/query/InvoiceType";
import { FETCH_TAX_AREAS } from "../../../../graphql/query/TaxArea";

const AddInvoice = props => {
  const { closeModal } = props || {};
  const navigate = useNavigate();
  const [createInvoice, { loading }] = useMutation(createInvoiceMutation(), {
    refetchQueries: [fetchInvoicesV2()],
  });
  const [fetchBankAccounts, { data: { bankAccounts = [] } = {} }] =
    useLazyQuery(fetchBankAccountsQuery({ currency: {} }));
  const { data: { companies = [] } = {} } = useQuery(fetchCompanies());
  // const { data: { countries = []} = {} } = useQuery(FETCH_COUNTRIES)
  const { data: { invoiceTypes = [] } = {} } = useQuery(FETCH_INVOICE_TYPES);
  const { data: { taxAreas = [] } = {} } = useQuery(FETCH_TAX_AREAS);

  const formik = useFormik({
    initialValues: {},
    validationSchema: Yup.object({
      bankId: Yup.number()
        .required("Please select a bank account")
        .typeError("Please select a bank account"),
      companyId: Yup.number()
        .required("Please select a company")
        .typeError("Please select a company"),
      contactId: Yup.number()
        .required("Please select a client")
        .typeError("Please select a client"),
      // deliveryCountryId: Yup.number().required("Please select a delivery country"),
      taxareaId: Yup.number()
        .required("Please select a tax area")
        .typeError("Please select a tax area"),
      typeId: Yup.number()
        .required("Please select a type")
        .typeError("Please select a type"),
    }),
    onSubmit: async values => {
      createInvoice({
        variables: {
          input: {
            ...values,
          },
        },
      }).then(resp => {
        const {
          data: { createInvoice: { success, invoice } = {} },
        } = resp || {};
        if (success) {
          navigate(`/invoice/${invoice?.id}/details`);
          closeModal();
          formik?.resetForm();
        }
      });
    },
  });

  // fetch accounts when company is selected
  useEffect(() => {
    if (formik?.values?.companyId) {
      fetchBankAccounts({
        variables: { input: { companyId: formik?.values?.companyId } },
      });
    }
  }, [formik?.values?.companyId]);

  const inputs = [
    {
      label: "Type",
      name: "typeId",
      options: invoiceTypes?.map(item => ({
        label: item.description,
        value: item.id,
      })),
      type: "multi-select",
    },
    {
      label: "Tax Area",
      name: "taxareaId",
      options: taxAreas?.map(item => ({ label: item?.name, value: item?.id })),
      type: "multi-select",
    },
    {
      label: "Company",
      name: "companyId",
      options: companies?.edges?.map(item => ({
        label: item.name,
        value: item.id,
      })),
      type: "multi-select",
    },
    { label: "Client", name: "contactId", type: "contactSearch" },
    // { label: 'Delivery Country', name: 'deliveryCountryId', options: countries?.map(item => ({ label: item?.name, value: item?.id })), type: "multi-select" },
    {
      label: "Bank Account",
      name: "bankId",
      options: bankAccounts?.map(item => ({
        label: `${item?.bank}, ${item?.number}, ${item?.currency?.code}`,
        value: item.id,
      })),
      type: "multi-select",
    },
  ];

  const hasErrors = Object.keys(formik?.errors || {}).length > 0;

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          <div>New Invoice</div>
        </div>
        <div className="flex gap-4">
          <Button
            action="default"
            label="Cancel"
            onClick={() => (closeModal(), formik?.resetForm())}
          />
          <Button
            label={"Create"}
            loading={loading}
            disabled={loading || hasErrors}
            onClick={formik.submitForm}
          />
        </div>
      </div>
      <div className="w-50 grid grid-cols-2 gap-x-16 gap-y-4">
        {inputs?.map((item, index) => {
          const inputProps = {
            ...item,
            formik,
          };
          return <div key={index}>{getInput(inputProps)}</div>;
        })}
      </div>
    </div>
  );
};

export default AddInvoice;
