import { Draggable, Droppable } from "react-beautiful-dnd";
import InstanceComponent from "./stepsSharedComponents/InstanceComponent";
import { useEffect, useRef, useState } from "react";

const imagePadding = 16;
const imageSafeArea = 6;
const imageMinWidth = 100;

const getItemStyle = (isDragging, draggableStyle) => ({
  background: isDragging ? "#efefef" : "transparent",
  boxShadow: isDragging ? "1px 1px 10px rgba(0,0,0,.3)" : "none",
  borderRadius: isDragging ? 5 : 0,
  ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
  border: isDraggingOver ? "2px dotted #9f9f9f" : "2px solid transparent",
  display: "flex",
  overflow: "auto",
});

const InstanceItem = ({
  keyId,
  item,
  index,
  selectedInstances,
  setSelected,
  instanceWH,
  document,
}) => {
  const keys =
    document.pages
      .filter(page => page.artworkId === item.artworkId)
      ?.map(pag => pag.position + 1)
      ?.join(", ") || "";
  return (
    <Draggable draggableId={keyId} index={index}>
      {(provided, snapshot) => {
        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className="column flex"
            style={{
              padding: `0 ${imagePadding / 2}px`,
              ...getItemStyle(
                snapshot.isDragging,
                provided.draggableProps.style,
              ),
            }}
          >
            <InstanceComponent
              isSelected={true}
              item={item}
              onSelectImage={setSelected}
              selectedInstances={selectedInstances}
              instanceId={item?.instanceId}
              styles={{
                width: instanceWH,
                height: instanceWH,
              }}
              instanceKey={keys}
              itemsPadding={imagePadding / 2}
              hideActionsButton={true}
            />
          </div>
        );
      }}
    </Draggable>
  );
};

const DroppableRow = ({
  prefix,
  elements,
  selectedInstances,
  setSelected,
  isLoadingUpdate,
  instanceWH,
  document,
}) => {
  return (
    <Droppable droppableId={prefix} direction="horizontal">
      {(provided, snapshot) => (
        <div
          {...provided.droppableProps}
          className={`rounded-lg pb-1 pt-1 ${isLoadingUpdate && "loading-instances-image"}`}
          ref={provided.innerRef}
          style={getListStyle(snapshot.isDraggingOver)}
          type="INSTANCE_IMAGE"
        >
          {elements?.map((item, index) => {
            const keyId = `${item.instanceId}:${item.archiveId}-${item.artworkId}`;
            return (
              <InstanceItem
                keyId={keyId}
                key={keyId}
                item={item}
                index={index}
                selectedInstances={selectedInstances}
                setSelected={setSelected}
                instanceWH={instanceWH}
                document={document}
              />
            );
          })}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

const SelectedImages = props => {
  const { setSelected, document, isLoadingUpdate } = props;
  const [rowElements, handleRowElements] = useState(3);
  const [columWidth, handleColumWidth] = useState(100);
  const columnRef = useRef();
  const selectedInstances =
    document.meta?.instances?.map((item, key) => ({
      ...item,
      fieldsData: document.fieldsData[key],
    })) || [];

  const gridRow =
    selectedInstances && selectedInstances.length
      ? Math.ceil(selectedInstances.length / rowElements)
      : null;

  const handleResize = () => {
    const columWidth = columnRef.current.offsetWidth;
    const columnsCount = Math.floor(
      columWidth / (imageMinWidth + imagePadding),
    );
    handleRowElements(columnsCount > 0 ? columnsCount : 1);
    handleColumWidth(columWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="full-width" ref={columnRef}>
      {!!selectedInstances.length &&
        [...Array(gridRow).keys()]?.map(item => {
          const initialCount = item * rowElements;
          const groupKey = `group-${item}-${rowElements}`;
          return (
            <DroppableRow
              elements={selectedInstances.filter(
                (_item, key) =>
                  key >= initialCount && key < initialCount + rowElements,
              )}
              key={groupKey}
              prefix={groupKey}
              selectedInstances={selectedInstances}
              setSelected={setSelected}
              isLoadingUpdate={isLoadingUpdate}
              instanceWH={
                columWidth / rowElements - (imagePadding + imageSafeArea)
              }
              document={document}
            />
          );
        })}
    </div>
  );
};

export default SelectedImages;
