import contact from "./Contact";
import ContactLinkType from "./ContactLinkType";

const ContactLink = ({ link = false, type = false } = {}) => `
  id
  contactId
  description
  expired
  ${
    link
      ? `link {
      ${contact(link)}
    }`
      : ""
  }
  linkId
  ${
    type
      ? `type {
      ${ContactLinkType}
    }`
      : ""
  }
  typeId
  updatedAt
`;

export default ContactLink;
