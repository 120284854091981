import { useMutation } from "@apollo/client";
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import { getInput, Modal, Button } from "../../../../../../components/ui";
import {
  IMPORT_CSV as importCSVMutation,
  UPLOAD_CSV,
} from "../../../../../../graphql/mutation/Email";
import { FETCH_EMAIL_CONTACTS } from "../../../../../../graphql/query/EmailContact";

const CSVUpload = props => {
  const { id } = props || {};
  const [
    uploadCSV,
    { loading, data: { uploadCSV: { success, data } = {} } = {}, reset },
  ] = useMutation(UPLOAD_CSV);
  const [importCSV, { loading: importLoading }] = useMutation(
    importCSVMutation(),
    { refetchQueries: [FETCH_EMAIL_CONTACTS] },
  );

  const handleUpload = async e => {
    const files = e.target.files;

    e.preventDefault();
    uploadCSV({
      variables: {
        input: {
          id,
          file: files[0],
        },
      },
    });
  };

  const handleImport = closeModal => {
    importCSV({
      variables: {
        input: {
          id,
        },
      },
    }).then(() => {
      closeModal();
      reset();
    });
  };

  const inputProps = {
    accept: ".csv",
    loading,
    name: "file",
    onChange: handleUpload,
    type: "img",
    uploadType: "file",
  };

  const steps = {
    upload: {
      title: "Import CSV to Add Recipients",
      description:
        "Drag and drop below or browse. Use provided template to import contacts.",
      body: () => getInput(inputProps),
    },
    review: {
      title: "Review and confirm",
      description: (() => {
        return (
          <span>
            Importing this file will add{" "}
            <span className="font-bold">{data?.stats?.success} recipients</span>{" "}
            to your mailing.
          </span>
        );
      })(),
      body: closeModal => (
        <div className="flex flex-col">
          <div className="mb-8 flex flex-col gap-2 text-sm">
            <div className="flex gap-4">
              <ExclamationTriangleIcon className="w-4 text-yellow-500" />
              <span>
                There are{" "}
                <span className="font-bold">{data?.stats?.total}</span> rows in
                the uploaded CSV file.
              </span>
            </div>
            <div className="flex gap-4">
              <CheckCircleIcon className="w-4 text-green-500" />
              <span>
                <span className="font-bold">{data?.stats?.success}</span>{" "}
                recipients will import successfully
              </span>
            </div>
            <div className="flex gap-4">
              <div className="flex items-baseline">
                <ExclamationTriangleIcon className="w-4 text-red-500" />
              </div>
              <span>
                <span className="font-bold">{data?.stats?.invalid}</span> rows
                have non unique emails or emails are missing and will not be
                imported
              </span>
            </div>
            <div className="flex gap-4">
              <div className="flex items-baseline">
                <ExclamationTriangleIcon className="w-4 text-red-500" />
              </div>
              <span>
                <span className="font-bold">{data?.stats?.skipped}</span> rows
                have parsing errors and will not be imported, please review the
                csv file or{" "}
                <span className="pointer font-bold underline">
                  contact support
                </span>
              </span>
            </div>
          </div>
          <div className="flex gap-4">
            <Button
              label="Proceed"
              loading={importLoading}
              onClick={() => handleImport(closeModal)}
            />
            <Button
              label="Cancel"
              action="default"
              onClick={() => {
                closeModal();
                reset();
              }}
            />
          </div>
        </div>
      ),
    },
  };
  const step = success ? steps.review : steps.upload;

  const importModalProps = {
    title: step?.title,
    scale: "sm",
    description: step?.description,
    closeOnBackdrop: false,
    body: ({ closeModal }) => {
      return <div className="mt-4 flex">{step?.body(closeModal)}</div>;
    },
  };

  return (
    <Modal {...importModalProps}>
      <Button label="Import" />
    </Modal>
  );
};

export default CSVUpload;
