import { useQuery } from "@apollo/client";
import { GridInputs } from "../../../../../components/Functional";
import {
  FETCH_CONTINENTS,
  FETCH_COUNTRIES,
} from "../../../../../graphql/query/Country";
import { useEffect } from "react";

const Provenance = props => {
  const { formik } = props || {};
  const { data: { continents = [] } = {} } = useQuery(FETCH_CONTINENTS);
  const { data: { countries = [] } = {} } = useQuery(FETCH_COUNTRIES);

  useEffect(() => {
    if (formik?.values?.none) {
      formik?.setFieldValue("noneProvenance", {
        operator: "eq",
        value: null,
      });
    } else if (formik?.values?.none === false) {
      formik?.setFieldValue("noneProvenance", null);
    }
  }, [formik?.values?.none]);

  useEffect(() => {
    if (formik?.values?.contact?.inActive) {
      formik?.setFieldValue("_instanceProvenance._contact.typeId", {
        operator: "eq",
        value: 3,
      });
    } else if (formik?.values?.contact?.inActive === false) {
      formik?.setFieldValue("_instanceProvenance._contact.typeId", null);
    }
  }, [formik?.values?.contact?.inActive]);

  const inputs = {
    className: "grid grid-cols-4 gap-16 mt-5",
    inputs: [
      {
        className: "flex flex-col gap-4",
        inputs: [
          {
            label: "Name (Owner)",
            name: "_instanceProvenance.owner",
            type: "text",
          },
          {
            designType: "checkbox",
            label: "None",
            name: "none",
            type: "toggle",
            view: "partial",
          },
          {
            designType: "checkbox",
            label: "Inactive Contact",
            name: "contact.inActive",
            type: "toggle",
          },
        ],
      },
      {
        className: "gap-4",
        inputs: [
          {
            label: "City",
            name: "_instanceProvenance._contact.county",
            type: "text",
          },
        ],
      },
      {
        className: "gap-4",
        inputs: [
          {
            label: "Country",
            name: "_instanceProvenance._contact.countryId",
            type: "multi-select",
            isMulti: false,
            options: countries?.map(item => ({
              label: item?.name,
              value: item?.id,
            })),
          },
        ],
      },
      {
        className: "gap-4",
        inputs: [
          {
            label: "Continent",
            name: "_instanceProvenance._contact._country.continent",
            options: continents?.map(item => ({
              label: item?.continent,
              value: item?.continent,
            })),
            type: "multi-select",
          },
        ],
      },
    ],
  };

  const gridInputProps = {
    formik,
    ...inputs,
  };

  return <GridInputs {...gridInputProps} />;
};

export default Provenance;
