import { useQuery } from "@apollo/client";
import { Button } from "../../../../components/ui";
import { FETCH_CONSIGNMENT_WORKS as fetchConsignmentWorks } from "../../../../graphql/query/ConsignmentWork";

const CopyDescription = ({ closeModal, consignmentId }) => {
  const { data: { consignmentWorks = {} } = {}, loading } = useQuery(
    fetchConsignmentWorks({
      instance: {
        description: {},
        edition: { artwork: { artists: {}, images: {} } },
        status: {},
      },
    }),
    {
      variables: {
        input: {
          limit: 50,
          offset: 0,
          consignmentId: consignmentId,
        },
      },
    },
  );

  const handleCopyDescription = () => {
    const descriptions = consignmentWorks?.edges
      ?.map(consignmentWork => consignmentWork?.instance?.description)
      .join("\n");
    navigator.clipboard.writeText(descriptions);
    closeModal();
  };

  return (
    <div className="mt-4 flex">
      <Button
        className="mr-3"
        label={"Cancel"}
        action={"default"}
        onClick={() => {
          closeModal?.();
        }}
      />
      <Button
        disabled={loading}
        label={"Copy description"}
        onClick={handleCopyDescription}
      />
    </div>
  );
};

export default CopyDescription;
