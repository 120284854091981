import { gql } from "@apollo/client";
import { Job as job } from "../fragments";

const FETCH_JOB_REQUEST = props => gql`
  query FetchJob($id: Int!) {
    job(id: $id) {
      ${job(props)}
    }
  }
`;

const FETCH_JOB_REQUESTS_V2 = props => gql`
  query FetchJobsV2($input: AdvJobFilterInput!) {
    jobsV2(input: $input) {
      count
      edges {
        ${job(props)}
      }
      sqlQuery
    }
  }
`;

export { FETCH_JOB_REQUEST, FETCH_JOB_REQUESTS_V2 };
