/* eslint-disable max-len */
const SuccessIcon = props => (
  <svg
    width={16}
    height={11}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>{"feather icon/success"}</title>
    <defs>
      <path
        d="m17.25 6.417-9.167 9.166a.757.757 0 0 1-.583.25.757.757 0 0 1-.583-.25L2.75 11.417a.806.806 0 0 1 0-1.167.806.806 0 0 1 1.167 0L7.5 13.833l8.583-8.583a.806.806 0 0 1 1.167 0 .806.806 0 0 1 0 1.167Z"
        id="a"
      />
    </defs>
    <g transform="translate(-2 -5)" fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <use fill="#000" fillRule="nonzero" xlinkHref="#a" />
      <g mask="url(#b)" fill="#34BFA3">
        <path d="M0 0h20v20H0z" />
      </g>
    </g>
  </svg>
);

export default SuccessIcon;
