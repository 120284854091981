const exportModule = {
  name: "Consignment",
  sections: [
    {
      fields: [
        { label: "Type", name: "ConsignmentType.description" },
        { label: "Id", name: "id" },
        // TODO: FIXME: Unable to fetch Contact.name
        { label: "Consignee", name: "contactId" },
        { label: "Consigner", name: "Consigner.forename" },
        { label: "Consigner Type", name: "Consigner.ContactType.description" },
        { label: "Date", name: "date" },
        { label: "Due", name: "due" },
        { label: "Currency", name: "Currency.code" },
        { label: "Terms", name: "terms" },
        { label: "Notes", name: "notes" },
      ],
      label: "",
      name: "consignment",
    },
    {
      fields: [
        {
          label: "Artist",
          name: "ConsignmentWorks.Instance.Edition.Artwork.ArtistArtworks.Artist.name",
        },
        { label: "Ref", name: "ConsignmentWorks.Instance.ref" },
        {
          label: "Title",
          name: "ConsignmentWorks.Instance.Edition.Artwork.title",
        },
        { label: "Company", name: "ConsignmentWorks.Instance.Company.name" },
      ],
      label: "Artworks",
      name: "artworks",
    },
    {
      fields: [
        {
          label: "Registrar",
          name: "ConsignmentWorks.Instance.CRMRegistrars.name",
        },
      ],
      label: "Registrar",
      name: "registrar",
    },
    {
      fields: [
        { label: "Sales User", name: "SalesUser.name" },
        { label: "Status", name: "ConsignmentStatus.description" },
      ],
      label: "Consignment",
      name: "consignment",
    },
  ],
};

export default exportModule;
