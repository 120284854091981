import { gql } from "@apollo/client";
import Catalogue from "../fragments/Catalogue";

const FETCH_CATALOGUES = gql`
  query FetchCatalogues {
    catalogues {
      ${Catalogue}
    }
  }
`;

export { FETCH_CATALOGUES };
