import { gql } from "@apollo/client";
import { ContactMailingList as contactMailingList } from "../fragments";

const CREATE_CONTACT_MAILING_LIST = props => gql`
  mutation CreateContactMailingList($input: CreateContactMailingListInput!) {
    createContactMailingList(input: $input) {
      contactMailingList {
        ${contactMailingList(props)}
      }
      error
      success
    }
  }
`;

const DELETE_CONTACT_MAILING_LIST = gql`
  mutation DeleteContactMailnigList($input: DeleteContactMailingListInput!) {
    deleteContactMailingList(input: $input) {
      error
      success
    }
  }
`;

const UPDATE_CONTACT_MAILING_LIST = props => gql`
  mutation UpdateContactMailingList($input: UpdateContactMailinstListInput!) {
    updateContactMailingList(input: $input) {
      contactMailingList {
        ${contactMailingList(props)}
      }
      error
      success
    }
  }
`;

export {
  CREATE_CONTACT_MAILING_LIST,
  DELETE_CONTACT_MAILING_LIST,
  UPDATE_CONTACT_MAILING_LIST,
};
