import { useQuery } from "@apollo/client";

import Activity from "./Activity";
import { FilterBar } from "../../../../../../../components/ui";
import { FETCH_CONTACT_ACTIVITIES as fetchContactActivities } from "../../../../../../../graphql/query/ContactActivity";

const ListActivities = props => {
  const { contact } = props || {};
  const { data: { contactActivities: { edges } = {} } = {} } = useQuery(
    fetchContactActivities(),
    {
      variables: {
        input: {
          contactId: contact?.id,
        },
      },
    },
  );

  return (
    <div className="mt-[50px] flex w-full flex-col">
      <div className="flex w-full items-center justify-between">
        <div className="text-2xl font-bold tracking-wider">Recent</div>
        <FilterBar />
      </div>
      <div className="flex flex-col">
        {edges?.map((item, index) => {
          return <Activity key={index} {...item} contact={contact} />;
        })}
      </div>
    </div>
  );
};

export default ListActivities;
