const exportModule = {
  name: "Instance",
  sections: [
    {
      fields: [
        { label: "Control Company", name: "Company.name" },
        { label: "Control Reference", name: "ref" },
        { label: "Artist", name: "Edition.Artwork.Artists.name" },
        { label: "Node Company", name: "NodeCompany.name" },
        { label: "Title", name: "Edition.Artwork.title" },
        { label: "Year", name: "Edition.year" },
        { label: "Medium", name: "Edition.Artwork.medium" },
        { label: "Name", name: "name" },
        { label: "Previous Reference", name: "previousRef", type: "text" },
        {
          label: "Artist Representation",
          name: "Edition.Artwork.Artist.represented",
        },
        { label: "Artist Ref", name: "artistRef" },
        { label: "Category", name: "Edition.Artwork.Category.description" },
        { label: "Series", name: "Edition.Artwork.series" },
        { label: "Edition no", name: "editionNumber" },
        { label: "Edition Size", name: "Edition.number" },
        { label: "Status", name: "Status.description" },
        { label: "Class", name: "Edition.Artwork.classId" },
        { label: "Dimensons", name: "Edition.Artwork.dimensions" },
        { label: "Handling", name: "Edition.Artwork.standardId" },
        { label: "Description", name: "Edition.Artwork.description" },
        { label: "Notes", name: "notes", type: "text" },
        { label: "Certificate", name: "certificate" },
        { label: "Id", name: "id", type: "number" },
        {
          label: "Ecommerce",
          name: "channelEcommerce",
          options: [
            { label: "True", value: 1 },
            { label: "False", value: 0 },
          ],
        },
        {
          label: "Presentation",
          name: "channelPresentation",
          options: [
            { label: "None", value: 0 },
            { label: "Public", value: 1 },
            { label: "Private", value: 2 },
          ],
        },
        {
          label: "Fabricated",
          name: "fabricated",
          options: [
            { label: "True", value: -1 },
            { label: "False", value: 0 },
          ],
        },
        {
          label: "For Sale",
          name: "forSale",
          options: [
            { label: "True", value: -1 },
            { label: "False", value: 0 },
          ],
        },
        {
          label: "Framed",
          name: "framed",
          options: [
            { label: "True", value: -1 },
            { label: "False", value: 0 },
          ],
        },
        { label: "On Loan", name: "onLoan" },
        {
          label: "Photographed",
          name: "Edition.Artwork.photographed",
          options: [
            { label: "True", value: -1 },
            { label: "False", value: 0 },
          ],
        },
      ],
      label: "",
      name: "instance",
    },
    {
      fields: [
        { label: "Id", name: "ExhibitionWork.Exhibition.id" },
        { label: "Exhibition Type", name: "ExhibitionWork.Exhibition.type" },
        { label: "Exhibited", name: "ExhibitionWork.exhibited" },
      ],
      label: "Art Fair",
      name: "artfair",
    },
    {
      fields: [{ label: "Auctioneer", name: "InstanceAuction.auctioneer" }],
      label: "Auction",
      name: "auction",
    },
    {
      fields: [{ label: "Status", name: "Condition.Status.description" }],
      label: "Condition",
      name: "condition",
    },
    {
      fields: [
        { label: "Type", name: "ConsignmentWork.Consignment.typeId" },
        { label: "ID", name: "ConsignmentWork.Consignment.id" },
        { label: "Date", name: "ConsignmentWork.Consignment.date" },
        { label: "Due", name: "ConsignmentWork.Consignment.due" },
      ],
      label: "Consignment",
      name: "consignment",
    },
    {
      fields: [
        { label: "Type", name: "Value.typeId" },
        { label: "Currency", name: "Value.currencyId" },
        { label: "Date", name: "Value.date" },
        { label: "Sales Strategy", name: "salesStrategy" },
        { label: "Return", name: "returns" },
        { label: "Director Confirm (Return)", name: "returnsDirector" },
      ],
      label: "History",
      name: "history",
    },
    {
      fields: [{ label: "Id", name: "JobItem.Job.id" }],
      label: "Jobs",
      name: "jobs",
    },
    {
      fields: [
        { label: "Name (Owner)", name: "InstanceProvenance.owner" },
        { label: "Contact Type", name: "InstanceProvenance.Contact.typeId" },
        { label: "City", name: "InstanceProvenance.Contact.county" },
        { label: "Country", name: "InstanceProvenance.Contact.countryId" },
        {
          label: "Continent",
          name: "InstanceProvenance.Contact.Country.continent",
        },
      ],
      label: "Provenance",
      name: "provenance",
    },
    {
      fields: [
        {
          label: "Contact",
          name: "WarehouseItem.WarehouseMove.Shipment.delContactId",
        },
        {
          label: "Postcode",
          name: "WarehouseItem.WarehouseMove.Shipment.delPostcode",
        },
        {
          label: "Country",
          name: "WarehouseItem.WarehouseMove.Shipment.Country.id",
        },
        {
          label: "Continent",
          name: "WarehouseItem.WarehouseMove.Shipment.Country.continent",
        },
        {
          label: "Viewing Room",
          name: "WarehouseItem.WarehouseMove.WarehouseLocation.viewingRoomId",
        },
        {
          label: "Date",
          name: "WarehouseItem.WarehouseMove.Shipment.date",
        },
        {
          label: "Category",
          name: "WarehouseItem.WarehouseMove.Shipment.categoryId",
        },
        {
          label: "Sites",
          name: "WarehouseItem.WarehouseMove.site",
        },
        {
          label: "Shipment ID",
          name: "WarehouseItem.WarehouseMove.Shipment.id",
        },
        {
          label: "Shipping Ref",
          name: "WarehouseItem.WarehouseMove.Shipment.shippingRef",
        },
      ],
      label: "Shipments",
      name: "shipments",
    },
    {
      fields: [
        { label: "Type", name: "CRMRegistrar.typeId" },
        { label: "Description", name: "CRMRegistrar.description" },
        { label: "Issue Id", name: "CRMRegistrar.issueId" },
      ],
      label: "Technical",
      name: "technical",
    },
    {
      fields: [
        { label: "Storage Id", name: "WarehouseItem.id" },
        { label: "Group", name: "WarehouseItem.mgroup" },
        { label: "Type", name: "WarehouseItem.categoryId" },
        { label: "Description", name: "WarehouseItem.description" },
      ],
      label: "Warehouse",
      name: "warehouse",
    },
  ],
};

export default exportModule;
