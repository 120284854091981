import { gql } from "@apollo/client";
import { PaymentPlan as paymentPlan } from "../fragments";

const CREATE_PAYMENT_PLAN = props => gql`
  mutation CreatePaymentPlan($input: CreatePaymentPlanInput!) {
    createPaymentPlan(input: $input) {
      success
      error
      paymentPlan {
        ${paymentPlan(props)}
      }
    }
  }
`;
const DELETE_PAYMENT_PLAN = gql`
  mutation DeletePaymentPlan($input: DeletePaymentPlanInput!) {
    deletePaymentPlan(input: $input) {
      success
      error
    }
  }
`;

const UPDATE_PAYMENT_PLAN = props => gql`
  mutation UpdatePaymentPlan($input: UpdatePaymentPlanInput!) {
    updatePaymentPlan(input: $input) {
      success
      error
      paymentPlan {
        ${paymentPlan(props)}
      }
    }
  }
`;

export { CREATE_PAYMENT_PLAN, DELETE_PAYMENT_PLAN, UPDATE_PAYMENT_PLAN };
