import { Accordion } from "../../../ui";

const LeftListView = props => {
  const { exportModule, formik } = props || {};
  const { selectedFields = [] } = formik?.values || {};

  const onClick = props => {
    const { field, module, section } = props || {};
    // append to selected Fields
    formik?.setFieldValue?.(`selectedFields`, [
      ...selectedFields,
      { field, section, module },
    ]);
  };

  return (
    <div className="my-5 flex h-96 overflow-y-scroll rounded-md border border-black">
      <div className="m-5 flex flex-grow flex-col">
        {exportModule?.sections?.map((section, index) => {
          return (
            <Accordion
              key={index}
              label={`${exportModule?.name} - ${section?.label}`}
              variant={"left"}
            >
              <div className="flex flex-col gap-2">
                {section?.fields?.map((field, index) => {
                  const selected = selectedFields?.find(
                    item =>
                      item?.field?.name === field?.name &&
                      item?.section?.name === section?.name,
                  );
                  return (
                    <div
                      className={`${selected ? "border-gray-300" : "border-black"} flex cursor-pointer rounded-md border px-4 py-2`}
                      key={index}
                      onClick={() =>
                        !selected &&
                        onClick({
                          field,
                          module: exportModule?.name,
                          section: {
                            label: section?.label,
                            name: section?.name,
                          },
                        })
                      }
                    >
                      <div
                        className={`${selected ? "text-gray-300" : ""} text-sm`}
                      >
                        {field?.label}
                      </div>
                    </div>
                  );
                })}
              </div>
            </Accordion>
          );
        })}
      </div>
    </div>
  );
};

export default LeftListView;
