import DetailFormCol1 from "./components/DetailFormCol1";
import DetailFormConsigner from "./components/DetailFormConsigner";
import DetailFormConsignee from "./components/DetailFormConsignee";
import { Input } from "../../../../../../../../components/ui";

const disableConfig = {
  disabled: true,
  inputClassName: "!bg-white !text-gray-400 !border-gray-400",
};

const DetailForm = ({ formik, submitOnBlur = false, consignment }) => {
  const updatedAt = new Date(consignment?.updatedAt) || false;

  const padL = (nr, chr = `0`) => `${nr}`.padStart(2, chr);
  const formatDate = date => {
    return `${padL(date.getDate())}/${padL(
      date.getMonth() + 1,
    )}/${date.getFullYear()} ${padL(date.getHours())}:${padL(
      date.getMinutes(),
    )}:${padL(date.getSeconds())}`;
  };

  return (
    <div className="mt-5 w-full">
      <div className="grid grid-cols-3 gap-24">
        <DetailFormCol1
          formik={formik}
          submitOnBlur={submitOnBlur}
          consignment={consignment}
        />
        <div className="grid-cols-1">
          <div className="mb-6 rounded border border-black p-4">
            <DetailFormConsigner
              formik={formik}
              disableConfig={disableConfig}
              submitOnBlur={submitOnBlur}
            />
          </div>
          <div className="rounded border border-black p-4">
            <DetailFormConsignee
              formik={formik}
              disableConfig={disableConfig}
              submitOnBlur={submitOnBlur}
              consignment={consignment}
            />
          </div>
        </div>
        <div className="grid-cols-1">
          {updatedAt && (
            <Input
              disabled
              label="Updated At"
              value={formatDate(updatedAt)}
              type="text"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DetailForm;
