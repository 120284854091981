import {
  CheckIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import { getIn } from "formik";

import { IconButton } from "..";

export const ValidationError = props => {
  const { formik, fields } = props || {};
  const errors = [];

  fields.forEach(field => {
    const value = field?.value || getIn(formik?.values, field?.name);
    const error = getIn(formik?.errors, field?.name);
    const isValid =
      field?.isValid !== undefined ? field?.isValid : !error || error === "";
    const errorMessage = error || `${field?.label} field is required.`;

    errors.push({
      errorMessage,
      isValid,
      label: field?.label,
      value,
    });
  });

  return (
    <>
      {errors &&
        errors?.map((error, index) => (
          <p
            key={index}
            className="flex grid grid-cols-8 items-center gap-4 py-2 text-sm"
          >
            <span className="col-span-2 flex items-center">
              <IconButton
                title={`${error?.errorMessage}`}
                className="border-none hover:bg-transparent"
              >
                {error?.isValid ? (
                  <CheckIcon className="mr-4 inline h-5 w-5 text-green-600" />
                ) : (
                  <ExclamationTriangleIcon className="mr-4 inline h-5 w-5 text-red-600" />
                )}
              </IconButton>
              <b className="col-span-2">{error?.label}</b>
            </span>
            <span className="col-span-6">
              {error?.value
                ?.toString()
                ?.replace(/<[^>]*>?/gm, "")
                ?.substring(0, 50)}
            </span>
          </p>
        ))}
    </>
  );
};
