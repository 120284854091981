import Focus from "./Focus";

const ContactFocus = ({ focus = false } = {}) => `
  id
  contactId
  createdById
  createdOn
  deleted
  ${
    focus
      ? `focus {
      ${Focus}
    }`
      : ""
  }
  focusId
  online
  rank
  typeId
  updatedAt
  updatedById
`;

export default ContactFocus;
