import { gql } from "@apollo/client";

const CREATE_INVOICE_SALES_PEOPLE = gql`
  mutation CreateInvoiceSalesPeople($input: CreateInvoiceSalesPeopleInput!) {
    createInvoiceSalesPeople(input: $input) {
      success
      error
    }
  }
`;

const EDIT_INVOICE_SALES_PERSON = gql`
  mutation EditInvoiceSalesPerson($input: EditInvoiceSalesPersonInput!) {
    editInvoiceSalesPerson(input: $input) {
      success
      error
    }
  }
`;

const DELETE_INVOICE_SALES_PERSON = gql`
  mutation DeleteInvoiceSalesPerson($input: DeleteInvoiceSalesPersonInput!) {
    deleteInvoiceSalesPerson(input: $input) {
      success
      error
    }
  }
`;

export {
  CREATE_INVOICE_SALES_PEOPLE,
  EDIT_INVOICE_SALES_PERSON,
  DELETE_INVOICE_SALES_PERSON,
};
