import { useMutation } from "@apollo/client";
import { useState } from "react";
import { saveAs } from "file-saver";
import { useFormik } from "formik";

import { GridInputs } from "../../Functional";
import { Button, Modal, Spinner } from "../../ui";

import { GET_IMAGE_URLS as getImageUrls } from "../../../graphql/mutation/Image";
import { zipAndDownloadFiles } from "../../../util/zipper";

const getImageBlob = async ({ fileName, fileUrl }) => {
  const file = await fetch(fileUrl);
  if (file.ok) {
    const blobFile = await file.blob();
    return new File([blobFile], fileName);
  } else {
    throw new Error("Error: fetching image.");
  }
};

const ExportImage = props => {
  const {
    artwork,
    currentSlide = 0,
    disabled = false,
    images,
    selectedImageFilters,
    allImageFilters,
  } = props;
  const image = images?.[currentSlide];
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      exportQty: "selected",
      name: "${artist} - ${title} - ${year}",
      format: "tif",
      type: "t",
    },
  });
  const { exportQty, format, name, type } = formik.values || {};

  const [getImagesUrl] = useMutation(getImageUrls, {
    variables: {
      input: {
        ...(exportQty === "all"
          ? {
              artworkId: allImageFilters
                ? allImageFilters?.artworkId
                : [image?.artworkId],
            }
          : {
              artworkId: selectedImageFilters
                ? selectedImageFilters?.artworkId
                : [image?.artworkId],
            }),
        ...(exportQty === "all"
          ? { archiveId: allImageFilters ? allImageFilters?.archiveId : null }
          : {
              archiveId: selectedImageFilters
                ? selectedImageFilters?.archiveId
                : [image?.archiveId],
            }),
        format,
        type,
      },
    },
  });

  const getFileName = ({ format, name }) => {
    const artists = artwork?.artists?.map(artist => artist.name)?.join(", ");
    // replace merge tags in name with data
    const data = {
      artist:
        artists?.length > 100
          ? artists?.substring(0, 100)?.trim()
          : artists?.trim() || "artist",
      title:
        artwork?.title?.length > 100
          ? artwork?.title?.substring(0, 100)?.trim()
          : artwork?.title?.trim() || "title",
      year: artwork?.timestamp
        ? new Date(artwork?.timestamp).getFullYear()
        : "year",
      reference: artwork?.reference || "reference",
      artworkId: artwork?.id || "artworkId",
    };
    const fileName = name?.replace(/\${(.*?)}/g, (match, key) => {
      return data[key];
    });
    return `${fileName}.${format}`;
  };

  const handleExportImages = async ({ closeModal }) => {
    setLoading(true);
    getImagesUrl().then(async res => {
      const { getImageUrls: { urls } = {} } = res?.data || {};

      if (exportQty === "selected") {
        if (urls.length > 1) {
          const promises = urls?.map(async (url, index) => {
            return await getImageBlob({
              fileName: getFileName({ format, name: `${name}_${index}` }),
              fileUrl: url,
            });
          });
          // wait for promises to resolve
          const files = await Promise.all(promises);
          await zipAndDownloadFiles(files, `Selected List Images.zip`);
        } else {
          const file = await getImageBlob({
            fileName: getFileName({ format, name }),
            fileUrl: urls[0],
          });
          saveAs(file);
        }
      } else if (exportQty === "all") {
        const promises = urls?.map(async (url, index) => {
          return await getImageBlob({
            fileName: getFileName({ format, name: `${name}_${index}` }),
            fileUrl: url,
          });
        });
        // wait for promises to resolve
        const files = await Promise.all(promises);
        await zipAndDownloadFiles(
          files,
          `${getFileName({ format, name })}.zip`,
        );
      }
      formik?.resetForm();
      closeModal?.();
      setLoading(false);
    });
  };

  const inputs = {
    className: "grid grid-cols-2 gap-4 mt-5",
    inputs: [
      {
        className: "grid-cols-1 gap-4",
        inputs: [
          {
            label: "Export",
            name: "exportQty",
            type: "select",
            placeholder: "Drop Down",
            options: [
              { label: "Selected Image", value: "selected" },
              { label: "All", value: "all" },
            ],
            ...(selectedImageFilters || allImageFilters
              ? { disabled: true }
              : null),
          },
          {
            label: "File Name",
            name: "name",
            type: "select",
            placeholder: "Drop Down",
            options: [
              {
                label: "Artist, Title, Year",
                value: "${artist} - ${title} - ${year}",
              },
              { label: "Reference", value: "${reference}" },
              { label: "Artwork ID", value: "${artworkId}" },
            ],
          },
        ],
      },
      {
        className: "grid-cols-1 gap-4",
        inputs: [
          {
            label: "Format",
            name: "format",
            type: "select",
            placeholder: "Drop Down",
            options: [
              { label: "TIF", value: "tif" },
              { label: "PNG", value: "png" },
              { label: "JPEG", value: "jpeg" },
            ],
          },
          {
            label: "Size",
            name: "type",
            type: "select",
            placeholder: "Drop Down",
            options: [
              { label: "Thumbnail", value: "t" },
              { label: "Screen", value: "x" },
              { label: "Medium Res", value: "a" },
              { label: "Higher Res", value: "b" },
            ],
          },
        ],
      },
    ],
  };

  const detailInputProps = {
    formik,
    ...inputs,
  };

  const modalProps = {
    scale: "md",
    closeOnBackdrop: false,
    hideCloseButton: true,
    disabled,
    body: ({ closeModal }) => {
      return (
        <div className="relative flex w-full flex-col p-8">
          {loading && (
            <div className="absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center bg-gray-200/50 backdrop-blur-sm">
              <Spinner />
            </div>
          )}
          <div className="flex flex-row">
            <div className="flex flex-1 flex-row items-center font-bold">
              <span className="text-2xl">Export Artwork Image(s)</span>
              {/* {errorMessage && <span className="ml-5 text-sm text-red-500	">{errorMessage}</span>} */}
            </div>
            <div className="flex flex-row">
              <Button
                action="default"
                className="mr-8"
                label="Cancel"
                onClick={() => {
                  formik?.resetForm();
                  closeModal();
                }}
              />
              <Button
                disabled={loading}
                label="Export"
                loading={loading}
                onClick={() => handleExportImages({ closeModal })}
              />
            </div>
          </div>
          <GridInputs {...detailInputProps} />
        </div>
      );
    },
  };

  return (
    <Modal {...modalProps}>
      <Button
        disabled={disabled}
        label="Export"
        action="dark"
        className={"!w-32"}
      />
    </Modal>
  );
};

export default ExportImage;
