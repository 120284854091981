import { PencilIcon, TrashIcon, EyeIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { IconButton } from "../../../../../../../components/ui/IconButton";

const Actions = props => {
  const { id } = props || {};
  const navigate = useNavigate();

  const handleEdit = () => {
    navigate(`events/${id}/edit`);
  };

  const handleDetail = () => {
    navigate(`events/${id}/detail`);
  };

  const handleDelete = () => {
    // delete action
  };

  if (typeof id == "number") {
    return (
      <div className="user-list-actions flex text-right text-sm font-medium">
        {id && (
          <div className="grid grid-cols-4 gap-4">
            <IconButton variant="clean" title="Edit Events">
              <PencilIcon
                className={`h-4 w-4 cursor-pointer text-gray-400`}
                onClick={handleEdit}
              />
            </IconButton>
            <IconButton variant="clean" title="View Events">
              <EyeIcon
                className={`h-4 w-4 cursor-pointer text-gray-400`}
                onClick={handleDetail}
              />
            </IconButton>
            <IconButton variant="clean" title="Delete Events">
              <TrashIcon
                className={`h-4 w-4 cursor-pointer text-gray-400`}
                onClick={handleDelete}
              />
            </IconButton>
          </div>
        )}
      </div>
    );
  } else {
    return false;
  }
};

export default Actions;
