import instanceFrag from "./Instance";
import reservationFrag from "./Reservation";

const ReservationInstance = ({ instance = false, reservation = false }) => `
  createdBy
  dateModified
  id
  instanceId
  ${
    instance
      ? `instance {
      ${instanceFrag(instance)}
    }`
      : ""
  }
  released
  reservationId
  ${
    reservation
      ? `reservation {
      ${reservationFrag(reservation)}
    }`
      : ""
  }
`;

export default ReservationInstance;
