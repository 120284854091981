import { Link, useParams } from "react-router-dom";

import SectionHeader from "../../components/SectionHeader";
import { Table } from "../../../../../../components/Functional";
import { FETCH_SHIPMENTS_V2 as fetchShipmentsV2 } from "../../../../../../graphql/query/Shipment";

const InstanceShipments = () => {
  const { id: instanceId } = useParams();

  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "shipmentsV2",
    headers: [
      { label: "Date", name: "date", type: "date" },
      {
        label: "#",
        name: "id",
        sortKey: "id",
        type: "custom",
        component: ({ values }) => (
          <Link className="underline" to={`/shipments/${values?.id}/details`}>
            {values?.id}
          </Link>
        ),
      },
      {
        label: "Shipped By",
        name: "shipper.company",
        type: "label",
      },
      {
        label: "Origin",
        type: "custom",
        component: ({ values }) => (
          <span className="flex gap-1">
            <span className="overflow-hidden text-ellipsis text-base font-medium leading-5 text-black">
              <Link
                className="underline"
                to={`/contacts/${values?.oriContactId}/details`}
              >
                {values?.oriCompany ? values?.oriCompany : values?.oriName}
              </Link>
            </span>
          </span>
        ),
      },
      {
        label: "Destination",
        type: "custom",
        component: ({ values }) => (
          <span className="flex gap-1">
            <span className="overflow-hidden text-ellipsis text-base font-medium leading-5 text-black">
              <Link
                className="underline"
                to={`/contacts/${values?.delContactId}/details`}
              >
                {values?.delCompany ? values?.delCompany : values?.delName}
              </Link>
            </span>
          </span>
        ),
      },
      {
        label: "Category",
        name: "category.description",
        type: "label",
      },
      {
        label: "Delivered",
        name: "delivered",
        type: "date",
      },
    ],
    inputs: [],
    FETCH_QUERY: fetchShipmentsV2({ category: {}, shipper: {} }),
    variables: {
      _warehouseMove: {
        _warehouseItem: {
          _instance: {
            id: {
              operator: "eq",
              value: +instanceId,
            },
          },
        },
      },
    },
  };

  return (
    <div className="w-full">
      <SectionHeader title="Shipments" />
      <div className="mb-6 w-full">
        <Table {...tableProps} />
      </div>
    </div>
  );
};

export default InstanceShipments;
