import ContactAddressType from "./ContactAddressType";
import Country from "./Country";

const ContactAddress = ({ country = false, type = false } = {}) => `
  id
  address
  address2
  address3
  contactId
  ${
    country
      ? `country {
      ${Country}
    }`
      : ""
  }
  countryId
  county
  do_not_call
  expired
  postcode
  town
  ${
    type
      ? `type {
      ${ContactAddressType}
    }`
      : ""
  }
  typeId
  use_billing
  use_catalogue
  use_delivery
  use_event
  use_statement
  use_vat
  use_default
`;

export default ContactAddress;
