// import { Invoice } from "../../../../../../graphql/fragments"

// const extractObjects = (query) => {
//   const objects = []
//   const nonObjects = []
//   const lines = query.split('\n')

//   let depth = 0
//   let currentObject = ''

//   for (const line of lines) {
//     if (line.trim().endsWith('{')) {
//       depth++
//     }

//     else if (line.trim() === '}') {
//       depth--
//       if (depth === 0) {
//         currentObject += line + '\n'
//         objects.push(currentObject.trim())
//         currentObject = ''
//       }
//     }
//     else {
//       if(depth === 0) {
//         const field = line.trim().split(' ')[0]
//         field && nonObjects.push(field)
//       }
//     }

//     if (depth > 0) {
//       currentObject += line + '\n'
//     }
//   }

//   return { objects, nonObjects }
// };

// const graphqlFieldsToFlatArray = (query, prefix = '') => {

//   const { objects, nonObjects } = extractObjects(query)
//   let objectFields = []
//   // recursively call graphqlFieldsToFlatArray on each object
//   objects?.forEach(object => {
//     const objectName = object.split(' ')[0]
//     // query without object name
//     const startIdx = object.indexOf('{') || 0
//     const endIdx = object.lastIndexOf('}') || object.length
//     const objQuery = object.substring(startIdx + 1, endIdx);

//     objectFields = [...objectFields, ...graphqlFieldsToFlatArray(objQuery, prefix ? `${prefix}.${objectName}` : objectName)]
//   })

//   const nonObjectsWithPrefix = nonObjects.map(field => `${prefix ? `${prefix}.` : ''}${field}`)
//   return [...nonObjectsWithPrefix, ...objectFields]
// }

// // convert to an array of field names
// const invoiceFields = graphqlFieldsToFlatArray(Invoice)?.map(field => ({ Name: field, DataField: field }))

// const invoicesDataSet = {
//   Name: "Invoice",
//   Query: {
//     DataSourceName: "Invoice",
//     CommandText: "jpath=$.",
//   },
//   Fields: invoiceFields,
// }

export const dataSources = sources => {
  let dataSources = [];
  sources.forEach(source => {
    dataSources.push({
      id: source.name.toLowerCase(),
      canEdit: true,
      template: {
        Name: source.name,
        ConnectionProperties: {
          DataProvider: "JSON",
          ConnectString: source.connectString,
        },
      },
      title: source.name,
    });
  });

  return dataSources;
};

export const defaultReportDefinition = {
  Name: "Report",
  Body: {
    ReportItems: [
      {
        Type: "textbox",
        Name: "TextBox1",
        Value: "Hello, ActiveReportsJS Viewer",
        Style: {
          FontSize: "18pt",
        },
        Width: "8.5in",
        Height: "0.5in",
      },
    ],
  },
};
