import { useQuery } from "@apollo/client";

import { FETCH_COUNTRIES } from "../../../../../../../graphql/query/Country";
import { getInput } from "../../../../../../../components/ui";
import useUser from "../../../../../../../hooks/useUser";

const Collection = props => {
  const { formik, submitOnBlur = false } = props;
  const { data: { countries = [] } = {} } = useQuery(FETCH_COUNTRIES);
  const { hasPermission } = useUser();

  const inputs = [
    {
      name: "colContactId",
      type: "contactSearch",
      label: "Name",
      submitOnBlur,
      showType: true,
      disabled: !hasPermission("UPDATE_CONSIGNMENT"),
    },
    {
      name: "colCompany",
      type: "text",
      label: "Company",
      submitOnBlur,
      disabled: !hasPermission("UPDATE_CONSIGNMENT"),
    },
    {
      name: "colAddress",
      type: "text",
      label: "Address",
      submitOnBlur,
      disabled: !hasPermission("UPDATE_CONSIGNMENT"),
    },
    {
      name: "colAddress2",
      type: "text",
      label: "Address 2",
      submitOnBlur,
      disabled: !hasPermission("UPDATE_CONSIGNMENT"),
    },
    {
      name: "colAddress3",
      type: "text",
      label: "Address 3",
      submitOnBlur,
      disabled: !hasPermission("UPDATE_CONSIGNMENT"),
    },
    {
      name: "colTown",
      type: "text",
      label: "Town",
      submitOnBlur,
      disabled: !hasPermission("UPDATE_CONSIGNMENT"),
    },
    {
      name: "colCounty",
      type: "text",
      label: "County",
      submitOnBlur,
      disabled: !hasPermission("UPDATE_CONSIGNMENT"),
    },
    {
      label: "Country",
      name: "colCountryId",
      type: "multi-select",
      options: countries?.map(item => ({
        label: item.name,
        value: item.id,
      })),
      submitOnBlur,
      disabled: !hasPermission("UPDATE_CONSIGNMENT"),
    },
    {
      name: "colPostcode",
      type: "text",
      label: "Postcode",
      submitOnBlur,
      disabled: !hasPermission("UPDATE_CONSIGNMENT"),
    },
  ];

  return (
    <div className="w-full">
      <h5 className="font-bold">Collection</h5>
      <div className="mt-2 grid h-fit grid-cols-1 gap-4">
        {inputs?.map((item, index) => {
          const inputProps = {
            formik,
            ...item,
          };

          return <div key={index}>{getInput(inputProps)}</div>;
        })}
      </div>
    </div>
  );
};

export default Collection;
