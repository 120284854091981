import { Route, Routes as RouterRoutes } from "react-router-dom";
import AuthRoute from "../../components/AuthRoute";

import Analytics from "./pages/Analytics";
import Dashboard from "./pages/Dashboard";
import Wizard from "./pages/Wizard";

const routes = [
  {
    path: ":id/analytics",
    element: Analytics,
    permissions: ["EMAIL_MODULE"],
  },
  {
    path: ":id/*",
    element: Wizard,
    permissions: ["EMAIL_MODULE"],
  },
  {
    path: "*",
    element: Dashboard,
    permissions: ["READ_MAILING_LIST", "EMAIL_MODULE"],
  },
];

const Email = props => {
  return (
    <RouterRoutes>
      {routes?.map((item, index) => {
        return (
          <Route
            key={index}
            path={item?.path}
            element={
              <AuthRoute permissions={item.permissions}>
                <item.element {...props} />
              </AuthRoute>
            }
          />
        );
      })}
    </RouterRoutes>
  );
};

export default Email;
