import ContactAttributeType from "./ContactAttributeType";

const ContactAttribute = ({ type = false } = {}) => `
  id
  description
  ${
    type
      ? `type {
      ${ContactAttributeType}
    }`
      : ""
  }
  updatedAt
`;

export default ContactAttribute;
