import { useQuery } from "@apollo/client";
import { GridInputs } from "../../../../../components/Functional";
import { FETCH_JOB_STATUSES } from "../../../../../graphql/query/JobStatus";

const Handover = props => {
  const { formik } = props || {};

  const { data: { jobStatuses = [] } = {} } = useQuery(FETCH_JOB_STATUSES);

  // TODO: hardcode typeId to 25 for handovers
  const inputs = {
    className: "grid grid-rows-2 gap-4",
    inputs: [
      {
        className: "grid grid-cols-4 gap-4",
        inputs: [
          {
            label: "Date Start",
            name: "_CRMRegistrar.date.value.from",
            type: "date",
          },
          {
            label: "Date End",
            name: "_CRMRegistrar.date.value.to",
            type: "date",
          },
          {
            label: "Handover From",
            name: "_CRMRegistrar.validFrom",
            type: "date",
          },
          {
            label: "Handover To",
            name: "_CRMRegistrar.validTo",
            type: "date",
          },
        ],
      },
      {
        className: "grid grid-cols-4 gap-4",
        inputs: [
          {
            label: "Notes",
            name: "_CRMRegistrar.notes",
            type: "text",
          },
          {
            label: "Status",
            name: "_CRMRegistrar.statusId",
            options: jobStatuses?.map(item => ({
              label: item?.description,
              value: item?.id,
            })),
            type: "multi-select",
          },
        ],
      },
    ],
  };

  const gridInputProps = {
    formik,
    ...inputs,
  };

  return <GridInputs {...gridInputProps} />;
};

export default Handover;
