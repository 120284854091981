import { gql } from "@apollo/client";
import { Event } from "../fragments";

const FETCH_EVENTS = gql`
  query Events {
    events {
      ${Event}
    }
  }
`;

export { FETCH_EVENTS };
