import { Table } from "../../../../../../components/Functional";
import { FETCH_CHECK_LISTS_V2 } from "../../../../../../graphql/query/CheckList";

import SectionHeader from "../../components/SectionHeader";

const CheckList = props => {
  const { warehouseItem } = props;

  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "checkListsV2",
    headers: [
      { label: "Check List #", name: "id", type: "label" },
      { label: "Date", name: "date", type: "date" },
      { label: "Type", name: "typeId", type: "label" },
      { label: "Technician", name: "technician", type: "label" },
      { label: "Location", name: "location", type: "label" },
    ],
    inputs: [],
    FETCH_QUERY: FETCH_CHECK_LISTS_V2,
    variables: {
      _warehouseItem: {
        id: {
          operator: "eq",
          value: warehouseItem?.id,
        },
      },
    },
  };

  return (
    <div className="w-full">
      <SectionHeader title="Check Lists" />
      <div className="mb-6 w-full">
        <Table {...tableProps} />
      </div>
    </div>
  );
};

export default CheckList;
