import { useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import { useEffect } from "react";
import * as Yup from "yup";

import { Button } from "../../../../../../../components/ui";
import { GridInputs } from "../../../../../../../components/Functional";

import { FETCH_CURRENCIES } from "../../../../../../../graphql/query/Currency";
import { FETCH_FABRICATION_TYPES } from "../../../../../../../graphql/query/FabricationType";
import { FETCH_FABRICATION_STAGES } from "../../../../../../../graphql/query/FabricationStage";
import { FETCH_FABRICATION_CATEGORIES } from "../../../../../../../graphql/query/FabricationCategory";
import {
  CREATE_FABRICATION as createFabricationMutation,
  UPDATE_FABRICATION as updateFabricationMutation,
} from "../../../../../../../graphql/mutation/Fabrication";
import { currentLocaleISODate } from "../../../../../../../util";

const AddEstimate = props => {
  const { closeModal, values, isOpen, consignmentId } = props;

  const { data: { fabricationTypes = [] } = {} } = useQuery(
    FETCH_FABRICATION_TYPES,
    { variables: { input: { id_gt: 10 } } },
  );
  const { data: { fabricationStages = [] } = {} } = useQuery(
    FETCH_FABRICATION_STAGES,
  );
  const { data: { fabricationCategories = [] } = {} } = useQuery(
    FETCH_FABRICATION_CATEGORIES,
  );
  const { data: { currencies = [] } = {} } = useQuery(FETCH_CURRENCIES);

  const [createFabrication, { loading: loadingCreate }] = useMutation(
    createFabricationMutation({ category: {}, stage: {}, type: {} }),
    { refetchQueries: ["FetchFabricationsV2"] },
  );

  const [updateFabrication, { loading: loadingUpdate }] = useMutation(
    updateFabricationMutation({ category: {}, stage: {}, type: {} }),
    { refetchQueries: ["FetchFabricationsV2"] },
  );

  const addEstimate = () => {
    const input = {
      amount: formik.values.amount,
      categoryId: formik.values.categoryId,
      confirmed: formik.values.confirmed,
      currencyId: formik.values.currencyId,
      date: formik.values.date,
      days: formik.values.days || 0,
      description: formik.values.description,
      hours: formik.values.hours || 0,
      rate: formik.values.rate,
      recharge: formik.values.recharge,
      stageId: formik.values.stageId,
      technicians: formik.values.technicians,
      typeId: formik.values.typeId,
      waived: formik.values.waived,
      notes: formik.values.notes,
      estimate: -1,
    };

    if (values?.id) {
      input.id = values?.id;
    } else {
      input.consignmentId = consignmentId;
    }

    const action = values?.id ? updateFabrication : createFabrication;

    action({
      variables: {
        input,
      },
    }).then(() => {
      closeModal();
      formik.resetForm();
    });
  };

  const exclusiveFields = function (values, schema) {
    const { days, hours } = values;
    const daysInt = parseInt(days);
    const hoursInt = parseInt(hours);
    if ((daysInt > 0 && !hours) || (!days && hoursInt > 0)) {
      return true;
    }
    return schema.createError({
      message: "only one field should be provided",
      path: "hours", // Choose any of the fields as the error path
    });
  };

  const formik = useFormik({
    initialValues: values
      ? {
          amount: values.amount,
          categoryId: values.categoryId,
          confirmed: values.confirmed,
          currencyId: values.currencyId,
          date: values.date,
          days: values.days,
          description: values.description,
          hours: values.hours,
          rate: values.rate,
          recharge: values.recharge,
          stageId: values.stageId,
          technicians: values.technicians,
          typeId: values.typeId,
          notes: values.notes,
          waived: values.waived,
        }
      : {
          date: currentLocaleISODate(),
        },
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: Yup.object()
      .shape(
        {
          amount: Yup.number().required("required").positive(),
          categoryId: Yup.number().nullable().required("required"),
          currencyId: Yup.number().nullable().required("required"),
          date: Yup.date().required("required"),
          days: Yup.number().when("hours", {
            is: hours => parseInt(hours) === 0,
            then: () =>
              Yup.number()
                .positive()
                .required("either days or hours is required"),
            otherwise: () =>
              Yup.number()
                .transform(value => (Number.isNaN(value) ? null : value))
                .nullable(),
          }),
          rate: Yup.number().positive().nullable(),
          hours: Yup.number().when("days", {
            is: days => parseInt(days) === 0,
            then: () =>
              Yup.number()
                .positive()
                .required("either days or hours is required"),
            otherwise: () =>
              Yup.number()
                .transform(value => (Number.isNaN(value) ? null : value))
                .nullable(),
          }),
          stageId: Yup.number().nullable().required("required"),
          technicians: Yup.number().positive().integer().nullable(),
          typeId: Yup.number().nullable().required("required"),
        },
        ["days", "hours"],
      )
      .test(
        "exclusive-fields",
        "only one field should be provided",
        exclusiveFields,
      ),
    onSubmit: addEstimate,
  });

  const inputs = {
    className: "grid grid-cols-2 gap-12",
    inputs: [
      {
        className: "grid grid-cols-1 gap-4",
        inputs: [
          { label: "Date", name: "date", type: "date" },
          {
            label: "Stage",
            name: "stageId",
            type: "multi-select",
            options: fabricationStages?.map(item => ({
              label: item?.name,
              value: item?.id,
            })),
          },
          {
            label: "Type",
            name: "categoryId",
            type: "multi-select",
            options: fabricationCategories?.map(item => ({
              label: item?.name,
              value: item?.id,
            })),
          },
          {
            label: "Service",
            name: "typeId",
            type: "multi-select",
            options: fabricationTypes?.map(item => ({
              label: item?.description,
              value: item?.id,
            })),
          },
          { label: "Description", name: "description", type: "textarea" },
        ],
      },
      {
        className: "grid grid-cols-1 gap-4",
        inputs: [
          { label: "Amount", name: "amount", type: "number-currency" },
          {
            label: "Currency",
            name: "currencyId",
            type: "multi-select",
            options: currencies?.map(currency => ({
              label: `${currency?.description} (${currency?.code})`,
              value: currency?.id,
            })),
          },
          {
            className: "grid grid-cols-2 gap-4",
            inputs: [
              { label: "Technicians", name: "technicians", type: "number" },
              { label: "Rate", name: "rate", type: "number" },
            ],
          },
          {
            className: "grid grid-cols-2 gap-4",
            inputs: [
              { label: "Hours", name: "hours", type: "number" },
              { label: "Days", name: "days", type: "number" },
            ],
          },
          { label: "Recharge", name: "recharge", type: "number" },
          {
            className: "grid grid-cols-2 gap-4",
            inputs: [
              {
                name: "waived",
                options: [{ label: "Waived", value: -1 }],
                singleCheckbox: true,
                type: "checkbox",
              },
              {
                name: "confirmed",
                options: [{ label: "Confirmed", value: -1 }],
                singleCheckbox: true,
                type: "checkbox",
              },
            ],
          },
          {
            label: "Notes",
            name: "notes",
            type: "textarea",
          },
        ],
      },
    ],
  };

  const inputProps = {
    ...inputs,
    formik,
  };

  const successLabel = values?.id ? "Edit" : "Add";

  useEffect(() => {
    formik.handleReset();
  }, [isOpen]);

  return (
    <div className="flex w-full flex-col p-8">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          {values?.id ? "Edit" : "Add"} Estimate
        </div>
        <div className="flex flex-col">
          <div>
            <Button
              action="default"
              className="mr-8"
              label="Cancel"
              onClick={closeModal}
            />
            <Button
              label={`${successLabel}${loadingUpdate || loadingCreate ? "ing" : ""}`}
              disabled={loadingUpdate || loadingCreate}
              onClick={formik.submitForm}
            />
          </div>
        </div>
      </div>
      <div className="mt-12 pb-10">
        <GridInputs {...inputProps} />
      </div>
    </div>
  );
};

export default AddEstimate;
