import { useLazyQuery, useMutation } from "@apollo/client";
import { useFormik } from "formik";
import { useEffect } from "react";
import * as Yup from "yup";

import { Button } from "../../../../../../../components/ui";
import { GridInputs } from "../../../../../../../components/Functional";

import {
  CREATE_INSTANCE_PROVENANCE as createInstanceProvenanceMutation,
  UPDATE_INSTANCE_PROVENANCE as updateInstanceProvenanceMutation,
} from "../../../../../../../graphql/mutation/InstanceProvenance";
import { FETCH_CONTACT as fetchContactQuery } from "../../../../../../../graphql/query/Contact";
import { validateDate } from "../../../../../../../util";

const AddProvenance = props => {
  const { instanceId, closeModal, values, isOpen } = props;
  const [fetchContact, { loading: loadingContact }] =
    useLazyQuery(fetchContactQuery());

  const [createInstanceProvenance, { loading: loadingCreate }] = useMutation(
    createInstanceProvenanceMutation(),
    { refetchQueries: ["FetchInstanceProvenances"] },
  );

  const [updateInstanceProvenance, { loading: loadingUpdate }] = useMutation(
    updateInstanceProvenanceMutation(),
    { refetchQueries: ["FetchInstanceProvenances"] },
  );

  const addProvenance = () => {
    const input = {
      contactId: formik.values.contactId,
      credit: formik.values.credit,
      day: formik.values.day || null,
      month: formik.values.month || null,
      notes: formik.values.notes,
      owner: formik.values.owner,
      year: formik.values.year || null,
    };

    if (values?.id) {
      input.id = values?.id;
    } else {
      input.instanceId = instanceId;
    }

    const action = values?.id
      ? updateInstanceProvenance
      : createInstanceProvenance;

    action({
      variables: {
        input,
      },
    }).then(() => {
      closeModal();
      formik.resetForm();
    });
  };

  const formik = useFormik({
    initialValues: values
      ? {
          contactId: values?.contactId,
          credit: values.credit,
          day: values.day || null,
          month: values.month || null,
          notes: values.notes,
          owner: values.owner,
          year: values.year || null,
        }
      : {},
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: Yup.object({
      contactId: Yup.number().required("required"),
      day: Yup.number()
        .integer()
        .typeError("Please enter a day")
        .test("valid-date", "Invalid day", function (value, context) {
          const { day, month, year } = context.parent;

          return validateDate(day, month, year);
        }),
      month: Yup.number()
        .integer()
        .min(1, "Month must be between 1 and 12")
        .max(12, "Month must be between 1 and 12")
        .typeError("Please enter a month"),
      year: Yup.number()
        .integer()
        .typeError("Please enter a year")
        .min(1000, "Year must have 4 digit")
        .max(9999, "Year must be less than 9999"),
    }),
    onSubmit: addProvenance,
  });

  useEffect(() => {
    if (formik.values?.contactId) {
      fetchContact({
        variables: {
          id: formik.values?.contactId,
        },
      }).then(({ data: { contact } }) => {
        formik.setFieldValue("owner", contact?.name);
        if (contact?.name) {
          formik.setFieldValue("credit", contact?.name);
        } else {
          formik.setFieldValue("credit", contact?.company);
        }
      });
    }
  }, [formik.values?.contactId]);

  const inputs = {
    className: "grid grid-cols-2 gap-12",
    inputs: [
      {
        className: "grid grid-cols-1 gap-4",
        inputs: [
          {
            className: "grid grid-cols-3 gap-4",
            inputs: [
              { label: "Day", name: "day", type: "number" },
              { label: "Month", name: "month", type: "number" },
              { label: "Year", name: "year", type: "number" },
            ],
          },
          {
            label: "Contact",
            name: "contactId",
            type: "contactSearch",
          },
          { label: "Owner", name: "owner", type: "text" },
          { label: "Credit", name: "credit", type: "text" },
        ],
      },
      {
        className: "grid grid-cols-1 gap-4",
        inputs: [{ label: "Notes", name: "notes", rows: 4, type: "textarea" }],
      },
    ],
  };

  const inputProps = {
    ...inputs,
    formik,
  };

  const successLabel = values?.id ? "Edit" : "Add";

  useEffect(() => {
    formik.handleReset();
  }, [isOpen]);

  return (
    <div className="flex w-full flex-col p-8">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          {values?.id ? "Edit" : "Add"} Provenance Record
        </div>
        <div className="flex flex-col">
          <div>
            <Button
              action="default"
              className="mr-8"
              label="Cancel"
              onClick={closeModal}
            />
            <Button
              label={`${successLabel}${
                loadingUpdate || loadingCreate || loadingContact ? "ing" : ""
              } Provenance`}
              disabled={loadingUpdate || loadingCreate || loadingContact}
              onClick={formik.submitForm}
            />
          </div>
        </div>
      </div>
      <div className="mt-12 pb-10">
        <GridInputs {...inputProps} />
      </div>
    </div>
  );
};

export default AddProvenance;
