import { useQuery } from "@apollo/client";
import { useFormik } from "formik";
import * as React from "react";
import { Link } from "react-router-dom";

import { Table } from "../../../../../../components/Functional";
import { FETCH_COMPANIES as fetchCompanies } from "../../../../../../graphql/query/Company";
import { FETCH_CONSIGNMENTS as fetchConsignments } from "../../../../../../graphql/query/Consignment";
import { FETCH_CONSIGNMENT_CHANNELS } from "../../../../../../graphql/query/ConsignmentChannel";
import { FETCH_CONSIGNMENTS_STATUS } from "../../../../../../graphql/query/ConsignmentStatus";
import { FETCH_CONSIGNMENTS_TYPES } from "../../../../../../graphql/query/ConsignmentType";
import { FETCH_CONTACTS as fetchContacts } from "../../../../../../graphql/query/Contact";
import { useDebounce } from "../../../../../../util";
import SectionHeader from "../../components/SectionHeader";

const ContactRequests = props => {
  const { contactId } = props || {};
  const { data: { consignmentStatus = [] } = {} } = useQuery(
    FETCH_CONSIGNMENTS_STATUS,
  );
  const { data: { consignmentTypes = [] } = {} } = useQuery(
    FETCH_CONSIGNMENTS_TYPES,
  );
  const { data: { consignmentChannels = [] } = {} } = useQuery(
    FETCH_CONSIGNMENT_CHANNELS,
  );

  // for searching consigner
  const formik = useFormik({ initialValues: { search: "" } });
  const debouncedFilters = useDebounce(formik?.values, 300);
  const { data: { contacts = [] } = {} } = useQuery(fetchContacts(), {
    variables: { input: { ...debouncedFilters } },
  });

  const { data: { companies = [] } = {} } = useQuery(fetchCompanies());

  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "consignments",
    headers: [
      {
        label: "Id",
        name: "id",
        type: "custom",
        component: props => (
          <Link
            className="underline"
            to={`/consignment/${props?.values?.id}/form`}
          >
            {props?.values?.id}
          </Link>
        ),
      },
      { label: "Consigner", name: "consigner.name", type: "label" },
      { label: "Channel", name: "channel.description", type: "label" },
      { label: "Company", name: "company.name", type: "label" },
      { label: "Status", name: "status.description", type: "label" },
      { label: "Type", name: "type.description", type: "label" },
      { label: "Due Date", name: "due", type: "date" },
      { label: "Date", name: "date", type: "date" },
    ],
    inputs: [
      {
        label: "Status",
        name: "statusId",
        type: "select",
        options: consignmentStatus?.map(item => ({
          label: item.description,
          value: item.id,
        })),
      },
      {
        label: "Consigner",
        name: "consignerId",
        type: "multi-select",
        onInputChange: value => {
          formik?.setFieldValue("search", value);
        },
        options: contacts?.edges?.map(item => ({
          label: item.name,
          value: item.id,
        })),
      },
      {
        label: "Channel",
        name: "channelId",
        type: "select",
        options: consignmentChannels?.map(item => ({
          label: item.description,
          value: item.id,
        })),
      },
      {
        label: "Company",
        name: "companyId",
        type: "select",
        options: companies?.edges?.map(item => ({
          label: item.name,
          value: item.id,
        })),
      },
      { label: "Date From", name: "dateFrom", type: "date" },
      { label: "Date To", name: "dateTo", type: "date" },
      { label: "Due Date From", name: "dueFrom", type: "date" },
      { label: "Due Date To", name: "dueTo", type: "date" },
      {
        label: "Type",
        name: "typeId",
        type: "select",
        options: consignmentTypes?.map(item => ({
          label: item.description,
          value: item.id,
        })),
      },
    ],
    FETCH_QUERY: fetchConsignments({
      channel: {},
      company: {},
      consigner: {},
      status: {},
      type: {},
    }),
    variables: {
      contactId,
    },
    className: "sm:px-0 lg:px-0",
  };

  return (
    <div className="w-full">
      <SectionHeader title="Consignments" />
      <div className="mb-6 w-full">
        <Table {...tableProps} />
      </div>
    </div>
  );
};

export default ContactRequests;
