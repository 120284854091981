import { gql } from "@apollo/client";
import { User as user } from "../fragments";

const FETCH_USER = props => gql`
  query FetchUser($id: Int) {
    user(id: $id) {
      ${user(props)}
    }
  }
`;

const FETCH_USERS = props => gql`
  query FetchUsers($input: UserFilterInput!) {
    users(input: $input) {
      count
      edges {
        ${user(props)}
      }
      activeUsers
      inactiveUsers
    }
  }
`;

export { FETCH_USER, FETCH_USERS };
