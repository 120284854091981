import contactFrag from "./Contact";

const Artist = ({ contact = false } = {}) => `
  id
  ${
    contact
      ? `contact {
      ${contactFrag(contact)}
    }`
      : ""
  }
  contactId
  droitdesuite
  name
  terms
`;

export default Artist;
