import React from "react";
import { useQuery } from "@apollo/client";

import SectionHeader from "../../components/SectionHeader";
import { GridInputs, Table } from "../../../../../../components/Functional";
import { Button, Modal } from "../../../../../../components/ui";
import AddTechnical from "./components/AddTechnical";
import Actions from "./components/Actions";
import { FETCH_CRM_REGISTRARS_V2 as fetchCRMRegistrarsV2 } from "../../../../../../graphql/query/CRMRegistrar";
import { FETCH_CRM_TYPES } from "../../../../../../graphql/query/CRMType";
import { UPDATE_CRM_REGISTRAR as updateCRMRegistrarMutation } from "../../../../../../graphql/mutation/CRMRegistrar";

const InstanceTechnical = props => {
  const { instanceId, instance, formik } = props || {};
  const { data: { crmTypes = [] } = {} } = useQuery(FETCH_CRM_TYPES);

  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "crmRegistrarsV2",
    headers: [
      { label: "Date", name: "date", type: "date" },
      // { label: "Type", name: "type.description", type: "label" },
      {
        label: "Type",
        name: "typeId",
        options: crmTypes?.map(item => ({
          label: item?.description,
          value: item?.id,
        })),
        type: "input",
        inputType: "multi-select",
      },
      {
        label: "Description",
        name: "description",
        type: "input",
        inputType: "textarea",
      },
      { label: "User", name: "user.name", type: "label" },
      { label: "Issue", name: "issueId", type: "label" },
      {
        label: "",
        name: "actions",
        type: "custom",
        component: Actions,
        instance,
      },
    ],
    inputs: [],
    FETCH_QUERY: fetchCRMRegistrarsV2({ user: {} }),
    UPDATE_MUTATION: updateCRMRegistrarMutation(),
    variables: {
      categoryId: {
        operator: "eq",
        value: 3,
      },
      _instance: {
        id: {
          operator: "eq",
          value: instanceId,
        },
      },
    },
    className: "sm:px-0 lg:px-0",
  };

  const modalProps = {
    body: AddTechnical,
    instanceId: +instanceId,
    hideCloseButton: true,
    scale: "md",
  };

  const technicalActions = [
    {
      type: "black",
      label: "ADD RECORD",
      action: () => null,
      component: () => (
        <Modal {...modalProps}>
          <Button label="Add Technical" action="dark" />
        </Modal>
      ),
    },
  ];

  const inputs = {
    inputs: [
      {
        className: "grid grid-cols-1 gap-4",
        inputs: [
          {
            label: "Activity",
            name: "_",
            type: "text",
            className: "w-1/4",
            disabled: true,
            submitOnBlur: true,
          },
          {
            label: "Link",
            name: "link",
            type: "href",
            submitOnBlur: true,
          },
        ],
      },
    ],
  };

  const inputProps = {
    formik,
    ...inputs,
  };

  return (
    <div className="w-full">
      <SectionHeader title="Technical" actions={technicalActions} />
      <div className="w-full border-b px-12 py-2 pb-8">
        <GridInputs {...inputProps} />
      </div>
      <div className="flex w-full">
        <div className="flex-1">
          <Table {...tableProps} />
        </div>
      </div>
    </div>
  );
};

export default InstanceTechnical;
