import { gql } from "@apollo/client";
import { User as user } from "../fragments";

const GENERATE_USER_TOKEN = gql`
  mutation GenerateUserToken {
    generateUserToken {
      success
      error
      token
    }
  }
`;

const DELETE_USER = gql`
  mutation DeleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      success
      error
    }
  }
`;
const GET_SIGNED_URL = gql`
  mutation GetSignedUrl($input: GetSignedUrlInput!) {
    getSignedUrl(input: $input) {
      success
      error
      url
      signedRequest
    }
  }
`;
const LOGIN = gql`
  mutation login {
    login {
      success
      error
    }
  }
`;
const LOGOUT = gql`
  mutation logout {
    logout {
      success
      error
    }
  }
`;
const UPLOAD_FILE = gql`
  mutation UploadFile($file: Upload!, $isPublic: Boolean) {
    uploadFile(file: $file, isPublic: $isPublic) {
      error
      filename
      size
      success
      url
    }
  }
`;
const UPDATE_USER = props => gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      success
      error
      user {
        ${user(props)}
      }
    }
  }
`;

export {
  DELETE_USER,
  GENERATE_USER_TOKEN,
  GET_SIGNED_URL,
  LOGIN,
  LOGOUT,
  UPLOAD_FILE,
  UPDATE_USER,
};
