/* eslint-disable new-cap */
import { gql } from "@apollo/client";
import { Email as email } from "../fragments";

const CREATE_EMAIL = props => gql`
  mutation CreateEmail($input: CreateEmailInput!) {
    createEmail(input: $input) {
      email {
        ${email(props)}
      }
      error
      success
    }
  }
`;

const DELETE_EMAIL = gql`
  mutation DeleteEmail($input: DeleteEmailInput!) {
    deleteEmail(input: $input) {
      error
      success
    }
  }
`;

const DELETE_EMAIL_CONTACTS = props => gql`
  mutation DeleteEmailContacts($input: DeleteEmailContactsInput!) {
    deleteEmailContacts(input: $input) {
      email {
        ${email(props)}
      }
      error
      success
    }
  }
`;

const DUPLICATE_EMAIL = props => gql`
  mutation DuplicateEmail($input: DuplicateEmailInput!) {
    duplicateEmail(input: $input) {
      email {
        ${email(props)}
      }
      error
      success
    }
  }
`;

const IMPORT_CSV = props => gql`
  mutation ImportCSV($input: ImportCSVInput!) {
    importCSV(input: $input) {
      email {
        ${email(props)}
      }
      error
      success
    }
  }
`;

const UPLOAD_CSV = gql`
  mutation UploadCSV($input: UploadCSVInput!) {
    uploadCSV(input: $input) {
      data
      error
      filename
      size
      success
      url
    }
  }
`;

const SEND_EMAIL = props => gql`
  mutation SendEmail($input: SendEmailInput!) {
    sendEmail(input: $input) {
      email {
        ${email(props)}
      }
      error
      result
      success
    }
  }
`;

const SEND_TEST_EMAIL = gql`
  mutation SendTestEmail($input: SendTestEmailInput!) {
    sendTestEmail(input: $input) {
      error
      result
      success
    }
  }
`;

const UPDATE_EMAIL = props => gql`
  mutation UpdateEmail($input: UpdateEmailInput!) {
    updateEmail(input: $input) {
      email {
        ${email(props)}
      }
      error
      success
    }
  }
`;

const UNSCHEDULE_EMAIL = props => gql`
  mutation UnscheduleEmail($input: UnscheduleEmailInput!) {
    unscheduleEmail(input: $input) {
      email {
        ${email(props)}
      }
      error
      success
    }
  }
`;

export {
  CREATE_EMAIL,
  DELETE_EMAIL,
  DELETE_EMAIL_CONTACTS,
  DUPLICATE_EMAIL,
  IMPORT_CSV,
  UPLOAD_CSV,
  SEND_EMAIL,
  SEND_TEST_EMAIL,
  UNSCHEDULE_EMAIL,
  UPDATE_EMAIL,
};
