import { useMutation } from "@apollo/client";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { Button, IconButton, Modal } from "../../../../../components/ui";

import { DELETE_USER } from "../../../../../graphql/mutation/User";

const Actions = props => {
  const { id, values } = props || {};
  const navigate = useNavigate();

  const [deleteUser, { client, loading }] = useMutation(DELETE_USER);

  const handleEdit = () => {
    navigate(`${id}/edit`);
  };

  const modalProps = {
    title: "Are you sure?",
    scale: "sm",
    description: `Would you like to delete User: ${values?.email ?? "n/a"}?`,
    closeOnBackdrop: true,
    body: ({ closeModal }) => {
      const handleDelete = () => {
        deleteUser({
          variables: {
            input: { id },
          },
          update: (cache, { data }) => {
            const { deleteUser: { success } = {} } = data;
            if (success) {
              // TODO: handle this through updating cache to improve performance
              closeModal();
              client.resetStore();
              // cache.evict({
              //   id: cache.identify({
              //     __typename: 'Document',
              //     id,
              //   }),
              // });
            }
          },
        });
      };

      return (
        <div className="mt-4 flex">
          <Button
            className="mr-3"
            label={"Cancel"}
            action={"default"}
            onClick={() => {
              closeModal?.();
            }}
          />
          <Button
            label={loading ? "Deleting" : "Delete"}
            disabled={loading}
            onClick={handleDelete}
          />
        </div>
      );
    },
  };

  if (typeof id == "number") {
    return (
      <div className="user-list-actions flex text-right text-sm font-medium">
        {id && (
          <div className="grid grid-cols-2 gap-4">
            {/* <ShareIcon className={`h-4 w-4 cursor-pointer text-gray-400`}/> */}
            <IconButton
              variant="clean"
              title="Edit User"
              className="h-6 w-6 cursor-pointer text-gray-400"
              onClick={handleEdit}
            >
              <PencilIcon />
            </IconButton>
            <Modal {...modalProps}>
              <IconButton
                variant="clean"
                title="Delete User"
                className="h-6 w-6 cursor-pointer text-gray-400"
              >
                <TrashIcon />
              </IconButton>
            </Modal>
          </div>
        )}
      </div>
    );
  } else {
    return false;
  }
};

export default Actions;
