import { useEffect, Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";

import { getInput, IconButton } from "../..";
import { classNames } from "../../../../util";

const Checkbox = props => {
  const { formik, name, rows = [], label, showAll = true } = props || {};
  const { value } = formik.getFieldProps(name);
  const options = [
    ...(showAll
      ? [{ label: `All ${label}`, value: { checked: true, select: "all" } }]
      : []),
    { label: "All On This Page", value: { checked: true, select: "current" } },
    { label: "None", value: { checked: false, select: "none" } },
  ];

  const checkboxInputProps = {
    disabled: true,
    options: [{ value: true }],
    value: value?.checked,
    type: "checkbox",
  };

  const handleClick = props => {
    const { checked } = props || {};
    const ids = value?.ids || {};

    if (checked) {
      rows?.forEach(item => {
        ids[`_${item?.id}`] = item;
      });
    } else {
      rows?.forEach(item => {
        delete ids[`_${item?.id}`];
      });
    }

    formik?.getFieldHelpers(name)?.setValue({ ...props, ids });
  };

  // unselect check box if all rows are not selected
  useEffect(() => {
    const { ids = {} } = value || {};
    // id's within rows that are not selected
    const unselected = rows?.some(item => !ids[`_${item?.id}`]);

    if (rows?.length) {
      if (unselected && value?.checked) {
        formik
          ?.getFieldHelpers(name)
          ?.setValue({ ...value, checked: false, select: null });
      } else if (unselected == false && value?.checked == false) {
        formik
          ?.getFieldHelpers(name)
          ?.setValue({ ...value, checked: true, select: "current" });
      }
    }
  }, [value?.ids, rows]);

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            className={`flex items-center hover:bg-gray-50 focus:outline-none`}
          >
            {getInput(checkboxInputProps)}
            <IconButton variant="clean" title="Filter">
              <ChevronDownIcon className="h-4 w-4" />
            </IconButton>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-0 z-10 mt-3 w-max max-w-xs transform">
              <div className="flex flex-col gap-2 overflow-hidden rounded-lg bg-white pt-2 shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="cursor-default px-4 font-bold">Select</div>
                <div>
                  {options?.map((item, index) => {
                    const active = value?.select == item?.value?.select;
                    return (
                      <div
                        key={index}
                        className={classNames(
                          active ? "bg-gray-100 text-black" : "text-gray-900",
                          "relative cursor-pointer select-none px-4 py-2 font-medium hover:bg-gray-50",
                        )}
                        onClick={() => handleClick(item?.value)}
                      >
                        {item?.label}
                      </div>
                    );
                  })}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export { Checkbox };
