import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";

import { Button } from "../../../../components/ui";

import { CREATE_OFFERS as createOffersMutation } from "../../../../graphql/mutation/Offer";

const AddToOfferSelectList = props => {
  const { closeModal, ids, listId, search, select } = props || {};
  const navigate = useNavigate();
  const [createOffers, { loading, reset }] = useMutation(
    createOffersMutation({ contact: {}, currency: {}, status: {} }),
    { refetchQueries: ["FetchOffersV2"] },
  );

  const formik = useFormik({
    initialValues: {
      ...(select === "all"
        ? { ...(listId ? { listId } : { search }) }
        : { instanceIds: ids }),
      source: "Instance",
    },
    enableReinitialize: true,
    onSubmit: values => {
      createOffers({
        variables: {
          input: values,
        },
      }).then(resp => {
        const {
          data: { createOffers: { success } = {} },
        } = resp || {};
        if (success) {
          closeModal?.();
          reset?.();
          formik?.resetForm();
          navigate(`/offer/?status=selectListWorking`);
        }
      });
    },
  });

  return (
    <div className="flex w-full flex-col p-8">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          Create offers for selected Instances?
        </div>
        <div className="flex flex-row">
          <Button
            action="default"
            className="mr-8"
            label="Cancel"
            onClick={() => {
              closeModal();
              formik?.resetForm();
            }}
          />
          <Button
            label={loading ? "Creating" : "Create"}
            disabled={loading || !formik?.isValid}
            onClick={formik.submitForm}
          />
        </div>
      </div>
    </div>
  );
};

export default AddToOfferSelectList;
