import React from "react";

import { getInput } from "../../../../../../../components/ui";
import { useQuery } from "@apollo/client";
import { FETCH_COUNTRIES } from "../../../../../../../graphql/query/Country";

const Origin = ({ formik, submitOnBlur = false, shipment }) => {
  const { data: { countries = [] } = {} } = useQuery(FETCH_COUNTRIES);

  const inputs = [
    {
      label: "",
      name: "oriContactId",
      type: "contactSearch",
      submitOnBlur,
      disabled: shipment.itemCount > 0,
    },
    {
      label: "Name",
      name: "oriName",
      type: "text",
      submitOnBlur,
      disabled: shipment.itemCount > 0,
    },
    {
      label: "Company",
      name: "oriCompany",
      type: "text",
      submitOnBlur,
      disabled: shipment.itemCount > 0,
    },
    {
      label: "Address",
      name: "oriAddress",
      type: "text",
      submitOnBlur,
      disabled: shipment.itemCount > 0,
    },
    {
      label: "",
      name: "oriAddress2",
      type: "text",
      submitOnBlur,
      disabled: shipment.itemCount > 0,
    },
    {
      label: "",
      name: "oriAddress3",
      type: "text",
      submitOnBlur,
      disabled: shipment.itemCount > 0,
    },
    {
      label: "Town",
      name: "oriTown",
      type: "text",
      submitOnBlur,
      disabled: shipment.itemCount > 0,
    },
    {
      label: "County",
      name: "oriCounty",
      type: "text",
      submitOnBlur,
      disabled: shipment.itemCount > 0,
    },
    {
      label: "Country",
      name: "oriCountryId",
      type: "multi-select",
      options: countries?.map(({ id, name }) => ({ value: id, label: name })),
      submitOnBlur,
      disabled: shipment.itemCount > 0,
    },
    {
      label: "Postcode",
      name: "oriPostcode",
      type: "text",
      submitOnBlur,
      disabled: shipment.itemCount > 0,
    },
  ];

  return (
    <div className="grid-cols-1 gap-4">
      <div>
        <h3 className="mb-4 text-lg font-bold text-gray-900">Origin</h3>
      </div>
      {inputs?.map((item, index) => {
        const inputProps = {
          formik,
          ...item,
          ...formik?.getFieldProps(item.name),
        };

        return (
          <div key={index} className="pb-6">
            {getInput(inputProps)}
          </div>
        );
      })}
    </div>
  );
};

export default Origin;
