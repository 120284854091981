import { useState } from "react";
import { useQuery } from "@apollo/client";

import FilterBar from "./components/FilterBar";

import Table from "../../../../components/Table";
import { Pagination } from "../../../../components/ui";
import { FETCH_CHANGE_LOGS as fetchChangeLogs } from "../../../../graphql/query/ChangeLog";
import { useDebounce } from "../../../../util";
import Header from "../../components/HeaderComponent";

const Changelog = props => {
  const limit = 10;
  const [filters, setFilters] = useState({ limit, offset: 0 });
  const debouncedFilters = useDebounce(filters, 300);
  const skip = Object.keys(debouncedFilters).length === 0;
  const { previousData, data, loading } = useQuery(
    fetchChangeLogs({ user: {} }),
    { skip, variables: { input: { ...debouncedFilters } } },
  );
  const { changeLogs } = data || previousData || {};
  const { count, edges = [] } = changeLogs || {};

  const tableProps = {
    headers: [
      { label: "User", name: "user.email", type: "label" },
      { label: "Section", name: "meta.section", type: "label" },
      { label: "Action", name: "meta.action", type: "label" },
      { label: "Date", name: "createdAt", type: "label" },
    ],
    limit,
    loading,
    rows: edges,
  };

  const onChange = values => {
    setFilters({ ...filters, ...values });
  };

  const paginationProps = {
    count,
    limit,
    offset: filters?.offset,
    onChange,
  };

  const filterBarProps = {
    onChange,
  };

  return (
    <>
      <header>
        <Header leftText="Changelog" rightText="PDF Generator" />
        <div className="flex flex-grow items-center justify-between">
          <FilterBar {...filterBarProps} />
        </div>
      </header>
      <main>
        <div className="flex-col p-4 sm:px-0">
          <Table {...tableProps} />
          <Pagination {...paginationProps} />
        </div>
      </main>
    </>
  );
};

export default Changelog;
