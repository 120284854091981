import React from "react";
import Origin from "./components/Origin";
import Delivery from "./components/Delivery";
import Customs from "./components/Customs";
import Declarations from "./components/Declarations";
import Insurance from "./components/Insurance";
import SectionHeader from "../../components/SectionHeader";

const Details = props => {
  const { formik, shipment, shipmentHasConfirmedItems } = props || {};

  return (
    <div className="w-full">
      <SectionHeader title="Details" />
      <div className="w-full px-8">
        <div className="grid grid-cols-4 gap-16">
          <Origin formik={formik} submitOnBlur={true} shipment={shipment} />
          <Delivery
            formik={formik}
            submitOnBlur={true}
            shipment={shipment}
            shipmentHasConfirmedItems={shipmentHasConfirmedItems}
          />
          <Customs formik={formik} submitOnBlur={true} />
          <div>
            <Declarations formik={formik} submitOnBlur={true} />
            <Insurance formik={formik} submitOnBlur={true} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;
