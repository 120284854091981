import companyFrag from "./Company";
import ConsignmentChannel from "./ConsignmentChannel";
import ConsignmentStatus from "./ConsignmentStatus";
import ConsignmentType from "./ConsignmentType";
import Currency from "./Currency";
import contactFrag from "./Contact";
import exhibitionFrag from "./Exhibition";
import consignmentFrag from "./ConsignmentWork";
import Entity from "./Entity";

const Consignment = ({
  channel = false,
  company = false,
  consigner = false,
  consignmentWorks = false,
  contact = false,
  currency = false,
  exhibition = false,
  registrar = false,
  salesUser = false,
  status = false,
  type = false,
} = {}) => `
  id
  artistUserId
  ${
    channel
      ? `channel {
      ${ConsignmentChannel}
    }`
      : ""
  }
  channelId
  ${
    company
      ? `company {
      ${companyFrag(company)}
    }`
      : ""
  }
  companyId
  colAddress
  colAddress2
  colAddress3
  colCompany
  colContactId
  colCounty
  colCountryId
  colName
  colPostcode
  colTown
  consigneeCompany
  consigneeEmail
  consigneeFax
  consigneeName
  ${
    consigner
      ? `consigner {
      ${contactFrag(consigner)}
    }`
      : ""
  }
  consignerId
  consigneeTel
  ${
    contact
      ? `contact {
      ${contactFrag(contact)}
    }`
      : ""
  }
  contactId
  ${
    consignmentWorks
      ? `consignmentWorks {
      ${consignmentFrag(consignmentWorks)}
    }`
      : ""
  }
  credit
  ${
    currency
      ? `currency {
      ${Currency}
    }`
      : ""
  }
  currencyId
  date
  due
  exchange
  ${
    exhibition
      ? `exhibition {
      ${exhibitionFrag(exhibition)}
    }`
      : ""
  }
  exhibitionId
  guarantee
  notes
  noOfConsignmentWorks
  photoMaterial
  registrarId
  ${
    registrar
      ? `registrar {
      ${Entity}
    }`
      : ""
  }
  retAddress
  retAddress2
  retAddress3
  retCompany
  retContactId
  retCountryId
  retCounty
  retName
  retPostcode
  retTown
  salesUserId
  ${
    salesUser
      ? `salesUser {
      ${Entity}
    }`
      : ""
  }
  shipmentNotes
  ${
    status
      ? `status {
      ${ConsignmentStatus}
    }`
      : ""
  }
  statusId
  terms
  ${
    type
      ? `type {
      ${ConsignmentType}
    }`
      : ""
  }
  typeId
  updatedAt
`;

export default Consignment;
