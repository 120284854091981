import ConditionStatus from "./ConditionStatus";

const Condition = ({
  instance = false,
  mountStatus = false,
  status = false,
} = {}) => `
  id
  actionRequired
  actionTaken
  annotationUrl
  artistIntent
  artistIntentInclude
  date
  description
  details
  detailsExternal
  detailsExternalInclude
  detailsInternal
  detailsInternalInclude
  dimsArtwork
  dimsFramed
  exhibition
  exhibitionInclude
  fixings
  frame
  image
  ${
    instance
      ? `instance {
      id
      edition {
        id
        artwork {
          id
          title
        }
      }
      name
    }`
      : ""
  }
  instanceId
  jobId
  link
  location
  mount
  ${
    mountStatus
      ? `mountStatus {
      ${ConditionStatus}
    }`
      : ""
  }
  mountStatusId
  neon
  packing
  packSpec
  staff
  ${
    status
      ? `status {
      ${ConditionStatus}
    }`
      : ""
  }
  statusId
  technician
  time
  user
  userCreate
  work
`;

export default Condition;
