import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/24/solid";

const Accordion = props => {
  const { children, label, open, variant = "default" } = props || {};

  const styles = {
    default: {
      button:
        "items-center flex justify-between rounded-lg bg-black-100 py-2 focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75",
      chevronPosition: "right",
      panel: "px-4 pt-4 pb-2 text-gray-500",
    },
    left: {
      button:
        "items-center flex rounded-lg bg-black-100 py-2 focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75",
      icon: "mr-2",
      chevronPosition: "left",
      panel: "mx-7",
    },
  };

  const style = styles?.[variant] || {};

  const ChevronIcon = ({ open }) => {
    return (
      <div className={style?.icon}>
        <ChevronUpIcon
          className={`${
            open ? "rotate-180 transform" : "rotate-90 transform"
          } text-black-500 h-5 w-5`}
        />
      </div>
    );
  };

  return (
    <Disclosure defaultOpen={open}>
      {({ open }) => (
        <>
          <Disclosure.Button className={style?.button}>
            {style?.chevronPosition === "left" && <ChevronIcon open={open} />}
            <div className={style?.label}>{label}</div>
            {style?.chevronPosition === "right" && <ChevronIcon open={open} />}
          </Disclosure.Button>
          <Disclosure.Panel className={style?.panel}>
            {children}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export { Accordion };
