import PropTypes from "prop-types";

const Header = props => {
  const { rightComponent, rightText, leftText } = props;

  return (
    <div className="flex flex-row justify-between border-b px-8 py-6">
      <div className="flex flex-row items-center">
        <div className="mr-4 text-xl font-bold">{leftText}</div>
        {rightText && (
          <>
            |
            <div className="pl-4 text-xl font-bold text-black">{rightText}</div>
          </>
        )}
      </div>
      {rightComponent?.()}
    </div>
  );
};

Header.propTypes = {
  leftText: PropTypes.string,
  rightText: PropTypes.string,
  rightComponent: PropTypes.element,
};

export default Header;
