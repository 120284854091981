import { gql } from "@apollo/client";
import { WarehouseSite } from "../fragments";

const FETCH_WAREHOUSE_SITES = gql`
  query FetchWarehouseSites {
    warehouseSites {
      ${WarehouseSite}
    }
  }
`;

export { FETCH_WAREHOUSE_SITES };
