/* eslint-disable require-jsdoc */
import React, { useEffect, useRef, useState } from "react";
import { useMutation } from "@apollo/client";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { createRoot } from "react-dom/client";

import { UPLOAD_FILE } from "../../../../graphql/mutation/User";

class MyUploadAdapter {
  constructor(props) {
    const { loader, uploadFile } = props || {};
    // The file loader instance to use during the upload.
    this.loader = loader;
    this.uploadFile = uploadFile;
  }

  // Starts the upload process.
  upload() {
    // Return a promise that will be resolved when the file is uploaded.
    return this.loader.file.then(file => {
      return new Promise((resolve, reject) => {
        this.uploadFile({
          variables: {
            file,
            isPublic: true,
          },
        }).then(resp => {
          const { data: { uploadFile: { error, success, url } = {} } = {} } =
            resp || {};
          if (success) {
            resolve({
              default: url,
            });
          } else {
            reject(error);
          }
        });
      });
    });
  }
}

const MergeTags = props => {
  const { options, handleInsert } = props;
  const [active, setActive] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    // add event listener for click outside
    document.addEventListener("click", handleClickOutside, true);
    // remove event listener on cleanup
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const handleClickOutside = e => {
    // if the click was outside the dropdown, set active to false
    if (ref.current && !ref.current.contains(e.target)) setActive(false);
  };

  const handleClick = e => {
    // if the click was inside the dropdown, set active
    if (ref.current.contains(e.target)) setActive(true);
  };

  return (
    <div ref={ref} className="ck ck-dropdown ck-heading-dropdown">
      <button
        className={`ck ck-button ${!active ? "ck-off" : "ck-on"} ck-button_with-text ck-dropdown__button`}
        onClick={handleClick}
      >
        <span
          className="ck ck-button__label"
          id="ck-editor__aria-label_e774bec3de7ea3b225cd785b1caf2cbf6"
        >
          Merge Tags
        </span>
        <svg
          className="ck ck-icon ck-reset_all-excluded ck-icon_inherit-color ck-dropdown__arrow"
          viewBox="0 0 10 10"
        >
          <path d="M.941 4.523a.75.75 0 1 1 1.06-1.06l3.006 3.005 3.005-3.005a.75.75 0 1 1 1.06 1.06l-3.549 3.55a.75.75 0 0 1-1.168-.136L.941 4.523z"></path>
        </svg>
      </button>
      <div
        className={`ck ck-reset ck-dropdown__panel ck-dropdown__panel_se ${!active ? "" : "ck-dropdown__panel-visible"}`}
      >
        <ul className="ck ck-reset ck-list">
          {options?.map((option, index) => {
            return (
              <li
                key={index}
                className="ck ck-list__item"
                onClick={() => {
                  setActive(false);
                  handleInsert?.(option?.value);
                }}
              >
                <button className="ck ck-button ck-heading_paragraph ck-off ck-button_with-text">
                  <span className="ck ck-button__label">{option?.text}</span>
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

const CKEditorText = ({ formik, ...props }) => {
  const [uploadFile] = useMutation(UPLOAD_FILE);

  const {
    className,
    error = "",
    label,
    mailMergeOptions,
    onChange,
    value,
    variant,
    mergeTags = true,
  } = props;
  const variants = {
    default: {
      label: "block text-sm font-medium text-black mb-2",
      wrapper: "border border-black rounded",
      editor: "h-[300px] overflow-auto m-0 p-4 py-2",
    },
    simpleRight: {
      label: "block text-xl font-bold font-medium text-black mb-4",
      wrapper: "",
      editor: "h-[300px] overflow-auto m-0 p-4 py-2",
    },
  };
  const classes = variants[variant] || variants.default;
  return (
    <>
      <div className={`flex flex-1 flex-col ${className} z-0`}>
        {label && <label className={classes.label}>{label}</label>}
        <CKEditor
          editor={Editor}
          config={{
            simpleUpload: {
              // The URL that the images are uploaded to.
              uploadUrl: "http://example.com",

              // Enable the XMLHttpRequest.withCredentials property.
              withCredentials: true,

              // Headers sent along with the XMLHttpRequest to the upload server.
              headers: {
                "X-CSRF-TOKEN": "CSRF-Token",
                Authorization: "Bearer <JSON Web Token>",
              },
            },
          }}
          data={value}
          onReady={editor => {
            editor.editing.view.change(writer => {
              // writer.setStyle('height', `${400}px`, editor.editing.view.document.getRoot());
              // writer.setStyle('resize', 'vertical', editor.editing.view.document.getRoot());
            });

            editor.plugins.get("FileRepository").createUploadAdapter =
              loader => {
                return new MyUploadAdapter({ loader, uploadFile });
              };

            // editor.editing.view.document.up;
            const toolbar = document.querySelector(".ck .ck-toolbar__items");
            const mergeTagsContainer = document.createElement("div");
            toolbar.appendChild(mergeTagsContainer);
            const root = createRoot(mergeTagsContainer);

            mergeTags &&
              root.render(
                <React.StrictMode>
                  <MergeTags
                    options={mailMergeOptions}
                    handleInsert={value => {
                      editor.model.change(writer => {
                        // Insert the text at the user's current position.
                        editor.model.insertContent(writer.createText(value));
                      });
                    }}
                  />
                </React.StrictMode>,
              );
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            onChange(data);
          }}
          onBlur={(event, editor) => {
            const data = editor.getData();
            onChange(data);
          }}
        />
        {error && <small className="text-sm text-red-600">{error}</small>}
      </div>
    </>
  );
};

export default CKEditorText;
