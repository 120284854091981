import * as React from "react";
import { Link, useParams } from "react-router-dom";

import SectionHeader from "../../components/SectionHeader";
import { Table } from "../../../../../../components/Functional";
import { FETCH_CONSIGNMENTS_V2 as fetchConsignmentsV2 } from "../../../../../../graphql/query/Consignment";
import Actions from "./components/Actions";

const InstanceConsignment = props => {
  const { id: instanceId } = useParams();

  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "consignmentsV2",
    headers: [
      { label: "Type", name: "type.description", type: "label" },
      {
        label: "#",
        name: "id",
        type: "custom",
        component: props => (
          <Link
            className="underline"
            to={`/consignment/${props?.values?.id}/form`}
          >
            {props?.values?.id}
          </Link>
        ),
      },
      { label: "Date", name: "date", type: "date" },
      { label: "Due", name: "due", type: "date" },
      {
        label: "Consigned To",
        type: "custom",
        component: ({ values }) => {
          const { company, id, name } = values?.contact || {};
          return (
            <Link
              className="flex gap-1 underline"
              to={`/contacts/${id}/details`}
            >
              <span className="overflow-hidden text-ellipsis text-base font-medium leading-5 text-black">
                {company || name}
              </span>
            </Link>
          );
        },
      },
      {
        label: "Consigned By",
        type: "custom",
        component: ({ values }) => {
          const { company, id, name } = values?.consigner || {};
          return (
            <Link
              className="flex gap-1 underline"
              to={`/contacts/${id}/details`}
            >
              <span className="overflow-hidden text-ellipsis text-base font-medium leading-5 text-black">
                {company || name}
              </span>
            </Link>
          );
        },
      },
      { label: "Notes", name: "notes", type: "label" },
      { label: "", name: "action", type: "custom", component: Actions },
    ],
    inputs: [],
    FETCH_QUERY: fetchConsignmentsV2({ consigner: {}, type: {}, contact: {} }),
    className: "sm:px-0 lg:px-0",
    variables: {
      _consignmentWork: {
        _instance: {
          id: {
            operator: "eq",
            value: +instanceId,
          },
        },
      },
    },
  };

  return (
    <div className="w-full">
      <SectionHeader title="Consignments" />
      <div className="mb-6 w-full">
        <Table {...tableProps} />
      </div>
    </div>
  );
};

export default InstanceConsignment;
