import { useEffect } from "react";

import { getInput } from "../../../../../components/ui";

const exhibitedOption = [
  { label: "Not Shown", value: 0 },
  { label: "Shown", value: 1 },
  { label: "1st day", value: 2 },
  { label: "2nd day", value: 3 },
  { label: "3rd day", value: 4 },
  { label: "4th day", value: 5 },
  { label: "5th day", value: 6 },
  { label: "Viewing Room", value: 7 },
];

const exhibitionTypeOption = [
  { label: "Art Fair", value: "Artfair" },
  { label: "Solo", value: "Solo" },
  { label: "Group", value: "Group" },
];

const Artfairs = props => {
  const { formik } = props || {};

  useEffect(() => {
    if (formik?.values?.artfairSelectedData?.id) {
      formik.setFieldValue(
        "_exhibitionWork._exhibition.id",
        formik?.values?.artfairSelectedData?.id,
      );
    }
  }, [formik?.values?.artfairSelectedData?.id]);

  const inputs = [
    {
      label: "Art Fair",
      name: "_exhibitionWork._exhibition.id",
      type: "artFairSearch",
      excludeRemoved: true,
    },
    {
      label: "Exhibition Type",
      name: "_exhibitionWork._exhibition.type",
      type: "multi-select",
      options: exhibitionTypeOption,
    },
    {
      label: "Exhibited",
      name: "_exhibitionWork.exhibited",
      type: "multi-select",
      options: exhibitedOption,
    },
  ];

  return (
    <div className="mb-5 mt-5 grid grid-cols-4 gap-16">
      {inputs?.map((item, index) => {
        const inputProps = {
          formik,
          ...item,
        };

        return (
          <div key={index} className="grid-cols-1">
            {getInput(inputProps)}
          </div>
        );
      })}
    </div>
  );
};

export default Artfairs;
