import { useEffect } from "react";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { GridInputs } from "../../../../../../components/Functional";
import { Button, Modal, getInput } from "../../../../../../components/ui";
import { SectionHeader } from "../../../../../../components/ui/Header";
import { currentLocaleISODate, useDebounce } from "../../../../../../util";

import { SPLIT_COST } from "../../../../../../graphql/mutation/Fabrication";
import { FETCH_FABRICATIONS_V2 as fetchFabricationsV2 } from "../../../../../../graphql/query/Fabrication";

const TimeSheets = props => {
  const { formik, jobId } = props;

  const navigate = useNavigate();

  const [
    splitCost,
    { data: { splitCost: { error: spiltCostError } = {} } = {}, loading },
  ] = useMutation(SPLIT_COST, {
    refetchQueries: [fetchFabricationsV2()],
  });

  const costFormik = useFormik({
    initialValues: {},
    validationSchema: Yup.object({
      total: Yup.number()
        .typeError("Invalid amount.")
        .required("Total amount required to allocate cost."),
    }),
  });

  const debouncedValues = useDebounce(formik?.values, 500);
  const debouncedCostValues = useDebounce(costFormik?.values, 500);

  const inputs = {
    className: "grid grid-cols-4 gap-8",
    inputs: [
      {
        className: "grid grid-cols-1 gap-4",
        inputs: [
          { label: "Staff Hours", type: "label" },
          {
            label: "Total Standard Hours",
            name: "hours",
            type: "number-currency",
            allowEmpty: true,
            submitOnBlur: true,
          },
          {
            label: "Total Overtime Hours",
            name: "overtimehours",
            type: "number-currency",
            allowEmpty: true,
            submitOnBlur: true,
          },
          {
            label: "Technical Staff Hours",
            name: "permanentHours",
            type: "number-currency",
            allowEmpty: true,
            submitOnBlur: true,
          },
        ],
      },
      {
        className: "grid grid-cols-1 gap-4",
        inputs: [
          { label: "Sundry Costs", type: "label" },
          {
            label: "Van Hours",
            name: "hoursvan",
            type: "number-currency",
            allowEmpty: true,
            submitOnBlur: true,
          },
          {
            label: "Expenses £",
            name: "expenses",
            type: "number-currency",
            allowEmpty: true,
            submitOnBlur: true,
          },
        ],
      },
      {
        className: "grid grid-cols-1 gap-4",
        inputs: [
          { label: "Rates", type: "label" },
          {
            label: "Technician Rate",
            name: "rate",
            type: "number-currency",
            allowEmpty: true,
            submitOnBlur: true,
          },
          {
            label: "Overtime",
            name: "rateOvertime",
            type: "number-currency",
            allowEmpty: true,
            submitOnBlur: true,
          },
          {
            label: "Technical Staff Rate",
            name: "rateStaff",
            type: "number-currency",
            allowEmpty: true,
            submitOnBlur: true,
          },
          {
            label: "Van Rate",
            name: "ratevan",
            type: "number-currency",
            allowEmpty: true,
            submitOnBlur: true,
          },
        ],
      },
      {
        className: "grid grid-cols-1 gap-4",
        inputs: [
          { label: "Technicians", type: "label" },
          {
            label: "# Technicians",
            name: "technicians",
            type: "number",
            allowEmpty: true,
            submitOnBlur: true,
          },
          {
            label: "Technician Note",
            name: "technicianNotes",
            rows: 5,
            type: "textarea",
            submitOnBlur: true,
          },
          { label: "Link", name: "link", type: "text", submitOnBlur: true },
        ],
      },
    ],
  };

  const inputProps = {
    formik,
    ...inputs,
  };

  const costInputs = {
    className: "grid grid-cols-4 gap-8",
    inputs: [
      {
        className: "grid grid-cols-1 gap-4",
        inputs: [
          {
            label: "Cost Staff",
            name: "costStaff",
            type: "text-currency",
            disabled: true,
          },
        ],
      },
      {
        className: "grid grid-cols-1 gap-4",
        inputs: [
          {
            label: "Cost Tech",
            name: "costTech",
            type: "text-currency",
            disabled: true,
          },
        ],
      },
      {
        className: "grid grid-cols-1 gap-4",
        inputs: [
          {
            label: "Cost Van",
            name: "costVan",
            type: "text-currency",
            disabled: true,
          },
        ],
      },
      {
        className: "grid grid-cols-1 gap-4",
        inputs: [
          {
            label: "Total",
            name: "total",
            type: "text-currency",
            disabled: true,
          },
          {
            label: "Charge Out",
            name: "chargeOutVal",
            type: "text-currency",
            inputBgClass: formik.values?.chargeOut === -1 && "!bg-[#0047FF]",
            disabled: true,
          },
        ],
      },
    ],
  };

  const costInputProps = {
    formik: costFormik,
    ...costInputs,
  };

  useEffect(() => {
    costFormik.setValues({
      ...costFormik.values,
      costStaff:
        (+debouncedValues?.permanentHours || 0) * +debouncedValues?.rateStaff,
      costTech:
        +debouncedValues?.rate * (+debouncedValues?.hours || 0) +
        (+debouncedValues?.overtimehours || 0) * +debouncedValues?.rateOvertime,
      costVan: (+debouncedValues?.hoursvan || 0) * +debouncedValues?.ratevan,
      total:
        costFormik?.values?.costTech +
        costFormik?.values?.costVan +
        +debouncedValues?.expenses,
      chargeOutVal:
        costFormik?.values?.costTech +
        costFormik?.values?.costVan +
        debouncedValues?.expenses +
        costFormik?.values.costStaff,
    });
  }, [debouncedValues, debouncedCostValues]);

  const allocateCost = () => {
    splitCost({
      variables: {
        input: {
          amount: costFormik?.values?.total,
          currencyId: 1, // 1 for GBP
          date: currentLocaleISODate(),
          jobId,
        },
      },
    }).then(({ data }) => {
      const { splitCost: { success } = {} } = data;
      if (success) {
        formik.resetForm();
        navigate(`/jobs/${jobId}/costs`);
      }
    });
  };

  const actions = [
    {
      component: () => {
        return (
          <>
            <Button
              label={loading ? "Allocating Cost" : "Allocate Cost"}
              disabled={loading || !costFormik?.isValid}
              onClick={() => allocateCost()}
            />
          </>
        );
      },
    },
  ];

  const chargeOutProps = {
    formik,
    name: "chargeOut",
    options: [{ label: "Charge Out", value: -1 }],
    singleCheckbox: true,
    type: "checkbox",
    submitOnBlur: true,
  };

  const errorModalProps = {
    title: "Error",
    scale: "sm",
    description: spiltCostError,
    closeOnBackdrop: true,
    body: ({ closeModal }) => {
      return (
        <div className="flex mt-4">
          <Button
            className="mr-3"
            label="Close"
            action="primary"
            onClick={() => {
              closeModal?.();
            }}
          />
        </div>
      );
    },
  };

  return (
    <div className="w-full">
      <SectionHeader title="Timesheet" actions={actions} />
      <div className="px-8 pb-12 mb-8 border-b border-black">
        <GridInputs {...inputProps} />
      </div>
      <div className="w-full">
        <GridInputs {...costInputProps} />
        <div className="grid grid-cols-4 gap-8 mt-4">
          <div className="col-start-4">{getInput(chargeOutProps)}</div>
        </div>
      </div>
      {spiltCostError && <Modal {...{ ...errorModalProps, isOpen: true }} />}
    </div>
  );
};
export default TimeSheets;
