import { useQuery } from "@apollo/client";

import { GridInputs } from "../../../../../../../components/Functional";
import { FETCH_COUNTRIES } from "../../../../../../../graphql/query/Country";
import useUser from "../../../../../../../hooks/useUser";

const BillingDetail = props => {
  const { data: { countries = [] } = {} } = useQuery(FETCH_COUNTRIES);

  const { formik, submitOnBlur = false } = props;
  const { hasPermission } = useUser();

  const inputs = {
    className: "grid grid-cols-4 gap-8 mt-5",
    inputs: [
      {
        className: "grid-cols-1 gap-4",
        inputs: [
          {
            label: "Name",
            name: "name",
            type: "text",
            submitOnBlur,
            disabled: !hasPermission("UPDATE_INVOICE"),
          },
          {
            label: "Company",
            name: "contact.company",
            type: "text",
            submitOnBlur,
            disabled: !hasPermission("UPDATE_INVOICE"),
          },
          {
            label: "Address line 1",
            name: "address",
            type: "text",
            submitOnBlur,
            disabled: !hasPermission("UPDATE_INVOICE"),
          },
          {
            label: "Address line 2",
            name: "address2",
            type: "text",
            submitOnBlur,
            disabled: !hasPermission("UPDATE_INVOICE"),
          },
          {
            label: "Address line 3",
            name: "address3",
            type: "text",
            submitOnBlur,
            disabled: !hasPermission("UPDATE_INVOICE"),
          },
        ],
      },
      {
        className: "grid-cols-1 gap-4",
        inputs: [
          {
            label: "Town",
            name: "town",
            type: "text",
            submitOnBlur,
            disabled: !hasPermission("UPDATE_INVOICE"),
          },
          {
            label: "County",
            name: "county",
            type: "text",
            submitOnBlur,
            disabled: !hasPermission("UPDATE_INVOICE"),
          },
          {
            label: "Country",
            name: "countryId",
            type: "select",
            options: countries?.map(item => ({
              label: item.name,
              value: item.id,
            })),
            submitOnBlur,
            disabled: !hasPermission("UPDATE_INVOICE"),
          },
          {
            label: "Postcode",
            name: "postcode",
            type: "text",
            submitOnBlur,
            disabled: !hasPermission("UPDATE_INVOICE"),
          },
          {
            label: "Charge Storage",
            name: "chargeStorage",
            type: "toggle",
            designType: "threeStateCheckbox",
            submitOnBlur,
            disabled: !hasPermission("UPDATE_INVOICE"),
          },
        ],
      },
      {
        className: "grid-cols-1 gap-4",
        inputs: [
          {
            label: "Final Payment Date",
            name: "dueDate",
            type: "date",
            submitOnBlur,
            disabled: !hasPermission("UPDATE_INVOICE"),
          },
          {
            label: "Immutable",
            name: "immutable",
            options: [
              { label: "Unlocked", value: 0 },
              { label: "Locked", value: -1 },
            ],
            type: "multi-select",
            submitOnBlur,
            disabled: !hasPermission("UPDATE_INVOICE"),
          },
          {
            label: "Delivery Expectation",
            name: "deliveryExpectation",
            type: "text",
            submitOnBlur,
            disabled:
              !hasPermission("UPDATE_INVOICE") ||
              !hasPermission("UPDATE_INVOICE_SALES_NOTES"),
          },
        ],
      },
      {
        className: "grid-cols-1 gap-4",
        inputs: [
          {
            label: "Payment Schedule",
            name: "shedule",
            type: "textarea",
            submitOnBlur,
            disabled: !hasPermission("UPDATE_INVOICE"),
          },
          {
            label: "Notes",
            name: "notes",
            rows: 10,
            type: "textarea",
            submitOnBlur,
            disabled:
              !hasPermission("UPDATE_INVOICE") ||
              !hasPermission("UPDATE_INVOICE_SALES_NOTES"),
          },
        ],
      },
    ],
  };

  const detailInputProps = {
    formik,
    ...inputs,
  };

  return (
    <>
      <h5 className="font-bold">Billing</h5>
      <GridInputs {...detailInputProps} />
    </>
  );
};

export default BillingDetail;
