import { gql } from "@apollo/client";
import { Contact as contact } from "../fragments";

const FETCH_CONTACT = props => gql`
  query FetchContact($id: Int) {
    contact(id: $id) {
      ${contact(props)}
    }
  }
`;

const FETCH_CONTACTS = props => gql`
  query FetchContacts($input: ContactFilterInput!) {
    contacts(input: $input) {
      count
      edges {
        ${contact(props)}
      }
    }
  }
`;

const FETCH_CONTACTS_V2 = props => gql`
  query FetchContactsV2($input: AdvContactFilterInput!) {
    contactsV2(input: $input) {
      count
      edges {
        ${contact(props)}
      }
      sqlQuery
    }
  }
`;

const FETCH_CONTACT_LOCATIONS = gql`
  query FetchContactLocations {
    contactLocations
  }
`;

const FETCH_CONTACT_PRIMARY_ADDRESS = gql`
  query FetchContactPrimaryAddress($id: Int!) {
    contact(id: $id) {
      address
      address2
      address3
      country {
        id
        continent
        digraph
        name
      }
      county
      town
      postcode
    }
  }
`;

export {
  FETCH_CONTACT,
  FETCH_CONTACTS,
  FETCH_CONTACTS_V2,
  FETCH_CONTACT_LOCATIONS,
  FETCH_CONTACT_PRIMARY_ADDRESS,
};
