import * as React from "react";
import { CheckIcon } from "@heroicons/react/24/solid";

import { GridInputs, Table } from "../../../../../../components/Functional";
import { Button, Modal } from "../../../../../../components/ui";
import { FETCH_PHOTO_MATERIAL_V2 as fetchPhotoMaterialsV2 } from "../../../../../../graphql/query/PhotoMaterial";
import useUser from "../../../../../../hooks/useUser";

import SectionHeader from "../../components/SectionHeader";
import addRecord from "./components/AddRecord";
import Actions from "./components/Actions";

const OriginalComponent = props => {
  // show check icon if original is 0
  return (
    <div className="flex items-center justify-center">
      {props?.values?.original === -1 && (
        <CheckIcon className="h-5 w-5" aria-hidden="true" />
      )}
    </div>
  );
};

const ArtworkPhotographicMaterial = props => {
  const { artworkId, artwork, formik } = props || {};
  const { hasPermission } = useUser();
  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "photoMaterialsV2",
    headers: [
      { label: "Format", name: "format.description", type: "label" },
      {
        label: "Original",
        name: "original",
        type: "custom",
        component: OriginalComponent,
      },
      { label: "Copies", name: "number", type: "label" },
      { label: "Description", name: "description", type: "label" },
      { label: "Date", name: "date", type: "date" },
      { label: "Credit", name: "credit", type: "label" },
      { label: "Photographer", name: "photographer", type: "label" },
      {
        label: "",
        name: "actions",
        type: "custom",
        component: Actions,
        artwork,
      },
    ],
    FETCH_QUERY: fetchPhotoMaterialsV2({ format: true }),
    variables: {
      _artwork: {
        id: {
          operator: "eq",
          value: artworkId,
        },
      },
    },
    className: "sm:px-0 lg:px-0",
  };

  const inputs = {
    className: "grid grid-cols-4",
    inputs: [
      {
        className: "grid grid-flow-col col-span-2 gap-16 items-end",
        inputs: [
          {
            label: "Photography Note",
            name: "photoNote",
            rows: 6,
            type: "textarea",
            submitOnBlur: true,
          },
          {
            disabled: !hasPermission("UPDATE_ARTWORK_PHOTOGRAPHED"),
            designType: "threeStateCheckbox",
            label: "Not photographed",
            name: "photographed",
            type: "toggle",
            submitOnBlur: true,
          },
        ],
      },
    ],
  };

  const detailInputProps = {
    formik,
    ...inputs,
  };

  const modalProps = {
    body: addRecord,
    artworkId,
    hideCloseButton: true,
    scale: "md",
  };

  const addRecordActions = [
    {
      type: "black",
      label: "ADD RECORD",
      action: () => null,
      component: () => (
        <Modal {...modalProps}>
          <Button label="Add Record" action="dark" />
        </Modal>
      ),
    },
  ];

  return (
    <div className="w-full">
      <SectionHeader title="Photographic Material" actions={addRecordActions} />
      <div className="w-full border-b px-16 py-8">
        <GridInputs {...detailInputProps} />
      </div>
      <div className="mb-6 w-full">
        <Table {...tableProps} />
      </div>
    </div>
  );
};

export default ArtworkPhotographicMaterial;
