import { useFormik } from "formik";
import { useEffect } from "react";
import { convertToFilter, makeObservable } from "../../../util";

// observable store
const jobFilterStore = makeObservable({});
const useJobFilters = () => {
  const formik = useFormik({
    initialValues: {
      limit: 50,
      offset: 0,
      tab: "instance",
      sort1: { operator: "ASC" },
      sort2: { operator: "ASC" },
      sort3: { operator: "ASC" },
    },
  });

  const filterKeys = ["tab"];

  const skipKeys = ["limit", "offset"];

  useEffect(() => {
    // convert formik values to filter and update store
    jobFilterStore.set(
      convertToFilter({ values: formik.values, filterKeys, skipKeys }),
    );
  }, [formik.values]);

  return {
    formik,
  };
};

export { jobFilterStore, useJobFilters };
