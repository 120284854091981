import { useState, useEffect } from "react";
import { Button } from "../../../../../../components/ui";
import { ContentZoomView } from "../../../../../../components/ui/ContentZoomView";

const instanceImagePaddingBottom = 16;
const instanceDescriptionHeight = 80;
const maxDescritionsCharacters = 20;

const InstanceComponent = props => {
  const {
    isSelected,
    item,
    onSelectImage,
    selectedInstances,
    instanceId,
    styles = { width: 180, height: 180 },
    instanceKey,
    name,
    zoomImage = false,
    itemsPadding,
    hideSummary = false,
    smallActions = false,
    hideActionsButton = false,
    loading: loadingProp,
  } = props;

  const [showButton, changeShowButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOnHover = () => changeShowButton(true);
  const handleOnBlur = () => changeShowButton(false);
  const trimContent = (text = "", maxLength) => {
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  };
  const labelRemove = smallActions ? "X" : "REMOVE";
  const buttonProps = {
    label: !isSelected ? "SELECT" : labelRemove,
    action: isSelected ? "default" : "black",
    onClick: !isSelected
      ? () => (
          onSelectImage([
            ...selectedInstances,
            {
              archiveId: item?.archiveId,
              artworkId: item?.artworkId,
              instanceId,
            },
          ]),
          setLoading(true)
        )
      : () => (
          onSelectImage(
            [...selectedInstances].filter(s => s.archiveId !== item?.archiveId),
          ),
          setLoading(true)
        ),
  };

  useEffect(() => {
    if (loadingProp == false) {
      setLoading(false);
    }
  }, [loadingProp]);

  const imageStyles = {
    ...styles,
  };
  const imagePath = item?.fieldsData?.image || item?.imgT;
  if (imagePath) {
    imageStyles.backgroundImage = `url("${imagePath}")`;
  } else {
    imageStyles.display = "flex";
    imageStyles.justifyContent = "center";
    imageStyles.alignItems = "center";
  }
  const artistName =
    (item?.artwork?.artists?.map(artist => artist?.name) || [])?.join(", ") ||
    item?.fieldsData?.artist_name;
  const title = item?.artwork?.title || item?.fieldsData?.artwork_title;
  const buttonSize = smallActions ? 30 : 65;
  const marginLeft = -(buttonSize - itemsPadding / 2) / 2;
  const top = styles.height / 2 - 13;
  const getActionButton = (zoomContent = false) =>
    showButton && (
      <div
        className="absolute left-[50%] z-10"
        style={{
          top,
          marginLeft: marginLeft,
          width: buttonSize,
        }}
      >
        <Button {...buttonProps} customPadding="px-2 py-1" />
      </div>
    );
  const getImageContent = (showActionButton = false) => (
    <div
      className="instance-image-bg relative mx-auto overflow-hidden border bg-gray-100 bg-cover"
      style={imageStyles}
    >
      {!imagePath && (
        <div
          className={`text-center ${
            imageStyles.width === 75 ? "w-[40px] text-[10px]" : "w-[69px]"
          }`}
        >
          No Image Available
        </div>
      )}
      {instanceKey && !hideSummary && (
        <div className="z-4 absolute left-0 top-0 h-[100%] w-[100%] bg-black opacity-[.4]" />
      )}
      {showActionButton && getActionButton()}
    </div>
  );
  return (
    <div
      className={`instance-image relative ${loading === true ? "animate-pulse" : ""}`}
      onMouseEnter={handleOnHover}
      onMouseLeave={handleOnBlur}
    >
      {instanceKey && !showButton && (
        <div
          className="number-shadow absolute left-0 z-10 size-3 w-[100%] text-center text-2xl font-bold text-white"
          style={{ top }}
        >
          {instanceKey}
        </div>
      )}
      {zoomImage ? (
        <div
          className="relative mx-auto border bg-gray-100"
          style={{
            ...styles,
            marginBottom: !hideSummary ? instanceImagePaddingBottom : 0,
          }}
        >
          <ContentZoomView
            customContent={getImageContent(false)}
            contentAction={getActionButton(true)}
          />
        </div>
      ) : (
        getImageContent(!hideActionsButton && true)
      )}
      {!hideSummary && (
        <div
          style={{ width: styles.width, height: instanceDescriptionHeight }}
          className="mx-auto overflow-hidden"
        >
          <div className="mb-[3px] text-xs" title={artistName}>
            {trimContent(artistName || "", maxDescritionsCharacters)}
          </div>
          <div className="mb-[3px] text-xs" title={title}>
            {trimContent(title || "", maxDescritionsCharacters)}
          </div>
          <div className="text-xs">
            {name || item?.fieldsData?.instance_code || instanceId}
          </div>
        </div>
      )}
    </div>
  );
};

export default InstanceComponent;
