import useUser from "../../../../../../../../hooks/useUser";
import ArtworkDetailCol1 from "./components/ArtworkDetailCol1";
import ArtworkDetailCol2 from "./components/ArtworkDetailCol2";
import ArtworkDetailCol3 from "./components/ArtworkDetailCol3";
import ArtworkDetailCol4 from "./components/ArtworkDetailCol4";

const disableConfig = {
  disabled: true,
  inputClassName: "!bg-white !text-gray-400 !border-gray-400",
};

const ArtworkDetail = ({ formik, submitOnBlur = false }) => {
  const { hasPermission } = useUser();
  const disabled = !hasPermission("UPDATE_ARTWORK");

  const colProps = {
    formik,
    disabled,
    submitOnBlur,
  };

  return (
    <div className={`grid w-full grid-cols-4 gap-16`}>
      <ArtworkDetailCol1 {...colProps} />
      <ArtworkDetailCol2 {...colProps} disableConfig={disableConfig} />
      <ArtworkDetailCol3 {...colProps} />
      <ArtworkDetailCol4 {...colProps} disableConfig={disableConfig} />
    </div>
  );
};

export default ArtworkDetail;
