import { useQuery } from "@apollo/client";
import { useFormik } from "formik";
import * as React from "react";

import { Table } from "../../../../../../components/Functional";
import { FETCH_CRM_REGISTRARS as fetchCRMRegistrars } from "../../../../../../graphql/query/CRMRegistrar";
import { FETCH_JOB_STATUSES } from "../../../../../../graphql/query/JobStatus";
import { multiSelect } from "../../../../../../components/Functional/CustomHeaders";

import Actions from "./components/Actions";
import AcceptHandoverModal from "./components/AcceptHandoverModal";

import Header from "../../../../components/HeaderComponent";

const ListView = props => {
  const { user } = props || {};
  const { data: { jobStatuses = [] } = {} } = useQuery(FETCH_JOB_STATUSES);

  const formik = useFormik({
    initialValues: {},
  });

  const multiSelectProps = {
    formik,
    label: "Handovers",
    name: "acceptHandovers",
  };

  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "crmRegistrars",
    headers: [
      multiSelect(multiSelectProps),
      { label: "Name", name: "contact.name", type: "label" },
      { label: "Date", name: "date", sortKey: "date", type: "date" },
      { label: "From", name: "salesUser.name", type: "label" },
      { label: "To", name: "user.name", type: "label" },
      { label: "Input By", name: "entity.name", type: "label" },
      { label: "Notes", name: "description", type: "label" },
      { label: "Status", name: "status.description", type: "label" },
      { label: "", name: "action", type: "custom", component: Actions },
    ],
    inputs: [
      {
        label: "Status",
        name: "statusId",
        type: "select",
        options: jobStatuses?.map(item => ({
          label: item.description,
          value: item.id,
        })),
      },
      { label: "Notes", name: "description", type: "text", view: "partial" },
      { label: "From date", name: "from", type: "date", view: "partial" },
      { label: "To date", name: "to", type: "date", view: "partial" },
    ],
    FETCH_QUERY: fetchCRMRegistrars({
      contact: {},
      entity: {},
      salesUser: {},
      status: {},
      user: {},
    }),
    variables: {
      userId: user?.entityId,
      statusId: 1,
      typeId: 25,
    },
    className: "sm:px-0 lg:px-0",
  };

  const handOverActions = [
    {
      component: () => <AcceptHandoverModal formik={formik} />,
    },
  ];

  return (
    <div className="w-full">
      <Header
        leftText="Handover"
        rightComponent={() =>
          handOverActions?.map((item, index) => {
            const Component = item?.component;
            return <Component key={index} />;
          })
        }
      />
      <div className="mb-6 w-full">
        <Table {...tableProps} />
      </div>
    </div>
  );
};

export default ListView;
