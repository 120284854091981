import { gql } from "@apollo/client";
import { ContactNumberType } from "../fragments";

const FETCH_CONTACT_NUMBER_TYPES = gql`
  query FetchContactNumberType {
    contactNumberTypes {
      ${ContactNumberType}
    }
  }
`;

export { FETCH_CONTACT_NUMBER_TYPES };
