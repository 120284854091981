import { gql } from "@apollo/client";
import { EventRsvp } from "../fragments";

const FETCH_EVENT_RSVPS = gql`
  query EventRsvps {
    eventRsvps {
      ${EventRsvp}
    }
  }
`;

export { FETCH_EVENT_RSVPS };
