/* eslint-disable max-len */
const EmailIcon = () => (
  <svg
    width="40px"
    height="40px"
    viewBox="0 0 50 50"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Email Icon</title>
    <defs>
      <path
        d="M39.5833333,0 L6.25,0 C2.70833333,0 8.8817842e-16,2.70833333 8.8817842e-16,6.25 L8.8817842e-16,31.25 C8.8817842e-16,34.7916667 2.70833333,37.5 6.25,37.5 L39.5833333,37.5 C43.125,37.5 45.8333333,34.7916667 45.8333333,31.25 L45.8333333,6.25 C45.8333333,2.70833333 43.125,0 39.5833333,0 Z M6.25,4.16666667 L39.5833333,4.16666667 C40.4166667,4.16666667 41.0416667,4.58333333 41.4583333,5.41666667 L22.9166667,18.3333333 L4.375,5.41666667 C4.79166667,4.58333333 5.41666667,4.16666667 6.25,4.16666667 Z M39.5833333,33.3333333 L6.25,33.3333333 C5,33.3333333 4.16666667,32.5 4.16666667,31.25 L4.16666667,10.2083333 L21.6666667,22.5 C22.0833333,22.7083333 22.5,22.9166667 22.9166667,22.9166667 C23.3333333,22.9166667 23.75,22.7083333 24.1666667,22.5 L41.6666667,10.2083333 L41.6666667,31.25 C41.6666667,32.5 40.8333333,33.3333333 39.5833333,33.3333333 Z"
        id="path-1"
      />
    </defs>
    <g
      id="Email-Icon"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="feather-icon/mail" transform="translate(2.083333, 6.250000)">
        <mask id="mask-2" fill="white">
          <use xlinkHref="#path-1" />
        </mask>
        <use id="Mask" fill="#000000" fillRule="nonzero" xlinkHref="#path-1" />
        <g id="Icon-Color" mask="url(#mask-2)" fill="#212121">
          <g transform="translate(-2.083333, -6.250000)" id="Shape">
            <rect x="0" y="0" width="50" height="40" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default EmailIcon;
