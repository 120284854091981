import { gql } from "@apollo/client";
import { Offer as offer } from "../fragments";

const FETCH_OFFER = props => gql`
  query FetchOffer($id: Int) {
    offer(id: $id) {
      ${offer(props)}
    }
  }
`;

const FETCH_OFFERS = props => gql`
  query FetchOffers($input: OfferFilterInput!) {
    offers(input: $input) {
      count
      edges {
        ${offer(props)}
      }
    }
  }
`;

const FETCH_OFFERS_V2 = props => gql`
  query FetchOffersV2($input: AdvOfferFilterInput!) {
    offersV2(input: $input) {
      count
      edges {
        ${offer(props)}
      }
      sqlQuery
    }
  }
`;

export { FETCH_OFFER, FETCH_OFFERS, FETCH_OFFERS_V2 };
