import { gql } from "@apollo/client";
import { FabricationStage } from "../fragments";

const FETCH_FABRICATION_STAGES = gql`
  query FetchFabricationStages {
    fabricationStages {
      ${FabricationStage}
    }
  }
`;

export { FETCH_FABRICATION_STAGES };
