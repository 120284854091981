import { useState, useRef, useEffect } from "react";
import { IconButton } from "../IconButton";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import FormLoggingSupport from "./components/FormLoggingSupport";
import LoggingSupportUser from "./components/LoggingSupportUser";
import { useLocation } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { CREATE_TICKET } from "../../../graphql/mutation/Support";
import useUser from "../../../hooks/useUser";

const PREFIX_ENUM = {
  development: "DEV: ",
  staging: "STG: ",
  production: "",
};

const getTitle = title =>
  PREFIX_ENUM[process.env.NODE_ENV || "development"].concat(title);

const LoggingSupport = props => {
  // eslint-disable-next-line
  const { onSubmit: openAlert } = props;
  const ref = useRef(null);
  const [formState, setFormState] = useState(false);
  const [step, setStep] = useState("support");
  const location = useLocation();
  const [createTicket] = useMutation(CREATE_TICKET);
  const { user } = useUser();

  const onSubmit = data => {
    const transformedData = {
      name: getTitle(data.ticketTitle),
      issue_type: data.issueType,
      category: {
        name: data.categoryName,
      },
      ...(data.subCategoryName
        ? { subcategory: { name: data.subCategoryName } }
        : {}),
      description: data.description,
      user_agent: window.navigator.userAgent,
      url: location.pathname,
      referring_url: location.pathname,
      local_time: new Date(),
      requester: {
        email: user?.email,
      },
    };
    createTicket({
      variables: {
        input: transformedData,
      },
    })
      .then(() => {
        openAlert({
          text: "Your ticket was successfully created.",
          isOpen: true,
          type: "info",
        });
      })
      .catch(() => {
        openAlert({
          text: "There was an error creating the ticket, please try again later.",
          isOpen: true,
          type: "warning",
        });
      });
    setFormState(false);
    setStep("support");
  };

  const handleClickOutside = e => {
    if (ref?.current && !ref.current.contains(e.target)) {
      setFormState(false);
      setStep("support");
    }
  };
  const getElement = () => {
    switch (step) {
      case "support":
        return (
          <FormLoggingSupport
            nextStep={e => {
              setStep("log");
              e.stopPropagation();
            }}
            onSubmit={onSubmit}
          />
        );
      case "log":
      default:
        return <LoggingSupportUser onSubmit={onSubmit} />;
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div ref={ref}>
      <IconButton
        variant="clean"
        title="Support"
        onClick={() => setFormState(!formState)}
        className="absolute bottom-8 left-3 z-[2] cursor-pointer"
      >
        <QuestionMarkCircleIcon width="32px" height="32px" />
      </IconButton>
      {formState && getElement()}
    </div>
  );
};

export default LoggingSupport;
