import Currency from "./Currency";

const BankAccount = ({ currency = false } = {}) => `
  id
  address
  bank
  county
  ${
    currency
      ? `currency {
      ${Currency}
    }`
      : ""
  }
  details
  holder
  invoiceTerms
  number
  postcode
  sortCode
  town
`;

export default BankAccount;
