import { ArrowDownTrayIcon, EyeIcon } from "@heroicons/react/24/outline";
import { Button, IconButton, Modal } from "../../../../../../../components/ui";
import { TrashIcon } from "@heroicons/react/24/solid";
import { DELETE_SHIPMENT_DOCUMENT } from "../../../../../../../graphql/mutation/ShipmentDocument";
import { useMutation } from "@apollo/client";

const Actions = props => {
  const { id, values } = props || {};
  const [deleteDocument, { loading }] = useMutation(DELETE_SHIPMENT_DOCUMENT, {
    refetchQueries: ["FetchShipmentDocuments"],
  });

  const modalProps = {
    title: "Are you sure?",
    scale: "sm",
    description: `Would you like to delete document titled '${
      values?.fileName ?? "n/a"
    }'?`,
    closeOnBackdrop: true,
    body: ({ closeModal }) => {
      const handleDelete = () => {
        deleteDocument({
          variables: {
            input: { documentPath: id },
          },
        }).then(() => {
          closeModal();
        });
      };

      return (
        <div className="mt-4 flex">
          <Button
            className="mr-3"
            label={"Cancel"}
            action={"default"}
            onClick={() => {
              closeModal?.();
            }}
          />
          <Button
            label={loading ? "Deleting" : "Delete"}
            disabled={loading}
            onClick={handleDelete}
          />
        </div>
      );
    },
  };

  if (typeof id !== "number") {
    return (
      <div className="user-list-actions flex text-right text-sm font-medium">
        {id && (
          <div className="grid grid-cols-4 gap-4">
            <IconButton variant="clean" title="View Document">
              <EyeIcon
                className={`h-4 w-4 cursor-pointer text-gray-400`}
                onClick={() => {
                  window.open(values?.viewUrl, "_blank");
                }}
              />
            </IconButton>
            <IconButton variant="clean" title="Download Document">
              <ArrowDownTrayIcon
                className={`h-4 w-4 cursor-pointer text-gray-400`}
                onClick={() => {
                  window.open(values?.url, "_blank");
                }}
              />
            </IconButton>
            <Modal {...modalProps}>
              <IconButton variant="clean" title="Delete Document">
                <TrashIcon className={`h-4 w-4 cursor-pointer text-gray-400`} />
              </IconButton>
            </Modal>
          </div>
        )}
      </div>
    );
  } else {
    return false;
  }
};

export default Actions;
