import Select from "react-select";
import CreatableSelect from "react-select/creatable";

const MultiSelect = props => {
  const {
    className = "",
    disabled = false,
    error = "",
    handleAction,
    isMulti = false,
    inputClassName,
    name,
    label,
    onChange,
    selected,
    variant,
    bottomLabel,
    isClearable = false,
    isCreatable = false,
    isLoading = false,
  } = props || {};

  const variants = {
    default: {
      container: "flex flex-col flex-1",
      containerMenu: "black",
      label: "block text-xs font-medium text-black mb-1",
      selectContainer: `${inputClassName}`,
    },
    simple: {
      container: "flex flex-col flex-1",
      containerMenu: "",
      label: `block text-xs font-medium text-black ${
        bottomLabel && "order-2 font-bold pt-2"
      }`,
      selectContainer: `${inputClassName} ${bottomLabel && "order-1"}`,
    },
    horizontal: {
      label: "col-span-1 flex-1 sm13 font-medium text-right",
      field: "col-span-2 ml-3 relative flex-1 min-w-max",
      container: "flex items-center grid grid-cols-5 gap-5",
      button: "relative text-left pr-12",
      containerMenu: "",
      selectContainer: `${inputClassName} ${bottomLabel && "order-1"}`,
    },
  };

  const classes = variants[variant] || variants.default;
  const SelectComponent = isCreatable ? CreatableSelect : Select;

  return (
    <div className={`${classes.container} ${className}`}>
      {label && (
        <label htmlFor={name} className={classes.label}>
          {label}
        </label>
      )}
      <div className={`${classes.selectContainer}`}>
        <SelectComponent
          {...props}
          isLoading={isLoading}
          menuPortalTarget={document.body}
          isDisabled={disabled || props.isDisabled}
          key={`${name}_${selected}`}
          isClearable={isClearable}
          menuPosition="fixed"
          menuShouldScrollIntoView={false}
          theme={theme => ({
            ...theme,
            borderRadius: "0.25rem",
            colors: {
              ...theme.colors,
              primary25: "lightgrey",
              primary50: "lightgrey",
              primary: "black",
            },
            borderColor: "black",
          })}
          styles={{
            control: (base, state) => ({
              ...base,
              boxShadow: "none",
              color: disabled ? "lightgrey" : "black",
              borderColor: disabled ? "lightgrey" : "black",
              backgroundColor: disabled ? "white" : base.backgroundColor,
              "*": {
                boxShadow: "none !important",
              },
              "&:hover": {
                borderColor: "black",
              },
            }),
            container: (base, state) => ({
              ...base,
              border: classes.containerMenu,
            }),
            indicatorSeparator: (base, state) => ({
              ...base,
              backgroundColor: disabled ? "lightgrey" : "black",
            }),
            menu: (base, state) => ({
              ...base,
              borderColor: "black",
            }),
            menuPortal: base => ({
              ...base,
              zIndex: 9999,
            }),
          }}
          value={selected}
          onChange={(value, action) => {
            onChange(isMulti ? value : value?.value);
            handleAction?.(value, action);
          }}
        />
      </div>
      {error && <small className="text-sm text-red-600">{error}</small>}
    </div>
  );
};

export default MultiSelect;
