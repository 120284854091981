import userFrag from "./User";

const ChangeLog = ({ user = false } = {}) => `
  id
  createdAt
  meta
  updatedAt
  ${
    user
      ? `user {
      ${userFrag(user)}
    }`
      : ""
  }
`;

export default ChangeLog;
