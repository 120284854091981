import { gql } from "@apollo/client";
import { ListItems as listItems } from "../fragments";

const FETCH_LIST_ITEMS = props => gql`
  query FetchListItems($input: ListItemsFilterInput!) {
    listItems(input: $input) {
      count
      edges {
        ${listItems(props)}
      }
    }
  }
`;

export { FETCH_LIST_ITEMS };
