import * as Yup from "yup";
import { useEffect } from "react";
import { useFormik } from "formik";
import { useMutation, useQuery } from "@apollo/client";

import { Button, getInput } from "../../../components/ui";
import { FETCH_WAREHOUSE_SITES } from "../../../graphql/query/WarehouseSite";
import { MOVE_ITEM as moveItemMutation } from "../../../graphql/mutation/WarehouseItem";
import { FETCH_WAREHOUSE_LOCATIONS } from "../../../graphql/query/WarehouseLocation";

const MoveItem = props => {
  const { warehouseItem, closeModal } = props || {};
  const [moveItem, { loading, data, error, reset }] = useMutation(
    moveItemMutation(),
    {
      refetchQueries: ["FetchWarehouseMoveV2", "FetchWarehouseItem"],
    },
  );

  const { data: { warehouseLocations = [] } = {} } = useQuery(
    FETCH_WAREHOUSE_LOCATIONS,
  );
  const { data: { warehouseSites = [] } = {} } = useQuery(
    FETCH_WAREHOUSE_SITES,
  );

  useEffect(() => {
    formik?.setFieldValue("siteId", warehouseItem?.itemSite?.siteId);
  }, [warehouseItem?.itemSite?.siteId]);

  const formik = useFormik({
    initialValues: {
      siteId: warehouseItem?.itemSite?.siteId,
    },
    validationSchema: Yup.object({
      locationId: Yup.number()
        .typeError("Please select a location")
        .required("Please select a location"),
    }),
    onSubmit: async values => {
      moveItem({
        variables: {
          input: {
            itemId: warehouseItem?.id,
            ...values,
          },
        },
      }).then(resp => {
        const {
          data: { moveItem: { success } = {} },
        } = resp || {};
        if (success) {
          formik.resetForm();
          closeModal();
        }
      });
    },
  });

  const leftInputFields = [
    {
      label: "Site",
      name: "siteId",
      disabled: true,
      type: "multi-select",
      options: warehouseSites?.map(item => ({
        label: item?.name,
        value: item?.id,
      })),
    },
    {
      label: "Location",
      name: "locationId",
      type: "multi-select",
      options: warehouseLocations
        ?.filter(item => item?.siteId == warehouseItem?.itemSite?.siteId)
        ?.map(item => ({
          label: item.description,
          value: item.id,
        })),
    },
  ];

  const rightInputfields = [
    { label: "Movement Notes", name: "locref", type: "textarea" },
  ];

  if (data?.moveItem?.error || error) {
    return (
      <div className="flex w-full flex-col gap-4 p-6">
        <div className="flex flex-row">
          <div className="flex flex-1 flex-col text-2xl font-bold">
            <div>Move Item</div>
          </div>
          <div className="flex gap-4">
            <Button
              action="default"
              label="Cancel"
              onClick={() => {
                formik?.resetForm();
                closeModal?.();
                reset();
              }}
            />
          </div>
        </div>
        <div>{data?.moveItem?.error || error?.message}</div>
      </div>
    );
  }

  return (
    <div className="flex w-full flex-col gap-4 p-6">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          <div>Move Item</div>
        </div>
        <div className="flex gap-4">
          <Button
            action="default"
            label="Cancel"
            onClick={() => {
              formik?.resetForm();
              closeModal?.();
              reset();
            }}
          />
          <Button
            label={"Move Item"}
            loading={loading}
            disabled={loading}
            onClick={formik.submitForm}
          />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4 pb-6 pt-6">
        <div className="grid grid-cols-1 gap-4">
          {leftInputFields?.map((item, index) => {
            const inputProps = {
              ...item,
              formik,
            };
            return (
              <div key={index} className={`w-full ${item?.className}`}>
                {getInput(inputProps)}
              </div>
            );
          })}
        </div>
        <div className="grid grid-cols-1 gap-4">
          {rightInputfields?.map((item, index) => {
            const inputProps = {
              ...item,
              formik,
            };
            return (
              <div key={index} className={`w-full ${item?.className}`}>
                {getInput(inputProps)}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MoveItem;
