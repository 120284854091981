import { useEffect } from "react";
import { useFormik } from "formik";
import { useMutation } from "@apollo/client";
import { TrashIcon } from "@heroicons/react/24/outline";

import { Button, IconButton } from "../../../../../components/ui";
import { GridInputs } from "../../../../../components/Functional";

import { UPDATE_COMPANY as updateCompanyMutation } from "../../../../../graphql/mutation/Company";

const ImgInputLabel = ({ label, name, formik }) => {
  return (
    <div className="flex justify-between">
      <div>{label}</div>
      <IconButton variant="clean" title="Delete Role">
        <TrashIcon
          className={`h-4 w-4 cursor-pointer text-gray-400`}
          onClick={() => formik?.setFieldValue(name, { url: null, file: null })}
        />
      </IconButton>
    </div>
  );
};

const EditHeaderFooter = props => {
  const { closeModal, isOpen, values } = props;

  const [updateCompany, { loading }] = useMutation(
    updateCompanyMutation({ images: {} }),
  );

  const handleUpdate = () => {
    const { header, footer } = formik?.values || {};

    const input = {
      id: values?.id,
      header: header?.file,
      footer: footer?.file,
    };

    updateCompany({
      variables: {
        input,
      },
    }).then(() => {
      closeModal();
      formik.resetForm();
    });
  };

  const formik = useFormik({
    initialValues: values
      ? {
          header: { url: values?.images?.header },
          footer: { url: values?.images?.footer },
        }
      : {},
    enableReinitialize: true,
    onSubmit: handleUpdate,
  });

  const inputs = {
    className: "grid grid-cols-1",
    inputs: [
      {
        className: "grid grid-cols-1 gap-4",
        inputs: [
          {
            label: (
              <ImgInputLabel {...{ formik, name: "header", label: "Header" }} />
            ),
            name: "header",
            type: "img",
            w: "w-full",
            h: "h-36",
            value: formik?.values?.header?.url,
            onChange: e => {
              const files = e?.target?.files;
              const url = URL.createObjectURL(files[0]);
              formik.setFieldValue("header", {
                url,
                file: files?.[0],
              });
            },
          },
          {
            label: (
              <ImgInputLabel {...{ formik, name: "footer", label: "Footer" }} />
            ),
            name: "footer",
            type: "img",
            w: "w-full",
            h: "h-36",
            value: formik?.values?.footer?.url,
            onChange: e => {
              const files = e?.target?.files;
              const url = URL.createObjectURL(files[0]);
              formik.setFieldValue("footer", {
                url,
                file: files?.[0],
              });
            },
          },
        ],
      },
    ],
  };

  const inputProps = {
    ...inputs,
  };

  useEffect(() => {
    formik.handleReset();
  }, [isOpen]);

  return (
    <div className="flex w-full flex-col p-8">
      <div className="flex flex-row justify-between">
        <div className="flex flex-col">
          <div className="flex flex-1 flex-col text-2xl font-bold">
            Edit Company Header / Footer
          </div>
          <div className="flex flex-col text-xl">{values?.name}</div>
        </div>
        <div className="flex flex-col">
          <div>
            <Button
              action="default"
              className="mr-8"
              label="Cancel"
              onClick={closeModal}
            />
            <Button
              label={"Save"}
              disabled={loading}
              onClick={formik.submitForm}
            />
          </div>
        </div>
      </div>
      <div className="mt-12 pb-10">
        <GridInputs {...inputProps} />
      </div>
    </div>
  );
};

export default EditHeaderFooter;
