import { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

const RichText = props => {
  const {
    className,
    error = "",
    label,
    onChange,
    value,
    toolbarCustomButtons,
    variant,
    placeholder = "",
  } = props;
  const [editorState, onEditorStateChange] = useState();
  const variants = {
    default: {
      label: "block text-sm font-medium text-black mb-2",
      wrapper: "border border-black rounded",
      editor: "h-[300px] overflow-auto m-0 p-4 py-2",
    },
    simpleRight: {
      label: "block text-xl font-bold font-medium text-black mb-4",
      wrapper: "",
      editor: "h-[300px] overflow-auto m-0 p-4 py-2",
    },
  };
  const classes = variants[variant] || variants.default;
  // initialise the editor state when value is available
  useEffect(() => {
    if (!editorState && value) {
      const contentBlock = htmlToDraft(value || "");
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks,
      );
      const editorStateValue = EditorState.createWithContent(contentState);
      onEditorStateChange(editorStateValue);
    }
  }, [value]);

  const handleChange = content => {
    onEditorStateChange(content);
    onChange(draftToHtml(convertToRaw(content.getCurrentContent())));
  };
  return (
    <div className={`flex flex-1 flex-col ${className} z-0`}>
      {label && <label className={classes.label}>{label}</label>}
      <Editor
        editorState={editorState}
        toolbarClassName="border-0 border-b border-black"
        wrapperClassName={classes.wrapper}
        editorClassName={classes.editor}
        onEditorStateChange={handleChange}
        placeholder={placeholder}
        toolbar={{
          options: [
            "history",
            "blockType",
            "inline",
            "fontSize",
            "list",
            "remove",
          ],
          history: { inDropdown: false },
          inline: { options: ["bold", "italic"] },
          list: { inDropdown: true },
          list2: { inDropdown: true },
        }}
        // eslint-disable-next-line react/jsx-key
        toolbarCustomButtons={
          toolbarCustomButtons?.map(item => {
            return item;
          }) || []
        }
      />
      {error && <small className="text-sm text-red-600">{error}</small>}
    </div>
  );
};

export default RichText;
