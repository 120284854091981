import { gql } from "@apollo/client";
import { PaymentPlan as paymentPlan } from "../fragments";

const FETCH_PAYMENT_PLAN = props => gql`
  query FetchPaymentPlan($id: Int) {
    paymentPlan(id: $id) {
      ${paymentPlan(props)}
    }
  }
`;

const FETCH_PAYMENT_PLANS_V2 = props => gql`
  query FetchPaymentPlans($input: AdvPaymentPlanFilterInput!) {
    paymentPlansV2(input: $input) {
      count
      edges {
        ${paymentPlan(props)}
      }
      totalAmount
    }
  }
`;

export { FETCH_PAYMENT_PLAN, FETCH_PAYMENT_PLANS_V2 };
