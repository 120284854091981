import { gql } from "@apollo/client";
import { Value as value } from "../fragments";

const CREATE_VALUE = props => gql`
  mutation CreateValue($input: CreateValueInput!) {
    createValue(input: $input) {
      success
      error
      value {
        ${value(props)}
      }
    }
  }
`;

const UPDATE_VALUE = props => gql`
  mutation UpdateValue($input: UpdateValueInput!) {
    updateValue(input: $input) {
      success
      error
      value {
        ${value(props)}
      }
    }
  }
`;

export { CREATE_VALUE, UPDATE_VALUE };
