import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useFormik } from "formik";

import Header from "../../components/HeaderComponent";
import Actions from "./components/Actions";
import Filters from "./components/Filters";

import Table from "../../../../components/Table";
import {
  Avatar,
  Button,
  Pagination,
  SearchBar,
  Tag,
} from "../../../../components/ui";
import { FETCH_ROLES } from "../../../../graphql/query/Role";
import { FETCH_USERS as fetchUsers } from "../../../../graphql/query/User";
import { useDebounce } from "../../../../util";

import useUser from "../../../../hooks/useUser";

const RightComponent = () => {
  const navigate = useNavigate();
  const { hasPermission } = useUser();
  if (!hasPermission("CREATE_USER")) return null;
  return (
    <Button
      label={"Manage Roles"}
      action={"dark"}
      className="mr-4"
      onClick={() => navigate(`/admin/role`)}
    />
  );
};

const AdminUser = () => {
  const [searchParams] = useSearchParams();
  const roleId = searchParams.get("roleId");

  const urlParams = {
    roleId: roleId ? +roleId : undefined,
  };
  const limit = 10;
  const [filters, setFilters] = useState({});
  const debouncedFilters = useDebounce(filters, 300);
  const skip = Object.keys(debouncedFilters).length === 0;
  const { previousData, data, loading } = useQuery(fetchUsers({ roles: {} }), {
    skip,
    variables: { input: { ...debouncedFilters } },
  });
  const { data: { roles: { edges: roles = [] } = {} } = {} } =
    useQuery(FETCH_ROLES);
  const { users } = data || previousData || {};
  const { count, edges = [], activeUsers, inactiveUsers } = users || {};

  const onChange = values => {
    setFilters({ ...filters, ...values });
  };

  const paginationProps = {
    count,
    limit,
    offset: filters?.offset,
    onChange,
    statsComponent: !loading && data && (
      <span className="mr-9">
        {activeUsers} Active Users {inactiveUsers} Inactive Users
      </span>
    ),
  };

  const inputs = [
    {
      name: "status",
      label: "Status",
      type: "select",
      options: [
        {
          label: "All",
          value: "",
        },
        {
          label: "Active",
          value: "active",
        },
        {
          label: "Inactive",
          value: "inactive",
        },
      ],
    },
    {
      name: "roleId",
      label: "Role",
      type: "multi-select",
      options: roles?.map(item => ({ label: item?.name, value: item?.id })),
    },
    { name: "email", label: "Email", type: "text" },
    { name: "name", label: "Name", type: "text" },
    { name: "from", label: "From", type: "date" },
    { name: "to", label: "To", type: "date" },
  ];

  const tableProps = {
    headers: [
      {
        label: "",
        name: "meta.profilePic",
        type: "custom",
        component: props => (
          <Avatar
            src={props?.values?.meta?.profilePic}
            label={props?.values?.meta?.name || "User Name"}
          />
        ),
      },
      { label: "User", name: "meta.name", type: "label" },
      { label: "Email", name: "email", type: "label" },
      { label: "Status", name: "status", type: "tag" },
      {
        label: "Roles",
        type: "custom",
        component: props => (
          <span className="flex gap-1 capitalize">
            {props?.values?.roles?.map((item, index) => (
              <Tag key={index} label={item?.name} />
            ))}
          </span>
        ),
      },
      { label: "Last Active", name: "lastActive", type: "label" },
      { label: "Action", name: "action", type: "custom", component: Actions },
    ],
    limit,
    loading,
    rows: edges?.map(item => ({
      ...item,
      action: item?.isActive ? "success" : "danger",
    })),
  };

  const initialValues = {
    search: "",
    limit,
    ...inputs
      ?.map(item => ({ ...item, value: "" }))
      .reduce((acc, item) => ({ ...acc, [item.name]: item.value }), {}),
  };
  const formik = useFormik({
    initialValues: { ...initialValues, ...urlParams },
  });

  const filterProps = {
    inputs,
    formik,
    type: "simple",
    onChange,
  };

  const searchBarProps = {
    name: "search",
    formik,
  };

  useEffect(() => {
    onChange(formik.values);
    // set the url params
    searchParams.set("roleId", formik.values?.roleId);
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${searchParams}`,
    );
  }, [formik.values]);

  return (
    <div className="user-admin">
      <Header
        leftText="User Administration"
        rightText="PDF Generator"
        rightComponent={RightComponent}
      />
      <div>Manage user accounts and permissions</div>
      <div className="flex flex-grow items-center justify-end">
        <div className="mr-4">
          <SearchBar {...searchBarProps} />
        </div>
        <Filters {...filterProps} />
      </div>
      <div className="flex-col p-4 sm:px-0">
        <Table {...tableProps} />
        <Pagination {...paginationProps} />
      </div>
    </div>
  );
};

export default AdminUser;
