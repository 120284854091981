import { gql } from "@apollo/client";
import { Instance as instance, Job as job } from "../fragments";

const CHANGE_INSTANCE_EDITION_NUMBER = props => gql`
  mutation ChangeInstanceEditionNumber($input: ChangeInstanceEditionNumberInput!) {
    changeInstanceEditionNumber(input: $input) {
      success
      error
      instance {
        ${instance(props)}
      }
    }
  }
`;

const CREATE_INSTANCES = props => gql`
  mutation CreateInstances($input: CreateInstancesInput!) {
    createInstances(input: $input) {
      success
      error
      instances {
        ${instance(props)}
      }
    }
  }
`;

const CREATE_INSTANCE_JOB = props => gql`
  mutation CreateInstanceJob($id: Int!) {
    createInstanceJob(id: $id) {
      job{
        ${job(props)}
      }
      success
      error
    }
  }
`;

const DELETE_INSTANCE = gql`
  mutation DeleteInstance($input: DeleteInstanceInput!) {
    deleteInstance(input: $input) {
      success
      error
    }
  }
`;

const MAKE_AVAILABLE_INSTANCE = props => gql`
  mutation MakeAvailableInstance($input: MakeAvailableInstanceInput!) {
    makeAvailableInstance(input: $input) {
      success
      error
      instance {
        ${instance(props)}
      }
    }
  }
`;

const UNDO_STATUS_INSTANCE = props => gql`
  mutation UndoStatusInstance($input: UndoStatusInstanceInput!) {
    undoStatusInstance(input: $input) {
      success
      error
      instance {
        ${instance(props)}
      }
    }
  }
`;

const UNCONSIGN_INSTANCE = props => gql`
  mutation UnconsignInstance($input: UnconsignInstanceInput!) {
    unconsignInstance(input: $input) {
      success
      error
      instance {
        ${instance(props)}
      }
    }
  }
`;

const UPDATE_INSTANCE = props => gql`
  mutation UpdateInstance($input: UpdateInstanceInput!) {
    updateInstance(input: $input) {
      success
      error
      instance {
        ${instance(props)}
      }
    }
  }
`;

const EXPORT_INSTANCES = gql`
  mutation ExportInstances($input: ExportInstancesInput!) {
    exportInstances(input: $input) {
      error
      success
      url
    }
  }
`;

const EXPORT_ADHOC_REPORT_INSTANCES = gql`
  mutation ExportAdhocReportInstances($input: ExportInstancesInput!) {
    exportAdhocReportInstances(input: $input) {
      error
      success
      url
    }
  }
`;

export {
  CHANGE_INSTANCE_EDITION_NUMBER,
  CREATE_INSTANCES,
  CREATE_INSTANCE_JOB,
  DELETE_INSTANCE,
  MAKE_AVAILABLE_INSTANCE,
  UNCONSIGN_INSTANCE,
  UNDO_STATUS_INSTANCE,
  UPDATE_INSTANCE,
  EXPORT_INSTANCES,
  EXPORT_ADHOC_REPORT_INSTANCES,
};
