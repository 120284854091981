import { useFormik } from "formik";
import { useMutation, useQuery } from "@apollo/client";
import * as Yup from "yup";

import { GridInputs } from "../../../../components/Functional";
import { Button } from "../../../../components/ui/Button";
import Header from "../../components/HeaderComponent";

import { FETCH_PARTNER } from "../../../../graphql/query/Partner";
import { UPDATE_PARTNER } from "../../../../graphql/mutation/Partner";

const Configuration = () => {
  const { data } = useQuery(FETCH_PARTNER);
  const [updatePartner, { loading }] = useMutation(UPDATE_PARTNER);

  const formik = useFormik({
    initialValues: {
      domainOptions:
        data?.partner?.meta?.domains?.map(domain => ({
          label: domain,
          value: domain,
        })) || [],
      meta: data?.partner?.meta || {},
    },
    enableReinitialize: true,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: Yup.object({
      meta: Yup.object({
        minStorageCharge: Yup.number().required("Required"),
        chargePerM3: Yup.number().required("Required"),
      }),
    }),
    onSubmit: async values => {
      updatePartner({
        variables: {
          input: { meta: values?.meta },
        },
      });
    },
  });

  const { domainOptions } = formik.values || {};

  const handleAction = value => {
    formik?.setFieldValue("domainOptions", value);
    formik?.setFieldValue(
      "meta.domains",
      value?.map(item => item?.value) || [],
    );
  };

  const inputs = {
    className: "grid grid-cols-2 gap-4 mt-5",
    inputs: [
      {
        label: "Min Storage Charge (per m3)",
        name: "meta.minStorageCharge",
        type: "number-currency",
        allowNegativeInput: false,
      },
      {
        label: "Charge (per m3)",
        name: "meta.chargePerM3",
        type: "number-currency",
        allowNegativeInput: false,
      },
      {
        handleAction,
        isCreatable: true,
        isMulti: true,
        label: "Domains",
        name: "domainOptions",
        options: domainOptions,
        type: "multi-select",
      },
    ],
  };

  const inputProps = {
    formik,
    ...inputs,
  };

  return (
    <div className="w-full">
      <Header leftText={"Configuration"} />
      <div className="py-10">
        <GridInputs {...inputProps} />
      </div>
      <div className="mt-4 flex">
        <Button
          label="Save"
          disabled={!formik?.isValid || loading}
          onClick={formik?.submitForm}
        />
      </div>
    </div>
  );
};

export default Configuration;
