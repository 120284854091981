import React from "react";

import { getInput } from "../../../../../../../components/ui";

const Declarations = ({ formik, submitOnBlur = false }) => {
  const inputs = [
    {
      label: "Custom Declaration",
      name: "customsDeclaration",
      type: "textarea",
      submitOnBlur,
    },
    {
      label: "Instructions",
      name: "instructions",
      type: "textarea",
      submitOnBlur,
    },
    { label: "Note", name: "condition", type: "textarea", submitOnBlur },
  ];

  return (
    <div className="grid-cols-1 gap-4">
      {inputs?.map((item, index) => {
        const inputProps = {
          formik,
          ...item,
          ...formik?.getFieldProps(item.name),
        };

        return (
          <div key={index} className="pb-6">
            {getInput(inputProps)}
          </div>
        );
      })}
    </div>
  );
};

export default Declarations;
