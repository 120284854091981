import React, { useEffect, useMemo } from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { ApolloProvider } from "@apollo/client";
import { Routes as RouterRoutes, Route, BrowserRouter } from "react-router-dom";
import { Core } from "@grapecity/activereports";
import { createRoot } from "react-dom/client";
import getClient from "./graphql/client";
import Routes from "./routes";
import reportWebVitals from "./reportWebVitals";
import { msalConfig } from "./authConfig";
import useMSAL from "./hooks/useMSAL";
import "./index.css";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://d3f7b9617f27240796064843b52cc835@o1080904.ingest.us.sentry.io/4507214502100992",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

Core.setLicenseKey(process.env.REACT_APP_ACTIVE_REPORT_LICENSE_KEY);

const msalInstance = new PublicClientApplication(msalConfig);
await msalInstance.initialize();
const App = () => {
  useEffect(() => {
    window.debug = { env: process.env };
  }, []);

  const { isAuthenticated, requestAccessToken, token } = useMSAL();
  const client = getClient({ requestAccessToken });

  const routesProps = {
    isAuthenticated,
    token,
  };

  const memoizedRoutes = useMemo(() => {
    return (
      <ApolloProvider client={client}>
        <Routes {...routesProps} />
      </ApolloProvider>
    );
  }, [isAuthenticated, token]);

  return <>{memoizedRoutes}</>;
};

const MainRoutes = () => {
  const routes = [
    {
      path: "*",
      element: (
        <MsalProvider instance={msalInstance}>
          <App />
        </MsalProvider>
      ),
    },
    {
      path: "/auth",
      element: <div></div>,
    },
  ];

  const renderRoutes = routes?.map((item, index) => {
    return <Route key={index} {...item} />;
  });

  return (
    <BrowserRouter>
      <RouterRoutes>{renderRoutes}</RouterRoutes>
    </BrowserRouter>
  );
};

const container = document.getElementById("root");
const root = createRoot(container);
// eslint-disable-next-line
root.render(
  <React.StrictMode>
    <MainRoutes />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
