import { gql } from "@apollo/client";

// TODO: fix this
const GET_ISSUES_CATEGORIES = gql`
  query GetIssuesCategories {
    getIssuesCategories {
      id
      name
      parent_id
    }
  }
`;

const GET_SUPPORT_ARTICLES = gql`
  query GET_SUPPORT_ARTICLES {
    supportArticles
  }
`;

const GET_SUPPORT_ARTICLE = gql`
  query GET_SUPPORT_ARTICLES($id: Int) {
    supportArticle
  }
`;

export { GET_ISSUES_CATEGORIES, GET_SUPPORT_ARTICLES, GET_SUPPORT_ARTICLE };
