import { useState } from "react";
import { useMutation } from "@apollo/client";

import { Button, Modal } from "../../../../ui";
import { Table } from "../../../../Functional";
import { MOVE_IMAGES } from "../../../../../graphql/mutation/Image";
import { FETCH_ARTWORKS as fetchArtworks } from "../../../../../graphql/query/Artwork";

const MoveImage = props => {
  const { selectedImages, artworkId, onSuccess, mainCloseModal, onError } =
    props;

  const [moveImages, { client, loading: moveLoading }] =
    useMutation(MOVE_IMAGES);

  const [selectedArtwork, setSelectedArtwork] = useState(artworkId);

  const TableCheck = props => {
    const { values, selectedArtwork, onChange } = props;

    return (
      <div className="relative flex w-[40px] items-start" key={values.id}>
        <div className="flex h-5 items-center">
          <input
            id={values.id}
            value={values.id}
            checked={selectedArtwork === values.id}
            aria-describedby="comments-description"
            name="id"
            type="checkbox"
            className="h-4 w-4 rounded border-2 border-gray-800 text-gray-800 focus:ring-0"
            onChange={() => onChange(values)}
          />
        </div>
      </div>
    );
  };

  const handleCheckClick = ({ id }) => {
    setSelectedArtwork(id);
  };

  const handleMoveImages = () => {
    moveImages({
      variables: {
        input: {
          artworkId: artworkId,
          toArtworkId: selectedArtwork,
          archiveIds: selectedImages,
        },
      },
    }).then(async resp => {
      const {
        data: { moveImages: { success } = {} },
      } = resp || {};
      if (success) {
        await client.resetStore();
        mainCloseModal?.();
        onSuccess && onSuccess();
      } else {
        onError && onError();
      }
    });
  };

  const tableProps = {
    dataKey: "artworks",
    FETCH_QUERY: fetchArtworks({ artist: {} }),
    headers: [
      {
        label: "",
        width: "100px",
        name: "checkbox",
        type: "custom",
        component: props => (
          <TableCheck
            {...props}
            selectedArtwork={selectedArtwork}
            onChange={handleCheckClick}
          />
        ),
      },
      { label: "Ref", name: "id", sortKey: "ref", type: "label" },
      {
        label: "Artist Name",
        name: "artist.name",
        sortKey: "artistName",
        type: "label",
      },
      {
        label: "Artwork Title",
        name: "title",
        sortKey: "title",
        type: "label",
      },
    ],
    inputs: [],
    pagination: {
      variant: "sm",
    },
  };

  const moveModalProps = {
    scale: "md",
    closeOnBackdrop: false,
    hideCloseButton: true,
    disabled: selectedImages.length <= 0,
    onOpen: () => {
      setSelectedArtwork(artworkId);
    },
    body: ({ closeModal }) => {
      return (
        <div className="flex w-full flex-col px-4 py-2">
          <div className="flex flex-row">
            <div className="flex flex-1 flex-col text-2xl font-bold">
              Move Selected Images
            </div>
            <div className="flex flex-row gap-4">
              <Button
                className={`!w-32`}
                action="default"
                label="Cancel"
                onClick={() => {
                  closeModal();
                }}
              />
              <Button
                label="Back"
                className={`!w-32`}
                onClick={() => {
                  closeModal();
                }}
              />
              <Button
                className="flex !w-32 items-center"
                label={moveLoading ? "Moving" : "Move"}
                disabled={moveLoading}
                loading={moveLoading}
                onClick={handleMoveImages}
              />
            </div>
          </div>
          <div className="mt-8">
            <div>Select Artwork Record to Relocate Images</div>
            <Table {...tableProps} />
          </div>
        </div>
      );
    },
  };

  return (
    <Modal {...moveModalProps}>
      <Button
        className={`!w-28`}
        label="Move"
        disabled={selectedImages.length <= 0}
      />
    </Modal>
  );
};

export default MoveImage;
