import { Button } from "../../../../../../../../../components/ui";
import { useMutation } from "@apollo/client";
import { DELETE_CONTACT_NUMBER } from "../../../../../../../../../graphql/mutation/ContactNumber";
import DetailView from "./DetailView";

const DeleteAddressComponent = props => {
  const { contact, contactNumber, closeModal } = props || {};

  const [deleteContactNumber, { loading }] = useMutation(DELETE_CONTACT_NUMBER);

  const handleDelete = () => {
    deleteContactNumber({
      variables: {
        input: { id: contactNumber?.id },
      },
      update: (cache, response) => {
        const { data: { deleteContactNumber: { success } = {} } = {} } =
          response;
        if (success) {
          cache.evict({
            id: cache.identify({
              __typename: "ContactNumber",
              id: contactNumber?.id,
            }),
          });
          closeModal();
        }
      },
    });
  };

  return (
    <div className="p-8">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          Delete Method Confirmation
        </div>
        <div className="flex flex-col">
          <div>
            <Button
              label="CANCEL"
              action="default"
              className="mr-4"
              onClick={closeModal}
            />
            <Button
              label={loading ? "DELETING" : "DELETE"}
              onClick={handleDelete}
              disabled={loading}
            />
          </div>
        </div>
      </div>
      <div className="mb-4 mt-8">
        You are about to delete the following method from {contact?.name}{" "}
        contact record:
      </div>
      <DetailView contactNumber={contactNumber} disabled={true} />
    </div>
  );
};

export default DeleteAddressComponent;
