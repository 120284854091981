import { gql } from "@apollo/client";
import { ContactActivity as contactActivity } from "../fragments";

const DELETE_CONTACT_ACTIVITY = gql`
  mutation DeleteContactActivity($input: DeleteContactActivityInput!) {
    deleteContactActivity(input: $input) {
      success
      error
    }
  }
`;

const CREATE_CONTACT_ACTIVITY = props => gql`
  mutation CreateContactActivity($input: CreateContactActivityInput!) {
    createContactActivity(input: $input) {
      contactActivity {
        ${contactActivity(props)}
      }
      error
      success
    }
  }
`;

const UPDATE_CONTACT_ACTIVITY = props => gql`
  mutation UpdateContactActivity($input: UpdateContactActivityInput!) {
    updateContactActivity(input: $input) {
      contactActivity {
        ${contactActivity(props)}
      }
      error
      success
    }
  }
`;

export {
  DELETE_CONTACT_ACTIVITY,
  CREATE_CONTACT_ACTIVITY,
  UPDATE_CONTACT_ACTIVITY,
};
