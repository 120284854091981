import { useMutation, useQuery } from "@apollo/client";
import { PencilIcon } from "@heroicons/react/24/outline";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Avatar, Button, getInput } from "../../../../components/ui";
import {
  DELETE_USER,
  UPDATE_USER as updateUserMutation,
} from "../../../../graphql/mutation/User";
import { FETCH_ROLES } from "../../../../graphql/query/Role";
import { FETCH_USER as fetchUser } from "../../../../graphql/query/User";
import { getChangedFields } from "../../../../util";
import Header from "../../components/HeaderComponent";
import { FETCH_ENTITIES } from "../../../../graphql/query/Entity";
import {
  CREATE_USER_ROLE,
  DELETE_USER_ROLE,
} from "../../../../graphql/mutation/UserRole";

const RightComponent = () => {
  const [deleteUser] = useMutation(DELETE_USER);
  const { id } = useParams();
  const navigate = useNavigate();

  const handleDelete = id => {
    deleteUser({
      variables: {
        input: {
          id: +id,
        },
      },
      update: (cache, { data }) => {
        const { deleteUser: { success } = {} } = data;
        if (success) {
          cache.evict({
            id: cache.identify({
              __typename: "User",
              id,
            }),
          });
        }
      },
    }).then(resp => {
      const {
        data: { deleteUser: { success } = {} },
      } = resp || {};
      if (success) {
        // TODO: handle this through updating cache to improve performance
        // client.resetStore()
        navigate("/admin/users");
      }
    });
  };
  return (
    <div>
      <Button
        label={"Back"}
        action="default"
        className="mr-4"
        onClick={() => navigate("/admin/users")}
      />
      <Button
        label="Delete"
        onClick={() => {
          handleDelete(id);
        }}
      />
    </div>
  );
};

const returnpermissions = (inputs, objectValue) => {
  return (
    <div>
      <div className="ml-5">{permissionData(objectValue)}</div>
    </div>
  );
};

const permissionData = (data = {}) => {
  const permissions = [];
  for (const [objectKey, objectValue] of Object.entries(data)) {
    const inputs = [
      {
        label: objectKey,
        name: objectKey,
        value: objectValue,
        type: "toggle",
        variant: "horizontal",
      },
    ];
    if (typeof objectValue == "boolean") {
      if (objectValue) {
        permissions.push(<div>{objectKey}</div>);
      }
    } else if (typeof objectValue == "object") {
      permissions.push(returnpermissions(inputs, objectValue));
    }
  }
  return permissions;
};

const EditUser = () => {
  const { id } = useParams();
  const { data: { user = {} } = {} } = useQuery(fetchUser({ roles: {} }), {
    variables: { id: +id },
  });
  const { data: { roles: { edges: _roles = [] } = {} } = {} } =
    useQuery(FETCH_ROLES);
  const { data: { entities } = {} } = useQuery(FETCH_ENTITIES);

  const [createUserRole] = useMutation(CREATE_USER_ROLE, {
    refetchQueries: [fetchUser({ roles: {} })],
  });
  const [deleteUserRole] = useMutation(DELETE_USER_ROLE, {
    refetchQueries: [fetchUser({ roles: {} })],
  });

  const [updateUser] = useMutation(updateUserMutation({ roles: {} }));
  const navigate = useNavigate();

  const userRoles =
    user?.roles?.map(item => ({ label: item?.name, value: item?.id })) || [];
  const initialValues = { ...user, userRoles };

  const formik = useFormik({
    initialValues: initialValues || {},
    enableReinitialize: true,
  });

  useEffect(() => {
    const changed =
      Object.keys(
        getChangedFields(
          { ...formik?.values, userRoles },
          { ...initialValues, userRoles },
        ),
      )?.length > 0;
    changed &&
      updateUser({
        variables: {
          input: {
            id: +id,
            status: formik?.values?.status,
            entityId: formik?.values?.entityId,
          },
        },
      });
  }, [formik?.values]);

  const handleRoles = (value, actionProps) => {
    const { action, removedValue, removedValues, option } = actionProps || {};

    switch (action) {
      case "select-option":
        createUserRole({
          variables: { input: { userId: +id, roleId: +option?.value } },
        });
        break;
      case "remove-value":
        deleteUserRole({
          variables: { input: { userId: +id, roleId: +removedValue?.value } },
        });
        break;
      case "clear":
        removedValues.forEach(item =>
          deleteUserRole({
            variables: { input: { userId: +id, roleId: +item?.value } },
          }),
        );
        break;
    }
  };

  const inputs = [
    {
      label: "System Status",
      name: "status",
      type: "multi-select",
      variant: "horizontal",
      options: [
        { label: "Active", value: "active" },
        { label: "In-Active", value: "inactive" },
      ],
    },
    {
      label: "Name",
      type: "text",
      name: "meta.name",
      variant: "horizontal",
      disabled: true,
    },
    {
      label: "Email",
      type: "text",
      name: "email",
      variant: "horizontal",
      disabled: true,
    },
    {
      label: "Entity",
      name: "entityId",
      options:
        entities?.edges?.map(item => {
          return { label: item.name, value: item.id };
        }) || [],
      type: "multi-select",
      variant: "horizontal",
    },
    {
      handleAction: handleRoles,
      isMulti: true,
      label: "Role",
      name: "userRoles",
      options:
        _roles?.map(role => {
          return { label: role.name, value: role.id };
        }) || [],
      type: "multi-select",
      variant: "horizontal",
    },
  ];

  const renderLabels = inputs?.map((item, index) => {
    const inputProps = {
      formik,
      ...item,
      ...formik?.getFieldProps(item.name),
      onChange: null,
    };

    return (
      <div key={index} className="w-full p-1">
        {getInput(inputProps)}
      </div>
    );
  });

  return (
    <>
      <Header
        leftText="Edit User Details"
        rightText="White Cube PDF Generator"
        rightComponent={RightComponent}
      />
      <div className="mb-9">Manage user accounts and permissions</div>
      <div>
        <Avatar
          src={user?.meta?.profilePic}
          label={user?.meta?.name}
          size="lg"
        />
      </div>
      <div className="mt-5">
        <h4>White Cube Sales Rep 1</h4>
      </div>
      <div className="mt-5 items-center">
        {renderLabels}
        <div className="col-span-2 mt-4">
          <div className="text-rignt flex grid grid-cols-5 gap-5">
            <label className="sm13 col-span-1 mt-2 block text-right font-medium text-black">
              Permission
            </label>
            <div className="col-span-2 ml-3 flex items-start justify-between rounded-sm border font-light text-lightgrey">
              <div className="p-5">
                {/* {permissionData(user?.permissions)} */}
              </div>
              <PencilIcon
                className="h-7 w-7 cursor-pointer pt-2 text-sm text-lightgrey"
                onClick={() => {
                  navigate(`/admin/users/${id}/edit/permission`);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditUser;
