import { gql } from "@apollo/client";
import { GuaranteeAccount as guaranteeAccounts } from "../fragments";

const FETCH_GUARANTEE_ACCOUNTS = props => gql`
  query FetchGuaranteeAccounts {
    guaranteeAccounts {
      ${guaranteeAccounts(props)}  
    }
  }
`;

const FETCH_GUARANTEE_ACCOUNTS_V2 = props => gql`
  query FetchGuaranteeAccountsV2($input: AdvGuaranteeAccountFilterInput!) {
    guaranteeAccountsV2(input: $input) {
      count
      edges {
        ${guaranteeAccounts(props)}
      }
      sqlQuery
    }
  }
`;

export { FETCH_GUARANTEE_ACCOUNTS, FETCH_GUARANTEE_ACCOUNTS_V2 };
