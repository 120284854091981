import { gql } from "@apollo/client";
import { ContactAddress as contactAddress } from "../fragments";

const CREATE_CONTACT_ADDRESS = props => gql`
  mutation CreateContactAddress($input: CreateContactAddressInput!) {
    createContactAddress(input: $input) {
      contactAddress {
        ${contactAddress(props)}
      }
      success
      error
    }
  }
`;

const DELETE_CONTACT_ADDRESS = gql`
  mutation DeleteContactAddress($input: DeleteContactAddressInput!) {
    deleteContactAddress(input: $input) {
      success
      error
    }
  }
`;

const UPDATE_CONTACT_ADDRESS = props => gql`
  mutation UpdateContactAddress($input: UpdateContactAddressInput!) {
    updateContactAddress(input: $input) {
      contactAddress {
        ${contactAddress(props)}
      }
      success
      error
    }
  }
`;

export {
  CREATE_CONTACT_ADDRESS,
  DELETE_CONTACT_ADDRESS,
  UPDATE_CONTACT_ADDRESS,
};
