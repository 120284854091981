import { useLazyQuery, useQuery } from "@apollo/client";
import React from "react";
import { useNavigate } from "react-router-dom";

import { GridInputs } from "../../../../components/Functional";
import { Button } from "../../../../components/ui";

import { FETCH_SHIPMENTS_V2 as fetchShipmentsV2Query } from "../../../../graphql/query/Shipment";
import { FETCH_COUNTRIES } from "../../../../graphql/query/Country";

import { shipmentFilterStore, useShipmentFilters } from "../useShipmentFilters";

import Query from "./components/Query";
import Tabs from "./components/Tabs";
import Items from "./components/Items";
import Details from "./components/Details";
import Customs from "./components/Customs";

const ShipmentSearch = props => {
  const { closeModal } = props || {};
  const [fetchShipmentsV2, { loading, data: { shipmentsV2 } = {} }] =
    useLazyQuery(fetchShipmentsV2Query());
  const { data: { countries = [] } = {} } = useQuery(FETCH_COUNTRIES);

  const navigate = useNavigate();
  const { formik } = useShipmentFilters();
  const input = shipmentFilterStore.get();

  const inputs = {
    className: "grid grid-cols-4 gap-16 mt-5",
    inputs: [
      {
        className: "gap-4",
        inputs: [
          {
            label: "Shipment #",
            name: "id",
            type: "number",
          },
          {
            label: "Shipment Ref",
            name: "shippingRef",
            type: "text",
          },
          {
            label: "Job #",
            name: "jobId",
            type: "number",
          },
          {
            label: "Date - From",
            name: "date.value.from",
            type: "date",
          },
          {
            label: "Date - To",
            name: "date.value.to",
            type: "date",
          },
        ],
      },
      {
        className: "grid-cols-1 gap-4",
        inputs: [
          { label: "Consignor", name: "consignorId", type: "contactSearch" },
          {
            label: "Consignee",
            name: "consigneeId",
            type: "contactSearch",
          },
          {
            label: "Origin Country",
            name: "oriCountryId",
            type: "multi-select",
            options: countries?.map(item => ({
              label: item?.name,
              value: item?.id,
            })),
          },
          {
            label: "Destination Country",
            name: "delCountryId",
            type: "multi-select",
            options: countries?.map(item => ({
              label: item?.name,
              value: item?.id,
            })),
          },
        ],
      },
      {
        className: "grid-cols-1 gap-4",
        inputs: [
          {
            label: "Destination Post Code",
            name: "delPostcode",
            type: "text",
          },
          {
            label: "Shipper",
            name: "shipperId",
            type: "contactSearch",
            fetchType: "shipper",
          },
        ],
      },
      {
        className: "grid-cols-1 gap-4",
        inputs: [
          {
            label: "Cancelled",
            name: "cancel",
            type: "toggle",
            designType: "threeStateCheckbox",
          },
          {
            label: "Delivered - From",
            name: "delivered.value.from",
            type: "date",
          },
          {
            label: "Delivered - To",
            name: "delivered.value.to",
            type: "date",
          },
        ],
      },
    ],
  };

  const inputProps = {
    formik,
    ...inputs,
  };

  const searchButtonProps = {
    action: "black",
    label: loading ? "Searching" : "Search",
    disabled: loading,
    onClick: e =>
      fetchShipmentsV2({
        variables: {
          input,
        },
      }).then(resp => {
        navigate("/shipments?status=all");
        closeModal();
      }),
  };

  const resetButtonProps = {
    action: "default",
    label: "Reset",
    onClick: () => formik?.resetForm(),
  };

  const tabComponentProps = {
    shipmentsV2,
    formik,
  };

  const tabsProps = {
    formik,
    name: "tab",
    options: [
      {
        label: "Items",
        value: "items",
        component: <Items {...tabComponentProps} />,
      },
      {
        label: "Details",
        value: "details",
        component: <Details {...tabComponentProps} />,
      },
      {
        label: "Customs",
        value: "customs",
        component: <Customs {...tabComponentProps} />,
      },
      {
        label: "Query",
        value: "query",
        component: <Query {...tabComponentProps} />,
      },
    ],
  };

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between">
        <div className="text-xl font-bold">Shipment Search</div>
        <div className="flex gap-4">
          <Button {...resetButtonProps} />
          <Button {...searchButtonProps} />
        </div>
      </div>
      <div className="flex flex-col gap-8">
        <GridInputs {...inputProps} />
        <Tabs {...tabsProps} />
      </div>
    </div>
  );
};

export default ShipmentSearch;
