import { useEffect, useState } from "react";
import { Button, Modal } from "../ui";

const UnauthorizedError = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [perms, setPerms] = useState([]);

  useEffect(() => {
    window.addEventListener(
      "Error:Unauthorized",
      function (e) {
        setPerms(e?.detail?.permissions || []);
        setIsOpen(true);
      },
      false,
    );
  }, []);

  const modalProps = {
    body: ({ closeModal }) => {
      return (
        <div className="mt-4 flex">
          <Button
            label="Close"
            onClick={() => {
              closeModal?.();
            }}
          />
        </div>
      );
    },
    closeOnBackdrop: false,
    description:
      "Please contact your administrator." +
      (perms.length > 0 ? " Missing permission(s): " + perms.join(", ") : ""),
    isOpen,
    onClose: () => {
      setIsOpen(false);
    },
    scale: "sm",
    title: "You are not authorized",
  };

  return <Modal {...modalProps} />;
};

export default UnauthorizedError;
