import artistFrag from "./Artist";
import instanceFrag from "./Instance";
import WarehouseCategory from "./WarehouseCategory";
import warehouseMoveFrag from "./WarehouseMove";
import WarehouseWeightType from "./WarehouseWeightType";

const WarehouseItem = ({
  artist = false,
  category = false,
  instance = false,
  itemSite = false,
  warehouseMoves = false,
  weightType = false,
} = {}) => `
  id
  ${
    artist
      ? `artist {
      ${artistFrag(artist)}
    }`
      : ""
  }
  artistId
  ${
    category
      ? `category {
      ${WarehouseCategory}
    }`
      : ""
  }
  categoryId
  container
  description
  destroyed
  dimh
  dimw
  dimd
  externalRef
  image
  ${
    instance
      ? `instance {
      ${instanceFrag(instance)}
    }`
      : ""
  }
  instanceId
  itemDescription
  ${
    itemSite
      ? `itemSite {
      ${warehouseMoveFrag(itemSite)}
    }`
      : ""
  }
  link
  mgroup
  notes
  packingId
  responsibleGroupId
  ${
    warehouseMoves
      ? `warehouseMoves {
      ${warehouseMoveFrag(warehouseMoves)}
    }`
      : ""
  }
  weight
  ${
    weightType
      ? `weightType {
      ${WarehouseWeightType}
    }`
      : ""
  }
  weightTypeId
`;

export default WarehouseItem;
