import { PencilIcon } from "@heroicons/react/24/outline";

import { IconButton } from "../../../../..//components/ui/IconButton";
import { Modal } from "../../../../../components/ui/Modal";
import useUser from "../../../../../hooks/useUser";

import EditHeaderFooter from "./EditHeaderFooter";

const Actions = props => {
  const { id, values } = props || {};
  const { hasPermission } = useUser();

  const editModalProps = {
    body: EditHeaderFooter,
    hideCloseButton: true,
    // disabled: !hasPermission("insert permission here"),
    scale: "md",
    values,
  };

  if (typeof id == "number") {
    return (
      <div className="user-list-actions flex text-right text-sm font-medium">
        {id && (
          <div className="grid grid-cols-4 gap-4">
            <Modal {...editModalProps}>
              <IconButton
                variant="clean"
                title={
                  // eslint-disable-next-line
                  false && !hasPermission("insert permission here")
                    ? "Not enough permssion to edit"
                    : "Edit Auction"
                }
              >
                <PencilIcon
                  className={`h-4 w-4 cursor-pointer text-gray-400 ${false && !hasPermission("insert permission here") && "cursor-not-allowed"}`}
                />
              </IconButton>
            </Modal>
          </div>
        )}
      </div>
    );
  } else {
    return false;
  }
};

export default Actions;
