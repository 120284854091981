import {
  BookmarkIcon,
  DocumentTextIcon,
  EyeIcon,
  FlagIcon,
  HomeIcon,
  InformationCircleIcon,
  LinkIcon,
  Bars3Icon,
  ShoppingBagIcon,
  TagIcon,
  Squares2X2Icon,
  StopIcon,
  TruckIcon,
  ArrowUpCircleIcon,
  ArchiveBoxIcon,
  CheckCircleIcon,
  PaperClipIcon,
  ArrowsPointingOutIcon,
} from "@heroicons/react/24/outline";

import Artfair from "./pages/Artfair";
import Auctions from "./pages/Auctions";
import Condition from "./pages/Condition";
import Details from "./pages/Details";
import Provenance from "./pages/Provenance";
import Reservation from "./pages/Reservation";
import Sales from "./pages/Sales";
import Technical from "./pages/Technical";
import Consignment from "./pages/Consignment";
import Fabrication from "./pages/Fabrication";
import Holds from "./pages/Holds";
import Shipments from "./pages/Shipments";
import COA from "./pages/COA";
import Warehouse from "./pages/Warehouse";
import Job from "./pages/Jobs";
import Documents from "./pages/Documents";
import History from "./pages/History";

const routes = [
  {
    href: "/instance",
    icon: HomeIcon,
    name: "All Instances",
  },
  {
    element: Details,
    href: "details",
    icon: DocumentTextIcon,
    name: "Instance Details",
  },
  {
    element: Sales,
    href: "sales",
    icon: ShoppingBagIcon,
    name: "Sales",
  },
  {
    element: Holds,
    href: "holds",
    icon: StopIcon,
    name: "Holds",
  },
  {
    element: Consignment,
    href: "consignment",
    icon: TagIcon,
    name: "Consignment",
  },
  {
    element: Provenance,
    href: "provenance",
    icon: Bars3Icon,
    name: "Provenance",
  },
  {
    element: Auctions,
    href: "auctions",
    icon: FlagIcon,
    name: "Auctions",
  },
  {
    element: History,
    href: "history",
    icon: ArrowsPointingOutIcon,
    name: "History",
  },
  {
    element: Artfair,
    href: "artfairs",
    icon: LinkIcon,
    name: "Art Fairs",
  },
  {
    element: Shipments,
    href: "shipments",
    icon: TruckIcon,
    name: "Shipments",
  },
  {
    element: Condition,
    href: "conditions",
    icon: EyeIcon,
    name: "Condition",
  },
  {
    element: Technical,
    href: "technicals",
    icon: InformationCircleIcon,
    name: "Technical",
  },
  {
    element: Job,
    href: "jobs",
    icon: CheckCircleIcon,
    name: "Jobs",
  },
  {
    element: Warehouse,
    href: "warehouse",
    icon: ArchiveBoxIcon,
    name: "Warehouse",
  },
  {
    element: Fabrication,
    href: "fabrication",
    icon: Squares2X2Icon,
    name: "Fabrication",
  },
  {
    element: Reservation,
    href: "reservations",
    icon: BookmarkIcon,
    name: "Reservation",
  },
  {
    element: Documents,
    href: "documents",
    icon: PaperClipIcon,
    name: "Documents",
  },
  {
    element: COA,
    href: "coa",
    icon: ArrowUpCircleIcon,
    name: "COA",
  },
];

export default routes;
