import { useQuery } from "@apollo/client";
import { GridInputs } from "../../../../../components/Functional";
import { FETCH_CONDITION_STATUS } from "../../../../../graphql/query/ConditionStatus";

const Condition = props => {
  const { formik } = props || {};

  const { data: { conditionStatus = [] } = {} } = useQuery(
    FETCH_CONDITION_STATUS,
  );

  const inputs = {
    className: "grid grid-cols-4 gap-16 mt-5",
    inputs: [
      {
        className: "flex flex-col gap-4",
        inputs: [
          {
            hasOperator: true,
            isMulti: true,
            label: "Condition",
            name: "_condition.statusId",
            options: conditionStatus?.map(status => ({
              label: status.description,
              value: status.id,
            })),
            type: "multi-select",
          },
        ],
      },
    ],
  };

  const gridInputProps = {
    formik,
    ...inputs,
  };

  return <GridInputs {...gridInputProps} />;
};

export default Condition;
