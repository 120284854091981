import { useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import ImageSlider from "../../../../../Artwork/pages/Wizard/components/ImageSlider";

const DetailBar = props => {
  const { instance } = props;
  const [open, setOpen] = useState(false);

  const artistDetails = [
    // { label: "Artist", value: instance?.edition.artwork.artist.name },
    { label: "Title", value: instance?.edition?.artwork?.title },
    { label: "Edition", value: instance?.edition?.number },
    { label: "Medium", value: instance?.edition?.artwork?.medium },
    { label: "Dimensions", value: instance?.edition?.artwork?.dimensions },
    { label: "Series", value: instance?.edition?.artwork?.series },
    { label: "Category", value: instance?.edition?.artwork?.categoryId },
    { label: "Weight", value: instance?.weight },
    { label: "Description", value: instance?.edition?.artwork?.description },
    { label: "Notes", value: instance?.notes },
  ];

  const blocksClassNames = {
    label: "w-[100px] min-w-[100px] text-xs capitalise font-bold",
    block: "flex flex-row pb-3 flex-1",
    detail: "pl-4 text-sm break-all w-full",
  };

  return (
    <>
      <div className="min-h-full border border-b-0 border-t-0 border-gray-200">
        {open ? (
          <div className="w-full min-w-[350px] max-w-[400px] p-6">
            <div className="flex w-full flex-row">
              <div className="flex-1">
                <div className="text-l font-bold">Instance {instance?.id}</div>
              </div>
              <div>
                <ChevronLeftIcon
                  className="h-7 w-7 cursor-pointer"
                  onClick={() => setOpen(false)}
                />
              </div>
            </div>
            <div className="py-8">
              <ImageSlider
                images={instance?.edition?.artwork?.images || []}
                title=""
                previewHeight={210}
                sliderHeight={100}
                sliderWidth={110}
              />
            </div>
            {artistDetails?.map((item, index) => {
              return (
                <div className={blocksClassNames.block} key={index}>
                  <div className={blocksClassNames.label}>{item.label}</div>
                  <div className={blocksClassNames.detail}>{item.value}</div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="flex min-h-full flex-col px-[14px] py-[52px]">
            <ChevronRightIcon
              className="h-5 w-5 cursor-pointer"
              onClick={() => setOpen(true)}
            />
            <div className="relative flex min-h-full">
              <div className="absolute left-2/4 top-[75px] w-[155px] w-full -translate-x-1/2 -translate-y-1/2 -rotate-90 transform text-lg font-bold">
                Instance Details
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DetailBar;
