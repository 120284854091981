import { gql } from "@apollo/client";
import { CRMType } from "../fragments";

const FETCH_CRM_TYPES = gql`
  query FetchCRMTypes($input: CRMTypesInput) {
    crmTypes(input: $input) {
      ${CRMType}
    }
  }
`;

export { FETCH_CRM_TYPES };
