import { useMutation, useQuery } from "@apollo/client";
import { PencilIcon, TrashIcon, ClockIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { Button, IconButton, Modal } from "../../../../../components/ui";

import {
  DELETE_EMAIL,
  DUPLICATE_EMAIL as duplicateEmailMutaiton,
  UNSCHEDULE_EMAIL as unscheduleEmailMutation,
  UPDATE_EMAIL as updateEmailMutation,
} from "../../../../../graphql/mutation/Email";
import {
  FETCH_EMAIL as fetchEmail,
  FETCH_EMAILS as fetchEmails,
} from "../../../../../graphql/query/Email";
import FeatherIcon from "feather-icons-react";

const Actions = props => {
  const { id, values } = props || {};
  const navigate = useNavigate();
  useQuery(fetchEmail(), {
    pollInterval: 5000,
    skip: values?.status !== "sending",
    variables: { id },
  });
  const [deleteEmail, { client, loading }] = useMutation(DELETE_EMAIL);
  const [duplicateEmail] = useMutation(duplicateEmailMutaiton(), {
    refetchQueries: [fetchEmails()],
  });
  const [updateEmail, { client: updateClient }] = useMutation(
    updateEmailMutation(),
    { refetchQueries: [fetchEmails()] },
  );
  const [unscheduleEmail, { loading: unscheduleEmailLoading }] = useMutation(
    unscheduleEmailMutation(),
  );

  const handleEdit = () => {
    navigate(`${id}/content`);
  };

  const handleDuplicate = () => {
    duplicateEmail({
      variables: {
        input: {
          id,
        },
      },
    }).then(resp => {
      const {
        data: { duplicateEmail: { success, email } = {} },
      } = resp || {};
      if (success) {
        navigate(`${email?.id}/content`);
      }
    });
  };

  const handleUpdate = data => {
    updateEmail({
      variables: {
        input: {
          id,
          ...data,
        },
      },
    }).then(resp => {
      const {
        data: { updateEmail: { success } = {} },
      } = resp || {};
      if (success) {
        updateClient.resetStore();
      }
    });
  };

  const modalProps = {
    title: "Are you sure?",
    scale: "sm",
    description: `Would you like to delete email titled '${values?.title}'?`,
    closeOnBackdrop: true,
    body: ({ closeModal }) => {
      const handleDelete = () => {
        deleteEmail({
          variables: {
            input: { id },
          },
          update: (cache, { data }) => {
            const { deleteEmail: { success } = {} } = data;
            if (success) {
              // TODO: handle this through updating cache to improve performance
              closeModal();
              client.resetStore();
              // cache.evict({
              //   id: cache.identify({
              //     __typename: 'Email',
              //     id,
              //   }),
              // });
            }
          },
        });
      };

      return (
        <div className="mt-4 flex">
          <Button
            className="mr-3"
            label={"Cancel"}
            action={"default"}
            onClick={() => {
              closeModal?.();
            }}
          />
          <Button
            label={loading ? "Deleting" : "Delete"}
            disabled={loading}
            onClick={handleDelete}
          />
        </div>
      );
    },
  };

  const unscheduleModalProps = {
    title: "Are you sure?",
    scale: "sm",
    description: `Would you like to unschedule email titled '${values?.title}'?`,
    closeOnBackdrop: true,
    body: ({ closeModal }) => {
      const handleClick = () => {
        unscheduleEmail({
          variables: {
            input: { id },
          },
        }).then(() => {
          closeModal();
        });
      };

      return (
        <div className="mt-4 flex">
          <Button
            className="mr-3"
            label={"Cancel"}
            action={"default"}
            onClick={() => {
              closeModal?.();
            }}
          />
          <Button
            label={unscheduleEmailLoading ? "Unscheduling" : "Unschedule"}
            disabled={unscheduleEmailLoading}
            onClick={handleClick}
          />
        </div>
      );
    },
  };

  return (
    <div className="flex text-right text-sm font-medium">
      {id && (
        <div className="grid grid-cols-4 gap-4">
          {values?.meta?.locked !== true && (
            <IconButton variant="clean" title="Duplicate Email">
              <FeatherIcon
                icon={"copy"}
                className={`h-4 w-4 cursor-pointer text-gray-400`}
                onClick={handleDuplicate}
              />
            </IconButton>
          )}
          {["scheduled"].includes(values?.status) && (
            <Modal {...unscheduleModalProps}>
              <IconButton variant="clean" title="Unschedule Email">
                <ClockIcon className={`h-4 w-4 cursor-pointer text-gray-400`} />
              </IconButton>
            </Modal>
          )}
          {["draft"].includes(values?.status) && (
            <>
              <IconButton variant="clean" title="Edit Email">
                <PencilIcon
                  className={`h-4 w-4 cursor-pointer text-gray-400`}
                  onClick={handleEdit}
                />
              </IconButton>
              <Modal {...modalProps}>
                <IconButton variant="clean" title="Delete Email">
                  <TrashIcon
                    className={`h-4 w-4 cursor-pointer text-gray-400`}
                  />
                </IconButton>
              </Modal>
            </>
          )}
          {["sent", "archived"].includes(values?.status) && (
            <IconButton variant="clean" title="View Analytics">
              <FeatherIcon
                icon={"bar-chart-2"}
                className="h-4 w-4 cursor-pointer text-gray-400"
                onClick={() => navigate(`${id}/analytics`)}
              />
            </IconButton>
          )}
          {values?.status == "sent" && (
            <IconButton variant="clean" title="Archive Email">
              <FeatherIcon
                icon={"archive"}
                className={`h-4 w-4 cursor-pointer text-gray-400`}
                onClick={() => handleUpdate({ status: "archived" })}
              />
            </IconButton>
          )}
          {values?.status == "archived" && (
            <IconButton variant="clean" title="Unarchive Email">
              <FeatherIcon
                icon={"unlock"}
                className={`h-4 w-4 cursor-pointer text-gray-400`}
                onClick={() => handleUpdate({ status: "sent" })}
              />
            </IconButton>
          )}
          {/* {
            values?.status == "archived"
            ?
              values?.meta?.locked
              ?
                <IconButton variant="clean" title="Unlock Email">
                  <FeatherIcon icon={'lock'} className="h-4 w-4 cursor-pointer text-gray-400" onClick={() =>handleUpdate({meta:{...values?.meta, locked: false}})}/>
                </IconButton>
              :
                <IconButton variant="clean" title="Lock Email">
                  <FeatherIcon icon={'unlock'} className="h-4 w-4 cursor-pointer text-gray-400" onClick={() =>handleUpdate({meta:{...values?.meta, locked: true}})}/>
                </IconButton>
            :
              ''
          } */}
        </div>
      )}
    </div>
  );
};

export default Actions;
