import { useFormik } from "formik";
import { useEffect } from "react";
import { convertToFilter, makeObservable } from "../../../util";

// observable store
const invoiceFilterStore = makeObservable({});
const useInvoiceFilters = () => {
  const formik = useFormik({
    initialValues: {
      contactId: { operator: "eq" },
      limit: 50,
      offset: 0,
      tab: "invoice",
      sort1: { operator: "ASC" },
      sort2: { operator: "ASC" },
      sort3: { operator: "ASC" },
      companyId: { operator: "in" },
      _CRMRegistrar: { typeId: { operator: "in" } },
      _condition: { statusId: { operator: "in" } },
      _item: {
        typeId: { operator: "in" },
        _instance: {
          _edition: {
            _artwork: {
              categoryId: { operator: "in" },
              _artist: {
                id: { operator: "in" },
              },
            },
          },
        },
      },
      _invoiceSalesPerson: {
        split: { operator: "eq" },
      },
    },
  });

  const filterKeys = ["tab"];

  const skipKeys = ["limit", "offset"];

  useEffect(() => {
    // convert formik values to filter and update store
    invoiceFilterStore.set(
      convertToFilter({ values: formik.values, filterKeys, skipKeys }),
    );
  }, [formik.values]);

  return {
    formik,
  };
};

export { invoiceFilterStore, useInvoiceFilters };
