import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";

import { Button, getInput } from "../../../../components/ui";
import { CREATE_CONSIGNMENT_SHIPMENT } from "../../../../graphql/mutation/Consignment";
import { FETCH_CURRENCIES } from "../../../../graphql/query/Currency";

const CreateShipment = props => {
  const { closeModal, consignmentId } = props || {};
  const navigate = useNavigate();
  const [createConsignmentShipment, { loading }] = useMutation(
    CREATE_CONSIGNMENT_SHIPMENT,
  );
  const { data: { currencies = [] } = {} } = useQuery(FETCH_CURRENCIES);

  const formik = useFormik({
    initialValues: { consignmentId },
    validationSchema: Yup.object({
      consignerId: Yup.number().required("You must select consigner."),
      contactId: Yup.number().required("You must select consignee."),
      currencyId: Yup.number()
        .required("You must select currency.")
        .typeError("You must select currency."),
    }),
    onSubmit: async (values, { setErrors, resetForm }) => {
      const error = {};

      if (Object.keys(error).length <= 0) {
        createConsignmentShipment({
          variables: {
            input: values,
          },
        }).then(resp => {
          const {
            data: { createConsignmentShipment: { success, shipment } = {} },
          } = resp || {};
          if (success) {
            navigate(`/shipments/${shipment?.id}/details`);
          }
        });
      } else {
        setErrors(error);
      }
    },
  });

  const inputs = [
    { label: "Consigner", name: "consignerId", type: "contactSearch" },
    { label: "Consignee", name: "contactId", type: "contactSearch" },
    {
      label: "Currency",
      name: "currencyId",
      type: "multi-select",
      options:
        currencies?.map(currency => ({
          label: `${currency.description} (${currency.code})`,
          value: currency.id,
        })) || [],
    },
  ];

  const hasErrors = Object.keys(formik?.errors || {}).length > 0;

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          <div>Create Shipment</div>
        </div>
        <div className="flex gap-4">
          <Button
            action="default"
            label="Cancel"
            onClick={() => {
              closeModal();
              formik.resetForm();
            }}
          />
          <Button
            label={"Create"}
            loading={loading}
            disabled={loading || hasErrors}
            onClick={formik.submitForm}
          />
        </div>
      </div>
      <div className="w-50 grid grid-cols-2 gap-4">
        {inputs?.map((item, index) => {
          const inputProps = {
            ...item,
            formik,
          };
          return <div key={index}>{getInput(inputProps)}</div>;
        })}
      </div>
    </div>
  );
};

export default CreateShipment;
