import { useMutation, useQuery } from "@apollo/client";
import { Route, Routes as RouterRoutes, useParams } from "react-router-dom";
import { useFormik } from "formik";

import routes from "./routes";
import AuthRoute from "../../../../components/AuthRoute";
import { NavBar, Spinner } from "../../../../components/ui";
import { FETCH_INVOICE as fetchInvoice } from "../../../../graphql/query/Invoice";
import { UPDATE_INVOICE as updateInvoiceMutation } from "../../../../graphql/mutation/Invoice";
import { getChangedFields, Portal } from "../../../../util";
import { useEffect, useRef } from "react";

const Body = props => {
  const { loading, ...remainingProps } = props;

  return loading ? (
    <div className="h-100% flex w-full items-center justify-center">
      <Spinner />
    </div>
  ) : (
    <RouterRoutes>
      {routes
        ?.filter?.(item => item?.element)
        ?.map((item, index) => {
          return (
            <Route
              key={index}
              path={item?.href}
              element={
                <AuthRoute permissions={item.permissions}>
                  <item.element {...remainingProps} />
                </AuthRoute>
              }
            />
          );
        })}
    </RouterRoutes>
  );
};

const Wizard = props => {
  const { id } = useParams();
  const abortController = useRef();

  const { data: { invoice } = {}, loading } = useQuery(
    fetchInvoice({
      bank: {},
      contact: {},
      company: { images: {} },
      country: {},
      currency: {},
      invoiceSalesPersons: { salesUser: {} },
      invoiceTotals: {},
      items: {
        instance: { edition: { artwork: { imagesSummary: { imgT: {} } } } },
      },
      type: {},
    }),
    { variables: { id: +id } },
  );

  const [updateInvoice] = useMutation(updateInvoiceMutation(), {
    refetchQueries: ["FetchInvoice"],
  });

  const formik = useFormik({
    initialValues: invoice,
    enableReinitialize: true,
    onSubmit: values => {
      const changedFields = getChangedFields(values, invoice);

      if (Object.keys(changedFields).length > 0) {
        const controller = new AbortController();
        abortController.current = controller;

        updateInvoice({
          variables: {
            input: {
              id: invoice?.id,
              ...changedFields,
            },
          },
          context: {
            fetchOptions: {
              signal: controller.signal,
            },
          },
        });
      }
    },
  });

  useEffect(() => {
    if (formik?.values && abortController.current) {
      abortController.current.abort();
    }
  }, [formik?.values]);

  const bodyProps = {
    ...props,
    invoice,
    formik,
    invoiceId: +id,
  };

  return (
    <div className="relative flex min-h-full">
      <Portal id="sidebar">
        <NavBar
          navigation={routes?.filter?.(item => item?.icon)}
          open={props?.open}
        />
      </Portal>
      <div className="flex min-h-full flex-1 flex-col overflow-x-auto p-8">
        <Body loading={loading} {...bodyProps} />
      </div>
    </div>
  );
};

export default Wizard;
