import React, { useEffect } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";

import { getInput } from "../../../../../../../components/ui";
import { FETCH_SHIPMENT_CODES } from "../../../../../../../graphql/query/ShipmentCode";
import { FETCH_GUARANTEE_ACCOUNTS_V2 as fetchGuaranteeAccountsV2 } from "../../../../../../../graphql/query/GuaranteeAccount";
import { FETCH_SHIPMENT_INCOTERMS } from "../../../../../../../graphql/query/ShipmentIncoTerm";

const Customs = ({ formik, submitOnBlur = false }) => {
  const tarrifCodeOptions = [
    { value: -1, label: "true" },
    { value: 0, label: "false" },
  ];
  const disableConfig = {
    disabled: true,
    inputClassName: "!bg-white !text-gray-400 !border-gray-400",
  };
  const { data: { shipmentCodes = [] } = {} } = useQuery(FETCH_SHIPMENT_CODES);
  const [fetchGuaranteeAccounts, { data: { guaranteeAccountsV2 = {} } = {} }] =
    useLazyQuery(fetchGuaranteeAccountsV2({ company: true }));
  const { data: { shipmentIncoTerms = [] } = {} } = useQuery(
    FETCH_SHIPMENT_INCOTERMS,
  );

  useEffect(() => {
    if (!formik.values?.consignorId || !formik.values?.consigneeId) return;
    fetchGuaranteeAccounts({
      variables: {
        input: {
          expired: {
            value: 0,
            operator: "eq",
          },
          _company: {
            contactId: {
              value: [
                +formik?.values?.consignorId,
                +formik?.values?.consigneeId,
              ],
              operator: "in",
            },
          },
        },
      },
    });
  }, [fetchGuaranteeAccounts, formik.values]);

  const inputs = [
    {
      label: "Customs Code",
      name: "customsCode",
      type: "multi-select",
      options:
        shipmentCodes?.map(item => ({
          value: item?.description,
          label: `${item?.description || ""} | ${item?.definition || ""}`,
        })) || [],
      submitOnBlur,
    },
    { label: "EORI", name: "eori", type: "text", submitOnBlur },
    {
      label: "Tariff Code Match",
      name: "customsCodeMatch",
      type: "select",
      options: tarrifCodeOptions,
      submitOnBlur,
    },
    {
      label: "TA Account",
      name: "guaranteeAccountId",
      type: "multi-select",
      options:
        guaranteeAccountsV2?.edges?.map(item => ({
          value: +item.id,
          label: `${item?.number || ""} | ${item?.company?.name || ""}`,
        })) || [],
      submitOnBlur,
    },
    { label: "Customs Agent", name: "agent", type: "text", submitOnBlur },
    {
      label: "Customs Entry #",
      name: "customsRef",
      type: "text",
      submitOnBlur,
    },
    { label: "CDS", name: "cds", type: "text", submitOnBlur },
    { label: "Declarant", name: "declarant", type: "text", submitOnBlur },
    { label: "Bonded Ref", name: "bondRef", type: "text", submitOnBlur },
    {
      label: "Bonded Release Ref",
      name: "bondReleaseRef",
      type: "text",
      submitOnBlur,
      ...disableConfig,
    },
    {
      label: "Customs Entry Date",
      name: "customsDate",
      type: "date",
      submitOnBlur,
    },
    {
      label: "IncoTerm",
      name: "incoTerm",
      type: "multi-select",
      options: shipmentIncoTerms?.map(item => ({
        value: item?.id,
        label: `${item?.code || ""} | ${item?.description || ""} | ${item?.transport || ""}`,
      })),
      submitOnBlur,
    },
  ];

  return (
    <div className="grid-cols-1 gap-4">
      {inputs?.map((item, index) => {
        const inputProps = {
          formik,
          ...item,
        };

        return (
          <div key={index} className="pb-6">
            {getInput(inputProps)}
          </div>
        );
      })}
    </div>
  );
};

export default Customs;
