import { useQuery } from "@apollo/client";

import { getInput } from "../../../../../../../components/ui";
import { FETCH_CURRENCIES } from "../../../../../../../graphql/query/Currency";
import { FETCH_SHIPMENT_CATEGORIES } from "../../../../../../../graphql/query/ShipmentCategory";

const disableConfig = {
  disabled: true,
  inputClassName: "!bg-white !text-gray-400 !border-gray-400",
};

const Reference = ({ formik, submitOnBlur = false }) => {
  const inputs = [
    {
      name: "cancel",
      type: "checkbox",
      options: [{ label: "Cancelled", value: -1 }],
      singleCheckbox: true,
      submitOnBlur,
    },
    {
      label: "Job Reference",
      name: "jobId",
      type: "number",
      placeholder: "Type to add text",
      submitOnBlur,
    },
    {
      label: "Delivery Time(mins)",
      name: "time",
      type: "number",
      placeholder: "Type to add text",
      submitOnBlur,
    },
  ];

  const renderInputs = inputs?.map((item, index) => {
    const inputProps = {
      formik,
      ...item,
      ...formik?.getFieldProps(item.name),
    };

    return (
      <div key={index} className="pb-6">
        {getInput(inputProps)}
      </div>
    );
  });

  return <div className="grid-cols-1 gap-4">{renderInputs}</div>;
};

const Header = ({ formik, submitOnBlur = false }) => {
  const inputs = [
    {
      label: "Record Date & Time",
      name: "updatedAt",
      type: "timestamp",
      ...disableConfig,
      submitOnBlur,
    },
  ];

  const renderInputs = inputs?.map((item, index) => {
    const inputProps = {
      formik,
      ...item,
      ...formik?.getFieldProps(item.name),
    };

    return (
      <div key={index} className="grid-cols-1 gap-4">
        {getInput(inputProps)}
      </div>
    );
  });

  return (
    <div className="w-full">
      <div className="grid grid-cols-4 gap-16">{renderInputs}</div>
    </div>
  );
};

const Delivery = ({ formik, submitOnBlur = false, shipment }) => {
  const { data: { currencies = [] } = {} } = useQuery(FETCH_CURRENCIES);

  const inputs = [
    { label: "Date", name: "date", type: "date", submitOnBlur },
    {
      label: "Delivered",
      name: "delivered",
      type: "date",
      submitOnBlur,
      disabled: shipment.itemCount < 1,
      title:
        shipment.itemCount < 1 ? "Shipment items need to be added first" : "",
    },
    {
      label: "Currency",
      name: "currencyId",
      type: "select",
      options: currencies?.map(item => ({
        label: item?.code,
        value: item?.id,
      })),
      submitOnBlur,
    },
  ];

  return (
    <div className="grid-cols-1 gap-4">
      {inputs?.map((item, index) => {
        const inputProps = {
          formik,
          ...item,
          ...formik?.getFieldProps(item.name),
        };

        return (
          <div key={index} className="pb-6">
            {getInput(inputProps)}
          </div>
        );
      })}
    </div>
  );
};

const Shipper = ({ formik, submitOnBlur = false }) => {
  const { data: { shipmentCategories = [] } = {} } = useQuery(
    FETCH_SHIPMENT_CATEGORIES,
  );

  const shipperName = formik.values?.shipper?.name || "";
  const inputs = [
    {
      data: shipperName,
      label: "Shipper",
      name: "shipperId",
      type: "contactSearch",
      fetchType: "shipper",
      showCompany: true,
    },
    { label: "Shipping Ref", name: "shippingRef", type: "text", submitOnBlur },
    {
      label: "Category",
      name: "categoryId",
      type: "multi-select",
      options: shipmentCategories?.map(item => ({
        label: item?.description,
        value: item?.id,
      })),
      submitOnBlur,
    },
  ];

  return (
    <div className="grid-cols-1 gap-4">
      {inputs?.map((item, index) => {
        const inputProps = {
          formik,
          ...item,
        };

        return (
          <div key={index} className="pb-6">
            {getInput(inputProps)}
          </div>
        );
      })}
    </div>
  );
};

const Consigner = ({
  formik,
  submitOnBlur = false,
  shipment,
  shipmentHasConfirmedItems,
}) => {
  const consignorName = formik.values?.consignor?.name || "";
  const consigneeName = formik.values?.consignee?.name || "";

  const consignorInputs = [
    {
      data: consignorName,
      label: "Consigner",
      name: "consignorId",
      type: "contactSearch",
      submitOnBlur,
      disabled: shipment.itemCount > 0,
    },
    {
      label: "Consigner Company",
      name: "consignor.company",
      type: "text",
      ...disableConfig,
    },
  ];

  const consigneeInputs = [
    {
      data: consigneeName,
      label: "Consignee",
      name: "consigneeId",
      type: "contactSearch",
      submitOnBlur,
      disabled: shipmentHasConfirmedItems,
    },
    {
      label: "Consignee Company",
      name: "consignee.company",
      type: "text",
      ...disableConfig,
    },
  ];

  return (
    <div className="grid-cols-1 gap-4">
      <div className="rounded border border-black p-4">
        {consignorInputs?.map((item, index) => {
          const inputProps = {
            formik,
            ...item,
            ...formik?.getFieldProps(item.name),
          };

          return (
            <div key={index} className="pb-6">
              {getInput(inputProps)}
            </div>
          );
        })}
      </div>
      <div className="mt-8 rounded border border-black p-4">
        {consigneeInputs?.map((item, index) => {
          const inputProps = {
            formik,
            ...item,
            ...formik?.getFieldProps(item.name),
          };

          return (
            <div key={index} className="pb-6">
              {getInput(inputProps)}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const ShipmentDetail = ({
  formik,
  submitOnBlur = false,
  shipment,
  shipmentHasConfirmedItems,
}) => {
  return (
    <div className="w-full">
      <div className="mb-10">
        <Header formik={formik} />
      </div>
      <hr />
      <div className="mt-10">
        <div className="w-full">
          <div className="grid grid-cols-4 gap-16">
            <Delivery
              formik={formik}
              submitOnBlur={submitOnBlur}
              shipment={shipment}
            />
            <Shipper formik={formik} submitOnBlur={submitOnBlur} />
            <Consigner
              formik={formik}
              submitOnBlur={submitOnBlur}
              shipment={shipment}
              shipmentHasConfirmedItems={shipmentHasConfirmedItems}
            />
            <Reference formik={formik} submitOnBlur={submitOnBlur} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShipmentDetail;
