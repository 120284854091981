import MailingList from "./MailingList";

const ContactMailingList = ({ mailingList = false } = {}) => `
  id
  ${
    mailingList
      ? `mailingList {
      ${MailingList}
    }`
      : ""
  }
  mailingListId
  updatedAt
`;

export default ContactMailingList;
