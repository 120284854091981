import PropTypes from "prop-types";
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Image,
  Slide,
  Slider,
} from "pure-react-carousel";

import SliderControls from "./SliderControls";
import { useEffect, useState } from "react";
import { Modal } from "../Modal";
import { Button } from "../Button";
import { IconButton } from "../IconButton";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";

const DetailViewCarousel = props => {
  const { imageList: propsImageList = [], actions, artworkId } = props;

  const [imageList, setImageList] = useState(propsImageList);
  const [successOpModal, setSuccessOpModal] = useState({
    isOpen: false,
    message: "",
  });

  useEffect(() => {
    if (props.imageList.length > 0) {
      setImageList(propsImageList);
    }
  }, [propsImageList]);

  return (
    <CarouselProvider
      visibleSlides={6}
      totalSlides={imageList.length}
      naturalSlideWidth={275}
      naturalSlideHeight={300}
      hasMasterSpinner={true}
      touchEnabled={false}
      dragEnabled={false}
      className={"flex w-full justify-between"}
    >
      <ButtonBack>
        <IconButton variant="clean" title="Back">
          <ChevronLeftIcon className={`h-5 w-5 cursor-pointer`} />
        </IconButton>
      </ButtonBack>
      <Slider className={"w-11/12 gap-16"} draggable={false}>
        {imageList?.map((image, index) => (
          <Slide
            index={`dvc_image_${index}`}
            key={index}
            className="group relative"
          >
            <div className={`grid h-full w-full grid-rows-6 gap-1 px-4`}>
              <div
                className={`relative row-span-5 w-full group-hover:border ${index === 0 ? "bg-gray-200 px-4 py-3 " : ""}`}
              >
                <Image
                  src={image?.imgT}
                  className={`box-border object-cover ${index === 0 ? "shadow-md shadow-gray-500" : ""}`}
                />
                <SliderControls
                  imageIndex={index}
                  images={imageList}
                  artworkId={artworkId}
                  actions={actions}
                  setImageList={setImageList}
                  setSuccessOpModal={setSuccessOpModal}
                />
              </div>
              <div
                className={`row-span-1 flex justify-${index === 0 ? "between" : "end"} block text-sm font-medium text-black`}
              >
                {index === 0 && <span>Primary</span>}
                {index + 1}
              </div>
            </div>
          </Slide>
        ))}
      </Slider>
      <ButtonNext>
        <IconButton variant="clean" title="Next">
          <ChevronRightIcon className={`h-5 w-5 cursor-pointer`} />
        </IconButton>
      </ButtonNext>
      <Modal
        scale="sm"
        closeOnBackdrop={false}
        hideCloseButton={true}
        isOpen={successOpModal.isOpen}
        onClose={() => setSuccessOpModal({ isOpen: false, message: "" })}
        body={({ closeModal }) => (
          <div className="w-full">
            <div className="mb-5 text-lg font-medium leading-6 text-gray-900">
              {successOpModal.message}
            </div>
            <Button
              label={"Close"}
              onClick={() => {
                closeModal();
              }}
            />
          </div>
        )}
      />
    </CarouselProvider>
  );
};

DetailViewCarousel.propTypes = {
  imageList: PropTypes.array.isRequired,
};

DetailViewCarousel.defaultProps = {
  imageList: [],
};

export { DetailViewCarousel };
