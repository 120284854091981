import { Link } from "react-router-dom";
import SectionHeader from "../../components/SectionHeader";
import { Table } from "../../../../../../components/Functional";
import { FETCH_SHIPMENTS_V2 as fetchShipmentsV2 } from "../../../../../../graphql/query/Shipment";

const JobShipments = ({ jobId }) => {
  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "shipmentsV2",
    headers: [
      {
        label: "Shipment",
        name: "id",
        sortKey: "id",
        type: "custom",
        component: ({ values }) => (
          <Link className="underline" to={`/shipments/${values?.id}/details`}>
            {values?.id}
          </Link>
        ),
      },
      { label: "Start Date", name: "date", type: "date" },
      {
        label: "Origin",
        type: "custom",
        component: ({ values }) => (
          <div
            title={values?.delCompany ? values?.delCompany : values?.delName}
            className="flex gap-1"
          >
            <span className="overflow-hidden text-ellipsis text-base font-medium leading-5 text-black">
              {values?.oriCompany ? values?.oriCompany : values?.oriName}
            </span>
          </div>
        ),
      },
      {
        label: "Destination",
        type: "custom",
        component: ({ values }) => (
          <div
            title={values?.delCompany ? values?.delCompany : values?.delName}
            className="flex gap-1"
          >
            <span className="overflow-hidden text-ellipsis text-base font-medium leading-5 text-black">
              {values?.delCompany ? values?.delCompany : values?.delName}
            </span>
          </div>
        ),
      },
    ],
    inputs: [],
    FETCH_QUERY: fetchShipmentsV2(),
    variables: {
      jobId: {
        operator: "eq",
        value: jobId,
      },
    },
  };

  return (
    <div className="w-full">
      <SectionHeader title="Shipments" />
      <div className="mb-6 w-full">
        <Table {...tableProps} />
      </div>
    </div>
  );
};

export default JobShipments;
