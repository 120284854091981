import { gql } from "@apollo/client";
import { Invoice as invoice } from "../fragments";

const FETCH_INVOICE = props => gql`
  query FetchInvoice($id: Int) {
    invoice(id: $id) {
      ${invoice(props)}
    }
  }
`;

const FETCH_INVOICES = props => gql`
  query FetchInvoices($input: InvoicesFilterInput!) {
    invoices(input: $input) {
      count
      edges {
        ${invoice(props)}
      }
    }
  }
`;

const FETCH_INVOICES_V2 = props => gql`
  query FetchInvoicesV2($input: AdvInvoiceFilterInput!) {
    invoicesV2(input: $input) {
      count
      edges {
        ${invoice(props)}
      }
      sqlQuery
    }
  }
`;

const FETCH_INVOICE_INSTANCE = gql`
  query FetchInvoiceInstance($input: InvoiceInstanceInput!) {
    invoiceInstance(input: $input) {
      amount
      consigneeSplit
      consigneeSplitRate
      customs
      description
      discount
      invoiceId
      nominalId
      ref
      source
      tax
      taxAmount

      success
      error
    }
  }
`;

export {
  FETCH_INVOICE,
  FETCH_INVOICES,
  FETCH_INVOICES_V2,
  FETCH_INVOICE_INSTANCE,
};
