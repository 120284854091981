const Bibliography = `
  id
  artworkId
  day
  illustrated
  isbn
  month
  notes
  page
  publication
  publisher
  title
  type
  updatedAt
  writer
  year
`;

export default Bibliography;
