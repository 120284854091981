import { useMutation, useQuery } from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom";

import { Breadcrumb, Button, MenuButton, Modal } from "../../../components/ui";
import { FETCH_INVOICE as fetchInvoice } from "../../../graphql/query/Invoice";

import routes from "../pages/Wizard/routes";

import AddInvoice from "./Actions/AddInvoice";
import ChangeToInvoice from "./Actions/ChangeToInvoice";
import CreateShipmentWork from "./Actions/CreateShipmentWork";
// import ExportInvoiceReport from './Actions/ExportInvoiceReport'
import ExportActiveReport from "../../../components/Modals/ExportActiveReport";
import ExportSageAccounts from "./Actions/ExportSageAccounts";
import InvoiceSearch from "./InvoiceSearch";
import exportModule from "./Actions/ExportModule";
import AdhocReportBody from "./Actions/AdhocReportBody";
import { invoiceFilterStore } from "./useInvoiceFilters";
import RemoveItem from "./Actions/RemoveItem";
import { RE_INVOICE as reInvoiceMutation } from "../../../graphql/mutation/Invoice";
import useUser from "../../../hooks/useUser";

const PrimaryNav = props => {
  const { formik } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const pathnames = location?.pathname.split("/");
  const search = invoiceFilterStore.get();
  const { hasPermission } = useUser();

  const invoiceObj = {
    bank: { currency: {} },
    company: { images: {} },
    contact: {
      contactAddresses: {
        country: {},
        type: {},
      },
      contactArtists: { artist: { contact: {} } },
      contactCompany: { images: {} },
      contactFocuses: { focus: {} },
      contactMailingLists: { mailingList: {} },
      contactNumbers: { type: {} },
      country: {},
      documents: {},
      exhUser: {},
      firstUser: {},
      purchases: {},
      lastOffer: {
        artwork: {
          artist: {
            contact: {},
          },
          editions: {
            artwork: {},
            availableNumbers: {},
            instances: {
              arrApplicable: {},
              company: {},
              condition: {},
              description: {},
              edition: {},
              isBond: {},
              isGuaranteed: {},
              isOnLoan: {},
              isSecondaryMarket: {},
              status: {},
              unpackedWeight: {},
              value: {},
              location: {},
              volume: {},
              typeId: 1,
              weight: {},
            },
            type: {},
          },
          images: {},
          imagesSummary: {},
          archiveDescription: {},
          artistDescription: {},
          fullDescription: {},
          pressDescription: {},
        },
        contact: {},
        currency: {},
        hold: {},
        statis: {},
      },
      requests: {},
      salesUser: {},
      salesUser2: {},
      type: {},
      type2: {},
    },
    country: {},
    currency: {},
    items: {
      consignmentChannel: {},
      instance: {
        arrApplicable: {},
        company: {},
        condition: {},
        description: {},
        edition: {
          artwork: {
            images: {
              imgT: {},
              imgX: {},
              imgA: {},
              imgB: {},
              jpegUrls: {},
              photoMaterial: {},
              urls: {},
            },
            imagesSummary: {
              imgT: {},
              imgX: {},
              imgA: {},
              imgB: {},
              jpegUrls: {},
              photoMaterial: {},
              urls: {},
            },
          },
        },
        isBond: {},
        isGuaranteed: {},
        isOnLoan: {},
        isSecondaryMarket: {},
        status: {},
        unpackedWeight: {},
        value: {},
        location: {},
        volume: {},
        typeId: 1,
        weight: {},
      },
      invoice: {
        bank: {},
        company: {},
        contact: {},
        country: {},
        currency: {},
        items: {},
        invoiceTotals: {},
        invoiceSalesPersons: {},
        salesUser: {},
        type: {},
      },
      nominal: {},
      type: {},
    },
    invoiceTotals: {},
    invoiceSalesPersons: {
      salesUser: {},
    },
    salesUser: {},
    type: {},
  };

  const [reInvoice, { loading, error, reset }] =
    useMutation(reInvoiceMutation());

  // get id from url
  const id = pathnames?.length > 2 ? pathnames[2] : null;
  // fetch invoice details if id is available
  const { data: { invoice } = {} } = useQuery(fetchInvoice(invoiceObj), {
    skip: !id,
    variables: { id: +id },
  });

  // find current submodule route
  const path = pathnames[pathnames?.length - 1];
  const route = routes?.find(item => item?.href?.includes(path));
  const queryParams = new URLSearchParams(window.location.search);
  const status = queryParams.get("status") || "all";
  const selected = Object.keys(formik?.values?.selected?.ids || {});
  const listId = +(
    queryParams.get("listId") ||
    (status === "selectListWorking" ? queryParams.get("workingListId") : null)
  );
  const ids = selected?.map(key => {
    const obj = formik?.values?.selected?.ids?.[key];
    return obj?.item?.id || obj?.id;
  });
  const select = formik?.values?.selected?.select;
  const disabled = ids?.length === 0;

  const statusMap = {
    all: "All Invoices",
    searched: "Searched Invoices",
    selectListWorking: "Select List (Working)",
  };
  const label = statusMap[status] || "All Invoices";

  // prepare steps for breadcrumbs
  const steps = [
    { label, onClick: () => navigate(`/invoice?status=${status}`) },
    ...(invoice
      ? [
          {
            label: invoice?.id,
            onClick: () => navigate(`${invoice?.id}/details?status=${status}`),
          },
          {
            label: route?.name,
            onClick: () => navigate(`${invoice?.id}/${route?.href}`),
          },
        ]
      : []),
  ];

  const breadcrumbProps = {
    currentStepIndex: steps?.length - 1,
    onChange: index => steps[index]?.onClick(),
    steps: steps?.map(item => item?.label),
  };

  const defaultActionProps = {
    closeOnBackdrop: false,
    hideCloseButton: true,
    ids,
    listId,
    onClose: () => {
      formik?.resetForm();
    },
    scale: "md",
    search,
    select,
  };

  const ReInvoiceModalProps = {
    title: error ? "Error" : "Duplicate Invoice?",
    scale: "sm",
    description: error
      ? error?.message
      : `Are you sure you want to duplicate this invoice?`,
    closeOnBackdrop: true,
    body: ({ closeModal }) => {
      const handleDuplicate = () => {
        reInvoice({
          variables: {
            input: { id: invoice?.id },
          },
          update: (cache, { data }) => {
            const { reInvoice: { invoice, success } = {} } = data || {};
            if (success) {
              closeModal();
              reset();
              navigate(`${invoice?.id}/details`);
            }
          },
        });
      };

      return (
        <div className="mt-4 flex">
          <Button
            className="mr-3"
            label={error ? "Close" : "Cancel"}
            action={error ? "primary" : "default"}
            onClick={() => {
              closeModal?.();
              reset();
            }}
          />
          {!error && (
            <Button
              label={loading ? "Duplicating" : "Duplicate"}
              disabled={loading}
              onClick={handleDuplicate}
            />
          )}
        </div>
      );
    },
  };

  const actionMenuProps = {
    label: "Actions",
    options: [
      {
        label: "Add Invoice",
        disabled: !hasPermission("UPDATE_INVOICE"),
        modalProps: {
          body: AddInvoice,
          closeOnBackdrop: false,
          hideCloseButton: true,
          scale: "md",
        },
      },
      {
        disabled,
        label: "Create an Ahoc Report",
        modalProps: {
          body: AdhocReportBody,
          ...defaultActionProps,
          exportModule,
        },
      },
      {
        disabled: !invoice?.id && disabled,
        label: "Export Invoice Report",
        modalProps: {
          ...defaultActionProps,
          body: ExportActiveReport,
          obj: invoiceObj,
          type: "Invoice",
          ids: invoice?.id ? [invoice?.id] : ids,
        },
      },
      {
        label: "Export to Line 200",
        modalProps: {
          body: ExportSageAccounts,
          title: "Export Sage Accounts",
          scale: "sm",
          description: "Are you sure you want to export Sage Accounts?",
          closeOnBackdrop: false,
          hideCloseButton: true,
          // scale: "md",
        },
      },
      ...(invoice?.id
        ? [
            // {
            //   label: 'Export Report',
            //   modalProps: {
            //     body: ExportInvoiceReport,
            //     closeOnBackdrop: false,
            //     hideCloseButton: true,
            //     invoice,
            //     scale: "md",
            //   },
            // },
            {
              label: "Copy JSON",
              onClick: () =>
                navigator.clipboard.writeText(JSON.stringify(invoice)),
            },
            {
              disabled: invoice?.type?.id === 1,
              label: "Change Proforma to Invoice",
              modalProps: {
                body: ChangeToInvoice,
                closeOnBackdrop: false,
                invoice,
                hideCloseButton: true,
                scale: "md",
              },
            },
            {
              label: "Create Shipment Work",
              modalProps: {
                body: CreateShipmentWork,
                closeOnBackdrop: false,
                invoice,
                hideCloseButton: true,
                scale: "md",
              },
            },
            {
              label: "Remove Item",
              modalProps: {
                body: RemoveItem,
                closeOnBackdrop: false,
                invoice,
                hideCloseButton: true,
                scale: "md",
              },
            },
            {
              label: "ReInvoice",
              modalProps: ReInvoiceModalProps,
            },
          ]
        : []),
    ],
  };

  const searchModalProps = {
    body: InvoiceSearch,
    closeOnBackdrop: true,
    scale: "fullscreen",
  };

  return (
    <div className="flex items-center justify-between border-b px-8 py-5">
      <Breadcrumb {...breadcrumbProps} />
      <div className="flex justify-end gap-4">
        <Modal {...searchModalProps}>
          <Button label="Invoice Search" action="black" />
        </Modal>
        <MenuButton {...actionMenuProps} />
      </div>
    </div>
  );
};

export default PrimaryNav;
