import * as React from "react";

import { Table } from "../../../../../../components/Functional";
import { Modal, Button, getInput } from "../../../../../../components/ui";
import { FETCH_CRM_REGISTRARS_V2 as fetchCRMRegistrarsV2 } from "../../../../../../graphql/query/CRMRegistrar";

import SectionHeader from "../../components/SectionHeader";

import Actions from "./components/Actions";
import AddRegistrar from "./components/AddRegistrar";
import { useQuery } from "@apollo/client";
import { FETCH_ENTITIES } from "../../../../../../graphql/query/Entity";
import { useFormik } from "formik";
import useUser from "../../../../../../hooks/useUser";

const Registrar = props => {
  const { hasPermission } = useUser();
  const { consignment } = props || {};

  const formik = useFormik({
    initialValues: {
      userId: null,
    },
  });

  const { data: { entities } = {} } = useQuery(FETCH_ENTITIES);

  const registrarSearch = formik?.values?.userId && {
    userId: {
      operator: "eq",
      value: formik.values?.userId,
    },
  };

  const tableProps = {
    className: "sm:px-0 lg:px-0",
    dataKey: "crmRegistrarsV2",
    FETCH_QUERY: fetchCRMRegistrarsV2({ type: {}, user: {} }),
    headers: [
      { label: "Date", name: "date", type: "label" },
      { label: "Type", name: "type.description", type: "label" },
      {
        label: "Description",
        name: "description",
        type: "label",
        widthInPx: 300,
      },
      { label: "User", name: "user.name", type: "label" },
      { label: "", name: "action", type: "custom", component: Actions },
    ],
    variables: {
      _consignment: {
        id: {
          operator: "eq",
          value: consignment?.id,
        },
      },
      ...registrarSearch,
    },
    variant: "dynamicHeight",
  };

  const modalProps = {
    body: AddRegistrar,
    closeOnBackdrop: true,
    consignment,
    userId: formik.values.userId,
    hideCloseButton: true,
    scale: "md",
    disabled: !hasPermission("UPDATE_CONSIGNMENT"),
  };

  const actions = [
    {
      component: () => {
        return (
          <Modal {...modalProps}>
            <Button
              label="Add Registrar"
              action="black"
              disabled={!hasPermission("UPDATE_CONSIGNMENT")}
            />
          </Modal>
        );
      },
    },
  ];

  return (
    <div className="w-full">
      <SectionHeader title="Registrar" actions={actions} />
      <div className="grid w-full grid-cols-4 px-8">
        <div className="px-4">
          {getInput({
            label: "Registrar",
            name: "userId",
            type: "multi-select",
            options:
              entities?.edges?.map(item => {
                return { label: item.name, value: item.id };
              }) || [],
            formik,
          })}
        </div>
      </div>
      <div className="mb-6 w-full">
        <Table {...tableProps} />
      </div>
    </div>
  );
};

export default Registrar;
