import { useQuery } from "@apollo/client";
import { GridInputs } from "../../../../../components/Functional";
import { FETCH_SHIPMENT_CODES } from "../../../../../graphql/query/ShipmentCode";
import { FETCH_GUARANTEE_ACCOUNTS as fetchGuaranteeAccounts } from "../../../../../graphql/query/GuaranteeAccount";

const Details = props => {
  const { formik } = props || {};
  const { data: { shipmentCodes = [] } = {} } = useQuery(FETCH_SHIPMENT_CODES);
  const { data: { guaranteeAccounts = [] } = {} } = useQuery(
    fetchGuaranteeAccounts(),
  );

  const inputs = {
    className: "grid grid-cols-4 gap-16 mt-5",
    inputs: [
      {
        className: "flex flex-col gap-4",
        inputs: [
          {
            label: "Customs Code",
            name: "customsCode",
            type: "multi-select",
            options: shipmentCodes?.map(({ description }) => ({
              value: description,
              label: description,
            })),
          },
          {
            label: "Customs Declaration",
            name: "customsDeclaration",
            type: "text",
          },
        ],
      },
      {
        className: "flex flex-col gap-4",
        inputs: [
          {
            label: "Customs Entry",
            name: "customsRef",
            type: "text",
            hasOperator: true,
            operatorOptions: [{ label: "Null", value: "eq" }],
          },
          {
            label: "Customs Entry Date - From",
            name: "customsDate.value.from",
            type: "date",
          },
          {
            label: "Customs Entry Date - To",
            name: "customsDate.value.to",
            type: "date",
          },
        ],
      },
      {
        className: "flex flex-col gap-4",
        inputs: [
          {
            label: "Agent",
            name: "agent",
            type: "text",
          },
          {
            label: "TA Account",
            name: "guaranteeAccountId",
            type: "multi-select",
            options: guaranteeAccounts?.map(item => ({
              value: +item.id,
              label: item.number,
            })),
          },
        ],
      },
      {
        className: "flex flex-col gap-4",
        inputs: [
          {
            label: "Proof of Export",
            name: "exportProof",
            type: "toggle",
            designType: "threeStateCheckbox",
          },
          {
            label: "Condition",
            name: "examined",
            type: "radio",
            options: [
              { label: "Good Condition", value: 1 },
              { label: "Unexamined", value: 2 },
            ],
          },
        ],
      },
    ],
  };

  const gridInputProps = {
    formik,
    ...inputs,
  };

  return <GridInputs {...gridInputProps} />;
};

export default Details;
