import {
  AdjustmentsVerticalIcon,
  CogIcon,
  EnvelopeIcon,
  QuestionMarkCircleIcon,
  ArrowPathIcon,
  UsersIcon,
  QueueListIcon,
} from "@heroicons/react/24/outline";

import Users from "./pages/Users";
import Changelog from "./pages/Changelog";
import EditUser from "./pages/EditUser";
import EditPermission from "./pages/EditPermission";
import Handover from "./pages/Handover";
import Roles from "./pages/Roles";
import EditRole from "./pages/EditRole";
import Support from "./pages/Support";
import EmailFooter from "./pages/EmailFooter";
import HeaderFooter from "./pages/HeaderFooter";
import Configuration from "./pages/Configuration";

const routes = [
  {
    element: Changelog,
    href: "changelog",
    icon: QueueListIcon,
    name: "Changelog",
    permissions: ["ADMIN_MODULE"],
  },
  {
    element: Roles,
    href: "role",
    icon: AdjustmentsVerticalIcon,
    name: "Role",
    permissions: ["READ_ROLE"],
  },
  {
    element: EditRole,
    href: "role/:id/edit",
    permissions: ["READ_ROLE", "UPDATE_ROLE"],
  },
  {
    element: EditRole,
    href: "role/new",
    permissions: ["READ_ROLE", "CREATE_ROLE"],
  },
  {
    element: Users,
    href: "users",
    icon: UsersIcon,
    name: "User",
    permissions: ["READ_USER", "ADMIN_MODULE"],
  },
  {
    element: EditUser,
    href: "users/:id/edit",
    permissions: ["READ_USER", "UPDATE_USER"],
  },
  {
    element: EditPermission,
    href: "users/:id/edit/permission",
    permissions: ["READ_USER", "UPDATE_USER"],
  },
  {
    element: Support,
    href: "support",
    icon: QuestionMarkCircleIcon,
    name: "Support",
  },
  {
    element: Support,
    href: "support/*",
  },
  {
    element: Handover,
    href: "handover",
    icon: ArrowPathIcon,
    name: "Handover",
  },
  {
    element: EmailFooter,
    href: "email-footer",
    icon: EnvelopeIcon,
    name: "Email footer",
  },
  {
    element: HeaderFooter,
    href: "header-footer",
    icon: EnvelopeIcon,
    name: "Header & footer",
  },
  {
    element: Configuration,
    href: "configuration",
    icon: CogIcon,
    name: "Configuration",
  },
];

export default routes;
