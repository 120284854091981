import { gql } from "@apollo/client";
import { JobType } from "../fragments";

const FETCH_JOB_TYPES = gql`
  query FetchJobTypes {
    jobTypes {
      ${JobType}
    }
  }
`;

export { FETCH_JOB_TYPES };
