import { gql } from "@apollo/client";
import { ConsignmentDocument } from "../fragments";

const FETCH_CONSIGNMENT_DOCUMENTS = gql`
  query FetchConsignmentDocuments($input: ConsignmentDocumentFilterInput!) {
    consignmentDocuments(input: $input) {
      count
      edges {
        ${ConsignmentDocument}
      }
    }
  }
`;

export { FETCH_CONSIGNMENT_DOCUMENTS };
