import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";

import Filters from "./Filters";

import { Button, SearchBar } from "../../../../../components/ui";

const FilterBar = props => {
  const { onChange } = props || {};
  const navigate = useNavigate();

  const inputs = [
    // {
    //   name: 'type',
    //   label: 'Type',
    //   type: 'select',
    //   options: [
    //     {
    //       label: 'Exhibition',
    //       value: 'Exhibition'
    //     },
    //     {
    //       label: 'Single Artwork',
    //       value: 'Single Artwork'
    //     },
    //     {
    //       label: 'Multi Artwork',
    //       value: 'Multi Artwork'
    //     },
    //   ]
    // },
    {
      name: "status",
      label: "Status",
      type: "select",
      options: [
        {
          label: "All",
          value: "",
        },
        {
          label: "Active",
          value: "active",
        },
        {
          label: "Draft",
          value: "draft",
        },
        {
          label: "Shared",
          value: "shared",
        },
      ],
    },
    { name: "title", label: "Title", type: "text" },
    // {
    //   name: 'artist',
    //   label: 'Artist',
    //   type: 'select',
    //   options: []
    // },
    { name: "from", label: "From", type: "date" },
    { name: "to", label: "To", type: "date" },
  ];

  const initialValues = {
    search: "",
    ...inputs
      ?.map(item => ({ ...item, value: "" }))
      .reduce(
        (acc, item) => ({
          ...acc,
          [item.name]: item.value,
        }),
        {},
      ),
  };
  const formik = useFormik({
    initialValues,
  });

  useEffect(() => {
    onChange(formik.values);
  }, [formik.values]);

  const filterProps = {
    inputs,
    formik,
    type: "simple",
  };

  const searchBarProps = {
    name: "search",
    formik,
  };

  return (
    <div className="flex">
      <Button
        label="New PDF"
        onClick={() => navigate("create-pdf/title")}
        action="dark"
        className="mr-8"
      />
      <div className="mr-4">
        <SearchBar {...searchBarProps} />
      </div>
      <Filters {...filterProps} />
    </div>
  );
};

export default FilterBar;
