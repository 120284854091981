import { useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";

import ListActivities from "./components/ListActivities";
import AddActivity from "./components/AddActivity";

const ActivityTracker = props => {
  const [open, setOpen] = useState(false);

  return (
    <div className="absolute bottom-0 right-0 top-0 min-h-full overflow-y-auto border border-b-0 border-t-0 border-gray-200 bg-white">
      {open ? (
        <div className="w-[640px] w-full flex-col px-[40px] py-[60px]">
          <div className="flex w-full flex-row items-center justify-center">
            <ChevronRightIcon
              className="h-5 w-5 cursor-pointer"
              onClick={() => setOpen(false)}
            />
            <div className="ml-[20px] flex-1 text-2xl font-bold tracking-wider">
              Record an Activity
            </div>
          </div>
          <AddActivity {...props} />
          <ListActivities {...props} />
        </div>
      ) : (
        <div className="flex min-h-full flex-col px-[14px] py-[52px]">
          <ChevronLeftIcon
            className="h-5 w-5 cursor-pointer"
            onClick={() => setOpen(true)}
          />
          <div className="relative flex min-h-full">
            <div className="absolute left-2/4 top-[75px] w-[135px] w-full -translate-x-1/2 -translate-y-1/2 -rotate-90 transform text-lg font-bold">
              Record Activity
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ActivityTracker;
