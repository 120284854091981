import { gql } from "@apollo/client";
import { InvoiceChannel } from "../fragments";

const FETCH_INVOICE_CHANNELS = gql`
  query FetchInvoiceChannels {
    invoiceChannels {
      ${InvoiceChannel}  
    }
  }
`;

export { FETCH_INVOICE_CHANNELS };
