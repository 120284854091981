import { useMutation, useQuery } from "@apollo/client";
import React from "react";
import { useFormik } from "formik";

import { Button, getInput } from "../../../../../../../components/ui";
import { CREATE_CONTACT_ACTIVITY as createContactActivityMutation } from "../../../../../../../graphql/mutation/ContactActivity";
import { FETCH_ARTISTS as fetchArtists } from "../../../../../../../graphql/query/Artist";
import { FETCH_CURRENCIES } from "../../../../../../../graphql/query/Currency";

const AddActivity = props => {
  const { contact } = props || {};
  const [createContactActivity, { loading }] = useMutation(
    createContactActivityMutation(),
    { refetchQueries: ["FetchContactActivities"] },
  );
  const { data: { artists = [] } = {} } = useQuery(fetchArtists());
  const { data: { currencies = [] } = {} } = useQuery(FETCH_CURRENCIES);

  const formik = useFormik({
    initialValues: {},
  });

  const isValid = formik?.values?.meta?.type;

  const handleAdd = () => {
    createContactActivity({
      variables: {
        input: {
          contactId: contact?.id,
          ...formik?.values,
        },
      },
    }).then(() => {
      formik.resetForm();
    });
  };

  const input = {
    formik,
    label: "ACTIVITY TYPE",
    name: "meta.type",
    options: [
      { label: "Add a Note", value: "note" },
      {
        label: `Record an Artwork to ${contact?.name} Collection`,
        value: "collection",
      },
      { label: `Add an Auction activity`, value: "auction" },
      {
        label: `Track an engagement with ${contact?.name}`,
        value: "engagement",
      },
    ],
    placeholder: "Select Activity Type",
    type: "select",
    variant: "simple",
    bottomLabel: true,
    rightLabel: true,
  };

  const supportInputs = {
    note: [{ label: "Notes", name: "meta.notes", type: "textarea" }],
    collection: [
      { label: "ARTWORK NAME", name: "meta.artworkName", type: "text" },
      // { label: "ARTWORK IMAGES", name: 'meta.artworkImages', type: "img" },
      {
        label: "ARTIST",
        name: "meta.artistId",
        type: "multi-select",
        options: artists?.map(item => ({
          label: item?.name,
          value: item?.name,
        })),
      },
      { label: "NOTES", name: "meta.notes", type: "textarea" },
    ],
    auction: [
      { label: "AUCTION NAME", name: "meta.auctionName", type: "text" },
      { label: "ITEM NAME OR LOT NUMBER", name: "meta.itemName", type: "text" },
      { label: "URL", name: "meta.url", type: "text" },
      { label: "MAX BID", name: "meta.maxBid", type: "number" },
      {
        label: "CURRENY",
        name: "meta.currencyId",
        type: "multi-select",
        options: currencies?.map(item => ({
          label: item?.code,
          value: item?.code,
        })),
      },
      { label: "DATE", name: "meta.date", type: "date" },
      {
        label: "WON?",
        name: "meta.isWon",
        type: "checkbox",
        options: [{ label: "Won", value: true }],
      },
    ],
    engagement: [
      { label: "NAME", name: "meta.name", type: "text" },
      {
        label: "ENGAGEMENT TYPE",
        name: "meta.engagementType",
        options: [
          { label: "Call", value: "call" },
          { label: "In Person", value: "inPerson" },
          { label: "WhatsApp", value: "whatsapp" },
          { label: "Text", value: "text" },
          { label: "Email", value: "email" },
        ],
        placeholder: "Select Engagement Type",
        type: "select",
      },
      { label: "DATE", name: "meta.date", type: "date" },
    ],
  };

  const inputs = supportInputs[formik?.values?.meta?.type] || [];

  return (
    <div className="mt-[40px] flex justify-between">
      <div className="mr-[36px] flex flex-1 flex-col">
        <div className="mb-[40px]">{getInput(input)}</div>
        {inputs?.map((item, index) => {
          return (
            <div className="mb-[40px]" key={index}>
              {getInput({
                ...item,
                formik,
                bottomLabel: true,
                rightLabel: true,
                variant: "simpleRight",
                inputClassName: "w-full",
              })}
            </div>
          );
        })}
      </div>
      <div className="mt-[20px] flex w-36 flex-col">
        <Button
          label="Add"
          loading={loading}
          disabled={!isValid}
          onClick={handleAdd}
        />
      </div>
    </div>
  );
};

export default AddActivity;
