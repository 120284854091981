const exportModule = {
  name: "Artwork",
  sections: [
    {
      fields: [
        { label: "Artists", name: "ArtistArtworks.Artist.name" },
        { label: "Title", name: "title" },
        { label: "Category", name: "ArtworkCategory.name" },
        { label: "Medium", name: "medium" },
        { label: "Year", name: "Editions.year" },
        { label: "Series", name: "series" },
        { label: "Dimensions", name: "dimensions" },
        { label: "Description", name: "description" },
        { label: "Id", name: "id" },
        { label: "Image Archive Id", name: "Images.archiveId" },
        { label: "Handling", name: "standardId" },
        { label: "Photographed", name: "photographed" },
        { label: "Travel Pack", name: "travelPack" },
        { label: "Confidential", name: "confidential" },
      ],
      label: "",
      name: "artwork",
    },
    {
      fields: [
        {
          label: "Title Keyword",
          name: "Editions.Instances.ExhibitionWorks.Exhibition.title",
        },
        {
          label: "Id",
          name: "Editions.Instances.ExhibitionWorks.Exhibition.id",
        },
      ],
      label: "Exhibition",
      name: "exhibition",
    },
    {
      fields: [
        { label: "Status", name: "Editions.Instances.Status.description" },
      ],
      label: "Instance",
      name: "instance",
    },
  ],
};

export default exportModule;
