import { useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import DangerIcon from "../../../assets/icons/DangerIcon";
import InfoIcon from "../../../assets/icons/InfoIcon";
import SuccessIcon from "../../../assets/icons/SuccessIcon";
import WarningIcon from "../../../assets/icons/WarningIcon";
import DarkIcon from "../../../assets/icons/DarkIcon";

const AlertComponent = props => {
  const { text, isOpen, type, onClose } = props;
  const [alertStatus, changeAlertStatus] = useState(isOpen);
  const defaultAlertStyle = "border border-gray-900 text-gray-700 bg-zinc-50";
  const handleOnClose = () => {
    onClose?.();
    changeAlertStatus(false);
  };
  const alertType = {
    info: {
      className: defaultAlertStyle,
      icon: <InfoIcon />,
    },
    warning: {
      className: defaultAlertStyle,
      icon: <WarningIcon />,
    },
    danger: {
      className: defaultAlertStyle,
      icon: <DangerIcon />,
    },
    success: {
      className: defaultAlertStyle,
      icon: <SuccessIcon />,
    },
    dark: {
      className: "text-zinc-50 bg-black",
      icon: <DarkIcon />,
    },
  };
  const { className, icon } = alertType?.[type] || alertType?.info || {};
  return (
    <div
      className={`
        ${
          alertStatus ? "" : "hidden"
        } fixed right-4 top-4 z-50 ml-4 min-w-[160px] max-w-[600px] rounded-lg ${className}
      `}
    >
      <div className="relative px-8 py-4 md:pr-16">
        <div
          className="absolute right-2 top-2 w-4 cursor-pointer"
          onClick={handleOnClose}
        >
          <XMarkIcon className="absolute w-4 cursor-pointer" />
        </div>
        <div className="flex flex-row items-center">
          <div className="mr-4">{icon}</div>
          <div className="capitalise text-xs font-medium tracking-wider">
            {text}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertComponent;
