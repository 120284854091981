import { gql } from "@apollo/client";
import { Entity } from "../fragments";

const FETCH_ENTITIES = gql`
  query FetchEntities($input: EntityFilterInput) {
    entities(input: $input){
      count
      edges {
        ${Entity}
      }
    }
  }
`;

export { FETCH_ENTITIES };
