import { gql } from "@apollo/client";
import { DocumentPageTemplate } from "../fragments";

const FETCH_DOCUMENT_PAGE_TEMPLATES = gql`
  query FetchDocumentPageTemplates {
    documentPageTemplates {
      ${DocumentPageTemplate}
    }
  }
`;

export { FETCH_DOCUMENT_PAGE_TEMPLATES };
