/* eslint-disable max-len */
const GridIcon = props => (
  <svg
    width={20}
    height={20}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>{"Modules"}</title>
    <defs>
      <path
        d="M10 2H3c-.6 0-1 .4-1 1v7c0 .6.4 1 1 1h7c.6 0 1-.4 1-1V3c0-.6-.4-1-1-1ZM9 9H4V4h5v5Zm12-7h-7c-.6 0-1 .4-1 1v7c0 .6.4 1 1 1h7c.6 0 1-.4 1-1V3c0-.6-.4-1-1-1Zm-1 7h-5V4h5v5Zm1 4h-7c-.6 0-1 .4-1 1v7c0 .6.4 1 1 1h7c.6 0 1-.4 1-1v-7c0-.6-.4-1-1-1Zm-1 7h-5v-5h5v5Zm-10-7H3c-.6 0-1 .4-1 1v7c0 .6.4 1 1 1h7c.6 0 1-.4 1-1v-7c0-.6-.4-1-1-1Zm-1 7H4v-5h5v5Z"
        id="icon-grid"
      />
    </defs>
    <g transform="translate(-2 -2)" fill="none" fillRule="evenodd">
      <mask id="icon-grid-b" fill="#fff">
        <use xlinkHref="#icon-grid" />
      </mask>
      <use fill="#000" fillRule="nonzero" xlinkHref="#icon-grid" />
      <g mask="url(#icon-grid-b)" fill="#212121">
        <path d="M0 0h24v24H0z" />
      </g>
    </g>
  </svg>
);

export default GridIcon;
