import { Table } from "../../../../../../components/Functional";
import Actions from "./components/Actions";
import SectionHeader from "../../components/SectionHeader";
import { FETCH_FABRICATIONS_V2 as fetchFabricationsV2 } from "../../../../../../graphql/query/Fabrication";
import AddEditCost from "./components/AddEditCost";
import { Button, Modal } from "../../../../../../components/ui";
import useUser from "../../../../../../hooks/useUser";

const CostTotal = props => {
  const { costTotal = 0.0 } = props || {};
  return (
    <div className="border-b pb-4 pl-[1145px] pt-4 font-bold">
      Balance:{" "}
      {Number(costTotal).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}
    </div>
  );
};

const ConsignmentCosts = props => {
  const { hasPermission } = useUser();
  const { consignment } = props || {};

  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "fabricationsV2",
    headers: [
      { label: "Date", name: "date", type: "date" },
      { label: "Stage", name: "stage.name", type: "label" },
      { label: "Type", name: "category.name", type: "label" },
      { label: "Job #", name: "jobId", type: "label" },
      { label: "Service", name: "type.description", type: "label" },
      { label: "Description", name: "description", type: "label" },
      { label: "PO #", name: "po", type: "label" },
      { label: "Net Amount", name: "amount", type: "currency" },
      { label: "Invoice", name: "invoice", type: "label" },
      { label: "Recharge", name: "recharge", type: "currency" },
      { label: "Notes", name: "notes", type: "label" },
      { label: "", name: "action", type: "custom", component: Actions },
    ],
    inputs: [{ label: "Name", name: "name", type: "text", view: "partial" }],
    FETCH_QUERY: fetchFabricationsV2({ category: {}, stage: {}, type: {} }),
    footerComponent: CostTotal,
    variables: {
      _consignment: {
        id: {
          value: +consignment?.id,
          operator: "eq",
        },
      },
    },
    className: "sm:px-0 lg:px-0",
  };

  const modalProps = {
    body: AddEditCost,
    closeOnBackdrop: true,
    consignment,
    hideCloseButton: true,
    scale: "md",
    disabled: !hasPermission("UPDATE_CONSIGNMENT"),
  };

  const actions = [
    {
      component: () => {
        return (
          <Modal {...modalProps}>
            <Button
              label="Add Cost"
              action="black"
              disabled={!hasPermission("UPDATE_CONSIGNMENT")}
            />
          </Modal>
        );
      },
    },
  ];

  return (
    <div className="w-full">
      <SectionHeader title="Costs" actions={actions} />
      <div className="mb-6 w-full">
        <Table {...tableProps} />
      </div>
    </div>
  );
};

export default ConsignmentCosts;
