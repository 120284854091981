const exportModule = {
  name: "Shipment",
  sections: [
    {
      fields: [
        { label: "Id", name: "id" },
        { label: "Shipment Ref", name: "shippingRef" },
        { label: "Job ID", name: "jobId" },
        { label: "Date", name: "date" },
        { label: "Consignor", name: "consignorId" },
        { label: "Consignee", name: "Consignee.name" },
        { label: "Origin Country", name: "OriCountry.name" },
        { label: "Destination Country", name: "DelCountry.name" },
        { label: "Destination Post Code", name: "delPostcode" },
        { label: "Shipper", name: "Shipper.name" },
        { label: "Cancelled", name: "cancel" },
        { label: "Delivered", name: "delivered" },
      ],
      label: "",
      name: "shipment",
    },
    {
      fields: [
        { label: "Customs Code", name: "customsCode" },
        { label: "Customs Declaration", name: "customsDeclaration" },
        { label: "Customs Entry", name: "customsRef" },
        { label: "Customs Entry Date", name: "customsDate" },
        { label: "Agent", name: "agent" },
        { label: "TA Account", name: "guaranteeAccountId" },
        { label: "Proof of Export", name: "exportProof" },
        { label: "Condition", name: "examined" },
      ],
      label: "Details",
      name: "details",
    },
    {
      fields: [
        { label: "EORI", name: "eori" },
        { label: "CDS", name: "cds" },
        { label: "Declarant", name: "declarant" },
      ],
      label: "Customs",
      name: "customs",
    },
    {
      fields: [
        { label: "Id", name: "WarehouseMoves.WarehouseItem.id" },
        {
          label: "User",
          name: "WarehouseMoves.user",
        },
        { label: "Artist", name: "WarehouseMoves.WarehouseItem.Artist.name" },
        { label: "Location", name: "WarehouseMoves.locationId" },
        {
          label: "Customs Controlled",
          name: "WarehouseMoves.ti",
        },
      ],
      label: "Items",
      name: "items",
    },
  ],
};

export default exportModule;
