import { useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import { useEffect } from "react";
import * as Yup from "yup";

import { Button } from "../../../../../../../components/ui";
import { GridInputs } from "../../../../../../../components/Functional";
import { currentLocaleISODate } from "../../../../../../../util";

import { FETCH_CRM_TYPES } from "../../../../../../../graphql/query/CRMType";
import { FETCH_ENTITIES } from "../../../../../../../graphql/query/Entity";
import {
  CREATE_CRM_REGISTRAR as createCRMRegistrarMutation,
  UPDATE_CRM_REGISTRAR as updateCRMRegistrarMutation,
} from "../../../../../../../graphql/mutation/CRMRegistrar";
import useUser from "../../../../../../../hooks/useUser";

const AddEditDebtors = props => {
  const { invoiceId, closeModal, values, isOpen } = props;

  const { data: { crmTypes = [] } = {} } = useQuery(FETCH_CRM_TYPES, {
    variables: { input: { categoryId: 2 } },
  });
  const { data: { entities = [] } = {} } = useQuery(FETCH_ENTITIES);

  const [createCRMRegistrar, { loading: loadingCreate }] = useMutation(
    createCRMRegistrarMutation(),
    {
      refetchQueries: ["FetchCRMRegistrarsV2"],
    },
  );

  const [updateCRMRegistrar, { loading: loadingUpdate }] = useMutation(
    updateCRMRegistrarMutation(),
    {
      refetchQueries: ["FetchCRMRegistrarsV2"],
    },
  );

  const addTechnical = () => {
    const input = {
      categoryId: 2, // id of 'CRMCategory: {description: Accounts}'
      description: formik.values.description,
      issueId: formik.values.issueId || null,
      typeId: formik.values.typeId,
    };

    if (values?.id) {
      input.id = values?.id;
    } else {
      input.date = formik.values.date;
      input.userId = formik.values.userId;
      input.entityId = input.userId;
      input.invoiceId = invoiceId;
    }

    const action = values?.id ? updateCRMRegistrar : createCRMRegistrar;

    action({
      variables: {
        input,
      },
    }).then(() => {
      closeModal();
    });
  };

  const { user } = useUser();
  const entity = entities.edges.filter(e => e.id === user?.entityId)[0];

  const formik = useFormik({
    initialValues: values
      ? {
          date: values.date,
          description: values.description,
          typeId: values.typeId,
          userId: values.userId,
        }
      : {
          date: currentLocaleISODate(),
          userId: entity.id,
        },
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: Yup.object({
      date: Yup.date().required("Please enter a date."),
    }),
    onSubmit: addTechnical,
  });

  const inputs = {
    className: "grid grid-cols-2 gap-12",
    inputs: [
      {
        className: "grid grid-cols-1 gap-4",
        inputs: [
          { label: "Date", name: "date", type: "date", disabled: true },
          {
            label: "Description",
            name: "description",
            rows: 4,
            type: "textarea",
          },
        ],
      },
      {
        className: "grid grid-cols-1 gap-4",
        inputs: [
          {
            label: "Type",
            name: "typeId",
            type: "multi-select",
            placeholder: "Select a type",
            options: crmTypes?.map(item => ({
              label: item?.description,
              value: item?.id,
            })),
          },
          {
            label: "User Name",
            name: "userId",
            type: "multi-select",
            placeholder: "Select user",
            options: entities?.edges?.map(item => ({
              label: item?.name,
              value: item?.id,
            })),
            disabled: true,
          },
        ],
      },
    ],
  };

  const inputProps = {
    ...inputs,
    formik,
  };

  const successLabel = values?.id ? "Edit" : "Add";

  useEffect(() => {
    formik.handleReset();
  }, [isOpen]);

  return (
    <div className="flex w-full flex-col p-8">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          {values?.id ? "Edit" : "Add"} Debtor Record
        </div>
        <div className="flex flex-col">
          <div>
            <Button
              action="default"
              className="mr-8"
              label="Cancel"
              onClick={closeModal}
            />
            <Button
              label={`${successLabel}${
                loadingUpdate || loadingCreate ? "ing" : ""
              } Debtors`}
              disabled={loadingUpdate || loadingCreate}
              onClick={formik.submitForm}
            />
          </div>
        </div>
      </div>
      <div className="mt-12 pb-10">
        <GridInputs {...inputProps} />
      </div>
    </div>
  );
};

export default AddEditDebtors;
