const DocumentPageTemplate = `
  id
  name
  compiledHtml
  meta
  createdAt
  updatedAt
`;

export default DocumentPageTemplate;
