import { gql } from "@apollo/client";
import { EmailContact } from "../fragments";

const FETCH_EMAIL_CONTACTS = gql`
  query FetchEmailContacts($input: EmailContactFilterInput!) {
    emailContacts(input: $input) {
      count
      edges {
        ${EmailContact}
      }
    }
  }
`;

const FETCH_EMAIL_CONTACT = gql`
  query FetchEmailContact($id: Int) {
    emailContact(id: $id) {
      ${EmailContact}
    }
  }
`;

export { FETCH_EMAIL_CONTACT, FETCH_EMAIL_CONTACTS };
