import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { useFormik } from "formik";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { FETCH_CURRENCIES } from "../../../../graphql/query/Currency";

import { Button, IconButton, Input, Modal } from "../..";
import { GridInputs } from "../../../Functional";

const ARR = props => {
  const { disabled, error, label, loading, onChange: setValue, value } = props;
  const { data: { currencies = [] } = {} } = useQuery(FETCH_CURRENCIES);
  const [arrValue, setArrValue] = useState();

  const handleSave = closeModal => {
    setValue(arrValue);
    closeModal?.();
  };

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,
  });

  const calculateARR = amount => {
    const tiers = [
      { threshold: 50000, rate: 0.04 },
      { threshold: 200000, rate: 0.03 },
      { threshold: 350000, rate: 0.01 },
      { threshold: 500000, rate: 0.005 },
      { threshold: Infinity, rate: 0.0025 },
    ];

    let remainingAmount = amount;
    let ARRAmount = 0;

    for (const tier of tiers) {
      if (remainingAmount <= 0) {
        break;
      }

      const eligibleAmount = Math.min(remainingAmount, tier.threshold);
      const tierARRAmount = eligibleAmount * tier.rate;
      ARRAmount += tierARRAmount;

      remainingAmount -= eligibleAmount;
    }

    // Capped the ARRAmount at £12,500
    return Math.min(ARRAmount, 12500);
  };

  useEffect(() => {
    if (formik?.values?.currencyId && formik?.values?.amount) {
      setArrValue(calculateARR(formik?.values?.amount));
    }
  }, [formik?.values?.currencyId, formik?.values?.amount]);

  const inputs = {
    className: "grid grid-cols-1 gap-4",
    inputs: [
      { label: "Amount", name: "amount", type: "number" },
      {
        label: "Currency",
        name: "currencyId",
        type: "multi-select",
        isMulti: false,
        options: currencies?.map(currency => ({
          label: `${currency.description} (${currency.code})`,
          value: currency.id,
        })),
      },
    ],
  };
  const inputProps = {
    formik,
    ...inputs,
  };

  const modalProps = {
    scale: "md",
    hideCloseButton: true,
    closeOnBackdrop: true,
    disabled,
    body: ({ closeModal }) => {
      return (
        <div className="flex w-full flex-col p-8">
          <div className="flex flex-row">
            <div className="flex flex-1 flex-col text-2xl font-bold">
              <div>{label} Calculator</div>
            </div>
            <div className="flex flex-col">
              <div>
                <Button
                  action="default"
                  className="mr-8"
                  label="Cancel"
                  onClick={() => {
                    closeModal();
                    formik?.resetForm();
                    setArrValue();
                  }}
                />
                <Button
                  label={loading ? "Updating" : "Update"}
                  disabled={loading}
                  onClick={() => {
                    handleSave(closeModal);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="mt-8">
            <div className="grid grid-cols-2 gap-8">
              <GridInputs {...inputProps} />
              <Input
                type="text"
                label="Droite de Suite / ARR"
                value={arrValue}
                disabled={true}
              />
            </div>
            <p className="mt-8">
              Note that the Droite de Suite is calculated in Euros so any other
              currency will not involve a conversion and is only approximate
            </p>
          </div>
        </div>
      );
    },
  };

  return (
    <Modal {...modalProps}>
      <div className={`flex flex-1 flex-col`}>
        <div className={`block text-xs font-medium text-black`}>{label}</div>
        <div
          className={`mt-1 flex h-[38px] w-full items-center rounded border border-black focus:border-black focus:ring-0 ${disabled ? "bg-gray-100" : ""}`}
        >
          <div className={` flex-1 px-3 py-2 text-base `}>{value}</div>
          <IconButton variant="clean" title="Droite De Suite / ARR">
            <QuestionMarkCircleIcon
              className={`h-5 w-5 cursor-pointer text-gray-400`}
            />
          </IconButton>
        </div>
        {error && <small className="text-sm text-red-600">{error}</small>}
      </div>
    </Modal>
  );
};

export default ARR;
