import contact from "./Contact";
import Country from "./Country";
import Currency from "./Currency";
import ShipmentCategory from "./ShipmentCategory";

const Shipment = ({
  category = false,
  consignee = false,
  consignor = false,
  currency = false,
  delCountry = false,
  oriContact = false,
  oriCountry = false,
  shipmentDescriptions = false,
  shipper = false,
} = {}) => `
  id
  agent
  bondRef
  bondReleaseRef
  cancel
  ${
    category
      ? `category {
      ${ShipmentCategory}
    }`
      : ""
  }
  categoryId
  cds
  condition
  ${
    consignee
      ? `consignee {
      ${contact(consignee)}
    }`
      : ""
  }
  consigneeId
  ${
    consignor
      ? `consignor {
      ${contact(consignor)}
    }`
      : ""
  }
  consignorId
  ${
    currency
      ? `currency {
      ${Currency}
    }`
      : ""
  }
  currencyId
  customsDate
  customsCode
  customsCodeMatch
  customsCheck
  customsCheckDate
  customsCheckUser
  customsDeclaration
  customsRef
  customsRequest
  customsRequestDate
  customsRequestUser
  customsReceived
  customsReceivedDate
  customsReceivedUser
  date
  declarant
  delAddress
  delAddress2
  delAddress3
  delCompany
  delContactId
  ${
    delCountry
      ? `delCountry {
      ${Country}
    }`
      : ""
  }
  delCountryId
  delCounty
  delivered
  delName
  delPostcode
  delTown
  destination
  eori
  declarant
  examined
  guaranteeAccountId
  incoTerm
  insurance
  instructions
  jobId
  oriAddress
  oriAddress2
  oriAddress3
  oriCompany
  ${
    oriContact
      ? `oriContact {
      ${contact(oriContact)}
    }`
      : ""
  }
  oriContactId
  ${
    oriCountry
      ? `oriCountry {
      ${Country}
    }`
      : ""
  }
  oriCountryId
  oriCounty
  oriName
  oriPostcode
  oriTown
  ${shipmentDescriptions ? "shipmentDescriptions" : ""}
  ${
    shipper
      ? `shipper {
      ${contact(shipper)}
    }`
      : ""
  }
  shipperId
  shipping
  shippingRef
  time
  itemCount
  totalValue
  unconfirmedItemCount
  updatedAt
`;

export default Shipment;
