import * as React from "react";
import { useQuery } from "@apollo/client";
import { Table } from "../../../../../../components/Functional";
import { FETCH_CONTACT_CATALOGUES as fetchContactCatalogues } from "../../../../../../graphql/query/ContactCatalogue";
import Actions from "./components/Actions";
import SectionHeader from "../../components/SectionHeader";
import { Modal, Button } from "../../../../../../components/ui";
import AddMaterial from "./components/AddMaterial";
import { FETCH_NOMINALS } from "../../../../../../graphql/query/Nominal";
import { FETCH_CATALOGUES } from "../../../../../../graphql/query/Catalogue";
import useUser from "../../../../../../hooks/useUser";

const ContactMaterials = props => {
  const { contact, contactId } = props || {};
  const { data: { nominals = [] } = {} } = useQuery(FETCH_NOMINALS);
  const { data: { catalogues = [] } = {} } = useQuery(FETCH_CATALOGUES);
  const { hasPermission } = useUser();

  const disabled = !hasPermission("EDIT_CONTACT_CATALOGUE");
  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "contactCatalogues",
    headers: [
      { label: "Date", name: "updatedAt", sortKey: "updatedAt", type: "date" },
      {
        label: "Catalogue",
        name: "catalogue.title",
        sortKey: "catalogueId",
        type: "label",
      },
      {
        label: "Source",
        name: "nominal.description",
        sortKey: "nominalId",
        type: "label",
      },
      { label: "Note", name: "notes", sortKey: "notes", type: "label" },
      { label: "", name: "action", type: "custom", component: Actions },
    ],
    inputs: [
      { label: "From Date", name: "from", type: "date", view: "partial" },
      { label: "To Date", name: "to", type: "date", view: "partial" },
      {
        label: "Catalogue",
        name: "catalogueId",
        type: "multi-select",
        view: "partial",
        options: catalogues?.map(item => ({
          label: item.title,
          value: item.id,
        })),
      },
      {
        label: "Source",
        name: "nominalId",
        type: "multi-select",
        view: "partial",
        options: nominals?.map(item => ({ label: item.label, value: item.id })),
      },
      { label: "Note", name: "notes", type: "text", view: "partial" },
    ],
    FETCH_QUERY: fetchContactCatalogues({ catalogue: {}, nominal: {} }),
    variables: {
      contactId,
    },
    className: "sm:px-0 lg:px-0",
  };

  const modalProps = {
    body: AddMaterial,
    closeOnBackdrop: true,
    contact,
    disabled,
    hideCloseButton: true,
    scale: "md",
  };

  const materialActions = [
    {
      component: () => {
        return (
          <Modal {...modalProps}>
            <Button
              label="Add Material Sent"
              action="black"
              disabled={disabled}
            />
          </Modal>
        );
      },
    },
  ];

  return (
    <div className="w-full">
      <SectionHeader title="Material Sent" actions={materialActions} />
      <div className="mb-6 w-full">
        <Table {...tableProps} />
      </div>
    </div>
  );
};

export default ContactMaterials;
