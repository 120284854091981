import { useMutation } from "@apollo/client";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import AddMaterial from "./AddMaterial";
import { Button, IconButton, Modal } from "../../../../../../../components/ui";
import { DELETE_CONTACT_CATALOGUE } from "../../../../../../../graphql/mutation/ContactCatalogue";
import useUser from "../../../../../../../hooks/useUser";

const Actions = props => {
  const { id, contact, values } = props || {};
  const [deleteContactCatalogue, { loading }] = useMutation(
    DELETE_CONTACT_CATALOGUE,
  );
  const { hasPermission } = useUser();
  const disabled = !hasPermission("EDIT_CONTACT_CATALOGUE");

  const modalProps = {
    body: AddMaterial,
    closeOnBackdrop: true,
    contact,
    contactCatalogue: values?.rawFields,
    disabled,
    hideCloseButton: true,
    scale: "md",
  };

  const deleteModalProps = {
    title: "Are you sure?",
    scale: "sm",
    description: `Would you like to delete contact catalogue '${values?.catalogue?.title}'?`,
    closeOnBackdrop: true,
    body: ({ closeModal }) => {
      const handleDelete = () => {
        deleteContactCatalogue({
          variables: {
            input: { id: +values?.rawFields?.id },
          },
          update: (cache, { data }) => {
            const { deleteContactCatalogue: { success } = {} } = data;
            if (success) {
              closeModal();
              cache.evict({
                id: cache.identify({
                  __typename: "ContactCatalogue",
                  id,
                }),
              });
            }
          },
        });
      };

      return (
        <div className="mt-4 flex">
          <Button
            className="mr-3"
            label={"Cancel"}
            action={"default"}
            onClick={() => {
              closeModal?.();
            }}
          />
          <Button
            label={loading ? "Deleting" : "Delete"}
            disabled={loading}
            onClick={handleDelete}
          />
        </div>
      );
    },
  };

  if (typeof id == "number") {
    return (
      <div className="user-list-actions flex text-right text-sm font-medium">
        {id && (
          <div className="grid grid-cols-4 gap-4">
            {!disabled && (
              <>
                <Modal {...modalProps}>
                  <IconButton variant="clean" title="Edit Material">
                    <PencilIcon
                      className={`h-4 w-4 cursor-pointer text-gray-400`}
                    />
                  </IconButton>
                </Modal>
                <Modal {...deleteModalProps}>
                  <IconButton variant="clean" title="Delete Material">
                    <TrashIcon
                      className={`h-4 w-4 cursor-pointer text-gray-400`}
                    />
                  </IconButton>
                </Modal>
              </>
            )}
          </div>
        )}
      </div>
    );
  } else {
    return false;
  }
};

export default Actions;
