import { gql } from "@apollo/client";
import { Edition as edition } from "../fragments";

const CREATE_EDITION = props => gql`
  mutation CreateEdition($input: CreateEditionInput!) {
    createEdition(input: $input) {
      edition {
        ${edition(props)}
      }
      error
      success
    }
  }
`;
const DELETE_EDITION = gql`
  mutation DeleteEdition($input: DeleteEditionInput!) {
    deleteEdition(input: $input) {
      success
      error
    }
  }
`;

const UPDATE_EDITION = props => gql`
  mutation UpdateEdition($input: UpdateEditionInput!) {
    updateEdition(input: $input) {
      edition {
        ${edition(props)}
      }
      success
      error
    }
  }
`;

export { CREATE_EDITION, DELETE_EDITION, UPDATE_EDITION };
