import { gql } from "@apollo/client";
import { Request as request } from "../fragments";

const CREATE_REQUEST = props => gql`
  mutation CreateRequest($input: CreateRequestInput!) {
    createRequest(input: $input) {
      success
      error
      request {
        ${request(props)}
      }
    }
  }
`;

const DELETE_REQUEST = gql`
  mutation DeleteRequest($input: DeleteRequestInput!) {
    deleteRequest(input: $input) {
      success
      error
    }
  }
`;

const UPDATE_REQUEST = props => gql`
  mutation UpdateRequest($input: UpdateRequestInput!) {
    updateRequest(input: $input) {
      success
      error
      request {
        ${request(props)}
      }
    }
  }
`;

export { CREATE_REQUEST, DELETE_REQUEST, UPDATE_REQUEST };
