import Catalogue from "./Catalogue";
import Nominal from "./Nominal";

const ContactCatalogue = ({ catalogue = false, nominal = false } = {}) => `
  id
  ${
    catalogue
      ? `catalogue {
      ${Catalogue}
    }`
      : ""
  }
  catalogueId
  contactId
  ${
    nominal
      ? `nominal {
      ${Nominal}
    }`
      : ""
  }
  nominalId
  notes
  updatedAt
`;

export default ContactCatalogue;
