import { useQuery } from "@apollo/client";
import { GridInputs } from "../../../../../components/Functional";
import { FETCH_SHIPMENT_CATEGORIES } from "../../../../../graphql/query/ShipmentCategory";
import { useEffect } from "react";
import { FETCH_VIEWING_ROOM } from "../../../../../graphql/query/ViewingRoom";
import { FETCH_WAREHOUSE_SITES } from "../../../../../graphql/query/WarehouseSite";
import {
  FETCH_CONTINENTS,
  FETCH_COUNTRIES,
} from "../../../../../graphql/query/Country";

const Location = props => {
  const { formik } = props || {};
  const { data: { shipmentCategories = [] } = {} } = useQuery(
    FETCH_SHIPMENT_CATEGORIES,
  );
  const { data: { viewingRooms = [] } = {} } = useQuery(FETCH_VIEWING_ROOM);
  const { data: { warehouseSites = [] } = {} } = useQuery(
    FETCH_WAREHOUSE_SITES,
  );

  const contactName = formik.values?.deliveryContact?.name;
  const { data: { countries = [] } = {} } = useQuery(FETCH_COUNTRIES);
  const { data: { continents = [] } = {} } = useQuery(FETCH_CONTINENTS);

  useEffect(() => {
    if (formik.values?.deliveryContact?.id) {
      formik.setFieldValue(
        "_warehouseItem._warehouseMove._shipment.delContactId",
        formik.values?.deliveryContact?.id,
      );
    } else {
      formik.setFieldValue(
        "_warehouseItem._warehouseMove._shipment.delContactId",
        null,
      );
    }
  }, [formik.values?.deliveryContact?.id]);

  useEffect(() => {
    if (
      formik?.values?._warehouseItem?._warehouseMove?._shipment?.delPostcode ||
      formik?.values?._warehouseItem?._warehouseMove?._shipment
        ?._deliveryCountry?.name ||
      formik?.values?._warehouseItem?._warehouseMove?._shipment
        ?._deliveryCountry?.continent
    ) {
      formik?.setFieldValue("_warehouseItem.destroyed", {
        operator: "eq",
        value: 0,
      });
    } else {
      formik?.setFieldValue("_warehouseItem.destroyed", null);
    }
  }, [
    formik?.values?._warehouseItem?._warehouseMove?._shipment?.delPostcode,
    formik?.values?._warehouseItem?._warehouseMove?._shipment?._deliveryCountry
      ?.name,
    formik?.values?._warehouseItem?._warehouseMove?._shipment?._deliveryCountry
      ?.continent,
  ]);

  useEffect(() => {
    if (formik?.values?.checkExcludeUnlocatedShipments) {
      formik.setFieldValue("excludeUnlocatedShipments", {
        value: null,
        operator: "ne",
      });
    } else {
      formik.setFieldValue("excludeUnlocatedShipments", null);
    }
  }, [formik?.values?.checkExcludeUnlocatedShipments]);

  useEffect(() => {
    if (formik?.values?.checkSiteManaged) {
      formik.setFieldValue("siteManaged", {
        value: 0,
        operator: "gt",
      });
    } else {
      formik.setFieldValue("siteManaged", null);
    }
  }, [formik?.values?.checkSiteManaged]);

  const inputs = {
    className: "grid grid-cols-1 gap-4 mt-5 pb-5",
    inputs: [
      {
        className: "grid-cols-4 gap-16",
        inputs: [
          {
            data: contactName,
            label: "Contact",
            name: "shipmentDelContactId",
            type: "contactSearch",
          },
          {
            label: "Postcode",
            name: "shipmentDelPostcode",
            type: "text",
          },
          {
            label: "Country",
            name: "shipmentDelCountryId",
            type: "multi-select",
            isMulti: false,
            options: countries?.map(item => ({
              label: item.name,
              value: item.id,
            })),
          },
          {
            label: "Continent",
            name: "shipmentDelCountryContinent",
            type: "multi-select",
            options: continents?.map(item => ({
              label: item?.continent,
              value: item?.continent,
            })),
          },
        ],
      },
      {
        className: "grid-cols-4 gap-16",
        inputs: [
          {
            label: "Viewing Room",
            name: "viewingRoomId",
            type: "multi-select",
            isMulti: false,
            options: viewingRooms?.map(item => ({
              label: item.description,
              value: item.id,
            })),
          },
          {
            label: "Date",
            name: "shipmentDate",
            type: "date",
          },
          {
            label: "Category",
            name: "shipmentCategoryId",
            type: "multi-select",
            isMulti: false,
            options: shipmentCategories?.map(item => ({
              label: item.description,
              value: item.id,
            })),
          },
          {
            label: "Sites",
            name: "siteId",
            type: "multi-select",
            isMulti: false,
            options: warehouseSites?.map(item => ({
              label: item.name,
              value: item.id,
            })),
            postLabelInput: {
              designType: "checkbox",
              label: "Managed Site",
              name: "checkSiteManaged",
              type: "toggle",
            },
          },
        ],
      },
      {
        className: "grid-cols-4 gap-16",
        inputs: [
          {
            designType: "checkbox",
            label: "Exclude Unlocated Shipments",
            name: "checkExcludeUnlocatedShipments",
            type: "toggle",
          },
        ],
      },
    ],
  };

  const gridInputProps = {
    formik,
    ...inputs,
  };

  return (
    <div className="w-full">
      <GridInputs {...gridInputProps} />
    </div>
  );
};

export default Location;
