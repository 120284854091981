import { useQuery } from "@apollo/client";

import { GridInputs } from "../../../../../components/Functional";
import { FETCH_WAREHOUSE_SITES } from "../../../../../graphql/query/WarehouseSite";

const Addresses = props => {
  const { formik } = props || {};

  const { data: { warehouseSites = [] } = {} } = useQuery(
    FETCH_WAREHOUSE_SITES,
  );

  const inputs = {
    className: "grid grid-cols-4 gap-4",
    inputs: [
      { label: "Town", name: "_contactAddress.town", type: "text" },
      {
        label: "Site",
        name: "siteId",
        options: warehouseSites?.map(item => ({
          label: item?.name,
          value: item?.id,
        })),
        type: "multi-select",
      },
    ],
  };

  const gridInputProps = {
    formik,
    ...inputs,
  };

  return <GridInputs {...gridInputProps} />;
};

export default Addresses;
