import * as React from "react";

import { Table } from "../../../../../../components/Functional";
import { FETCH_FABRICATIONS_V2 as fetchFabricationsV2 } from "../../../../../../graphql/query/Fabrication";

import SectionHeader from "../../components/SectionHeader";

const InstanceFabrication = props => {
  const { instanceId } = props || {};
  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "fabricationsV2",
    headers: [
      { label: "Date", name: "date", type: "date" },
      { label: "Type", name: "type.description", type: "label" },
      { label: "Purchase Order", name: "po", type: "label" },
      { label: "Invoice", name: "invoice", type: "label" },
      {
        label: "Description",
        name: "description",
        type: "label",
      },
      { label: "Currency", name: "currency.code", type: "label" },
      { label: "Amount", name: "amount", type: "currency" },
      { label: "Recharge", name: "recharge", type: "currency" },
    ],
    FETCH_QUERY: fetchFabricationsV2({ currency: {}, type: {} }),
    variables: {
      _instance: {
        id: {
          operator: "eq",
          value: instanceId,
        },
      },
    },
    className: "sm:px-0 lg:px-0",
  };

  return (
    <div className="w-full">
      <SectionHeader title="Fabrication" />
      <div className="mb-6 w-full">
        <Table {...tableProps} />
      </div>
    </div>
  );
};

export default InstanceFabrication;
