import { useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Button, getInput } from "../../../../../../../components/ui";
import {
  CREATE_REQUEST as createRequestMutation,
  UPDATE_REQUEST as updateRequestMutation,
} from "../../../../../../../graphql/mutation/Request";
import { FETCH_REQUEST_TYPES } from "../../../../../../../graphql/query/RequestType";
import { FETCH_ARTISTS as fetchArtists } from "../../../../../../../graphql/query/Artist";
import { useEffect } from "react";
import { FETCH_ENTITIES } from "../../../../../../../graphql/query/Entity";

const AddRequest = props => {
  const { request, closeModal, contact, isOpen } = props;
  const { data: { requestTypes = {} } = {} } = useQuery(FETCH_REQUEST_TYPES, {
    variables: { input: {} },
  });
  const { data: { artists = [] } = {} } = useQuery(fetchArtists());
  const { data: { entities = {} } = {} } = useQuery(FETCH_ENTITIES);
  const { edges: requestTypeEdges = [] } = requestTypes;
  const { edges: entityEdges = [] } = entities;
  const [updateRequest, { loading: updateLoading }] = useMutation(
    updateRequestMutation(),
  );
  const [createRequest, { loading: createLoading }] = useMutation(
    createRequestMutation(),
    { refetchQueries: ["FetchRequests"] },
  );

  const loading = updateLoading || createLoading;

  const handleClick = () => {
    const {
      id,
      artistId,
      budgetFrom,
      budgetTo,
      description,
      date,
      dateClosed,
      notes,
      salesUserId,
      typeId,
    } = formik.values;
    (request
      ? updateRequest({
          variables: {
            input: {
              id,
              artistId,
              budgetFrom,
              budgetTo,
              date,
              dateClosed,
              description,
              notes,
              salesUserId,
              typeId,
            },
          },
        })
      : createRequest({
          variables: {
            input: {
              artistId,
              budgetFrom,
              budgetTo,
              contactId: contact?.id,
              date,
              dateClosed,
              description,
              notes,
              salesUserId,
              typeId,
            },
          },
        })
    ).then(resp => {
      const {
        data: {
          createRequest: { success } = {},
          updateRequest: { success: updateSuccess } = {},
        },
      } = resp || {};
      if (success || updateSuccess) {
        closeModal();
      }
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      artistId: request?.artist?.id,
      ...request,
    },
    onSubmit: handleClick,
    validationSchema: Yup.object({
      artistId: Yup.number().required("Required"),
      typeId: Yup.number().required("Required"),
    }),
  });

  useEffect(() => {
    formik?.resetForm();
  }, [isOpen]);

  const rightInputfields = [
    {
      label: "Type",
      name: "typeId",
      type: "multi-select",
      options: requestTypeEdges?.map(item => ({
        label: item.name,
        value: item.id,
      })),
    },
    {
      label: "Artist",
      name: "artistId",
      type: "multi-select",
      options: artists?.map(item => ({ label: item.name, value: item.id })),
    },
    ...(request
      ? [{ label: "Date Closed", name: "dateClosed", type: "date" }]
      : []),
    { label: "Budget Range Low - End", name: "budgetFrom", type: "number" },
    { label: "Budget Range High - End", name: "budgetTo", type: "number" },
  ];

  const leftInputFields = [
    { label: "Description", name: "description", type: "text" },
    {
      label: "Sales User",
      name: "salesUserId",
      type: "multi-select",
      options: entityEdges?.map(item => ({ label: item.name, value: item.id })),
    },
    {
      label: "Notes",
      name: "notes",
      type: "textarea",
      className: "row-span-2",
    },
  ];

  return (
    <div className="flex w-full flex-col p-8">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          {request ? "Update" : "Create"} Request Record for {contact?.name}
        </div>
        <div className="flex flex-row">
          <Button
            action="default"
            className="mr-8"
            label="Cancel"
            onClick={() => {
              closeModal();
            }}
          />
          <Button
            label={
              loading
                ? request
                  ? "Updating"
                  : "Creating"
                : request
                  ? "Update Record"
                  : "Create Record"
            }
            disabled={loading}
            onClick={formik?.submitForm}
          />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-x-10 pb-10 pt-20">
        <div className="grid grid-cols-1 gap-y-4">
          {rightInputfields?.map((item, index) => {
            const inputProps = {
              ...item,
              formik,
            };
            return (
              <div key={index} className={`w-full ${item?.className}`}>
                {getInput(inputProps)}
              </div>
            );
          })}
        </div>
        <div className="grid w-full grid-cols-1 grid-rows-5 gap-x-20 gap-y-4">
          {leftInputFields?.map((item, index) => {
            const inputProps = {
              ...item,
              formik,
            };
            return (
              <div key={index} className={`w-full ${item?.className}`}>
                {getInput(inputProps)}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AddRequest;
