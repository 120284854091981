import { useCallback } from "react";
import { useQuery } from "@apollo/client";
import { FETCH_USER as fetchUser } from "../graphql/query/User";

const useUser = props => {
  const { skip = false } = props || {};
  const { data, error, loading } = useQuery(fetchUser({ permissions: {} }), {
    skip,
  });

  const user = data?.user;
  const hasPermission = useCallback(
    (permissions = []) => {
      const checkPermissions = Array.isArray(permissions)
        ? permissions
        : (typeof permissions === "string" && [permissions]) || [];

      // bypass permission checker if there's no items to check
      if (!checkPermissions.length) return true;

      const userPermissions = user?.permissions || {};
      const flatUserPermissions = Object.values(userPermissions).reduce(
        (acc, item) => {
          const items = [];
          Object.keys(item).forEach(key => {
            if (item[key]) {
              items.push(key);
            }
          });
          return [...acc, ...items];
        },
        [],
      );
      let count = 0;
      checkPermissions?.some(permission => {
        if (flatUserPermissions.includes(permission)) {
          count += 1;
        }
      });
      if (count == checkPermissions?.length) {
        return true;
      } else {
        return false;
      }
    },
    [user],
  );

  return {
    error,
    hasPermission,
    loading,
    user,
  };
};

export default useUser;
