/* eslint-disable max-len */
const ServerIcon = props => (
  <svg
    width={37}
    height={37}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.8}
    stroke="currentColor"
    {...props}
  >
    <rect x="2" y="2" width="20" height="8" rx="2" ry="2" />
    <rect x="2" y="14" width="20" height="8" rx="2" ry="2" />
    <circle cx="6" cy="6" r="0.3" fill="#000" />
    <circle cx="6" cy="18" r="0.3" fill="#000" />
  </svg>
);

export default ServerIcon;
