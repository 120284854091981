import { useQuery } from "@apollo/client";
import { GridInputs } from "../../../../../components/Functional";
import { FETCH_INVOICE_ANALYSIS_EVENTS } from "../../../../../graphql/query/InvoiceAnalysisEvent";
import { FETCH_INVOICE_CHANNELS } from "../../../../../graphql/query/InvoiceChannel";
import { FETCH_INVOICE_DESTINATIONS } from "../../../../../graphql/query/InvoiceDestination";
import { FETCH_INVOICE_SAGECODES } from "../../../../../graphql/query/InvoiceSageCode";
import useUser from "../../../../../hooks/useUser";

const AdvisorSale = props => {
  const { formik } = props || {};
  const { data: { invoiceChannels = [] } = {} } = useQuery(
    FETCH_INVOICE_CHANNELS,
  );
  const { data: { invoiceAnalysisEvents = [] } = {} } = useQuery(
    FETCH_INVOICE_ANALYSIS_EVENTS,
  );
  const { data: { invoiceDestinations = [] } = {} } = useQuery(
    FETCH_INVOICE_DESTINATIONS,
  );
  const { data: { invoiceSageCodes = [] } = {} } = useQuery(
    FETCH_INVOICE_SAGECODES,
  );
  const { hasPermission } = useUser();

  const inputs = {
    className: "grid grid-cols-4 gap-16",
    inputs: [
      {
        className: "grid grid-rows-2 gap-4",
        inputs: [
          {
            // hasOperator: true,
            label: "Art Advisor",
            name: "artAdvisorId",
            type: "contactSearch",
            fetchType: "artAdvisor",
            disabled: !hasPermission("UPDATE_INVOICE"),
          },
          {
            hasOperator: true,
            label: "Channel",
            name: "channelId",
            type: "multi-select",
            options: invoiceChannels?.map(item => ({
              label: item.description,
              value: item.id,
            })),
            disabled: !hasPermission("UPDATE_INVOICE"),
          },
        ],
      },
      {
        className: "grid grid-rows-2 gap-4",
        inputs: [
          {
            label: "Analysis",
            name: "analysisId",
            options: invoiceSageCodes?.map(item => ({
              label: item.description,
              value: item.id,
            })),
            type: "multi-select",
            disabled: !hasPermission("UPDATE_INVOICE"),
          },
        ],
      },
      {
        className: "grid grid-rows-2 gap-4",
        inputs: [
          {
            label: "Event",
            name: "analysisEventId",
            type: "multi-select",
            options: invoiceAnalysisEvents?.map(item => ({
              label: item.description,
              value: item.id,
            })),
            disabled: !hasPermission("UPDATE_INVOICE"),
          },
        ],
      },
      {
        className: "grid grid-rows-2 gap-4",
        inputs: [
          {
            label: "Destination",
            name: "destinationId",
            type: "multi-select",
            options: invoiceDestinations?.map(item => ({
              label: item.description,
              value: item.id,
            })),
            disabled: !hasPermission("UPDATE_INVOICE"),
          },
        ],
      },
    ],
  };

  const gridInputProps = {
    formik,
    ...inputs,
  };

  return <GridInputs {...gridInputProps} />;
};

export default AdvisorSale;
