import React, { useState } from "react";
import { Routes as RouterRoutes, Route } from "react-router-dom";

import Auth from "./auth";

import Login from "../pages/Login";
import Unsubscribe from "../pages/Unsubscribe";
import AlertComponent from "../components/ui/Alert";
import LoggingSupport from "../components/ui/LoggingSupport";
import UnauthorizedError from "../components/Modals/UnauthorizedError";
import { Spinner } from "../components/ui";
import useUser from "../hooks/useUser";
import GraphQLErrorModal from "../components/Modals/GraphQLErrorModal";

const route = ({ isAuthorized }) => {
  return [
    ...[
      {
        path: "unsubscribe/:optOutKey",
        element: <Unsubscribe />,
      },
      ...(isAuthorized
        ? [
            {
              path: "*",
              element: <Auth />,
            },
          ]
        : [
            {
              path: "*",
              element: <Login />,
            },
          ]),
    ],
  ];
};

const Routes = props => {
  const { token, isAuthenticated } = props || {};
  const { user, error, loading } = useUser({
    skip: !(isAuthenticated && token),
  });
  const isAuthorized = !loading && !error && user?.isActive;

  const [notificationLogging, setNotificationLogging] = useState({
    text: "",
    isOpen: false,
    type: "",
  });

  const closeNotificationLogging = () => {
    setNotificationLogging({
      text: "",
      isOpen: false,
      type: "",
    });
  };

  const renderRoutes = route({ isAuthorized })?.map((item, index) => {
    return <Route key={index} {...item} />;
  });

  return (
    <>
      {(!user || loading) && isAuthenticated ? (
        <div className="absolute left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-gray-100 bg-opacity-75">
          <Spinner />
        </div>
      ) : (
        <>
          <RouterRoutes>{renderRoutes}</RouterRoutes>
          {error && (
            <AlertComponent
              text="Your account is awaiting authorization, Please contact support if the problem persists"
              type="info"
              isOpen={error}
            />
          )}
          <LoggingSupport onSubmit={setNotificationLogging} />
          {notificationLogging.isOpen && (
            <AlertComponent
              {...notificationLogging}
              onClose={closeNotificationLogging}
            />
          )}
          <UnauthorizedError />
          <GraphQLErrorModal />
        </>
      )}
    </>
  );
};

export default Routes;
