import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import { artworkFilterStore } from "../../components/useArtworkFilters";

import Actions from "./components/Actions";
import HeaderComponent from "../../components/HeaderComponent";
import searchedArtworks from "./components/SearchedArtworks";
import * as SelectListWorking from "./components/SelectListWorking";
import * as SelectListSaved from "./components/SelectListSaved";
import * as ImageSelectListWorking from "./components/ImageSelectListWorking";
import * as ImageSelectListSaved from "./components/ImageSelectListSaved";

import {
  Table,
  multiSelect,
  SelectList,
} from "../../../../components/Functional";
import { FETCH_LISTS as fetchLists } from "../../../../graphql/query/List";

const List = props => {
  const { formik } = props;
  const navigate = useNavigate();
  const { data: { lists: { edges = [] } = {} } = {} } = useQuery(fetchLists(), {
    variables: {
      // fetch instance_working list
      input: { typeId: 6 },
    },
  });
  const { data: { lists: { edges: imageListEdges = [] } = {} } = {} } =
    useQuery(fetchLists(), {
      variables: {
        // fetch instance_working list
        input: { typeId: 16 },
      },
    });
  const workingList = edges?.length > 0 ? edges[0] : null;
  const workingListId = workingList?.id;
  const imageWorkingListId =
    imageListEdges?.length > 0 ? imageListEdges[0]?.id : null;
  const queryParams = new URLSearchParams(window.location.search);
  const status = queryParams?.get("status") || "all";
  const listId = +queryParams.get("listId");
  const imageListId = +queryParams.get("imageListId");
  const ids = Object.keys(formik?.values?.selected?.ids || {})?.map(item =>
    parseInt(item.replace("_", "")),
  );
  const select = formik?.values?.selected?.select;

  useEffect(() => {
    if (workingListId) {
      queryParams.set("workingListId", workingListId);
      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      // eslint-disable-next-line
      history.pushState(null, null, newUrl);
    }
  }, [workingListId]);

  const multiSelectProps = {
    formik,
    label: "Artworks",
    name: "selected",
    keyName: "id",
  };

  const actionProps = {
    formik,
    ids,
    imageListId,
    imageWorkingListId,
    listId,
    onSuccess: () => formik?.resetForm(),
    search: artworkFilterStore.get(),
    workingListId,
  };

  const handleChange = value => {
    navigate(
      `/artworks?status=${value}${listId ? `&listId=${listId}` : ""}${workingListId ? `&workingListId=${workingListId}` : ""}${imageListId ? `&imageListId=${imageListId}` : ""}`,
    );
  };

  const GeneralTabActions = () => {
    const createListProps = {
      ...actionProps,
      onSuccess: () => (
        formik?.resetForm(),
        navigate(
          `/artworks?status=selectListWorking${listId ? `&listId=${listId}` : ""}${imageListId ? `&imageListId=${imageListId}` : ""}`,
        )
      ),
      typeId: 6,
    };

    const createImageListProps = {
      ...actionProps,
      label: "Add Images to Select List",
      onSuccess: () => (
        formik?.resetForm(),
        navigate(
          `/artworks?status=imageSelectListWorking${listId ? `&listId=${listId}` : ""}${imageListId ? `&imageListId=${imageListId}` : ""}`,
        )
      ),
      typeId: 16,
      isSearch: true,
      ...(select !== "all"
        ? { search: { id: { operator: "in", value: ids } } }
        : {}),
    };

    const createPrimaryImageListProps = {
      ...actionProps,
      label: "Add Primary Images to Select List",
      onSuccess: () => (
        formik?.resetForm(),
        navigate(
          `/artworks?status=imageSelectListWorking${listId ? `&listId=${listId}` : ""}${imageListId ? `&imageListId=${imageListId}` : ""}`,
        )
      ),
      typeId: 16,
      primary: true,
      isSearch: true,
      ...(select !== "all"
        ? { search: { id: { operator: "in", value: ids } } }
        : {}),
    };

    return (
      <>
        <SelectList.CreateList {...createPrimaryImageListProps} />
        <SelectList.CreateList {...createImageListProps} />
        <SelectList.CreateList {...createListProps} />
      </>
    );
  };

  const tabProps = {
    active: status,
    onChange: handleChange,
    tabs: [
      {
        actions: GeneralTabActions,
        label: "All Artworks",
        name: "all",
        tableProps: searchedArtworks(),
      },
      {
        actions: SelectListWorking.Actions,
        label: "Select List (Working)",
        name: "selectListWorking",
        tableProps: SelectListWorking.tableProps(actionProps),
      },
      {
        actions: SelectListSaved.Actions,
        label: "Select List (Saved)",
        name: "selectListSaved",
        tableProps: SelectListSaved.tableProps(actionProps),
      },
      {
        actions: ImageSelectListWorking.Actions,
        label: "Image Select List (Working)",
        name: "imageSelectListWorking",
        tableProps: ImageSelectListWorking.tableProps({
          imageListId,
          imageWorkingListId,
          formik,
        }),
      },
      {
        actions: ImageSelectListSaved.Actions,
        label: "Image Select List (Saved)",
        name: "imageSelectListSaved",
        tableProps: ImageSelectListSaved.tableProps({ formik }),
      },
    ],
  };
  const { tableProps: tabTableProps, actions: tabActions } =
    tabProps?.tabs?.find(tab => tab.name === status) || {};

  const fixHeadersName = ({ status, headers }) =>
    headers?.map(header => {
      return {
        ...header,
        name:
          header?.name && status === "selectListWorking"
            ? Array.isArray(header?.name)
              ? header?.name?.map(item => `item.${item}`)
              : `item.${header?.name}`
            : header?.name,
      };
    });

  const tableProps = {
    dataKey: "artworks",
    headers: fixHeadersName({
      status,
      headers: [
        multiSelect(multiSelectProps),
        { label: "Image", name: "imagesSummary.imgT", type: "image" },
        {
          label: "Artist",
          name: "artists",
          type: "custom",
          component: props => (
            <div
              className={`overflow-hidden text-ellipsis text-base font-medium leading-5 text-black`}
              style={{ maxWidth: 200 }}
            >
              {(
                (status === "selectListWorking"
                  ? props?.values?.item?.artists
                  : props?.values?.artists
                )?.map(item => item?.name) || []
              )?.join(", ")}
            </div>
          ),
        },
        { label: "Title", name: "title", type: "label" },
        { label: "Description", name: "description", type: "label" },
        { label: "Medium", name: "medium", type: "label" },
        { label: "Dimensions", name: "dimensions", type: "label" },
        { label: "", type: "custom", component: Actions },
      ],
    }),
    inputs: [],
    tabProps,
    variant: "dynamicHeight",
    ...tabTableProps,
  };

  return (
    <>
      <div className="px-8">
        <HeaderComponent
          leftText="All Artworks"
          rightComponent={() => {
            return (
              <div className="flex gap-4">{tabActions?.(actionProps)}</div>
            );
          }}
        />
      </div>
      <div className="mt-6 w-full">
        <Table {...tableProps} />
      </div>
    </>
  );
};

export default List;
