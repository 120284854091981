import { useQuery } from "@apollo/client";
import { GridInputs } from "../../../../../components/Functional";
import { FETCH_WAREHOUSE_SITES } from "../../../../../graphql/query/WarehouseSite";
import { FETCH_WAREHOUSE_LOCATIONS } from "../../../../../graphql/query/WarehouseLocation";
import { useEffect } from "react";

const Movements = props => {
  const { formik } = props || {};
  const { data: { warehouseSites = [] } = {} } = useQuery(
    FETCH_WAREHOUSE_SITES,
  );
  // Need to show all locations above 0 id
  const { data: { warehouseLocations = [] } = {} } = useQuery(
    FETCH_WAREHOUSE_LOCATIONS,
    {
      variables: {
        input: {
          siteId: {
            value: 0,
            operator: "gt",
          },
        },
      },
    },
  );

  // To disable location tab
  useEffect(() => {
    if (
      formik.values?._warehouseMove?.date?.value?.from ||
      formik.values?._warehouseMove?.date?.value?.to
    ) {
      formik.setFieldValue("_movementTabActive", true);
    }
  }, [
    formik.values?._warehouseMove?.date?.value?.from,
    formik.values?._warehouseMove?.date?.value?.to,
  ]);

  const inputs = {
    className: "grid grid-cols-4 gap-16",
    inputs: [
      {
        className: "flex flex-col gap-4",
        inputs: [
          {
            label: "Date - From",
            name: "_warehouseMove.date.value.from",
            type: "date",
            disabled: formik?.values?._locationTabActive,
          },
          {
            label: "Date - To",
            name: "_warehouseMove.date.value.to",
            type: "date",
            disabled: formik?.values?._locationTabActive,
          },
        ],
      },
      {
        className: "flex flex-col gap-4",
        inputs: [
          {
            label: "Site",
            disabled: formik?.values?._locationTabActive,
            name: "_warehouseMove._warehouseSite.id",
            type: "multi-select",
            options: warehouseSites?.map(item => ({
              label: item.name,
              value: item.id,
            })),
          },
        ],
      },
      {
        className: "flex flex-col gap-4",
        inputs: [
          {
            label: "Location",
            disabled: formik?.values?._locationTabActive,
            name: "_warehouseMove._warehouseLocation.id",
            type: "multi-select",
            options: warehouseLocations?.map(item => ({
              label: item.description,
              value: item.id,
            })),
          },
        ],
      },
    ],
  };

  const gridInputProps = {
    formik,
    ...inputs,
  };

  return <GridInputs {...gridInputProps} />;
};

export default Movements;
