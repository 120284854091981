import contactFrag from "./Contact";

const InstanceProvenance = ({ contact = false } = {}) => `
  id
  credit
  contactId
  ${
    contact
      ? `contact {
      ${contactFrag(contact)}
    }`
      : ""
  }
  day
  display
  instanceId
  itemId
  month
  notes
  owner
  updatedAt
  year
`;

export default InstanceProvenance;
