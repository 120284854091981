import { useEffect, useState } from "react";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";

import { loginRequest } from "../authConfig";

const requestAccessToken = async ({ instance, accounts, setToken }) => {
  const request = {
    ...loginRequest,
    account: accounts[0],
  };

  // eslint-disable-next-line
  const token = await new Promise((resolve, reject) => {
    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance
      .acquireTokenSilent(request)
      .then(response => {
        localStorage.setItem("user:token", response.accessToken);
        resolve(response.accessToken);
      })
      .catch(() => {
        instance.acquireTokenPopup(request).then(response => {
          localStorage.setItem("user:token", response.accessToken);
          resolve(response.accessToken);
        });
      });
  });
  setToken(token);
  return token;
};

const useMSAL = () => {
  const [token, setToken] = useState();
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();

  useEffect(() => {
    isAuthenticated && requestAccessToken({ instance, accounts, setToken });
  }, [isAuthenticated]);

  return {
    isAuthenticated,
    token,
    requestAccessToken: async () =>
      await requestAccessToken({ instance, accounts, setToken }),
  };
};

export default useMSAL;
