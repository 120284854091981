import BillingDetail from "./components/BillingDetail";
import SectionHeader from "../../components/SectionHeader";

const InvoiceInformation = props => {
  const { formik } = props;

  return (
    <div className="w-full">
      <SectionHeader title="Invoice Information" />
      <BillingDetail formik={formik} submitOnBlur={true} />
    </div>
  );
};

export default InvoiceInformation;
