import * as React from "react";
import { Table } from "../../../../../../components/Functional";
import { FETCH_EVENT_GUESTS as fetchEventGuests } from "../../../../../../graphql/query/EventGuest";
import Actions from "./components/Actions";
import SectionHeader from "../../components/SectionHeader";
import { FETCH_EVENT_RSVPS } from "../../../../../../graphql/query/EventRsvp";
import { useQuery } from "@apollo/client";

// // const GuestsList = (props) => {
// //   return props.guestsList.map((item, key) => <span key={key} className="text-base font-medium capitalize pr-1 underline">
// //     {item},
// //   </span>)
// }

const Events = props => {
  const { contactId } = props || {};
  const { data: { eventRsvps = [] } = {} } = useQuery(FETCH_EVENT_RSVPS);
  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "eventGuests",
    headers: [
      { label: "Date", name: "event.startDate", type: "date" },
      {
        label: "Event Name",
        name: "event.name",
        sortKey: "event",
        type: "label",
      },
      {
        label: "Guests",
        name: "guestName",
        sortKey: "guestname",
        type: "label",
      },
      {
        label: "RSVP",
        name: "rsvp.description",
        sortKey: "rsvpId",
        type: "label",
      },
      {
        label: "Attended",
        name: "attended",
        type: "custom",
        component: props => (
          <span className="text-xs font-medium text-black">
            {props?.values?.attended === -1 ? "Yes" : "No"}
          </span>
        ),
      },
      // {label: 'GUESTS', name: 'guestsList', type: 'custom', component: GuestsList},
      {
        label: "Invited By",
        name: "invitedBy",
        sortKey: "invitedBy",
        type: "label",
      },
      { label: "Notes", name: "notes", type: "label" },
      { label: "", name: "actions", type: "custom", component: Actions },
    ],
    inputs: [
      { label: "From Date", name: "from", type: "date", view: "partial" },
      { label: "To Date", name: "to", type: "date", view: "partial" },
      {
        label: "RSVP",
        name: "rsvpId",
        type: "select",
        options: eventRsvps?.map(item => ({
          label: item.description,
          value: item.id,
        })),
      },
      { label: "Note", name: "notes", type: "text", view: "full" },
      { label: "Invited By", name: "invitedBy", type: "text", view: "full" },
      { label: "Guests", name: "guestName", type: "text", view: "full" },
      {
        name: "attended",
        type: "checkbox",
        options: [{ label: "Attended", value: -1 }],
      },
    ],
    FETCH_QUERY: fetchEventGuests({ event: {}, rsvp: {} }),
    variables: {
      contactId,
    },
    className: "sm:px-0 lg:px-0",
  };

  return (
    <div className="w-full">
      <SectionHeader title="Events" />
      <div className="mb-6 w-full">
        <Table {...tableProps} />
      </div>
    </div>
  );
};

export default Events;
