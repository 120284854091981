import artworkFrag from "./Artwork";
import contactFrag from "./Contact";
import Currency from "./Currency";
import instance from "./Instance";
import OfferStatus from "./OfferStatus";

const Offer = ({
  artwork = false,
  contact = false,
  currency = false,
  hold = false,
  status = false,
} = {}) => `
  id
  amount
  ${
    artwork
      ? `artwork {
      ${artworkFrag(artwork)}
    }`
      : ""
  }
  ${
    contact
      ? `contact {
      ${contactFrag(contact)}
    }`
      : ""
  }
  contactId
  ${
    currency
      ? `currency {
      ${Currency}
    }`
      : ""
  }
  currencyId
  date
  dateClosed
  exchange
  ${
    hold
      ? `hold {
      ${instance(hold)}
    }`
      : ""
  }
  holdId
  holdDate
  notes
  priority
  rank
  ${
    status
      ? `status {
      ${OfferStatus}
    }`
      : ""
  }
  statusId
  tbiAmount
  tbiDate
  transparency
  user
  updatedAt
`;

export default Offer;
