import {
  ChartPieIcon,
  ClipboardIcon,
  DocumentTextIcon,
  EyeIcon,
  HomeIcon,
  PaperClipIcon,
} from "@heroicons/react/24/outline";

import ShipmentDetails from "./pages/ShipmentDetails";
import Items from "./pages/Items";
import ShipmentCosts from "./pages/Cost";
import Condition from "./pages/Condition";
import Documents from "./pages/Documents";
import Details from "./pages/Details";

const routes = [
  {
    href: "/shipments",
    icon: HomeIcon,
    name: "All Shipments",
  },
  {
    element: ShipmentDetails,
    href: "shipment-details",
    icon: DocumentTextIcon,
    name: "Shipment Details",
  },
  {
    element: Items,
    href: "items",
    icon: HomeIcon,
    name: "Shipment Items",
  },
  {
    element: Details,
    href: "details",
    icon: ClipboardIcon,
    name: "Details",
  },
  {
    element: Condition,
    href: "condition",
    icon: EyeIcon,
    name: "Condition",
  },
  {
    element: ShipmentCosts,
    href: "costs",
    icon: ChartPieIcon,
    name: "Costs",
  },
  {
    element: Documents,
    href: "documents",
    icon: PaperClipIcon,
    name: "Documents",
  },
];

export default routes;
