import { useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";

import { Button, getInput, Modal } from "../../../../components/ui";
import { HANDOVER_CONTACTS } from "../../../../graphql/mutation/Contact";
import { FETCH_ENTITIES } from "../../../../graphql/query/Entity";
import useUser from "../../../../hooks/useUser";

const HandoverBody = props => {
  const { closeModal, ids, select } = props || {};
  const [handoverContacts, { loading }] = useMutation(HANDOVER_CONTACTS, {
    refetchQueries: ["FetchCRMRegistrars", "FetchContactsV2"],
  });
  const { data: { entities: { edges = [] } = {} } = {} } =
    useQuery(FETCH_ENTITIES);

  const formik = useFormik({
    initialValues: {},
  });
  const { salesUserId } = formik?.values || {};

  const inputProps = {
    formik,
    name: "salesUserId",
    type: "multi-select",
    variant: "simple",
    bottomLabel: true,
    rightLabel: true,
    placeholder: "Select Primary Sales Contact",
    options: edges?.map(item => ({ label: item?.name, value: item?.id })),
  };

  const handleClick = () => {
    handoverContacts({
      variables: {
        input: {
          ids,
          handoverAll: select === "all",
          salesUserId,
        },
      },
    }).then(resp => {
      const { data: { handoverContacts: { success } = {} } = {} } = resp;
      if (success) {
        closeModal?.();
      }
    });
  };

  return (
    <div className="mt-6 flex w-full flex-col">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col">
          <div className="text-2xl font-bold">
            Handover Contacts: Select a Recipient
          </div>
          <div className="pt-4 text-base font-normal">
            Select a recipient to handover contacts to.
          </div>
        </div>
        <div className="flex flex-col">
          <div>
            <Button
              action="default"
              className="mr-4"
              label="Cancel"
              onClick={() => {
                closeModal();
              }}
            />
            <Button
              label={loading ? "Moving" : "Move"}
              disabled={loading || !salesUserId}
              onClick={handleClick}
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 pt-4">{getInput(inputProps)}</div>
    </div>
  );
};

const HandoverModal = props => {
  const { ids } = props || {};
  const { hasPermission } = useUser();

  const disabled = ids?.length == 0 || !hasPermission("HANDOVER_CONTACT");
  const modalProps = {
    body: HandoverBody,
    closeOnBackdrop: false,
    disabled,
    hideCloseButton: true,
    scale: "md",
    onClose: () => {
      // eslint-disable-next-line
      formik?.resetForm();
    },
    ...props,
  };

  return (
    <Modal {...modalProps}>
      <Button label="Handover" disabled={disabled} action="dark" />
    </Modal>
  );
};

export { HandoverBody, HandoverModal };
