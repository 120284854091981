import * as React from "react";

import SectionHeader from "../../components/SectionHeader";
import { Table } from "../../../../../../components/Functional";
import { FETCH_OFFERS as fetchOffers } from "../../../../../../graphql/query/Offer";
import { Avatar } from "../../../../../../components/ui";
import { Link } from "react-router-dom";

const clientName = props => {
  return (
    <>
      <div className="flex items-center gap-2">
        <Avatar
          size="lg"
          src={props?.values?.contact?.img}
          label={props?.values?.contact?.img || props?.values?.contact?.name}
        />
        <div>
          <Link
            className="underline"
            to={`/contacts/${props?.values?.contact?.id}/details`}
          >
            {props?.values?.contact?.name}
          </Link>
        </div>
      </div>
    </>
  );
};

const InstanceHolds = props => {
  const { instanceId } = props || {};
  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "offers",
    headers: [
      { label: "Hold Order", name: "rank", type: "label" },
      { label: "Hold User", name: "user", type: "label" },
      { label: "Hold Date", name: "holdDate", type: "date" },
      {
        label: "Contact",
        name: "contact.name",
        type: "custom",
        component: clientName,
      },
      { label: "Offer Amount", name: "amount", type: "label" },
      { label: "Currency", name: "currency.code", type: "label" },
      { label: "Offer Date", name: "date", type: "date" },
      { label: "Notes", name: "notes", type: "label" },
    ],
    FETCH_QUERY: fetchOffers({ contact: {}, currency: {} }),
    variables: {
      instanceId: +instanceId,
      statusId: 1,
    },
    className: "sm:px-0 lg:px-0",
  };

  return (
    <div className="w-full">
      <SectionHeader title="Holds" />
      <div className="mb-6 w-full">
        <Table {...tableProps} />
      </div>
    </div>
  );
};

export default InstanceHolds;
