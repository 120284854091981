import { useFormik } from "formik";
import { useEffect } from "react";
import { convertToFilter, makeObservable } from "../../../util";

// observable store
const instanceFilterStore = makeObservable({});
const useInstanceFilters = () => {
  const formik = useFormik({
    initialValues: {
      artistRef: { operator: "like" },
      limit: 50,
      offset: 0,
      statusId: { operator: "in" },
      tab: "consignments",
      sort1: { operator: "ASC" },
      sort2: { operator: "ASC" },
      sort3: { operator: "ASC" },
      _condition: { statusId: { operator: "in" } },
      _edition: {
        _artwork: {
          classId: { operator: "eq" },
          medium: { operator: "like" },
          series: { operator: "like" },
          title: { operator: "like" },
        },
        number: {
          operator: "eq",
        },
      },
    },
  });

  const filterKeys = ["tab"];

  const skipKeys = ["limit", "offset"];

  useEffect(() => {
    // convert formik values to filter and update store
    instanceFilterStore.set(
      convertToFilter({ values: formik.values, filterKeys, skipKeys }),
    );
  }, [formik.values]);

  return {
    formik,
  };
};

export { instanceFilterStore, useInstanceFilters };
