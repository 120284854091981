import { gql } from "@apollo/client";
import { TaxArea } from "../fragments";

const FETCH_TAX_AREAS = gql`
  query FetchTaxAreas {
    taxAreas {
      ${TaxArea}
    }
  }
`;

const FETCH_TAX_AREA_SAGE_CODES = gql`
  query FetchTaxAreaSageCodes {
    taxAreaSageCodes {
      ${TaxArea}
    }
  }
`;

export { FETCH_TAX_AREAS, FETCH_TAX_AREA_SAGE_CODES };
