import { useFormik } from "formik";
import { useMutation } from "@apollo/client";

import { FETCH_LISTS as fetchLists } from "../../../graphql/query/List";
import { UPDATE_LIST as updateListMutation } from "../../../graphql/mutation/List";
import { Button, getInput, Modal } from "../../ui";

const SaveList = props => {
  const { disabled, workingListId, onSuccess, typeId } = props || {};
  const formik = useFormik({
    initialValues: {
      name: "",
    },
  });

  const [updateList, { loading }] = useMutation(updateListMutation(), {
    refetchQueries: [fetchLists(), fetchLists()],
  });

  const handleSaveList = closeModal => async e => {
    e.preventDefault();

    const res = await updateList({
      variables: {
        input: {
          id: workingListId,
          name: formik?.values?.name,
          typeId,
        },
      },
    });
    if (res?.data?.updateList?.success) {
      onSuccess?.();
      closeModal?.();
    }
  };

  const inputProps = {
    formik,
    label: "Select List Name",
    name: "name",
    type: "text",
  };

  const modalProps = {
    title: "",
    scale: "md",
    hideCloseButton: true,
    closeOnBackdrop: false,
    disabled: disabled,
    body: ({ closeModal }) => {
      return (
        <div className={`p-4`}>
          <div className={`flex flex-row items-center justify-between`}>
            <div className={`text-lg`}>Save Select List</div>
            <div className={`flex gap-6`}>
              <Button
                className={`!w-28`}
                action="default"
                label="Cancel"
                onClick={closeModal}
              />
              <Button
                loading={loading}
                disabled={!formik?.values?.name}
                className={`flex !w-28 items-center`}
                label={loading ? "Saving" : "Save List"}
                onClick={handleSaveList(closeModal)}
              />
            </div>
          </div>
          <div className="mt-8 w-1/2 text-sm font-normal">
            {getInput(inputProps)}
          </div>
        </div>
      );
    },
  };

  return (
    <Modal {...modalProps}>
      <Button label="Save Select List" disabled={disabled} />
    </Modal>
  );
};

export default SaveList;
