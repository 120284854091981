import { gql } from "@apollo/client";
import { ShipmentIncoTerm } from "../fragments";

const FETCH_SHIPMENT_INCOTERMS = gql`
  query FetchShipmentIncoTerms {
    shipmentIncoTerms {
      ${ShipmentIncoTerm}
    }
  }
`;

export { FETCH_SHIPMENT_INCOTERMS };
