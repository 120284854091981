import { gql } from "@apollo/client";
import { WarehousePacking } from "../fragments";

const FETCH_WAREHOUSE_PACKINGS = gql`
  query FetchWarehousePackings {
    warehousePackings {
      ${WarehousePacking}
    }
  }
`;

export { FETCH_WAREHOUSE_PACKINGS };
