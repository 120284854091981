import { CreateList } from "../../Functional/SelectList";

const AddToSelectList = props => {
  const { currentSlide, images } = props || {};

  const { archiveId, artworkId } = images[currentSlide];
  const id = `${archiveId},${artworkId}`;

  const createProps = {
    label: "Add to Image Select List",
    typeId: 8,
    ids: [id],
  };

  return <CreateList {...createProps} />;
};

export default AddToSelectList;
