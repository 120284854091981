import { useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import { filterObj, useDebounce } from "../../../util";
import {
  // FilterBar,
  Pagination,
  Tabs,
} from "../../ui";
import TableUI from "../../Table";

const Table = props => {
  const {
    className = "",
    dataKey,
    FETCH_QUERY,
    footerComponent,
    headers = [],
    // inputs = [],
    isAdmin,
    limit = 50,
    pagination = {},
    // showSearchFilter = true,
    skip,
    tabProps,
    pollInterval,
    REFETCH_QUERIES = [],
    UPDATE_MUTATION,
    variables = {},
    variant = "modal",
    fetchPolicy = null,
  } = props || {};

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...(isAdmin ? { isAdmin } : {}),
      limit,
      offset: 0,
      ...variables,
    },
  });

  const debouncedObj = useDebounce(
    { values: formik?.values, FETCH_QUERY },
    500,
  );
  // don't include null or empty values in query

  const values = filterObj(
    debouncedObj?.values,
    value => value !== undefined && value !== null && value !== "",
  );

  const { previousData, data, loading } = useQuery(debouncedObj?.FETCH_QUERY, {
    skip: skip?.(values),
    variables: { input: { ...values } },
    fetchPolicy,
    pollInterval,
  });

  let updateMutation = null;
  const refetchQueries = Array.isArray(REFETCH_QUERIES)
    ? REFETCH_QUERIES
    : [REFETCH_QUERIES];
  if (UPDATE_MUTATION) {
    // eslint-disable-next-line
    const [mutation] = useMutation(UPDATE_MUTATION, { refetchQueries });
    updateMutation = mutation;
  }

  const obj = data?.[dataKey] || previousData?.[dataKey] || {};
  const { count, edges = [] } = obj || {};
  const _edges = edges?.map(x => ({
    ...x,
    rawFields: x,
    ...(pollInterval > 0
      ? {
          isnewData:
            new Date(x?.updatedAt) > new Date(new Date() - 14 * pollInterval),
        }
      : { isnewData: false }),
  }));

  const difference = obj?.edges?.filter(
    x => new Date(x?.updatedAt) > new Date(new Date() - 14 * pollInterval),
  );

  const tableProps = {
    formik,
    headers,
    limit: formik?.values?.limit || limit,
    loading,
    rows: _edges,
    updateMutation,
    variant,
  };

  const onChange = values => {
    formik?.setValues({ ...formik?.values, ...values });
  };

  const paginationProps = {
    count,
    limit: formik?.values?.limit || limit,
    offset: formik?.values?.offset,
    onChange,
    ...pagination,
  };

  // const filterBarProps = {
  //   inputs,
  //   formik,
  // };

  return (
    <div
      className={`mx-auto w-full flex-grow py-5 sm:px-6 lg:px-8 ${className}`}
    >
      <header>
        <div className="flex flex-grow items-center justify-between">
          {tabProps && <Tabs {...tabProps} />}
          <div className="flex flex-grow items-center justify-end">
            {/* showSearchFilter && <FilterBar {...filterBarProps} /> */}
          </div>
        </div>
      </header>
      {difference?.length > 0 && pollInterval > 0 && (
        <div className="flex flex-grow items-center">
          <div className="flex flex-grow items-center">
            <span className="text-sm text-black">
              {`${difference?.length} new data have arrived`}
            </span>
          </div>
        </div>
      )}
      <main>
        <div className="flex-col p-4 sm:px-0">
          <TableUI {...tableProps} />
          {!loading && footerComponent && footerComponent(data?.[dataKey])}
          <div className="pt-8">
            {!loading && <Pagination {...paginationProps} />}
          </div>
        </div>
      </main>
    </div>
  );
};

export { Table };
