import { useMutation } from "@apollo/client";
import React from "react";
import { useFormik } from "formik";

import { Tabs } from "../../../../../components/Functional";
import { Button } from "../../../../../components/ui";
import { UPDATE_CRM_INFO } from "../../../../../graphql/mutation/Contact";

import ConfirmHandover from "./components/ConfirmHandover";
import FocusTags from "./components/FocusTags";
import Handover from "./components/Handover";
import MaterialSent from "./components/MaterialSent";
import MailingLists from "./components/MailingLists";

const UpdateCRM = props => {
  const { closeModal, ids } = props || {};
  const formik = useFormik({
    initialValues: {
      tab: "materialSent",
    },
  });
  const [updateCRMInfo, { loading }] = useMutation(UPDATE_CRM_INFO);

  const searchButtonProps = {
    action: "black",
    label: loading ? "Saving" : "OK",
    disabled: false,
    onClick: () => {
      updateCRMInfo({
        variables: {
          input: {
            crmRegistrar: {
              notes: formik?.values?.CRMRegistrar?.notes,
              salesUserId: formik?.values?.CRMRegistrar?.salesUserId,
              statusId: formik?.values?.CRMRegistrar?.statusId,
              userId: formik?.values?.CRMRegistrar?.userId,
            },
            focusIds: formik?.values?.focusId?.map(item =>
              parseInt(item?.value, 10),
            ),
            ids,
            mailingListIds: formik?.values?.mailingListId?.map(item =>
              parseInt(item?.value, 10),
            ),
            notes: formik?.values?.notes,
          },
        },
      }).then(resp => {
        const { data: { updateCRMInfo: { success } = {} } = {} } = resp || {};
        if (success) {
          formik?.resetForm();
          closeModal();
        }
      });
    },
  };

  const cancelButtonProps = {
    action: "default",
    label: "Cancel",
    onClick: () => {
      formik?.resetForm();
      closeModal();
    },
  };

  const tabComponentProps = {
    formik,
  };

  const tabsProps = {
    formik,
    name: "tab",
    options: [
      {
        label: "Material Sent",
        value: "materialSent",
        component: <MaterialSent {...tabComponentProps} />,
      },
      {
        label: "Mailing Lists",
        value: "mailingLists",
        component: <MailingLists {...tabComponentProps} />,
      },
      {
        label: "Focus / Tags",
        value: "emailSent",
        component: <FocusTags {...tabComponentProps} />,
      },
      {
        label: "Handover",
        value: "handover",
        component: <Handover {...tabComponentProps} />,
      },
      {
        label: "Confirm Handover",
        value: "confirmHandover",
        component: <ConfirmHandover {...tabComponentProps} />,
      },
    ],
  };

  return (
    <div className="flex min-h-[340px] flex-col">
      <div className="flex items-center justify-between">
        <div className="text-xl font-bold">Bulk Update Selected Contacts</div>
        <div className="flex gap-4">
          <Button {...cancelButtonProps} />
          <Button {...searchButtonProps} />
        </div>
      </div>
      <div className="mt-16 flex flex-col gap-8">
        <Tabs {...tabsProps} />
      </div>
    </div>
  );
};

export default UpdateCRM;
