import { Table } from "../../../../../../components/Functional";
import { FETCH_INSTANCE_AUCTIONS } from "../../../../../../graphql/query/InstanceAuctions";
import Actions from "./components/Actions";
import SectionHeader from "../../components/SectionHeader";
import { Button, Modal } from "../../../../../../components/ui";
import * as React from "react";
import AddAuction from "./components/AddAuction";

const Auctions = props => {
  const { contact, contactId } = props || {};

  const modalProps = {
    body: AddAuction,
    closeOnBackdrop: true,
    contact,
    hideCloseButton: true,
    scale: "md",
  };

  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "instanceAuctions",
    headers: [
      { label: "Sales Date", name: "saleDate", type: "date" },
      { label: "Auctioneer", name: "auctioneer", type: "label" },
      { label: "Event", name: "event", type: "label" },
      { label: "Sale Ref", name: "saleRef", type: "label" },
      { label: "City", name: "city", type: "label" },
      { label: "Lot", name: "lot", type: "label" },
      { label: "Bid", name: "hammer", type: "label" },
      { label: "Notes", name: "notes", type: "label" },
      { label: "", name: "action", type: "custom", component: Actions },
    ],
    inputs: [],
    FETCH_QUERY: FETCH_INSTANCE_AUCTIONS,
    className: "sm:px-0 lg:px-0",
    variables: {
      contactId,
    },
  };

  const actions = [
    {
      component: () => {
        return (
          <Modal {...modalProps}>
            <Button label="Add Auction" action="black" />
          </Modal>
        );
      },
    },
  ];

  return (
    <div className="w-full">
      <SectionHeader title="Auctions" actions={actions} />
      <div className="mb-6 w-full">
        <Table {...tableProps} />
      </div>
    </div>
  );
};

export default Auctions;
