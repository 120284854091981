import artistFrag from "./Artist";

const ContactArtist = ({ artist = false } = {}) => `
  id
  ${
    artist
      ? `artist {
      ${artistFrag(artist)}
    }`
      : ""
  }
  artistId
  contactId
  updatedAt
`;

export default ContactArtist;
