import DetailForm from "./components/DetailForm";
import SectionHeader from "../../components/SectionHeader";

const ConsignmentDetails = props => {
  const { consignment, formik } = props;

  return (
    <div className="w-full">
      <SectionHeader title={`Consignment #${consignment?.id}`} />
      <div className="flex flex-col space-x-20 pb-[50px] pl-[50px] md:flex-row">
        <div className="w-full max-w-[1900px]">
          <div className=" flex flex-col space-x-10 md:flex-row">
            <DetailForm
              formik={formik}
              consignment={consignment}
              submitOnBlur={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsignmentDetails;
