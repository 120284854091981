import { gql } from "@apollo/client";
import { RequestType } from "../fragments";

const FETCH_REQUEST_TYPES = gql`
  query FetchRequestTypes($input: RequestTypeFilterInput!) {
    requestTypes(input: $input) {
      count
      edges {
        ${RequestType}
      }
    }
  }
`;

export { FETCH_REQUEST_TYPES };
