import { useQuery } from "@apollo/client";

import { Table } from "../../../../../../components/Functional";
import SectionHeader from "../../components/SectionHeader";
import { FETCH_FABRICATIONS_V2 as fetchFabricationsV2 } from "../../../../../../graphql/query/Fabrication";

import Actions from "./components/Actions";
import AddCost from "./components/AddCost";

import { FETCH_CURRENCIES } from "../../../../../../graphql/query/Currency";
import { UPDATE_FABRICATION as updateFabricationMutation } from "../../../../../../graphql/mutation/Fabrication";

const CostTotal = props => {
  const { costTotal = 0.0 } = props || {};
  return (
    <div className="border-b pb-4 pl-[1180px] pt-4 font-bold">
      Total:{" "}
      {Number(costTotal).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}
    </div>
  );
};

const ShipmentCosts = props => {
  const { shipmentId } = props || {};
  const { data: { currencies = [] } = {} } = useQuery(FETCH_CURRENCIES);

  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "fabricationsV2",
    headers: [
      { label: "Date", name: "date", type: "date", inputType: "date" },
      {
        label: "Order #",
        name: "po",
        type: "input",
        inputType: "text",
        rowClass: "w-[160px]",
      },
      {
        label: "Invoice #",
        name: "invoice",
        type: "input",
        inputType: "text",
        rowClass: "w-[160px]",
      },
      {
        label: "Description",
        name: "description",
        type: "input",
        inputType: "textarea",
      },
      {
        type: "input",
        label: "Currency",
        name: "currencyId",
        options: currencies?.map(item => ({
          value: item?.id,
          label: item?.code,
        })),
        inputType: "multi-select",
      },
      {
        label: "Net Amount",
        name: "amount",
        type: "input",
        inputType: "number-currency",
        allowNegativeInput: false,
        rowClass: "w-[160px]",
      },
      {
        label: "Tax",
        name: "tax",
        type: "input",
        inputType: "number-currency",
        allowNegativeInput: false,
        rowClass: "w-[160px]",
      },
      {
        label: "Allocation",
        name: "instanceId",
        type: "input",
        inputType: "instanceSearch",
        filterInput: [
          { "_warehouseItem._warehouseMove._shipment.id": +shipmentId },
        ],
      },
      {
        label: "",
        name: "actions",
        type: "custom",
        component: Actions,
      },
    ],
    inputs: [{ label: "Name", name: "name", type: "text", view: "partial" }],
    UPDATE_MUTATION: updateFabricationMutation(),
    FETCH_QUERY: fetchFabricationsV2(),
    footerComponent: CostTotal,
    variables: {
      shipmentId: {
        value: +shipmentId,
        operator: "eq",
      },
    },
    className: "sm:px-0 lg:px-0",
  };

  const actions = [
    {
      component: () => <AddCost shipmentId={shipmentId} />,
    },
  ];

  return (
    <div className="w-full">
      <SectionHeader title="Costs" actions={actions} />
      <div className="mb-6 w-full">
        <Table {...tableProps} />
      </div>
    </div>
  );
};

export default ShipmentCosts;
