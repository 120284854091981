import * as Yup from "yup";
import { useFormik } from "formik";
import { useMutation, useQuery } from "@apollo/client";

import { Button, getInput } from "../../../../components/ui";
import { FETCH_JOB_TYPES } from "../../../../graphql/query/JobType";
import { FETCH_ENTITIES } from "../../../../graphql/query/Entity";
import { CREATE_CONSIGNMENT_JOB_REQUEST } from "../../../../graphql/mutation/Consignment";
import { FETCH_JOB_REQUESTS_V2 } from "../../../../graphql/query/Job";
import useUser from "../../../../hooks/useUser";

const CreateJob = props => {
  const { user } = useUser();
  const { closeModal, consignmentId } = props || {};
  const { data: { jobTypes = [] } = {} } = useQuery(FETCH_JOB_TYPES);
  const [createConsignmentJob, { loading }] = useMutation(
    CREATE_CONSIGNMENT_JOB_REQUEST,
    {
      refetchQueries: [
        FETCH_JOB_REQUESTS_V2({
          jobStatus: {},
          jobType: {},
          requestUser: {},
          user: {},
          variables: {
            consignmentId: {
              value: consignmentId,
              operator: "eq",
            },
          },
        }),
      ],
    },
  );
  const { data: { entities: pickupGroups } = {} } = useQuery(FETCH_ENTITIES, {
    variables: { input: { hide: 0, user: 0, order: ["name"] } },
  });

  const formik = useFormik({
    initialValues: { statusId: 1, date: new Date(), consignmentId },
    validationSchema: Yup.object({
      typeId: Yup.string().trim().required("You must select type for new job."),
      description: Yup.string()
        .trim()
        .required("You must enter description for new job."),
      dueDate: Yup.date()
        .required("You must select due date for new job.")
        .test(
          "validation",
          `Due-Date must be greater than or equal to tomorrow's date`,
          function (value) {
            return new Date(value) > new Date();
          },
        ),
      duetime: Yup.string()
        .required("Due-Time must be greater than or equal to 10 AM GMT")
        .test(
          "validation",
          `Due-Time must be greater than or equal to 10 AM GMT`,
          function (value) {
            const splitTime = value.split(":");
            return Number(splitTime[0]) >= 10;
          },
        ),
      pickupId: Yup.number()
        .typeError("You must enter pickup for new job.")
        .required("You must enter pickup for new job."),
    }),
    onSubmit: async (values, { setErrors, resetForm }) => {
      const error = {};
      const updatedDueTime = new Date(`${values.dueDate} ${values.duetime}`);

      if (!(values?.duetime.split(":")[0] >= 10)) {
        error.duetime = `Due-Time must be greater than or equal to 10 AM GMT`;
      }

      if (Object.keys(error).length <= 0) {
        createConsignmentJob({
          variables: {
            input: {
              ...values,
              requestUserId: user?.entityId,
              duetime: updatedDueTime,
            },
          },
        }).then(resp => {
          const {
            data: { createConsignmentJob: { success } = {} },
          } = resp || {};
          if (success) {
            formik.resetForm();
            closeModal();
          }
        });
      } else {
        setErrors(error);
      }
    },
  });

  const inputs = [
    {
      label: "Type",
      name: "typeId",
      type: "select",
      placeholder: "Select a type",
      options: jobTypes?.map(item => ({
        label: item.description,
        value: item.id,
      })),
    },
    { label: "Description", name: "description", type: "text" },
    { label: "Due Date", name: "dueDate", type: "date" },
    {
      label: "Due Time",
      name: "duetime",
      type: "time",
      disabled: !formik.getFieldProps("dueDate").value,
    },
    {
      label: "Pickup Group",
      name: "pickupId",
      type: "multi-select",
      options:
        pickupGroups?.edges?.map(item => ({
          label: item?.name,
          value: item?.id,
        })) || [],
    },
  ];

  const hasErrors = Object.keys(formik?.errors || {}).length > 0;

  return (
    <div className="flex flex-col w-full gap-4">
      <div className="flex flex-row">
        <div className="flex flex-col flex-1 text-2xl font-bold">
          <div>Create Job for all consignment works</div>
        </div>
        <div className="flex gap-4">
          <Button
            action="default"
            label="Cancel"
            onClick={() => {
              closeModal();
              formik.resetForm();
            }}
          />
          <Button
            label={"Create"}
            loading={loading}
            disabled={loading || hasErrors}
            onClick={formik.submitForm}
          />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4 w-50">
        {inputs?.map((item, index) => {
          const inputProps = {
            ...item,
            formik,
          };
          return <div key={index}>{getInput(inputProps)}</div>;
        })}
      </div>
    </div>
  );
};

export default CreateJob;
