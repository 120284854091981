import React from "react";
import { useFormik } from "formik";
import { Link, useParams } from "react-router-dom";
import SectionHeader from "../../components/SectionHeader";
import { GridInputs, Table } from "../../../../../../components/Functional";
import { FETCH_WAREHOUSE_ITEMS_V2 as fetchWarehouseItemsV2 } from "../../../../../../graphql/query/WarehouseItem";

const Label = props => {
  const { label, widthInPx = 200 } = props || {};
  return (
    <div
      title={label}
      className={`overflow-hidden text-ellipsis text-base font-medium leading-5 text-black`}
      style={{ maxWidth: `${widthInPx}px` }}
    >
      {label}
    </div>
  );
};

const disableConfig = {
  disabled: true,
  inputClassName: "!bg-white !text-gray-400 !border-gray-400",
};

const InstanceWarehouse = props => {
  const { instance } = props || {};
  const { id: instanceId } = useParams();

  const formik = useFormik({
    initialValues: instance,
    enableReinitialize: true,
  });

  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "warehouseItemsV2",
    headers: [
      {
        label: "Storage #",
        name: "id",
        type: "custom",
        component: ({ values }) => (
          <Link className="underline" to={`/warehouse/${values?.id}/details`}>
            {values?.id}
          </Link>
        ),
      },
      { label: "Group", name: "mgroup", type: "label" },
      { label: "Category", name: "category.description", type: "label" },
      { label: "Description", name: "description", type: "label" },
      { label: "Unpacked Weight (KG)", name: "weight", type: "label" },
      {
        label: "Dimensions",
        type: "custom",
        component: ({ values }) => (
          <Label
            label={`${values.dimh ?? "00"}H ${values.dimw ?? "00"}W ${
              values.dimd ?? "00"
            }L`}
          />
        ),
      },
      { label: "Container", name: "container", type: "label" },
      { label: "Site", type: "label", name: "itemSite.site.name" },
      {
        label: "Location",
        type: "label",
        name: "itemSite.location.description",
      },
    ],
    inputs: [],
    FETCH_QUERY: fetchWarehouseItemsV2({
      category: true,
      itemSite: {
        site: true,
        location: true,
      },
      warehouseMoves: true,
    }),
    variables: {
      _instance: {
        id: {
          operator: "eq",
          value: instanceId,
        },
      },
    },
    className: "sm:px-0 lg:px-0",
  };

  const inputs = {
    inputs: [
      {
        className: "grid grid-cols-4 grid-flow-col gap-16 items-end",
        inputs: [
          {
            label: "Unpacked Weight",
            name: "weight",
            type: "text",
            ...disableConfig,
          },
          {
            label: "Total Weight",
            name: "totalWeight",
            type: "text",
            ...disableConfig,
          },
        ],
      },
    ],
  };

  const detailInputProps = {
    formik,
    ...inputs,
  };

  return (
    <div className="w-full">
      <SectionHeader title="Warehouse" />
      <div className="w-full border-b px-16 py-8">
        <GridInputs {...detailInputProps} />
      </div>
      <div className="flex w-full">
        <div className="flex-1">
          <Table {...tableProps} />
        </div>
      </div>
    </div>
  );
};

export default InstanceWarehouse;
