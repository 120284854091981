import { useMutation } from "@apollo/client";

import { Button } from "../../../../../../../components/ui";
import { DELETE_EDITION } from "../../../../../../../graphql/mutation/Edition";

const DeleteEdition = props => {
  const { editionId, closeModal } = props || {};
  const [deleteEdition, { loading, error: deleteEditionError, reset }] =
    useMutation(DELETE_EDITION, {
      refetchQueries: ["FetchArtwork"],
    });

  const handleDelete = () => {
    deleteEdition({
      variables: {
        input: { id: editionId },
      },
    })
      .then(response => {
        closeModal?.();
      })
      .catch(error => {});
  };

  if (deleteEditionError) {
    return (
      <div className="flex flex-col">
        <div className="flex flex-row">
          <div className="flex flex-1 flex-col text-2xl font-medium">
            Delete Edition
          </div>
          <div className="flex">
            <Button
              className="mr-3"
              label={"Cancel"}
              action={"default"}
              onClick={() => {
                closeModal?.();
                reset();
              }}
            />
          </div>
        </div>
        <div className="mt-4">
          <div className="text-sm text-gray-500">
            {deleteEditionError?.message}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-medium">
          Delete Edition
        </div>
        <div className="flex items-end justify-end">
          <Button
            className="mr-3"
            label={"Cancel"}
            action={"default"}
            onClick={() => {
              closeModal?.();
            }}
          />
          <Button
            label={loading ? "Deleting" : "Delete"}
            disabled={loading}
            onClick={handleDelete}
          />
        </div>
      </div>
      <div className="mt-4">
        <div className="text-sm text-gray-500">
          Are you sure you want to delete this edition?
        </div>
      </div>
    </div>
  );
};

export default DeleteEdition;
