import { useQuery } from "@apollo/client";
import { GridInputs } from "../../../../../components/Functional";
import { FETCH_STATUS } from "../../../../../graphql/query/Status";
import { FETCH_COMPANIES as fetchCompanies } from "../../../../../graphql/query/Company";
import { FETCH_ARTISTS as fetchArtists } from "../../../../../graphql/query/Artist";
import { useEffect } from "react";

const Instance = props => {
  const { formik } = props || {};
  const { data: { status = [] } = {} } = useQuery(FETCH_STATUS);
  const { data: { companies = {} } = {} } = useQuery(fetchCompanies());
  const { data: { artists = [] } = {} } = useQuery(fetchArtists());

  useEffect(() => {
    if (formik?.values?.noInstance) {
      formik.setFieldValue("instanceId", { value: null, operator: "eq" });
    }
  }, [formik?.values?.noInstance]);

  const inputs = {
    className: "grid grid-cols-4 gap-16",
    inputs: [
      {
        className: "flex flex-col gap-4",
        inputs: [
          {
            label: "Owner",
            name: "_instance.companyId",
            type: "multi-select",
            options: companies?.edges?.map(item => ({
              label: item.name,
              value: item.id,
            })),
          },
          {
            label: "Instance Ref",
            name: "_instance.ref",
            type: "text",
          },
          {
            label: "No Instance",
            type: "toggle",
            name: "noInstance",
            designType: "threeStateCheckBox",
          },
        ],
      },
      {
        className: "flex flex-col gap-4",
        inputs: [
          {
            label: "Title",
            name: "_instance._edition._artwork.title",
            type: "textarea",
          },
          {
            label: "Artist",
            name: "_instance._edition._artwork._artist.id",
            type: "multi-select",
            options: artists?.map(item => ({
              label: item.name,
              value: item.id,
            })),
          },
        ],
      },
      {
        className: "flex flex-col gap-4",
        inputs: [
          {
            label: "Instance Status",
            name: "_instance.statusId",
            type: "multi-select",
            options: status?.map(item => ({
              label: item.name,
              value: item.id,
            })),
          },
        ],
      },
      {
        className: "flex flex-col gap-4",
        inputs: [
          {
            label: "Notes",
            name: "_instance.notes",
            type: "textarea",
          },
        ],
      },
    ],
  };

  const gridInputProps = {
    formik,
    ...inputs,
  };

  return <GridInputs {...gridInputProps} />;
};

export default Instance;
