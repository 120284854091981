import { gql } from "@apollo/client";
import { ReservationInstance as reservationInstance } from "../fragments";

const RELEASE_RESERVATION_INSTANCE = props => gql`
  mutation ReleaseReservationInstance($input: ReleaseReservationInstanceInput!) {
    releaseReservationInstance(input: $input) {
      success
      error
      reservationInstance {
        ${reservationInstance(props)}
      }
    }
  }
`;

export { RELEASE_RESERVATION_INSTANCE };
