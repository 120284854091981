import { useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";

import Header from "../../components/HeaderComponent";
import PermissionsView from "../../components/PermissionsView";
import { Button } from "../../../../components/ui";
import { getChangedFieldsV2 } from "../../../../util";

import { UPDATE_USER as updateUserMutation } from "../../../../graphql/mutation/User";
import { FETCH_USER as fetchUser } from "../../../../graphql/query/User";

const RightComponent = props => {
  const { id, permissions, dirty } = props || {};
  const navigate = useNavigate();

  const [updateUser, { loading }] = useMutation(
    updateUserMutation({
      deltaPermissions: {},
      permissions: {},
      permissionSet: {},
    }),
  );
  const redirectRoute = `/admin/users/${id}/edit`;

  const handleClick = () => {
    updateUser({ variables: { input: { id: +id, permissions } } }).then(
      resp => {
        const {
          data: { updateUser: { success } = {} },
        } = resp || {};
        success && navigate(redirectRoute);
      },
    );
  };

  return (
    <div className="flex gap-4">
      <Button
        label={"Back"}
        action="default"
        onClick={() => navigate(redirectRoute)}
      />
      <Button
        label={"Save"}
        disabled={loading || !dirty}
        action={"dark"}
        onClick={handleClick}
      />
    </div>
  );
};

const EditPermission = () => {
  const { id } = useParams();
  const { data: { user } = {} } = useQuery(
    fetchUser({
      deltaPermissions: {},
      permissions: {},
      permissionsSet: {},
      roles: {},
    }),
    { variables: { id: +id } },
  );
  const {
    deltaPermissions,
    permissions: initialValues = {},
    permissionSet = {},
    roles = [],
  } = user || {};
  const formik = useFormik({
    initialValues: { permissions: initialValues },
    enableReinitialize: true,
  });

  const onChange = ({ permissions }) => {
    formik?.setValues({ permissions });
  };

  const permissionViewProps = {
    initialValues,
    isDelta: true,
    onChange,
    permissionSet,
    roles: [...(roles || []), { name: "Delta", permissions: deltaPermissions }],
  };

  const rightComponentProps = {
    id,
    permissions: formik?.values?.permissions,
    dirty:
      Object.keys(
        getChangedFieldsV2(formik?.values, { permissions: initialValues }),
      )?.length > 0,
  };

  return (
    <>
      <Header
        leftText={`Add Permissions to User ${user?.meta?.name || user?.email}`}
        rightComponent={() => <RightComponent {...rightComponentProps} />}
        subtitle=""
      />
      <div className="grid grid-flow-row">
        <div className="flex flex-col">
          <PermissionsView {...permissionViewProps} />
        </div>
      </div>
    </>
  );
};

export default EditPermission;
