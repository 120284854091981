/* eslint-disable new-cap */
import { gql } from "@apollo/client";
import { Email as email } from "../fragments";

const FETCH_EMAIL = props => gql`
  query FetchEmail($id: Int) {
    email(id: $id) {
      ${email(props)}
    }
  }
`;

const FETCH_EMAILS = props => gql`
  query FetchEmails($input: EmailFilterInput!) {
    emails(input: $input) {
      count
      edges {
        ${email(props)}
      }
    }
  }
`;

export { FETCH_EMAIL, FETCH_EMAILS };
