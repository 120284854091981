import * as React from "react";
import { Navigate } from "react-router-dom";
import useUser from "../../hooks/useUser";

const AuthRoute = ({ children, permissions }) => {
  const { hasPermission } = useUser();

  // TODO: remove once we can grab permissions from the user
  const isAuthorized = hasPermission(permissions);
  if (isAuthorized) return children;
  return <Navigate to="/401" replace={true} />;
};

export default AuthRoute;
