/* eslint-disable max-len */
const ContactIcon = props => (
  <svg
    width={37}
    height={31}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>{"feather icon/contact"}</title>
    <defs>
      <path
        d="M27.86 29.292v3.083c0 .925-.619 1.542-1.547 1.542-.929 0-1.548-.617-1.548-1.542v-3.083c0-2.621-2.012-4.625-4.643-4.625H7.739c-2.631 0-4.643 2.004-4.643 4.625v3.083c0 .925-.62 1.542-1.548 1.542C.619 33.917 0 33.3 0 32.375v-3.083c0-4.317 3.405-7.709 7.74-7.709h12.382c4.333 0 7.739 3.392 7.739 7.709Zm-21.669-18.5c0-4.317 3.405-7.709 7.74-7.709 4.333 0 7.738 3.392 7.738 7.709 0 4.316-3.405 7.708-7.739 7.708s-7.739-3.392-7.739-7.708Zm3.096 0c0 2.62 2.012 4.625 4.643 4.625 2.632 0 4.644-2.005 4.644-4.625 0-2.621-2.012-4.625-4.644-4.625-2.631 0-4.643 2.004-4.643 4.625Zm21.979 11.1c-.774-.154-1.703.308-1.858 1.079-.154.77.31 1.696 1.084 1.85 2.012.462 3.405 2.312 3.405 4.47v3.084c0 .925.62 1.542 1.548 1.542.929 0 1.548-.617 1.548-1.542v-3.083c.154-3.546-2.167-6.63-5.727-7.4Zm-6.191-18.5c-.774-.309-1.548.154-1.858 1.079-.155.77.31 1.696 1.084 1.85 2.476.617 4.024 3.083 3.405 5.704-.465 1.696-1.703 2.93-3.405 3.392-.774.154-1.393 1.079-1.084 1.85.155.77.774 1.233 1.548 1.233h.31c2.786-.77 4.953-2.775 5.572-5.55 1.083-4.317-1.393-8.633-5.572-9.558Z"
        id="icon-contact"
      />
    </defs>
    <g transform="translate(0 -3)" fill="none" fillRule="evenodd">
      <mask id="icon-contact-b" fill="#fff">
        <use xlinkHref="#icon-contact" />
      </mask>
      <use fill="#000" fillRule="nonzero" xlinkHref="#icon-contact" />
      <g mask="url(#icon-contact-b)" fill="#212121">
        <path d="M0 0h37v37H0z" />
      </g>
    </g>
  </svg>
);

export default ContactIcon;
