import { useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";

import {
  GridInputs,
  multiSelect,
  Table,
} from "../../../../../../../components/Functional";
import { Button, Modal } from "../../../../../../../components/ui";
import ThreeStateCheckbox from "../../../../../../../components/ui/Input/components/ThreeStateCheckbox";
import { convertToFilter } from "../../../../../../../util";

import { FETCH_INSTANCES_V2 as fetchInstancesV2 } from "../../../../../../../graphql/query/Instance";
import { ADD_SHIPMENT_WORK as addShipmentWorkMutation } from "../../../../../../../graphql/mutation/Shipment";
import { FETCH_ARTISTS as fetchArtists } from "../../../../../../../graphql/query/Artist";
import { FETCH_COMPANIES as fetchCompanies } from "../../../../../../../graphql/query/Company";

const FilterBar = props => {
  const { formik } = props || {};
  const { data: { artists = [] } = {} } = useQuery(fetchArtists());
  const { data: { companies = [] } = {} } = useQuery(fetchCompanies());

  const inputs = {
    className: "grid-cols-5 gap-4 my-6",
    inputs: [
      {
        label: "Company",
        name: "companyId",
        type: "multi-select",
        options: companies?.edges?.map(item => ({
          label: item?.name,
          value: item?.id,
        })),
      },
      { label: "Control Reference", name: "ref", type: "text" },
      {
        label: "Artist",
        name: "_edition._artwork._artist.id",
        type: "multi-select",
        options: artists?.map(item => ({ label: item?.name, value: item?.id })),
      },
      { label: "Title", name: "_edition._artwork.title", type: "text" },
      { label: "Edition #", name: "editionNumber", type: "number" },
      { label: "Name", name: "name", type: "text" },
      { label: "Artist Ref", name: "artistRef", type: "text" },
      { label: "Series", name: "_edition._artwork.series", type: "text" },
      {
        designType: "threeStateCheckbox",
        label: "Photographed",
        name: "_edition._artwork.photographed",
        type: "toggle",
      },
    ],
  };

  const inputProps = {
    formik,
    ...inputs,
  };

  return <GridInputs {...inputProps} />;
};

const AddWorkModal = props => {
  const { closeModal, shipment } = props;
  const [TaWarning, setTaWarning] = useState(false);

  const [
    addShipmentWork,
    {
      reset: resetAdd,
      loading,
      data: { addShipmentWork: { error: addShipmentWorkError } = {} } = {},
    },
  ] = useMutation(addShipmentWorkMutation(), {
    refetchQueries: ["FetchWarehouseMoveV2"],
  });

  const handleClick = () => {
    refetch();

    addShipmentWork({
      variables: {
        input: {
          id: shipment?.id,
          instanceIds: selectedInstances,
        },
      },
    }).then(({ data }) => {
      let hasTAWork = [];
      if (edges && edges.length > 0) {
        hasTAWork = edges.filter(edge => edge?.bondType === "TA");
        if (hasTAWork && hasTAWork.length > 0) {
          setTaWarning(true);
        }
      }

      if (data?.addShipmentWork?.success) {
        if (hasTAWork.length < 1) {
          closeModal?.();
        }
        resetAll();
      }
    });
  };

  const formik = useFormik({
    initialValues: {},
    onSubmit: handleClick,
  });

  const filterFormik = useFormik({
    initialValues: {},
    validationSchema: Yup.object({
      editionNumber: Yup.number()
        .nullable()
        .integer()
        .positive()
        .typeError("Invalid input"),
    }),
  });

  const selectedInstances = Object.keys(
    formik?.values?.selectedInstances?.ids || {},
  )?.map(item => parseInt(item.replace("_", "")));

  const filters = convertToFilter({
    values: filterFormik?.values,
    skipKeys: ["limit", "offset"],
  });

  const { data: { instancesV2: { edges = [] } = {} } = {}, refetch } = useQuery(
    fetchInstancesV2(),
    {
      enabled: false,
      variables: {
        input: { ...filters },
      },
    },
  );

  const EditionNumber = ({ values }) => {
    if (values?.edition?.number === 1 || !values?.editionNumber) return null;
    return (
      <div className="flex flex-col">
        <span className="text-base font-medium leading-5 text-black">{`${values?.editionNumber}/${values?.edition?.number}`}</span>
      </div>
    );
  };

  const multiSelectProps = {
    formik,
    label: "",
    name: "selectedInstances",
    keyName: "id",
    showAll: false,
  };

  const tableProps = {
    dataKey: "instancesV2",
    FETCH_QUERY: fetchInstancesV2({
      edition: { artwork: { artists: {} } },
      status: {},
    }),
    headers: [
      multiSelect(multiSelectProps),
      {
        label: "Status",
        name: "status.name",
        type: "label",
      },
      { label: "Reference", name: "name", type: "label" },
      {
        label: "Artist",
        name: "edition.artwork.artists",
        type: "custom",
        component: props => (
          <div className="flex flex-col">
            <span className="text-base font-medium leading-5 text-black">
              {props.values?.edition?.artwork?.artists
                ?.map(artist => artist.name)
                .join(", ")}
            </span>
          </div>
        ),
      },
      {
        label: "Title",
        name: "edition.artwork.title",
        type: "label",
        widthInPx: 400,
      },
      {
        label: "Edition",
        name: "editionNumber",
        type: "custom",
        component: EditionNumber,
      },
      {
        label: "AP",
        name: "edition.ap",
        type: "custom",
        component: props => (
          <ThreeStateCheckbox
            checked={props.values?.edition?.ap}
            disabled={true}
          />
        ),
      },
      { label: "Year", name: "edition.year", type: "label" },
      { label: "Medium", name: "edition.artwork.medium", type: "label" },
      {
        label: "Dimensions",
        name: "edition.artwork.dimensions",
        type: "label",
      },
      {
        label: "Fabricated",
        name: "fabricated",
        type: "custom",
        component: props => (
          <ThreeStateCheckbox
            checked={props.values?.fabricated}
            disabled={true}
          />
        ),
      },
      {
        label: "Location",
        name: "locationHistory",
        type: "label",
      },
    ],
    inputs: [],
    limit: 10,
    skip: () => !filterFormik?.isValid,
    variables: convertToFilter({
      values: filterFormik?.values,
      skipKeys: ["limit", "offset"],
    }),
    pagination: {
      variant: "sm",
    },
    variant: "dynamicHeight",
  };

  const resetAll = () => {
    filterFormik?.resetForm();
    formik?.resetForm();
    resetAdd?.();
  };

  if (addShipmentWorkError) {
    return (
      <div className="mt-6 flex w-full flex-col">
        <div className="flex flex-col">
          <div className="text-2xl font-bold">Error Adding Work</div>
          <div className="mt-6">{addShipmentWorkError}</div>
        </div>
        <div className="mt-8 flex flex-row">
          <div className="flex flex-col">
            <div>
              <Button
                className="mr-4"
                label="Close"
                onClick={() => {
                  resetAll();
                  closeModal?.();
                  setTaWarning(false);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  } else if (!addShipmentWorkError && TaWarning) {
    return (
      <div className="mt-6 flex w-full flex-col">
        <div className="flex flex-col">
          <div className="text-2xl font-bold">Instance on TA</div>
          <div className="mt-6">
            This instance is on TA. Please comply with TA shipping restrictions.
          </div>
        </div>
        <div className="mt-8 flex flex-row">
          <div className="flex flex-col">
            <div>
              <Button
                className="mr-4"
                label="Close"
                onClick={() => {
                  resetAll();
                  closeModal?.();
                  setTaWarning(false);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="mt-6 flex w-full flex-col">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col">
          <div className="text-2xl font-bold">Add Work: Select an Instance</div>
        </div>
        <div className="flex flex-col">
          <div>
            <Button
              action="default"
              className="mr-4"
              label="Cancel"
              onClick={() => {
                resetAll();
                closeModal?.();
              }}
            />
            <Button
              label={loading ? "Adding" : "Add"}
              disabled={loading || !selectedInstances?.length}
              onClick={formik?.submitForm}
            />
          </div>
        </div>
      </div>
      <FilterBar formik={filterFormik} />
      <p className="mt-4">
        {selectedInstances?.length} Selected{" "}
        <span className="ml-8 text-red-500">{addShipmentWorkError}</span>
      </p>
      <Table {...tableProps} />
    </div>
  );
};

const AddWork = props => {
  const { shipment } = props || {};

  const modalProps = {
    body: AddWorkModal,
    closeOnBackdrop: false,
    shipment,
    hideCloseButton: true,
    scale: "md",
  };

  return (
    <Modal {...modalProps}>
      <Button label="Add Work" action="black" />
    </Modal>
  );
};

export default AddWork;
