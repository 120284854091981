import { gql } from "@apollo/client";
import { ContactActivity as contactActivity } from "../fragments";

const FETCH_CONTACT_ACTIVITIES = props => gql`
  query FetchContactActivities($input: ContactActivitiesFilterInput!) {
    contactActivities(input: $input) {
      count
      edges {
        ${contactActivity(props)}
      }
    }
  }
`;

const FETCH_CONTACT_ACTIVITY = props => gql`
  query FetchContactActivity($id: Int) {
    artwork(id: $id) {
      ${contactActivity(props)}
    }
  }
`;

export { FETCH_CONTACT_ACTIVITY, FETCH_CONTACT_ACTIVITIES };
