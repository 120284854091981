import { gql } from "@apollo/client";
import { ArtistArtwork as artistArtwork } from "../fragments";

const CREATE_ARTIST_ARTWORK = props => gql`
  mutation CreateArtistArtwork($input: CreateArtistArtworkInput!) {
    createArtistArtwork(input: $input) {
      success
      error
      artistArtwork {
        ${artistArtwork(props)}
      }
    }
  }
`;

const DELETE_ARTIST_ARTWORK = gql`
  mutation DeleteArtistArtwork($input: DeleteArtistArtworkInput!) {
    deleteArtistArtwork(input: $input) {
      success
      error
    }
  }
`;

export { CREATE_ARTIST_ARTWORK, DELETE_ARTIST_ARTWORK };
