import artistFrag from "./Artist";
import editionFrag from "./Edition";
import imageFrag from "./Image";

// TODO: look into images and imagesSummary
const Artwork = ({
  artists = false,
  editions = false,
  images = false,
  imagesSummary = false,
  archiveDescription = false,
  artistDescription = false,
  fullDescription = false,
  pressDescription = false,
} = {}) => `
  id
  ${archiveDescription ? `archiveDescription` : ""}
  artFair
  ${
    artists
      ? `artists {
      ${artistFrag(artists)}
    }`
      : ""
  }
  ${artistDescription ? `artistDescription` : ""}
  authenticity
  categoryId
  classId
  confidential
  countryOfOriginId
  createdAt
  description
  dimensions
  ${
    editions
      ? `editions {
      ${editionFrag(editions)}
    }`
      : ""
  }
  ${fullDescription ? `fullDescription` : ""}
  ${pressDescription ? `pressDescription` : ""}
  goodsCode
  installation
  installationLink
  ${
    images
      ? `images {
      ${imageFrag(images)}
    }`
      : ""
  }
  ${
    imagesSummary
      ? `imagesSummary {
      ${imageFrag(imagesSummary)}
    }`
      : ""
  }
  maintenance
  medium
  packing
  photographed
  photoLink
  photoNote
  precis
  reference
  series
  standardId
  technicalDimensions
  timestamp
  title
  travelPack
  videoLink
  website
`;

export default Artwork;
