import { useMutation } from "@apollo/client";

import { Button, getInput, Modal } from "../../../../../components/ui";
import { UPLOAD_FILE } from "../../../../../graphql/mutation/User";
import { FETCH_DOCUMENTS as fetchDocuments } from "../../../../../graphql/query/Document";
import { Table } from "../../../../../components/Functional";
import { CheckIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import { EXPORT_DOCUMENT } from "../../../../../graphql/mutation/Document";

const ImageField = props => {
  const { value, setValue, isMultiSelect } = props || {};
  const [uploadFile, { loading }] = useMutation(UPLOAD_FILE);
  const handleUpload = async e => {
    const files = e.target.files;
    e.preventDefault();
    if (files.length > 1 && isMultiSelect) {
      const newArray = [];
      for (let i = 0; i < files.length; i++) {
        // Code for uploading file to AWS S3 server side
        await uploadFile({
          variables: {
            file: files[i],
          },
        }).then(resp => {
          const { data } = resp;
          const { uploadFile } = data || {};
          const { filename, size } = uploadFile || {};
          newArray.push({ filename, size });
        });
      }
      if (value) {
        setValue?.([...value, ...newArray]);
      } else {
        setValue?.(newArray);
      }
    } else {
      // Code for uploading file to AWS S3 server side
      uploadFile({
        variables: {
          file: files[0],
        },
      }).then(resp => {
        const { data } = resp;
        const { uploadFile } = data || {};
        const { filename, size } = uploadFile || {};
        if (value) {
          setValue?.([...value, { filename, size }]);
        } else {
          setValue?.([{ filename, size }]);
        }
      });
    }
  };

  const handleDetach = attachment => {
    setValue?.(value.filter(item => item?.filename !== attachment?.filename));
  };

  const inputProps = {
    ...props,
    loading,
    onChange: handleUpload,
    handleDetach,
  };

  return <div className="flex">{getInput(inputProps)}</div>;
};

const Content = props => {
  const { formik, isEmailContactContent = false } = props || {};
  const [selectedDocument, setSelectedDocument] = useState([]);
  const [exportDocument, { loading }] = useMutation(EXPORT_DOCUMENT);

  const mailMergeOptions = [
    { key: "forename", value: "{{forename}}", text: "Forename" },
    { key: "surname", value: "{{surname}}", text: "Surname" },
    { key: "company", value: "{{company}}", text: "Company" },
    { key: "title", value: "{{title}}", text: "Title" },
    { key: "salutation", value: "{{salutation}}", text: "Salutation" },
    { key: "greeting", value: "{{greeting}}", text: "Greetings" },
  ];

  const inputs = [
    !isEmailContactContent && {
      name: "title",
      label: "Mailing Title",
      type: "text",
      className: "col-span-1",
    },
    {
      name: "meta.subject",
      label: "Subject",
      type: "text",
      className: "col-span-1",
    },
    !isEmailContactContent && {
      name: "meta.preheader",
      label: "Pre-Header Text - Appears after subject line in inbox",
      type: "text",
      className: "col-span-1",
    },
    {
      name: "meta.body",
      label: "Body",
      type: "ckeditortext",
      mailMergeOptions,
      className: "col-span-2",
    },
  ];

  const imageInput = [
    {
      name: "meta.files",
      label: "Attach File(s)",
      type: "img",
      isMultiSelect: true,
      uploadType: "file(s)",
      maxSize: 15,
    },
  ];

  const renderImages = imageInput?.map((item, index) => {
    const inputProps = {
      ...item,
      ...formik?.getFieldProps(`${item.name}`),
      ...formik?.getFieldHelpers(`${item.name}`),
    };
    return <ImageField key={index} {...inputProps} />;
  });

  const renderInputs = inputs?.map((item, index) => {
    const inputProps = {
      formik,
      ...item,
      ...formik?.getFieldProps(item.name),
    };

    return (
      <div key={index} className="flex grid flex-1 grid-cols-2">
        {getInput(inputProps)}
      </div>
    );
  });

  const handleDocumentSelection = id => {
    if (selectedDocument.includes(id)) {
      setSelectedDocument(selectedDocument.filter(docId => docId !== id));
    } else {
      setSelectedDocument([...selectedDocument, id]);
    }
  };

  const radioAndImage = props => {
    const { values = {} } = props || {};
    const url = values?.fieldsData?.[0]?.image;

    return (
      <>
        <div className="mb-2 flex items-center">
          <span
            className="mr-10 inline-block h-5 w-5 rounded-full border-2 border-black"
            onClick={() => handleDocumentSelection(values?.id)}
          >
            {selectedDocument.includes(values?.id) && (
              <CheckIcon className="h-4 w-4" />
            )}
          </span>
          {url ? (
            <img src={url} className="h-20 w-20" />
          ) : (
            <i>
              <small>No Image</small>
            </i>
          )}
        </div>
      </>
    );
  };

  const artistsNameComponent = props => {
    const { values = {} } = props || {};
    const artistsName = values?.fieldsData
      ?.map(({ artist_name: artistName }) => artistName)
      ?.join(", ");

    return (
      <div className="whitespace-normal text-base font-medium leading-5 text-black">
        {artistsName}
      </div>
    );
  };

  const pagesCountComponent = props => {
    const { values = {} } = props || {};

    const pagesCount = values?.pages?.length;
    return (
      <span className="text-base font-medium leading-5 text-black">
        {pagesCount}
      </span>
    );
  };

  const inputTableProps = {
    dataKey: "documents",
    FETCH_QUERY: fetchDocuments({ fieldsData: {}, pages: {} }),
    headers: [
      {
        label: "",
        name: "",
        type: "custom",
        component: props => radioAndImage(props),
      },
      { label: "Title", name: "title", type: "label" },
      {
        label: "Artist(s)",
        name: "artists",
        type: "custom",
        component: props => artistsNameComponent(props),
      },
      {
        label: "Pages",
        name: "pages",
        type: "custom",
        component: props => pagesCountComponent(props),
      },
      { label: "Last Updated", name: "updatedAt", type: "date" },
    ],
    inputs: [
      {
        name: "status",
        label: "Status",
        type: "select",
        options: [
          {
            label: "All",
            value: "",
          },
          {
            label: "Active",
            value: "active",
          },
          {
            label: "Draft",
            value: "draft",
          },
          {
            label: "Shared",
            value: "shared",
          },
        ],
      },
      { label: "Title", name: "title", type: "text", view: "full" },
    ],
    pagination: {
      variant: "sm",
    },
    limit: 10,
    variant: "dynamicHeight",
  };

  const modalProps = {
    body: ({ closeModal }) => {
      const handleAttachBtn = async () => {
        if (selectedDocument.length > 0) {
          const fileNames = [];
          for (const id of selectedDocument) {
            await exportDocument({
              variables: {
                input: { id },
              },
            }).then(resp => {
              const { data } = resp;
              const { exportDocument } = data || {};
              const { filename, size } = exportDocument || {};
              fileNames.push({ filename, size });
            });
          }

          const fileValues = formik?.values?.meta?.files;
          if (fileValues) {
            formik?.setFieldValue?.("meta.files", [
              ...new Set([...fileValues, ...fileNames]),
            ]);
            setSelectedDocument([]);
            closeModal?.();
          } else {
            formik?.setFieldValue?.("meta.files", fileNames);
            setSelectedDocument([]);
            closeModal?.();
          }
        }
      };

      return (
        <>
          <div className="mt-4 flex justify-between">
            <div>
              <p className="font-bold">Select PDF</p>
            </div>
            <div>
              <Button
                className="mr-3"
                label={"Cancel"}
                onClick={() => {
                  closeModal?.();
                }}
              />
              <Button
                label={loading ? "Processing" : "Attach"}
                disabled={loading}
                onClick={() => handleAttachBtn()}
              />
            </div>
          </div>
          <div>
            <Table {...inputTableProps} />
          </div>
        </>
      );
    },
  };

  return (
    <div className="flex grid flex-1 grid-cols-1 gap-8">
      {renderInputs}
      {!isEmailContactContent && (
        <div className="flex flex-row">
          <div className="pr-4">{renderImages}</div>
          <div className="pl-4">
            <label className="mb-2 block text-xs font-medium text-black">
              Attach PDF(s)
            </label>
            <Modal {...modalProps}>
              <Button label={"Select PDF"} />
            </Modal>
          </div>
        </div>
      )}
    </div>
  );
};

export default Content;
