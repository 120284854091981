import { gql } from "@apollo/client";
import { InvoiceDestination } from "../fragments";

const FETCH_INVOICE_DESTINATIONS = gql`
  query FetchInvoiceDestinations {
    invoiceDestinations {
      ${InvoiceDestination}  
    }
  }
`;

export { FETCH_INVOICE_DESTINATIONS };
