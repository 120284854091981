import { gql } from "@apollo/client";
import { InvoiceDocument } from "../fragments";

const FETCH_INVOICE_DOCUMENTS = gql`
  query FetchInvoiceDocuments($input: InvoiceDocumentFilterInput!) {
    invoiceDocuments(input: $input) {
      count
      edges {
        ${InvoiceDocument}
      }
    }
  }
`;

export { FETCH_INVOICE_DOCUMENTS };
