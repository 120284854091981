/* eslint-disable max-len */
const DangerIcon = props => (
  <svg
    width={20}
    height={20}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>{"feather icon/danger"}</title>
    <defs>
      <path
        d="M13.417 19.167H6.583a.757.757 0 0 1-.583-.25L1.083 14c-.166-.083-.25-.333-.25-.583V6.583c0-.25.084-.416.25-.583L6 1.083c.083-.166.333-.25.583-.25H13.5c.25 0 .417.084.583.25L19 6c.083.083.167.333.167.583V13.5c0 .25-.084.417-.25.583L14 19c-.083.083-.333.167-.583.167Zm-6.5-1.667h6.25l4.416-4.417V6.917l-4.5-4.417H6.917L2.5 6.917v6.25L6.917 17.5Zm3.916-7.5V6.667c0-.5-.333-.834-.833-.834-.5 0-.833.334-.833.834V10c0 .5.333.833.833.833.5 0 .833-.333.833-.833Zm-.25 3.917c.084-.084.167-.167.167-.25 0-.084.083-.25.083-.334 0-.083 0-.25-.083-.333-.083-.083-.083-.167-.167-.25 0 0-.083-.083-.166-.083-.084 0-.084-.084-.167-.084-.333-.166-.667-.083-.917.167-.083.083-.166.167-.166.25 0 .083-.084.167-.084.333 0 .25.084.417.25.584.167.166.334.25.584.25.333 0 .5-.084.666-.25Z"
        id="a"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <use fill="#000" fillRule="nonzero" xlinkHref="#a" />
      <g mask="url(#b)" fill="#F4516C">
        <path d="M0 0h20v20H0z" />
      </g>
    </g>
  </svg>
);

export default DangerIcon;
