import { GridInputs } from "../../../../../components/Functional";

const Sort = props => {
  const { formik } = props || {};

  const sortOptions = [
    { label: "Id", value: "id" },
    { label: "Date", value: "date" },
    { label: "Type", value: "typeId" },
  ];

  const inputs = {
    className: "grid grid-rows-2 gap-4",
    inputs: [
      {
        className: "grid grid-cols-4 gap-4",
        inputs: [
          {
            hasOperator: true,
            label: "Sort By 1st",
            name: "sort1",
            options: sortOptions.filter(
              option =>
                ![
                  formik?.values?.sort2?.value,
                  formik?.values?.sort3?.value,
                ].includes(option.value),
            ),
            operatorOptions: [
              { label: "Ascending", value: "ASC" },
              { label: "Descending", value: "DESC" },
            ],
            type: "multi-select",
          },
          {
            hasOperator: true,
            label: "Sort By 2nd",
            name: "sort2",
            options: sortOptions.filter(
              option =>
                ![
                  formik?.values?.sort1?.value,
                  formik?.values?.sort3?.value,
                ].includes(option.value),
            ),
            operatorOptions: [
              { label: "Ascending", value: "ASC" },
              { label: "Descending", value: "DESC" },
            ],
            type: "multi-select",
          },
          {
            hasOperator: true,
            label: "Sort By 3rd",
            name: "sort3",
            options: sortOptions.filter(
              option =>
                ![
                  formik?.values?.sort1?.value,
                  formik?.values?.sort2?.value,
                ].includes(option.value),
            ),
            operatorOptions: [
              { label: "Ascending", value: "ASC" },
              { label: "Descending", value: "DESC" },
            ],
            type: "multi-select",
          },
          {
            label: "limit",
            name: "limit",
            onChange: e => {
              const { value } = e.target;
              formik.setFieldValue("limit", parseInt(value) || 10);
            },
            type: "number",
          },
        ],
      },
    ],
  };

  const gridInputProps = {
    formik,
    ...inputs,
  };

  return <GridInputs {...gridInputProps} />;
};

export default Sort;
