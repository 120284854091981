import * as React from "react";
import { Link } from "react-router-dom";
import { Table } from "../../../../../../components/Functional";
import { FETCH_CONTACT_LINKS as fetchContactLinks } from "../../../../../../graphql/query/ContactLink";
import Actions from "./components/Actions";
import SectionHeader from "../../components/SectionHeader";
import { Avatar, Button, Modal } from "../../../../../../components/ui";
import AddAffiliation from "./components/AddAffiliations";
import { FETCH_CONTACT_LINK_TYPES } from "../../../../../../graphql/query/ContactLinkType";
import { useQuery } from "@apollo/client";
import { useDebounce } from "../../../../../../util";
import { FETCH_CONTACTS as fetchContacts } from "../../../../../../graphql/query/Contact";
import { useFormik } from "formik";
import useUser from "../../../../../../hooks/useUser";

const AffiliationStatus = props => {
  return (
    <span
      className={`text-base font-medium capitalize ${props?.values?.expired === 0 ? "text-green-700" : "text-red-700"}`}
    >
      {props?.values?.expired === 0 ? "Current" : "Expired"}
    </span>
  );
};

const AffiliationName = props => {
  return (
    <span className="text-base font-medium capitalize">
      <Link
        className="underline"
        to={`/contacts/${props?.values?.linkId}/details`}
      >
        {props?.values?.link?.name || props?.values?.link?.company}
      </Link>
    </span>
  );
};

const Affiliations = props => {
  const { contactId, contact } = props || {};
  const { data: { contactLinkTypes = [] } = {} } = useQuery(
    FETCH_CONTACT_LINK_TYPES,
  );
  // for searching contacts
  const formik = useFormik({ initialValues: { search: "" } });
  const debouncedFilters = useDebounce(formik?.values, 300);
  const { data: { contacts = {} } = {} } = useQuery(fetchContacts(), {
    variables: { input: { ...debouncedFilters } },
  });
  const { hasPermission } = useUser();
  const disabled = !hasPermission("EDIT_CONTACT_LINK");

  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "contactLinks",
    headers: [
      {
        label: "",
        name: "image",
        type: "custom",
        component: props => (
          <Avatar
            size="lg"
            src={props?.values?.link?.img}
            label={props?.values?.link?.img || props?.values?.link?.name}
          />
        ),
      },
      {
        label: "Name",
        name: "link.name",
        type: "custom",
        component: AffiliationName,
      },
      {
        label: "Description",
        name: "description",
        sortKey: "description",
        type: "label",
        width: "400px",
      },
      { label: "Relationship Type", name: "type.description", type: "label" },
      {
        label: "Status",
        name: "expired",
        type: "custom",
        component: AffiliationStatus,
      },
      { label: "", name: "action", type: "custom", component: Actions },
    ],
    inputs: [
      {
        name: "linkId",
        label: "Name",
        type: "multi-select",
        onInputChange: value => {
          formik?.setFieldValue("search", value);
        },
        options:
          contacts?.edges?.map(item => ({
            label: item.name,
            value: item.id,
          })) || [],
      },
      {
        name: "typeId",
        label: "Relation Type",
        type: "multi-select",
        isMulti: false,
        options:
          contactLinkTypes?.map(item => ({
            label: item.description,
            value: item.id,
          })) || [],
      },
    ],
    FETCH_QUERY: fetchContactLinks({ link: {}, type: {} }),
    variables: {
      contactId,
    },
    className: "sm:px-0 lg:px-0",
  };

  const modalProps = {
    body: AddAffiliation,
    closeOnBackdrop: true,
    contact,
    disabled,
    hideCloseButton: true,
    scale: "md",
  };

  const affiliationsActions = [
    {
      component: () => {
        return (
          <Modal {...modalProps}>
            <Button
              label="Add Affiliation"
              action="black"
              disabled={disabled}
            />
          </Modal>
        );
      },
    },
  ];
  return (
    <div className="w-full">
      <SectionHeader title="Affiliations" actions={affiliationsActions} />
      <div className="mb-6 w-full">
        <Table {...tableProps} />
      </div>
    </div>
  );
};

export default Affiliations;
