import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { IconButton } from "../../../../../../../components/ui/IconButton";
import { Button } from "../../../../../../../components/ui";
import { Modal } from "../../../../../../../components/ui/Modal";
import { useMutation } from "@apollo/client";
import { DELETE_CONTACT_ATTRIBUTE } from "../../../../../../../graphql/mutation/ContactAttribute";
import AddAttribute from "./AddAttribute";
import useUser from "../../../../../../../hooks/useUser";

const Actions = props => {
  const { id, values, contact } = props || {};
  const [deleteContactAttribute, { loading }] = useMutation(
    DELETE_CONTACT_ATTRIBUTE,
  );
  const { hasPermission } = useUser();
  const disabled = !hasPermission("EDIT_CONTACT_ATTRIBUTE");

  const editModalProps = {
    body: AddAttribute,
    hideCloseButton: true,
    scale: "md",
    values,
    contact,
  };
  const deleteModalProps = {
    title: "Are you sure?",
    scale: "sm",
    description: (
      <>
        <strong>Would you like to delete attribute:</strong>{" "}
        {values?.description}?
      </>
    ),
    body: ({ closeModal }) => {
      const handleDelete = () => {
        deleteContactAttribute({
          variables: {
            input: { id },
          },
          update: (cache, { data }) => {
            const { deleteContactAttribute: { success } = {} } = data;
            if (success) {
              closeModal();
              cache.evict({
                id: cache.identify({
                  __typename: "ContactAttribute",
                  id,
                }),
              });
            }
          },
        });
      };
      return (
        <div className="mt-4 flex">
          <Button
            className="mr-3"
            label={"Cancel"}
            action={"default"}
            onClick={() => {
              closeModal?.();
            }}
          />
          <Button
            label={loading ? "Deleting" : "Delete"}
            disabled={loading}
            onClick={handleDelete}
          />
        </div>
      );
    },
  };
  if (typeof id == "number") {
    return (
      <div className="user-list-actions flex text-right text-sm font-medium">
        {id && !disabled && (
          <div className="grid grid-cols-4 gap-4">
            <Modal {...editModalProps}>
              <IconButton variant="clean" title="Edit Events">
                <PencilIcon
                  className={`h-4 w-4 cursor-pointer text-gray-400`}
                />
              </IconButton>
            </Modal>
            <Modal {...deleteModalProps}>
              <IconButton variant="clean" title="Delete Events">
                <TrashIcon className={`h-4 w-4 cursor-pointer text-gray-400`} />
              </IconButton>
            </Modal>
          </div>
        )}
      </div>
    );
  } else {
    return false;
  }
};

export default Actions;
