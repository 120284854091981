import { gql } from "@apollo/client";

const UPLOAD_CONSIGNMENT_DOCUMENTS = gql`
  mutation UploadConsignmentDocuments(
    $input: UploadConsignmentDocumentsInput!
  ) {
    uploadConsignmentDocuments(input: $input) {
      success
      error
      response
    }
  }
`;

export { UPLOAD_CONSIGNMENT_DOCUMENTS };
