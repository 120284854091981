import { gql } from "@apollo/client";
import { Value as value } from "../fragments";

const FETCH_VALUE = props => gql`
  query FetchValue($id: Int) {
    value(id: $id) {
      ${value(props)}
    }
  }
`;

const FETCH_VALUES = props => gql`
  query FetchValues($input: ValueFilterInput!) {
    values(input: $input) {
      count
      edges {
        ${value(props)}
      }
    }
  }
`;

export { FETCH_VALUE, FETCH_VALUES };
