import { useQuery } from "@apollo/client";

import { FETCH_COUNTRIES } from "../../../../../../../graphql/query/Country";
import { GridInputs } from "../../../../../../../components/Functional";
import useUser from "../../../../../../../hooks/useUser";

const Details = props => {
  const { formik, submitOnBlur = false } = props;
  const { hasPermission } = useUser();

  const { data: { countries = [] } = {} } = useQuery(FETCH_COUNTRIES);

  const inputs = {
    className: "grid grid-cols-2 gap-8 mt-5",
    inputs: [
      {
        className: "grid-cols-1 gap-4",
        inputs: [
          {
            label: "Ultimate Purchaser",
            name: "purchaserId",
            type: "contactSearch",
            submitOnBlur,
            disabled: !hasPermission("UPDATE_INVOICE"),
          },
          {
            label: "Purchaser Country",
            name: "purchaserCountryId",
            type: "select",
            options: countries?.map(item => ({
              label: item.name,
              value: item.id,
            })),
            submitOnBlur,
            disabled: !hasPermission("UPDATE_INVOICE"),
          },
        ],
      },
      {
        className: "grid-cols-1 gap-4",
        inputs: [
          {
            label: "Art Advisor",
            name: "artAdvisorId",
            type: "contactSearch",
            fetchType: "artAdvisor",
            submitOnBlur,
            disabled: !hasPermission("UPDATE_INVOICE"),
          },
          {
            label: "Art Advisor Sale",
            name: "artAdvisorSale",
            type: "toggle",
            designType: "threeStateCheckbox",
            submitOnBlur,
            disabled: !hasPermission("UPDATE_INVOICE"),
          },
        ],
      },
    ],
  };

  const inputProps = {
    formik,
    ...inputs,
  };

  return (
    <div className="w-full">
      <h5 className="font-bold">Details</h5>
      <GridInputs {...inputProps} />
    </div>
  );
};

export default Details;
