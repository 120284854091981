import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import { Button, Modal } from "../../../../../../../components/ui";
import { CREATE_LIST as createListMutation } from "../../../../../../../graphql/mutation/List";

const AddToSelectListBody = props => {
  const { closeModal, invoiceId } = props || {};
  const navigate = useNavigate();

  const [createList, { loading, error }] = useMutation(createListMutation(), {
    refetchQueries: ["FetchLists", "FetchListItems"],
  });

  const onClick = async () => {
    const res = await createList({
      variables: {
        input: {
          name: `select_list_working_${Date.now()}`,
          listItemIds: [invoiceId],
          typeId: 10,
          primary: true,
        },
      },
    });
    if (res?.data?.createList?.success) {
      navigate(`/invoice?status=selectListWorking`);
    }
  };

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          <div>Add to Select List?</div>
        </div>
        <div className="flex gap-4">
          <Button
            action="default"
            label="Cancel"
            onClick={() => {
              closeModal();
            }}
          />
          <Button
            label={"Add"}
            loading={loading}
            disabled={loading}
            onClick={onClick}
          />
        </div>
      </div>
      {error ? (
        <p>
          <span className="text-sm text-red-500">{error}</span>
        </p>
      ) : (
        <div className="grid">
          Add this invoice to a select list? You will be redirected to the
          select list
        </div>
      )}
    </div>
  );
};

const AddToSelectList = props => {
  const { invoiceId } = props || {};

  const modalProps = {
    body: AddToSelectListBody,
    closeOnBackdrop: false,
    hideCloseButton: true,
    scale: "sm",
    invoiceId,
  };

  return (
    <Modal {...modalProps}>
      <Button label="Add to Select List" action="dark" />
    </Modal>
  );
};

export default AddToSelectList;
