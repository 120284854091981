import { Fragment } from "react";

import { Table, multiSelect } from "../../../../components/Functional";
import Header from "../../components/HeaderComponent";

import Actions from "./components/Actions";
import searchedShipments from "./components/SearchedShipments";
import { useNavigate } from "react-router-dom";
import { shipmentFilterStore } from "../../components/useShipmentFilters";

const List = props => {
  const { formik } = props || {};
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const status = queryParams?.get("status") || "all";

  const actionProps = {
    formik,
    search: shipmentFilterStore.get(),
    onSuccess: () => formik?.resetForm(),
  };

  const handleChange = value => {
    navigate(`/shipments?status=${value}`);
  };

  const tabProps = {
    active: status,
    onChange: handleChange,
    tabs: [
      {
        label: "All Shipments",
        name: "all",
        tableProps: searchedShipments(),
      },
    ],
  };

  const { tableProps: tabTableProps, actions: tabActions } =
    tabProps?.tabs?.find(tab => tab.name === status) || {};

  // function fixes header.name for select lists when status = selectListWorking
  const fixHeadersName = ({ status, headers }) =>
    headers?.map(header => {
      return {
        ...header,
        name:
          status === "selectListWorking"
            ? `item.${header?.name}`
            : header?.name,
      };
    });

  const multiSelectProps = {
    formik,
    label: "Shipments",
    name: "selected",
    keyName: "id",
  };

  const tableProps = {
    headers: fixHeadersName({
      status,
      headers: [
        multiSelect(multiSelectProps),
        { label: "Id", name: "id", sortKey: "id", type: "label" },
        {
          label: "Delivery Company",
          name: "delCompany",
          sortKey: "delCompany",
          type: "label",
        },
        {
          label: "Delivery Address",
          name: "delAddress",
          sortKey: "delAddress",
          type: "label",
        },
        {
          label: "Origin Name",
          name: "oriName",
          sortKey: "oriName",
          type: "label",
        },
        {
          label: "Origin Company",
          name: "oriCompany",
          sortKey: "oriCompany",
          type: "label",
        },
        {
          label: "Shipment Date",
          name: "date",
          sortKey: "date",
          type: "label",
        },
        {
          label: "Delivered Date",
          name: "delivered",
          sortKey: "delivered",
          type: "label",
        },
        {
          label: "Shipment Currency",
          name: "currency.code",
          sortKey: "currency.code",
          type: "label",
        },
        {
          label: "Items on Shipment",
          name: "itemCount",
          sortKey: "itemCount",
          type: "label",
        },
        {
          label: "Total Value",
          name: "totalValue",
          sortKey: "totalValue",
          type: "currencylabel",
        },
        { label: "", name: "action", type: "custom", component: Actions },
      ],
    }),
    tabProps,
    variant: "dynamicHeight",
    ...tabTableProps,
  };

  return (
    <Fragment>
      <div className="px-8">
        <Header
          leftText="Shipments"
          rightComponent={() => {
            return (
              <div className="flex gap-4">{tabActions?.(actionProps)}</div>
            );
          }}
        />
      </div>
      <div className="mt-6 w-full">
        <Table {...tableProps} />
      </div>
    </Fragment>
  );
};

export default List;
