import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import Actions from "./components/Actions";
import HeaderComponent from "./components/HeaderComponent";
import searchedInvoices from "./components/SearchedInvoices";
import * as SelectListWorking from "./components/SelectListWorking";
import * as SelectListSaved from "./components/SelectListSaved";

import { invoiceFilterStore } from "../../components/useInvoiceFilters";

import {
  Table,
  multiSelect,
  SelectList,
} from "../../../../components/Functional";
import { FETCH_LISTS as fetchLists } from "../../../../graphql/query/List";
import { CheckIcon } from "@heroicons/react/24/solid";
import { Avatar } from "../../../../components/ui";
import { useEffect } from "react";

const InvoiceNumber = props => {
  const { values } = props;
  const number = values?.type?.id === 1 ? values?.number : values?.reference;
  return <p>{number}</p>;
};

const nameAndCompany = props => {
  const { contact, companyName } = props?.values?.item || props?.values || {};
  return (
    <>
      <div className="flex items-center gap-2">
        <Avatar
          size="lg"
          src={contact?.img}
          label={contact?.img || contact?.name}
        />
        <div>
          <p>{contact?.name}</p>
          <p className="mt-1 italic text-gray-400">{companyName}</p>
        </div>
      </div>
    </>
  );
};

const Approved = props => {
  // show check icon if value is -1
  return (
    <div className="flex items-center justify-center">
      {(props?.values?.item?.approved === -1 ||
        props?.values?.approved === -1) && (
        <CheckIcon className="h-5 w-5" aria-hidden="true" />
      )}
    </div>
  );
};

const List = props => {
  const { formik } = props;
  const navigate = useNavigate();
  const { data: { lists: { edges = [] } = {} } = {} } = useQuery(fetchLists(), {
    variables: {
      // fetch invoice_working list
      input: { typeId: 10 },
    },
  });
  const workingListId = edges?.length > 0 ? edges[0]?.id : null;

  const queryParams = new URLSearchParams(window.location.search);
  const status = queryParams?.get("status") || "all";
  const listId = +queryParams.get("listId");

  const ids = Object.keys(formik?.values?.selected?.ids || {})?.map(item =>
    parseInt(item.replace("_", "")),
  );

  useEffect(() => {
    if (workingListId) {
      queryParams.set("workingListId", workingListId);
      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      // eslint-disable-next-line
      history.pushState(null, null, newUrl);
    }
  }, [workingListId]);

  const multiSelectProps = {
    formik,
    label: "Invoices",
    name: "selected",
    keyName: "id",
  };

  const actionProps = {
    formik,
    ids,
    listId,
    onSuccess: () => formik?.resetForm(),
    search: invoiceFilterStore.get(),
    workingListId,
  };

  const handleChange = value => {
    navigate(`/invoice?status=${value}${listId ? `&listId=${listId}` : ""}`);
  };

  const GeneralTabActions = () => {
    const createListProps = {
      ...actionProps,
      onSuccess: () => (
        formik?.resetForm(), navigate(`/invoice?status=selectListWorking`)
      ),
      typeId: 10,
    };

    return <SelectList.CreateList {...createListProps} />;
  };

  const tabProps = {
    active: status,
    onChange: handleChange,
    tabs: [
      // { actions: GeneralTabActions, label: 'All Invoices', name: 'all' },
      {
        actions: GeneralTabActions,
        label: "All Invoices",
        name: "all",
        tableProps: searchedInvoices(),
      },
      {
        actions: SelectListWorking.Actions,
        label: "Select List (Working)",
        name: "selectListWorking",
        tableProps: SelectListWorking.tableProps({ listId, workingListId }),
      },
      {
        actions: SelectListSaved.Actions,
        label: "Select List (Saved)",
        name: "selectListSaved",
        tableProps: SelectListSaved.tableProps({ formik }),
      },
    ],
  };
  const { tableProps: tabTableProps, actions: tabActions } =
    tabProps?.tabs?.find(tab => tab.name === status) || {};

  // function fixes header.name for select lists when status = selectListWorking
  const fixHeadersName = ({ status, headers }) =>
    headers?.map(header => {
      return {
        ...header,
        name:
          header?.name && status === "selectListWorking"
            ? Array.isArray(header?.name)
              ? header?.name?.map(item => `item.${item}`)
              : `item.${header?.name}`
            : header?.name,
      };
    });

  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "invoices",
    headers: fixHeadersName({
      status,
      headers: [
        multiSelect(multiSelectProps),
        { label: "Date", name: "date", type: "date" },
        { label: "Invoiced From", name: "company.name", type: "label" },
        { label: "Type", name: "type.description", type: "label" },
        {
          label: "Approved",
          name: "approved",
          type: "custom",
          component: Approved,
        },
        {
          label: "Client Name & Company",
          type: "custom",
          component: props => nameAndCompany(props),
        },
        { label: "Invoice Number", type: "custom", component: InvoiceNumber },
        { label: "Currency", name: "currency.code", type: "label" },
        { label: "Total", name: "invoiceTotals.total", type: "currency" },
        { label: "Balance", name: "invoiceTotals.balance", type: "currency" },
        { label: "Due Date", name: "dueDate", type: "date" },
        { label: "", name: "action", type: "custom", component: Actions },
      ],
    }),
    inputs: [],
    tabProps,
    ...tabTableProps,
  };

  return (
    <>
      <div className={"px-8"}>
        <HeaderComponent
          leftText="All Invoices"
          rightComponent={() => {
            return (
              <div className="flex gap-4">{tabActions?.(actionProps)}</div>
            );
          }}
        />
      </div>
      <div className="mt-6 w-full">
        <Table {...tableProps} />
      </div>
    </>
  );
};

export default List;
