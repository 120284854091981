import { Link } from "react-router-dom";
import { Table } from "../../../../../../components/Functional";
import { FETCH_WAREHOUSE_ITEMS_V2 as fetchWarehouseItemsV2 } from "../../../../../../graphql/query/WarehouseItem";

import SectionHeader from "../../components/SectionHeader";
import Actions from "./components/Actions";
import AddItem from "./components/AddItem";

const EditionNumber = ({ values }) => {
  const comparer = [null, undefined];
  const editionNumber = values?.instance?.editionNumber;
  const edition = values?.instance?.edition?.number;

  return (
    <div className="flex flex-col">
      <span className="text-base font-medium leading-5 text-black">
        {`${comparer.includes(editionNumber) ? "" : editionNumber}/${comparer.includes(edition) ? "" : edition}`}
      </span>
    </div>
  );
};

const Contents = props => {
  const { warehouseItem, warehouseItemId } = props;

  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "warehouseItemsV2",
    headers: [
      {
        label: "Item #",
        name: "id",
        type: "custom",
        component: ({ values }) => (
          <Link className="underline" to={`/warehouse/${values?.id}/details`}>
            {values?.id}
          </Link>
        ),
      },
      { label: "Category", name: "category.description", type: "label" },
      { label: "Group", name: "mgroup", type: "label" },
      {
        label: "Instance",
        name: "instance.name",
        type: "custom",
        component: ({ values }) => (
          <Link
            className="underline"
            to={`/instance/${values?.instance?.id}/details`}
          >
            {values?.instance?.name}
          </Link>
        ),
      },
      {
        label: "Edition",
        name: "instance",
        type: "custom",
        component: EditionNumber,
      },
      { label: "Description", name: "description", type: "label" },
      { label: "", name: "action", type: "custom", component: Actions },
    ],
    inputs: [],
    FETCH_QUERY: fetchWarehouseItemsV2({
      category: true,
      instance: { edition: {} },
    }),
    variables: {
      container: {
        operator: "eq",
        value: warehouseItemId,
      },
    },
  };

  const actions = [
    {
      component: () => {
        return (
          <>
            <AddItem
              warehouseItem={warehouseItem}
              warehouseItemId={warehouseItemId}
            />
          </>
        );
      },
    },
  ];

  return (
    <div className="w-full">
      <SectionHeader title="Contents" actions={actions} />
      <div className="mb-6 w-full">
        <Table {...tableProps} />
      </div>
    </div>
  );
};

export default Contents;
