import { gql } from "@apollo/client";
import { WarehouseMove as warehouseMove } from "../fragments";

const FETCH_WAREHOUSE_MOVE = props => gql`
  query FetchWarehouseMove($id: Int!) {
    warehouseMove(id: $id) {
      ${warehouseMove(props)}
    }
  }
`;
const FETCH_WAREHOUSE_MOVES = props => gql`
  query FetchWarehouseMoves($input: WarehouseMoveFilterInput!) {
    warehouseMoves(input: $input) {
      count
      edges {
        ${warehouseMove(props)}
      }
      sqlQuery
    }
  }
`;

const FETCH_WAREHOUSE_MOVE_V2 = props => gql`
  query FetchWarehouseMoveV2($input: AdvWarehouseMoveFilterInput!) {
    warehouseMoveV2(input: $input) {
      count
      edges {
        ${warehouseMove(props)}
      }
      sqlQuery
    }
  }
`;

export { FETCH_WAREHOUSE_MOVE, FETCH_WAREHOUSE_MOVES, FETCH_WAREHOUSE_MOVE_V2 };
