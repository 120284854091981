import { useEffect } from "react";
import { useQuery } from "@apollo/client";

import useUser from "../../../../../../../../../hooks/useUser";
import InstanceInputRender from "./InstanceInputRender";
import DimensionsModal from "../../../../../../../../../components/Modals/DimensionsModal";

import { FETCH_ARTWORK_CATEGORIES } from "../../../../../../../../../graphql/query/ArtworkCategory";
import { FETCH_ARTWORK_TARIFFS } from "../../../../../../../../../graphql/query/ArtworkTariff";

const InstanceDetailCol2 = ({ formik, isDisabled, submitOnBlur = false }) => {
  const { data: { artworkCategories = [] } = {} } = useQuery(
    FETCH_ARTWORK_CATEGORIES,
  );
  const { data: { artworkTariffs = [] } = {} } = useQuery(
    FETCH_ARTWORK_TARIFFS,
  );
  const { hasPermission } = useUser();

  useEffect(() => {
    if (formik?.values?.fabricated === -1 && !formik?.values?.fabricatedDate) {
      formik?.setFieldValue("fabricatedDate", new Date());
    } else if (
      formik?.values?.fabricated === 0 &&
      formik?.values?.fabricatedDate
    ) {
      // unset fabricatedDate
      formik?.setFieldValue("fabricatedDate", null);
    }
  }, [formik?.values?.fabricated]);

  const inputs = [
    {
      label: "Dimensions",
      name: "edition.artwork.dimensions",
      type: "textarea",
      inputClassName: "pr-7",
      rows: 5,
      modalButton: () => (
        <DimensionsModal
          formik={formik}
          submitOnBlur={submitOnBlur}
          fieldName={"edition.artwork.dimensions"}
        />
      ),
      disabled: isDisabled,
      submitOnBlur,
    },
    {
      name: "edition.artwork.series",
      label: "Series",
      type: "text",
      disabled: isDisabled,
      submitOnBlur,
    },
    {
      name: "edition.artwork.categoryId",
      label: "Category",
      type: "select",
      options: artworkCategories?.map(cat => ({
        label: cat.name,
        value: cat.id,
      })),
      disabled: isDisabled,
      submitOnBlur,
    },
    {
      name: "edition.artwork.goodsCode",
      label: "Goods Code",
      type: "multi-select",
      disabled: isDisabled,
      submitOnBlur,
      options: artworkTariffs?.map(tariff => ({
        label: tariff?.code,
        value: tariff?.code,
      })),
    },
    { name: "weight", label: "Weight (Kg)", disabled: true, submitOnBlur },
    {
      name: "condition.status.description",
      label: "Condition",
      type: "text",
      disabled: true,
      submitOnBlur,
    },
    {
      name: "fabricatedDate",
      label: "Fabricated",
      placeholder: "DD/MM/YYYY",
      type: "date",
      checkbox: {
        disabled: isDisabled,
        name: "fabricated",
        options: [{ label: "AP", value: -1 }],
        // disabled: !hasPermission("UPDATE_INSTANCE_FABRICATED"),
        submitOnBlur,
      },
      disabled: true,
      submitOnBlur,
    },
    {
      name: "framed",
      label: "Framed",
      type: "toggle",
      designType: "threeStateCheckbox",
      disabled: !hasPermission("UPDATE_INSTANCE_FRAMED"),
      submitOnBlur,
    },
  ];

  return (
    <div className={`grid h-fit grid-cols-1 gap-4`}>
      <InstanceInputRender inputs={inputs} formik={formik} />
    </div>
  );
};

export default InstanceDetailCol2;
