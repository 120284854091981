import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Button, IconButton, Modal } from "../../../../../../../components/ui";
import useUser from "../../../../../../../hooks/useUser";
import { DELETE_SUPPORT_ARTICLE } from "../../../../../../../graphql/mutation/SupportArticle";

const Actions = props => {
  const { id, values } = props || {};
  const navigate = useNavigate();
  const { hasPermission } = useUser();
  const [deleteSupportArticle, { loading }] = useMutation(
    DELETE_SUPPORT_ARTICLE,
  );

  const deleteModalProps = {
    title: "Are You Sure?",
    scale: "sm",
    description: `Would you like to delete ${values?.meta?.title}?`,
    closeOnBackdrop: true,
    body: ({ closeModal }) => {
      const deleteArticle = () => {
        deleteSupportArticle({
          variables: {
            input: {
              id: +id,
            },
          },
          update: (cache, { data }) => {
            const { deleteSupportArticle: { success } = {} } = data;
            if (success) {
              closeModal();
              cache.evict({
                id: cache.identify({
                  __typename: "SupportArticle",
                  id,
                }),
              });
            }
          },
        });
      };
      return (
        <div className="mt-4 flex justify-center">
          <Button
            className="mr-3"
            label="Cancel"
            onClick={() => {
              closeModal();
            }}
          />
          <Button
            label={loading ? "Deleting" : "Delete"}
            disabled={loading}
            onClick={deleteArticle}
          />
        </div>
      );
    },
  };

  if (typeof id == "number") {
    return (
      <div className="user-list-actions flex text-right text-sm font-medium">
        {id && (
          <div className="grid grid-cols-4 gap-4">
            {hasPermission("UPDATE_SUPPORT_ARTICLE") && (
              <IconButton variant="clean" title="Edit Support">
                <PencilIcon
                  className={`h-4 w-4 cursor-pointer text-gray-400`}
                  onClick={() => navigate(`${id}/edit`)}
                />
              </IconButton>
            )}
            {hasPermission("DELETE_SUPPORT_ARTICLE") && (
              <Modal {...deleteModalProps}>
                <IconButton variant="clean" title="Delete Support">
                  <TrashIcon
                    className={`h-4 w-4 cursor-pointer text-gray-400`}
                  />
                </IconButton>
              </Modal>
            )}
          </div>
        )}
      </div>
    );
  } else {
    return false;
  }
};

export default Actions;
