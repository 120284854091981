import { useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { FETCH_CONTACTS as fetchContacts } from "../../../../graphql/query/Contact";
import { CREATE_CONTACT as createContactMutation } from "../../../../graphql/mutation/Contact";
import { FETCH_CONTACT_TYPES } from "../../../../graphql/query/ContactType";
import { FETCH_ENTITIES } from "../../../../graphql/query/Entity";
import { FETCH_FIRST_CONTACTS } from "../../../../graphql/query/FirstContact";
import { Button, getInput } from "../../../../components/ui";

const NewContact = props => {
  const { closeModal } = props || {};
  const navigate = useNavigate();
  const [createContact, { loading }] = useMutation(createContactMutation(), {
    refetchQueries: [fetchContacts()],
  });
  const { data: { contactTypes = [] } = {} } = useQuery(FETCH_CONTACT_TYPES);
  const { data: { entities } = {} } = useQuery(FETCH_ENTITIES);
  const { data: { firstContacts = [] } = {} } = useQuery(FETCH_FIRST_CONTACTS);

  const contactTypeOptions =
    contactTypes?.map(item => ({
      label: item?.description,
      value: item?.id,
    })) || [];
  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: Yup.object({
      surname: Yup.string().required("Please enter a surname"),
      email: Yup.string().email("Please enter a valid email."),
      // company: Yup.string().required("Please enter a company"),
      typeId: Yup.number().required("Please select a type"),
      salutation: Yup.string().required("Please enter a salutation"),
      // rating is required if the contact type is a collector
      level: Yup.number().when("typeId", {
        is: typeId => typeId === 4,
        then: schema => schema.required("Please enter a rating"),
      }),
      firstcontacttype: Yup.number().when("salesUserId", {
        is: salesUserId => (salesUserId ? true : false),
        then: schema => schema.required("Please enter a first contact type"),
      }),
      firstUserId: Yup.number().when("salesUserId", {
        is: salesUserId => (salesUserId ? true : false),
        then: schema => schema.required("Please enter a first user"),
      }),
    }),
    onSubmit: async values => {
      if (!values.forename) {
        values.forename = "";
      }
      createContact({
        variables: {
          input: {
            ...values,
          },
        },
      }).then(resp => {
        const {
          data: { createContact: { success, contact } = {} },
        } = resp || {};
        if (success) {
          navigate(`/contacts/${contact?.id}/details`);
          closeModal();
        }
      });
    },
  });

  const inputs = [
    { label: "First Name", name: "forename", type: "text" },
    {
      label: "Sales Contact",
      name: "salesUserId",
      options:
        entities?.edges?.map(item => {
          return { label: item.name, value: item.id };
        }) || [],
      type: "multi-select",
    },
    { label: "Surname", name: "surname", type: "text" },
    {
      label: "Type",
      name: "typeId",
      options: contactTypeOptions,
      type: "multi-select",
    },
    { label: "Company", name: "company", type: "text" },
    // { label: 'Email', name: 'email', type: 'text'  },
    { label: "Salutation", name: "salutation", type: "text" },
    // if type is collector, show rating
    formik?.values?.salesUserId
      ? {
          label: "Rating",
          name: "level",
          options: [
            { label: "A+", value: 1 },
            { label: "A", value: 2 },
            { label: "B", value: 3 },
            { label: "C", value: 4 },
            { label: "G", value: 5 },
          ],
          placeholder: "Select Rating",
          type: "multi-select",
        }
      : null,
    formik?.values?.salesUserId
      ? {
          label: "First Met Type",
          name: "firstcontacttype",
          options: firstContacts?.map(item => ({
            label: item.description,
            value: item.id,
          })),
          type: "multi-select",
        }
      : null,
    formik?.values?.salesUserId
      ? {
          label: "First Met User",
          name: "firstUserId",
          options: entities?.edges?.map(item => ({
            label: item?.name,
            value: item?.id,
          })),
          type: "multi-select",
        }
      : null,
  ];

  const hasErrors = Object.keys(formik?.errors || {}).length > 0;

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          <div>Add a new contact</div>
        </div>
        <div className="flex gap-4">
          <Button action="default" label="Cancel" onClick={closeModal} />
          <Button
            label={"Create"}
            loading={loading}
            disabled={loading || hasErrors}
            onClick={formik.submitForm}
          />
        </div>
      </div>
      <div className="w-50 grid grid-cols-2 gap-4">
        {inputs
          ?.filter(item => !!item)
          .map((item, index) => {
            const inputProps = {
              ...item,
              formik,
            };
            return <div key={index}>{getInput(inputProps)}</div>;
          })}
      </div>
    </div>
  );
};

export default NewContact;
