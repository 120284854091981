import * as React from "react";
import { useQuery } from "@apollo/client";

import { Table } from "../../../../../../components/Functional";
import { Button, Modal } from "../../../../../../components/ui";

import { FETCH_CURRENCIES } from "../../../../../../graphql/query/Currency";
import { FETCH_VALUES as fetchValues } from "../../../../../../graphql/query/Value";
import { UPDATE_VALUE as updateValue } from "../../../../../../graphql/mutation/Value";
import { FETCH_VALUE_TYPES } from "../../../../../../graphql/query/ValueType";
import { FETCH_ENTITIES } from "../../../../../../graphql/query/Entity";

import SectionHeader from "../../components/SectionHeader";
import Header from "./components/Header";
import AddHistory from "./components/AddHistory";

const InstanceHistory = props => {
  const { instanceId, formik } = props || {};
  const { data: { valueTypes = [] } = {} } = useQuery(FETCH_VALUE_TYPES);
  const { data: { currencies = [] } = {} } = useQuery(FETCH_CURRENCIES);
  const { data: { entities: { edges = [] } = {} } = {} } =
    useQuery(FETCH_ENTITIES);

  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "values",
    headers: [
      {
        inputType: "multi-select",
        label: "Type",
        name: "typeId",
        options: valueTypes?.map(item => ({
          value: item?.id,
          label: item?.description,
        })),
        type: "input",
      },
      {
        width: "160px",
        inputType: "number-currency",
        label: "Value",
        name: "value",
        type: "input",
      },
      {
        width: "160px",
        type: "input",
        label: "Currency",
        name: "currencyId",
        inputType: "multi-select",
        options: currencies?.map(item => ({
          value: item?.id,
          label: item?.code,
        })),
      },
      {
        width: "160px",
        inputType: "number-currency",
        label: "Max Discount",
        name: "maxDiscount",
        type: "input",
      },
      { inputType: "date", label: "Date", name: "date", type: "input" },
      {
        inputType: "multi-select",
        label: "Authorised",
        name: "authorisedId",
        type: "input",
        options: edges?.map(item => ({ label: item.name, value: item.id })),
      },
      { label: "Gross Profit", name: "grossProfit", type: "currency" },
      { label: "Notes", name: "notes", type: "label" },
      { label: "User", name: "user.name", type: "label" },
    ],
    UPDATE_MUTATION: updateValue(),
    FETCH_QUERY: fetchValues({
      authorisedBy: true,
      currency: true,
      user: true,
    }),
    variables: {
      instanceId: +instanceId,
    },
    className: "sm:px-0 lg:px-0",
  };

  const modalProps = {
    body: AddHistory,
    instanceId,
    hideCloseButton: true,
    scale: "md",
  };

  const historyActions = [
    {
      type: "black",
      label: "ADD RECORD",
      action: () => null,
      component: () => (
        <Modal {...modalProps}>
          <Button label="Add Valuation" action="dark" />
        </Modal>
      ),
    },
  ];

  return (
    <div className="w-full">
      <SectionHeader title="History" actions={historyActions} />
      <Header formik={formik} />
      <div className="mb-6 w-full">
        <Table {...tableProps} />
      </div>
    </div>
  );
};

export default InstanceHistory;
