import { gql } from "@apollo/client";
import { Exhibition as exhibition } from "../fragments";

const FETCH_EXHIBITION = props => gql`
  query FetchExhibition($id: Int) {
    exhibition(id: $id) {
      ${exhibition(props)}
    }
  }
`;

const FETCH_EXHIBITIONS = props => gql`
  query FetchExhibitions($input: ExhibitionFilterInput) {
    exhibitions(input: $input) {
      count
      edges {
        ${exhibition(props)}
      }
    }
  }
`;

const FETCH_EXHIBITIONS_V2 = props => gql`
  query FetchExhibitionsV2($input: AdvExhibitionFilterInput!) {
    exhibitionsV2(input: $input) {
      count
      edges {
        ${exhibition(props)}
      }
      sqlQuery
    }
  }
`;

export { FETCH_EXHIBITION, FETCH_EXHIBITIONS, FETCH_EXHIBITIONS_V2 };
