import ConsignmentChannel from "./ConsignmentChannel";
import instanceFrag from "./Instance";
import invoiceFrag from "./Invoice";
import Nominal from "./Nominal";
import PayType from "./PayType";

const Item = ({
  consignmentChannel = false,
  instance = false,
  invoice = false,
  nominal = false,
  type = false,
} = {}) => `
  amount
  commission
  commissionRate
  consigneeSplit
  consigneeSplitRate
  ${
    consignmentChannel
      ? `consignmentChannel {
      ${ConsignmentChannel}
    }`
      : ""
  }
  creditId
  customs
  date
  description
  discount
  discountRate
  id
  ${
    instance
      ? `instance {
      ${instanceFrag(instance)}
    }`
      : ""
  }
  instanceId
  ${
    invoice
      ? `invoice {
      ${invoiceFrag(invoice)}
    }`
      : ""
  }
  invoiceId
  ${
    nominal
      ? `nominal {
      ${Nominal}
    }`
      : ""
  }
  nominalId
  price
  ref
  showDiscount
  sourceId
  tax
  taxAmount
  ${
    type
      ? `type {
      ${PayType}
    }`
      : ""
  }
  typeId
  exported
  artworkDimensions
  currency
`;

export default Item;
