import { gql } from "@apollo/client";
import { ShipmentCategory } from "../fragments";

const FETCH_SHIPMENT_CATEGORIES = gql`
  query FetchShipmentCategories {
    shipmentCategories {
      ${ShipmentCategory}
    }
  }
`;

export { FETCH_SHIPMENT_CATEGORIES };
