const InvoiceDocument = `
  id
  fileName
  fileType
  invoiceId
  updatedAt
  url
  viewUrl
`;

export default InvoiceDocument;
