import SectionHeader from "../../components/SectionHeader";
import Collection from "./components/Collection";
import Consignee from "./components/Consignee";
import Return from "./components/Return";
import Sales from "./components/Sales";

const Schedule = props => {
  const { formik } = props;

  return (
    <div className="w-full">
      <SectionHeader title="Schedule" />
      <div className="grid h-fit grid-cols-4 gap-8 px-12">
        <Collection formik={formik} submitOnBlur={true} />
        <Return formik={formik} submitOnBlur={true} />
        <Consignee formik={formik} submitOnBlur={true} />
        <Sales formik={formik} submitOnBlur={true} />
      </div>
    </div>
  );
};

export default Schedule;
