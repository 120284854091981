import { gql } from "@apollo/client";
import { CRMRegistrar as crmRegistrar } from "../fragments";

const CREATE_CRM_REGISTRAR = props => gql`
  mutation CreateCRMRegistrar($input: CreateCRMRegistrarInput!) {
    createCRMRegistrar(input: $input) {
      crmRegistrar {
        ${crmRegistrar(props)}
      }
      success
      error
    }
  }
`;

const DELETE_CRM_REGISTRAR = gql`
  mutation DeleteCRMRegistrar($input: DeleteCRMRegistrarInput!) {
    deleteCRMRegistrar(input: $input) {
      success
      error
    }
  }
`;

const HANDOVER_CRM_REGISTRARS = gql`
  mutation HandoverCRMRegistrars($input: HandoverCRMRegistrarsInput!) {
    handoverCRMRegistrars(input: $input) {
      success
      error
    }
  }
`;

const UPDATE_CRM_REGISTRAR = props => gql`
  mutation UpdateCRMRegistrar($input: UpdateCRMRegistrarInput!) {
    updateCRMRegistrar(input: $input) {
      crmRegistrar {
        ${crmRegistrar(props)}
      }
      success
      error
    }
  }
`;

export {
  CREATE_CRM_REGISTRAR,
  DELETE_CRM_REGISTRAR,
  HANDOVER_CRM_REGISTRARS,
  UPDATE_CRM_REGISTRAR,
};
