import { useMutation } from "@apollo/client";

import { Button, Modal } from "../../ui";
import { FETCH_LISTS as fetchLists } from "../../../graphql/query/List";
import { DELETE_LIST } from "../../../graphql/mutation/List";

const DeleteList = props => {
  const { ids, onSuccess } = props || {};
  const [deleteList, { loading }] = useMutation(DELETE_LIST, {
    refetchQueries: [fetchLists()],
  });

  const handleDeleteList = closeModal => async e => {
    e.preventDefault();
    const res = await deleteList({ variables: { input: { ids } } });
    if (res?.data?.deleteList?.success) {
      onSuccess?.();
      closeModal();
    }
  };

  const modalProps = {
    title: "",
    scale: "md",
    hideCloseButton: true,
    closeOnBackdrop: false,
    body: ({ closeModal }) => {
      return (
        <div className={`p-4`}>
          <div className={`flex flex-row items-center justify-between`}>
            <div className={`text-lg`}>Confirmation</div>
            <div className={`flex gap-6`}>
              <Button
                className={`!w-28`}
                action="default"
                label="Cancel"
                onClick={closeModal}
              />
              <Button
                loading={loading}
                className={`flex !w-28 items-center`}
                label={loading ? "Deleting" : "Delete list"}
                onClick={handleDeleteList(closeModal)}
              />
            </div>
          </div>
          <div className="mt-8 text-sm font-normal">
            You are about to delete the selected lists. Are you sure?
          </div>
        </div>
      );
    },
  };

  return (
    <Modal {...modalProps}>
      <Button label="Delete Selected" disabled={ids?.length === 0} />
    </Modal>
  );
};

export default DeleteList;
