import { gql } from "@apollo/client";
import { Company as company, PhotoCredit } from "../fragments";

const FETCH_COMPANIES = props => gql`
  query FetchCompanies($input: CompanyFilterInput) {
    companies(input: $input) {
      count
      edges {
        ${company(props)}
      }
    }
  }
`;

const FETCH_PHOTO_CREDITS = gql`
  query FetchPhotoCredits {
    photoCredits {
      ${PhotoCredit}
    }
  }
`;

export { FETCH_COMPANIES, FETCH_PHOTO_CREDITS };
