import { FETCH_JOB_REQUESTS_V2 as fetchJobsV2 } from "../../../../../graphql/query/Job";
import { jobFilterStore } from "../../../components/useJobsFilters";

const SearchedJobs = props => {
  const tableProps = {
    dataKey: "jobsV2",
    FETCH_QUERY: fetchJobsV2({
      jobStatus: true,
      jobType: true,
      pickupGroup: true,
      user: true,
    }),
    limit: 50,
    variables: {
      ...jobFilterStore.get(),
    },
  };

  return tableProps;
};

export default SearchedJobs;
