import * as React from "react";
import { Table } from "../../../../../../components/Functional";
import Actions from "./components/Actions";
import { FETCH_CRM_REGISTRARS as fetchCRMRegistrars } from "../../../../../../graphql/query/CRMRegistrar";
import SectionHeader from "../../components/SectionHeader";
import { useQuery } from "@apollo/client";
import { FETCH_JOB_STATUSES } from "../../../../../../graphql/query/JobStatus";

const Handover = props => {
  const { contactId } = props || {};
  const { data: { jobStatuses = [] } = {} } = useQuery(FETCH_JOB_STATUSES);
  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "crmRegistrars",
    headers: [
      { label: "Date", name: "date", sortKey: "date", type: "date" },
      { label: "From", name: "salesUser.name", type: "label" },
      { label: "To", name: "user.name", type: "label" },
      { label: "Input By", name: "entity.name", type: "label" },
      { label: "Notes", name: "description", type: "label" },
      { label: "Status", name: "status.description", type: "label" },
      { label: "", name: "action", type: "custom", component: Actions },
    ],
    inputs: [
      {
        label: "Status",
        name: "statusId",
        type: "select",
        options: jobStatuses?.map(item => ({
          label: item.description,
          value: item.id,
        })),
      },
      { label: "Notes", name: "description", type: "text", view: "partial" },
      { label: "From date", name: "from", type: "date", view: "partial" },
      { label: "To date", name: "to", type: "date", view: "partial" },
    ],
    FETCH_QUERY: fetchCRMRegistrars({
      entity: {},
      salesUser: {},
      status: {},
      user: {},
    }),
    variables: {
      contactId,
      typeId: 25,
    },
    className: "sm:px-0 lg:px-0",
  };

  return (
    <div className="w-full">
      <SectionHeader title="Handover" />
      <div className="mb-6 w-full">
        <Table {...tableProps} />
      </div>
    </div>
  );
};

export default Handover;
