import { gql } from "@apollo/client";
import { AuthGroup } from "../fragments";

const FETCH_AUTH_GROUPS = gql`
  query AuthGroups {
    authGroups {
      ${AuthGroup}
  }
}
`;

export { FETCH_AUTH_GROUPS };
