import { getInput } from "../../../../../../../../../components/ui";

const InstanceInputRender = ({ inputs, formik }) => {
  const getInputWithProps = item => {
    return getInput({
      formik,
      ...item,
      ...formik?.getFieldProps(item.name),
      type: item.type || "text",
    });
  };

  return (
    <>
      {inputs?.map((item, index) => {
        const {
          checkbox,
          row,
          modalButton: ModalButton,
          ...remainingItem
        } = item;

        return (
          <div key={index} className={"flex w-full gap-4"}>
            <div className="w-5/6">
              {ModalButton ? (
                <div className={`relative`}>
                  {getInputWithProps(remainingItem)}
                  <div className={`absolute right-1 top-6`}>
                    <ModalButton />
                  </div>
                </div>
              ) : row?.length > 0 ? (
                <div className="flex flex-row items-end space-x-4">
                  {row?.map(rowItem => (
                    <div
                      key={`idr_row_${rowItem.name}`}
                      className={`w-1/${row.length}`}
                    >
                      {getInputWithProps(rowItem)}
                    </div>
                  ))}
                </div>
              ) : (
                <div className="w-full">{getInputWithProps(remainingItem)}</div>
              )}
            </div>
            <div className="flex w-1/6 flex-row items-center pt-5">
              {checkbox &&
                getInput({
                  formik,
                  ...checkbox,
                  ...formik?.getFieldProps(checkbox.name),
                  type: "toggle",
                  designType: checkbox.designType || "threeStateCheckbox",
                })}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default InstanceInputRender;
