import { useMemo, useState } from "react";
import { useMutation } from "@apollo/client";
import { XMarkIcon, PlusIcon } from "@heroicons/react/24/outline";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-dnd";

import MoveImage from "./components/MoveImage";
import DeleteImage from "./components/DeleteImage";
import { Button, IconButton, Modal } from "../../../ui";
import { UPDATE_IMAGE_ORDER as updateImageOrderMutation } from "../../../../graphql/mutation/Image";

const GRID_DND_ITEM_IN_ROW = 4;
const GRID_DND_ROW_HEIGHT = 240;

const ManageImage = props => {
  const {
    images: propsImages = [],
    setImageList,
    setSuccessOpModal,
    moveAble = true,
    deleteAble = true,
    orderUpdateAble = true,
  } = props;

  const [updateImageOrder, { loading: updateImageOrderLoading }] = useMutation(
    updateImageOrderMutation(),
  );

  const [localImages, setLocalImages] = useState(propsImages);
  const [selectedImages, setSelectedImages] = useState([]);

  const enableUpdateOrderButton = useMemo(() => {
    let enable = false;
    localImages?.forEach((item, index) => {
      if (!enable) {
        enable = item.order !== index + 1;
      }
    });
    return enable;
  }, [localImages]);

  const gridDnDRows = Math.ceil(localImages.length / GRID_DND_ITEM_IN_ROW);
  const girdDnDHeight = GRID_DND_ROW_HEIGHT * gridDnDRows;

  const resetLocalState = () => {
    setSelectedImages([]);
  };

  const handleSelectImage = archiveId => {
    !selectedImages.includes(archiveId) &&
      setSelectedImages([...selectedImages, archiveId]);
  };

  const handleUnselectImage = archiveId => {
    selectedImages.includes(archiveId) &&
      setSelectedImages(selectedImages.filter(id => id !== archiveId));
  };

  const removeUnLinkedImages = () => {
    setImageList?.(prevState =>
      prevState.filter(item => !selectedImages.includes(item.archiveId)),
    );
    setLocalImages(prevState =>
      prevState.filter(item => !selectedImages.includes(item.archiveId)),
    );
  };

  const onDeleteSuccess = () => {
    removeUnLinkedImages();
    setSuccessOpModal({
      isOpen: true,
      message: `${selectedImages.length} ${selectedImages.length > 1 ? "images" : "image"} deleted successfully.`,
    });
    resetLocalState();
  };

  const onChange = (_, sourceIndex, targetIndex) => {
    const nextState = swap(localImages, sourceIndex, targetIndex);
    setLocalImages(nextState);
  };

  const handleUpdateOrder = async e => {
    e.preventDefault();
    try {
      const res = await updateImageOrder({
        variables: {
          input: {
            newImageOrder: localImages.map((item, index) => ({
              artworkId: item.artworkId,
              archiveId: item.archiveId,
              order: index + 1,
            })),
          },
        },
      });
      const updatedImageOrder = res?.data?.updateImageOrder.images;
      if (res?.data?.updateImageOrder.success) {
        setImageList?.(updatedImageOrder);
        setLocalImages(updatedImageOrder);
      }
    } catch (err) {
      console.log("err: ", err);
    }
  };

  const onMoveSuccess = () => {
    removeUnLinkedImages();
    setSuccessOpModal({ isOpen: true, message: "Images has been moved." });
    resetLocalState();
  };

  const modalProps = {
    scale: "md",
    closeOnBackdrop: false,
    hideCloseButton: true,
    onOpen: () => {
      setLocalImages(propsImages);
    },
    onClose: resetLocalState,
    body: ({ closeModal }) => {
      return (
        <div className="flex w-full flex-col p-8">
          <div className="flex flex-row">
            <div className="flex flex-1 flex-col text-2xl font-bold">
              Manage Artwork Images
            </div>
            <div className="flex flex-row gap-4">
              <Button
                className={`!w-28`}
                action="default"
                label="Cancel"
                onClick={() => {
                  closeModal();
                }}
              />
              {orderUpdateAble && (
                <Button
                  className="flex !min-w-28 items-center"
                  label="Update Order"
                  loading={updateImageOrderLoading}
                  disabled={updateImageOrderLoading || !enableUpdateOrderButton}
                  onClick={handleUpdateOrder}
                />
              )}
              {moveAble && (
                <MoveImage
                  selectedImages={selectedImages}
                  artworkId={propsImages[0]?.artworkId}
                  onSuccess={onMoveSuccess}
                  mainCloseModal={closeModal}
                />
              )}
              {deleteAble && (
                <DeleteImage
                  selectedImages={selectedImages}
                  artworkId={propsImages[0]?.artworkId}
                  onSuccess={onDeleteSuccess}
                  mainCloseModal={closeModal}
                />
              )}
            </div>
          </div>

          <div className="mt-8">
            <div className="font-bold">Select Artwork Images</div>
            <div className="text-sm italic text-gray-400">
              Drag and Drop to Re-Order
            </div>
            <div className="h-[500px] overflow-y-auto">
              {localImages?.length > 0 ? (
                <GridContextProvider onChange={onChange}>
                  <GridDropZone
                    id="items"
                    boxesPerRow={GRID_DND_ITEM_IN_ROW}
                    rowHeight={GRID_DND_ROW_HEIGHT}
                    style={{ height: `${girdDnDHeight}px` }}
                  >
                    {localImages?.map((item, index) => (
                      <GridItem key={item.archiveId}>
                        <div className="mb-4 h-full w-full p-4">
                          <div className="group relative h-full border-2 bg-gray-100 hover:cursor-pointer hover:border-black hover:bg-gray-300">
                            <div
                              className="h-full bg-contain bg-center bg-no-repeat"
                              style={{
                                backgroundImage: `url("${item.downloadPath}")`,
                              }}
                            />
                            <div
                              className={`invisible
                                 ${selectedImages.includes(item.archiveId) && "!visible"}
                                  absolute left-0 top-0 flex h-full w-full justify-center bg-black/[.5] group-hover:visible`}
                            >
                              {selectedImages.includes(item.archiveId) ? (
                                <IconButton variant="clean" title="Deselect">
                                  <XMarkIcon
                                    className={`h-8 w-8 cursor-pointer rounded-lg bg-white p-2`}
                                    onClick={() =>
                                      handleUnselectImage(item.archiveId)
                                    }
                                  />
                                </IconButton>
                              ) : (
                                <IconButton variant="clean" title="Select">
                                  <PlusIcon
                                    className={`h-8 w-8 cursor-pointer rounded-lg bg-white p-2`}
                                    onClick={() =>
                                      handleSelectImage(item.archiveId)
                                    }
                                  />
                                </IconButton>
                              )}
                            </div>
                          </div>
                          <div className="mt-2 text-right text-sm">
                            {index + 1}
                          </div>
                        </div>
                      </GridItem>
                    ))}
                  </GridDropZone>
                </GridContextProvider>
              ) : (
                <div
                  className={`text-md flex h-full w-full items-center justify-center text-gray-400`}
                >
                  NO IMAGES TO SHOW
                </div>
              )}
            </div>
            {localImages.length > 0 && (
              <div className={`mt-5 w-full text-right text-gray-500`}>
                Selected {selectedImages.length} of {localImages.length} Images.
              </div>
            )}
          </div>
        </div>
      );
    },
  };

  return (
    <Modal {...modalProps}>
      <Button label="Manage Images" action="dark" className={"!w-32"} />
    </Modal>
  );
};

export default ManageImage;
