import { gql } from "@apollo/client";
import { ContactAttribute as contactAttribute } from "../fragments";

const CREATE_CONTACT_ATTRIBUTE = props => gql`
  mutation CreateContactAttribute($input: CreateContactAttributeInput!) {
    createContactAttribute(input: $input) {
      contactAttribute {
        ${contactAttribute(props)}
      }
      success
      error
    }
  }
`;

const DELETE_CONTACT_ATTRIBUTE = gql`
  mutation DeleteContactAttribute($input: DeleteContactAttributeInput!) {
    deleteContactAttribute(input: $input) {
      success
      error
    }
  }
`;

const UPDATE_CONTACT_ATTRIBUTE = props => gql`
  mutation UpdateContactAttribute($input: UpdateContactAttributeInput!) {
    updateContactAttribute(input: $input) {
      contactAttribute {
        ${contactAttribute(props)}
      }
      success
      error
    }
  }
`;

export {
  CREATE_CONTACT_ATTRIBUTE,
  DELETE_CONTACT_ATTRIBUTE,
  UPDATE_CONTACT_ATTRIBUTE,
};
