import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Button, Modal, IconButton } from "../../../../../../../components/ui";
import { DELETE_REQUEST } from "../../../../../../../graphql/mutation/Request";
import AddRequest from "./AddRequest";
import { useMutation } from "@apollo/client";
import useUser from "../../../../../../../hooks/useUser";

const Actions = props => {
  const { contact, values, id } = props || {};
  const [deleteRequest, { loading }] = useMutation(DELETE_REQUEST);
  const { hasPermission } = useUser();
  const disabled = !hasPermission("EDIT_REQUEST");

  const editModalProps = {
    body: AddRequest,
    contact,
    disabled,
    request: values?.rawFields,
    closeOnBackdrop: true,
    hideCloseButton: true,
    scale: "md",
  };

  const deleteModalProps = {
    disabled,
    title: "Are you sure?",
    scale: "sm",
    description: `Would you like to delete request id ${values?.id}?`,
    closeOnBackdrop: true,
    body: ({ closeModal }) => {
      const handleDelete = () => {
        deleteRequest({
          variables: {
            input: { id: +values?.rawFields?.id },
          },
          update: (cache, { data }) => {
            const { deleteRequest: { success } = {} } = data;
            if (success) {
              closeModal();
              cache.evict({
                id: cache.identify({
                  __typename: "Request",
                  id,
                }),
              });
            }
          },
        });
      };

      return (
        <div className="mt-4 flex">
          <Button
            className="mr-3"
            label={"Cancel"}
            action={"default"}
            onClick={() => {
              closeModal?.();
            }}
          />
          <Button
            label={loading ? "Deleting" : "Delete"}
            disabled={loading}
            onClick={handleDelete}
          />
        </div>
      );
    },
  };

  if (typeof id == "number") {
    return (
      <div className="user-list-actions flex text-right text-sm font-medium">
        {id && !disabled && (
          <div className="grid grid-cols-4 gap-4">
            <Modal {...editModalProps}>
              <IconButton variant="clean" title="Edit Request">
                <PencilIcon
                  className={`h-4 w-4 cursor-pointer text-gray-400`}
                />
              </IconButton>
            </Modal>
            {/* <IconButton variant="clean" title="View Request">
              <EyeIcon
                className={`h-4 w-4 cursor-pointer text-gray-400`}
                onClick={() => {}}
              />
            </IconButton> */}
            <Modal {...deleteModalProps}>
              <IconButton variant="clean" title="Delete Request">
                <TrashIcon className={`h-4 w-4 cursor-pointer text-gray-400`} />
              </IconButton>
            </Modal>
          </div>
        )}
      </div>
    );
  } else {
    return false;
  }
};

export default Actions;
