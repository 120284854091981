import SectionHeader from "../../components/SectionHeader";
import { Table } from "../../../../../../components/Functional";
import { FETCH_CRM_REGISTRARS_V2 as fetchCRMRegistrarsV2 } from "../../../../../../graphql/query/CRMRegistrar";

import Actions from "./components/Actions";
import { AddRegistrar } from "./components/AddRegistrar";
import ShippingContact from "./components/ShippingContact";

const disableConfig = {
  disabled: true,
  inputClassName: "!bg-white !text-gray-400 !border-gray-400",
};

const FormattedDate = props => {
  return new Date(props?.label).toLocaleDateString().replaceAll("/", "-");
};

const Registrar = props => {
  const { invoice, formik } = props || {};

  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "crmRegistrarsV2",
    headers: [
      { label: "Date", name: "date", type: "custom", component: FormattedDate },
      { label: "Type", name: "type.description", type: "label" },
      { label: "Sub Type", name: "subType.description", type: "label" },
      {
        label: "Description",
        name: "description",
        type: "label",
        widthInPx: 600,
      },
      { label: "User", name: "user.name", type: "label" },
      { label: "", name: "action", type: "custom", component: Actions },
    ],
    inputs: [],
    FETCH_QUERY: fetchCRMRegistrarsV2({ subType: {}, type: {}, user: {} }),
    variables: {
      categoryId: {
        operator: "eq",
        value: 1,
      },
      _invoice: {
        id: {
          operator: "eq",
          value: invoice?.id,
        },
      },
    },
  };

  const registrarActions = [
    {
      component: () => <AddRegistrar invoice={invoice} />,
    },
  ];

  return (
    <>
      <SectionHeader title="Registrar" actions={registrarActions} />
      <div className="w-full">
        <ShippingContact
          formik={formik}
          disableConfig={disableConfig}
          submitOnBlur={true}
        />
        <div className="mt-8 w-full">
          <Table {...tableProps} />
        </div>
      </div>
    </>
  );
};

export default Registrar;
