import shipmentFrag from "./Shipment";
import WarehouseLocation from "./WarehouseLocation";
import WarehouseSite from "./WarehouseSite";
import warehouseItemFrag from "./WarehouseItem";

const WarehouseMove = ({
  item = false,
  location = false,
  shipment = false,
  site = false,
} = {}) => `
  bondref
  date
  goodsCode
  id
  confirmed
  ${
    item
      ? `item {
      ${warehouseItemFrag(item)}
    }`
      : ""
  }
  itemId
  ${
    location
      ? `location {
      ${WarehouseLocation}
    }`
      : ""
  }
  locationId
  locref
  ${
    shipment
      ? `shipment {
      ${shipmentFrag(shipment)}
    }`
      : ""
  }
  shipmentId
  siteId
  ${
    site
      ? `site {
      ${WarehouseSite}
    }`
      : ""
  }
  ti
  taDays
  user
  value
`;

export default WarehouseMove;
