import { gql } from "@apollo/client";
import { FirstContact } from "../fragments";

const FETCH_FIRST_CONTACTS = gql`
  query FirstContacts {
    firstContacts {
      ${FirstContact}
    }
  }
`;

export { FETCH_FIRST_CONTACTS };
