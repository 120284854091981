import { useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import moment from "moment";
import { useEffect } from "react";
import * as Yup from "yup";

import { Button, getInput } from "../../../../../../../components/ui";
import { FETCH_CRM_CATEGORIES } from "../../../../../../../graphql/query/CRMCategory";
import { FETCH_CRM_TYPES } from "../../../../../../../graphql/query/CRMType";
import { FETCH_ENTITIES } from "../../../../../../../graphql/query/Entity";
import { FETCH_JOB_STATUSES } from "../../../../../../../graphql/query/JobStatus";
import {
  CREATE_CRM_REGISTRAR as createCRMRegistrarMutation,
  UPDATE_CRM_REGISTRAR as updateCRMRegistrarMutation,
} from "../../../../../../../graphql/mutation/CRMRegistrar";
import useUser from "../../../../../../../hooks/useUser";

const AddAction = props => {
  const { user } = useUser();
  const { closeModal, contact, crmRegistrar, isOpen } = props;
  const { data: { crmCategories = [] } = {} } = useQuery(FETCH_CRM_CATEGORIES);
  const { data: { crmTypes = [] } = {} } = useQuery(FETCH_CRM_TYPES);
  const { data: { entities: { edges = [] } = {} } = {} } =
    useQuery(FETCH_ENTITIES);
  const { data: { jobStatuses = [] } = {} } = useQuery(FETCH_JOB_STATUSES);
  const [createCRMRegistrar, { loading: createLoading }] = useMutation(
    createCRMRegistrarMutation(),
    { refetchQueries: ["FetchCRMRegistrars"] },
  );
  const [updateCRMRegistrar, { loading: updateLoading }] = useMutation(
    updateCRMRegistrarMutation(),
  );

  const loading = createLoading || updateLoading;

  const handleClick = () => {
    const { categoryId, date, notes, statusId, typeId, userId } = formik.values;

    (crmRegistrar
      ? updateCRMRegistrar({
          variables: {
            input: {
              id: crmRegistrar?.id,
              categoryId,
              date: moment(date).format("YYYY-MM-DD"),
              notes,
              statusId,
              typeId,
              userId,
            },
          },
        })
      : createCRMRegistrar({
          variables: {
            input: {
              categoryId,
              contactId: contact?.id,
              date,
              notes,
              statusId,
              typeId,
              userId,
            },
          },
        })
    ).then(resp => {
      const {
        data: {
          createCRMRegistrar: { success } = {},
          updateCRMRegistrar: { success: updateSuccess } = {},
        },
      } = resp || {};
      if (success || updateSuccess) {
        closeModal();
      }
    });
  };

  const leftInputs = [
    {
      label: "Status",
      name: "statusId",
      type: "multi-select",
      options: jobStatuses?.map(item => ({
        label: item?.description,
        value: item?.id,
      })),
    },
    {
      label: "Category",
      name: "categoryId",
      type: "multi-select",
      options: crmCategories?.map(item => ({
        label: item?.description,
        value: item?.id,
      })),
    },
    {
      label: "Action By",
      name: "userId",
      type: "multi-select",
      options: edges?.map(item => ({ label: item?.name, value: item?.id })),
    },
    {
      label: "Type",
      name: "typeId",
      type: "multi-select",
      options: crmTypes?.map(item => ({
        label: item.description,
        value: item.id,
      })),
    },
    { label: "Date", name: "date", type: "date" },
    { label: "Notes", name: "notes", type: "textarea" },
  ];

  const rightInputs = [
    { label: "Linked", name: "createdOn", type: "date", disabled: true },
    { label: "ID", name: "entityId", type: "text", disabled: true },
  ];

  const scheduleInputs = [
    { label: "Date", name: "scheduleDate", type: "date" },
    { label: "Start Time", name: "startTime", type: "time" },
    { label: "End Time", name: "endTime", type: "time" },
    {
      label: "Notify",
      name: "notify",
      type: "multi-select",
      options: [
        { label: "5 minutes before", value: 5 },
        { label: "10 minutes before", value: 10 },
        { label: "15 minutes before", value: 15 },
        { label: "30 minutes before", value: 30 },
        { label: "1 hour before", value: 60 },
        { label: "1 day before", value: 60 * 24 },
      ],
    },
    { label: "Reminder", name: "reminder", type: "checkbox" },
  ];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...crmRegistrar,
      userId: crmRegistrar?.userId || user?.entityId || undefined,
    },
    onSubmit: handleClick,
    validationSchema: Yup.object({
      userId: Yup.number().required("Please select an action by."),
      categoryId: Yup.number().required("Please select a category."),
      date: Yup.date().required("Please select a date."),
      statusId: Yup.number().required("Please select a status."),
      typeId: Yup.number().required("Please select a type."),
    }),
  });

  useEffect(() => {
    formik.resetForm();
  }, [isOpen]);

  return (
    <div className="flex w-full flex-col p-8">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          {crmRegistrar ? `Update` : `Record`} Action for {contact?.name}
        </div>
        <div className="flex flex-row">
          <Button
            action="default"
            className="mr-8"
            label="Cancel"
            onClick={() => {
              closeModal();
            }}
          />
          <Button
            label={
              loading
                ? crmRegistrar
                  ? "Updating"
                  : "Adding"
                : crmRegistrar
                  ? "Update Record"
                  : "Add Record"
            }
            disabled={loading}
            onClick={formik?.submitForm}
          />
        </div>
      </div>
      <div className="grid w-full grid-cols-2 gap-x-20 pb-10 pt-20">
        <div className="grid grid-cols-1 gap-y-4">
          {leftInputs?.map((item, index) => {
            const inputProps = {
              ...item,
              formik,
            };
            return (
              <div key={index} className={`w-full ${item?.className}`}>
                {getInput(inputProps)}
              </div>
            );
          })}
        </div>
        <div className="grid grid-cols-1 gap-y-4">
          <div className="grid grid-cols-1 gap-y-4">
            {rightInputs?.map((item, index) => {
              const inputProps = {
                ...item,
                formik,
              };
              return (
                <div key={index} className={`w-full ${item?.className}`}>
                  {getInput(inputProps)}
                </div>
              );
            })}
          </div>
          <div className="flex flex-col border border-black p-5">
            <div className="mb-4 text-xl font-bold">SCHEDULE</div>
            <div className="grid grid-cols-1 gap-y-4">
              {scheduleInputs?.map((item, index) => {
                const inputProps = {
                  ...item,
                  formik,
                  variant: "simpleRight",
                };
                return (
                  <div key={index} className={`w-full ${item?.className}`}>
                    {getInput(inputProps)}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAction;
