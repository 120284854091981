import { useQuery } from "@apollo/client";
import { GridInputs } from "../../../../../../../components/Functional";
import { FETCH_COUNTRIES } from "../../../../../../../graphql/query/Country";
import { FETCH_ENTITIES } from "../../../../../../../graphql/query/Entity";
import { shippingCostOptions } from "../../../../../../../util";
import useUser from "../../../../../../../hooks/useUser";

const ShippingContact = props => {
  const { formik, disableConfig, submitOnBlur = false } = props;
  const { hasPermission } = useUser();

  const { data: { countries = [] } = {} } = useQuery(FETCH_COUNTRIES);
  const { data: { entities = {} } = {} } = useQuery(FETCH_ENTITIES);

  const inputs = {
    className: "grid grid-cols-4 gap-4 mt-8",
    inputs: [
      {
        className: "grid-cols-1 gap-4",
        inputs: [
          {
            label: "Contact",
            name: "contactId",
            type: "contactSearch",
            submitOnBlur,
            ...(!hasPermission("UPDATE_REGISTRAR") ? disableConfig : {}),
          },
          {
            label: "Name",
            name: "deliveryName",
            type: "text",
            submitOnBlur,
            ...(!hasPermission("UPDATE_REGISTRAR") ? disableConfig : {}),
          },
          {
            label: "Company",
            name: "deliveryCompany",
            type: "text",
            submitOnBlur,
            ...(!hasPermission("UPDATE_REGISTRAR") ? disableConfig : {}),
          },
          {
            label: "Telephone",
            name: "deliveryPhone",
            type: "text",
            submitOnBlur,
            ...(!hasPermission("UPDATE_REGISTRAR") ? disableConfig : {}),
          },
          {
            label: "Email",
            name: "deliveryEmail",
            type: "text",
            submitOnBlur,
            ...(!hasPermission("UPDATE_REGISTRAR") ? disableConfig : {}),
          },
        ],
      },
      {
        className: "grid-cols-1 gap-4",
        inputs: [
          {
            label: "Address",
            name: "deliveryAddress",
            type: "text",
            submitOnBlur,
            ...(!hasPermission("UPDATE_REGISTRAR") ? disableConfig : {}),
          },
          {
            label: "Address 2",
            name: "deliveryAddress2",
            type: "text",
            submitOnBlur,
            ...(!hasPermission("UPDATE_REGISTRAR") ? disableConfig : {}),
          },
          {
            label: "Address 3",
            name: "deliveryAddress3",
            type: "text",
            submitOnBlur,
            ...(!hasPermission("UPDATE_REGISTRAR") ? disableConfig : {}),
          },
          {
            label: "Hold Delivery",
            name: "deliveryHold",
            type: "toggle",
            designType: "threeStateCheckbox",
            submitOnBlur,
            ...(!hasPermission("UPDATE_REGISTRAR") ? disableConfig : {}),
          },
        ],
      },
      {
        className: "grid-cols-1 gap-4",
        inputs: [
          {
            label: "Town",
            name: "deliveryTown",
            type: "text",
            submitOnBlur,
            ...(!hasPermission("UPDATE_REGISTRAR") ? disableConfig : {}),
          },
          {
            label: "County",
            name: "deliveryCounty",
            type: "text",
            submitOnBlur,
            ...(!hasPermission("UPDATE_REGISTRAR") ? disableConfig : {}),
          },
          {
            label: "Country",
            name: "deliveryCountryId",
            type: "select",
            options: countries?.map(item => ({
              label: item.name,
              value: item.id,
            })),
            submitOnBlur,
            ...(!hasPermission("UPDATE_REGISTRAR") ? disableConfig : {}),
          },
          {
            label: "Post Code",
            name: "deliveryPostcode",
            type: "text",
            submitOnBlur,
            ...(!hasPermission("UPDATE_REGISTRAR") ? disableConfig : {}),
          },
        ],
      },
      {
        className: "grid-cols-1 gap-4",
        inputs: [
          {
            label: "Registrar",
            name: "registrarId",
            options:
              entities?.edges?.map(item => {
                return { label: item.name, value: item.id };
              }) || [],
            type: "multi-select",
            submitOnBlur,
            ...(!hasPermission("UPDATE_REGISTRAR") ? disableConfig : {}),
          },
          {
            label: "Shipping Cost",
            name: "chargeStorage",
            type: "select",
            options: shippingCostOptions,
            submitOnBlur,
            ...(!hasPermission("UPDATE_REGISTRAR") ? disableConfig : {}),
          },
          {
            label: "Proof of export",
            name: "proofdate",
            type: "text",
            submitOnBlur,
            ...disableConfig,
          },
        ],
      },
    ],
  };

  const detailInputProps = {
    formik,
    ...inputs,
  };

  return (
    <>
      <h5 className="font-bold">Client&apos;s Shipping Contact</h5>
      <GridInputs {...detailInputProps} />
    </>
  );
};

export default ShippingContact;
