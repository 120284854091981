import {
  ChartPieIcon,
  ClockIcon,
  ChatBubbleLeftEllipsisIcon,
  DocumentTextIcon,
  EyeIcon,
  HomeIcon,
  TruckIcon,
} from "@heroicons/react/24/outline";

import Detail from "./pages/Detail";
import Costs from "./pages/Costs";
import TimeSheets from "./pages/TimeSheets";
import Condition from "./pages/Condition";
import Description from "./pages/Description";
import JobShipments from "./pages/Shipment";

const routes = [
  {
    href: "/jobs",
    icon: HomeIcon,
    name: "All Jobs",
  },
  {
    element: Detail,
    href: "details",
    icon: DocumentTextIcon,
    name: "Job Detail",
  },
  {
    element: Description,
    href: "description",
    icon: ChatBubbleLeftEllipsisIcon,
    name: "Description",
  },
  {
    element: TimeSheets,
    href: "timesheets",
    icon: ClockIcon,
    name: "Time Sheets",
  },
  {
    element: JobShipments,
    href: "shipments",
    icon: TruckIcon,
    name: "Shipments",
  },
  {
    element: Costs,
    href: "costs",
    icon: ChartPieIcon,
    name: "Costs",
  },
  {
    element: Condition,
    href: "condition",
    icon: EyeIcon,
    name: "Condition",
  },
];

export default routes;
