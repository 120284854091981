import { useMutation } from "@apollo/client";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";

import { Button, IconButton, Modal } from "../../../../../../../components/ui";
import AddEstimate from "./AddEstimate";

import { DELETE_FABRICATION } from "../../../../../../../graphql/mutation/Fabrication";
import useUser from "../../../../../../../hooks/useUser";

const Actions = props => {
  const { hasPermission } = useUser();
  const { id, values, consignment } = props || {};
  const [
    deleteFabrication,
    { data: { deleteFabrication: { error } = {} } = {}, loading, reset },
  ] = useMutation(DELETE_FABRICATION, {
    refetchQueries: ["FetchFabricationsV2"],
  });

  const editModalProps = {
    body: AddEstimate,
    hideCloseButton: true,
    scale: "md",
    values,
    consignment,
  };

  const deleteModalProps = {
    title: error ? "Error" : "Are you sure?",
    scale: "sm",
    description: error ? error : `Would you like to remove this estimate?`,
    closeOnBackdrop: true,
    disabled: !hasPermission("UPDATE_CONSIGNMENT"),
    body: ({ closeModal }) => {
      const handleRemove = () => {
        deleteFabrication({
          variables: {
            input: { id: values?.id },
          },
          update: (cache, { data }) => {
            const { deleteFabrication: { success } = {} } = data || {};
            if (success) {
              closeModal?.();
            }
          },
        });
      };
      return (
        <div className="mt-4 flex">
          <Button
            className="mr-3"
            label={error ? "Close" : "Cancel"}
            action={error ? "primary" : "default"}
            onClick={() => {
              reset();
              closeModal?.();
            }}
          />
          {!error && (
            <Button
              label={loading ? "Removing" : "Remove"}
              disabled={loading}
              onClick={handleRemove}
            />
          )}
        </div>
      );
    },
  };

  if (typeof id == "number") {
    return (
      <div className="user-list-actions flex text-right text-sm font-medium">
        {id && (
          <div className="grid grid-cols-2 gap-4">
            <Modal {...editModalProps}>
              <IconButton variant="clean" title="Edit Estimate">
                <PencilIcon
                  className={`h-4 w-4 cursor-pointer text-gray-400`}
                />
              </IconButton>
            </Modal>
            <Modal {...deleteModalProps}>
              <IconButton
                variant="clean"
                title="Delete Estimate"
                disabled={!hasPermission("UPDATE_CONSIGNMENT")}
              >
                <TrashIcon className={`h-4 w-4 cursor-pointer text-gray-400`} />
              </IconButton>
            </Modal>
          </div>
        )}
      </div>
    );
  } else {
    return false;
  }
};

export default Actions;
