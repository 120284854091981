import { useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import * as Yup from "yup";

import { GridInputs } from "../../../../../../../components/Functional";
import { Button, Modal } from "../../../../../../../components/ui";

import { CREATE_INVOICE_SALES_PEOPLE } from "../../../../../../../graphql/mutation/InvoiceSalesPerson";
import { FETCH_ENTITIES } from "../../../../../../../graphql/query/Entity";
import { useEffect } from "react";
import useUser from "../../../../../../../hooks/useUser";

const AddSalesPersonBody = props => {
  const { closeModal, invoice } = props || {};
  const [
    createInvoiceSalesPeople,
    { data: { createInvoiceSalesPeople: { error } = {} } = {}, loading, reset },
  ] = useMutation(CREATE_INVOICE_SALES_PEOPLE, {
    refetchQueries: ["FetchInvoice"],
  });
  const { data: { entities: { edges: entities = [] } = {} } = {} } =
    useQuery(FETCH_ENTITIES);
  const entityOptions =
    entities?.map(item => ({ label: item?.name, value: item?.id })) || [];

  const createDynamicValidation = fieldName => {
    return Yup.object().shape({
      ...(/^split_\d+$/.test(fieldName) && {
        [fieldName]: Yup.number()
          .required("Enter split ratio")
          .min(0, "Invalid Value")
          .max(100, "Invalid Value")
          .typeError("Invalid Value"),
      }),
      ...(/^salesUserId_\d+$/.test(fieldName) && {
        [fieldName]: Yup.number()
          .required("Select a sales user")
          .typeError("Select a sales user"),
      }),
    });
  };

  const dynamicValidation = formData => {
    let validationSchema = Yup.object({
      allocated: Yup.number().test(
        "allocation-validation",
        "Invalid allocation",
        function (allocated, context) {
          const remaining = context.parent.remaining;
          if (
            formData.salesPeople.length <= 0 &&
            allocated === 0 &&
            remaining === 100
          ) {
            // skip validating allocation when no sales people set
            return true;
          }
          if (allocated === 100) {
            return true;
          }
          return context.createError({
            message: "Allocated split ratio must be 100%",
          });
        },
      ),
      remaining: Yup.number().test(
        "allocation-validation",
        "Invalid allocation",
        function (remaining, context) {
          const allocated = context.parent.allocated;

          if (
            formData.salesPeople.length <= 0 &&
            allocated === 0 &&
            remaining === 100
          ) {
            // skip validating allocation when no sales people set
            return true;
          }

          if (allocated !== 100) {
            if (remaining !== 0) {
              return context.createError({
                message: "Split ratio must be properly allocated",
              });
            }
          }
          return true;
        },
      ),
    });

    // eslint-disable-next-line
    formData && typeof formData === "object"
      ? Object.keys(formData).forEach(fieldName => {
          validationSchema = validationSchema.concat(
            createDynamicValidation(fieldName),
          );
        })
      : validationSchema;

    return validationSchema;
  };

  const salesPeople = invoice?.invoiceSalesPersons?.length
    ? invoice?.invoiceSalesPersons.map((user, index) => ({
        [`salesUserId_${index + 1}`]: user.salesUserId,
        [`split_${index + 1}`]: user.split,
      }))
    : [];

  const formik = useFormik({
    initialValues: {
      allocated: 0,
      remaining: 100,
      salesPeople,
      ...(salesPeople.length
        ? salesPeople.reduce((item, obj) => {
            Object.assign(item, obj);
            return item;
          }, {})
        : {}),
    },
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: () => dynamicValidation(formik?.values),
    onSubmit: async values => {
      const finalValues = {
        invoiceId: invoice?.id,
        salesUsers: values?.salesPeople?.map((_, index) => ({
          salesUserId: values[`salesUserId_${index + 1}`],
          split: values[`split_${index + 1}`],
        })),
      };

      createInvoiceSalesPeople({
        variables: {
          input: finalValues,
        },
      }).then(resp => {
        const { data: { createInvoiceSalesPeople: { success } = {} } = {} } =
          resp || {};
        if (success) {
          reset();
          formik?.resetForm?.();
          closeModal?.();
        }
      });
    },
  });

  useEffect(() => {
    const totalSplitRatio = Object.keys(
      Object.assign({}, ...formik.values.salesPeople),
    )
      .filter(key => /^split_\d+$/.test(key))
      .reduce((acc, key) => acc + (+formik.values[key] || 0), 0);

    formik?.setFieldValue("allocated", totalSplitRatio);
    formik?.setFieldValue("remaining", 100 - totalSplitRatio);
  }, [formik.values, formik.values.salesPeople.length]);

  const inputConfigs = {
    salesUserId: {
      label: "Sales User",
      type: "multi-select",
      options: entityOptions,
      className: "col-span-4",
    },
    split: {
      label: "Split",
      type: "number-currency",
      allowNegativeInput: true,
    },
    action: { name: "salesPeople", type: "removeDynamicInputs" },
  };

  const filterEntities = keyName => {
    const eligibleSalesUserIds = Object.keys(formik?.values)
      .filter(key => key.startsWith("salesUserId_") && key !== keyName)
      .map(key => formik?.values?.[key]);
    const filteredEntities = entities?.filter(
      entity => !eligibleSalesUserIds.includes(entity?.id),
    );
    return (
      filteredEntities?.map(item => ({ label: item?.name, value: item?.id })) ||
      []
    );
  };

  const inputs = {
    className:
      "grid grid-cols-6 gap-4 mt-5 pb-4 border-b-[1px] max-h-[450px] overflow-y-auto pr-10",
    inputs: [
      ...formik.values.salesPeople.flatMap(salePerson =>
        Object.keys(salePerson).map(key => ({
          name: key,
          ...inputConfigs[key?.split("_")?.[0]],
          options: key.startsWith("salesUserId_") ? filterEntities(key) : [],
        })),
      ),
      inputConfigs.action,
    ],
  };

  const handleInsertSalesPerson = () => {
    const salesPeopleCount = formik?.values?.salesPeople?.length;
    const defaultSalesUser = {
      [`salesUserId_${salesPeopleCount + 1}`]: null,
      [`split_${salesPeopleCount + 1}`]: 0,
    };
    formik?.setFieldValue("salesPeople", [
      ...formik.values.salesPeople,
      defaultSalesUser,
    ]); // for gridlayout
    formik?.setFieldValue(`salesUserId_${salesPeopleCount + 1}`, null);
    formik?.setFieldValue(`split_${salesPeopleCount + 1}`, null);
  };

  const handleSplitRatioEqually = () => {
    const salesUsers = formik?.values?.salesPeople?.length;
    const splitRatio = Number(100 / salesUsers).toFixed(2);
    for (let index = 1; index <= salesUsers; index++) {
      formik?.setFieldValue(`split_${index}`, +splitRatio);
    }
  };

  const inputProps = {
    formik,
    ...inputs,
  };

  const disabled = !formik?.isValid || loading;

  const balanceInputs = {
    className: "grid grid-cols-2 gap-16 mt-5",
    inputs: [
      {
        disabled: true,
        label: "Allocated",
        name: "allocated",
        type: "text-currency",
      },
      {
        disabled: true,
        label: "Remaining",
        name: "remaining",
        type: "text-currency",
      },
    ],
  };

  const balanceInputProps = {
    formik,
    ...balanceInputs,
  };

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          <div>Edit Sales Person</div>
        </div>
        <div className="flex gap-4">
          <Button
            action="default"
            label="Cancel"
            onClick={() => {
              closeModal();
              reset();
              formik?.resetForm?.();
            }}
          />
          <Button
            label={"Insert Sales User"}
            disabled={loading}
            onClick={() => {
              handleInsertSalesPerson();
            }}
          />
          <Button
            label={"Save"}
            loading={loading}
            disabled={disabled}
            onClick={formik.submitForm}
          />
        </div>
      </div>
      <p>
        <span className="text-sm text-red-500">{error}</span>
      </p>
      {formik?.values?.salesPeople?.length ? (
        <div className="grid">
          <GridInputs {...inputProps} />
        </div>
      ) : (
        <div className="grid py-16">
          <center className="text-gray-400">Insert Sales User first</center>
        </div>
      )}
      <div className="grid grid-cols-6 gap-8">
        <div className="col-span-5">
          <GridInputs {...balanceInputProps} />
        </div>
        <div className="mt-10 text-center">
          <Button
            label="Split Equally"
            onClick={() => {
              handleSplitRatioEqually();
            }}
          />
        </div>
      </div>
    </div>
  );
};

const AddSalesPerson = props => {
  const { hasPermission } = useUser();
  const { invoice } = props || {};

  const modalProps = {
    body: AddSalesPersonBody,
    closeOnBackdrop: false,
    hideCloseButton: true,
    scale: "md",
    invoice,
    disabled: !hasPermission("UPDATE_INVOICE_SALES_PERSON"),
  };

  return (
    <Modal {...modalProps}>
      <Button
        label="Edit Sales Person"
        action="dark"
        disabled={!hasPermission("UPDATE_INVOICE_SALES_PERSON")}
      />
    </Modal>
  );
};

export default AddSalesPerson;
