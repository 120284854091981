import { gql } from "@apollo/client";
import { SupportArticle } from "../fragments";

const FETCH_SUPPORT_ARTICLES = gql`
  query FetchSupportArticles($input: SupportArticleFilterInput!) {
    supportArticles(input: $input) {
      count
      edges {
        ${SupportArticle}
      }
    }
  }
`;

const FETCH_SUPPORT_ARTICLE = gql`
  query FetchSupportArticle($id: Int!) {
    supportArticle(id: $id) {
      ${SupportArticle}
    }
  }
`;

export { FETCH_SUPPORT_ARTICLE, FETCH_SUPPORT_ARTICLES };
