import { Fragment } from "react";
import { getInput } from "../../../../../../../../../components/ui";
import useUser from "../../../../../../../../../hooks/useUser";
import DimensionsModal from "../../../../../../../../../components/Modals/DimensionsModal";

const ArtworkDetailCol2 = ({ disabled, formik, submitOnBlur = false }) => {
  const { hasPermission } = useUser();

  const inputs = [
    {
      disabled,
      label: "Dimensions",
      name: "dimensions",
      type: "dimensions",
      inputClassName: "pr-7",
      rows: 5,
      submitOnBlur,
    },
    {
      disabled: !hasPermission("UPDATE_ARTWORK_SERIES"),
      label: "Series",
      name: "series",
      type: "textarea",
      rows: 5,
      submitOnBlur,
    },
    {
      disabled: !hasPermission("UPDATE_ARTWORK_VIDEO_LINK"),
      label: "Video Link",
      name: "videoLink",
      type: "text",
      submitOnBlur,
    },
    { disabled, label: "Link", name: "photoLink", type: "text", submitOnBlur },
  ];

  return (
    <div className={`grid h-fit grid-cols-1 gap-4`}>
      {inputs?.map((item, index) => {
        const inputProps = {
          formik,
          ...item,
          type: item.type === "dimensions" ? "textarea" : item.type,
        };

        return item.type === "dimensions" ? (
          <div key={index} className={`relative`}>
            {getInput(inputProps)}
            <div className={`absolute right-1 top-6`}>
              <DimensionsModal
                formik={formik}
                submitOnBlur={submitOnBlur}
                fieldName="dimensions"
              />
            </div>
          </div>
        ) : (
          <Fragment key={index}>{getInput(inputProps)}</Fragment>
        );
      })}
    </div>
  );
};

export default ArtworkDetailCol2;
