import { gql } from "@apollo/client";
import { Shipment as shipment } from "../fragments";
import { Job as job } from "../fragments";

const ADD_SHIPMENT_ITEMS = props => gql`
  mutation AddShipmentItems($input: AddShipmentItemsInput!) {
    addShipmentItems(input: $input) {
      shipment{
        ${shipment(props)}
      }
      success
      error
    }
  }
`;

const CREATE_SHIPMENT_JOB = props => gql`
  mutation CreateShipmentJob($id: Int!) {
    createShipmentJob(id: $id) {
      job{
        ${job(props)}
      }
      success
      error
    }
  }
`;

const CONFIRM_SHIPMENT_ITEMS = props => gql`
  mutation ConfirmShipmentItems($input: ConfirmShipmentItemsInput!) {
    confirmShipmentItems(input: $input) {
      shipment{
        ${shipment(props)}
      }
      success
      error
    }
  }
`;

const CREATE_SHIPMENT = props => gql`
  mutation CreateShipment($input: CreateShipmentInput!) {
    createShipment(input: $input) {
      shipment{
        ${shipment(props)}
      }
      success
      error
    }
  }
`;

const CREATE_SHIPMENT_RETURN = props => gql`
  mutation CreateShipmentReturn($id: Int!) {
    createShipmentReturn(id: $id) {
      newShipmentId
      success
      error
    }
  }
`;

const CREATE_SHIPMENT_ONWARD = props => gql`
  mutation CreateShipmentOnward($input: CreateShipmentOnwardInput!) {
    createShipmentOnward(input: $input) {
      shipment{
        ${shipment(props)}
      }
      success
      error
    }
  }
`;

const REMOVE_SHIPMENT_ITEM = props => gql`
  mutation RemoveShipmentWarehouseItem($id: Int!) {
    removeShipmentWarehouseItem(id: $id) {
      shipment{
        ${shipment(props)}
      }
      success
      error
    }
  }
`;

const REMOVE_SHIPMENT_ITEMS = props => gql`
  mutation RemoveShipmentWarehouseItems($id: Int!) {
    removeShipmentWarehouseItems(id: $id) {
      shipment{
        ${shipment(props)}
      }
      success
      error
    }
  }
`;

const UPDATE_SHIPMENT = props => gql`
  mutation UpdateShipment($input: UpdateShipmentInput!) {
    updateShipment(input: $input) {
      shipment{
        ${shipment(props)}
      }
      success
      error
    }
  }
`;

const ADD_SHIPMENT_WORK = props => gql`
  mutation AddShipmentWork($input: AddShipmentWorkInput!) {
    addShipmentWork(input: $input) {
      shipment{
        ${shipment(props)}
      }
      success
      error
    }
  }
`;

const EXPORT_ADHOC_REPORT_SHIPMENTS = gql`
  mutation ExportAdhocReportShipments($input: ExportShipmentsInput!) {
    exportAdhocReportShipments(input: $input) {
      error
      success
      url
    }
  }
`;

export {
  ADD_SHIPMENT_ITEMS,
  CREATE_SHIPMENT_JOB,
  CREATE_SHIPMENT_RETURN,
  CREATE_SHIPMENT,
  CREATE_SHIPMENT_ONWARD,
  CONFIRM_SHIPMENT_ITEMS,
  REMOVE_SHIPMENT_ITEM,
  REMOVE_SHIPMENT_ITEMS,
  UPDATE_SHIPMENT,
  ADD_SHIPMENT_WORK,
  EXPORT_ADHOC_REPORT_SHIPMENTS,
};
