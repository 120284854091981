import { useEffect, useState } from "react";
import { Button, Modal } from "../ui";

const GraphQLErrorModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    const workErrorModal = window.document.querySelectorAll(".work-error");

    window.addEventListener(
      "Error:GraphQLError",
      function (e) {
        setMessage(e.detail?.message);
        workErrorModal ? setIsOpen(false) : setIsOpen(true);
      },
      false,
    );
  }, []);

  const modalProps = {
    body: ({ closeModal }) => {
      return (
        <div className="mt-4 flex">
          <Button
            label="Close"
            onClick={() => {
              closeModal?.();
            }}
          />
        </div>
      );
    },
    closeOnBackdrop: false,
    description: "Please contact your administrator",
    isOpen,
    onClose: () => {
      setIsOpen(false);
      setMessage("");
    },
    scale: "sm",
    title: message,
  };

  return <Modal {...modalProps} />;
};

export default GraphQLErrorModal;
