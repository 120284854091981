import { useMutation } from "@apollo/client";
import { XMarkIcon } from "@heroicons/react/24/outline";

import { Button, IconButton, Modal } from "../../../../../../../components/ui";
import { DELETE_CONSIGNMENT_WORK } from "../../../../../../../graphql/mutation/ConsignmentWork";
import useUser from "../../../../../../../hooks/useUser";

const Actions = props => {
  const { id } = props || {};
  const [deleteConsignmentWork, { loading, error }] = useMutation(
    DELETE_CONSIGNMENT_WORK,
    { refetchQueries: ["FetchConsignment"] },
  );
  const { hasPermission } = useUser();

  const modalProps = {
    title: error ? "Error deleting consignment work" : "Are you sure?",
    scale: "sm",
    description: error
      ? error?.message
      : `Would you like to delete this consignment work?`,
    closeOnBackdrop: true,
    disabled: !hasPermission("ADD_CONSIGNMENT_ITEM"),
    body: ({ closeModal }) => {
      const handleDelete = () => {
        deleteConsignmentWork({
          variables: {
            input: { id },
          },
          update: (cache, { data }) => {
            const { deleteConsignmentWork: { success } = {} } = data;
            if (success) {
              closeModal();
              cache.evict({
                id: cache.identify({
                  __typename: "ConsignmentWork",
                  id,
                }),
              });
            }
          },
        });
      };

      return (
        <div className="mt-4 flex">
          <Button
            className="mr-3"
            label={"Cancel"}
            action={"default"}
            onClick={() => {
              closeModal?.();
            }}
          />
          <Button
            label={loading ? "Deleting" : "Delete"}
            disabled={loading}
            onClick={handleDelete}
          />
        </div>
      );
    },
  };

  if (typeof id == "number") {
    return (
      <div className="user-list-actions flex text-right text-sm font-medium">
        {id && (
          <div className="grid">
            <Modal {...modalProps}>
              <IconButton
                variant="clean"
                title="Delete Work"
                disabled={!hasPermission("ADD_CONSIGNMENT_ITEM")}
              >
                <XMarkIcon className={`h-4 w-4 cursor-pointer text-gray-400`} />
              </IconButton>
            </Modal>
          </div>
        )}
      </div>
    );
  } else {
    return false;
  }
};

export default Actions;
