import { gql } from "@apollo/client";
import { Format } from "../fragments";

const FETCH_FORMATS = gql`
  query Formats {
    formats {
      ${Format}
    }
  }
`;
export { FETCH_FORMATS };
