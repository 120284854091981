import { useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";

import { Button } from "../../../../../../../components/ui";
import { GridInputs } from "../../../../../../../components/Functional";

import { FETCH_FABRICATION_TYPES } from "../../../../../../../graphql/query/FabricationType";
import { FETCH_INSTANCES_V2 as fetchInstancesV2 } from "../../../../../../../graphql/query/Instance";
import { FETCH_CURRENCIES } from "../../../../../../../graphql/query/Currency";
import {
  CREATE_FABRICATION as createFabricationMutation,
  UPDATE_FABRICATION as updateFabricationMutation,
} from "../../../../../../../graphql/mutation/Fabrication";
import {
  currentLocaleISODate,
  getChangedFieldsV2,
  isEqual,
} from "../../../../../../../util";

const AddFabrication = props => {
  const { artworkId, closeModal, values, isOpen } = props;

  const { data: { fabricationTypes = [] } = {} } = useQuery(
    FETCH_FABRICATION_TYPES,
  );
  const { data: { currencies = [] } = {} } = useQuery(FETCH_CURRENCIES);
  const { data: { instancesV2 = [] } = {} } = useQuery(fetchInstancesV2(), {
    variables: {
      input: {
        limit: null,
        _edition: {
          _artwork: {
            id: { operator: "eq", value: artworkId },
          },
        },
      },
    },
    skip: !isOpen,
  });

  const [createFabrication, { loading: loadingCreate }] = useMutation(
    createFabricationMutation({ currency: {}, type: {} }),
    { refetchQueries: ["FetchFabricationsV2"] },
  );

  const [updateFabrication, { loading: loadingUpdate }] = useMutation(
    updateFabricationMutation({ currency: {}, type: {} }),
    { refetchQueries: ["FetchFabricationsV2"] },
  );

  const addFabrication = () => {
    const input = {
      amount: formik.values.amount,
      currencyId: formik.values.currencyId,
      date: formik.values.date,
      description: formik.values.description,
      instanceId: formik.values.instanceId,
      invoice: formik?.values?.invoice?.toString(),
      po: formik.values.po,
      rechargerate: formik.values.rechargerate || null,
      recharge: formik.values.recharge || null,
      tax: formik.values.tax || null,
      typeId: formik.values.typeId,
    };

    if (values?.id) {
      input.id = values?.id;
    } else {
      input.artworkId = artworkId;
    }

    const action = values?.id ? updateFabrication : createFabrication;

    action({
      variables: {
        input,
      },
    }).then(() => {
      closeModal();
      formik.resetForm();
    });
  };

  const [prevValues, setPrevValues] = useState(values);
  const formik = useFormik({
    initialValues: values
      ? {
          amount: values.amount,
          currencyId: values.currencyId,
          date: values.date,
          description: values.description,
          instanceId: values.instanceId,
          invoice: values.invoice,
          po: values.po,
          rechargerate: values.rechargerate,
          recharge: values.recharge,
          tax: values.tax,
          typeId: values.typeId,
        }
      : {
          date: currentLocaleISODate(),
        },
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: Yup.object({
      amount: Yup.number().required("required").typeError("Invalid input"),
      currencyId: Yup.number().required("required").typeError("required"),
      date: Yup.date().required("required"),
      instanceId: Yup.number().required("required").typeError("required"),
      // invoice: Yup.number().required("required"),
      po: Yup.string().trim().required("required").typeError("required"),
      recharge: Yup.number().nullable().typeError("Invalid input"),
      rechargerate: Yup.number()
        .min(0)
        .max(100)
        .nullable()
        .typeError("Invalid input"),
      typeId: Yup.number().required("required").typeError("required"),
      tax: Yup.number().nullable().typeError("Invalid input"),
    }),
    onSubmit: addFabrication,
  });

  useEffect(() => {
    const { amount, recharge, rechargerate } = getChangedFieldsV2(
      formik?.values,
      prevValues,
    );

    if (
      (amount && isNaN(amount)) ||
      (recharge && isNaN(recharge)) ||
      (rechargerate && isNaN(rechargerate))
    ) {
      return;
    }

    const newValues = { ...formik.values };

    if (amount) {
      if (formik?.values?.rechargerate) {
        newValues.recharge = (formik.values.rechargerate / 100) * amount;
      } else if (formik?.values?.recharge) {
        newValues.rechargerate = (formik.values.recharge / amount) * 100;
      }
    }

    if (recharge) {
      if (formik?.values?.amount) {
        newValues.rechargerate = (recharge / formik.values.amount) * 100;
      } else if (formik?.values?.rechargerate) {
        newValues.amount = recharge / (formik.values.rechargerate / 100);
      }
    }

    if (rechargerate) {
      if (formik?.values?.amount) {
        newValues.recharge = (rechargerate / 100) * formik.values.amount;
      } else if (formik?.values?.recharge) {
        newValues.amount = formik.values.recharge / (rechargerate / 100);
      }
    }

    if (!isEqual(formik.values, newValues)) {
      formik.setValues(newValues);
      setPrevValues(newValues);
    }
  }, [formik.values]);

  const inputs = {
    className: "grid grid-cols-2 gap-12",
    inputs: [
      {
        className: "grid grid-cols-1 gap-4",
        inputs: [
          { label: "Date", name: "date", type: "date" },
          {
            label: "Type",
            name: "typeId",
            type: "multi-select",
            options: fabricationTypes?.map(item => ({
              label: item?.description,
              value: item?.id,
            })),
          },
          { label: "Order #", name: "po", type: "text" },
          { label: "Invoice #", name: "invoice", type: "text" },
          {
            label: "Description",
            name: "description",
            rows: 5,
            type: "textarea",
          },
        ],
      },
      {
        className: "grid grid-cols-1 gap-4",
        inputs: [
          {
            label: "Currency",
            name: "currencyId",
            type: "multi-select",
            options: currencies?.map(currency => ({
              label: `${currency?.description} (${currency?.code})`,
              value: currency?.id,
            })),
          },
          { label: "Net Amount", name: "amount", type: "number-currency" },
          { label: "Tax", name: "tax", type: "number-currency" },
          { label: "%", name: "rechargerate", type: "number-currency" },
          { label: "Recharge", name: "recharge", type: "number-currency" },
          {
            label: "Allocation",
            name: "instanceId",
            options:
              instancesV2?.edges?.map(item => ({
                label: item?.name,
                value: item?.id,
              })) || [],
            type: "multi-select",
          },
        ],
      },
    ],
  };

  const inputProps = {
    ...inputs,
    formik,
  };

  const successLabel = values?.id ? "Edit" : "Add";

  useEffect(() => {
    formik.handleReset();
  }, [isOpen]);

  return (
    <div className="flex w-full flex-col p-8">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          {values?.id ? "Edit" : "Add"} Fabrication Record
        </div>
        <div className="flex flex-col">
          <div>
            <Button
              action="default"
              className="mr-8"
              label="Cancel"
              onClick={closeModal}
            />
            <Button
              label={`${successLabel}${
                loadingUpdate || loadingCreate ? "ing" : ""
              } Fabrication`}
              disabled={loadingUpdate || loadingCreate || !formik?.isValid}
              onClick={formik.submitForm}
            />
          </div>
        </div>
      </div>
      <div className="mt-12 pb-10">
        <GridInputs {...inputProps} />
      </div>
    </div>
  );
};

export default AddFabrication;
