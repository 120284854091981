import { useMutation } from "@apollo/client";
import { XMarkIcon } from "@heroicons/react/24/outline";

import { Button, IconButton, Modal } from "../../../../../../../components/ui";
import { REMOVE_WAREHOUSE_ITEM_CONTAINER as removeWarehouseItemContainerMutation } from "../../../../../../../graphql/mutation/WarehouseItem";

const Actions = props => {
  const { id } = props || {};

  const [
    removeWarehouseItemContainer,
    {
      data: {
        removeWarehouseItemContainer: {
          error: removeWarehouseItemContainerError,
        } = {},
      } = {},
      loading,
      reset,
    },
  ] = useMutation(removeWarehouseItemContainerMutation(), {
    refetchQueries: ["FetchWarehouseItemsV2"],
  });

  const modalProps = {
    title: removeWarehouseItemContainerError ? "Error" : "Are you sure?",
    scale: "sm",
    description: removeWarehouseItemContainerError
      ? removeWarehouseItemContainerError
      : `Would you like to remove this content?`,
    closeOnBackdrop: true,
    body: ({ closeModal }) => {
      const handleRemove = () => {
        removeWarehouseItemContainer({
          variables: {
            input: { id },
          },
        }).then(({ data }) => {
          const { removeWarehouseItemContainer: { success } = {} } = data;
          if (success) {
            closeModal();
            reset();
          }
        });
      };

      return (
        <div className="mt-4 flex">
          <Button
            className="mr-3"
            label={removeWarehouseItemContainerError ? "Close" : "Cancel"}
            action={removeWarehouseItemContainerError ? "primary" : "default"}
            onClick={() => {
              closeModal?.();
              reset();
            }}
          />
          {!removeWarehouseItemContainerError && (
            <Button
              label={loading ? "Removing" : "Remove"}
              disabled={loading}
              onClick={handleRemove}
            />
          )}
        </div>
      );
    },
  };

  if (typeof id == "number") {
    return (
      <div className="manage-roles-list-actions flex text-right text-sm font-medium">
        {id && (
          <div className="grid grid-cols-4 gap-4">
            <Modal {...modalProps}>
              <IconButton variant="clean" title="Remove from Container">
                <XMarkIcon className={`h-4 w-4 cursor-pointer text-gray-400`} />
              </IconButton>
            </Modal>
          </div>
        )}
      </div>
    );
  } else {
    return false;
  }
};

export default Actions;
