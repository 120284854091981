import { useMutation } from "@apollo/client";

import { Button, Modal } from "../../ui";
import { DELETE_LIST_ITEMS } from "../../../graphql/mutation/ListItems";

const ClearList = props => {
  const { listId, onSuccess } = props || {};
  const [deleteListItems, { loading }] = useMutation(DELETE_LIST_ITEMS, {
    refetchQueries: ["FetchListItems"],
  });

  const handleClearList = closeModal => async e => {
    e.preventDefault();
    await deleteListItems({
      variables: {
        input: {
          listId,
        },
      },
      update: async (cache, { data }) => {
        const { deleteListItems: { success } = {} } = data;
        if (success) {
          onSuccess?.();
          closeModal?.();
        }
      },
    });
  };

  const modalProps = {
    title: "",
    scale: "sm",
    hideCloseButton: true,
    closeOnBackdrop: false,
    body: ({ closeModal }) => {
      return (
        <div className={`p-4`}>
          <div className={`flex flex-row items-center justify-between`}>
            <div className={`text-lg`}>Confirmation</div>
            <div className={`flex gap-4`}>
              <Button
                className={`!w-22`}
                action="default"
                label="Cancel"
                onClick={closeModal}
              />
              <Button
                loading={loading}
                className={`!w-22 flex items-center`}
                label={loading ? "Clearing..." : "Clear"}
                onClick={handleClearList(closeModal)}
              />
            </div>
          </div>
          <div className="mt-8 text-sm font-normal">
            The current working select list will be cleared. <b>Confirm?</b>
          </div>
        </div>
      );
    },
  };

  return (
    <Modal {...modalProps}>
      <Button label="Clear Select List" />
    </Modal>
  );
};

export default ClearList;
