import ShipmentDetail from "./components/ShipmentDetail";
import SectionHeader from "../../components/SectionHeader";

const ShipmentDetails = props => {
  const { shipment, shipmentHasConfirmedItems, formik } = props || {};

  return (
    <div className="w-full">
      <SectionHeader title={`Shipment #${shipment?.id}`} />
      <div className="w-full px-8">
        <div className="flex flex-col space-x-16 md:flex-row">
          <div className="flex w-full flex-col md:flex-row">
            <ShipmentDetail
              formik={formik}
              submitOnBlur={true}
              shipment={shipment}
              shipmentHasConfirmedItems={shipmentHasConfirmedItems}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShipmentDetails;
