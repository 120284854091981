import { useState } from "react";

import { Button, Modal } from "../../ui";

const CopyClipboard = props => {
  // eslint-disable-next-line
  const { currentSlide, images } = props;
  const [copied, setCopied] = useState(false);

  const img = new Image();
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const writeToCanvas = src => {
    return new Promise(res => {
      fetch(src)
        .then(res => res.blob())
        .then(imgBlob => {
          const imgURL = URL.createObjectURL(imgBlob);
          img.src = imgURL;
        })
        .then(() => {
          img.onload = function () {
            canvas.width = img.naturalWidth;
            canvas.height = img.naturalHeight;
            ctx.drawImage(img, 0, 0);
            canvas.toBlob(blob => {
              res(blob);
            }, "image/png");
          };
        });
    });
  };

  const handleCopyToClipboard = async () => {
    // eslint-disable-next-line
    const image = await writeToCanvas(images?.[currentSlide]?.xDownloadPath);
    try {
      await navigator.clipboard.write([
        new ClipboardItem({
          [image.type]: image,
        }),
      ]);

      setCopied(true);
    } catch (e) {
      console.log("Copy failed: " + e);
    }
  };

  const modalProps = {
    title: copied
      ? "Image has been copied to your clipboard"
      : "Please wait ...",
    scale: "sm",
    closeOnBackdrop: false,
    onClose: () => {
      setCopied(false);
    },
    body: ({ closeModal }) => {
      return (
        <div className="mt-4 flex">
          <Button
            className={"flex-between flex"}
            label={!copied ? "Processing" : "Close"}
            disabled={!copied}
            loading={!copied}
            onClick={() => {
              closeModal?.();
            }}
          />
        </div>
      );
    },
  };

  return (
    <Modal {...modalProps}>
      <Button
        label="Copy (Clipboard)"
        action="dark"
        onClick={handleCopyToClipboard}
        className={"!w-32"}
      />
    </Modal>
  );
};

export default CopyClipboard;
