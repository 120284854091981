import {
  HomeIcon,
  LinkIcon,
  ArchiveBoxIcon,
  UserPlusIcon,
  FlagIcon,
  CalendarIcon,
  PaperClipIcon,
  DocumentPlusIcon,
  DocumentTextIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";

import BankDetails from "./pages/Bank";
import InvoiceDetails from "./pages/Details";
import PaymentPlan from "./pages/PaymentPlan";
import InvoiceInformation from "./pages/Information";
import Registrar from "./pages/Registrar";
import AdvisorSale from "./pages/AdvisorSale";
import Terms from "./pages/Terms";
import InvoiceDocuments from "./pages/Document";
import Debtors from "./pages/Debtors";

const routes = [
  {
    href: "/invoice",
    icon: HomeIcon,
    name: "All Invoices",
  },
  {
    element: InvoiceDetails,
    href: "details",
    icon: DocumentTextIcon,
    name: "Invoice Detail",
  },
  {
    element: InvoiceInformation,
    href: "information",
    icon: InformationCircleIcon,
    name: "Invoice Information",
  },
  {
    element: BankDetails,
    href: "bank",
    icon: LinkIcon,
    name: "Bank Details",
  },
  {
    element: PaymentPlan,
    href: "payment-plan",
    icon: CalendarIcon,
    name: "Payment Plan",
  },
  {
    element: Registrar,
    href: "registrar",
    icon: ArchiveBoxIcon,
    name: "Registrar",
  },
  {
    element: Debtors,
    href: "debtors",
    icon: FlagIcon,
    name: "Debtors",
  },
  {
    element: AdvisorSale,
    href: "advisor-sale",
    icon: UserPlusIcon,
    name: "Advisor Sale",
  },
  {
    element: Terms,
    href: "terms",
    icon: DocumentPlusIcon,
    name: "Terms",
  },
  {
    element: InvoiceDocuments,
    href: "documents",
    icon: PaperClipIcon,
    name: "Documents",
  },
];

export default routes;
