import { Checkbox } from "../../ui/Popovers";
import { getInput } from "../../ui";
import { getValue } from "../../../util";

const multiSelect = props => {
  const { formik, keyName = "id", label, name } = props || {};

  const SelectAll = props => {
    const { rows, showAll } = props || {};
    const checkboxProps = {
      formik,
      label,
      name,
      rows,
      showAll,
    };

    return <Checkbox {...checkboxProps} />;
  };

  const toggleProps = props => {
    const id = getValue(props, keyName);
    const toggleName = `${name}.ids._${id}`;

    return {
      designType: "checkbox",
      name: toggleName,
      type: "toggle",
      ...formik.getFieldProps(toggleName),
      onChange: value =>
        formik
          .getFieldHelpers(toggleName)
          .setValue(value ? props?.values : undefined),
    };
  };

  return {
    component: props => getInput(toggleProps(props)),
    label: SelectAll,
    type: "custom",
  };
};

export { multiSelect };
