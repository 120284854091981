const ShipmentDocument = `
  id
  fileName
  fileType
  shipmentId
  updatedAt
  url
  viewUrl
`;

export default ShipmentDocument;
