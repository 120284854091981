import { useNavigate } from "react-router-dom";
import { Button } from "../../../../../../components/ui/Button";
import { Table } from "../../../../../../components/Functional";
import { FETCH_SUPPORT_ARTICLES } from "../../../../../../graphql/query/SupportArticle";
import useUser from "../../../../../../hooks/useUser";
import Actions from "./components/Actions";

import Header from "../../../../components/HeaderComponent";

const Support = () => {
  const { hasPermission } = useUser();
  const navigate = useNavigate();
  const newArticle = () => {
    navigate("/admin/support/new");
  };

  const tableProps = {
    dataKey: "supportArticles",
    headers: [
      {
        label: "Title",
        name: "meta.title",
        type: "label",
        onClick: props => {
          navigate(`${props?.formik?.values?.id}/edit`);
        },
      },
      { label: "Module", name: "meta.module", type: "label" },
      { label: "Last Update", name: "updatedAt", type: "date" },
      { label: "", name: "action", type: "custom", component: Actions },
    ],
    inputs: [
      { label: "Title", name: "title", type: "text", view: "partial" },
      { label: "Module", name: "module", type: "text", view: "partial" },
    ],
    FETCH_QUERY: FETCH_SUPPORT_ARTICLES,
    className: "sm:px-0 lg:px-0",
  };
  return (
    <div className="w-full">
      <Header
        leftText={"Support Articles"}
        rightComponent={() =>
          hasPermission("CREATE_SUPPORT_ARTICLE") && (
            <Button action="black" label="New Article" onClick={newArticle} />
          )
        }
      />
      <div className="flex flex-row pb-10">
        <Table {...tableProps} />
      </div>
    </div>
  );
};

export default Support;
