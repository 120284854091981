import { useState } from "react";
import { useMutation } from "@apollo/client";

import { Button, Modal } from "../../../../ui";
import { DELETE_IMAGES } from "../../../../../graphql/mutation/Image";

const DeleteImage = props => {
  const { selectedImages, artworkId, onSuccess, mainCloseModal, onError } =
    props;

  const [deleteImages, { loading: deleteLoading }] = useMutation(DELETE_IMAGES);

  const [errorMessage, setErrorMessage] = useState("");

  const handleDeleteImage = async e => {
    e.preventDefault();
    if (selectedImages.length > 0) {
      const res = await deleteImages({
        variables: {
          input: {
            archiveIds: selectedImages,
            artworkId: artworkId,
          },
        },
      });

      if (res?.data?.deleteImages?.success) {
        mainCloseModal();
        onSuccess && onSuccess();
      } else {
        setErrorMessage("Error while deleting image.");
        onError && onError();
      }
    }
  };

  const deleteModalProps = {
    scale: "md",
    closeOnBackdrop: false,
    hideCloseButton: true,
    disabled: selectedImages.length <= 0,
    body: ({ closeModal }) => {
      return (
        <div className="flex w-full flex-col p-8">
          <div className="flex flex-row">
            <div className="flex flex-1 flex-row text-2xl font-bold">
              Delete Artwork Image
            </div>
            <div className="flex flex-row gap-4">
              <Button
                action="default"
                label="Cancel"
                onClick={() => {
                  closeModal();
                }}
              />
              <Button
                className="flex items-center"
                label={deleteLoading ? "Deleting..." : "Delete"}
                disabled={deleteLoading}
                loading={deleteLoading}
                onClick={handleDeleteImage}
              />
            </div>
          </div>
          <div className="mt-8 w-full text-sm font-bold">
            You are about to delete {selectedImages?.length} images.
            {errorMessage && (
              <div className="mt-3 text-right text-sm text-red-500	">
                {errorMessage}
              </div>
            )}
          </div>
        </div>
      );
    },
  };

  return (
    <Modal {...deleteModalProps}>
      <Button
        className={`!w-28`}
        label="Delete"
        disabled={selectedImages.length <= 0}
      />
    </Modal>
  );
};

export default DeleteImage;
