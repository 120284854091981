import { gql } from "@apollo/client";
import { WarehouseItem as warehouseItem } from "../fragments";

const ADD_WAREHOUSE_ITEM_CONTAINER = props => gql`
  mutation AddWarehouseItemContainer($input: AddWarehouseItemContainerInput!) {
    addWarehouseItemContainer(input: $input) {
      success
      error
      warehouseItem {
        ${warehouseItem(props)}
      }
    }
  }
`;

const CREATE_WAREHOUSE_ITEM = props => gql`
  mutation CreateWarehouseItem($input: CreateWarehouseItemInput!) {
    createWarehouseItem(input: $input) {
      success
      error
      warehouseItem {
        ${warehouseItem(props)}
      }
    }
  }
`;

const DELETE_WAREHOUSE_ITEM = props => gql`
  mutation DeleteWarehouseItem($input: DeleteWarehouseItemInput!) {
    deleteWarehouseItem(input: $input) {
      success
      error
      warehouseItem {
        ${warehouseItem(props)}
      }
    }
  }
`;

const UPDATE_WAREHOUSE_ITEM = props => gql`
  mutation UpdateWarehouseItem($input: UpdateWarehouseItemInput!) {
    updateWarehouseItem(input: $input) {
      success
      error
      warehouseItem {
        ${warehouseItem(props)}
      }
    }
  }
`;

const REMOVE_WAREHOUSE_ITEM_CONTAINER = props => gql`
  mutation RemoveWarehouseItemContainer($input: RemoveWarehouseItemContainerInput!) {
    removeWarehouseItemContainer(input: $input) {
      success
      error
      warehouseItem {
        ${warehouseItem(props)}
      }
    }
  }
`;

const EXPORT_ADHOC_REPORT_WAREHOUSE_ITEMS = gql`
  mutation ExportAdhocReportWarehouseItems($input: ExportWarehouseItemsInput!) {
    exportAdhocReportWarehouseItems(input: $input) {
      error
      success
      url
    }
  }
`;

const MOVE_ITEM = props => gql`
  mutation MoveItem($input: MoveItemInput!) {
    moveItem(input: $input) {
      error
      success
      warehouseItem {
        ${warehouseItem(props)}
      }
    }
  }
`;

export {
  ADD_WAREHOUSE_ITEM_CONTAINER,
  DELETE_WAREHOUSE_ITEM,
  UPDATE_WAREHOUSE_ITEM,
  CREATE_WAREHOUSE_ITEM,
  REMOVE_WAREHOUSE_ITEM_CONTAINER,
  EXPORT_ADHOC_REPORT_WAREHOUSE_ITEMS,
  MOVE_ITEM,
};
