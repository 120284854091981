import { gql } from "@apollo/client";
import { ValueType } from "../fragments";

const FETCH_VALUE_TYPES = gql`
  query FetchValueTypes {
    valueTypes {
      ${ValueType}
    }
  }
`;

export { FETCH_VALUE_TYPES };
