import { useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import { useEffect } from "react";
import * as Yup from "yup";

import { Button } from "../../../../../../../components/ui";
import { GridInputs } from "../../../../../../../components/Functional";
import { currentLocaleISODate } from "../../../../../../../util";

import { FETCH_CRM_TYPES } from "../../../../../../../graphql/query/CRMType";
import { FETCH_ENTITIES } from "../../../../../../../graphql/query/Entity";
import { FETCH_JOB_STATUSES } from "../../../../../../../graphql/query/JobStatus";
import {
  CREATE_CRM_REGISTRAR as createCRMRegistrarMutation,
  UPDATE_CRM_REGISTRAR as udateCRMRegistrarMutation,
} from "../../../../../../../graphql/mutation/CRMRegistrar";

const AddCOA = props => {
  const { instanceId, closeModal, values, isOpen } = props;

  const checkDisabled = {
    disabled: values?.id ? true : false,
    inputClassName: values?.id
      ? "!bg-white !text-gray-400 !border-gray-400"
      : "",
  };

  // TODO - CRM Types with parentTypeId 67 not found in database, verify
  const { data: { crmTypes = [] } = {} } = useQuery(FETCH_CRM_TYPES, {
    variables: { input: { parentTypeId: 67 } },
  });
  const { data: { entities = [] } = {} } = useQuery(FETCH_ENTITIES);
  const { data: { jobStatuses = [] } = {} } = useQuery(FETCH_JOB_STATUSES);

  const [createCRMRegistrar, { loading: loadingCreate }] = useMutation(
    createCRMRegistrarMutation({ salesUser: {}, type: {} }),
    { refetchQueries: ["FetchCRMRegistrarsV2"] },
  );

  const [updateCRMRegistrar, { loading: loadingUpdate }] = useMutation(
    udateCRMRegistrarMutation({ salesUser: {}, type: {} }),
    { refetchQueries: ["FetchCRMRegistrarsV2"] },
  );

  const addCOA = () => {
    const input = {
      categoryId: 9, // id of 'CRMCategory: {description: Communication}'
      description: formik.values.description,
      entityId: formik.values.entityId || null,
      statusId: formik.values.statusId || null,
      salesUserId: formik.values.salesUserId || null,
      typeId: formik.values.typeId,
    };

    if (values?.id) {
      input.id = values?.id;
    } else {
      input.date = formik.values.date;
      input.userId = formik.values.userId;
      input.instanceId = instanceId;
    }

    const action = values?.id ? updateCRMRegistrar : createCRMRegistrar;

    action({
      variables: {
        input,
      },
    }).then(() => {
      closeModal();
      formik.resetForm();
    });
  };

  const formik = useFormik({
    initialValues: values
      ? {
          date: values.date,
          description: values.description,
          entityId: values.entityId,
          salesUserId: values.salesUserId,
          statusId: values.statusId,
          typeId: values.typeId,
          userId: values.userId,
        }
      : {
          date: currentLocaleISODate(),
        },
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: Yup.object({
      date: Yup.date().required("Please enter a date."),
      entityId: Yup.number().required("Please select user."),
    }),
    onSubmit: addCOA,
  });

  const inputs = {
    className: "grid grid-cols-2 gap-12",
    inputs: [
      {
        className: "grid grid-cols-1 gap-4",
        inputs: [
          { label: "Date", name: "date", type: "date", ...checkDisabled },
          {
            label: "Type",
            name: "typeId",
            type: "multi-select",
            placeholder: "Select a type",
            options: crmTypes?.map(item => ({
              label: item?.description,
              value: item?.id,
            })),
          },
          {
            label: "Description",
            name: "description",
            rows: 4,
            type: "textarea",
          },
        ],
      },
      {
        className: "grid grid-cols-1 gap-4",
        inputs: [
          {
            label: "Status",
            name: "statusId",
            type: "multi-select",
            placeholder: "Select status",
            options: jobStatuses?.map(item => ({
              label: item?.description,
              value: item?.id,
            })),
          },
          {
            label: "Entity",
            name: "entityId",
            type: "multi-select",
            placeholder: "Select user",
            options: entities?.edges?.map(item => ({
              label: item?.name,
              value: item?.id,
            })),
          },
          {
            label: "User",
            name: "userId",
            type: "multi-select",
            placeholder: "Select user",
            ...checkDisabled,
            options: entities?.edges?.map(item => ({
              label: item?.name,
              value: item?.id,
            })),
          },
          {
            label: "Sales User",
            name: "salesUserId",
            type: "multi-select",
            placeholder: "Select user",
            options: entities?.edges?.map(item => ({
              label: item?.name,
              value: item?.id,
            })),
          },
        ],
      },
    ],
  };

  const inputProps = {
    ...inputs,
    formik,
  };

  const successLabel = values?.id ? "Edit" : "Add";

  useEffect(() => {
    formik.handleReset();
  }, [isOpen]);

  return (
    <div className="flex w-full flex-col p-8">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          {values?.id ? "Edit" : "Add"} COA Record
        </div>
        <div className="flex flex-col">
          <div>
            <Button
              action="default"
              className="mr-8"
              label="Cancel"
              onClick={closeModal}
            />
            <Button
              label={`${successLabel}${
                loadingUpdate || loadingCreate ? "ing" : ""
              } COA`}
              disabled={loadingUpdate || loadingCreate}
              onClick={formik.submitForm}
            />
          </div>
        </div>
      </div>
      <div className="mt-12 pb-10">
        <GridInputs {...inputProps} />
      </div>
    </div>
  );
};

export default AddCOA;
