const Header = props => {
  const Component = props.rightComponent;
  return (
    <div className="flex flex-row justify-between">
      <div className="flex flex-row items-center">
        <div className="mr-4 text-xl font-medium">{props.leftText}</div>
        {props.rightText && (
          <>
            |
            <div className="pl-4 text-xl font-medium text-black">
              {props.rightText}
            </div>
          </>
        )}
      </div>
      {Component && <Component />}
    </div>
  );
};

export default Header;
