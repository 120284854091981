import { PencilIcon, EyeIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useMutation } from "@apollo/client";

import EditEmailContact from "./EditEmailContact";
import { Button, IconButton, Modal } from "../../../../../../components/ui";
import { DELETE_EMAIL_CONTACT } from "../../../../../../graphql/mutation/EmailContact";
import { FETCH_EMAIL_CONTACTS } from "../../../../../../graphql/query/EmailContact";

const Actions = props => {
  const { id, values } = props || {};
  const [deleteEmailContact, { loading }] = useMutation(DELETE_EMAIL_CONTACT, {
    refetchQueries: [FETCH_EMAIL_CONTACTS],
  });

  const deleteModalProps = {
    title: "Are you sure?",
    scale: "sm",
    description: `Would you like to delete email contact titled '${values?.meta?.name}'?`,
    closeOnBackdrop: true,
    body: ({ closeModal }) => {
      const handleDelete = () => {
        deleteEmailContact({
          variables: {
            input: {
              id: +id,
            },
          },
          update: (cache, { data }) => {
            const { deleteEmailContact: { success } = {} } = data;
            if (success) {
              closeModal();
            }
          },
        });
      };

      return (
        <div className="mt-4 flex">
          <Button
            className="mr-3"
            label={"Cancel"}
            action={"default"}
            onClick={() => {
              closeModal?.();
            }}
          />
          <Button
            label={loading ? "Deleting" : "Delete"}
            disabled={loading}
            onClick={handleDelete}
          />
        </div>
      );
    },
  };

  const editEmailContactProps = {
    ...props,
  };

  const modalProps = {
    title: "Email Preview",
    body: () => {
      return (
        <div
          className="mt-6 h-full overflow-auto border border-gray-400 p-6 sm:rounded-lg"
          dangerouslySetInnerHTML={{ __html: values?.content }}
        />
      );
    },
  };

  return (
    <div className="flex text-right text-sm font-medium">
      {id && (
        <div className="grid grid-cols-4 gap-4">
          <IconButton variant="clean" title="Edit">
            <EditEmailContact {...editEmailContactProps}>
              <PencilIcon className={`h-4 w-4 cursor-pointer text-gray-400`} />
            </EditEmailContact>
          </IconButton>
          <Modal {...modalProps}>
            <IconButton variant="clean" title="Preview">
              <EyeIcon className="h-4 w-4 cursor-pointer text-gray-400" />
            </IconButton>
          </Modal>
          <Modal {...deleteModalProps}>
            <IconButton variant="clean" title="Remove">
              <XMarkIcon className={`h-4 w-4 cursor-pointer text-gray-400`} />
            </IconButton>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default Actions;
