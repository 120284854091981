import { useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { useMemo } from "react";

import { Button, getInput } from "../../../components/ui";
import { FETCH_EDITIONS_V2 as fetchEditionsV2 } from "../../../graphql/query/Edition";
import { CHANGE_INSTANCE_EDITION_NUMBER as changeInstanceEditionNumberMutation } from "../../../graphql/mutation/Instance";

const ChangeEditionNumber = props => {
  const { closeModal, instance } = props || {};
  const navigate = useNavigate();
  const [changeInstanceEditionNumber, { loading }] = useMutation(
    changeInstanceEditionNumberMutation(),
    {
      refetchQueries: ["FetchInstances", "FetchInstancesV2"], // TODO: instead of refetch write and update cache
    },
  );

  const { data: { editionsV2 = [] } = {} } = useQuery(fetchEditionsV2(), {
    variables: {
      input: {
        _artwork: {
          id: {
            operator: "eq",
            value: instance?.edition?.artwork?.id,
          },
        },
      },
    },
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { editionId: "", editionNumber: "" },
    validationSchema: Yup.object({}),
    onSubmit: async values => {
      changeInstanceEditionNumber({
        variables: {
          input: {
            ...values,
            id: instance?.id,
          },
        },
      }).then(resp => {
        const {
          data: { changeInstanceEditionNumber: { success, instance } = {} },
        } = resp || {};
        if (success) {
          formik.resetForm();
          navigate(`/instance/${instance?.id}/details`);
          closeModal();
        }
      });
    },
  });

  const possibleNumbers = useMemo(() => {
    const edition = editionsV2?.edges?.filter(
      item => item?.id === formik?.values?.editionId,
    )?.[0];

    if (!edition) return [];
    const options = [];
    for (let i = 1; i <= edition?.number; i++) {
      options.push({ label: i, value: i });
    }
    return options;
  }, [formik?.values?.editionId, editionsV2?.edges]);

  const inputs = [
    {
      label: "Editions",
      name: "editionId",
      type: "multi-select",
      options: editionsV2?.edges?.map(item => ({
        label: `Edition ${item?.id}`,
        value: item.id,
      })),
    },
    {
      label: "Edition Number",
      name: "editionNumber",
      type: "multi-select",
      options: possibleNumbers,
      disabled: possibleNumbers.length < 0,
      inputClassName:
        possibleNumbers.length < 0
          ? ""
          : "!bg-white !text-gray-400 !border-gray-400",
    },
  ];

  const hasErrors = Object.keys(formik?.errors || {}).length > 0;

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          <div>Change edition number</div>
        </div>
        <div className="flex gap-4">
          <Button action="default" label="Cancel" onClick={closeModal} />
          <Button
            label={"Change"}
            loading={loading}
            disabled={loading || hasErrors}
            onClick={formik.submitForm}
          />
        </div>
      </div>
      <div className="w-50 grid grid-cols-2 gap-4">
        {inputs?.map((item, index) => {
          const inputProps = {
            ...item,
            formik,
          };
          return <div key={index}>{getInput(inputProps)}</div>;
        })}
      </div>
    </div>
  );
};

export default ChangeEditionNumber;
