import { useQuery } from "@apollo/client";
import { GridInputs } from "../../../../../components/Functional";
import { FETCH_ARTISTS as fetchArtists } from "../../../../../graphql/query/Artist";
import { FETCH_FOCUSES } from "../../../../../graphql/query/Focus";

const Collection = props => {
  const { formik } = props || {};

  const { data: { artists } = {} } = useQuery(fetchArtists());
  const { data: { focuses } = {} } = useQuery(FETCH_FOCUSES);

  const inputs = {
    className: "grid grid-rows-2 gap-4",
    inputs: [
      {
        className: "grid grid-cols-4 gap-4",
        inputs: [
          {
            hasOperator: true,
            isMulti: true,
            label: "Artist",
            name: "_contactArtist.artistId",
            options: artists?.map(artist => ({
              label: artist.name,
              value: artist.id,
            })),
            type: "multi-select",
          },
          {
            hasOperator: true,
            isMulti: true,
            label: "Focus",
            name: "_contactFocus.focusId",
            options: focuses?.map(focus => ({
              label: focus.description,
              value: focus.id,
            })),
            type: "multi-select",
          },
        ],
      },
      {
        className: "grid grid-cols-4 gap-4",
        inputs: [
          { label: "Collection Notes", name: "collection", type: "text" },
          {
            label: "Collection Status",
            name: "collectionActive",
            options: [
              { label: "All", value: null },
              { label: "Active", value: -1 },
              { label: "Inactive", value: 0 },
            ],
            type: "select",
          },
        ],
      },
    ],
  };

  const gridInputProps = {
    formik,
    ...inputs,
  };

  return <GridInputs {...gridInputProps} />;
};

export default Collection;
