import { gql } from "@apollo/client";
import { Fabrication as fabrication } from "../fragments";

const FETCH_FABRICATIONS_V2 = props => gql`
  query FetchFabricationsV2($input: AdvFabricationFilterInput!) {
    fabricationsV2(input: $input) {
      count
      edges {
        ${fabrication(props)}
      }
      costTotal
      estimateTotal
      sqlQuery
    }
  }
`;

export { FETCH_FABRICATIONS_V2 };
