import { gql } from "@apollo/client";

const UPLOAD_INVOICE_DOCUMENTS = gql`
  mutation UploadInvoiceDocuments($invoiceId: Int!, $files: [Upload]!) {
    uploadInvoiceDocuments(invoiceId: $invoiceId, files: $files) {
      success
      error
      response
    }
  }
`;

const DELETE_INVOICE_DOCUMENT = gql`
  mutation DeleteInvoiceDocument($input: DeleteInvoiceDocumentInput!) {
    deleteInvoiceDocument(input: $input) {
      success
      error
    }
  }
`;

export { UPLOAD_INVOICE_DOCUMENTS, DELETE_INVOICE_DOCUMENT };
