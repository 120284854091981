import { Button } from "../../../../../../../../../components/ui";
import { useMutation } from "@apollo/client";
import { DELETE_CONTACT_ADDRESS } from "../../../../../../../../../graphql/mutation/ContactAddress";
import DetailView from "./DetailView";

const DeleteAddressComponent = props => {
  const { contact, contactAddress, closeModal } = props || {};

  const [deleteContactAddress, { loading }] = useMutation(
    DELETE_CONTACT_ADDRESS,
  );

  const handleDelete = () => {
    deleteContactAddress({
      variables: {
        input: { id: contactAddress?.id },
      },
      update: (cache, response) => {
        const { data: { deleteContactAddress: { success } = {} } = {} } =
          response;
        if (success) {
          cache.evict({
            id: cache.identify({
              __typename: "ContactAddress",
              id: contactAddress?.id,
            }),
          });
          closeModal();
        }
      },
    });
  };

  return (
    <div className="p-8">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          Delete Address Confirmation
        </div>
        <div className="flex flex-col">
          <div>
            <Button
              label="CANCEL"
              action="default"
              className="mr-4"
              onClick={closeModal}
            />
            <Button
              label={loading ? "DELETING" : "DELETE"}
              onClick={handleDelete}
              disabled={loading}
            />
          </div>
        </div>
      </div>
      <div className="mb-4 mt-8">
        You are about to delete the following address from {contact?.name}{" "}
        contact record:
      </div>
      <DetailView contactAddress={contactAddress} disabled={true} />
    </div>
  );
};

export default DeleteAddressComponent;
