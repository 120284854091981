import SectionHeader from "./components/SectionHeader";
import InstanceDetail from "./components/InstanceDetail";
import { DetailViewCarousel } from "../../../../../../components/ui";
import {
  AddToSelectList,
  CopyClipboard,
  ExportImage,
  ManageImage,
  UpdateImageInfo,
} from "../../../../../../components/Modals/ImageActions";
import ArtworkFolder from "./components/ArtworkFolder";

const InstanceTags = props => {
  const { instance = {} } = props || {};

  return (
    <div className="mt-3 flex gap-2 text-xs tracking-wider text-white">
      {instance?.isGuaranteed && (
        <span className="rounded-full bg-[#dd3333] px-2 py-1">Indemnity</span>
      )}
      {instance?.isOnLoan && (
        <span className="rounded-full bg-[#ff7400] px-2 py-1">On Loan</span>
      )}
      {instance?.isSecondaryMarket && (
        <span className="rounded-full bg-[#0047FF] px-2 py-1">
          Secondary Market
        </span>
      )}
      {instance?.isBond && instance?.company?.name && (
        <span className="rounded-full bg-[#8400ff] px-2 py-1">
          TA - {instance?.company?.name}
        </span>
      )}
    </div>
  );
};

const InstanceDetails = props => {
  const { instance, formik } = props || {};
  const imageList = instance?.edition?.artwork?.images || [];

  const instanceActions = [
    {
      component: props => {
        return (
          <>
            <ManageImage {...props} images={imageList} />
            <ArtworkFolder />
          </>
        );
      },
    },
  ];

  const imageActions = [
    {
      component: props => {
        return (
          <>
            <AddToSelectList {...props} />
            <UpdateImageInfo {...props} />
            <CopyClipboard {...props} />
            <ExportImage {...props} artwork={instance?.edition?.artwork} />
          </>
        );
      },
    },
  ];

  return (
    <div className="w-full">
      <div>
        <SectionHeader
          title={
            <div>
              Instance {instance?.name || ""}
              <div className={`mt-3 text-sm font-medium text-black`}>
                {imageList.length > 0 ? `${imageList.length} images` : ""}
              </div>
              <InstanceTags instance={instance} />
            </div>
          }
          actions={instanceActions}
        />
      </div>
      <div className="w-full px-16 py-8">
        <div className="flex flex-col space-y-8">
          <DetailViewCarousel imageList={imageList} actions={imageActions} />
          <InstanceDetail formik={formik} />
        </div>
      </div>
    </div>
  );
};

export default InstanceDetails;
