import companyFrag from "./Company";

const GuaranteeAccount = ({ company = false } = {}) => `
  id
  accountLimit
  ${
    company
      ? `company {
      ${companyFrag(company)}
    }`
      : ""
  }
  companyId
  description
  expired
  number
  terms
  updatedAt
`;

export default GuaranteeAccount;
