import { gql } from "@apollo/client";
import { DocumentPage } from "../fragments";

const ASSIGN_INSTANCE_DOCUMENT_PAGE = gql`
  mutation AssignInstanceDocumentPage($input: AssignInstanceDocumentPageInput!) {
    assignInstanceDocumentPage(input: $input) {
      documentPage {
        ${DocumentPage}
      }
      success
      error
    }
  }
`;

const CREATE_DOCUMENT_PAGE = gql`
  mutation CreateDocumentPage($input: CreateDocumentPageInput!) {
    createDocumentPage(input: $input) {
      documentPage {
        ${DocumentPage}
      }
      success
      error
    }
  }
`;

const DELETE_DOCUMENT_PAGE = gql`
  mutation DeleteDocumentPage($input: DeleteDocumentPageInput!) {
    deleteDocumentPage(input: $input) {
      success
      error
    }
  }
`;

const DUPLICATE_DOCUMENT_PAGE = gql`
  mutation DuplicateDocumentPage($input: DuplicateDocumentPageInput!) {
    duplicateDocumentPage(input: $input) {
      documentPage {
        ${DocumentPage}
      }
      success
      error
    }
  }
`;

const UPDATE_DOCUMENT_PAGE = gql`
  mutation UpdateDocumentPage($input: UpdateDocumentPageInput!) {
    updateDocumentPage(input: $input) {
      documentPage {
        ${DocumentPage}
      }
      success
      error
    }
  }
`;

const UPDATE_IMAGE = gql`
  mutation UpdateImage($input: UpdateDocumentImageInput!) {
    updateImage(input: $input) {
      documentPage {
        ${DocumentPage}
      }
      success
      error
    }
  }
`;

const UPDATE_DOCUMENT_PAGE_TEMPLATE = gql`
  mutation UpdateDocumentPageTemplate(
    $input: [UpdateDocumentPageTemplateInput]!
  ) {
    updateDocumentPageTemplate(input: $input) {
      success
      error
    }
  }
`;

export {
  ASSIGN_INSTANCE_DOCUMENT_PAGE,
  CREATE_DOCUMENT_PAGE,
  DELETE_DOCUMENT_PAGE,
  DUPLICATE_DOCUMENT_PAGE,
  UPDATE_DOCUMENT_PAGE,
  UPDATE_IMAGE,
  UPDATE_DOCUMENT_PAGE_TEMPLATE,
};
