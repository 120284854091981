import { gql } from "@apollo/client";
import { Request as request } from "../fragments";

const FETCH_REQUESTS = props => gql`
  query FetchRequests($input: RequestFilterInput!) {
    requests(input: $input) {
      count
      edges {
        ${request(props)}
      }
    }
  }
`;

const FETCH_REQUEST = props => gql`
  query FetchRequest($id: Int) {
    request(id: $id) {
      ${request(props)}
    }
  }
`;

export { FETCH_REQUEST, FETCH_REQUESTS };
