/* eslint-disable max-len */
const DarkIcon = props => (
  <svg
    width={20}
    height={20}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>{"feather icon/dark"}</title>
    <defs>
      <path
        d="M10 19.167A9.14 9.14 0 0 1 .833 10 9.14 9.14 0 0 1 10 .833 9.14 9.14 0 0 1 19.167 10 9.14 9.14 0 0 1 10 19.167ZM10 2.5A7.469 7.469 0 0 0 2.5 10c0 4.167 3.333 7.5 7.5 7.5s7.5-3.333 7.5-7.5-3.333-7.5-7.5-7.5Zm.833 7.5V6.667c0-.5-.333-.834-.833-.834-.5 0-.833.334-.833.834V10c0 .5.333.833.833.833.5 0 .833-.333.833-.833Zm-.25 3.917c.084-.084.167-.167.167-.25 0-.084.083-.25.083-.334 0-.083 0-.25-.083-.333-.083-.083-.083-.167-.167-.25 0 0-.083-.083-.166-.083-.084 0-.084-.084-.167-.084-.333-.166-.667-.083-.917.167-.083.083-.166.167-.166.25 0 .083-.084.167-.084.333 0 .25.084.417.25.584.167.166.334.25.584.25.333 0 .5-.084.666-.25Z"
        id="a"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <use fill="#000" fillRule="nonzero" xlinkHref="#a" />
      <g mask="url(#b)" fill="#FFF">
        <path d="M0 0h20v20H0z" />
      </g>
    </g>
  </svg>
);

export default DarkIcon;
