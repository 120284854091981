import { Table } from "../../../../../../components/Functional";
import { Modal, Button } from "../../../../../../components/ui";
import ThreeStateCheckbox from "../../../../../../components/ui/Input/components/ThreeStateCheckbox";

import Actions from "./components/Actions";
import SectionHeader from "../../components/SectionHeader";
import { FETCH_FABRICATIONS_V2 as fetchFabricationsV2 } from "../../../../../../graphql/query/Fabrication";
import AddEstimate from "./components/AddEstimate";
import useUser from "../../../../../../hooks/useUser";

const EstimateTotal = props => {
  const { estimateTotal = 0.0 } = props || {};
  return (
    <div className="border-b pb-4 pl-[1145px] pt-4 font-bold">
      Estimate Total: {estimateTotal}
    </div>
  );
};

const EstimateAmount = ({ values }) => {
  return (
    <span>{`${values.amount ? values.amount?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ""} ${values?.currency?.code || ""}`}</span>
  );
};

const ConsignmentEstimates = props => {
  const { hasPermission } = useUser();
  const { consignment, consignmentId } = props || {};

  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "fabricationsV2",
    headers: [
      { label: "Date", name: "date", type: "date" },
      { label: "Stage", name: "stage.name", type: "label" },
      { label: "Type", name: "category.name", type: "label" },
      { label: "Service", name: "type.description", type: "label" },
      { label: "Description", name: "description", type: "label" },
      {
        label: "Amount",
        name: "amount",
        type: "custom",
        component: EstimateAmount,
      },
      { label: "Technicians", name: "technicians", type: "label" },
      { label: "Days", name: "days", type: "label" },
      { label: "Hour", name: "hours", type: "label" },
      { label: "Rate", name: "rate", type: "label" },
      { label: "Total", name: "techniciansTotal", type: "label" },
      { label: "Notes", name: "notes", type: "label" },
      {
        label: "Waived",
        name: "waived",
        type: "custom",
        component: props => (
          <div className="flex justify-center">
            <ThreeStateCheckbox checked={props.label} disabled={true} />
          </div>
        ),
      },
      {
        label: "Confirmed",
        name: "confirmed",
        type: "custom",
        component: props => (
          <div className="flex justify-center">
            <ThreeStateCheckbox checked={props.label} disabled={true} />
          </div>
        ),
      },
      { label: "", name: "action", type: "custom", component: Actions },
    ],
    inputs: [{ label: "Name", name: "name", type: "text", view: "partial" }],
    FETCH_QUERY: fetchFabricationsV2({
      category: {},
      currency: {},
      stage: {},
      type: {},
    }),
    variables: {
      consignmentId: consignmentId,
      estimate: -1,
    },
    className: "sm:px-0 lg:px-0",
    footerComponent: EstimateTotal,
  };

  const modalProps = {
    body: AddEstimate,
    closeOnBackdrop: true,
    consignment,
    consignmentId,
    hideCloseButton: true,
    scale: "md",
    disabled: !hasPermission("UPDATE_CONSIGNMENT"),
  };

  const actions = [
    {
      component: () => {
        return (
          <Modal {...modalProps}>
            <Button
              label="New Estimate"
              action="black"
              disabled={!hasPermission("UPDATE_CONSIGNMENT")}
            />
          </Modal>
        );
      },
    },
  ];

  return (
    <div className="w-full">
      <SectionHeader title="Estimate" actions={actions} />
      <div className="mb-6 w-full">
        <Table {...tableProps} />
      </div>
    </div>
  );
};

export default ConsignmentEstimates;
