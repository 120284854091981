import { useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../../../components/ui";
import { FETCH_EMAIL as fetchEmail } from "../../../../graphql/query/Email";

const StatsComponent = props => {
  const { label, value = 0 } = props || {};
  return (
    <div className="flex w-48 flex-col items-center rounded border-2 px-6 py-6">
      <div className="text-4xl">{value}</div>
      <div className="text-xl">{label}</div>
    </div>
  );
};

const StatsList = props => {
  const { label, value } = props || {};
  return (
    <div className="flex flex-1 items-center justify-between px-3 py-3">
      <div className="text-xl">{label}</div>
      <div className="text-xl">{value}</div>
    </div>
  );
};

const Analytics = props => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: { email = {} } = {} } = useQuery(
    fetchEmail({ analytics: {} }),
    { variables: { id: +id } },
  );
  const { analytics = {} } = email || {};

  const stats = [
    { label: "Delivery", value: analytics?.delivery },
    { label: "Opened", value: analytics?.open },
    { label: "Bounce", value: analytics?.bounce },
    // { label: 'Injection', value: analytics?.injection },
  ];

  const renderStats = stats?.map((item, index) => {
    return <StatsComponent key={index} {...item} />;
  });

  return (
    <div className="mx-auto py-5 sm:px-6 lg:px-8">
      <div className="flex items-center justify-between">
        <h3 className="text-lg font-bold leading-6">Mailing Analytics</h3>
        <div className="mb-4 flex items-center justify-between">
          <div className="flex justify-end">
            <Button
              label="Close"
              className="mr-4"
              onClick={() => navigate(`/email`)}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex-1">
          <div className="flex w-2/4 divide-y-2 border-y-2">
            <StatsList
              label="Last Opened At"
              value={new Date(analytics?.lastOpenedAt * 1000).toLocaleString()}
            />
          </div>
        </div>
        <div className="flex">
          <div className="flex grid w-2/4 grid-cols-3">{renderStats}</div>
          <div className="flex"></div>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
