import { gql } from "@apollo/client";
import { WarehouseLocation } from "../fragments";

const FETCH_WAREHOUSE_LOCATIONS = gql`
  query FetchWarehouseLocations($input: WarehouseLocationFilterInput) {
    warehouseLocations(input: $input) {
      ${WarehouseLocation}
    }
  }
`;

export { FETCH_WAREHOUSE_LOCATIONS };
