import { Route, Routes as RouterRoutes } from "react-router-dom";
import ListView from "./pages/ListView";

const routes = [
  {
    path: "*",
    element: ListView,
  },
];

const Body = props => {
  return (
    <RouterRoutes>
      {routes?.map((item, index) => {
        return (
          <Route
            key={index}
            path={item?.path}
            element={<item.element {...props} />}
          />
        );
      })}
    </RouterRoutes>
  );
};

const Handover = props => {
  return <Body {...props} />;
};

export default Handover;
