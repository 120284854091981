import { gql } from "@apollo/client";
import { ConditionStatus } from "../fragments";

const FETCH_CONDITION_STATUS = gql`
  query conditionStatus {
    conditionStatus {
      ${ConditionStatus}
    }
  }
`;

export { FETCH_CONDITION_STATUS };
