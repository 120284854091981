import { GridInputs } from "../../../../../../../../../components/Functional";
import useUser from "../../../../../../../../../hooks/useUser";

const ArtworkDetailCol4 = ({
  disabled,
  formik,
  disableConfig,
  submitOnBlur = false,
}) => {
  const { hasPermission } = useUser();
  const inputs = {
    className: "grid-cols-1 gap-4",
    inputs: [
      {
        disabled: !hasPermission("UPDATE_ARTWORK_DESCRIPTION"),
        label: "Description",
        name: "description",
        type: "textarea",
        rows: 5,
        submitOnBlur,
      },
      {
        disabled,
        label: "Record Date",
        name: "timestamp",
        type: "timestamp",
        ...disableConfig,
        submitOnBlur,
      },
      {
        className: "grid-cols-2 gap-4",
        inputs: [
          {
            label: "ID",
            name: "id",
            type: "text",
            ...disableConfig,
            submitOnBlur,
          },
          {
            label: "System Field",
            name: "field",
            type: "text",
            ...disableConfig,
            submitOnBlur,
          },
        ],
      },
    ],
  };

  const detailInputProps = {
    formik,
    ...inputs,
  };

  return <GridInputs {...detailInputProps} />;
};

export default ArtworkDetailCol4;
