import { gql } from "@apollo/client";
import { Partner } from "../fragments";

const UPDATE_PARTNER = gql`
  mutation UpdatePartner($input: UpdatePartnerInput!) {
    updatePartner(input: $input) {
      success
      error
      partner {
       ${Partner}
      }
    }
  }
`;

export { UPDATE_PARTNER };
