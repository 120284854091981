import * as Yup from "yup";
import { useEffect } from "react";
import { useFormik } from "formik";
import { useMutation, useQuery } from "@apollo/client";

import { Button } from "../../../../../../../components/ui";
import { GridInputs } from "../../../../../../../components/Functional";
import { CREATE_EDITION as createEditionMutation } from "../../../../../../../graphql/mutation/Edition";
import { FETCH_EDITION_TYPES } from "../../../../../../../graphql/query/EditionType";

const AddEdition = props => {
  const { closeModal, isOpen, artwork } = props;

  const { data: { editionTypes = [] } = {} } = useQuery(FETCH_EDITION_TYPES);
  const [createEdition, { loading }] = useMutation(createEditionMutation(), {
    refetchQueries: ["FetchArtwork"],
  });

  const handleClick = props => {
    const { year, numberOfWorksInEdition, ap } = props || {};

    createEdition({
      variables: {
        input: {
          artworkId: artwork?.id,
          year,
          ap,
          number: numberOfWorksInEdition,
        },
      },
    }).then(resp => {
      const {
        data: { createEdition: { success } = {} },
      } = resp || {};
      if (success) {
        closeModal();
      }
    });
  };

  const inputs = {
    className: "grid grid-cols-2 gap-4 mt-5",
    inputs: [
      {
        className: "grid-cols-1 gap-4",
        inputs: [
          {
            label: "Year",
            name: "year",
            type: "text",
          },
          {
            label: "Number of works in edition",
            name: "numberOfWorksInEdition",
            type: "number",
          },
        ],
      },
      {
        className: "grid-cols-1 gap-4",
        inputs: [
          {
            label: "Type",
            name: "ap",
            type: "multi-select",
            options: editionTypes.map(item => ({
              label: `${item?.description} (${item?.code})`,
              value: item?.id,
            })),
          },
        ],
      },
    ],
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      year: "",
      numberOfWorksInEdition: 1,
      typeId: null,
    },
    onSubmit: handleClick,
    validationSchema: Yup.object({
      year: Yup.string().required("Year is required"),
      numberOfWorksInEdition: Yup.number()
        .required("Number of works in edition is required")
        .min(1, `Number of works in edition must be greater than 1`),
      // ap: Yup.number().required("Type is required"),
    }),
  });

  useEffect(() => {
    formik.resetForm();
  }, [isOpen]);

  const inputProps = {
    formik,
    ...inputs,
  };

  return (
    <div className="flex w-full flex-col p-8">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          Add Edition
        </div>
        <div className="flex flex-row">
          <Button
            action="default"
            className="mr-8"
            label="Cancel"
            onClick={() => {
              closeModal();
              formik.resetForm();
            }}
          />
          <Button
            label={loading ? "Adding" : "Add Edition"}
            disabled={loading}
            onClick={formik?.submitForm}
          />
        </div>
      </div>
      <GridInputs {...inputProps} />
    </div>
  );
};

export default AddEdition;
