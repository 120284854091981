import { Route, Routes as RouterRoutes } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Wizard from "./pages/Wizard";

const routes = [
  {
    path: "*",
    element: Dashboard,
  },
  {
    path: ":id/*",
    element: Wizard,
  },
];

const Body = props => {
  return (
    <RouterRoutes>
      {routes?.map((item, index) => {
        return (
          <Route
            key={index}
            path={item?.path}
            element={<item.element {...props} />}
          />
        );
      })}
    </RouterRoutes>
  );
};

const Support = props => {
  return <Body {...props} />;
};

export default Support;
