import { PencilIcon } from "@heroicons/react/24/outline";
import {
  Button,
  getInput,
  IconButton,
  Modal,
} from "../../../../../../../components/ui";
import EditNameModal from "./EditNameModal";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import { CREATE_LIST as createListMutation } from "../../../../../../../graphql/mutation/List";

const ImageField = props => {
  const { value, setValue, image, loadingContact, updateContact, contactId } =
    props || {};

  const handleUpload = async (e, closeModal) => {
    const files = e.files;

    if (files.length) {
      updateContact({
        variables: {
          input: {
            id: contactId,
            img: files[0],
          },
        },
        update: () => {
          closeModal();
        },
      });
    }
  };

  const handleDetach = attachment => {
    setValue?.(value.filter(item => item !== attachment));
  };

  const inputProps = {
    ...props,
    onChange: handleUpload,
    handleDetach,
    className: "border-neutral-400",
    variant: "modal",
    value: image,
    loading: loadingContact,
  };

  return <div className="flex bg-neutral-200">{getInput(inputProps)}</div>;
};

const Header = props => {
  const { contact, contactId, disabled, image, loadingContact, updateContact } =
    props || {};

  const [createList, { loading }] = useMutation(createListMutation());

  const [successOpModal, setSuccessOpModal] = useState({
    isOpen: false,
    message: "",
  });

  const imageInput = {
    disabled,
    handleDelete: () =>
      updateContact({
        variables: {
          input: {
            id: contactId,
            img: null,
          },
        },
      }),
    name: "meta.files",
    label: "",
    type: "img",
    isMultiSelect: false,
    uploadType: "file(s)",
    h: "h-[200px]",
    w: "w-[200px]",
    text: "Add Image",
    textClassName:
      "bg-white border border-gray-400 px-6 py-2 rounded-md text-[12px]",
    uploadButtonClassName: "px-8 py-2",
    uploadModalTitle: `Upload Image for ${props.leftText || ""}`,
    image,
    loadingContact,
    updateContact,
    contactId,
  };

  const modalProps = {
    body: EditNameModal,
    contact,
    closeOnBackdrop: true,
    hideCloseButton: true,
    scale: "md",
  };

  const handleAddToSelectList = async e => {
    e.preventDefault();
    const res = await createList({
      variables: {
        input: {
          name: `select_list_working_${Date.now()}`,
          listItemIds: [contact?.id],
          typeId: 2,
        },
      },
    });
    if (res?.data?.createList?.success) {
      setSuccessOpModal({
        isOpen: true,
        message: "The contact has been added to select list.",
      });
    }
  };

  return (
    <div className="col-span-1 flex cursor-pointer flex-row justify-between border-b border-gray-400 pb-12">
      <div className="relative flex w-full flex-row items-end">
        <Button
          loading={loading}
          className={`absolute right-0 top-0 flex items-center`}
          label={loading ? "Adding..." : "Add to select list"}
          onClick={handleAddToSelectList}
          disabled={loading}
        />
        <div className="pr-8">
          <ImageField {...imageInput} />
        </div>
        <div className="group flex">
          <h1 className="text-xl font-bold">{props.leftText}</h1>
          {!disabled && (
            <Modal {...modalProps}>
              <IconButton
                className="hidden group-hover:block"
                variant="clean"
                title="Edit Method"
              >
                <PencilIcon
                  className={`h-4 w-4 cursor-pointer text-gray-400`}
                />
              </IconButton>
            </Modal>
          )}
        </div>
      </div>
      <Modal
        scale="sm"
        closeOnBackdrop={false}
        hideCloseButton={true}
        isOpen={successOpModal.isOpen}
        onClose={() => setSuccessOpModal({ isOpen: false, message: "" })}
        body={({ closeModal }) => (
          <div className="w-full">
            <div className="mb-5 text-lg font-medium leading-6 text-gray-900">
              {successOpModal.message}
            </div>
            <Button
              label={"Close"}
              onClick={() => {
                closeModal();
              }}
            />
          </div>
        )}
      />
    </div>
  );
};

export default Header;
