import { useMutation, useQuery } from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom";

import { AdhocReportBody } from "../../../components/Modals/AdhocReport";
import ExportActiveReport from "../../../components/Modals/ExportActiveReport";
import { Breadcrumb, Button, MenuButton, Modal } from "../../../components/ui";
import { DELETE_CONTACT } from "../../../graphql/mutation/Contact";
import { FETCH_CONTACT as fetchContact } from "../../../graphql/query/Contact";
import useUser from "../../../hooks/useUser";

import routes from "../pages/Wizard/routes";

import { ExportContactsBody } from "./Actions/ExportContacts";
import { ExportEmailsBody } from "./Actions/ExportEmails";
import exportModule from "./Actions/ExportModule";
import { HandoverBody } from "./Actions/Handover";
import MergeContact from "./Actions/MergeContact";
import NewContact from "./Actions/NewContact";
import SubscribeModal from "./Actions/Subscribe";
import UpdateCRM from "./Actions/UpdateCRM";

import ContactSearch from "./ContactSearch";
import { contactFilterStore } from "./useContactFilters";

const PrimaryNav = props => {
  const { formik } = props;
  const search = contactFilterStore.get();
  const location = useLocation();
  const navigate = useNavigate();
  const { user: { entityId } = {}, hasPermission } = useUser();
  const pathnames = location?.pathname.split("/");
  // get id from url
  const id = pathnames?.length > 2 ? pathnames[2] : null;

  const contactObj = {
    contactAddresses: {},
    contactArtists: { artist: {} },
    contactFocuses: { focus: {} },
    contactMailingLists: { mailingList: {} },
    contactNumbers: { type: {} },
    country: {},
    documents: {},
    exhUser: {},
    firstUser: {},
    lastOffer: {},
    salesUser: {},
    salesUser2: {},
    totalSpent: {},
    type: {},
    type2: {},
  };
  // fetch contact details if id is available
  const { data: { contact } = {} } = useQuery(fetchContact(contactObj), {
    skip: !id,
    variables: { id: +id },
  });
  // find current submodule route
  const path = pathnames[pathnames?.length - 1];
  const route = routes?.find(item => item?.href?.includes(path));
  const queryParams = new URLSearchParams(window.location.search);
  const status = queryParams.get("status") || "all";
  const listId = +(
    queryParams.get("listId") ||
    (status === "selectListWorking" ? queryParams.get("workingListId") : null)
  );
  const [
    deleteContact,
    {
      data: { deleteContact: { error: deleteContactError } = {} } = {},
      loading: deleteContactLoading,
      reset,
    },
  ] = useMutation(DELETE_CONTACT);
  const selected = Object.keys(formik?.values?.selected?.ids || {});
  const ids = selected?.map(key => {
    const obj = formik?.values?.selected?.ids?.[key];
    return obj?.item?.id || obj?.id;
  });
  const select = formik?.values?.selected?.select;

  const statusMap = {
    all: "All Contacts",
    my: "My Contacts",
    searched: "Searched Contacts",
    selectListWorking: "Select List (Working)",
  };
  const label = statusMap[status] || "All Contacts";
  // prepare steps for breadcrumbs
  const steps = [
    { label, onClick: () => navigate(`/contacts?status=${status}`) },
    ...(contact
      ? [
          {
            label: contact?.name,
            onClick: () => navigate(`${contact?.id}/details`),
          },
          {
            label: route?.name,
            onClick: () => navigate(`${contact?.id}/${route?.href}`),
          },
        ]
      : []),
  ];

  const breadcrumbProps = {
    currentStepIndex: steps?.length - 1,
    onChange: index => steps[index]?.onClick(),
    steps: steps?.map(item => item?.label),
  };

  const modalProps = {
    body: ContactSearch,
    closeOnBackdrop: true,
    scale: "fullscreen",
  };

  const deleteModalProps = {
    title: deleteContactError ? "Error" : "Are you sure?",
    scale: "sm",
    description: deleteContactError
      ? deleteContactError
      : `You are about to permeantly delete the contact record for ${contact?.name}.`,
    closeOnBackdrop: true,
    body: ({ closeModal }) => {
      const handleDelete = () => {
        deleteContact({
          variables: {
            input: { id: contact?.id },
          },
          update: (cache, { data }) => {
            const { deleteContact: { success } = {} } = data || {};
            if (success) {
              closeModal();
              cache.evict({
                id: cache.identify({
                  __typename: "Contact",
                  id,
                }),
              });
              navigate("/contacts");
            }
          },
        });
      };

      return (
        <div className="mt-4 flex">
          <Button
            className="mr-3"
            label={deleteContactError ? "Close" : "Cancel"}
            action={deleteContactError ? "primary" : "default"}
            onClick={() => {
              closeModal?.();
              reset();
            }}
          />
          {!deleteContactError && (
            <Button
              label={deleteContactLoading ? "Deleting" : "Delete"}
              disabled={deleteContactLoading}
              onClick={handleDelete}
            />
          )}
        </div>
      );
    },
  };

  const defaultActionProps = {
    closeOnBackdrop: false,
    hideCloseButton: true,
    ids: ids.length ? ids : [+id],
    listId,
    onClose: () => {
      formik?.resetForm();
    },
    scale: "md",
    search,
    select,
  };

  const actionMenuProps = {
    label: "Actions",
    options: [
      {
        label: "New contact",
        modalProps: {
          body: NewContact,
          ...defaultActionProps,
        },
      },
      {
        disabled: !ids.length && !id,
        label: "Update CRM Info",
        modalProps: {
          body: UpdateCRM,
          ...defaultActionProps,
        },
      },
      {
        disabled:
          (!ids.length && !id) ||
          !hasPermission("HANDOVER_CONTACT") ||
          (contact && contact?.salesUserId !== entityId),
        label: "Handover",
        modalProps: {
          body: HandoverBody,
          ...defaultActionProps,
        },
      },
      {
        disabled: !ids.length && !id,
        label: "Create an Adhoc Report",
        modalProps: {
          body: AdhocReportBody,
          ...defaultActionProps,
          exportModule,
        },
      },
      {
        disabled: !ids.length && !id,
        label: "Export Contact Report",
        modalProps: {
          ...defaultActionProps,
          body: ExportActiveReport,
          obj: {
            ...contactObj,
            purchases: { invoice: {} },
            requests: { artist: {} },
          },
          type: "Contact",
        },
      },
      {
        disabled: !ids.length && !id,
        label: "Export Emails",
        modalProps: {
          body: ExportEmailsBody,
          ...defaultActionProps,
        },
      },
      {
        disabled: !ids.length && !id,
        label: "Export Contacts",
        modalProps: {
          body: ExportContactsBody,
          ...defaultActionProps,
        },
      },
      {
        disabled: !ids.length && !id,
        label: "Subscribe",
        modalProps: {
          body: SubscribeModal,
          ...defaultActionProps,
        },
      },
      ...(id
        ? [
            {
              disabled: !hasPermission("DELETE_CONTACT"),
              label: "Delete contact",
              modalProps: deleteModalProps,
            },
            {
              label: "Merge contact",
              modalProps: {
                body: MergeContact,
                ...defaultActionProps,
                contact,
              },
            },
          ]
        : []),
    ],
  };

  return (
    <div className="flex items-center justify-between border-b px-8 py-5">
      <Breadcrumb {...breadcrumbProps} />
      <div className="flex justify-end gap-4">
        <Modal {...modalProps}>
          <Button label="Contact Search" action="black" />
        </Modal>
        <MenuButton {...actionMenuProps} />
      </div>
    </div>
  );
};

export default PrimaryNav;
