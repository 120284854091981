import { gql } from "@apollo/client";
import { Contact as contact } from "../fragments";

const CREATE_CONTACT = props => gql`
  mutation CreateContact($input: CreateContactInput!) {
    createContact(input: $input) {
      success
      error
      contact {
        ${contact(props)}
      }
    }
  }
`;

const DELETE_CONTACT = gql`
  mutation DeleteContact($input: DeleteContactInput!) {
    deleteContact(input: $input) {
      success
      error
    }
  }
`;

const EXPORT_ADHOC_REPORT_CONTACTS = gql`
  mutation ExportAdhocReportContacts($input: ExportContactsInput!) {
    exportAdhocReportContacts(input: $input) {
      error
      success
      url
    }
  }
`;

const EXPORT_CONTACTS = gql`
  mutation ExportContacts($input: ExportContactsInput!) {
    exportContacts(input: $input) {
      error
      success
      url
    }
  }
`;

const EXPORT_EMAILS = gql`
  mutation ExportEmails($input: ExportEmailsInput!) {
    exportEmails(input: $input) {
      error
      success
      url
    }
  }
`;

const HANDOVER_CONTACTS = gql`
  mutation HandoverContacts($input: HandoverContactsInput!) {
    handoverContacts(input: $input) {
      success
      error
    }
  }
`;

const MERGE_CONTACT = props => gql`
  mutation MergeContact($input: MergeContactInput!) {
    mergeContact(input: $input) {
      contact {
        ${contact(props)}
      }
      data
      error
      success
    }
  }
`;

const SUBSCRIBE_CONTACTS = gql`
  mutation SubscribeContacts($input: SubscribeContactsInput!) {
    subscribeContacts(input: $input) {
      success
      error
    }
  }
`;

const UPDATE_CONTACT = props => gql`
  mutation UpdateContact($input: UpdateContactInput!) {
    updateContact(input: $input) {
      success
      error
      contact {
        ${contact(props)}
      }
    }
  }
`;

const UPDATE_CRM_INFO = gql`
  mutation UpdateCRMInfo($input: UpdateCRMInfoInput!) {
    updateCRMInfo(input: $input) {
      success
      error
    }
  }
`;

export {
  CREATE_CONTACT,
  DELETE_CONTACT,
  EXPORT_ADHOC_REPORT_CONTACTS,
  EXPORT_CONTACTS,
  EXPORT_EMAILS,
  HANDOVER_CONTACTS,
  MERGE_CONTACT,
  SUBSCRIBE_CONTACTS,
  UPDATE_CONTACT,
  UPDATE_CRM_INFO,
};
