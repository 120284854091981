import useUser from "../../../../../../../../hooks/useUser";
import InstanceDetailCol1 from "./components/InstanceDetailCol1";
import InstanceDetailCol2 from "./components/InstanceDetailCol2";
import InstanceDetailCol3 from "./components/InstanceDetailCol3";
import InstanceDetailCol4 from "./components/InstanceDetailCol4";

const InstanceDetail = ({ formik }) => {
  const { hasPermission } = useUser();
  const isDisabled = !hasPermission("UPDATE_INSTANCE");

  return (
    <div
      className={`grid w-full grid-cols-4 gap-8 border-t border-gray-500 pt-8`}
    >
      <InstanceDetailCol1
        formik={formik}
        isDisabled={isDisabled}
        submitOnBlur={true}
      />
      <InstanceDetailCol2
        formik={formik}
        isDisabled={isDisabled}
        submitOnBlur={true}
      />
      <InstanceDetailCol3
        formik={formik}
        isDisabled={isDisabled}
        submitOnBlur={true}
      />
      <InstanceDetailCol4
        formik={formik}
        isDisabled={isDisabled}
        submitOnBlur={true}
      />
    </div>
  );
};

export default InstanceDetail;
