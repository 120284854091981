import { useMutation } from "@apollo/client";
import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { BellIcon } from "@heroicons/react/24/outline";
import LogoIcon from "../../../assets/icons/LogoIcon";
import SlideoutComponent from "./components/Slideout";
import { useHandleLogout } from "../../../util";

import useUser from "../../../hooks/useUser";
import { GENERATE_USER_TOKEN } from "../../../graphql/mutation/User";

import { Avatar } from "../Avatar";
import { Button } from "../Button";

export const Header = props => {
  const { user: loggedUser } = useUser();
  const handleLogout = useHandleLogout();
  const [generateUserToken] = useMutation(GENERATE_USER_TOKEN);

  const handleGenerateToken = () => {
    generateUserToken().then(({ data }) => {
      const { token } = data.generateUserToken;
      navigator.clipboard.writeText(token);
    });
  };

  const user = {
    name: loggedUser?.meta?.name,
    email: loggedUser?.email,
    imageUrl: loggedUser?.meta?.profilePic,
  };

  const classNames = (...classes) => classes.filter(Boolean).join(" ");

  const userNavigation = [
    { name: loggedUser?.email, href: "#" },
    { name: "Generate Token", onClick: handleGenerateToken },
    { name: "Sign out", href: "#", onClick: () => handleLogout() },
  ];

  return (
    <Disclosure as="nav" className="border-b border-gray-200 bg-white">
      {({ open }) => (
        <>
          <div className="mx-auto px-6">
            <div className="flex h-16 justify-between">
              <div className="flex">
                <div className="flex flex-shrink-0 items-center">
                  <span className="font-semi-bold capitalise ml-2 text-2xl tracking-widest">
                    <LogoIcon className="w-[200px]" />
                  </span>
                </div>
              </div>
              <div className="flex items-center">
                <div className="mr-3">
                  <SlideoutComponent />
                </div>
                <button
                  type="button"
                  className="rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                {/* Profile dropdown */}
                <Menu as="div" className="relative ml-3">
                  <div>
                    <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                      <span className="sr-only">Open user menu</span>
                      <Avatar
                        src={user.imageUrl}
                        label={user.name || "User Name"}
                        size="sm-2"
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-64 origin-top-right cursor-pointer rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {userNavigation?.map(item => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <a
                              href={item.href}
                              onClick={() => item?.onClick?.()}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block truncate px-4 py-2 text-sm text-gray-700",
                              )}
                              title={item.name}
                            >
                              {item.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <Disclosure.Panel>
            <div className="border-t border-gray-200 pb-3 pt-4">
              <div className="flex items-center px-4">
                <div className="flex-shrink-0">
                  <Avatar
                    src={user.imageUrl}
                    label={user.name || "User Name"}
                    size="md"
                  />
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium text-gray-800">
                    {user.name}
                  </div>
                </div>
                <button
                  type="button"
                  className="ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-3 space-y-1">
                {userNavigation?.map(item => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export const SectionHeader = props => {
  const { title, subtitle, actions } = props;
  return (
    <div className="pb-4">
      <div className="flex w-full flex-row p-5 pl-8 pr-8 font-bold">
        <div className="flex-1">
          <div className="text-2xl">{title}</div>
          {subtitle && <p className="my-4 font-medium">{subtitle}</p>}
        </div>
        <div
          className={`gap-4 ${(actions?.length > 1 || actions?.[0]?.component.length > 1) && "flex"}`}
        >
          {actions?.map((item, key) => {
            return item?.component ? (
              <Fragment key={key}>{item?.component()}</Fragment>
            ) : (
              <Button
                action={item.type}
                key={key}
                className={`mr-4 ${item.className}`}
                label={item.label}
                onClick={item.action}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
