import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import { useEffect } from "react";
import * as Yup from "yup";

import { Button, getInput } from "../../../../../../../components/ui";
import { UPDATE_CONTACT as updateContactMutation } from "../../../../../../../graphql/mutation/Contact";

const EditNameModal = props => {
  const { contact, closeModal, isOpen } = props;
  const [updateContact, { loading }] = useMutation(updateContactMutation());

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: contact?.email,
      forename: contact?.forename,
      middlename: contact?.middlename,
      surname: contact?.surname,
    },
    validationSchema: Yup.object({
      surname: Yup.string().required("Required"),
      email: Yup.string().email("Please enter a valid email."),
    }),
    onSubmit: async values => {
      updateContact({
        variables: {
          input: {
            id: contact?.id,
            ...values,
          },
        },
      }).then(resp => {
        const {
          data: { updateContact: { success } = {} },
        } = resp || {};
        if (success) {
          closeModal();
        }
      });
    },
  });

  useEffect(() => {
    formik.resetForm();
  }, [isOpen]);

  const inputs = [
    { label: "FIRST NAME", name: "forename", type: "text" },
    { label: "MIDDLE NAME", name: "middlename", type: "text" },
    { label: "SURNAME", name: "surname", type: "text" },
    { label: "EMAIL", name: "email", type: "text" },
  ];

  return (
    <div className="flex w-full flex-col p-8">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          Edit Contact Name
        </div>
        <div className="flex flex-row">
          <Button
            action="default"
            className="mr-8"
            label="Cancel"
            onClick={() => {
              closeModal();
            }}
          />
          <Button
            label={loading ? "Saving" : "Confirm"}
            disabled={loading}
            onClick={formik.submitForm}
          />
        </div>
      </div>
      <div className="grid w-full grid-cols-2 gap-x-20 gap-y-8 pb-10 pt-20">
        {inputs?.map((item, index) => {
          const inputProps = {
            ...item,
            bottomLabel: true,
            formik,
            labelRight: true,
            variant: "simpleRight",
          };
          return (
            <div key={index} className={`w-full ${item?.className}`}>
              {getInput(inputProps)}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default EditNameModal;
