import { gql } from "@apollo/client";
import { ArtworkStandard } from "../fragments";

const FETCH_ARTWORK_STANDARDS = gql`
  query FetchArtworkStandards {
    artworkStandards {
      ${ArtworkStandard}
    }
  }
`;

export { FETCH_ARTWORK_STANDARDS };
