import { useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import { useEffect } from "react";
import * as Yup from "yup";

import { Button } from "../../../../../../../components/ui";
import { GridInputs } from "../../../../../../../components/Functional";
import { CREATE_INSTANCES as createInstancesMutation } from "../../../../../../../graphql/mutation/Instance";
import { FETCH_COMPANIES as fetchCompanies } from "../../../../../../../graphql/query/Company";

const CreateWork = props => {
  const { edition, closeModal, isOpen } = props;
  const [createInstances, { loading }] = useMutation(
    createInstancesMutation(),
    {
      refetchQueries: ["FetchArtwork", "FetchInstancesV2"],
    },
  );
  const { data: { companies = [] } = {} } = useQuery(fetchCompanies());

  const handleClick = props => {
    createInstances({
      variables: {
        input: {
          editionId: edition?.id,
          ...formik?.values,
        },
      },
    }).then(resp => {
      const {
        data: { createInstances: { success } = {} },
      } = resp || {};
      if (success) {
        closeModal();
      }
    });
  };

  const inputs = {
    className: "grid grid-cols-2 gap-x-16 gap-y-4 mt-5",
    inputs: [
      {
        label: "Company",
        name: "companyId",
        options: companies?.edges?.map(item => ({
          label: item.name,
          value: item.id,
        })),
        type: "multi-select",
      },
      {
        label: "Number to Start at",
        name: "startAt",
        type: "multi-select",
        options: edition?.availableNumbers?.map(item => ({
          label: item,
          value: item,
        })),
      },
      {
        label: "Number to Create",
        name: "num",
        type: "multi-select",
        options: edition?.availableNumbers?.map((item, index) => ({
          label: index + 1,
          value: index + 1,
        })),
      },
    ],
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      num: 1,
      startAt: edition?.availableNumbers?.[0],
    },
    onSubmit: handleClick,
    validationSchema: Yup.object({
      companyId: Yup.mixed().required("Please select a company"),
      num: Yup.mixed().required("Please select number to create"),
      startAt: Yup.mixed().required("Please select a number to start at"),
    }),
  });

  useEffect(() => {
    formik.resetForm();
  }, [isOpen]);

  const inputProps = {
    formik,
    ...inputs,
  };
  return (
    <div className="flex w-full flex-col p-8">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          Create Work
        </div>
        <div className="flex flex-row">
          <Button
            action="default"
            className="mr-8"
            label="Cancel"
            onClick={() => {
              closeModal();
              formik.resetForm();
            }}
          />
          <Button
            label={loading ? "Creating" : "Create"}
            disabled={loading || edition?.availableNumbers?.length === 0}
            onClick={formik?.submitForm}
          />
        </div>
      </div>
      {edition?.availableNumbers?.length > 0 ? (
        <GridInputs {...inputProps} />
      ) : (
        <div className="mt-4 flex">
          All the works in this edition have already been created
        </div>
      )}
    </div>
  );
};

export default CreateWork;
