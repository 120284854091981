import { gql } from "@apollo/client";
import { ContactAttribute as contactAttribute } from "../fragments";

const FETCH_CONTACT_ATTRIBUTES = props => gql`
  query FetchContactAttributes($input: ContactAttributesInput!) {
    contactAttributes(input: $input) {
      count
      edges {
        ${contactAttribute(props)}
      }
    }
  }
`;

export { FETCH_CONTACT_ATTRIBUTES };
