import { useQuery } from "@apollo/client";
import { GridInputs } from "../../../../../components/Functional";
import { FETCH_ARTISTS as fetchArtists } from "../../../../../graphql/query/Artist";
import { FETCH_ARTWORK_CATEGORIES } from "../../../../../graphql/query/ArtworkCategory";
import { FETCH_INVOICE_ANALYSIS_EVENTS } from "../../../../../graphql/query/InvoiceAnalysisEvent";

const Sales = props => {
  const { formik } = props || {};

  const { data: { artists } = {} } = useQuery(fetchArtists());
  const { data: { invoiceAnalysisEvents } = {} } = useQuery(
    FETCH_INVOICE_ANALYSIS_EVENTS,
  );
  const { data: { artworkCategories } = {} } = useQuery(
    FETCH_ARTWORK_CATEGORIES,
  );

  const inputs = {
    className: "grid grid-rows-2 gap-4",
    inputs: [
      {
        className: "grid grid-cols-4 gap-4",
        inputs: [
          {
            label: "Purchased Artist",
            name: "_invoice._item._instance._edition._artwork._artist.id",
            options: artists?.map(artist => ({
              label: artist.name,
              value: artist.id,
            })),
            type: "multi-select",
          },
          {
            label: "Category",
            name: "_invoice._item._instance._edition._artwork.categoryId",
            options: artworkCategories?.map(item => ({
              label: item.name,
              value: item.id,
            })),
            type: "multi-select",
          },
          {
            label: "Value Start",
            name: "_invoice._item.price.value.from",
            operator: "gte",
            type: "number",
          },
          {
            label: "Value Ends",
            name: "_invoice._item.price.value.to",
            operator: "lte",
            type: "number",
          },
          {
            label: "Invoice Date Start",
            name: "_invoice.date.value.from",
            type: "date",
          },
          {
            label: "Invoice Date End",
            name: "_invoice.date.value.to",
            type: "date",
          },
          {
            label: "Total Spent Start",
            name: "totalSpent.value.from",
            type: "number",
          },
          {
            label: "Total Spent End",
            name: "totalSpent.value.to",
            type: "number",
          },
          {
            label: "Unit",
            name: "units",
            type: "number",
          },
          {
            label: "Analysis",
            name: "_invoice.analysisId",
            options: invoiceAnalysisEvents?.map(item => ({
              label: item.description,
              value: item.id,
            })),
            type: "multi-select",
          },
        ],
      },
    ],
  };

  const gridInputProps = {
    formik,
    ...inputs,
  };

  return <GridInputs {...gridInputProps} />;
};

export default Sales;
