import { useEffect } from "react";
import { GridInputs } from "../../../../../components/Functional";

const Invoice = props => {
  const { formik } = props || {};

  useEffect(() => {
    if (formik?.values?.unpaid) {
      formik?.setFieldValue("balanceAmount", {
        value: 0.01,
        operator: "gt",
      });
    } else {
      formik?.setFieldValue("balanceAmount", null);
    }
  }, [formik?.values?.unpaid]);

  const inputs = {
    className: "grid grid-cols-4 gap-16 mt-5",
    inputs: [
      {
        className: "flex flex-col gap-4",
        inputs: [
          {
            label: "Amount From",
            name: "netAmount.value.from",
            type: "number",
          },
          {
            label: "Amount To",
            name: "netAmount.value.to",
            type: "number",
          },
        ],
      },
      {
        className: "gap-4",
        inputs: [
          {
            label: "Notes",
            name: "notes",
            type: "textarea",
          },
        ],
      },
      {
        className: "gap-4",
        inputs: [
          {
            label: "Terms",
            name: "terms",
            type: "textarea",
          },
        ],
      },
      {
        className: "gap-4",
        inputs: [
          {
            designType: "checkbox",
            label: "Unpaid",
            name: "unpaid",
            type: "toggle",
          },
        ],
      },
    ],
  };

  const gridInputProps = {
    formik,
    ...inputs,
  };

  return (
    <div className="mb-8">
      <GridInputs {...gridInputProps} />
    </div>
  );
};

export default Invoice;
