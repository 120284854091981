import SectionHeader from "../../components/SectionHeader";
import { GridInputs } from "../../../../../../components/Functional";

const Description = props => {
  const { formik } = props;

  const inputs = {
    className: "grid grid-cols-4 gap-8",
    inputs: [
      {
        className: "grid-cols-1 col-span-4 gap-4",
        inputs: [
          {
            name: "description",
            rows: 40,
            type: "textarea",
            submitOnBlur: true,
          },
        ],
      },
    ],
  };

  const detailInputProps = {
    formik,
    ...inputs,
  };

  return (
    <div className="w-full">
      <SectionHeader title="Description" />
      <GridInputs {...detailInputProps} />
    </div>
  );
};

export default Description;
