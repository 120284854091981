import Format from "./Format";

const PhotoMaterial = ({ format = false } = {}) => `
  artworkId
  credit
  date
  description
  ${
    format
      ? `format {
      ${Format}
    }`
      : ""
  }
  formatId
  id
  number
  original
  photographer
  updatedAt
`;

export default PhotoMaterial;
