import { Table } from "../../../../../../components/Functional";
import { FETCH_REQUESTS as fetchRequests } from "../../../../../../graphql/query/Request";
import Actions from "./components/Actions";
import SectionHeader from "../../components/SectionHeader";
import * as React from "react";
import { Button, Modal } from "../../../../../../components/ui";
import AddRequest from "./components/AddRequest";
import { useQuery } from "@apollo/client";
import { FETCH_REQUEST_TYPES } from "../../../../../../graphql/query/RequestType";
import { FETCH_ARTISTS as fetchArtists } from "../../../../../../graphql/query/Artist";
// import { FETCH_ENTITIES } from "../../../../../../graphql/query/Entity";
import useUser from "../../../../../../hooks/useUser";

const ContactRequests = props => {
  const { contact, contactId } = props || {};
  const { data: { requestTypes = {} } = {} } = useQuery(FETCH_REQUEST_TYPES, {
    variables: { input: {} },
  });
  const { edges: requestTypeEdges = [] } = requestTypes;
  const { data: { artists = [] } = {} } = useQuery(fetchArtists());
  const { hasPermission } = useUser();
  const disabled = !hasPermission("EDIT_REQUEST");
  // const { data: { entities = {} } = {} } = useQuery(FETCH_ENTITIES)
  // const { edges: entityEdges = [] } = entities

  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "requests",
    headers: [
      { label: "Type", name: "type.name", sortKey: "typeId", type: "label" },
      {
        label: "Artist",
        name: "artist.name",
        sortKey: "artistId",
        type: "label",
      },
      { label: "Date", name: "date", sortKey: "date", type: "date" },
      {
        label: "Closed",
        name: "dateClosed",
        sortKey: "dateClosed",
        type: "date",
      },
      {
        label: "Budget Range",
        name: "budgetRange",
        type: "custom",
        component: props =>
          `${props?.values.budgetFrom ?? "NA"} - ${props?.values.budgetTo ?? "NA"}`,
      },
      {
        label: "Description",
        name: "description",
        sortKey: "description",
        type: "label",
      },
      { label: "Notes", name: "notes", sortKey: "notes", type: "label" },
      {
        label: "User",
        name: "salesUser.name",
        sortKey: "saleUserId",
        type: "label",
      },
      { label: "", name: "action", type: "custom", component: Actions },
    ],
    inputs: [
      {
        label: "Type",
        name: "typeId",
        type: "multi-select",
        view: "partial",
        options: requestTypeEdges?.map(item => ({
          label: item.name,
          value: item.id,
        })),
      },
      {
        label: "Artist",
        name: "artistId",
        type: "multi-select",
        view: "partial",
        options: artists?.map(item => ({ label: item.name, value: item.id })),
      },
      { label: "Date From", name: "from", type: "date", view: "partial" },
      { label: "Date To", name: "to", type: "date", view: "partial" },
      {
        label: "Close Date From",
        name: "dateClosedFrom",
        type: "date",
        view: "partial",
      },
      {
        label: "Close Date To",
        name: "dateClosedTo",
        type: "date",
        view: "partial",
      },
      {
        label: "Budget From",
        name: "budgetFrom",
        type: "number",
        view: "partial",
      },
      { label: "Budget To", name: "budgetTo", type: "number", view: "partial" },
      // { label: 'Sales User', name: 'salesUserId', type: 'multi-select', view: 'partial', options: entityEdges?.map(item => ({ label: item.name, value: item.id })) },
    ],
    FETCH_QUERY: fetchRequests({ artist: {}, salesUser: {}, type: {} }),
    variables: {
      contactId,
    },
    className: "sm:px-0 lg:px-0",
  };

  const modalProps = {
    body: AddRequest,
    closeOnBackdrop: true,
    contact,
    disabled: disabled || !hasPermission("UPDATE_REQUEST"),
    hideCloseButton: true,
    scale: "md",
  };

  const requestActions = [
    {
      component: () => {
        return (
          <Modal {...modalProps}>
            <Button
              label="Add Request"
              action="black"
              disabled={disabled || !hasPermission("UPDATE_REQUEST")}
            />
          </Modal>
        );
      },
    },
  ];
  return (
    <div className="w-full">
      <SectionHeader title="Requests" actions={requestActions} />
      <div className="mb-6 w-full">
        <Table {...tableProps} />
      </div>
    </div>
  );
};

export default ContactRequests;
