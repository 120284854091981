import { Fragment } from "react";
import PropTypes from "prop-types";
import { Menu } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { Modal } from "../Modal";

const MenuButton = props => {
  const { label, disabled, options = [] } = props || {};

  return (
    <Menu as="div" className="relative inline-block text-left">
      {({ open }) => (
        <>
          <div>
            <Menu.Button
              disabled={disabled}
              className={`flex w-full items-center justify-center rounded-md bg-black
          px-4 py-2 text-xs font-medium text-white
          ${disabled ? "bg-opacity-70" : "hover:bg-opacity-70"}`}
            >
              {label}
              <ChevronDownIcon
                className="hover:text--100 -mr-1 ml-2 h-5 w-5"
                aria-hidden="true"
              />
            </Menu.Button>
          </div>
          {/* <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      > */}
          {
            <Menu.Items
              className={`${open ? "block" : "hidden"} absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y
          divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
              static
            >
              {options?.map((item, index) => {
                return (
                  <div key={index} className="px-1 py-1">
                    <Menu.Item>
                      {({ active }) => {
                        return item?.modalProps ? (
                          <Modal {...item?.modalProps}>
                            <button
                              title={item?.title}
                              disabled={item?.disabled}
                              className={`${
                                active
                                  ? "bg-gray-100 text-black"
                                  : "text-gray-900"
                              } ${item?.disabled ? "bg-gray-300" : "hover:bg-gray-50"} group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                            >
                              {item?.label}
                            </button>
                          </Modal>
                        ) : (
                          <button
                            className={`${
                              active
                                ? "bg-gray-100 text-black"
                                : "text-gray-900"
                            } group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-gray-50`}
                            onClick={item?.onClick}
                          >
                            {item?.label}
                          </button>
                        );
                      }}
                    </Menu.Item>
                  </div>
                );
              })}
            </Menu.Items>
          }
          {/* </Transition> */}
        </>
      )}
    </Menu>
  );
};

MenuButton.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.array,
};

export { MenuButton };
