import SectionHeader from "../../components/SectionHeader";
import Details from "./components/Details";
import Coding from "./components/Coding";

const AdvisorSale = props => {
  const { formik } = props;

  return (
    <div className="w-full">
      <SectionHeader title="Advisor Sale" />
      <div className="flex w-full flex-row gap-8">
        <Details formik={formik} submitOnBlur={true} />
        <Coding formik={formik} submitOnBlur={true} />
      </div>
    </div>
  );
};

export default AdvisorSale;
