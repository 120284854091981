import { useMutation } from "@apollo/client";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

import LogoIcon from "../assets/icons/LogoIcon";
import { UNSUBSCRIBE_EMAIL_CONTACT } from "../graphql/mutation/EmailContact";

const Unsubscribe = () => {
  const { optOutKey } = useParams();
  const [
    unsubscribe,
    { loading, data: { unsubscribeEmailContact: { success } = {} } = {} },
  ] = useMutation(UNSUBSCRIBE_EMAIL_CONTACT);

  useEffect(() => {
    optOutKey &&
      unsubscribe({
        variables: {
          input: {
            optOutKey,
          },
        },
      });
  }, []);

  return (
    <div className="flex flex-col">
      <span className="m-2">
        <LogoIcon className="w-[200px]" />
      </span>
      <div className="m-2 flex items-center">
        {loading
          ? "Unsubscribing..."
          : success
            ? `You have been unsubscribed from our newsletters and will recieve a confirmation message shortly.`
            : "Something went wrong."}
      </div>
    </div>
  );
};

export default Unsubscribe;
