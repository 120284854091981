import { gql } from "@apollo/client";
import { ContactNumber as contactNumber } from "../fragments";

const CREATE_CONTACT_NUMBER = props => gql`
  mutation CreateContactNumber($input: CreateContactNumberInput!) {
    createContactNumber(input: $input) {
      contactNumber {
        ${contactNumber(props)}
      }
      error
      success
    }
  }
`;

const DELETE_CONTACT_NUMBER = gql`
  mutation DeleteContactNumber($input: DeleteContactNumberInput!) {
    deleteContactNumber(input: $input) {
      error
      success
    }
  }
`;

const UPDATE_CONTACT_NUMBER = props => gql`
  mutation UpdateContactNumber($input: UpdateContactNumberInput!) {
    updateContactNumber(input: $input) {
      contactNumber {
        ${contactNumber(props)}
      }
      error
      success
    }
  }
`;

export { CREATE_CONTACT_NUMBER, DELETE_CONTACT_NUMBER, UPDATE_CONTACT_NUMBER };
