import Entity from "./Entity";
import TechnicalType from "./TechnicalType";

const Technical = ({ type = false, user = false } = {}) => `
  closeDate
  date
  description
  id
  outcome
  startDate
  statusId
  technician
  ${
    type
      ? `type {
      ${TechnicalType}
    }`
      : ""
  }
  typeId
  ${
    user
      ? `user {
      ${Entity}
    }`
      : ""
  }
  userId
  updatedAt
`;

export default Technical;
