import { GridInputs } from "../../../../../components/Functional";

const Jobs = props => {
  const { formik } = props || {};

  const inputs = {
    className: "grid grid-cols-4 gap-16 mt-5",
    inputs: [
      {
        className: "flex flex-col gap-4",
        inputs: [
          {
            label: "Job #",
            name: "_jobItem.jobId",
            type: "number",
          },
        ],
      },
    ],
  };

  const gridInputProps = {
    formik,
    ...inputs,
  };

  return <GridInputs {...gridInputProps} />;
};

export default Jobs;
