import { useFormik } from "formik";
import { useEffect } from "react";
import { SearchBar } from "../../../../../components/ui";
import Filters from "./Filters";

const FilterBar = props => {
  const { onChange } = props || {};

  const inputs = [
    {
      name: "userId",
      label: "User",
      type: "select",
      options: [
        {
          label: "All",
          value: null,
        },
      ],
      view: "full",
    },
    { name: "section", label: "Section", type: "text", view: "partial" },
    { name: "action", label: "Action", type: "text", view: "partial" },
    { name: "from", label: "From", type: "date", view: "partial" },
    { name: "to", label: "To", type: "date", view: "partial" },
  ];

  const initialValues = {
    search: "",
    ...inputs
      ?.map(item => ({ ...item }))
      .reduce(
        (acc, item) => ({
          ...acc,
          [item.name]: item.value,
        }),
        {},
      ),
  };
  const formik = useFormik({
    initialValues,
  });

  useEffect(() => {
    onChange(formik.values);
  }, [formik.values]);

  const filterProps = {
    inputs,
    formik,
    type: "simple",
  };

  const searchBarProps = {
    name: "search",
    formik,
  };

  return (
    <div className="flex w-full flex-row justify-end">
      <div className="mr-4">
        <SearchBar {...searchBarProps} />
      </div>
      <Filters {...filterProps} />
    </div>
  );
};

export default FilterBar;
