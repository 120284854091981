import { FETCH_ARTWORKS as fetchArtworks } from "../../../graphql/query/Artwork";
import { FETCH_COMPANIES as fetchCompanies } from "../../../graphql/query/Company";
import { FETCH_MAILING_LISTS } from "../../../graphql/query/MailingList";
// import { FETCH_CONTACTS as fetchContacts } from "../../../graphql/query/Contact";
// import { FETCH_DOCUMENTS } from '../../../graphql/query/Document'
// import { FETCH_ROLES } from '../../../graphql/query/Role'
// import { FETCH_USERS } from '../../../graphql/query/User'

const tableProps = {
  artwork: {
    dataKey: "artworks",
    headers: [
      { label: "ID", name: "id", type: "label" },
      { label: "Title", name: "title", type: "label" },
      { label: "Description", name: "description", type: "label" },
    ],
    inputs: [
      { label: "Title", name: "title", type: "text", view: "partial" },
      { label: "Year", name: "year", type: "text", view: "partial" },
      { label: "From", name: "from", type: "date", view: "partial" },
      { label: "To", name: "to", type: "date", view: "partial" },
    ],
    FETCH_QUERY: fetchArtworks(),
  },
  company: {
    dataKey: "companies",
    headers: [
      { label: "ID", name: "id", type: "label" },
      { label: "Name", name: "name", type: "label" },
      { label: "Code", name: "code", type: "label" },
    ],
    inputs: [
      { label: "Name", name: "name", type: "text", view: "partial" },
      { label: "From", name: "from", type: "date", view: "partial" },
      { label: "To", name: "to", type: "date", view: "partial" },
    ],
    FETCH_QUERY: fetchCompanies(),
  },
  // 'contact': {
  //   dataKey: 'contacts',
  //   headers: [
  //     {label: 'Name', name: 'name', type: 'label'},
  //     {label: 'Company', name: 'company', type: 'label'},
  //     {label: 'Type', name: 'type.name', type: 'label'},
  //     {label: 'Job Title', name: 'title', type: 'label'},
  //     {label: 'Country', name: 'country.name', type: 'label'},
  //     {label: 'Sales User', name: 'salesUser.name', type: 'label'},
  //   ],
  //   inputs: [
  //     { label: 'Name', name: 'name', type: 'text', view: 'partial' },
  //     { label: 'Email', name: 'email', type: 'text', view: 'partial' },
  //   ],
  //   FETCH_QUERY: fetchContacts()
  // },
  mailing_list: {
    dataKey: "mailingLists",
    headers: [
      { label: "ID", name: "id", type: "label" },
      { label: "Description", name: "description", type: "label" },
    ],
    inputs: [
      { label: "From", name: "from", type: "date", view: "partial" },
      { label: "To", name: "to", type: "date", view: "partial" },
    ],
    FETCH_QUERY: FETCH_MAILING_LISTS,
  },
  // 'document': {
  //   dataKey: 'documents',
  //   headers: [
  //     {label: 'ID', name: 'id', type: 'label'},
  //     {label: 'Title', name: 'title', type: 'label'},
  //     {label: 'Owner', name: 'owner', type: 'label'},
  //   ],
  //   inputs: [
  //     { label: 'Title', name: 'title', type: 'text', view: 'partial' },
  //     { label: 'From', name: 'from', type: 'date', view: 'partial' },
  //     { label: 'To', name: 'to', type: 'date', view: 'partial' },
  //   ],
  //   FETCH_QUERY: FETCH_DOCUMENTS
  // },
  // 'role': {
  //   dataKey: 'roles',
  //   headers: [
  //     {label: 'ID', name: 'id', type: 'label'},
  //     {label: 'Name', name: 'name', type: 'label'},
  //     {label: 'Users Count', name: 'usersCount', type: 'label'},
  //   ],
  //   inputs: [
  //     { label: 'From', name: 'from', type: 'date', view: 'partial' },
  //     { label: 'To', name: 'to', type: 'date', view: 'partial' },
  //   ],
  //   FETCH_QUERY: FETCH_ROLES
  // },
  // 'user': {
  //   dataKey: 'users',
  //   headers: [
  //     {label: 'ID', name: 'id', type: 'label'},
  //     {label: 'Email', name: 'email', type: 'label'},
  //     {label: 'Role', name: 'role.name', type: 'label'},
  //   ],
  //   inputs: [
  //     { label: 'Email', name: 'email', type: 'text', view: 'partial' },
  //     { label: 'From', name: 'from', type: 'date', view: 'partial' },
  //     { label: 'To', name: 'to', type: 'date', view: 'partial' },
  //   ],
  //   FETCH_QUERY: FETCH_USERS
  // },
};

const getTableProps = model => {
  return tableProps?.[model] || null;
};

export { getTableProps };
