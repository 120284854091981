import moment from "moment";
import {
  BellIcon,
  BookOpenIcon,
  CurrencyDollarIcon,
  EnvelopeIcon,
  PhotoIcon,
  CalendarIcon,
  CurrencyPoundIcon,
} from "@heroicons/react/24/outline";
import { FETCH_OFFER as fetchOffer } from "../../../../../../../graphql/query/Offer";
import { useQuery } from "@apollo/client";

const getIcon = type => {
  switch (type) {
    case "note":
      return <BookOpenIcon className="h-6 w-6" />;
    case "collection":
      return <PhotoIcon className="h-6 w-6" />;
    case "auction":
      return <CurrencyDollarIcon className="h-6 w-6" />;
    case "engagement":
      return <CalendarIcon className="h-6 w-6" />;
    case "emailSent":
      return <EnvelopeIcon className="h-6 w-6" />;
    case "subscribe":
      return <BellIcon className="h-6 w-6" />;
    case "unsubscribe":
      return <BellIcon className="h-6 w-6" />;
    default:
      return <CurrencyPoundIcon className="h-6 w-6" />;
  }
};

const Activity = props => {
  const { contact, updatedAt, meta, user } = props || {};
  const { type } = meta || {};

  const updatedAtDMY = moment(updatedAt).format("DD-MM-YYYY");
  const updatedAtHMS = moment(updatedAt).format("HH:mm");

  const getBody = type => {
    switch (type) {
      case "note":
        return <div>{meta?.notes}</div>;
      case "collection":
        return (
          <div className="flex">
            <div className="flex h-32 w-32 items-center justify-center bg-gray-200">
              img
            </div>
            <div className="ml-2 flex flex-col">
              <div>{meta?.artworkName}</div>
              <div>{meta?.artistId}</div>
              <div>{meta?.notes}</div>
            </div>
          </div>
        );

      case "auction":
        return (
          <div>{`${meta?.auctionName}, ${meta?.date}, for item lot ${meta?.itemName}, ${meta?.currencyId} ${meta?.maxBid}, Won: ${meta?.isWon || false}`}</div>
        );
      case "engagement":
        return (
          <div>{`${meta?.name} - ${meta?.engagementType}, on ${meta?.date}`}</div>
        );
      case "emailSent":
        return (
          <div>
            {`This contact was sent an email through the Email Module on ${new Date(meta?.date).toLocaleString()} to email address ${meta?.contactEmail}
            by ${meta?.sentBy?.entityName}`}
          </div>
        );
      case "subscribe":
        return (
          <div>
            {`This contact was subscribed back on ${new Date(meta?.date).toLocaleString()} by ${meta?.activityBy?.entityName}`}
          </div>
        );
      case "unsubscribe":
        return (
          <div>
            {`This contact was unsubscribed on ${new Date(meta?.date).toLocaleString()}`}
          </div>
        );
      default:
        // eslint-disable-next-line
        const { data: { offer = {} } = {} } = useQuery(
          fetchOffer({ artwork: {}, status: {} }),
          { variables: { id: meta?.id } },
        );
        return (
          <div>
            {`${contact?.salutation} was sent an offer for
              Artwork: ${offer?.artwork?.title} by ${offer?.user} on ${offer?.date}.
              Offer status: ${offer?.status?.description}. Date Closed: ${offer?.dateClosed}`}
          </div>
        );
    }
  };

  return (
    <div className="flex items-center pt-[40px]">
      <div className="flex items-center">
        <div className="flex h-[40px] w-[40px] items-center justify-center rounded-full border border-black">
          {getIcon(type)}
        </div>
      </div>
      <div className="ml-[20px] flex-col ">
        <div className="mb-[10px] text-base">{getBody(type)}</div>
        <div className="mb-[10px] text-xs">{user?.name}</div>
        <div className="text-xs">{`${updatedAtHMS} on ${updatedAtDMY}`}</div>
      </div>
    </div>
  );
};

export default Activity;
