import { HomeIcon, DocumentTextIcon } from "@heroicons/react/24/outline";

import Designer from "./pages/Designer";
// import Viewer from "./pages/Viewer"

const routes = [
  {
    href: "/reports",
    icon: HomeIcon,
    name: "All Report Templates",
  },
  // {
  //   element: Viewer,
  //   href: "customer",
  //   icon: DocumentTextIcon,
  //   name: "Template Contact",
  // },
  {
    element: Designer,
    href: "edit",
    icon: DocumentTextIcon,
    name: "Template Designer",
  },
];

export default routes;
