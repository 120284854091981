import ContactNumberType from "./ContactNumberType";

const ContactNumber = ({ type = false } = {}) => `
  id
  donotcall
  identifier
  number
  prefere
  ${
    type
      ? `type {
      ${ContactNumberType}
    }`
      : ""
  }
  typeId
`;

export default ContactNumber;
