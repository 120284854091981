import SectionHeader from "../../components/SectionHeader";
import { GridInputs } from "../../../../../../components/Functional";
import useUser from "../../../../../../hooks/useUser";

const Terms = props => {
  const { formik } = props;
  const { hasPermission } = useUser();

  const inputs = {
    className: "grid grid-cols-4 gap-8",
    inputs: [
      {
        className: "grid-cols-1 col-span-4 gap-4",
        inputs: [
          {
            label: "",
            name: "termsback",
            rows: 40,
            type: "textarea",
            submitOnBlur: true,
            readOnly: true,
            disabled: !hasPermission("UPDATE_INVOICE"),
          },
        ],
      },
    ],
  };

  const detailInputProps = {
    formik,
    ...inputs,
  };

  return (
    <div className="w-full">
      <SectionHeader title="Terms" />
      <GridInputs {...detailInputProps} />
    </div>
  );
};

export default Terms;
