import { gql } from "@apollo/client";
import { List as list } from "../fragments";

const FETCH_LIST = props => gql`
  query FetchList($id: Int!) {
    list(id: $id) {
      ${list(props)}
    }
  }
`;

const FETCH_LISTS = props => gql`
  query FetchLists($input: ListsFilterInput!) {
    lists(input: $input) {
      count
      edges {
        ${list(props)}
      }
    }
  }
`;

export { FETCH_LIST, FETCH_LISTS };
