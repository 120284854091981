import {
  AtSymbolIcon,
  CalendarIcon,
  CameraIcon,
  DocumentTextIcon,
  DocumentMinusIcon,
  InformationCircleIcon,
  PhotoIcon,
  HandThumbUpIcon,
  Squares2X2Icon,
  Bars4Icon,
} from "@heroicons/react/24/outline";
import Bibliography from "./pages/Bibliography";

import Details from "./pages/Details";
import Editions from "./pages/Editions";
import Exhibitions from "./pages/Exhibitions";
import Fabrication from "./pages/Fabrication";
import Offers from "./pages/Offers";
import PhotographicMaterial from "./pages/PhotographicMaterial";
import Precis from "./pages/Precis";
import Technical from "./pages/Technical";

const routes = [
  {
    href: "/artworks",
    icon: PhotoIcon,
    name: "All Artworks",
  },
  {
    element: Details,
    href: "details",
    icon: DocumentTextIcon,
    name: "Artwork Detail",
  },
  {
    element: Editions,
    href: "editions",
    icon: Bars4Icon,
    name: "Editions",
  },
  {
    element: PhotographicMaterial,
    href: "photographic-material",
    icon: CameraIcon,
    name: "Photographic Material",
  },
  {
    element: Exhibitions,
    href: "exhibitions",
    icon: CalendarIcon,
    name: "Exhibitions",
  },
  {
    element: Bibliography,
    href: "bibliographys",
    icon: DocumentMinusIcon,
    name: "Bibliography",
  },
  {
    element: Technical,
    href: "technical",
    icon: InformationCircleIcon,
    name: "Technical",
  },
  {
    element: Fabrication,
    href: "fabrications",
    icon: Squares2X2Icon,
    name: "Fabrication",
  },
  {
    element: Offers,
    href: "offers",
    icon: HandThumbUpIcon,
    name: "Offers",
  },
  {
    element: Precis,
    href: "precis",
    icon: AtSymbolIcon,
    name: "Precis",
  },
];

export default routes;
