import { BarsArrowUpIcon, BarsArrowDownIcon } from "@heroicons/react/24/solid";
import { useEffect } from "react";
import { useFormik } from "formik";
import { getInput, Spinner, Tag } from "../ui";
import { getChangedFields, useDebounce } from "../../util";

const SubLabel = props => {
  const { label, widthInPx = 200 } = props || {};
  return (
    <div
      className="text-[15px] italic tracking-wide text-slate-500"
      style={{ maxWidth: `${widthInPx}px` }}
    >
      {label}
    </div>
  );
};

const Label = props => {
  const { label, labelClass, widthInPx = 200 } = props || {};
  const className =
    typeof labelClass == "function" ? labelClass?.(props) : labelClass;
  return (
    <div
      title={label}
      className={`overflow-hidden text-ellipsis text-base font-medium leading-5 text-black ${className && className}`}
      style={{ maxWidth: `${widthInPx}px` }}
    >
      {label}
    </div>
  );
};

const CurrencyLabel = props => {
  const { label, labelClass, widthInPx = 200 } = props || {};
  const className =
    typeof labelClass == "function" ? labelClass?.(props) : labelClass;
  return (
    <div
      title={label}
      className={`overflow-hidden text-ellipsis text-base font-medium leading-5 text-black ${className && className}`}
      style={{ maxWidth: `${widthInPx}px` }}
    >
      {label
        ? new Intl.NumberFormat("en-GB", {
            minimumFractionDigits: 2,
          }).format(label)
        : ""}
    </div>
  );
};

const MultiLabel = props => {
  const { name, formik } = props || {};
  return (
    <div className="flex flex-col">
      {name?.map((item, index) => {
        if (typeof item === "function") {
          return item?.(props);
        } else {
          const value = formik?.getFieldProps?.(item)?.value || "";
          return index === 0 ? (
            <Label key={index} label={value} />
          ) : (
            <SubLabel key={index} label={value} />
          );
        }
      })}
    </div>
  );
};

const Image = props => {
  const { label: imageSrc, formik } = props;
  let thumbnailSrc = imageSrc;

  if (
    thumbnailSrc === "" &&
    formik &&
    formik?.values?.instance?.edition?.artwork?.imagesSummary?.imgT
  ) {
    thumbnailSrc =
      formik?.values?.instance?.edition?.artwork?.imagesSummary?.imgT;
  }

  return (
    <div
      className={`flex h-[50px] w-[50px] items-center justify-center overflow-hidden border border-gray-300 ${imageSrc ? "" : "bg-gray-100"}`}
    >
      {thumbnailSrc && thumbnailSrc !== "" ? (
        <img src={thumbnailSrc} alt={"img"} />
      ) : (
        <span className="h-max text-center text-xs text-gray-500">
          NO
          <br />
          IMAGE
        </span>
      )}
    </div>
  );
};

const getType = props => {
  const { defaultValue, formik, name, type, component } = props || {};
  const values = formik?.values || {};
  const value = formik?.getFieldProps?.(name)?.value || defaultValue || "";
  const cProps = {
    ...props,
    id: values?.id,
    label: value,
    values,
  };

  switch (type) {
    case "custom":
      return component(cProps);
    case "currency":
      return (
        <Label
          label={
            value
              ? value?.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : "0.00"
          }
        />
      );
    case "date":
      return (
        <Label label={value ? new Date(value).toLocaleDateString() : ""} />
      );
    case "date-time":
      return <Label label={value ? new Date(value).toLocaleString() : ""} />;
    case "time":
      return (
        <Label label={value ? new Date(value).toLocaleTimeString() : ""} />
      );
    case "image":
      return <Image {...cProps} />;
    case "input":
      return getInput({ ...cProps, type: cProps?.inputType, label: null });
    case "label":
      return <Label {...cProps} />;
    case "multi-label":
      return <MultiLabel {...cProps} />;
    case "tag":
      // eslint-disable-next-line
      const tagProps = {
        ...cProps,
        action: formik?.values?.action,
      };
      return <Tag {...tagProps} />;
    case "currencylabel":
      return <CurrencyLabel {...cProps} />;
    default:
      return null;
  }
};

const Column = props => {
  const { item, fnProps } = props || {};
  let executableItem = item;

  if (typeof item === "function") {
    executableItem = item?.(fnProps);
  }
  const { onClick } = executableItem || {};
  const component = getType({ ...fnProps, ...executableItem });

  return (
    <td
      onClick={() => onClick?.(fnProps)}
      className={`border-0 border-b border-gray-200 leading-5 ${
        onClick ? "cursor-pointer hover:underline" : ""
      } containter-column whitespace-nowrap p-3 ${
        executableItem?.rowClass || ""
      }`}
    >
      <div
        className={`${
          executableItem?.name === "action"
            ? "opacity-0 group-hover:opacity-100"
            : ""
        }`}
      >
        {component}
      </div>
    </td>
  );
};

const Row = props => {
  const { headers, row, updateMutation } = props || {};

  const formik = useFormik({
    initialValues: {
      ...row,
    },
    enableReinitialize: true,
  });

  const debouncedValues = useDebounce(formik?.values, 500);
  const changedFields = getChangedFields(
    debouncedValues || {},
    formik?.initialValues || {},
  );

  useEffect(() => {
    if (Object.keys(changedFields).length > 0) {
      if (changedFields?.amount === "") {
        changedFields.amount = 0;
      }
      updateMutation?.({
        variables: {
          input: {
            id: formik?.values?.id,
            ...changedFields,
          },
        },
      });
    }
  }, [debouncedValues]);

  const fnProps = {
    formik,
    ...props,
  };

  return (
    <tr className="component-table-row group border-0 border-b border-gray-400">
      <td className="w-4 p-1">
        {formik?.values?.isnewData && (
          <span className="relative flex h-2 w-2">
            <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-sky-400 opacity-75"></span>
            <span className="relative inline-flex h-2 w-2 rounded-full bg-sky-500"></span>
          </span>
        )}
      </td>
      {headers?.map((item, index) => (
        <Column key={index} item={item} fnProps={fnProps} />
      ))}
    </tr>
  );
};

const Table = props => {
  const {
    formik,
    headers = [],
    loading,
    rows,
    updateMutation,
    variant = "default",
  } = props || {};
  const order = formik?.getFieldProps("order")?.value;

  const variants = {
    default: "h-[651px]",
    sm: "h-32",
    md: "h-80",
    lg: "h-90",
    modal: "h-[600px]",
    dynamicHeight: "",
  };
  const classes = variants[variant];

  const handleSort = item => {
    const { sortKey } = item;
    const { setValue } = formik.getFieldHelpers("order");

    if (order && order.length === 2) {
      if (order[0] === sortKey) {
        if (order[1] === "ASC") setValue([sortKey, "DESC"]);
        else if (order[1] === "DESC") setValue(null);
      } else setValue([sortKey, "ASC"]);
    } else {
      setValue([sortKey, "ASC"]);
    }
  };

  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto overflow-y-visible">
        <div className="inline-block min-w-full py-3 align-middle">
          <div className={`relative overflow-visible ${classes}`}>
            <table
              className={`min-w-full border-separate divide-y divide-gray-200`}
              style={{ borderSpacing: 0 }}
            >
              <thead className="bg-white">
                <tr>
                  {/* Spacing for new data indicator */}
                  <th className="w-4"></th>
                  {headers?.map((i, index) => {
                    let item = i;
                    if (typeof item === "function") {
                      item = item?.(props);
                    }
                    return (
                      <th
                        key={index}
                        scope="col"
                        className={
                          !item?.className &&
                          `border-0 border-b border-gray-200 p-3 text-left text-xs font-bold font-medium text-black`
                        }
                        {...(item?.sortKey
                          ? { onClick: () => handleSort(item) }
                          : {})}
                        style={{ width: `${item.width || "initial"}` }}
                      >
                        <div className="flex">
                          <div className={item?.className}>
                            {typeof item?.label === "function" ? (
                              item?.label(props)
                            ) : Array.isArray(item?.label) ? (
                              <div className="flex flex-col">
                                {item?.label?.map((label, index) => (
                                  <div key={index}>{label}</div>
                                ))}
                              </div>
                            ) : (
                              item?.label
                            )}
                          </div>
                          {item?.sortKey && (
                            <div className="ml-4">
                              {order &&
                              order.length == 2 &&
                              order[0] == item?.sortKey ? (
                                order[1] === "ASC" ? (
                                  <BarsArrowUpIcon className="h-4 w-4" />
                                ) : (
                                  <BarsArrowDownIcon className="h-4 w-4" />
                                )
                              ) : null}
                            </div>
                          )}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className={`divide-y divide-gray-200 bg-white`}>
                {loading || rows?.length === 0 ? (
                  <tr>
                    <td colSpan={headers.length}>
                      <div className="absolute relative inset-0 block flex w-full items-center justify-center py-12 text-black">
                        {loading ? (
                          <Spinner className="text-black" />
                        ) : (
                          <div className="text-black">No results found</div>
                        )}
                      </div>
                    </td>
                  </tr>
                ) : (
                  rows?.map((row, index) => {
                    const rowProps = {
                      headers,
                      row,
                      updateMutation,
                    };
                    return <Row key={index} {...rowProps} />;
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
