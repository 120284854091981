import { gql } from "@apollo/client";
import { WarehouseMGroup } from "../fragments";

const FETCH_WAREHOUSE_MGROUPS = gql`
  query FetchWarehouseMGroups($input: MGroupFilterInput!) {
    warehouseMGroups(input: $input) {
      ${WarehouseMGroup}
    }
  }
`;

export { FETCH_WAREHOUSE_MGROUPS };
