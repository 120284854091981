import { gql } from "@apollo/client";
import {
  Artwork as artwork,
  Contact as contact,
  Consignment as consignment,
  Instance as instance,
  Invoice as invoice,
  Job as job,
  Offer as offer,
  Shipment as shipment,
  WarehouseItem as warehouseItem,
} from "../fragments";

const EXPORT_ACTIVE_REPORT_JSON = props => {
  let fragment = "";
  switch (props.__typename) {
    case "Artwork":
      fragment = artwork(props);
      break;
    case "Contact":
      fragment = contact(props);
      break;
    case "Consignment":
      fragment = consignment(props);
      break;
    case "Instance":
      fragment = instance(props);
      break;
    case "Invoice":
      fragment = invoice(props);
      break;
    case "Job":
      fragment = job(props);
      break;
    case "Offer":
      fragment = offer(props);
      break;
    case "Shipment":
      fragment = shipment(props);
      break;
    case "WarehouseItem":
      fragment = warehouseItem(props);
      break;
    default:
      fragment = "";
      break;
  }

  return gql`
    mutation ExportActiveReportJSON($input: ExportActiveReportJSONInput!) {
      exportActiveReportJSON(input: $input) {
        count
        edges {
          __typename
          ... on ${props.__typename} {
            ${fragment}
          }
        }
        error
      }
    }
  `;
};

export { EXPORT_ACTIVE_REPORT_JSON };
