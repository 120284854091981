import instanceFrag from "./Instance";
import entity from "./Entity";

const ConsignmentWork = ({ instance = false, user = false } = {}) => `
  id
  conPrice
  consignmentId
  discount
  discountRate
  fabCost
  fabRate
  grossProfit
  instanceId
  ${
    instance
      ? `instance {
      ${instanceFrag(instance)}
    }`
      : ""
  }
  price
  ref
  share
  updatedAt
  ${
    user
      ? `user {
      ${entity}
    }`
      : ""
  }
`;

export default ConsignmentWork;
