/* eslint-disable max-len */
const LogoIcon = props => (
  <svg viewBox="0 0 757.333 66.638" xmlSpace="preserve" {...props}>
    <path
      style={{
        fill: "#000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="m0 0 9.765-28.904h3.906l12.276 39.06h-5.692l-8.76-28.29-9.542 28.29h-3.906l-9.542-28.29-8.76 28.29h-5.692l12.276-39.06h3.906zm82.879-28.904h5.58v39.06h-5.58V-5.188H61.117v15.344h-5.58v-39.06h5.58v18.414h21.762zm40.337 0h5.58v39.06h-5.58zm36.085 33.759h10.602v-33.759h5.58V4.855h10.602v5.301h-26.784zm62.93-10.043V4.855h16.74v5.301h-22.32v-39.06h22.878v5.301h-17.298v13.113h12.834v5.302zm85.317-4.185c0-11.552 8.593-20.089 20.255-20.089 6.028 0 11.83 2.957 15.178 7.255l-3.962 3.571c-2.176-2.623-5.971-5.524-11.216-5.524-8.314 0-14.675 5.969-14.675 14.787 0 8.425 6.194 14.786 14.173 14.786 4.185 0 8.258-1.897 10.546-4.408l3.739 3.682c-3.404 3.74-8.705 6.028-14.285 6.028-11.272 0-19.753-8.818-19.753-20.088m82.572-20.089c4.798 0 9.15 1.953 12.108 5.134 2.511 2.733 4.073 6.36 4.073 11.606v22.878h-5.58v-23.157c0-3.682-.892-5.971-2.455-7.7-1.953-2.177-4.854-3.459-8.146-3.459-3.292 0-6.195 1.282-8.148 3.459-1.561 1.729-2.455 4.018-2.455 7.7v23.157h-5.58v-22.878c0-5.246 1.563-8.873 4.074-11.606 2.957-3.181 7.309-5.134 12.109-5.134m56.144 5.859v13.113h8.483c3.514 0 5.188-.781 6.417-2.008 1.115-1.173 1.84-2.734 1.84-4.521a6.653 6.653 0 0 0-1.84-4.576c-1.229-1.227-2.903-2.008-6.417-2.008zm0 18.415V4.855h6.696c3.125 0 4.464-.614 5.357-1.619a4.9 4.9 0 0 0 1.339-3.403c0-1.34-.502-2.511-1.339-3.403-.893-1.005-2.232-1.618-5.357-1.618zm9.486-23.716c3.962 0 6.529.949 8.538 2.623a11.634 11.634 0 0 1 4.296 9.039c0 4.409-2.734 8.427-6.417 10.267 1.841 1.731 3.069 4.297 3.069 6.975 0 2.902-1.228 5.58-3.236 7.422-1.841 1.674-4.241 2.734-8.036 2.734h-13.28v-39.06zm50.292 23.716V4.855h16.74v5.301h-22.32v-39.06h22.878v5.301h-17.298v13.113h12.834v5.302z"
      transform="matrix(1.33333 0 0 -1.33333 48.596 21.762)"
    />
  </svg>
);

export default LogoIcon;
