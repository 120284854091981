import * as React from "react";

import Actions from "./components/Actions";
import AddAction from "./components/AddAction";
import SectionHeader from "../../components/SectionHeader";

import { Table } from "../../../../../../components/Functional";
import { FETCH_CRM_REGISTRARS as fetchCRMRegistrars } from "../../../../../../graphql/query/CRMRegistrar";
import { Modal, Button } from "../../../../../../components/ui";
import { useQuery } from "@apollo/client";
import { FETCH_CRM_CATEGORIES } from "../../../../../../graphql/query/CRMCategory";
import { FETCH_CRM_TYPES } from "../../../../../../graphql/query/CRMType";
import { FETCH_ENTITIES } from "../../../../../../graphql/query/Entity";
import { FETCH_JOB_STATUSES } from "../../../../../../graphql/query/JobStatus";

const ActionsModule = props => {
  const { contact, contactId } = props || {};
  const { data: { crmCategories = [] } = {} } = useQuery(FETCH_CRM_CATEGORIES);
  const { data: { crmTypes = [] } = {} } = useQuery(FETCH_CRM_TYPES);
  const { data: { jobStatuses = [] } = {} } = useQuery(FETCH_JOB_STATUSES);
  const { data: { entities = {} } = {} } = useQuery(FETCH_ENTITIES);
  const { edges: entityEdges = [] } = entities;

  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "crmRegistrars",
    headers: [
      {
        label: "Category",
        name: "category.description",
        sortKey: "categoryId",
        type: "label",
      },
      { label: "Date", name: "date", sortKey: "date", type: "date" },
      {
        label: "Type",
        name: "type.description",
        sortKey: "typeId",
        type: "label",
      },
      {
        label: "Action By",
        name: "user.name",
        sortKey: "userId",
        type: "label",
      },
      {
        label: "Description",
        name: "description",
        sortKey: "description",
        type: "label",
      },
      {
        label: "Status",
        name: "status.description",
        sortKey: "statusId",
        type: "label",
      },
      {
        label: "Setup By",
        name: "entity.name",
        sortKey: "entityId",
        type: "label",
      },
      { label: "", name: "actions", type: "custom", component: Actions },
    ],
    inputs: [
      { label: "Date From", name: "from", type: "date", view: "partial" },
      { label: "Date To", name: "to", type: "date", view: "partial" },
      {
        label: "Category",
        name: "categoryId",
        type: "multi-select",
        view: "partial",
        options: crmCategories?.map(item => ({
          label: item.description,
          value: item.id,
        })),
      },
      {
        label: "Type",
        name: "typeId",
        type: "multi-select",
        view: "partial",
        options: crmTypes?.map(item => ({
          label: item.description,
          value: item.id,
        })),
      },
      {
        label: "Action By",
        name: "userId",
        type: "multi-select",
        view: "partial",
        options: entityEdges?.map(item => ({
          label: item.name,
          value: item.id,
        })),
      },
      {
        label: "Status",
        name: "statusId",
        type: "multi-select",
        view: "partial",
        options: jobStatuses?.map(item => ({
          label: item.description,
          value: item.id,
        })),
      },
      {
        label: "Setup By",
        name: "entityId",
        type: "multi-select",
        view: "partial",
        options: entityEdges?.map(item => ({
          label: item.name,
          value: item.id,
        })),
      },
    ],
    FETCH_QUERY: fetchCRMRegistrars({
      category: {},
      entity: {},
      status: {},
      type: {},
      user: {},
    }),
    variables: {
      contactId: contactId,
    },
    className: "sm:px-0 lg:px-0",
  };

  const modalProps = {
    body: AddAction,
    closeOnBackdrop: true,
    contact,
    hideCloseButton: true,
    scale: "md",
  };

  const actions = [
    {
      component: () => {
        return (
          <Modal {...modalProps}>
            <Button label="Add Action" action="black" />
          </Modal>
        );
      },
    },
  ];

  return (
    <div className="w-full">
      <SectionHeader title="Actions" actions={actions} />
      <div className="mb-6 w-full">
        <Table {...tableProps} />
      </div>
    </div>
  );
};

export default ActionsModule;
