import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { GridInputs } from "../../../../../components/Functional";
import { FETCH_ARTISTS as fetchAritsts } from "../../../../../graphql/query/Artist";

const Requests = props => {
  const { formik } = props || {};
  const { data: { artists } = {} } = useQuery(fetchAritsts());

  useEffect(() => {
    if (formik?.values?._request?.pending) {
      formik?.setFieldValue("_request.dateClosed", {
        operator: "eq",
        value: null,
      });
    } else if (formik?.values?._request?.pending === false) {
      formik?.setFieldValue("_request.dateClosed", null);
    }
  }, [formik?.values?._request?.pending]);

  const inputs = {
    className: "grid grid-rows-2 gap-4",
    inputs: [
      {
        className: "grid grid-cols-4 gap-4",
        inputs: [
          {
            label: "Type",
            name: "_request.typeId",
            options: [
              { label: "Primary", value: 1 },
              { label: "Secondary", value: 3 },
            ],
            type: "multi-select",
          },
          {
            label: "Artist",
            name: "_request.artistId",
            options: artists?.map(artist => ({
              label: artist?.name,
              value: artist?.id,
            })),
            type: "multi-select",
          },
          {
            label: "Date Start",
            name: "_request.dateClosed.value.from",
            type: "date",
          },
          {
            label: "Date End",
            name: "_request.dateClosed.value.to",
            type: "date",
          },
          {
            label: "Budget From",
            name: "_request.budgetFrom.value.from",
            type: "number",
          },
          {
            label: "Budget To",
            name: "_request.budgetFrom.value.to",
            type: "number",
          },
          {
            label: "Description",
            name: "_request.description",
            type: "text",
          },
          {
            label: "Notes",
            name: "_request.notes",
            type: "text",
          },
          {
            label: "Pending",
            name: "_request.pending",
            designType: "checkbox",
            type: "toggle",
          },
        ],
      },
    ],
  };

  const gridInputProps = {
    formik,
    ...inputs,
  };

  return <GridInputs {...gridInputProps} />;
};

export default Requests;
