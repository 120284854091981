import { gql } from "@apollo/client";
import { WarehouseMove as warehouseMove } from "../fragments";

const CREATE_WAREHOUSE_MOVE = props => gql`
  mutation CreateWarehouseMove($input: WarehouseMoveInput!) {
    createWarehouseMove(input: $input) {
      error
      success
      warehouseMove {
        ${warehouseMove(props)}
      }
    }
  }
`;

const UPDATE_WAREHOUSE_MOVE = props => gql`
  mutation UpdateWarehouseMove($input: WarehouseMoveUpdateInput!) {
    updateWarehouseMove(input: $input) {
      error
      success
      warehouseMove {
        ${warehouseMove(props)}
      }
    }
  }
`;

export { CREATE_WAREHOUSE_MOVE, UPDATE_WAREHOUSE_MOVE };
