import { useFormik } from "formik";
import { useEffect } from "react";

import { Accordion, getInput, IconButton, Modal } from "../../../components/ui";
import { PencilIcon } from "@heroicons/react/24/outline";
import { Table } from "../../../components/Functional";
import { getTableProps } from "./getTableProps";

const AccordionHeader = props => {
  const { formik, isDelta = false, model, onClick } = props || {};
  const label = model.toUpperCase();
  const tableProps = getTableProps(model.toLowerCase());
  const applyIdsValue = formik.getFieldProps(
    `permissions.${model}.${model}_APPLY_IDS`,
  )?.value;

  const isApplyRadioProps = {
    formik,
    name: `permissions.${model}.${model}_APPLY_IDS`,
    type: "radio",
    options: [
      { label: "Enable (Allows access to selected few)", value: false },
      { label: "Disable (Allows access to everything)", value: true },
    ],
  };

  const toggleProps = props => {
    const { id } = props || {};
    const name = `permissions.${model}.${model}_IDS._${id}`;
    return {
      type: "toggle",
      disabled: applyIdsValue,
      name,
      ...formik.getFieldProps(name),
      onChange: value =>
        formik
          .getFieldHelpers(name)
          .setValue(value ? true : isDelta ? false : undefined),
    };
  };

  const inputTableProps = tableProps
    ? {
        ...tableProps,
        headers: [
          {
            label: "Apply",
            name: "ids",
            type: "custom",
            component: props => getInput(toggleProps(props)),
          },
          ...tableProps.headers,
        ],
        isAdmin: true,
        variant: "dynamicHeight",
      }
    : {};

  const modalProps = {
    title: `Scope Permissions for ${label}`,
    scale: "fullscreen",
    description: "Select rows to give access to",
    closeOnBackdrop: false,
    body: ({ closeModal }) => {
      return (
        <>
          <div className="mb-5 mt-5">
            <div className="flex">{getInput(isApplyRadioProps)}</div>
            <Table {...inputTableProps} />
          </div>
        </>
      );
    },
  };

  return (
    <div className="flex">
      {label}
      {tableProps && (
        <Modal {...modalProps}>
          <IconButton variant="clean" className="ml-5" title={"Edit Scope"}>
            <PencilIcon
              className={`h-4 w-4 cursor-pointer text-gray-400`}
              onClick={onClick}
            />
          </IconButton>
        </Modal>
      )}
    </div>
  );
};

const PermissionsView = props => {
  const {
    initialValues,
    permissionSet,
    onChange,
    roles = null,
    isDelta = false,
  } = props || {};

  const modelOptions =
    Object.keys(permissionSet)
      ?.sort()
      ?.map(key => ({
        label: key.toUpperCase(),
        value: key,
      })) || [];

  const filters = [
    {
      name: "type",
      label: "Type",
      type: "select",
      options: [
        { label: "All", value: "" },
        { label: "CREATE", value: "CREATE" },
        { label: "DELETE", value: "DELETE" },
        { label: "READ", value: "READ" },
        { label: "UPDATE", value: "UPDATE" },
        { label: "CONDITIONAL", value: "CONDITIONAL" },
        { label: "FUNCTIONAL", value: "FUNCTIONAL" },
      ],
    },
    {
      name: "model",
      label: "Model",
      type: "select",
      options: [{ label: "All", value: "" }, ...modelOptions],
    },
  ];

  const formik = useFormik({
    initialValues: { permissions: initialValues },
    enableReinitialize: true,
  });

  useEffect(() => {
    console.log("formik.values", formik?.values);
    onChange?.({ permissions: formik?.values?.permissions });
  }, [formik?.values]);

  const formikFilter = useFormik({
    initialValues: {},
  });

  const renderFilters = filters?.map((item, index) => {
    const inputProps = {
      formik: formikFilter,
      ...item,
      ...formikFilter?.getFieldProps(item.name),
    };

    return (
      <div key={index} className="col-span-1">
        {getInput(inputProps)}
      </div>
    );
  });

  const { model = "", type = "" } = formikFilter?.values || {};

  const sortedModalOptions = Object.keys(permissionSet)?.sort();
  const renderPermissions = sortedModalOptions
    ?.filter(keyModel => (model ? keyModel === model : true))
    ?.map((model, index) => {
      const modelPermissions = permissionSet[model] || {};

      const inputs =
        Object.entries(modelPermissions)
          ?.filter(([key, { type }]) => type !== "TABLE")
          ?.map(([permission]) =>
            type
              ? modelPermissions[[permission]].type === type
                ? permission
                : null
              : permission,
          )
          ?.filter(Boolean) || [];
      inputs.sort();

      const renderInputs = inputs?.map((perm, index) => {
        const name = `permissions.${model}.${perm}`;
        const inputProps = {
          formik,
          name,
          label: perm,
          variant: "horizontal",
          type: "toggle",
        };

        return (
          <div
            className={`grid grid-cols-${roles ? "2" : "1"} gap-4`}
            key={index}
          >
            <div className="flex items-center justify-center">
              {getInput(inputProps)}
            </div>
            {roles && (
              <div className={`grid auto-cols-auto grid-flow-col gap-4`}>
                {roles?.map((role, index) => {
                  const inputProps = {
                    value: role?.permissions?.[model]?.[perm],
                    designType: "checkbox",
                    type: "toggle",
                    disabled: true,
                  };
                  const keyExists =
                    role?.permissions?.[model]?.[perm] !== undefined;
                  return (
                    <div
                      className="flex w-12 items-center justify-center"
                      key={index}
                    >
                      {keyExists && getInput(inputProps)}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        );
      });

      const accordionHeaderProps = {
        model,
        formik,
        isDelta,
      };

      const accordionProps = {
        label: <AccordionHeader {...accordionHeaderProps} />,
        open: true,
      };

      return (
        <div className="flex w-full flex-col" key={index}>
          {inputs.length > 0 && (
            <Accordion key={index} {...accordionProps}>
              {renderInputs}
            </Accordion>
          )}
        </div>
      );
    });

  return (
    <>
      <div className="my-5 flex grid grid-cols-2 flex-row items-center gap-10">
        {renderFilters}
      </div>
      {roles && (
        <div className="grid grid-cols-2 gap-4 px-4">
          <div></div>
          <div className={`grid auto-cols-auto grid-flow-col gap-4`}>
            {roles?.map((role, index) => {
              return (
                <div
                  className="flex w-12 items-center justify-center"
                  key={index}
                >
                  {role?.name}
                </div>
              );
            })}
          </div>
        </div>
      )}
      {renderPermissions}
    </>
  );
};

export default PermissionsView;
