import { gql } from "@apollo/client";
import { InvoiceAnalysisEvent } from "../fragments";

const FETCH_INVOICE_ANALYSIS_EVENTS = gql`
  query FetchInvoiceAnalysisEvent {
    invoiceAnalysisEvents {
      ${InvoiceAnalysisEvent}  
    }
  }
`;

export { FETCH_INVOICE_ANALYSIS_EVENTS };
