import { gql } from "@apollo/client";
import { Status } from "../fragments";

const FETCH_STATUS = gql`
  query FetchStatus {
    status {
      ${Status}
    }
  }
`;

export { FETCH_STATUS };
