import { FETCH_LIST_ITEMS as fetchListItems } from "../../../graphql/query/ListItems";
import { SelectList } from "../../../components/Functional";
import { useNavigate } from "react-router-dom";

const Actions = props => {
  const { listId, workingListId } = props || {};
  const navigate = useNavigate();

  return (
    <div className="grid grid-flow-col gap-4">
      <SelectList.RemoveSelected {...props} />
      <SelectList.ClearList {...props} listId={listId || workingListId} />
      {listId ? (
        <SelectList.ExitEditMode
          onSuccess={() => (
            props?.onSuccess?.(), navigate("/jobs?status=selectListSaved")
          )}
        />
      ) : (
        <SelectList.SaveList
          {...{
            ...props,
            typeId: 17,
            onSuccess: () => (
              props?.onSuccess?.(), navigate("/jobs?status=selectListSaved")
            ),
          }}
        />
      )}
    </div>
  );
};

const tableProps = props => {
  const { listId, workingListId } = props || {};

  const tableProps = {
    dataKey: "listItems",
    fetchPolicy: "network-only",
    FETCH_QUERY: fetchListItems({
      item: {
        jobStatus: {},
        jobType: {},
        artworkDescriptions: {},
        pickupGroup: {},
      },
    }),
    skip: values => !values?.listId,
    variables: {
      // use working list if no listId is provided
      listId: listId || workingListId,
    },
  };

  return tableProps;
};

export { Actions, tableProps };
