import {
  ArchiveBoxIcon,
  CheckCircleIcon,
  DocumentTextIcon,
  HomeIcon,
} from "@heroicons/react/24/outline";
import CheckList from "./pages/CheckList";
import Contents from "./pages/Contents";
import WarehouseItemDetail from "./pages/Detail";
import Movement from "./pages/Movement";

const routes = [
  {
    href: "/warehouse",
    icon: HomeIcon,
    name: "All Warehouse Items",
  },
  {
    element: WarehouseItemDetail,
    href: "details",
    icon: DocumentTextIcon,
    name: "Warehouse Detail",
  },
  {
    element: Contents,
    href: "contents",
    icon: ArchiveBoxIcon,
    name: "Contents",
  },
  {
    element: CheckList,
    href: "checklist",
    icon: CheckCircleIcon,
    name: "CheckList",
  },
  {
    element: Movement,
    href: "movements",
    icon: DocumentTextIcon,
    name: "Movements",
  },
];

export default routes;
