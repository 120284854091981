import React from "react";
import { useFormik } from "formik";
import { useMutation, useQuery } from "@apollo/client";

import { getInput } from "../../../../components/ui/Input";
import { Button } from "../../../../components/ui/Button";
import { FETCH_PARTNER } from "../../../../graphql/query/Partner";
import { UPDATE_PARTNER } from "../../../../graphql/mutation/Partner";
import Header from "../../components/HeaderComponent";

const EmailFooter = () => {
  const { data } = useQuery(FETCH_PARTNER);
  const [updatePartner, { loading }] = useMutation(UPDATE_PARTNER);
  const formik = useFormik({
    initialValues: {
      meta: {
        ...data?.partner?.meta,
        emailFooter: data?.partner?.meta?.emailFooter || "",
      },
    },
    enableReinitialize: true,
  });
  const inputProps = {
    name: "meta.emailFooter",
    type: "textarea",
    rows: 20,
    formik,
    placeholder: "Config the text content for email footer",
  };
  const updateFooterEmail = () => {
    updatePartner({
      variables: {
        input: formik.values,
      },
    });
  };

  return (
    <div className="w-full">
      {/* <div className="w-full flex flex-row pb-10 font-bold">
      <SectionHeader title="Global Email Footer" />
    </div> */}
      <Header leftText={"Global Email Footer"} />
      <div className="flex flex-row py-10">{getInput(inputProps)}</div>
      <div className="mt-4 flex">
        <Button
          label="Save Email Footer"
          disabled={!formik.values?.meta?.emailFooter || loading}
          onClick={updateFooterEmail}
        />
      </div>
    </div>
  );
};

export default EmailFooter;
