// import { useEffect, useState } from "react";
import { FETCH_WAREHOUSE_ITEMS_V2 as fetchWarehouseItemsV2 } from "../../../../../graphql/query/WarehouseItem";
import { warehouseFilterStore } from "../../../components/useWarehouseFilter";

const SearchedWarehouses = props => {
  const tableProps = {
    dataKey: "warehouseItemsV2",
    FETCH_QUERY: fetchWarehouseItemsV2({ category: true, weightType: true }),
    variables: {
      ...warehouseFilterStore.get(),
    },
  };

  return tableProps;
};

export default SearchedWarehouses;
