import { Button, Modal } from "../../../../../../../components/ui";

const ArtworkFolder = () => {
  const modalProps = {
    title: "Artwork Folder",
    scale: "sm",
    closeOnBackdrop: false,
    body: () => {
      return <div>Artwork Folder</div>;
    },
  };

  return (
    <Modal {...modalProps}>
      <Button label="Artwork Folder" />
    </Modal>
  );
};

export default ArtworkFolder;
