import React from "react";

import SectionHeader from "../../components/SectionHeader";
import { Table } from "../../../../../../components/Functional";
import { FETCH_CONDITIONS_V2 as fetchConditionsV2 } from "../../../../../../graphql/query/Condition";
import ConditionStatus from "./components/ConditionStatus";

const InstanceCondition = props => {
  const { instanceId } = props || {};

  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "conditionsV2",
    headers: [
      { label: "Date", name: "date", type: "date" },
      { label: "Updated", name: "time", type: "date" },
      { label: "User", name: "user", type: "label" },
      {
        label: "Status",
        name: "status.description",
        type: "custom",
        component: ConditionStatus,
      },
      { label: "Frame Status", name: "mountStatus.description", type: "label" },
      { label: "Description", name: "description", type: "label" },
    ],
    inputs: [],
    FETCH_QUERY: fetchConditionsV2({ mountStatus: true, status: true }),
    variables: {
      _instance: {
        id: {
          operator: "eq",
          value: instanceId,
        },
      },
    },
    className: "sm:px-0 lg:px-0",
  };

  return (
    <div className="w-full">
      <SectionHeader title="Condition" />
      <div className="flex w-full">
        <div className="flex-1">
          <Table {...tableProps} />
        </div>
      </div>
    </div>
  );
};

export default InstanceCondition;
