import { gql } from "@apollo/client";
import { OfferStatus } from "../fragments";

const FETCH_OFFER_STATUSES = gql`
  query FetchOfferStatuses {
    offerStatuses {
      ${OfferStatus}
    }
  }
`;

export { FETCH_OFFER_STATUSES };
