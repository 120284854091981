import { useQuery } from "@apollo/client";
import { useEffect } from "react";
import { GridInputs } from "../../../../../components/Functional";
import { FETCH_ENTITIES } from "../../../../../graphql/query/Entity";
import { FETCH_CONTACT_LOCATIONS } from "../../../../../graphql/query/Contact";
import { FETCH_FIRST_CONTACTS } from "../../../../../graphql/query/FirstContact";

const ClientInfo = props => {
  const { formik } = props || {};

  const { data: { contactLocations = [] } = {} } = useQuery(
    FETCH_CONTACT_LOCATIONS,
  );
  const { data: { entities = {} } = {} } = useQuery(FETCH_ENTITIES);
  const { data: { firstContacts = [] } = {} } = useQuery(FETCH_FIRST_CONTACTS);

  const contactCategories = [
    { label: "Potential", value: "Potential" },
    { label: "Priority", value: "Priority" },
    { label: "None", value: "None" },
  ];

  const { contactCategory } = formik?.values || {};
  useEffect(() => {
    if (contactCategory === "None") {
      formik?.setFieldValue("_contactCategory.category", {
        operator: "notIn",
        value: ["Potential", "Priority"],
      });
    } else if (contactCategory) {
      formik?.setFieldValue("_contactCategory.category", {
        operator: "eq",
        value: contactCategory,
      });
    } else {
      formik?.setFieldValue("_contactCategory.category", null);
    }
  }, [contactCategory]);

  const inputs = {
    className: "grid grid-rows-2 gap-4",
    inputs: [
      {
        className: "grid grid-cols-4 gap-4",
        inputs: [
          {
            label: "Biography",
            name: "biography",
            type: "text",
          },
          {
            label: "Sales Category",
            name: "contactCategory",
            options: contactCategories?.map(item => ({
              label: item.label,
              value: item.value,
            })),
            type: "multi-select",
          },
          {
            label: "Notes",
            name: "notes",
            type: "text",
          },
          {
            label: "Rating",
            name: "level",
            options: [
              { label: "A+", value: 1 },
              { label: "A", value: 2 },
              { label: "B", value: 3 },
              { label: "C", value: 4 },
              { label: "G", value: 5 },
            ],
            type: "multi-select",
          },
        ],
      },
      {
        className: "grid grid-cols-4 gap-4",
        inputs: [
          {
            label: "First Met Type",
            name: "firstcontacttype",
            options: firstContacts?.map(item => ({
              label: item.description,
              value: item.id,
            })),
            type: "multi-select",
          },
          {
            label: "First Met Location",
            name: "firstcontactlocation",
            options: contactLocations?.map(item => ({
              label: item,
              value: item,
            })),
            type: "multi-select",
          },
          {
            label: "First Met Staff",
            name: "firstUserId",
            options: entities?.edges?.map(item => ({
              label: item?.name,
              value: item?.id,
            })),
            type: "multi-select",
          },
        ],
      },
      {
        className: "grid grid-cols-4 gap-4",
        inputs: [
          {
            label: "First Met Date From",
            name: "firstcontactdate.value.from",
            type: "date",
          },
          {
            label: "First Met Date To",
            name: "firstcontactdate.value.to",
            type: "date",
          },
          {
            label: "Confidentiality",
            name: "confidential",
            type: "select",
            options: [
              { label: "All", value: null },
              { label: "Confidential", value: -1 },
              { label: "Not Confidential", value: 0 },
            ],
            placeholder: "Select Confidentiality",
          },
        ],
      },
    ],
  };

  const gridInputProps = {
    formik,
    ...inputs,
  };

  return <GridInputs {...gridInputProps} />;
};

export default ClientInfo;
