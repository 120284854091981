import { useMutation } from "@apollo/client";

import { Button, Modal } from "../../../../../../../components/ui";
import { FETCH_CRM_REGISTRARS as fetchCRMRegistrars } from "../../../../../../../graphql/query/CRMRegistrar";
import { HANDOVER_CRM_REGISTRARS } from "../../../../../../../graphql/mutation/CRMRegistrar";

const HandoverModal = props => {
  const { closeModal, values } = props || {};
  const [handoverCRMRegistrars, { loading }] = useMutation(
    HANDOVER_CRM_REGISTRARS,
    { refetchQueries: [fetchCRMRegistrars()] },
  );

  const handleClick = () => {
    const { acceptHandovers: { ids: moveIds, select } = {} } = values || {};

    handoverCRMRegistrars({
      variables: {
        input: {
          ids: Object.keys(moveIds || {})?.map(key => +key.replace("_", "")),
          acceptAll: select === "all",
        },
      },
    }).then(resp => {
      const { data: { handoverCRMRegistrars: { success } = {} } = {} } = resp;

      if (success) {
        closeModal();
      }
    });
  };

  return (
    <div className="mt-6 flex w-full flex-col">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col">
          <div className="text-2xl font-bold">Accept Handover of Contacts</div>
          <div className="pt-4 text-base font-normal">
            {`Are you sure you?`}
          </div>
        </div>
        <div className="flex flex-col">
          <div>
            <Button
              action="default"
              className="mr-4"
              label="Cancel"
              onClick={() => {
                closeModal();
              }}
            />
            <Button
              label={loading ? "Confirming" : "Confirm"}
              disabled={loading}
              onClick={handleClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const AcceptHandoverModal = props => {
  const { formik } = props || {};

  const disabled =
    Object.keys(formik?.values?.acceptHandovers?.ids || {})?.length == 0;
  const modalProps = {
    body: HandoverModal,
    closeOnBackdrop: false,
    disabled,
    hideCloseButton: true,
    scale: "md",
    values: formik?.values,
    onClose: () => {
      formik?.resetForm();
    },
  };

  return (
    <Modal {...modalProps}>
      <Button label="Accept Handover" disabled={disabled} action="dark" />
    </Modal>
  );
};

export default AcceptHandoverModal;
