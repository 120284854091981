import { getInput } from "../../ui";

const GridInputs = props => {
  const { className, inputs, formik } = props || {};

  if (inputs) {
    return (
      <div className={`grid h-fit ${className}`}>
        {inputs?.map((item, index) => {
          const itemProps = {
            ...item,
            formik,
          };

          return <GridInputs key={index} {...itemProps} />;
        })}
      </div>
    );
  } else {
    const inputProps = {
      ...props,
      formik,
    };

    return inputProps.type === "title" ? (
      <div className={"mt-10"}>{inputProps.label}</div>
    ) : (
      <>{getInput(inputProps)}</>
    );
  }
};

export { GridInputs };
