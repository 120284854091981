import * as React from "react";
import { useQuery } from "@apollo/client";

import AddAuction from "./components/AddAuction";
import SectionHeader from "../../components/SectionHeader";
import { Table } from "../../../../../../components/Functional";
import { Button, Modal } from "../../../../../../components/ui";
import { FETCH_CURRENCIES } from "../../../../../../graphql/query/Currency";
import { FETCH_INSTANCE_AUCTIONS } from "../../../../../../graphql/query/InstanceAuctions";
import Actions from "./components/Actions";
import useUser from "../../../../../../hooks/useUser";

const InstanceAuctions = props => {
  const { instanceId } = props || {};
  const { hasPermission } = useUser();
  const { data: { currencies = [] } = {} } = useQuery(FETCH_CURRENCIES);

  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "instanceAuctions",
    headers: [
      { label: "Date", name: "saleDate", type: "date" },
      { label: "Event", name: "event", type: "label" },
      { label: "Auctioner", name: "auctioneer", type: "label" },
      { label: "Lot #", name: "lot", type: "label" },
      { label: "Total Price", name: "total", type: "currency" },
      {
        label: "Currency",
        type: "custom",
        component: props => (
          <span className="flex gap-1">
            <span className="overflow-hidden text-ellipsis text-base font-medium leading-5 text-black">
              {
                currencies.find(item => item.id === props.values.currencyId)
                  ?.code
              }
            </span>
          </span>
        ),
      },
      { label: "", name: "action", type: "custom", component: Actions },
    ],
    inputs: [],
    FETCH_QUERY: FETCH_INSTANCE_AUCTIONS,
    className: "sm:px-0 lg:px-0",
    variables: {
      instanceId: +instanceId,
    },
  };

  const modalProps = {
    body: AddAuction,
    closeOnBackdrop: true,
    disabled: !hasPermission("CREATE_INSTANCE_AUCTION"),
    hideCloseButton: true,
    instanceId,
    scale: "md",
  };

  const actions = [
    {
      component: () => {
        return (
          <Modal {...modalProps}>
            <Button
              disabled={!hasPermission("CREATE_INSTANCE_AUCTION")}
              label="Add Auction"
              action="black"
            />
          </Modal>
        );
      },
    },
  ];

  return (
    <div className="w-full">
      <SectionHeader title="Auctions" actions={actions} />
      <div className="mb-6 w-full">
        <Table {...tableProps} />
      </div>
    </div>
  );
};

export default InstanceAuctions;
