import { useQuery } from "@apollo/client";
import { GridInputs } from "../../../../../components/Functional";
import { FETCH_CATALOGUES } from "../../../../../graphql/query/Catalogue";

const MaterialSent = props => {
  const { formik } = props || {};
  const { data: { catalogues = [] } = {} } = useQuery(FETCH_CATALOGUES);

  const inputs = {
    className: "grid grid-rows-2 gap-4",
    inputs: [
      {
        className: "grid grid-cols-4 gap-4",
        inputs: [
          {
            label: "CRM",
            name: "crm",
            type: "text",
          },
          {
            label: "Catalogues",
            name: "_contactCatalogue.catalogueId",
            options: catalogues.map(item => ({
              label: item.title,
              value: item.id,
            })),
            type: "multi-select",
          },
        ],
      },
    ],
  };

  const gridInputProps = {
    formik,
    ...inputs,
  };

  return <GridInputs {...gridInputProps} />;
};

export default MaterialSent;
