import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";

export const Pagination = props => {
  const {
    customPageRange,
    count = 0,
    limit = 10,
    offset = 0,
    onChange,
    showSteps = true,
    statsComponent,
    variant = "lg",
  } = props || {};
  const pages = Math.ceil(count / limit);
  const currentPage = Math.floor(offset / limit);

  const styles = {
    selected:
      "z-10 bg-gray-50 border-black text-black hidden md:inline-flex justify-center",
    default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50",
  };

  const variants = {
    xs: {
      classes: "sm:flex-col",
      pageRange: 1,
      centerPageRange: 3,
      arrows:
        "cursor-pointer relative inline-flex items-center px-2 py-2 border border-black bg-white text-sm font-medium text-black hover:bg-gray-50 justify-center w-[44px]",
      nav: "relative z-0 inline-flex rounded-md shadow-sm -space-x-px mb-4",
      main: "bg-white py-3 flex items-center justify-between",
      navItem: "w-[44px]",
    },
    sm: {
      classes: "sm:flex-col",
      pageRange: 1,
      centerPageRange: 3,
      arrows:
        "cursor-pointer relative inline-flex items-center px-2 py-2 border border-black bg-white text-sm font-medium text-black hover:bg-gray-50 justify-center w-[65px]",
      nav: "relative z-0 inline-flex rounded-md shadow-sm -space-x-px mb-4",
      main: "bg-white px-4 py-3 flex items-center justify-between sm:px-6",
      navItem: "w-[65px]",
    },
    lg: {
      classes: "sm:flex-row",
      pageRange: 2,
      centerPageRange: 5,
      arrows:
        "cursor-pointer relative inline-flex items-center px-2 py-2 border border-black bg-white text-sm font-medium text-black hover:bg-gray-50 justify-center w-[65px]",
      nav: "relative z-0 inline-flex rounded-md shadow-sm -space-x-px mb-4",
      main: "bg-white px-4 py-3 flex items-center justify-between sm:px-6",
      navItem: "w-[65px]",
    },
    simple: {
      classes: "sm:flex-row",
      pageRange: 2,
      centerPageRange: 5,
      arrows:
        "cursor-pointer relative inline-flex items-center px-2 py-2 bg-white text-sm font-medium text-black hover:bg-gray-50 justify-center w-[65px]",
      nav: "relative z-0 inline-flex -space-x-px mb-4",
      main: "bg-white py-3 flex items-center justify-between",
      navItem: "w-[65px]",
    },
  };
  const paginationVariant = variants[variant];
  const pageRange = paginationVariant.pageRange;
  const centerPageRange = customPageRange || paginationVariant.centerPageRange;
  const minLength = pageRange * 2 + centerPageRange;

  const goToNext = () => {
    const nextPage = currentPage + 1;
    if (nextPage < pages) {
      onChange({ offset: nextPage * limit });
    }
  };

  const goToPrevious = () => {
    const previousPage = currentPage - 1;
    if (previousPage >= 0) {
      onChange({ offset: previousPage * limit });
    }
  };

  const handleClick = page => {
    onChange({ offset: page * limit });
  };

  const pagesMap = Array.from({ length: pages }, (_, i) => i);
  const renderPageSteps = pagesMap?.map(i => {
    return (
      <a
        key={i}
        className={`${paginationVariant.navItem} ${i === currentPage ? styles.selected : styles.default} relative cursor-pointer items-center border p-2 text-center text-sm font-medium`}
        onClick={e => {
          handleClick(i);
          e.stopPropagation();
        }}
      >
        {i + 1}
      </a>
    );
  });

  let newRenderSteps = renderPageSteps;
  const startSteps = [];
  const endSteps = [];
  const centerSteps = [];

  if (pages > minLength) {
    const currentPageNumber = currentPage + 1;

    // Start Steps
    if (currentPageNumber > centerPageRange) {
      for (let i = 0; i < pageRange; i++) {
        const findRender = renderPageSteps.find(step => step.key == i);
        startSteps.push(findRender);
      }
    } else {
      for (let i = 0; i < pageRange + 1; i++) {
        const findRender = renderPageSteps.find(step => step.key == i);
        startSteps.push(findRender);
      }
    }

    // Center Steps
    let startOfCenterStep;
    let endOfCenterStep;
    if (
      currentPageNumber > centerPageRange &&
      currentPageNumber < pages - pageRange - centerPageRange
    ) {
      startOfCenterStep = currentPageNumber - Math.floor(centerPageRange / 2);
      endOfCenterStep = currentPageNumber + Math.floor(centerPageRange / 2);
    } else if (
      currentPageNumber > centerPageRange &&
      currentPageNumber >= pages - pageRange - centerPageRange
    ) {
      startOfCenterStep = pages - pageRange - centerPageRange;
      endOfCenterStep = pages;
    } else {
      startOfCenterStep = centerPageRange - Math.floor(centerPageRange / 2) + 1;
      endOfCenterStep = centerPageRange + Math.floor(centerPageRange / 2) + 1;
    }
    for (let i = startOfCenterStep; i <= endOfCenterStep; i++) {
      const findRender = renderPageSteps.find(step => step.key == i - 1);
      centerSteps.push(findRender);
    }

    // End Steps
    for (let i = pages - (pageRange - 1); i <= pages; i++) {
      const findRender = renderPageSteps.find(step => step.key == i - 1);
      endSteps.push(findRender);
    }

    if (currentPageNumber > centerPageRange) {
      const jumpStep = Math.ceil((pageRange + startOfCenterStep) / 2);

      const gotoPage = jumpStep < 1 ? currentPage : jumpStep;
      newRenderSteps = [
        ...startSteps,
        <a
          key={"leftDots"}
          className={`relative cursor-pointer items-center border px-4 py-2 text-center text-sm font-medium ${paginationVariant.navItem}`}
          onClick={e => {
            e.stopPropagation();
            handleClick(gotoPage - 1);
          }}
        >
          ...
        </a>,
        ...centerSteps,
      ];
    } else {
      newRenderSteps = [...startSteps, ...centerSteps];
    }

    if (endOfCenterStep < pages - pageRange) {
      const jumpStep = Math.ceil((pages - pageRange + 1 + endOfCenterStep) / 2);

      const gotoPage = jumpStep > pages ? currentPage : jumpStep;
      newRenderSteps = [
        ...newRenderSteps,
        <a
          key={"rightDots"}
          className={`relative cursor-pointer items-center border px-4 py-2 text-center text-sm font-medium ${paginationVariant.navItem}`}
          onClick={e => {
            e.stopPropagation();
            handleClick(gotoPage - 1);
          }}
        >
          ...
        </a>,
        ...endSteps,
      ];
    } else {
      newRenderSteps = [...newRenderSteps, ...endSteps];
    }

    newRenderSteps = [...new Set(newRenderSteps)];
  }

  return (
    <div className={paginationVariant.main}>
      <div className="flex flex-1 justify-between sm:hidden">
        <a
          onClick={goToPrevious}
          className="relative inline-flex cursor-pointer items-center rounded-md border border-black bg-white px-4 py-2 text-sm font-medium text-black hover:bg-gray-50"
        >
          Previous
        </a>
        <a
          onClick={goToNext}
          className="relative ml-3 inline-flex cursor-pointer items-center rounded-md border border-black bg-white px-4 py-2 text-sm font-medium text-black hover:bg-gray-50"
        >
          Next
        </a>
      </div>
      <div
        className={`hidden sm:flex sm:flex-1 sm:items-center sm:justify-between ${paginationVariant.classes}`}
      >
        <div>
          <nav className={paginationVariant.nav} aria-label="Pagination">
            <a
              onClick={goToPrevious}
              className={`${paginationVariant.arrows} rounded-l-md`}
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </a>
            {showSteps && newRenderSteps}
            <a
              onClick={goToNext}
              className={`${paginationVariant.arrows} rounded-r-md`}
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </a>
          </nav>
        </div>
        <div>
          <p className="capitalise text-xs font-bold text-black">
            {statsComponent}
            Showing {count > 1 ? offset + 1 : offset} to{" "}
            {offset + limit > count ? count : offset + limit} of {count} results
          </p>
        </div>
      </div>
    </div>
  );
};
