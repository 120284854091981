import { gql } from "@apollo/client";
import { ConsignmentChannel } from "../fragments";

const FETCH_CONSIGNMENT_CHANNELS = gql`
  query ConsignmentChannels {
    consignmentChannels {
      ${ConsignmentChannel}
  }
}
`;

export { FETCH_CONSIGNMENT_CHANNELS };
