import { useQuery } from "@apollo/client";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { useState } from "react";

import { useDebounce } from "../../../../../util";
import {
  getInput,
  IconButton,
  ValidationError,
} from "../../../../../components/ui";
import { FETCH_EMAIL_CONTACTS } from "../../../../../graphql/query/EmailContact";

const Review = props => {
  const { email, formik, timeZones, isValidAttachmentSize } = props || {};
  const [contactIndex, setContactIndex] = useState(0);

  const limit = 10;
  const [filters, setFilters] = useState({
    limit,
    offset: 0,
    emailId: +email?.id,
  });
  const debouncedFilters = useDebounce(filters, 100);
  const skip = Object.keys(debouncedFilters).length === 0;
  const { previousData, data } = useQuery(FETCH_EMAIL_CONTACTS, {
    fetchPolicy: "network-only",
    skip,
    variables: {
      input: { ...debouncedFilters, isValid: true, isUnsubscribed: false },
    },
  });
  const { emailContacts = {} } = data || previousData || {};
  const { count, edges = [] } = emailContacts || {};

  const radioInputs = [
    {
      name: "meta.sendType",
      label: "Select option below:",
      type: "radio",
      options: [
        { label: "Send immediately", value: "immediate" },
        { label: "Schedule to send later", value: "schedule" },
      ],
    },
  ];

  const scheduleInputs = [
    {
      name: "meta.schedule.date",
      label: "Select scheduled date",
      type: "date",
    },
    {
      name: "meta.schedule.time",
      label: "Select scheduled time",
      type: "time",
    },
    {
      name: "meta.schedule.timeZone",
      label: "Select scheduled timezone",
      type: "multi-select",
      options: timeZones?.map(item => ({
        label: `${item?.name}, GMT${item?.rawFormat?.split(" - ")[0]}`,
        value: `${item?.name}`,
      })),
    },
  ];

  const renderRadioInputs = radioInputs?.map((item, index) => {
    const inputProps = {
      formik,
      ...item,
      ...formik?.getFieldProps(item.name),
    };

    return (
      <div key={index} className="flex flex-1">
        {getInput(inputProps)}
      </div>
    );
  });

  const renderScheduleInputs = scheduleInputs?.map((item, index) => {
    const inputProps = {
      formik,
      ...item,
      ...formik?.getFieldProps(item.name),
    };

    return <div key={index}>{getInput(inputProps)}</div>;
  });

  const { meta } = formik?.values || {};

  const validatingFields = [
    { name: "meta.subject", label: "Subject" },
    {
      name: "meta.body",
      label: "Body",
      value: meta?.body?.replaceAll("&nbsp;", " "),
    },
    {
      name: "meta.files",
      label: "Attachments",
      value: "size of all attachments (15 MB max)",
      isValid: isValidAttachmentSize,
    },
    { name: "meta.sender", label: "Sender" },
    {
      name: "meta.recipient",
      label: "Recipient",
      isValid: emailContacts?.count > 0,
      value: `will be sent to ${emailContacts?.count} contacts.`,
    },
    { name: "meta.sendType", label: "Send Type" },
    ...(meta?.sendType === "immediate"
      ? []
      : [
          {
            name: "meta.schedule",
            label: "Schedule",
            validate: "text",
            value: `${meta?.schedule?.date || "----/--/--"} ${meta?.schedule?.time || "--:--"} - ${meta?.schedule?.timeZone}`,
          },
        ]),
  ];

  const validationProps = {
    formik,
    fields: validatingFields,
  };

  const handlePrevContact = () => {
    const currentIndex = contactIndex + filters?.offset;
    const newContactIndex = currentIndex - 1 < 0 ? 0 : currentIndex - 1;
    const offset = Math.floor(newContactIndex / limit) * limit;
    setFilters({ ...filters, offset });
    setContactIndex(newContactIndex - offset);
  };

  const handleNextContact = () => {
    const currentIndex = contactIndex + filters?.offset;
    const newContactIndex =
      currentIndex + 1 > count - 1 ? count - 1 : currentIndex + 1;
    const offset = Math.floor(newContactIndex / limit) * limit;
    setFilters({ ...filters, offset });
    setContactIndex(newContactIndex - offset);
  };

  return (
    <>
      <div className="grid grid-cols-2 gap-4">
        <div className="pr-24">
          <div>
            <p>Mailing List Validation</p>
            <div className="mb-4 mt-4">
              <ValidationError {...validationProps} />
            </div>
          </div>
          <div className="mt-4">{renderRadioInputs}</div>
          <div className="mt-4 px-10">
            <div className="grid grid-cols-1 gap-4">
              {meta?.sendType == "schedule" && renderScheduleInputs}
            </div>
          </div>
        </div>

        <div className="mt-4">
          {edges?.length > 0 && (
            <>
              <p>Individual Recipient Preview</p>
              <div className="flex grid grid-cols-4 items-end py-6">
                <div className="col-span-3">
                  <p className="py-2">
                    <b className="mr-5">Recipient</b>
                    {edges?.[contactIndex]?.meta?.name}{" "}
                    {edges?.[contactIndex]?.meta?.email
                      ? `(${edges?.[contactIndex]?.meta?.email})`
                      : ""}
                  </p>
                  <p className="py-2">
                    <b className="mr-5">Subject</b> {email?.meta?.subject}
                  </p>
                </div>
                <div className="text-right">
                  <span className="inline-flex">
                    <IconButton
                      className="cursor-pointer border-none hover:bg-transparent"
                      onClick={handlePrevContact}
                    >
                      <ChevronLeftIcon className="mr-3 h-5 w-5" />
                    </IconButton>
                    {contactIndex + 1 + filters?.offset} / {count || 0}
                    <IconButton
                      className="cursor-pointer border-none hover:bg-transparent"
                      onClick={handleNextContact}
                    >
                      <ChevronRightIcon className="ml-3 h-5 w-5" />
                    </IconButton>
                  </span>
                </div>
              </div>
              <div
                className="h-96 overflow-y-auto rounded border border-gray-700 p-10"
                dangerouslySetInnerHTML={{
                  __html: edges?.[contactIndex]?.content,
                }}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Review;
