const exportModule = {
  name: "WarehouseItems",
  sections: [
    {
      fields: [
        { label: "Item Id", name: "id" },
        { label: "Category", name: "WarehouseCat.description" },
        { label: "Artist", name: "Artist.name" },
        { label: "External", name: "externalRef" },
        { label: "Group", name: "mgroup" },
        { label: "Container", name: "container" },
        { label: "Description", name: "description" },
        { label: "Notes", name: "notes" },
        { label: "Destroyed", name: "destroyed" },
        { label: "Bonded", name: "WarehouseMove.ti" },
      ],
      label: "",
      name: "warehouseItems",
    },
    {
      fields: [
        { label: "Owner", name: "Instance.Company.name" },
        { label: "Instance Ref", name: "Instance.ref" },
        { label: "Title", name: "Instance.Edition.Artwork.title" },
        { label: "Instance Status", name: "Instance.Status.description" },
        { label: "Notes", name: "Instance.notes" },
      ],
      label: "Instance",
      name: "instance",
    },
    {
      fields: [
        { label: "Site", name: "WarehouseMoves.WarehouseSite.name" },
        {
          label: "Location",
          name: "WarehouseMoves.WarehouseLocation.id",
        },
        {
          label: "Location Descirption",
          name: "WarehouseMoves.WarehouseLocation.description",
        },
        { label: "Date", name: "WarehouseMoves.date" },
      ],
      label: "Location",
      name: "location",
    },
  ],
};

export default exportModule;
