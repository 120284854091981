import { useQuery } from "@apollo/client";
import { GridInputs } from "../../../../../components/Functional";
import { FETCH_ENTITIES } from "../../../../../graphql/query/Entity";

const Registrar = props => {
  const { formik } = props || {};
  const { data: { entities } = {} } = useQuery(FETCH_ENTITIES);

  const inputs = {
    className: "grid grid-cols-4 gap-16 mt-5",
    inputs: [
      {
        label: "Registrar",
        name: "registrarId",
        options:
          entities?.edges?.map(item => ({
            label: item?.name,
            value: item?.id,
          })) || [],
        type: "multi-select",
      },
    ],
  };

  const gridInputProps = {
    formik,
    ...inputs,
  };

  return (
    <div className="w-full">
      <GridInputs {...gridInputProps} />
    </div>
  );
};

export default Registrar;
