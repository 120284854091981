import moment from "moment-timezone";
import { createPortal } from "react-dom";
import { useEffect, useRef, useState } from "react";
import { useMsal } from "@azure/msal-react";
import TIFFParser from "./tiff";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { LOGOUT } from "../graphql/mutation/User";
import makeObservable from "./makeObservable";

// function changes filter values to match the backend
const convertToFilter = ({ values, filterKeys = [], skipKeys = [] }) => {
  const convertedValues = {};
  Object.keys(values)
    ?.filter(key => !filterKeys.includes(key))
    ?.map(key => {
      if (key.includes("_")) {
        const cValues = convertToFilter({ values: values[key] });
        if (Object.keys(cValues)?.length > 0) convertedValues[key] = cValues;
      } else if (!skipKeys?.includes(key)) {
        const item = values[key];
        // assign default operator eq for text and numeric inputs that don't have operator assigned
        const obj =
          typeof item === "string"
            ? { value: item?.trim(), operator: "like" }
            : typeof item === "number"
              ? { value: item, operator: "eq" }
              : Array.isArray(item)
                ? { value: item, operator: "in" }
                : item;
        // don't include empty arrays in filter
        if (
          obj?.operator == "isNull" ||
          (typeof obj?.value !== "undefined" &&
            (Array.isArray(obj?.value) ? obj?.value?.length > 0 : true))
        ) {
          convertedValues[key] = obj;
        }
      } else {
        convertedValues[key] = values[key];
      }
    });
  return convertedValues;
};

const getValue = (obj, path) => {
  return path.split(".").reduce((acc, key) => {
    return acc?.[key];
  }, obj);
};

const getDiff = (values, initialValues) => {
  return Object.entries(values).reduce((acc, [key, value]) => {
    const hasChanged = initialValues?.[key] !== value;
    if (hasChanged) {
      acc[key] = value;
    }
    return acc;
  }, {});
};

const isEqual = (a, b) => {
  if (a === b) return true;

  if (
    typeof a !== "object" ||
    a === null ||
    typeof b !== "object" ||
    b === null
  ) {
    return false;
  }

  const keysA = Object.keys(a);
  const keysB = Object.keys(b);

  if (keysA.length !== keysB.length) return false;

  for (const key of keysA) {
    if (!keysB.includes(key) || !isEqual(a[key], b[key])) return false;
  }

  return true;
};

// get changeed fields within nested objects
const getChangedFields = (values, initialValues = {}) => {
  const changedFields = {};
  Object.keys(values).forEach(key => {
    if (
      typeof values?.[key] === "object" &&
      values?.[key] !== null &&
      !(values?.[key] instanceof Date)
    ) {
      const nestedFields = getChangedFields(
        values?.[key],
        initialValues?.[key],
      );
      if (Object.keys(nestedFields).length > 0) {
        changedFields[key] = nestedFields;
      }
    } else if (values?.[key] !== initialValues?.[key]) {
      changedFields[key] = values[key];
    }
  });
  // // Check for deleted keys
  // Object.keys(initialValues).forEach(key => {
  //   if (!values.hasOwnProperty(key)) {
  //     changedFields[key] = null
  //   }
  // })
  return changedFields;
};

// get changeed fields within nested objects
const getChangedFieldsV2 = (values, initialValues = {}) => {
  const changedFields = {};
  Object.keys(values).forEach(key => {
    if (
      typeof values?.[key] === "object" &&
      values?.[key] !== null &&
      !(values?.[key] instanceof Date)
    ) {
      const nestedFields = getChangedFields(
        values?.[key],
        initialValues?.[key],
      );
      if (Object.keys(nestedFields).length > 0) {
        changedFields[key] = nestedFields;
      }
    } else if (values?.[key] !== initialValues?.[key]) {
      changedFields[key] = values[key];
    }
  });
  // Check for deleted keys
  Object.keys(initialValues).forEach(key => {
    // eslint-disable-next-line
    if (!values.hasOwnProperty(key)) {
      changedFields[key] = null;
    }
  });
  return changedFields;
};

const getImageId = item => {
  return `${item?.artworkId}-${item?.archiveId}`;
};

const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

const filterObj = (obj, predicate) => {
  const retObj = {};
  for (const key in obj) {
    if (predicate(obj[key])) retObj[key] = obj[key];
  }
  return retObj;
};

const useDebounce = (value, delay) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [JSON.stringify(value), delay], // Only re-call effect if value or delay changes
  );
  return debouncedValue;
};

const useHandleLogout = () => {
  const [logout] = useMutation(LOGOUT);
  const { instance } = useMsal();
  const navigate = useNavigate();

  const handleLogout = async () => {
    await instance
      .logoutPopup()
      .then(async e => {
        await logout();
        localStorage.removeItem("user:token");
        navigate("/");
      })
      .catch(e => {
        console.error(e);
      });
  };
  return handleLogout;
};

const prepareTIFF = async ({ url }) => {
  // eslint-disable-next-line
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();

      const reader = new FileReader();
      reader.onload = function (e) {
        // Load the TIFF parser.
        const tiffParser = new TIFFParser();
        // Parse the TIFF image.
        const tiffCanvas = tiffParser.parseTIFF(e.target.result);
        // Make it clear that we've loaded the image.
        tiffCanvas.style.borderStyle = "solid";
        // Put the parsed image in the page.
        resolve(tiffCanvas);
      };
      reader.readAsArrayBuffer(blob);
    } catch (e) {
      reject(e);
    }
  });
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const formatPrice = (price, currency) => {
  const formattedPrice = !!price && (+price).toLocaleString("en-EN");
  return `${currency ? `${currency} ` : ""}${formattedPrice || ""}`;
};

const checkFutureTimestamp = ({ date, time, timeZone }) => {
  try {
    const dueDate = moment.tz(`${date} ${time}`, `${timeZone}`);
    if (!dueDate.isValid()) throw Error('invalid "date" or "time"');
    if (dueDate.isBefore(moment()))
      throw Error('"dueDate" should be after today');
    return true;
  } catch (e) {
    return false;
  }
};

const refineObject = (values, errors) => {
  const refinedData = { ...values };
  if (errors) {
    Object.keys(errors)?.map(key => {
      delete refinedData[key];
    });
  }
  return refinedData;
};

const mask = {
  mobile: "+ 99 9 999 999 999",
  phone: "+ 99 9 999 999 999",
};

// /**
//  * Creates DOM element to be used as React root.
//  * @returns {HTMLElement}
//  */
const createRootElement = id => {
  const rootContainer = document.createElement("div");
  rootContainer.setAttribute("id", id);
  return rootContainer;
};

// /**
//  * Appends element as last child of body.
//  * @param {HTMLElement} rootElem
//  */
const addRootElement = rootElem => {
  document.body.insertBefore(
    rootElem,
    document.body.lastElementChild.nextElementSibling,
  );
};

// /**
//  * Hook to create a React Portal.
//  * Automatically handles creating and tearing-down the root elements (no SRR
//  * makes this trivial), so there is no need to ensure the parent target already
//  * exists.
//  * @example
//  * const target = usePortal(id, [id]);
//  * return createPortal(children, target);
//  * @param {String} id The id of the target container, e.g 'modal' or 'spotlight'
//  * @returns {HTMLElement} The DOM node to use as the Portal target.
//  */
const usePortal = id => {
  const rootElemRef = useRef(null);

  useEffect(() => {
    // Look for existing target dom element to append to
    const existingParent = document.querySelector(`#${id}`);
    // Parent is either a new root or the existing dom element
    const parentElem = existingParent || createRootElement(id);

    // If there is no existing DOM element, add a new one.
    if (!existingParent) {
      addRootElement(parentElem);
    }

    // Add the detached element to the parent
    parentElem.appendChild(rootElemRef.current);

    return function removeElement() {
      rootElemRef.current.remove();
      // if (!parentElem.childElementCount) {
      // parentElem.remove();
      // }
    };
  }, [id]);

  //  /**
  //  * It's important we evaluate this lazily:
  //  * - We need first render to contain the DOM element, so it shouldn't happen
  //  *   in useEffect. We would normally put this in the constructor().
  //  * - We can't do 'const rootElemRef = useRef(document.createElement('div))',
  //  *   since this will run every single render (that's a lot).
  //  * - We want the ref to consistently point to the same DOM element and only
  //  *   ever run once.
  //  * @link https://reactjs.org/docs/hooks-faq.html#how-to-create-expensive-objects-lazily
  //  */
  const getRootElem = () => {
    if (!rootElemRef.current) {
      rootElemRef.current = document.createElement("div");
    }
    return rootElemRef.current;
  };

  return getRootElem();
};

const Portal = ({ id, children }) => {
  const target = usePortal(id);
  return createPortal(children, target);
};

const currentLocaleISODate = () => {
  const currentDate = new Date();
  const currentLocaleDate = currentDate.toLocaleString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  const dateArray = currentLocaleDate.split("/");
  return `${dateArray[2]}-${dateArray[0]}-${dateArray[1]}`;
};

const formattedLocaleISODate = timestamp => {
  const currentDate = new Date(timestamp);
  const currentLocaleDate = currentDate.toLocaleString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  });
  const dateArray = currentLocaleDate.split("/");
  return `${dateArray[1]}/${dateArray[0]}/${dateArray[2].split(",")[0]} at ${dateArray[2].split(",")[1]}`;
};

const isLeapYear = year => {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

const validateDate = (day, month, year) => {
  const maxDaysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  if (month < 1 || month > 12) {
    return false;
  }

  if (day < 1 || day > maxDaysInMonth[month - 1]) {
    if (month === 2 && day === 29 && isLeapYear(year)) {
      return true;
    }
    return false;
  }

  return true;
};

const shippingCostOptions = [
  { label: "Client", value: 0 },
  { label: "Gallery", value: 1 },
];

// converts [{"_a.b": 123}, {"_a._b_c._d.e":{value: 567, operator: "ne"}}}] to {_a: {b: 123, _b: {_c: {_d: {e: {value: 567, operator: "ne"}}}}}}
const convertArrayToFilterInputObj = arr => {
  const addObject = (obj, path, value) => {
    const [head, ...tail] = path.split(".");
    obj[head] =
      tail.length === 0
        ? value
        : addObject(obj[head] || {}, tail.join("."), value);
    return obj;
  };

  const parseObjectValue = value => {
    if (value && typeof value === "object" && !Array.isArray(value)) {
      // If it's an object, recursively parse nested properties
      return Object.entries(value).reduce((acc, [key, nestedValue]) => {
        acc[key] = parseObjectValue(nestedValue);
        return acc;
      }, {});
    }
    return value; // Return the original value if not an object
  };

  return arr.reduce((acc, obj) => {
    const [key, rawValue] = Object.entries(obj)[0];
    const value = parseObjectValue(rawValue);
    addObject(acc, key, value);
    return acc;
  }, {});
};

export {
  convertArrayToFilterInputObj,
  currentLocaleISODate,
  convertToFilter,
  checkFutureTimestamp,
  classNames,
  filterObj,
  formattedLocaleISODate,
  getChangedFields,
  getChangedFieldsV2,
  getDiff,
  getImageId,
  getValue,
  isEqual,
  prepareTIFF,
  refineObject,
  shippingCostOptions,
  useDebounce,
  useHandleLogout,
  reorder,
  formatPrice,
  makeObservable,
  mask,
  usePortal,
  Portal,
  validateDate,
};
