import * as React from "react";

import { Table } from "../../../../../../components/Functional";
import { FETCH_FABRICATIONS_V2 as fetchFabricationsV2 } from "../../../../../../graphql/query/Fabrication";

import SectionHeader from "../../components/SectionHeader";
import AddFabrication from "./components/AddFabrication";
import { Button, Modal } from "../../../../../../components/ui";
import Actions from "./components/Actions";

const ArtworkFabrication = props => {
  const { artworkId } = props || {};
  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "fabricationsV2",
    headers: [
      { label: "Date", name: "date", type: "date" },
      { label: "Type", name: "type.description", type: "label" },
      { label: "Order #", name: "po", type: "label" },
      { label: "Invoice #", name: "invoice", type: "label" },
      { label: "Description", name: "description", type: "label" },
      { label: "Currency", name: "currency.code", type: "label" },
      { label: "Net Amount", name: "amount", type: "label" },
      { label: "Tax", name: "tax", type: "label" },
      { label: "%", name: "rechargerate", type: "label" },
      { label: "Recharge", name: "recharge", type: "label" },
      { label: "Allocation", name: "instance.name", type: "label" },
      {
        label: "",
        name: "actions",
        type: "custom",
        component: Actions,
        artworkId,
      },
    ],
    FETCH_QUERY: fetchFabricationsV2({ currency: {}, type: {}, instance: {} }),
    variables: {
      _artwork: {
        id: {
          operator: "eq",
          value: artworkId,
        },
      },
    },
    className: "sm:px-0 lg:px-0",
  };

  const modalProps = {
    body: AddFabrication,
    artworkId,
    hideCloseButton: true,
    scale: "md",
  };

  const fabricationActions = [
    {
      type: "black",
      label: "ADD RECORD",
      action: () => null,
      component: () => (
        <Modal {...modalProps}>
          <Button label="Add Fabrication" action="dark" />
        </Modal>
      ),
    },
  ];

  return (
    <div className="w-full">
      <SectionHeader title="Fabrication" actions={fabricationActions} />
      <div className="mb-6 w-full">
        <Table {...tableProps} />
      </div>
    </div>
  );
};

export default ArtworkFabrication;
