import { useFormik } from "formik";
import { useEffect } from "react";
import { convertToFilter, makeObservable } from "../../../util";

// observable store
const artworkFilterStore = makeObservable({});

const useArtworkFilters = () => {
  const formik = useFormik({
    initialValues: {
      limit: 50,
      medium: { operator: "like" },
      offset: 0,
      tab: "instance",
      _artworkCategory: { id: { operator: "in" } },
    },
  });

  const filterKeys = ["tab"];

  const skipKeys = ["limit", "offset"];

  useEffect(() => {
    // convert formik values to filter and update store
    artworkFilterStore.set(
      convertToFilter({ values: formik.values, filterKeys, skipKeys }),
    );
  }, [formik.values]);

  return {
    formik,
  };
};

export { artworkFilterStore, useArtworkFilters };
