import { gql } from "@apollo/client";
import { Photographer } from "../fragments";

const FETCH_PHOTOGRAPHERS = gql`
  query Photographers {
    photographers {
      ${Photographer}
    }
  }
`;

export { FETCH_PHOTOGRAPHERS };
