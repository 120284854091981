import { Button, getInput } from "../../../../../../../components/ui";
import { FETCH_CONTACT_ATTRIBUTE_TYPES } from "../../../../../../../graphql/query/ContactAttributeType";
import {
  CREATE_CONTACT_ATTRIBUTE as createContactAttributeMutation,
  UPDATE_CONTACT_ATTRIBUTE as updateContactAttributeMutation,
} from "../../../../../../../graphql/mutation/ContactAttribute";
import { useQuery, useMutation } from "@apollo/client";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as React from "react";

const AddAttribute = props => {
  const { closeModal, values, contact, isOpen } = props;

  const { data: { contactAttributeTypes = [] } = {} } = useQuery(
    FETCH_CONTACT_ATTRIBUTE_TYPES,
  );
  const [createAttribute, { loading: loadingCreate }] = useMutation(
    createContactAttributeMutation(),
    { refetchQueries: ["FetchContactAttributes"] },
  );
  const [updateAttribute, { loading: loadingUpdate }] = useMutation(
    updateContactAttributeMutation(),
    { refetchQueries: ["FetchContactAttributes"] },
  );
  const addAttribute = () => {
    const input = {
      description: formik.values.description,
      typeId: formik.values.type,
    };
    if (values?.id) {
      input.id = values?.id;
    } else {
      input.contactId = contact?.id;
    }
    const action = values?.id ? updateAttribute : createAttribute;
    action({
      variables: {
        input,
      },
    }).then(() => {
      closeModal();
      formik.resetForm();
    });
  };
  const formik = useFormik({
    initialValues: values
      ? { description: values.description, type: values?.type?.id }
      : {},
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: Yup.object({
      description: Yup.string().trim().required("Please enter a description."),
      type: Yup.string().trim().required("Please enter a type."),
    }),
    onSubmit: addAttribute,
  });

  const inputs = [
    {
      label: "Type (*)",
      name: "type",
      options: contactAttributeTypes?.map(item => ({
        label: item?.description,
        value: item?.id,
      })),
      placeholder: "Select a type",
      type: "select",
    },
    {
      label: "Description (*)",
      name: "description",
      placeholder: "",
      type: "textarea",
    },
  ];

  const successLabel = values?.id ? "Edit" : "Add";

  React.useEffect(() => {
    formik.handleReset();
  }, [isOpen]);

  return (
    <div className="flex w-full flex-col p-8">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          {values?.id ? "Edit" : "Add"} attribute for {contact?.name}
        </div>
        <div className="flex flex-col">
          <div>
            <Button
              action="default"
              className="mr-8"
              label="Cancel"
              onClick={closeModal}
            />
            <Button
              label={`${successLabel}${
                loadingUpdate || loadingCreate ? "ing" : ""
              } Attribute`}
              disabled={loadingUpdate || loadingCreate}
              onClick={formik.submitForm}
            />
          </div>
        </div>
      </div>
      <div className="mt-12 grid grid-cols-2 gap-x-20 gap-y-4 pb-10">
        {inputs?.map(input => {
          const inputProps = {
            ...input,
            formik,
          };
          return (
            <div key={input.name} className="w-full">
              {getInput(inputProps)}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AddAttribute;
