const statusColor = value => {
  switch (value) {
    case "Confirmed":
      return "text-[#ffad68]";
    case "Pending":
      return "text-red-650";
    default:
      return "text-black";
  }
};

const JobStatus = props => {
  const value = props.label || "";
  const userName = props?.values?.user?.name;

  return (
    <>
      <div
        title={value}
        className={`max-w-200 overflow-hidden text-ellipsis text-base font-medium leading-5 ${statusColor(
          value,
        )}`}
      >
        {value}
      </div>
      {userName && (
        <div
          title="Username"
          className="max-w-200 mt-1 overflow-hidden text-ellipsis text-base font-medium leading-5 tracking-wide"
        >
          {userName}
        </div>
      )}
    </>
  );
};

export default JobStatus;
