import { useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";

import { Table } from "../../../../../../../components/Functional";
import { Button, getInput } from "../../../../../../../components/ui";

import { MOVE_OFFERS } from "../../../../../../../graphql/mutation/Offer";
import { FETCH_CONTACTS as fetchContacts } from "../../../../../../../graphql/query/Contact";
import { FETCH_CONTACT_TYPES } from "../../../../../../../graphql/query/ContactType";
import { FETCH_ENTITIES } from "../../../../../../../graphql/query/Entity";

const CustomNameCell = props => {
  return (
    <>
      {props.values.name}
      <div className="text-sm">{props.values.email}</div>
    </>
  );
};

const CustomCompanyCell = props => {
  return (
    <div className="max-w-[320px] overflow-hidden text-ellipsis text-base text-black">
      {props.values.company}
      <div className="text-[15px] italic tracking-wide text-slate-500">
        {props?.values?.title !== "None" && props.values.title}
      </div>
    </div>
  );
};

const CustomLocationCell = props => {
  return (
    <div className="max-w-[320px] overflow-hidden text-ellipsis text-base text-black">
      {props?.values?.county}
      <div className="text-[15px] italic tracking-wide text-slate-500">
        {props?.values?.country?.name}
      </div>
    </div>
  );
};

const MoveOfferModal = props => {
  const { closeModal, contact, values } = props;
  const [moveOffers, { loading }] = useMutation(MOVE_OFFERS, {
    refetchQueries: ["FetchOffers"],
  });
  const { data: { contactTypes = [] } = {} } = useQuery(FETCH_CONTACT_TYPES);
  const { data: { entities = {} } = {} } = useQuery(FETCH_ENTITIES);
  const { edges: entityEdges = [] } = entities;
  const formik = useFormik({
    initialValues: {},
  });

  const toggleProps = props => {
    const { id, name } = props || {};

    return {
      type: "toggle",
      name,
      ...formik.getFieldProps(name),
      value: formik?.values?.[name] === id,
      onChange: value => {
        formik.getFieldHelpers(name).setValue(value ? id : null);
      },
    };
  };

  const tableProps = {
    dataKey: "contacts",
    FETCH_QUERY: fetchContacts({ country: {} }),
    headers: [
      {
        name: "contactId",
        type: "custom",
        component: props => getInput(toggleProps(props)),
      },
      // {
      //   rowClass: 'w-[74px] pr-0',
      //   label: "",
      //   name: "image",
      //   type: "custom",
      //   component: (props) => (
      //     <Avatar
      //       size="lg"
      //       src={props?.values?.img}
      //       label={props?.values?.img || props?.values?.name}
      //     />
      //   ),
      // },
      // { label: 'TYPE', name: 'type.name', sortKey: 'typeId', type: 'label' },
      {
        label: "NAME & EMAIL",
        name: "name",
        type: "custom",
        component: CustomNameCell,
        rowClass: "min-w-[320px] box-border",
      },
      {
        label: "COMPANY & TITLE",
        sortKey: "company",
        type: "custom",
        component: CustomCompanyCell,
      },
      {
        label: "LOCATION",
        names: ["county", "country.name"],
        sortKey: "country",
        type: "custom",
        component: CustomLocationCell,
      },
      // { label: 'SALES USER', name: 'salesUser.name', sortKey: 'salesUserId', type: 'label' },
    ],
    inputs: [
      { label: "Name", name: "name", type: "text", view: "partial" },
      { label: "Email", name: "email", type: "text", view: "partial" },
      {
        label: "Company",
        name: "companyNTitle",
        type: "text",
        view: "partial",
      },
      { label: "Location", name: "location", type: "text", view: "partial" },
      {
        label: "Type",
        name: "typeId",
        type: "multi-select",
        view: "partial",
        options: contactTypes?.map(item => ({
          label: item.name,
          value: item.id,
        })),
      },
      {
        label: "Sales User",
        name: "salesUserId",
        type: "multi-select",
        view: "partial",
        options: entityEdges?.map(item => ({
          label: item.name,
          value: item.id,
        })),
      },
    ],
    pagination: {
      variant: "sm",
    },
    variant: "dynamicHeight",
  };

  const handleClick = () => {
    const { moveOffers: { ids: moveIds, select } = {} } = values || {};
    const { contactId: toContactId } = formik?.values || {};

    moveOffers({
      variables: {
        input: {
          contactId: contact?.id,
          ids: Object.keys(moveIds || {})?.map(key => +key.replace("_", "")),
          moveAll: select === "all",
          toContactId,
        },
      },
    }).then(resp => {
      const { data: { moveOffers: { success } = {} } = {} } = resp;

      if (success) {
        closeModal();
      }
    });
  };

  return (
    <div className="mt-6 flex w-full flex-col">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col">
          <div className="text-2xl font-bold">
            Move Offers: Select a Recipient
          </div>
          <div className="pt-4 text-base font-normal">
            Search to select a contact to move offers to.
          </div>
        </div>
        <div className="flex flex-col">
          <div>
            <Button
              action="default"
              className="mr-4"
              label="Cancel"
              onClick={() => {
                closeModal();
              }}
            />
            <Button
              label={loading ? "Moving" : "Move"}
              disabled={loading || !formik?.values?.contactId}
              onClick={handleClick}
            />
          </div>
        </div>
      </div>
      <Table {...tableProps} />
    </div>
  );
};

export default MoveOfferModal;
