import React from "react";
import { Link } from "react-router-dom";

import SectionHeader from "../../components/SectionHeader";
import { Table } from "../../../../../../components/Functional";
import { FETCH_WAREHOUSE_MOVE_V2 as fetchWarehouseMoveV2 } from "../../../../../../graphql/query/WarehouseMove";
import { UPDATE_WAREHOUSE_MOVE as updateWarehouseMoveMutation } from "../../../../../../graphql/mutation/WarehouseMove";

const Movement = props => {
  const { warehouseItemId } = props;

  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "warehouseMoveV2",
    headers: [
      { label: "Date", name: "date", type: "date" },
      { label: "User", name: "user", type: "label" },
      {
        label: "Shipment",
        name: "shipment.id",
        type: "custom",
        component: ({ values }) => (
          <Link
            className="underline"
            to={`/shipments/${values?.shipment?.id}/details`}
          >
            {values?.shipment?.id}
          </Link>
        ),
      },
      { label: "Site", name: "site.name", type: "label" },
      { label: "Location", name: "location.description", type: "label" },
      { label: "Movement Note", name: "locref", type: "label" },
      {
        label: "Bonded",
        name: "ti",
        type: "input",
        inputType: "toggle",
        designType: "threeStateCheckbox",
      },
    ],
    inputs: [],
    FETCH_QUERY: fetchWarehouseMoveV2({
      shipment: true,
      site: true,
      location: true,
    }),
    UPDATE_MUTATION: updateWarehouseMoveMutation({
      shipment: true,
      site: true,
      location: true,
    }),
    variables: {
      _warehouseItem: {
        id: {
          operator: "eq",
          value: +warehouseItemId,
        },
      },
    },
    className: "sm:px-0 lg:px-0",
  };

  return (
    <div className="w-full">
      <SectionHeader title="Movements" />
      <div className="flex w-full">
        <div className="flex-1">
          <Table {...tableProps} />
        </div>
      </div>
    </div>
  );
};

export default Movement;
