import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import Actions from "./components/Actions";
import HeaderComponent from "./components/HeaderComponent";
import searchedJobs from "./components/SearchedJobs";
import * as SelectListWorking from "../../components/SelectListWorking";
import * as SelectListSaved from "../../components/SelectListSaved";
import { jobFilterStore } from "../../components/useJobsFilters";
import {
  Table,
  multiSelect,
  SelectList,
} from "../../../../components/Functional";
import JobStatus from "./components/JobStatus";
import { FETCH_LISTS as fetchLists } from "../../../../graphql/query/List";
import { useEffect } from "react";

const List = props => {
  const { formik } = props;
  const navigate = useNavigate();
  const { data: { lists: { edges = [] } = {} } = {} } = useQuery(fetchLists(), {
    variables: {
      // fetch job_working list
      input: { typeId: 18 },
    },
  });

  const workingListId = edges?.length > 0 ? edges[0]?.id : null;

  const queryParams = new URLSearchParams(window.location.search);
  const status = queryParams?.get("status") || "all";
  const listId = +queryParams.get("listId");

  const ids = Object.keys(formik?.values?.selected?.ids || {})?.map(item =>
    parseInt(item.replace("_", "")),
  );

  useEffect(() => {
    if (workingListId) {
      queryParams.set("workingListId", workingListId);
      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      // eslint-disable-next-line
      history.pushState(null, null, newUrl);
    }
  }, [workingListId]);

  const multiSelectProps = {
    formik,
    label: "Jobs",
    name: "selected",
    keyName: "id",
  };

  const actionProps = {
    formik,
    ids,
    listId,
    onSuccess: () => formik?.resetForm(),
    search: jobFilterStore.get(),
    workingListId,
  };

  const handleChange = value => {
    navigate(`/jobs?status=${value}${listId ? `&listId=${listId}` : ""}`);
  };

  const GeneralTabActions = () => {
    const createListProps = {
      ...actionProps,
      onSuccess: () => (
        formik?.resetForm(), navigate(`/jobs?status=selectListWorking`)
      ),
      typeId: 18,
    };

    return <SelectList.CreateList {...createListProps} />;
  };

  const tabProps = {
    active: status,
    onChange: handleChange,
    tabs: [
      {
        actions: GeneralTabActions,
        label: "All Jobs",
        name: "all",
        tableProps: searchedJobs(),
      },
      {
        actions: SelectListWorking.Actions,
        label: "Select List (Working)",
        name: "selectListWorking",
        tableProps: SelectListWorking.tableProps({ listId, workingListId }),
      },
      {
        actions: SelectListSaved.Actions,
        label: "Select List (Saved)",
        name: "selectListSaved",
        tableProps: SelectListSaved.tableProps({ formik }),
      },
    ],
  };
  const { tableProps: tabTableProps, actions: tabActions } =
    tabProps?.tabs?.find(tab => tab.name === status) || {};

  const fixHeadersName = ({ status, headers }) =>
    headers?.map(header => {
      return {
        ...header,
        name:
          header?.name && status === "selectListWorking"
            ? Array.isArray(header?.name)
              ? header?.name?.map(item => `item.${item}`)
              : `item.${header?.name}`
            : header?.name,
      };
    });

  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "jobs",
    headers: fixHeadersName({
      status,
      headers: [
        multiSelect(multiSelectProps),
        {
          label: "Job",
          name: "id",
          type: "label",
        },
        {
          label: "Date",
          name: "date",
          type: "date",
        },
        {
          label: "Job Status",
          name: "jobStatus.description",
          type: "custom",
          component: JobStatus,
        },
        {
          label: "Pickup Group",
          name: "pickupGroup.name",
          type: "label",
        },
        {
          label: "Type",
          name: "jobType.description",
          type: "label",
        },
        {
          label: "Due Date",
          name: "dueDate",
          type: "date",
        },
        { label: "Due Time", name: "duetime", type: "time" },
        {
          label: "Description",
          name: "description",
          type: "label",
        },
        { label: "", name: "action", type: "custom", component: Actions },
      ],
    }),
    // inputs: [
    //   { label: 'Artist name', name: 'artistName', type: 'text', view: 'partial' },
    //   { label: 'Status', name: 'statusId', type: 'multi-select', view: 'partial', options: instanceStatus?.map(item => ({ label: item.name, value: item.id })) },
    // ],
    limit: 50,
    tabProps,
    pollInterval: 10000,
    ...tabTableProps,
  };

  return (
    <>
      <div className={"px-8"}>
        <HeaderComponent
          leftText="Job Requests"
          rightComponent={() => {
            return (
              <div className="flex gap-4">{tabActions?.(actionProps)}</div>
            );
          }}
        />
      </div>
      <div className="mt-6 w-full">
        <Table {...tableProps} />
      </div>
    </>
  );
};

export default List;
