import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import Actions from "./components/Actions";
import HeaderComponent from "./components/HeaderComponent";
import InstanceStatus from "./components/Status";
import searchedInstances from "./components/SearchedInstances";
import * as SelectListWorking from "./components/SelectListWorking";
import * as SelectListSaved from "./components/SelectListSaved";
import * as ImageSelectListWorking from "./components/ImageSelectListWorking";
import * as ImageSelectListSaved from "./components/ImageSelectListSaved";

import { instanceFilterStore } from "../../components/useInstanceFilters";

import {
  Table,
  multiSelect,
  SelectList,
} from "../../../../components/Functional";
import { FETCH_LISTS as fetchLists } from "../../../../graphql/query/List";

const EditionNumber = ({ values }) => {
  const { edition, editionNumber } = values?.item || values || {};
  if (edition?.number === 1 || !editionNumber) return null;

  return (
    <div className="flex flex-col">
      <span className="text-base font-medium leading-5 text-black">{`${editionNumber}/${edition?.number}`}</span>
    </div>
  );
};

const List = props => {
  const { formik } = props;
  const navigate = useNavigate();
  const { data: { lists: { edges = [] } = {} } = {} } = useQuery(fetchLists(), {
    variables: {
      // fetch instance_working list
      input: { typeId: 4 },
    },
  });
  const { data: { lists: { edges: imageListEdges = [] } = {} } = {} } =
    useQuery(fetchLists(), {
      variables: {
        // fetch instance_working list
        input: { typeId: 8 },
      },
    });
  const workingListId = edges?.length > 0 ? edges[0]?.id : null;
  const imageWorkingListId =
    imageListEdges?.length > 0 ? imageListEdges[0]?.id : null;

  const queryParams = new URLSearchParams(window.location.search);
  const status = queryParams?.get("status") || "all";
  const listId = +queryParams.get("listId");
  const imageListId = +queryParams.get("imageListId");

  const ids = Object.keys(formik?.values?.selected?.ids || {})?.map(item =>
    parseInt(item.replace("_", "")),
  );
  const select = formik?.values?.selected?.select;

  useEffect(() => {
    if (workingListId) {
      queryParams.set("workingListId", workingListId);
      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      // eslint-disable-next-line
      history.pushState(null, null, newUrl);
    }
  }, [workingListId]);

  const multiSelectProps = {
    formik,
    label: "Instances",
    name: "selected",
    keyName: "id",
  };

  const actionProps = {
    formik,
    ids,
    imageListId,
    imageWorkingListId,
    listId,
    search: instanceFilterStore.get(),
    workingListId,
    onSuccess: () => formik?.resetForm(),
  };

  const handleChange = value => {
    navigate(
      `/instance?status=${value}${listId ? `&listId=${listId}` : ""}${workingListId ? `&workingListId=${workingListId}` : ""}${imageListId ? `&imageListId=${imageListId}` : ""}`,
    );
  };

  const GeneralTabActions = () => {
    const createListProps = {
      ...actionProps,
      onSuccess: () => (
        formik?.resetForm(),
        navigate(
          `/instance?status=selectListWorking${listId ? `&listId=${listId}` : ""}${imageListId ? `&imageListId=${imageListId}` : ""}`,
        )
      ),
      typeId: 4,
    };

    const createImageListProps = {
      ...actionProps,
      label: "Add Images to Select List",
      onSuccess: () => (
        formik?.resetForm(),
        navigate(
          `/instance?status=imageSelectListWorking${listId ? `&listId=${listId}` : ""}${imageListId ? `&imageListId=${imageListId}` : ""}`,
        )
      ),
      typeId: 8,
      isSearch: true,
      ...(select !== "all"
        ? { search: { id: { operator: "in", value: ids } } }
        : {}),
    };

    const createPrimaryImageListProps = {
      ...actionProps,
      label: "Add Primary Images to Select List",
      onSuccess: () => (
        formik?.resetForm(),
        navigate(
          `/instance?status=imageSelectListWorking${listId ? `&listId=${listId}` : ""}${imageListId ? `&imageListId=${imageListId}` : ""}`,
        )
      ),
      typeId: 8,
      primary: true,
      isSearch: true,
      ...(select !== "all"
        ? { search: { id: { operator: "in", value: ids } } }
        : {}),
    };

    return (
      <>
        <SelectList.CreateList {...createPrimaryImageListProps} />
        <SelectList.CreateList {...createImageListProps} />
        <SelectList.CreateList {...createListProps} />
      </>
    );
  };

  const tabProps = {
    active: status,
    onChange: handleChange,
    tabs: [
      // { actions: GeneralTabActions, label: 'All Instances', name: 'all' },
      {
        actions: GeneralTabActions,
        label: "All Instances",
        name: "all",
        tableProps: searchedInstances(),
      },
      {
        actions: SelectListWorking.Actions,
        label: "Select List (Working)",
        name: "selectListWorking",
        tableProps: SelectListWorking.tableProps({ listId, workingListId }),
      },
      {
        actions: SelectListSaved.Actions,
        label: "Select List (Saved)",
        name: "selectListSaved",
        tableProps: SelectListSaved.tableProps({ formik }),
      },
      {
        actions: ImageSelectListWorking.Actions,
        label: "Image Select List (Working)",
        name: "imageSelectListWorking",
        tableProps: ImageSelectListWorking.tableProps({
          imageListId,
          imageWorkingListId,
          formik,
        }),
      },
      {
        actions: ImageSelectListSaved.Actions,
        label: "Image Select List (Saved)",
        name: "imageSelectListSaved",
        tableProps: ImageSelectListSaved.tableProps({ formik }),
      },
    ],
  };
  const { tableProps: tabTableProps, actions: tabActions } =
    tabProps?.tabs?.find(tab => tab.name === status) || {};

  const fixHeadersName = ({ status, headers }) =>
    headers?.map(header => {
      return {
        ...header,
        name:
          header?.name && status === "selectListWorking"
            ? Array.isArray(header?.name)
              ? header?.name?.map(item => `item.${item}`)
              : `item.${header?.name}`
            : header?.name,
      };
    });

  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "instances",
    headers: fixHeadersName({
      status,
      headers: [
        multiSelect(multiSelectProps),
        {
          label: "Artwork Image",
          name: "edition.artwork.imagesSummary.imgT",
          type: "image",
        },
        { label: "Reference", name: "name", type: "label" },
        {
          label: "Title",
          name: "edition.artwork.title",
          type: "label",
          widthInPx: 400,
        },
        {
          label: "Artist Name",
          name: "edition.artwork",
          type: "custom",
          component: props => {
            const combinedName = props?.label?.artists
              ?.map(artist => artist.name)
              .join(",");
            return (
              <div
                title={combinedName}
                className={`overflow-hidden text-ellipsis text-base font-medium leading-5 text-black`}
                style={{ width: 140 }}
              >
                {combinedName}
              </div>
            );
          },
        },
        { label: "Year", name: "edition.year", type: "label" },
        {
          label: "Edition",
          name: "editionNumber",
          type: "custom",
          component: EditionNumber,
        },
        {
          label: "Status",
          name: "status.name",
          type: "custom",
          component: InstanceStatus,
        },
        { label: "", name: "action", type: "custom", component: Actions },
      ],
    }),
    // inputs: [
    //   { label: 'Artist name', name: 'artistName', type: 'text', view: 'partial' },
    //   { label: 'Status', name: 'statusId', type: 'multi-select', view: 'partial', options: instanceStatus?.map(item => ({ label: item.name, value: item.id })) },
    // ],
    limit: 50,
    tabProps,
    ...tabTableProps,
  };

  return (
    <>
      <div className={"px-8"}>
        <HeaderComponent
          leftText="All Instances"
          rightComponent={() => {
            return (
              <div className="flex gap-4">{tabActions?.(actionProps)}</div>
            );
          }}
        />
      </div>
      <div className="mt-6 w-full">
        <Table {...tableProps} />
      </div>
    </>
  );
};

export default List;
