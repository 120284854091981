import { gql } from "@apollo/client";
import { EmailContact } from "../fragments";

const CREATE_EMAIL_CONTACT = gql`
  mutation CreateEmailContact($input: CreateEmailContactInput!) {
    createEmailContact(input: $input) {
      emailContact {
        ${EmailContact}
      }
      error
      success
    }
  }
`;

const DELETE_EMAIL_CONTACT = gql`
  mutation DeleteEmailContact($input: DeleteEmailContactInput!) {
    deleteEmailContact(input: $input) {
      success
      error
    }
  }
`;

const UPDATE_EMAIL_CONTACT = gql`
  mutation UpdateEmailContact($input: UpdateEmailContactInput!) {
    updateEmailContact(input: $input) {
      emailContact {
        ${EmailContact}
      }
      error
      success
    }
  }
`;

const UNSUBSCRIBE_EMAIL_CONTACT = gql`
  mutation UnsubscribeEmailContact($input: UnsubscribeEmailContactInput!) {
    unsubscribeEmailContact(input: $input) {
      success
      error
    }
  }
`;

export {
  CREATE_EMAIL_CONTACT,
  DELETE_EMAIL_CONTACT,
  UNSUBSCRIBE_EMAIL_CONTACT,
  UPDATE_EMAIL_CONTACT,
};
