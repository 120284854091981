import { gql } from "@apollo/client";
import { Fabrication as fabrication } from "../fragments";

const CREATE_FABRICATION = props => gql`
  mutation CreateFabrication($input: CreateFabricationInput!) {
    createFabrication(input: $input) {
      success
      error
      fabrication {
        ${fabrication(props)}
      }
    }
  }
`;

const DELETE_FABRICATION = gql`
  mutation DeleteFabrication($input: DeleteFabricationInput!) {
    deleteFabrication(input: $input) {
      success
      error
    }
  }
`;

const SPLIT_COST = gql`
  mutation SplitCost($input: SplitCostInput!) {
    splitCost(input: $input) {
      success
      error
    }
  }
`;

const UPDATE_FABRICATION = props => gql`
  mutation UpdateFabrication($input: UpdateFabricationInput!) {
    updateFabrication(input: $input) {
      success
      error
      fabrication {
        ${fabrication(props)}
      }
    }
  }
`;

export {
  CREATE_FABRICATION,
  DELETE_FABRICATION,
  SPLIT_COST,
  UPDATE_FABRICATION,
};
