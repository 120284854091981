import { useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import { useEffect } from "react";
import * as Yup from "yup";

import { Button } from "../../../../../../../components/ui";
import { GridInputs } from "../../../../../../../components/Functional";

import { FETCH_CURRENCIES } from "../../../../../../../graphql/query/Currency";
import { FETCH_ENTITIES } from "../../../../../../../graphql/query/Entity";
import { FETCH_VALUE_TYPES } from "../../../../../../../graphql/query/ValueType";
import { CREATE_VALUE as createValueMutation } from "../../../../../../../graphql/mutation/Value";
import { currentLocaleISODate } from "../../../../../../../util";

const AddHistory = props => {
  const { instanceId, closeModal, isOpen } = props;

  const { data: { currencies = [] } = {} } = useQuery(FETCH_CURRENCIES);
  const { data: { entities: { edges = [] } = {} } = {} } =
    useQuery(FETCH_ENTITIES);
  const { data: { valueTypes = [] } = {} } = useQuery(FETCH_VALUE_TYPES);

  const [createValue, { loading }] = useMutation(createValueMutation(), {
    refetchQueries: ["FetchValues"],
  });

  const addHistory = () => {
    const input = {
      authorisedId: formik.values.authorisedId,
      currencyId: formik.values.currencyId,
      date: formik.values.date,
      instanceId: instanceId,
      maxDiscount: formik.values.maxDiscount || null,
      notes: formik.values.notes,
      typeId: formik.values.typeId,
      value: formik.values.value,
    };

    createValue({
      variables: {
        input,
      },
    }).then(() => {
      closeModal();
    });
  };

  const formik = useFormik({
    initialValues: {
      date: currentLocaleISODate(),
    },
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: Yup.object({
      currencyId: Yup.number()
        .required("Please select a currency.")
        .typeError("Please select a currency."),
      maxDiscount: Yup.number()
        .positive()
        .nullable()
        .typeError("Invalid Input"),
      typeId: Yup.number()
        .required("Please select a type.")
        .typeError("Please select a type."),
      value: Yup.number()
        .positive()
        .required("Please enter value.")
        .typeError("Invalid Input"),
    }),
    onSubmit: addHistory,
  });

  const inputs = {
    className: "grid grid-cols-2 gap-12",
    inputs: [
      {
        className: "grid grid-cols-1 gap-4",
        inputs: [
          {
            label: "Type",
            name: "typeId",
            type: "multi-select",
            placeholder: "Select a type",
            options: valueTypes?.map(item => ({
              label: item?.description,
              value: item?.id,
            })),
          },
          { label: "Value", name: "value", type: "number-currency" },
          {
            label: "Currency",
            name: "currencyId",
            type: "multi-select",
            placeholder: "Select Currency",
            options: currencies?.map(currency => ({
              label: `${currency.description} (${currency.code})`,
              value: currency.id,
            })),
          },
          {
            label: "Max Discount",
            name: "maxDiscount",
            type: "number-currency",
          },
        ],
      },
      {
        className: "grid grid-cols-1 gap-4",
        inputs: [
          { label: "Date", name: "date", type: "date" },
          {
            label: "Authorized",
            name: "authorisedId",
            options: edges?.map(item => ({ label: item.name, value: item.id })),
            type: "multi-select",
          },
          { label: "Notes", name: "notes", rows: 5, type: "textarea" },
        ],
      },
    ],
  };

  const inputProps = {
    ...inputs,
    formik,
  };

  useEffect(() => {
    formik.handleReset();
  }, [isOpen]);

  return (
    <div className="flex w-full flex-col p-8">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          Add Valuation
        </div>
        <div className="flex flex-col">
          <div>
            <Button
              action="default"
              className="mr-8"
              label="Cancel"
              onClick={closeModal}
            />
            <Button
              label={`Add${loading ? "ing" : ""} Valuation`}
              disabled={loading}
              onClick={formik.submitForm}
            />
          </div>
        </div>
      </div>
      <div className="mt-12 pb-10">
        <GridInputs {...inputProps} />
      </div>
    </div>
  );
};

export default AddHistory;
