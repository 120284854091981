import { useQuery } from "@apollo/client";
import { GridInputs } from "../../../../../components/Functional";
import { FETCH_CRM_TYPES } from "../../../../../graphql/query/CRMType";

const Technical = props => {
  const { formik } = props || {};
  const { data: { crmTypes = [] } = {} } = useQuery(FETCH_CRM_TYPES, {
    variables: { input: { categoryId: 3 } },
  });

  const inputs = {
    className: "grid grid-cols-4 gap-16 mt-5",
    inputs: [
      {
        className: "flex flex-col gap-4",
        inputs: [
          {
            label: "Type",
            name: "_CRMRegistrar.typeId",
            type: "multi-select",
            isMulti: false,
            options: crmTypes?.map(type => ({
              label: type.description,
              value: type.id,
            })),
          },
        ],
      },
      {
        className: "gap-4",
        inputs: [
          {
            label: "Description",
            name: "_CRMRegistrar.description",
            type: "text",
          },
        ],
      },
      {
        className: "gap-4",
        inputs: [
          {
            label: "Issue #",
            name: "_CRMRegistrar.issueId",
            type: "number",
          },
        ],
      },
    ],
  };

  const gridInputProps = {
    formik,
    ...inputs,
  };

  return <GridInputs {...gridInputProps} />;
};

export default Technical;
