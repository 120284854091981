import { useQuery } from "@apollo/client";
import { GridInputs } from "../../../../../components/Functional";
import { FETCH_CONTACT_LINK_TYPES } from "../../../../../graphql/query/ContactLinkType";

const Affiliations = props => {
  const { formik } = props || {};

  const { data: { contactLinkTypes = [] } = {} } = useQuery(
    FETCH_CONTACT_LINK_TYPES,
  );

  const inputs = {
    className: "grid grid-cols-4 gap-4",
    inputs: [
      {
        label: "Affiliated Contact",
        name: "_contactLink.linkId",
        type: "contactSearch",
      },
      {
        label: "Affiliated Description",
        name: "_contactLink.description",
        type: "text",
      },
      {
        label: "Affiliated Type",
        name: "_contactLink.typeId",
        options:
          contactLinkTypes?.map(item => ({
            label: item.description,
            value: item.id,
          })) || [],
        type: "multi-select",
      },
      {
        designType: "threeStateCheckbox",
        label: "Expired",
        name: "_contactLink.expired",
        type: "toggle",
      },
    ],
  };

  const gridInputProps = {
    formik,
    ...inputs,
  };

  return <GridInputs {...gridInputProps} />;
};

export default Affiliations;
