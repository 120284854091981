import { useMutation } from "@apollo/client";
import { TrashIcon } from "@heroicons/react/24/outline";
import {
  Button,
  getInput,
  IconButton,
} from "../../../../../../../components/ui";
import { useFormik } from "formik";
import { useEffect } from "react";
import { UPLOAD_INSTANCE_DOCUMENTS } from "../../../../../../../graphql/mutation/InstanceDocument";

const AddDocuments = props => {
  const { closeModal, instance, isOpen } = props;
  const [uploadInstanceDocuments, { loading }] = useMutation(
    UPLOAD_INSTANCE_DOCUMENTS,
    { refetchQueries: ["FetchInstanceDocuments"] },
  );

  const handleClick = () => {
    uploadInstanceDocuments({
      variables: {
        instanceId: instance?.id,
        files: formik?.values?.files,
      },
    }).then(resp => {
      const {
        data: { uploadInstanceDocuments: { success } = {} },
      } = resp || {};
      if (success) {
        closeModal();
      }
    });
  };

  const formik = useFormik({
    initialValues: {
      files: [],
    },
    enableReinitialize: true,
  });

  const handleDelete = index => {
    const { values: { files = [] } = {} } = formik || {};
    const newDocuments = files?.filter((item, i) => i !== index);
    formik.setFieldValue("files", newDocuments);
  };

  const inputs = [
    {
      label: "Attach File(s)",
      type: "img",
      isMultiSelect: true,
      uploadType: "file(s)",
      w: "w-full",
      h: "h-36",
      onChange: e => {
        const files = e.target.files;
        const newArray = [];
        for (let i = 0; i < files.length; i++) {
          newArray.push(files[i]);
        }
        formik.setFieldValue("files", [...formik.values.files, ...newArray]);
      },
    },
  ];

  useEffect(() => {
    formik?.resetForm();
  }, [isOpen]);

  return (
    <div className="flex w-full flex-col p-8">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          Upload Documents for {instance?.name}
        </div>
        <div className="flex flex-row">
          <Button
            action="default"
            className="mr-8"
            label="Cancel"
            onClick={() => {
              closeModal();
            }}
          />
          <Button
            label={loading ? "Uploading" : "Upload Record"}
            disabled={loading || formik?.values?.files?.length === 0}
            onClick={handleClick}
          />
        </div>
      </div>
      <div className="grid w-full grid-cols-1 pb-10 pt-20">
        <div className="flex">
          {inputs?.map((item, index) => {
            const inputProps = {
              ...item,
            };
            return (
              <div key={index} className={`w-full ${item?.className}`}>
                {getInput(inputProps)}
              </div>
            );
          })}
        </div>
        <div className="mt-4 flex flex-col">
          <div className="flex-1">Selected File(s)</div>
          <div className="mt-4">
            {formik?.values?.files?.map((item, index) => {
              return (
                <div key={index} className="flex flex-row gap-4">
                  <IconButton variant="clean" title="Delete Attachment">
                    <TrashIcon
                      className={`h-4 w-4 cursor-pointer text-gray-400`}
                      onClick={() => {
                        handleDelete(index);
                      }}
                    />
                  </IconButton>
                  <div className="flex-1">{item?.name}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddDocuments;
