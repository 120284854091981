import { getInput } from "../../../../../../../components/ui";
import useUser from "../../../../../../../hooks/useUser";

const Consignee = props => {
  const { formik, submitOnBlur = false } = props;
  const { hasPermission } = useUser();

  const inputs = [
    {
      name: "consigneeTel",
      type: "text",
      label: "Tel",
      submitOnBlur,
      disabled: !hasPermission("UPDATE_CONSIGNMENT"),
    },
    {
      name: "consigneeFax",
      type: "text",
      label: "Fax",
      submitOnBlur,
      disabled: !hasPermission("UPDATE_CONSIGNMENT"),
    },
    {
      name: "consigneeEmail",
      type: "text",
      label: "Email",
      submitOnBlur,
      disabled: !hasPermission("UPDATE_CONSIGNMENT"),
    },
  ];

  return (
    <div className="w-full">
      <h5 className="font-bold">Consignee</h5>
      <div className="mt-2 grid h-fit grid-cols-1 gap-4">
        {inputs?.map((item, index) => {
          const inputProps = {
            formik,
            ...item,
          };

          return <div key={index}>{getInput(inputProps)}</div>;
        })}
      </div>
    </div>
  );
};

export default Consignee;
