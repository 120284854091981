import { useQuery } from "@apollo/client";
import { GridInputs } from "../../../../../components/Functional";
import { FETCH_CONTACT_ATTRIBUTE_TYPES } from "../../../../../graphql/query/ContactAttributeType";

const Attributes = props => {
  const { formik } = props || {};

  const { data: { contactAttributeTypes = [] } = {} } = useQuery(
    FETCH_CONTACT_ATTRIBUTE_TYPES,
  );

  const inputs = {
    className: "grid grid-rows-2 gap-4",
    inputs: [
      {
        className: "grid grid-cols-4 gap-4",
        inputs: [
          {
            hasOperator: true,
            isMulti: true,
            label: "Type",
            name: "_contactAttribute.typeId",
            options: contactAttributeTypes?.map(type => ({
              label: type?.description,
              value: type?.id,
            })),
            type: "multi-select",
          },
        ],
      },
    ],
  };

  const gridInputProps = {
    formik,
    ...inputs,
  };

  return <GridInputs {...gridInputProps} />;
};

export default Attributes;
