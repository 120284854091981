import React from "react";

import { getInput } from "../../../../../../../components/ui";

const Insurance = ({ formik, submitOnBlur = false }) => {
  const inputs = [
    { label: "Insurance", type: "label", className: "pb-0" },
    {
      label: "",
      name: "insurance",
      type: "radio",
      options: [
        { label: "Consignor", value: 1 },
        { label: "Consignee", value: 2 },
        { label: "Shipper", value: 3 },
      ],
      submitOnBlur,
    },
    { label: "Shipping", type: "label", className: "pb-0" },
    {
      label: "",
      name: "shipping",
      type: "radio",
      options: [
        { label: "Consignor", value: 1 },
        { label: "Consignee", value: 2 },
        { label: "Agent", value: 3 },
      ],
      submitOnBlur,
    },
    { label: "Destination", type: "label", className: "pb-0" },
    {
      label: "",
      name: "destination",
      type: "radio",
      options: [
        { label: "Consignor", value: 1 },
        { label: "Consignee", value: 2 },
        { label: "Agent", value: 3 },
      ],
      submitOnBlur,
    },
    { label: "Condition", type: "label", className: "pb-0" },
    {
      label: "",
      name: "examined",
      type: "radio",
      options: [
        { label: "Good Condition", value: 1 },
        { label: "Unexamined", value: 2 },
      ],
      submitOnBlur,
    },
  ];

  return (
    <div className="grid-cols-1 gap-4">
      {inputs?.map((item, index) => {
        const inputProps = {
          formik,
          ...item,
          ...formik?.getFieldProps(item.name),
        };

        return (
          <div key={index} className="pb-6">
            {getInput(inputProps)}
          </div>
        );
      })}
    </div>
  );
};

export default Insurance;
