import { GridInputs } from "../../../../../../components/Functional";

const MaterialSent = props => {
  const { formik } = props || {};

  const inputs = {
    className: "grid grid-cols-2 gap-16",
    inputs: [{ label: "Text", name: "notes", type: "textarea" }],
  };

  const gridInputProps = {
    formik,
    ...inputs,
  };

  return <GridInputs {...gridInputProps} />;
};

export default MaterialSent;
