import { gql } from "@apollo/client";
import { Nominal } from "../fragments";

const FETCH_NOMINALS = gql`
  query FetchNominals {
    nominals {
      ${Nominal}
    }
  }
`;

export { FETCH_NOMINALS };
