import { useQuery } from "@apollo/client";

import InstanceInputRender from "./InstanceInputRender";
import { FETCH_ARTISTS as fetchArtists } from "../../../../../../../../../graphql/query/Artist";

const InstanceDetailCol1 = props => {
  const { formik, isDisabled, submitOnBlur = false } = props;
  const { data: { artists = [] } = {} } = useQuery(fetchArtists());

  const { artists: artworkArtists = [] } =
    formik?.values?.edition?.artwork || {};
  const inputs = [
    ...artworkArtists.map((artist, index) => {
      return {
        label: "Artist",
        name: `edition.artwork.artists[${index}].id`,
        type: "multi-select",
        options: [
          ...artists.map(item => ({ label: item?.name, value: item?.id })),
        ],
        checkbox: {
          disabled: true,
          name: `edition.artwork.artists[${index}].droitdesuite`,
          label: "DdS",
        },
        disabled: true,
        submitOnBlur,
      };
    }),
    {
      label: "Title",
      name: "edition.artwork.title",
      type: "textarea",
      rows: 5,
      disabled: isDisabled,
      submitOnBlur,
    },
    {
      label: "Year",
      name: "edition.year",
      type: "text",
      disabled: isDisabled,
      submitOnBlur,
    },
    {
      label: "Medium",
      name: "edition.artwork.medium",
      type: "textarea",
      rows: 5,
      disabled: isDisabled,
      submitOnBlur,
    },
  ];

  return (
    <div className={`grid h-fit grid-cols-1 gap-4`}>
      <InstanceInputRender inputs={inputs} formik={formik} />
    </div>
  );
};

export default InstanceDetailCol1;
