import { useFormik } from "formik";
import { useEffect } from "react";
import { convertToFilter, makeObservable } from "../../../util";

// observable store
const contactFilterStore = makeObservable({});

const useContactFilters = () => {
  const formik = useFormik({
    initialValues: {
      address: { operator: "eq" },
      countryId: { operator: "in" },
      exhUserId: { operator: "in" },
      email: { operator: "like" },
      id: { operator: "eq" },
      limit: 50,
      name: { operator: "eq" },
      offset: 0,
      salesUserId: { operator: "in" },
      salesUser2Id: { operator: "in" },
      sort1: { operator: "ASC" },
      sort2: { operator: "ASC" },
      sort3: { operator: "ASC" },
      tab: "contact_details",
      town: { operator: "eq" },
      typeId: { operator: "in" },
      _contactArtist: { artistId: { operator: "in" } },
      _contactFocus: { focusId: { operator: "in" } },
      _contactAttribute: { typeId: { operator: "in" } },
    },
  });

  const filterKeys = ["contactCategory", "tab"];

  const skipKeys = ["limit", "offset"];

  useEffect(() => {
    // convert formik values to filter and update store
    contactFilterStore.set(
      convertToFilter({ values: formik.values, filterKeys, skipKeys }),
    );
  }, [formik.values]);

  return {
    formik,
  };
};

export { contactFilterStore, useContactFilters };
