import { Route, Routes as RouterRoutes } from "react-router-dom";
import AuthRoute from "../../components/AuthRoute";
import NotFound from "../../components/NotFound";

// import CreatePDF from "./pages/CreatePDF";
import Dashboard from "./pages/Dashboard";

const routes = [
  // {
  //   path: "document/new/*",
  //   element: CreatePDF,
  //   permissions: ['CREATE_DOCUMENT', 'READ_DOCUMENT']
  // },
  // {
  //   path: "document/:id/*",
  //   element: CreatePDF,
  //   permissions: ['CREATE_DOCUMENT', 'READ_DOCUMENT']
  // },
  {
    path: "/401",
    element: NotFound,
  },
  {
    path: "*",
    element: Dashboard,
    permissions: ["CREATE_DOCUMENT", "READ_DOCUMENT"],
  },
];

const PDFGenerator = props => {
  return (
    <RouterRoutes>
      {routes?.map((item, index) => {
        return (
          <Route
            key={index}
            path={item?.path}
            element={
              <AuthRoute permissions={item.permissions}>
                <item.element {...props} />
              </AuthRoute>
            }
          />
        );
      })}
    </RouterRoutes>
  );
};

export default PDFGenerator;
