import { getInput } from "../../../../../../../../../components/ui";
import useUser from "../../../../../../../../../hooks/useUser";

const DetailFormConsigner = ({
  formik,
  disableConfig,
  submitOnBlur = false,
}) => {
  const { hasPermission } = useUser();
  const inputs = [
    {
      label: "Consigner Name",
      name: "consignerId",
      type: "contactSearch",
      submitOnBlur,
      showType: true,
      disabled: !hasPermission("UPDATE_CONSIGNMENT"),
    },
    {
      label: "Consigner Company",
      name: "consigner.company",
      type: "text",
      ...disableConfig,
      submitOnBlur,
    },
  ];

  return (
    <div className={`grid gap-4`}>
      <div>Consigner</div>
      {inputs?.map((item, index) => {
        const inputProps = {
          formik,
          ...item,
        };

        return (
          <div key={index} className="grid-cols-1 gap-4">
            {getInput(inputProps)}
          </div>
        );
      })}
    </div>
  );
};

export default DetailFormConsigner;
