const ShipmentIncoTerm = `
  id
  code
  description
  definition
  transport
  updatedAt
`;

export default ShipmentIncoTerm;
