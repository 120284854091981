import { useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

import Content from "../Content";
import { Modal } from "../../../../../../components/ui";
import { FETCH_EMAIL as fetchEmail } from "../../../../../../graphql/query/Email";
import { UPDATE_EMAIL_CONTACT } from "../../../../../../graphql/mutation/EmailContact";
import { getDiff, useDebounce } from "../../../../../../util";

const EditEmailContact = props => {
  const { id } = useParams();
  const { data: { email = {} } = {} } = useQuery(fetchEmail(), {
    variables: { id: +id },
  });
  const [updateEmailContact] = useMutation(UPDATE_EMAIL_CONTACT);

  const { children, values } = props || {};

  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: {
      id: values?.id,
      meta: {
        body: email?.meta?.body,
        subject: email?.meta?.subject,
        ...values?.meta,
      },
    },
    validationSchema: Yup.object({
      meta: Yup.object({
        body: Yup.string()
          .trim()
          .required("Please enter email content.")
          .min(8, "Please enter email content."),
        subject: Yup.string().trim().required("Please enter a subject."),
      }),
    }),
  });

  const debouncedValues = useDebounce(formik?.values, 300);

  const contentProps = {
    isEmailContactContent: true,
    formik,
  };

  useEffect(() => {
    formik?.validateForm();
  }, []);

  useEffect(() => {
    formik?.dirty &&
      updateEmailContact({
        variables: {
          input: {
            id: +values?.id,
            meta: getDiff(formik?.values?.meta, email?.meta),
          },
        },
      });
  }, [debouncedValues]);

  const modalProps = {
    title: `Edit Email to: ${values?.meta?.email}`,
    closeOnBackdrop: false,
    body: ({ closeModal }) => {
      return (
        <div className="mt-4 flex overflow-scroll">
          <Content {...contentProps} />
        </div>
      );
    },
    onClose: () => {
      formik?.resetForm({
        values: {
          id: values?.id,
          meta: {
            body: email?.meta?.body,
            subject: email?.meta?.subject,
            ...values?.meta,
          },
        },
      });
    },
    onOpen: () => {
      formik?.resetForm({
        values: {
          id: values?.id,
          meta: {
            body: email?.meta?.body,
            subject: email?.meta?.subject,
            ...values?.meta,
          },
        },
      });
    },
  };

  return <Modal {...modalProps}>{children}</Modal>;
};

export default EditEmailContact;
