import { Fragment } from "react";

import { Table } from "../../../../components/Functional";
import { FETCH_REPORT_TEMPLATES } from "../../../../graphql/query/ReportTemplate";

import Actions from "./components/Actions";
import Header from "./components/Header";

const List = () => {
  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "reportTemplates",
    headers: [
      { label: "Name", name: "name", type: "label" },
      { label: "Type", name: "meta.type", type: "label" },
      {
        label: "Date / Time Last Updated",
        name: "updatedAt",
        type: "date-time",
      },
      { label: "", name: "action", type: "custom", component: Actions },
    ],
    inputs: [],
    FETCH_QUERY: FETCH_REPORT_TEMPLATES,
  };

  return (
    <Fragment>
      <div className="px-8">
        <Header leftText="Report Layouts" />
      </div>
      <div className="mt-6 w-full">
        <Table {...tableProps} />
      </div>
    </Fragment>
  );
};

export default List;
