import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { Button, IconButton, Modal } from "../../../../../components/ui";
import { DELETE_WAREHOUSE_ITEM as deleteWarehouseItemMutation } from "../../../../../graphql/mutation/WarehouseItem";
import { useMutation } from "@apollo/client";

const Actions = props => {
  const { values } = props || {};
  const { id } = values?.item || values || {};
  const navigate = useNavigate();

  const handleEdit = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const status = queryParams.get("status") || "all";
    const listId = queryParams.get("listId") || null;
    navigate(
      `${id}/details?status=${status}${listId ? `&listId=${listId}` : ""}`,
    );
  };

  const [
    deleteWarehouseItem,
    {
      data: { deleteWarehouseItem: { error: deleteError } = {} } = {},
      client,
      loading,
    },
  ] = useMutation(deleteWarehouseItemMutation());

  const modalProps = {
    title: deleteError ? "Error" : "Delete Warehouse Item",
    scale: "sm",
    description: deleteError
      ? deleteError
      : `Would you like to delete this warehouse item '${values?.id ?? "n/a"}'?`,
    closeOnBackdrop: true,
    body: ({ closeModal }) => {
      const handleDelete = () => {
        deleteWarehouseItem({
          variables: {
            input: { id },
          },
          update: (cache, { data }) => {
            const { deleteWarehouseItem: { success } = {} } = data;
            if (success) {
              closeModal();
              client.resetStore();
            }
          },
        });
      };

      return (
        <div className="mt-4 flex">
          <Button
            className="mr-3"
            label={"Cancel"}
            action={"default"}
            onClick={() => {
              closeModal?.();
            }}
          />
          {!deleteError && (
            <Button
              label={loading ? "Deleting" : "Delete"}
              disabled={loading}
              onClick={handleDelete}
            />
          )}
        </div>
      );
    },
  };

  if (typeof id == "number") {
    return (
      <div className="user-list-actions flex text-right text-sm font-medium">
        {id && (
          <div className="grid grid-cols-4 gap-4">
            <IconButton variant="clean" title="Edit Warehouse Item">
              <PencilIcon
                className={`h-4 w-4 cursor-pointer text-gray-400`}
                onClick={handleEdit}
              />
            </IconButton>
            <Modal {...modalProps}>
              <IconButton variant="clean" title="Delete Warehouse Item">
                <TrashIcon className={`h-4 w-4 cursor-pointer text-gray-400`} />
              </IconButton>
            </Modal>
          </div>
        )}
      </div>
    );
  } else {
    return false;
  }
};

export default Actions;
