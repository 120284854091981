import { useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import { useEffect } from "react";

import { Button, getInput, Modal } from "../../../../../../../components/ui";
import {
  CREATE_CRM_REGISTRAR as createCRMRegistrarMutation,
  UPDATE_CRM_REGISTRAR as updateCRMRegistrarMutation,
} from "../../../../../../../graphql/mutation/CRMRegistrar";
import { FETCH_CRM_TYPES } from "../../../../../../../graphql/query/CRMType";

const AddRegistrarModal = props => {
  const { closeModal, invoice, crmRegistrar, isOpen } = props;
  const [createCRMRegistrar, { loading: createLoading }] = useMutation(
    createCRMRegistrarMutation(),
    { refetchQueries: ["FetchCRMRegistrarsV2"] },
  );
  const [updateCRMRegistrar, { loading: updateLoading }] = useMutation(
    updateCRMRegistrarMutation(),
  );
  const { data: { crmTypes = [] } = {} } = useQuery(FETCH_CRM_TYPES, {
    variables: { input: { categoryId: 1 } },
  });
  const loading = createLoading || updateLoading;

  const handleClick = () => {
    const { date, description, typeId, subTypeId } = formik.values;

    (crmRegistrar
      ? updateCRMRegistrar({
          variables: {
            input: {
              id: crmRegistrar?.id,
              description,
              typeId,
              subTypeId,
            },
          },
        }).then(resp => {
          const {
            data: { updateCRMRegistrar: { success } = {} },
          } = resp || {};
          if (success) {
            closeModal();
          }
        })
      : createCRMRegistrar({
          variables: {
            input: {
              categoryId: 1, // id of 'CRMCategory: {description: Registrar}'
              invoiceId: invoice?.id,
              date,
              description,
              typeId,
              subTypeId,
            },
          },
        })
    ).then(resp => {
      const {
        data: {
          createCRMRegistrar: { success } = {},
          updateCRMRegistrar: { success: updateSuccess } = {},
        } = {},
      } = resp || {};
      if (success || updateSuccess) {
        closeModal();
      }
    });
  };

  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      date: crmRegistrar?.date || formattedDate,
      description: crmRegistrar?.description,
      typeId: crmRegistrar?.typeId,
      subTypeId: crmRegistrar?.subTypeId,
    },
    onSubmit: handleClick,
  });

  useEffect(() => {
    formik?.resetForm();
  }, [isOpen]);

  const inputs = [
    {
      disabled: true,
      label: "Date",
      name: "date",
      type: "date",
      className: "row-span-1",
    },
    {
      label: "Description",
      name: "description",
      type: "textarea",
      className: "row-span-1",
    },
    {
      label: "Type",
      name: "typeId",
      options: crmTypes?.map(type => ({
        label: type?.description,
        value: type?.id,
      })),
      type: "multi-select",
    },
    {
      label: "Sub Type",
      name: "subTypeId",
      options: crmTypes?.map(type => ({
        label: type?.description,
        value: type?.id,
      })),
      type: "multi-select",
    },
  ];

  return (
    <div className="flex w-full flex-col p-8">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          {crmRegistrar ? "Update" : "Add"} Registrar Entry
        </div>
        <div className="flex flex-row">
          <Button
            action="default"
            className="mr-8"
            label="Cancel"
            onClick={() => {
              closeModal();
            }}
          />
          <Button
            label={
              loading
                ? crmRegistrar
                  ? "Updating"
                  : "Creating"
                : crmRegistrar
                  ? "Update Record"
                  : "Create Record"
            }
            disabled={loading}
            onClick={formik?.submitForm}
          />
        </div>
      </div>
      <div className="grid w-full grid-cols-2 gap-x-20 gap-y-4 pb-10 pt-20">
        {inputs?.map((item, index) => {
          const inputProps = {
            ...item,
            formik,
          };
          return (
            <div key={index} className={`w-full ${item?.className}`}>
              {getInput(inputProps)}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const AddRegistrar = props => {
  const { invoice } = props || {};

  const modalProps = {
    body: AddRegistrarModal,
    closeOnBackdrop: true,
    hideCloseButton: true,
    invoice,
    scale: "md",
  };

  return (
    <Modal {...modalProps}>
      <Button label="Add Registrar" action="black" />
    </Modal>
  );
};

export { AddRegistrar, AddRegistrarModal };
