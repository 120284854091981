import { useQuery, useMutation } from "@apollo/client";
import { Viewer } from "@grapecity/activereports-react";
import { useFormik } from "formik";
import { useRef } from "react";
import * as Yup from "yup";

import { onExport } from "../../../components/Functional/ActiveReport";
import { Button, getInput, Modal } from "../../../components/ui";
import { EXPORT_ACTIVE_REPORT_JSON as exportActiveReportJSONMutation } from "../../../graphql/mutation/ActiveReport";
import { FETCH_REPORT_TEMPLATES } from "../../../graphql/query/ReportTemplate";

const ExportInvoiceReport = props => {
  const {
    closeModal,
    ids,
    listId,
    obj = {},
    search,
    select,
    type = "Invoice",
  } = props || {};
  const { data: { reportTemplates: { edges = [] } = {} } = {} } = useQuery(
    FETCH_REPORT_TEMPLATES,
    { variables: { input: { limit: null, type: type?.toLowerCase() } } },
  );
  const [exportActiveReportJSON, { loading }] = useMutation(
    exportActiveReportJSONMutation({ ...obj, __typename: type }),
  );
  const viewerRef = useRef();

  const formik = useFormik({
    initialValues: {},
    validationSchema: Yup.object({
      reportTemplateId: Yup.number().required(
        "Please select a report template",
      ),
    }),
    onSubmit: async values => {
      const selectedTemplate = edges?.find(
        item => item?.id === values?.reportTemplateId,
      );
      const result = await exportActiveReportJSON?.({
        variables: {
          input: {
            type,
            ...(select === "all"
              ? { ...(listId ? { listId } : { search }) }
              : { ids }),
          },
        },
      });
      const data = {
        [type?.toLowerCase()]: result?.data?.["exportActiveReportJSON"]?.edges,
      };
      const title =
        data[type?.toLowerCase()].length > 1
          ? `${selectedTemplate.name}-${new Date().toISOString()}`
          : `${selectedTemplate.name}-${data[type?.toLowerCase()][0].id}`;

      await onExport({
        data,
        definition: selectedTemplate?.meta?.definition,
        name: type,
        title,
        viewerRef,
      });
    },
  });

  const inputs = [
    {
      label: "Report Template",
      name: "reportTemplateId",
      options: edges?.map(item => ({ label: item.name, value: item.id })),
      type: "multi-select",
    },
  ];

  const hasErrors = Object.keys(formik?.errors || {}).length > 0;

  const exportsSettings = {
    pdf: {
      title: "ActiveReportsJS Sample",
      author: "GrapeCity",
      subject: "Web Reporting",
      keywords: "reporting, sample",
      userPassword: "pwd",
      ownerPassword: "ownerPwd",
      printing: "none",
      copying: false,
      modifying: false,
      annotating: false,
      contentAccessibility: false,
      documentAssembly: false,
      pdfVersion: "1.7",
      autoPrint: true,
      filename: "ActiveReportsJS-Sample.pdf",
    },
    html: {
      title: "ActiveReportsJS Sample",
      filename: "ActiveReportsJS-Sample.html",
      autoPrint: true,
      multiPage: true,
      embedImages: "external",
      outputType: "html",
    },
  };

  // remove items from this array to hide unnecessary Exports Types
  const availableExports = ["pdf", "html", "tabular-data"];

  const previewModalProps = {
    body: () => (
      <div id="viewer-host" className="h-full w-full">
        <Viewer
          ref={viewerRef}
          exportsSettings={exportsSettings}
          availableExports={availableExports}
        />
      </div>
    ),
    disabled: loading || hasErrors,
    closeOnBackdrop: false,
    scale: "md",
    title: "Preview",
  };

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          <div>Export {type} Report</div>
        </div>
        <div className="flex gap-4">
          <Button action="default" label="Cancel" onClick={closeModal} />
          <Modal {...previewModalProps}>
            <Button
              label={"Export"}
              disabled={loading || hasErrors}
              loading={loading}
              onClick={formik.submitForm}
            />
          </Modal>
        </div>
      </div>
      <div className="w-50 grid grid-cols-2 gap-x-16 gap-y-4">
        {inputs?.map((item, index) => {
          const inputProps = {
            ...item,
            formik,
          };
          return <div key={index}>{getInput(inputProps)}</div>;
        })}
      </div>
    </div>
  );
};

export default ExportInvoiceReport;
