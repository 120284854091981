import { useMutation, useQuery } from "@apollo/client";
import { useEffect } from "react";

import { useFormik } from "formik";
import {
  GridInputs,
  Table,
  multiSelect,
} from "../../../../../../../components/Functional";
import { Button, Modal } from "../../../../../../../components/ui";
import * as Yup from "yup";
import { FETCH_WAREHOUSE_ITEMS_V2 as fetchWarehouseItemsV2 } from "../../../../../../../graphql/query/WarehouseItem";
import { ADD_WAREHOUSE_ITEM_CONTAINER as addWarehouseItemContainerMutation } from "../../../../../../../graphql/mutation/WarehouseItem";
import { FETCH_WAREHOUSE_CATEGORIES } from "../../../../../../../graphql/query/WarehouseCategory";
import { convertToFilter } from "../../../../../../../util";

const FilterBar = props => {
  const { formik } = props || {};
  const { data: { warehouseCategories = [] } = {} } = useQuery(
    FETCH_WAREHOUSE_CATEGORIES,
  );

  const inputs = {
    className: "grid-cols-4 gap-4",
    inputs: [
      {
        label: "Storage",
        name: "id",
        type: "text",
      },
      {
        label: "Category",
        name: "categoryId",
        type: "multi-select",
        options: warehouseCategories?.map(item => ({
          label: item.description,
          value: item.id,
        })),
      },
      {
        label: "Description",
        name: "description",
        type: "textarea",
      },
      {
        label: "External Ref",
        name: "externalRef",
        type: "text",
      },
    ],
  };

  const inputProps = {
    formik,
    ...inputs,
  };

  return <GridInputs {...inputProps} />;
};

const AddItemModal = props => {
  const { closeModal, warehouseItem, warehouseItemId } = props;
  const [
    addWarehouseItemContainer,
    {
      data: { addWarehouseItemContainer: { error } = {} } = {},
      loading,
      reset,
    },
  ] = useMutation(addWarehouseItemContainerMutation(), {
    refetchQueries: ["FetchWarehouseItemsV2"],
  });

  const formik = useFormik({
    initialValues: {},
  });

  const filterFormik = useFormik({
    initialValues: {},
    validationSchema: Yup.object({}),
  });

  const selectedItems = Object.keys(
    formik?.values?.selectedWarehouseItems?.ids || {},
  )?.map(item => parseInt(item.replace("_", "")));

  useEffect(() => {
    reset();
  }, [formik?.values?.selectedWarehouseItems]);

  const multiSelectProps = {
    formik,
    label: "Warehouse Items",
    name: "selectedWarehouseItems",
    keyName: "id",
    showAll: false,
  };

  const resetAll = () => {
    formik.resetForm();
    reset();
    filterFormik.resetForm();
    closeModal();
  };

  const tableProps = {
    dataKey: "warehouseItemsV2",
    FETCH_QUERY: fetchWarehouseItemsV2({
      category: true,
      artist: true,
      instance: { edition: { artwork: {} } },
    }),
    headers: [
      multiSelect(multiSelectProps),
      { label: "Storage#", name: "id", type: "label" },
      { label: "Category", name: "category.description", type: "label" },
      { label: "Group", name: "mgroup", type: "label" },
      { label: "Description", name: "description", type: "label" },
      { label: "Instance", name: "instanceId", type: "label" },
      { label: "Title", name: "instance.edition.artwork.title", type: "label" },
      { label: "Year", name: "instance.edition.year", type: "label" },
      { label: "Artist", name: "artist.name", type: "label" },
      { label: "External Ref", name: "externalRef", type: "label" },
    ],
    inputs: [],
    limit: 10,
    pagination: {
      variant: "sm",
    },
    variant: "dynamicHeight",
    variables: {
      idToSkip: {
        operator: "ne",
        value: warehouseItemId,
      },
      ...convertToFilter({
        values: filterFormik?.values,
        skipKeys: ["limit", "offset"],
      }),
    },
    skip: () => !filterFormik?.isValid,
  };

  const handleAddItem = () => {
    addWarehouseItemContainer({
      variables: {
        input: {
          container: warehouseItem?.id,
          items: selectedItems,
        },
      },
    }).then(resp => {
      const { data: { addWarehouseItemContainer: { success } = {} } = {} } =
        resp;

      if (success) {
        formik?.resetForm();
        reset();
        closeModal();
      }
    });
  };

  return (
    <div className="mt-6 flex w-full flex-col">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col">
          <div className="text-2xl font-bold">Add Warehouse Item(s)</div>
          <p className="mt-4">
            {selectedItems?.length} Selected
            <span className="ml-8 text-red-500">{error}</span>
          </p>
        </div>
        <div className="flex flex-col">
          <div>
            <Button
              action="default"
              className="mr-4"
              label="Clear Filters"
              onClick={() => {
                filterFormik.resetForm();
              }}
            />
            <Button
              action="default"
              className="mr-4"
              label="Cancel"
              onClick={() => {
                resetAll();
              }}
            />
            <Button
              label={loading ? "Adding" : "Add"}
              disabled={loading || !selectedItems?.length}
              onClick={handleAddItem}
            />
          </div>
        </div>
      </div>
      <FilterBar formik={filterFormik} />
      <Table {...tableProps} />
    </div>
  );
};

const AddItem = props => {
  const { warehouseItem, warehouseItemId } = props || {};

  const modalProps = {
    body: AddItemModal,
    closeOnBackdrop: false,
    warehouseItem,
    hideCloseButton: false,
    scale: "md",
    warehouseItemId,
  };

  return (
    <Modal {...modalProps}>
      <Button label="Add Item" action="black" />
    </Modal>
  );
};

export default AddItem;
