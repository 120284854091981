import { gql } from "@apollo/client";
import { InstanceProvenance as instanceProvenance } from "../fragments";

const CREATE_INSTANCE_PROVENANCE = props => gql`
  mutation CreateInstanceProvenance($input: CreateInstanceProvenanceInput!) {
    createInstanceProvenance(input: $input) {
      success
      error
      instanceProvenance {
        ${instanceProvenance(props)}
      }
    }
  }
`;

const UPDATE_INSTANCE_PROVENANCE = props => gql`
  mutation UpdateInstanceProvenance($input: UpdateInstanceProvenanceInput!) {
    updateInstanceProvenance(input: $input) {
      success
      error
      instanceProvenance {
        ${instanceProvenance(props)}
      }
    }
  }
`;

export { CREATE_INSTANCE_PROVENANCE, UPDATE_INSTANCE_PROVENANCE };
