import { useMutation } from "@apollo/client";

import { Button } from "../../../../components/ui";
import { FETCH_CONTACTS as fetchContacts } from "../../../../graphql/query/Contact";
import { SUBSCRIBE_CONTACTS } from "../../../../graphql/mutation/Contact";

const SubscribeModal = props => {
  const { closeModal, ids, search, select } = props || {};
  const [subscribeContacts, { loading }] = useMutation(SUBSCRIBE_CONTACTS, {
    refetchQueries: [fetchContacts()],
  });

  const handleClick = () => {
    subscribeContacts({
      variables: {
        input: {
          ...(select === "all" ? { search } : { ids }),
        },
      },
    }).then(resp => {
      const { data: { subscribeContacts: { success } = {} } = {} } = resp;

      if (success) {
        closeModal();
      }
    });
  };

  return (
    <div className="mt-6 flex w-full flex-col">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col">
          <div className="text-2xl font-bold">Subscribe Contacts:</div>
          <div className="pt-4 text-base font-normal">
            This will resubscribe the selected unsubscribed contacts. Are you
            sure you want to continue?
          </div>
        </div>
        <div className="flex flex-col">
          <div>
            <Button
              action="default"
              className="mr-4"
              label="Cancel"
              onClick={() => {
                closeModal();
              }}
            />
            <Button
              label={loading ? "Subscribing" : "Subscribe"}
              disabled={loading}
              onClick={handleClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscribeModal;
