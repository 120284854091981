import { Table } from "../../../../../../components/Functional";
import { FETCH_EXHIBITIONS_V2 as fetchExhibitionsV2 } from "../../../../../../graphql/query/Exhibition";
import SectionHeader from "../../components/SectionHeader";
import * as React from "react";

const DateComponent = props => {
  const { day, month, year } = props || {};
  const date = [day, month, year].filter(Boolean);

  return (
    <div className="flex items-center justify-center">{date?.join("/")}</div>
  );
};

const ArtworkExhibitions = props => {
  const { artworkId } = props || {};
  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "exhibitionsV2",
    headers: [
      { label: "Venue", name: "contact.company", type: "label" },
      { label: "Title", name: "title", type: "label" },
      { label: "Type", name: "type", type: "label" },
      {
        label: "Start Date",
        type: "custom",
        component: props => (
          <DateComponent
            day={props?.values?.startDay}
            month={props?.values?.startMonth}
            year={props?.values?.startYear}
          />
        ),
      },
      {
        label: "End Date",
        type: "custom",
        component: props => (
          <DateComponent
            day={props?.values?.endDay}
            month={props?.values?.endMonth}
            year={props?.values?.endYear}
          />
        ),
      },
    ],
    FETCH_QUERY: fetchExhibitionsV2({ contact: {} }),
    variables: {
      _exhibitionWork: {
        _instance: {
          _edition: {
            _artwork: {
              id: {
                operator: "eq",
                value: artworkId,
              },
            },
          },
        },
      },
    },
    className: "sm:px-0 lg:px-0",
  };

  return (
    <div className="w-full">
      <SectionHeader title="Exhibitions" />
      <div className="mb-6 w-full">
        <Table {...tableProps} />
      </div>
    </div>
  );
};

export default ArtworkExhibitions;
