import { useMutation } from "@apollo/client";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

import { Button, IconButton, Modal } from "../../../../../components/ui";
import { DELETE_REPORT_TEMPLATE } from "../../../../../graphql/mutation/ReportTemplate";

const Actions = props => {
  const { id, values } = props || {};
  const { name } = values || {};

  const navigate = useNavigate();
  const [deleteReportTemplate, { loading }] = useMutation(
    DELETE_REPORT_TEMPLATE,
    { refetchQueries: ["FetchReportTemplates"] },
  );

  const handleEdit = () => {
    return navigate(`/reports/${id}/edit`);
  };

  const handleDelete = closeModal => async () => {
    const res = await deleteReportTemplate({ variables: { input: { id } } });
    if (res?.data?.deleteReportTemplate?.success) {
      closeModal?.();
    }
  };

  const modalProps = {
    title: "",
    scale: "md",
    hideCloseButton: true,
    closeOnBackdrop: false,
    body: ({ closeModal }) => {
      return (
        <div className={`p-4`}>
          <div className={`flex flex-row items-center justify-between`}>
            <div className={`text-lg`}>Confirmation</div>
            <div className={`flex gap-6`}>
              <Button
                className={`!w-28`}
                action="default"
                label="Cancel"
                onClick={closeModal}
              />
              <Button
                loading={loading}
                className={`flex !w-28 items-center`}
                label={loading ? "Deleting" : "Delete"}
                onClick={handleDelete(closeModal)}
              />
            </div>
          </div>
          <div className="mt-8 text-sm font-normal">
            {`You are about to delete "${name}". Are you sure?`}
          </div>
        </div>
      );
    },
  };

  if (typeof id == "number") {
    return (
      <div className="user-list-actions flex text-right text-sm font-medium">
        {id && (
          <div className="flex gap-4">
            <IconButton variant="clean" title="Edit Select List">
              <PencilIcon
                className={`h-4 w-4 cursor-pointer text-gray-400`}
                onClick={handleEdit}
              />
            </IconButton>
            <Modal {...modalProps}>
              <IconButton variant="clean" title="Edit Select List">
                <TrashIcon className={`h-4 w-4 cursor-pointer text-gray-400`} />
              </IconButton>
            </Modal>
          </div>
        )}
      </div>
    );
  } else {
    return false;
  }
};

export default Actions;
