import { FETCH_CONSIGNMENTS_V2 as fetchConsignmentsV2 } from "../../../../../graphql/query/Consignment";
import { consignmentFilterStore } from "../../../components/useConsignmentFilters";

const SearchedConsignments = props => {
  const tableProps = {
    dataKey: "consignmentsV2",
    FETCH_QUERY: fetchConsignmentsV2({
      type: true,
      contact: {},
      consignmentWorks: { instance: {} },
    }),
    limit: 50,
    variables: {
      ...consignmentFilterStore.get(),
    },
  };

  return tableProps;
};

export default SearchedConsignments;
