import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import { useEffect, useRef } from "react";
import * as Yup from "yup";

import { GridInputs } from "../../../../../../../components/Functional";
import { Button } from "../../../../../../../components/ui";

import { CREATE_ITEM_CREDIT as createItemCreditMutation } from "../../../../../../../graphql/mutation/Item";
import { FETCH_PAY_TYPES } from "../../../../../../../graphql/query/PayType";
import { FETCH_NOMINALS } from "../../../../../../../graphql/query/Nominal";
import { FETCH_ITEM as fetchItemQuery } from "../../../../../../../graphql/query/Item";

const AddCreditNoteBody = props => {
  const { invoice, resetAll } = props || {};

  const [fetchItem] = useLazyQuery(
    fetchItemQuery({
      instance: { edition: { artwork: { imagesSummary: { imgT: {} } } } },
      type: {},
    }),
    { fetchPolicy: "network-only" },
  );

  const [
    createItemCredit,
    {
      data: {
        createItemCredit: { error: createItemCreditRespError } = {},
      } = {},
      error: createItemCreditError,
    },
  ] = useMutation(createItemCreditMutation(), {
    refetchQueries: ["FetchItemsV2"],
  });

  const { data: { payTypes = [] } = {} } = useQuery(FETCH_PAY_TYPES);
  const { data: { nominals = [] } = {} } = useQuery(FETCH_NOMINALS);

  const createDynamicValidation = fieldName => {
    const fieldNumber = fieldName.match(/\d+/); // Extract the number from the field name

    return Yup.object().shape({
      ...(/^amount_\d+$/.test(fieldName) && {
        [fieldName]: Yup.number().when([`id_${fieldNumber}`], {
          is: id => id !== undefined, // Validation is applied only if the corresponding id field is available (amount_1 for id_1)
          then: () =>
            Yup.number()
              .moreThan(0, "Invalid Value")
              .test("is-validAmt", "Invalid Value", function (value, context) {
                return value <= context.parent.amount;
              })
              .required("Invalid Value")
              .typeError("Invalid Value"),
          otherwise: () => Yup.number().notRequired(),
        }),
      }),
    });
  };

  const dynamicValidation = formData => {
    let validationSchema = Yup.object({
      amount: Yup.number().min(0).required("Please enter amount"),
      remaining: Yup.number().test(
        "allocation-validation",
        "Invalid allocation",
        function (remaining, context) {
          const allocated = context.parent.allocated;

          if (allocated !== 0) {
            if (remaining > 0) {
              return context.createError({
                message: "Credit amount must be properly allocated",
              });
            } else if (remaining < 0) {
              return context.createError({
                message: "Allocated amount is higher than credit amount",
              });
            }
          }
          return true;
        },
      ),
    });

    // eslint-disable-next-line
    formData && typeof formData === "object"
      ? Object.keys(formData).forEach(fieldName => {
          validationSchema = validationSchema.concat(
            createDynamicValidation(fieldName),
          );
        })
      : validationSchema;

    return validationSchema;
  };

  const creditFormik = useFormik({
    initialValues: {
      amount: 0,
      invoiceId: invoice?.id,
      items: [],
      remaining: 0,
      typeId: 6, // typeId 6 is for CreditNote
    },
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: () => dynamicValidation(creditFormik?.values),
    onSubmit: async values => {
      creditFormik?.setFieldValue("creditItemLoading", true);

      const finalValues = {
        amount: values?.amount,
        invoiceId: values?.invoiceId,
        typeId: values?.typeId,
        items: values?.items?.map((_, index) => ({
          id: values[`id_${index + 1}`],
          amount: values[`amount_${index + 1}`],
        })),
      };

      createItemCredit({
        variables: {
          input: finalValues,
        },
      })
        .then(resp => {
          const { data: { createItemCredit: { success } = {} } = {} } =
            resp || {};
          if (success) {
            resetAll();
            creditFormik?.resetForm?.();
          } else {
            creditFormik?.setFieldValue("creditItemLoading", false);
          }
        })
        .catch(() => {
          creditFormik?.setFieldValue("creditItemLoading", false);
        });
    },
  });

  const { creditAllItemLoading, creditItemLoading } =
    creditFormik?.values || {};

  const prevFormValuesRef = useRef(creditFormik.values);
  useEffect(() => {
    // Extract the changed item IDs
    const changedItemIDs = Object.keys(creditFormik.values)
      .filter(
        key =>
          /^id_\d+$/.test(key) &&
          creditFormik.values[key] !== prevFormValuesRef.current[key],
      )
      .map(key => parseInt(key.replace("id_", ""), 10));

    creditFormik?.values?.[`id_${changedItemIDs[0]}`] &&
      fetchItem({
        variables: {
          id: creditFormik?.values?.[`id_${changedItemIDs[0]}`],
        },
      }).then(resp => {
        const {
          data: { item: { amount, nominalId, description } = {} },
        } = resp || {};
        creditFormik?.setFieldValue(
          `nominalId_${changedItemIDs[0]}`,
          nominalId,
        );
        creditFormik?.setFieldValue(
          `description_${changedItemIDs[0]}`,
          description,
        );
        creditFormik?.setFieldValue(`amount_${changedItemIDs[0]}`, amount);
      });

    const totalAllocated = Object.keys(creditFormik.values)
      .filter(key => /^amount_\d+$/.test(key))
      .reduce((acc, key) => acc + (+creditFormik.values[key] || 0), 0);

    creditFormik?.setFieldValue("allocated", totalAllocated);
    creditFormik?.setFieldValue(
      "remaining",
      creditFormik?.values?.amount - totalAllocated,
    );

    prevFormValuesRef.current = creditFormik.values;
  }, [creditFormik.values]);

  const hasCreditErrors = Object.keys(creditFormik?.errors || {}).length > 0;

  const mutationError = createItemCreditError || createItemCreditRespError;

  const creditInputs = {
    className: "grid grid-cols-2 gap-8 mt-5",
    inputs: [
      {
        disabled: true,
        label: "Type",
        name: "typeId",
        options: payTypes?.map(item => ({
          label: item.name,
          value: item.numberId,
        })),
        type: "multi-select",
      },
      { label: "Amount", name: "amount", type: "number-currency" },
    ],
  };

  const creditInputProps = {
    formik: creditFormik,
    ...creditInputs,
  };

  const inputConfigs = {
    nominalId: {
      type: "multi-select",
      options: nominals?.map(item => ({ label: item?.label, value: item?.id })),
      appendMissingOption: true,
      disabled: true,
    },
    id: {
      type: "transactionSearch",
      filterInput: [
        { invoiceId: invoice?.id },
        { typeId: { value: 6, operator: "ne" } },
      ],
    },
    description: { type: "textarea", rows: 2, disabled: true },
    amount: { type: "number-currency", allowNegativeInput: false },
  };

  const creditItemInputs = {
    className: "grid grid-cols-4 gap-4 mt-5 pb-4 border-b-[1px]",
    inputs: [
      ...creditFormik.values.items.flatMap(item =>
        Object.keys(item).map(key => ({
          name: key,
          ...inputConfigs[key?.split("_")?.[0]],
        })),
      ),
    ],
  };

  const creditItemInputProps = {
    formik: creditFormik,
    ...creditItemInputs,
  };

  const balanceInputs = {
    className: "grid grid-cols-3 gap-8 mt-5",
    inputs: [
      {
        disabled: true,
        label: "Allocated",
        name: "allocated",
        type: "text-currency",
      },
      {
        disabled: true,
        label: "Credit Amount",
        name: "amount",
        type: "text-currency",
      },
      {
        disabled: true,
        label: "Remaining",
        name: "remaining",
        type: "text-currency",
      },
    ],
  };

  const balanceInputProps = {
    formik: creditFormik,
    ...balanceInputs,
  };

  const handleAddItem = () => {
    const itemsCount = creditFormik?.values?.items?.length;
    const defaultItem = {
      [`nominalId_${itemsCount + 1}`]: null,
      [`id_${itemsCount + 1}`]: null,
      [`description_${itemsCount + 1}`]: "",
      [`amount_${itemsCount + 1}`]: 0,
    };
    creditFormik?.setFieldValue("items", [
      ...creditFormik.values.items,
      defaultItem,
    ]);
  };

  const handleCreditAll = () => {
    creditFormik?.setFieldValue("creditAllItemLoading", true);

    const finalValues = {
      amount: creditFormik?.values?.amount,
      invoiceId: creditFormik?.values?.invoiceId,
      typeId: creditFormik?.values?.typeId,
      creditAll: true,
    };

    createItemCredit({
      variables: {
        input: finalValues,
      },
    })
      .then(resp => {
        const { data: { createItemCredit: { success } = {} } = {} } =
          resp || {};
        if (success) {
          resetAll();
          creditFormik?.resetForm?.();
        } else {
          creditFormik?.setFieldValue("creditAllItemLoading", false);
        }
      })
      .catch(() => {
        creditFormik?.setFieldValue("creditAllItemLoading", false);
      });
  };

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          <div>Add Transaction</div>
          {(createItemCreditError || createItemCreditRespError) && (
            <small className="text-sm font-medium text-red-600">
              {createItemCreditError || createItemCreditRespError}
            </small>
          )}
        </div>
        <div className="flex gap-4">
          <Button
            action="default"
            label="Cancel"
            onClick={() => {
              resetAll();
              creditFormik?.resetForm?.();
            }}
          />
          <Button
            label={"Credit All"}
            disabled={creditAllItemLoading || hasCreditErrors || mutationError}
            loading={creditAllItemLoading}
            onClick={handleCreditAll}
          />
          <Button
            label={"Done"}
            disabled={creditItemLoading || hasCreditErrors || mutationError}
            loading={creditItemLoading}
            onClick={creditFormik?.submitForm}
          />
        </div>
      </div>
      <div className="grid">
        <GridInputs {...creditInputProps} />
      </div>
      <div className="mt-2 flex flex-row-reverse">
        <Button
          label={"Add"}
          disabled={creditAllItemLoading || creditItemLoading}
          onClick={() => {
            handleAddItem();
          }}
        />
      </div>
      <div>
        <div>Credits</div>
        <div className="mt-5 grid grid-cols-4 gap-4 border-b-[1px] pb-4">
          <h5 className="text-sm font-bold">Nominal</h5>
          <h5 className="text-sm font-bold">Reference</h5>
          <h5 className="text-sm font-bold">Description</h5>
          <h5 className="text-sm font-bold">Amount</h5>
        </div>
        <GridInputs {...creditItemInputProps} />
      </div>
      <div>
        <GridInputs {...balanceInputProps} />
      </div>
    </div>
  );
};

export default AddCreditNoteBody;
