import { GridInputs } from "../../../../../components/Functional";
import { useEffect } from "react";

const Shipments = props => {
  const { formik } = props || {};

  useEffect(() => {
    if (formik?.values?.control) {
      formik?.setFieldValue("temporaryRelease", false);
      formik?.setFieldValue("_warehouseItem._warehouseMove.ti", {
        operator: "eq",
        value: -1,
      });
    } else {
      formik?.setFieldValue("_warehouseItem._warehouseMove.ti", null);
    }
  }, [formik?.values?.control]);

  useEffect(() => {
    if (formik?.values?.temporaryRelease) {
      formik?.setFieldValue("control", false);
      formik?.setFieldValue("_warehouseItem._warehouseMove.ti", null);
    } else if (!formik?.values?.control) {
      formik?.setFieldValue("_warehouseItem._warehouseMove.ti", null);
    }
  }, [formik?.values?.temporaryRelease]);

  useEffect(() => {
    if (formik?.values?.whereNoShipment) {
      formik?.setFieldValue("noShipment", {
        operator: "eq",
        value: null,
      });
    } else if (formik?.values?.whereNoShipment === false) {
      formik?.setFieldValue("noShipment", null);
    }
  }, [formik?.values?.whereNoShipment]);

  const inputs = {
    className: "grid grid-cols-2 gap-16 mt-5 pb-5",
    inputs: [
      {
        className: "grid-cols-2 gap-16",
        inputs: [
          {
            label: "Shipment #",
            name: "_warehouseItem._warehouseMove._shipment.id",
            type: "number",
          },
          {
            label: "Shipping Ref",
            name: "_warehouseItem._warehouseMove._shipment.shippingRef",
            type: "text",
          },
        ],
      },
      {
        className: "grid-cols-1 gap-4",
        inputs: [
          // {
          //   designType: "checkbox",
          //   label: "No Shipment",
          //   name: "whereNoShipment",
          //   type: "toggle",
          // },
          {
            designType: "checkbox",
            label: "Control (Customs)",
            name: "control",
            type: "toggle",
          },
          {
            designType: "checkbox",
            label: "Temporary Release (Customs)",
            name: "temporaryRelease",
            type: "toggle",
          },
        ],
      },
    ],
  };

  const gridInputProps = {
    formik,
    ...inputs,
  };

  return (
    <div className="w-full">
      <GridInputs {...gridInputProps} />
    </div>
  );
};

export default Shipments;
