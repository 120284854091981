import { useLazyQuery, useQuery } from "@apollo/client";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { GridInputs } from "../../../../components/Functional";
import { Button } from "../../../../components/ui";

import { FETCH_INSTANCES_V2 as fetchInstancesV2Query } from "../../../../graphql/query/Instance";
import { FETCH_ARTISTS as fetchArtists } from "../../../../graphql/query/Artist";
import { FETCH_STATUS } from "../../../../graphql/query/Status";
import { FETCH_COMPANIES as fetchCompaniesQuery } from "../../../../graphql/query/Company";
import { FETCH_ARTWORK_CATEGORIES } from "../../../../graphql/query/ArtworkCategory";

import { instanceFilterStore, useInstanceFilters } from "../useInstanceFilters";

import Consignments from "./components/Consignments";
import Query from "./components/Query";
import Tabs from "./components/Tabs";
import Provenance from "./components/Provenance";
import Auctions from "./components/Auctions";
import History from "./components/History";
import Artfairs from "./components/Artfairs";
import Location from "./components/Location";
import Shipments from "./components/Shipments";
import Condition from "./components/Condition";
import Technical from "./components/Technical";
import Jobs from "./components/Jobs";
import Warehouse from "./components/Warehouse";
import Sort from "./components/Sort";
import useUser from "../../../../hooks/useUser";

const InstanceSearch = props => {
  const { closeModal } = props || {};
  const [fetchInstancesV2, { loading, data: { instancesV2 } = {} }] =
    useLazyQuery(fetchInstancesV2Query());
  const [fetchArtistsLazily, { data: { artists = [] } = {} }] =
    useLazyQuery(fetchArtists());
  const { data: { artworkCategories = [] } = {} } = useQuery(
    FETCH_ARTWORK_CATEGORIES,
  );
  const [fetchCompanies, { data: { companies = [] } = {} }] = useLazyQuery(
    fetchCompaniesQuery(),
  );

  const { hasPermission } = useUser();
  const readCompanyPermission = hasPermission("READ_COMPANY");
  const readArtistPermission = hasPermission("READ_ARTIST");

  useEffect(() => {
    if (readArtistPermission) {
      fetchArtistsLazily();
    }
  }, [readArtistPermission]);

  useEffect(() => {
    if (readCompanyPermission) {
      fetchCompanies();
    }
  }, [readCompanyPermission]);

  const { data: { status = [] } = {} } = useQuery(FETCH_STATUS);

  const navigate = useNavigate();
  const { formik } = useInstanceFilters();
  const input = instanceFilterStore.get();

  useEffect(() => {
    if (formik?.values?.fabricatedIsNull) {
      formik?.setFieldValue("fabricated", {
        operator: "isNull",
        value: null,
      });
    } else if (formik?.values?.fabricated?.operatpor === "isNull") {
      formik?.setFieldValue("fabricated", null);
    }
  }, [formik?.values?.fabricatedIsNull]);

  useEffect(() => {
    if (
      formik?.values?.fabricatedIsNull === true &&
      [-1, 0]?.includes(formik?.values?.fabricated)
    ) {
      formik?.setFieldValue("fabricatedIsNull", false);
    }
  }, [formik?.values?.fabricated]);

  useEffect(() => {
    if (formik?.values?.previousRef) {
      formik?.setFieldValue("_item.ref", {
        operator: "like",
        value: formik?.values?.previousRef,
      });
      formik?.setFieldValue("_consignmentWork.ref", {
        operator: "like",
        value: formik?.values?.previousRef,
      });
    } else if (!formik?.values?.previousRef) {
      formik?.setFieldValue("_item.ref", null);
      formik?.setFieldValue("_consignmentWork.ref", null);
    }
  }, [formik?.values?.previousRef]);

  useEffect(() => {
    if (formik?.values?.onLoan === true) {
      formik?.setFieldValue("_consignmentWork._consignment.due", {
        operator: "gt",
        value: new Date(),
      });
      formik?.setFieldValue("_consignmentWork._consignment.typeId", {
        operator: "eq",
        value: 2,
      });
    } else if (formik?.values?.onLoan === false) {
      formik?.setFieldValue("_consignmentWork._consignment.due", null);
      formik?.setFieldValue("_consignmentWork._consignment.typeId", null);
    }
  }, [formik?.values?.onLoan]);

  const standardOptions = [
    { label: "Standard", value: 0 },
    { label: "Non-Standard", value: -1 },
    { label: "UnChecked", value: null },
  ];

  const inputs = {
    className: "grid grid-cols-4 gap-16 mt-5",
    inputs: [
      {
        className: "gap-4",
        inputs: [
          {
            isMulti: true,
            label: "Control Company",
            name: "companyId",
            type: "multi-select",
            options: companies?.edges?.map(item => ({
              label: `${item?.code ? item?.code + " |" : ""} ${item?.name || ""}`,
              value: item?.id,
            })),
          },
          { label: "Control Reference", name: "ref", type: "text" },
          {
            isMulti: true,
            label: "Artist",
            type: "multi-select",
            disabled: !readArtistPermission,
            name: "_edition._artwork._artist.id",
            options: artists?.map(item => ({
              label: item?.name,
              value: item?.id,
            })),
          },
          {
            isMulti: true,
            label: "Node Company",
            name: "nodeId",
            type: "multi-select",
            disabled: !readCompanyPermission,
            options: companies?.edges?.map(item => ({
              label: item?.name,
              value: item?.id,
            })),
          },
          {
            hasOperator: true,
            label: "Title",
            name: "_edition._artwork.title",
            type: "text",
          },
          { label: "Year", name: "_edition.year", type: "text" },
          {
            hasOperator: true,
            label: "Medium",
            name: "_edition._artwork.medium",
            type: "text",
          },
          { label: "Name", name: "name", type: "text" },
        ],
      },
      {
        className: "grid-cols-1 gap-4",
        inputs: [
          { label: "Previous Reference", name: "previousRef", type: "text" },
          {
            label: "Artist Representation",
            name: "_edition._artwork._artist.represented",
            type: "multi-select",
            options: [
              { label: "Internal", value: 0 },
              { label: "External", value: 1 },
              { label: "Secondary Market", value: 2 },
              { label: "Not Represented", value: 3 },
            ],
          },
          {
            hasOperator: true,
            label: "Artist Ref",
            name: "artistRef",
            type: "text",
          },
          {
            isMulti: true,
            label: "Category",
            name: "_edition._artwork.categoryId",
            type: "multi-select",
            options: artworkCategories?.map(item => ({
              label: item?.name,
              value: item?.id,
            })),
          },
          {
            hasOperator: true,
            label: "Series",
            name: "_edition._artwork.series",
            type: "text",
          },
          { label: "Edition no", name: "editionNumber", type: "number" },
          {
            hasOperator: true,
            label: "Edition Size",
            name: "_edition.number",
            type: "number",
          },
          {
            hasOperator: true,
            isMulti: true,
            label: "Status",
            name: "statusId",
            type: "multi-select",
            options: status?.map(item => ({
              label: item?.name,
              value: item?.id,
            })),
          },
        ],
      },
      {
        className: "grid-cols-1 gap-4",
        inputs: [
          {
            hasOperator: true,
            label: "Class",
            name: "_edition._artwork.classId",
            type: "number",
          },
          {
            label: "Dimensons",
            name: "_edition._artwork.dimensions",
            type: "text",
          },
          {
            label: "Handling",
            name: "_edition._artwork.standardId",
            type: "multi-select",
            options: standardOptions,
          },
          {
            label: "Description",
            name: "_edition._artwork.description",
            type: "text",
          },
          { label: "Notes", name: "notes", type: "text" },
          {
            label: "Certificate",
            name: "certificate",
            type: "text",
            postLabelInput: {
              designType: "threeStateCheckbox",
              label: "Sent",
              name: "certificateSent",
              type: "toggle",
            },
          },
          { label: "Id", name: "id", type: "number" },
          {
            hasOperator: true,
            label: "Exhibition",
            name: "_exhibitionWork._exhibition.id",
            type: "exhibitionSearch",
          },
        ],
      },
      {
        className: "grid-cols-1 gap-4",
        inputs: [
          {
            label: "Ecommerce",
            name: "channelEcommerce",
            type: "multi-select",
            options: [
              { label: "True", value: 1 },
              { label: "False", value: 0 },
            ],
          },
          {
            label: "Presentation",
            name: "channelPresentation",
            type: "multi-select",
            options: [
              { label: "None", value: 0 },
              { label: "Public", value: 1 },
              { label: "Private", value: 2 },
            ],
          },
          {
            designType: "threeStateCheckbox",
            label: "Fabricated",
            name: "fabricated",
            type: "toggle",
            postLabelInput: {
              designType: "checkbox",
              label: "Is Null",
              name: "fabricatedIsNull",
              type: "toggle",
            },
          },
          {
            designType: "threeStateCheckbox",
            label: "For Sale",
            name: "forSale",
            type: "toggle",
          },
          {
            designType: "threeStateCheckbox",
            label: "Framed",
            name: "framed",
            type: "toggle",
          },
          {
            designType: "checkbox",
            label: "On Loan",
            name: "onLoan",
            type: "toggle",
          },
          {
            designType: "threeStateCheckbox",
            label: "Photographed",
            name: "_edition._artwork.photographed",
            type: "toggle",
          },
        ],
      },
    ],
  };

  const inputProps = {
    formik,
    ...inputs,
  };

  const searchButtonProps = {
    action: "black",
    label: loading ? "Searching" : "Search",
    disabled: loading,
    onClick: e => {
      delete input.previousRef;

      fetchInstancesV2({
        variables: {
          input,
        },
      }).then(resp => {
        navigate("/instance?status=all");
        closeModal();
      });
    },
  };

  const resetButtonProps = {
    action: "default",
    label: "Reset",
    onClick: formik?.resetForm,
  };

  const tabComponentProps = {
    instancesV2,
    formik,
  };

  const tabsProps = {
    formik,
    name: "tab",
    options: [
      {
        label: "Consignments",
        value: "consignments",
        component: <Consignments {...tabComponentProps} />,
      },
      {
        label: "Provenance",
        value: "provenance",
        component: <Provenance {...tabComponentProps} />,
      },
      {
        label: "Auctions",
        value: "auctions",
        component: <Auctions {...tabComponentProps} />,
      },
      {
        label: "History",
        value: "history",
        component: <History {...tabComponentProps} />,
      },
      {
        label: "Artfairs",
        value: "artfairs",
        component: <Artfairs {...tabComponentProps} />,
      },
      {
        label: "Location",
        value: "location",
        component: <Location {...tabComponentProps} />,
      },
      {
        label: "Shipments",
        value: "shipments",
        component: <Shipments {...tabComponentProps} />,
      },
      {
        label: "Condition",
        value: "condition",
        component: <Condition {...tabComponentProps} />,
      },
      {
        label: "Technical",
        value: "technical",
        component: <Technical {...tabComponentProps} />,
      },
      {
        label: "Jobs",
        value: "jobs",
        component: <Jobs {...tabComponentProps} />,
      },
      {
        label: "Warehouse",
        value: "warehouse",
        component: <Warehouse {...tabComponentProps} />,
      },
      {
        label: "Sort",
        value: "sort",
        component: <Sort {...tabComponentProps} />,
      },
      {
        label: "Query",
        value: "query",
        component: <Query {...tabComponentProps} />,
      },
    ],
  };

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between">
        <div className="text-xl font-bold">Instance Search</div>
        <div className="flex gap-4">
          <Button {...resetButtonProps} />
          <Button {...searchButtonProps} />
        </div>
      </div>
      <div className="flex flex-col gap-8">
        <GridInputs {...inputProps} />
        <Tabs {...tabsProps} />
      </div>
    </div>
  );
};

export default InstanceSearch;
