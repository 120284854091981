import { useQuery } from "@apollo/client";
import { GridInputs } from "../../../../../../components/Functional";
import { FETCH_ENTITIES } from "../../../../../../graphql/query/Entity";

const Handover = props => {
  const { formik } = props || {};
  const { data: { entities } = {} } = useQuery(FETCH_ENTITIES);

  const inputs = {
    className: "grid grid-cols-2 gap-16",
    inputs: [
      {
        className: "grid grid-cols-1 gap-4",
        inputs: [
          {
            label: "Handover From",
            name: "CRMRegistrar.salesUserId",
            options: entities?.edges?.map(item => {
              return { label: item.name, value: item.id };
            }),
            type: "multi-select",
          },
          {
            label: "Handover To",
            name: "CRMRegistrar.userId",
            options: entities?.edges?.map(item => {
              return { label: item.name, value: item.id };
            }),
            type: "multi-select",
          },
        ],
      },
      { label: "Notes", name: "CRMRegistrar.notes", type: "textarea" },
    ],
  };

  const gridInputProps = {
    formik,
    ...inputs,
  };

  return <GridInputs {...gridInputProps} />;
};

export default Handover;
