import { useMutation } from "@apollo/client";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

import { FETCH_LISTS as fetchLists } from "../../../graphql/query/List";
import { DELETE_LIST } from "../../../graphql/mutation/List";
import { Button, IconButton, Modal } from "../../ui";

const TableActions = props => {
  const navigate = useNavigate();
  const {
    listKey = "listId",
    onSuccess,
    statusKey = "selectListWorking",
    values,
  } = props || {};
  const { id, name } = values || {};
  const [deleteList, { loading }] = useMutation(DELETE_LIST, {
    refetchQueries: [fetchLists()],
  });

  const handleDelete = closeModal => async () => {
    const res = await deleteList({ variables: { input: { ids: [id] } } });
    if (res?.data?.deleteList?.success) {
      onSuccess?.();
      closeModal();
    }
  };

  const handleEdit = () => {
    navigate(`?status=${statusKey}&${listKey}=${id}`);
  };

  const modalProps = {
    title: "",
    scale: "md",
    hideCloseButton: true,
    closeOnBackdrop: false,
    body: ({ closeModal }) => {
      return (
        <div className={`p-4`}>
          <div className={`flex flex-row items-center justify-between`}>
            <div className={`text-lg`}>Confirmation</div>
            <div className={`flex gap-6`}>
              <Button
                className={`!w-28`}
                action="default"
                label="Cancel"
                onClick={closeModal}
              />
              <Button
                loading={loading}
                className={`flex !w-28 items-center`}
                label={loading ? "Deleting" : "Delete list"}
                onClick={handleDelete(closeModal)}
              />
            </div>
          </div>
          <div className="mt-8 text-sm font-normal">
            {`You are about to delete "${name}". Are you sure?`}
          </div>
        </div>
      );
    },
  };

  if (typeof id == "number") {
    return (
      <div className="user-list-actions flex text-right text-sm font-medium">
        {id && (
          <div className="flex flex-row gap-4">
            <IconButton variant="clean" title="Edit Select List">
              <PencilIcon
                className={`h-4 w-4 cursor-pointer text-gray-400`}
                onClick={handleEdit}
              />
            </IconButton>
            <Modal {...modalProps}>
              <IconButton variant="clean" title="Delete Select List">
                <TrashIcon className={`h-4 w-4 cursor-pointer text-gray-400`} />
              </IconButton>
            </Modal>
          </div>
        )}
      </div>
    );
  } else {
    return false;
  }
};

export default TableActions;
