import { gql } from "@apollo/client";
import { CheckList } from "../fragments";

const FETCH_CHECK_LISTS_V2 = gql`
  query FetchCheckListsV2($input: AdvCheckListFilterInput!) {
    checkListsV2(input: $input) {
      count
      edges {
        ${CheckList}
      }
    }
  }
`;

export { FETCH_CHECK_LISTS_V2 };
