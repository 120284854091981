import { useMutation, useQuery } from "@apollo/client";
import { getInput } from "../../../../../../components/ui/Input";
import SectionHeader from "../../components/SectionHeader";
import {
  CREATE_CONTACT_ARTIST as createContactArtistMutation,
  DELETE_CONTACT_ARTIST,
} from "../../../../../../graphql/mutation/ContactArtist";
import {
  CREATE_CONTACT_FOCUS as createContactFocusMutation,
  DELETE_CONTACT_FOCUS,
} from "../../../../../../graphql/mutation/ContactFocus";
// import { FETCH_CONTACT as fetchContact } from "../../../../../../graphql/query/Contact";
import { FETCH_ARTISTS as fetchArtists } from "../../../../../../graphql/query/Artist";
import { FETCH_FOCUSES } from "../../../../../../graphql/query/Focus";
import useUser from "../../../../../../hooks/useUser";

const CollectionDetail = props => {
  const { contactId, formik } = props || {};
  const { data: { artists } = {} } = useQuery(fetchArtists());
  const { data: { focuses } = {} } = useQuery(FETCH_FOCUSES);
  const { hasPermission } = useUser();
  const [createContactArtist] = useMutation(createContactArtistMutation(), {
    refetchQueries: ["FetchContact"],
  });
  const [deleteContactArtist] = useMutation(DELETE_CONTACT_ARTIST, {
    refetchQueries: ["FetchContact"],
  });
  const [createContactFocus] = useMutation(createContactFocusMutation(), {
    refetchQueries: ["FetchContact"],
  });
  const [deleteContactFocus] = useMutation(DELETE_CONTACT_FOCUS, {
    refetchQueries: ["FetchContact"],
  });

  const handleArtists = (value, actionProps) => {
    const { action, removedValue, removedValues, option } = actionProps || {};

    switch (action) {
      case "select-option":
        createContactArtist({
          variables: {
            input: { contactId: +contactId, artistId: +option.value },
          },
        });
        break;
      case "remove-value":
        deleteContactArtist({
          variables: { input: { id: +removedValue.contactArtistId } },
        });
        break;
      case "clear":
        removedValues.forEach(item =>
          deleteContactArtist({
            variables: { input: { id: +item.contactArtistId } },
          }),
        );
        break;
    }
  };

  const handleFocuses = (value, actionProps) => {
    const { action, removedValue, removedValues, option } = actionProps || {};

    switch (action) {
      case "select-option":
        createContactFocus({
          variables: {
            input: { contactId: +contactId, focusId: +option.value },
          },
        });
        break;
      case "remove-value":
        deleteContactFocus({
          variables: { input: { id: +removedValue.contactFocusId } },
        });
        break;
      case "clear":
        removedValues.forEach(item =>
          deleteContactFocus({
            variables: { input: { id: +item.contactFocusId } },
          }),
        );
        break;
    }
  };

  const inputs = [
    {
      label: "Collection Status",
      name: "collectionActive",
      options: [
        { label: "Not Known", value: null },
        { label: "Active", value: -1 },
        { label: "Inactive", value: 0 },
      ],
      type: "select",
      submitOnBlur: true,
    },
    {
      handleAction: handleArtists,
      isDisabled: !hasPermission("EDIT_CONTACT_ARTIST"),
      isMulti: true,
      label: "Artist",
      name: "contactArtists",
      options: artists?.map(artist => ({
        label: artist.name,
        value: artist.id,
      })),
      type: "multi-select",
    },
    {
      label: "Collection notes",
      name: "collection",
      type: "textarea",
      submitOnBlur: true,
    },
    {
      handleAction: handleFocuses,
      isDisabled: !hasPermission("EDIT_CONTACT_FOCUS"),
      isMulti: true,
      label: "Focus",
      name: "contactFocuses",
      options: focuses?.map(focus => ({
        label: focus.description,
        value: focus.id,
      })),
      type: "multi-select",
    },
  ];

  return (
    <div className="w-full">
      <SectionHeader title="Collection" />
      <div className="grid grid-cols-2 gap-4">
        {inputs?.map((input, index) => {
          const inputProps = {
            ...input,
            formik,
          };
          return (
            <div key={index} className="w-full">
              {getInput(inputProps)}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CollectionDetail;
