import { useMutation, useQuery } from "@apollo/client";
import { CheckIcon } from "@heroicons/react/24/solid";
import { useFormik } from "formik";
import * as React from "react";
import * as Yup from "yup";
import { Link } from "react-router-dom";

import { GridInputs, Table } from "../../../../../../components/Functional";
import { Button, Modal } from "../../../../../../components/ui";
import { FETCH_INSTANCES_V2 as fetchInstancesV2 } from "../../../../../../graphql/query/Instance";
import { FETCH_EDITION_TYPES } from "../../../../../../graphql/query/EditionType";
import { UPDATE_EDITION as updateEditionMutation } from "../../../../../../graphql/mutation/Edition";
import useUser from "../../../../../../hooks/useUser";
import { getChangedFields, useDebounce } from "../../../../../../util";

import SectionHeader from "../../components/SectionHeader";

import InstanceStatus from "./components/InstanceStatus";
import AddEdition from "./components/AddEdition";
import CreateWork from "./components/CreateWork";
import DeleteEdition from "./components/DeleteEdition";
import Actions from "./components/Actions";

const FabricatedComponent = props => {
  const value = props.label || "";
  // show check icon if priority is -1
  return (
    <div className="flex items-center justify-center">
      {value === -1 && <CheckIcon className="h-5 w-5" aria-hidden="true" />}
    </div>
  );
};

const ArtworkEditions = props => {
  const { artwork, artworkId } = props || {};
  const { hasPermission } = useUser();

  const [updateEdition] = useMutation(updateEditionMutation(), {
    refetchQueries: ["FetchArtwork"],
  });
  const { data: { editionTypes = [] } = {} } = useQuery(FETCH_EDITION_TYPES);

  const formik = useFormik({
    initialValues: {
      selectedEditionId: artwork?.editions?.[0]?.id,
      year: artwork?.editions?.[0]?.year,
      number: artwork?.editions?.[0]?.number,
      ap: artwork?.editions?.[0]?.ap,
    },
    enableReinitialize: false,
    validationSchema: Yup.object({
      year: Yup.string()
        .required("Year is required")
        .typeError("Year is required"),
      number: Yup.number().nullable(),
    }),
  });

  const selectedEdition = React.useMemo(
    () =>
      artwork?.editions?.find(
        edition => edition?.id === formik?.values?.selectedEditionId,
      ),
    [formik?.values?.selectedEditionId],
  );

  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "instancesV2",
    headers: [
      {
        label: "Ref",
        name: "name",
        type: "custom",
        component: ({ values }) => (
          <Link className="underline" to={`/instance/${values?.id}/details`}>
            {values?.name}
          </Link>
        ),
      },
      { label: "Edition #", name: "editionNumber", type: "label" },
      {
        label: "Status",
        name: "status.name",
        type: "custom",
        component: InstanceStatus,
      },
      {
        label: "Fabricated",
        name: "fabricated",
        type: "custom",
        component: FabricatedComponent,
      },
      { label: "Value", name: "value.value", type: "label" },
      { label: "Artist Ref", name: "artistRef", type: "label" },
      {
        label: "Location",
        name: "location",
        type: "label",
        labelClass: "min-w-full",
      },
      { label: "", name: "actions", type: "custom", component: Actions },
    ],
    FETCH_QUERY: fetchInstancesV2({ status: {}, value: {}, location: true }),
    limit: 10,
    variables: {
      _edition: {
        id: {
          operator: "eq",
          value: formik?.values?.selectedEditionId,
        },
        _artwork: {
          id: {
            operator: "eq",
            value: artworkId,
          },
        },
      },
    },
    className: "sm:px-0 lg:px-0",
  };

  React.useEffect(() => {
    if (selectedEdition) {
      formik.setFieldValue("number", selectedEdition?.number);
      formik.setFieldValue("ap", selectedEdition?.ap);
      formik.setFieldValue("year", selectedEdition?.year);
    }
  }, [selectedEdition]);

  const debouncedValues = useDebounce(formik?.values, 500);

  const updateValue = values => {
    if (!formik?.values?.selectedEditionId) return;

    const changedFields = getChangedFields(
      values,
      artwork?.editions?.find(
        edition => edition?.id === formik?.values?.selectedEditionId,
      ),
    );

    delete changedFields?.selectedEditionId;

    if (Object.keys(changedFields).length > 0) {
      const { ap, ...rest } = changedFields;
      updateEdition({
        variables: {
          input: {
            id: +formik?.values?.selectedEditionId,
            ...rest,
            ...(ap ? { ap: +ap } : {}),
          },
        },
      });
    }
  };

  React.useEffect(() => {
    formik?.dirty &&
      debouncedValues &&
      !Object.values(formik?.errors).length &&
      updateValue(debouncedValues);
  }, [debouncedValues]);

  const inputs = {
    className: "grid grid-cols-4 gap-16",
    inputs: [
      {
        className: "col-span-1 gap-4",
        inputs: [
          {
            label: "Select Edition",
            name: "selectedEditionId",
            type: "select",
            options: artwork?.editions?.map(item => ({
              label: `Edition ${item?.id}`,
              value: item?.id,
            })),
          },
        ],
      },
      {
        className: "col-span-1 gap-4",
        inputs: [
          {
            disabled: !hasPermission("UPDATE_EDITION"),
            label: "Year",
            name: "year",
            type: "text",
          },
        ],
      },
      {
        className: "col-span-1 gap-4",
        inputs: [
          {
            disabled: !hasPermission("UPDATE_EDITION"),
            label: "Type",
            name: "ap",
            type: "multi-select",
            options: editionTypes?.map(item => ({
              label: `${item?.description} (${item?.code})`,
              value: item?.id,
            })),
          },
        ],
      },
      {
        className: "col-span-1 gap-4",
        inputs: [
          {
            disabled: !hasPermission("UPDATE_EDITION"),
            label: "Number of works in edition",
            name: "number",
            rows: 6,
            type: "number",
          },
        ],
      },
    ],
  };

  const detailInputProps = {
    formik,
    ...inputs,
  };

  const titleInputs = {
    className: "grid grid-cols-4 gap-16",
    inputs: [
      {
        className: "col-span-1 gap-4",
        inputs: [
          {
            label: "",
            name: "selectedEditionId",
            type: "text",
            disabled: true,
            inputClassName: "!bg-white !text-gray-400 !border-gray-400",
          },
        ],
      },
    ],
  };

  const titleInputProps = {
    formik,
    ...titleInputs,
  };

  const createWorkModalProps = {
    artwork,
    body: CreateWork,
    closeOnBackdrop: true,
    disabled: !selectedEdition,
    edition: selectedEdition || {},
    hideCloseButton: true,
    scale: "md",
  };

  const modalProps = {
    body: AddEdition,
    closeOnBackdrop: true,
    artwork,
    hideCloseButton: true,
    scale: "md",
  };

  const deleteModalProps = {
    body: DeleteEdition,
    closeOnBackdrop: true,
    scale: "sm",
    disabled: !selectedEdition,
    editionId: formik?.values?.selectedEditionId,
    hideCloseButton: true,
  };

  const actions = [
    {
      component: () => {
        return (
          <Modal {...modalProps}>
            <Button label="Add Edition" action="black" />
          </Modal>
        );
      },
    },
    {
      component: () => {
        return (
          <Modal {...createWorkModalProps}>
            <Button
              disabled={!selectedEdition}
              label="Create Work"
              action="black"
            />
          </Modal>
        );
      },
    },
    {
      component: () => {
        return (
          <Modal {...deleteModalProps}>
            <Button
              disabled={!selectedEdition}
              label="Delete Edition"
              action="black"
            />
          </Modal>
        );
      },
    },
  ];

  return (
    <div className="w-full">
      <SectionHeader title={`Editions - ${artwork?.title}`} actions={actions} />
      <div className="w-full px-8 py-8">
        <GridInputs {...titleInputProps} />
      </div>
      <div className="w-full border-t px-8 py-8">
        <GridInputs {...detailInputProps} />
      </div>
      <div className="mb-6 w-full">
        <Table {...tableProps} />
      </div>
    </div>
  );
};

export default ArtworkEditions;
