import { FETCH_INVOICES_V2 as fetchInvoicesV2 } from "../../../../../graphql/query/Invoice";
import { invoiceFilterStore } from "../../../components/useInvoiceFilters";

const SearchedInvoices = () => {
  const tableProps = {
    dataKey: "invoicesV2",
    FETCH_QUERY: fetchInvoicesV2({
      company: {},
      type: {},
      contact: {},
      currency: {},
      invoiceTotals: {},
    }),
    variables: {
      ...invoiceFilterStore.get(),
    },
  };

  return tableProps;
};

export default SearchedInvoices;
