import { gql } from "@apollo/client";
import { FabricationType } from "../fragments";

const FETCH_FABRICATION_TYPES = gql`
  query FetchFabricationTypes($input: FabricationTypeFilterInput) {
    fabricationTypes(input: $input) {
      ${FabricationType}
    }
  }
`;

export { FETCH_FABRICATION_TYPES };
