import React from "react";
import { Link } from "react-router-dom";

import SectionHeader from "../../components/SectionHeader";
import { Table } from "../../../../../../components/Functional";
import { Avatar, Button, Modal } from "../../../../../../components/ui";
import AddProvenance from "./components/AddProvenance";
import Actions from "./components/Actions";

import { FETCH_INSTANCE_PROVENANCES as fetchInstanceProvenance } from "../../../../../../graphql/query/InstanceProvenance";

const clientName = props => {
  return (
    <>
      {props?.values?.contactId ? (
        <div className="flex items-center gap-2">
          <Avatar
            size="lg"
            src={props?.values?.contact?.image}
            label={props?.values?.owner || props?.values?.contact?.name}
          />
          <div>
            <Link
              className="underline"
              to={`/contacts/${props?.values?.contactId}/details`}
            >
              {props?.values?.owner}
            </Link>
          </div>
        </div>
      ) : (
        <span title={props?.values?.owner}>{props?.values?.owner}</span>
      )}
    </>
  );
};

const InstanceProvenance = props => {
  const { instanceId } = props || {};

  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "instanceProvenances",
    headers: [
      {
        label: "Date",
        type: "custom",
        component: ({ values: { day, month, year } }) => (
          <span className="flex gap-1">
            <span className="overflow-hidden text-ellipsis text-base font-medium leading-5 text-black">
              {day || month || year
                ? `${day || "-"}/${month || "-"}/${year || "-"}`
                : ""}
            </span>
          </span>
        ),
      },
      { label: "Owner", name: "owner", type: "custom", component: clientName },
      { label: "Credit", name: "credit", type: "label" },
      { label: "Notes", name: "notes", type: "label" },
      {
        label: "",
        name: "actions",
        type: "custom",
        component: Actions,
        instanceId,
      },
    ],
    inputs: [],
    FETCH_QUERY: fetchInstanceProvenance(),
    variables: {
      instanceId,
    },
    className: "sm:px-0 lg:px-0",
  };

  const modalProps = {
    body: AddProvenance,
    instanceId: +instanceId,
    hideCloseButton: true,
    scale: "md",
  };

  const provenanceActions = [
    {
      type: "black",
      label: "ADD RECORD",
      action: () => null,
      component: () => (
        <Modal {...modalProps}>
          <Button label="Add Provenance" action="dark" />
        </Modal>
      ),
    },
  ];

  return (
    <div className="w-full">
      <SectionHeader title="Provenance" actions={provenanceActions} />
      <div className="w-full">
        <Table {...tableProps} />
      </div>
    </div>
  );
};

export default InstanceProvenance;
