import { gql } from "@apollo/client";
import { ContactFocus as contactFocus } from "../fragments";

const CREATE_CONTACT_FOCUS = props => gql`
  mutation CreateContactFocus($input: CreateContactFocusInput!) {
    createContactFocus(input: $input) {
      success
      error
      contactFocus {
        ${contactFocus(props)}
      }
    }
  }
`;

const DELETE_CONTACT_FOCUS = gql`
  mutation DeleteContactFocus($input: DeleteContactFocusInput!) {
    deleteContactFocus(input: $input) {
      success
      error
    }
  }
`;

export { CREATE_CONTACT_FOCUS, DELETE_CONTACT_FOCUS };
