import { classNames } from "../../../util";

export const Tabs = props => {
  const { tabs, active, onChange } = props || [];
  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          defaultValue={tabs.find(tab => tab.name == active)?.label}
        >
          {tabs?.map(tab => (
            <option key={tab.label} onClick={() => onChange(tab?.name)}>
              {tab.label}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="flex space-x-4" aria-label="Tabs">
          {tabs?.map(tab => (
            <a
              key={tab.label}
              onClick={() => onChange(tab?.name)}
              className={classNames(
                tab.name == active
                  ? "text-black underline underline-offset-1"
                  : "text-gray-400",
                "cursor-pointer rounded-md px-3 py-2 text-sm font-bold",
              )}
              aria-current={active ? "page" : undefined}
            >
              {tab.label}
            </a>
          ))}
        </nav>
      </div>
    </div>
  );
};
