import { useMutation } from "@apollo/client";
import { Button } from "../../../../components/ui";
import { UPDATE_INVOICE as updateInvoiceMutation } from "../../../../graphql/mutation/Invoice";

const ChangeToInvoice = props => {
  const { invoice, closeModal } = props || {};
  const [updateInvoice, { loading }] = useMutation(updateInvoiceMutation());

  const onSubmit = () => {
    updateInvoice({
      variables: {
        input: {
          id: invoice?.id,
          typeId: 1,
        },
      },
    }).then(resp => {
      const {
        data: { updateInvoice: { success } = {} },
      } = resp || {};
      if (success) {
        closeModal();
        window.location.reload();
      }
    });
  };

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          <div>Change type to Invoice?</div>
        </div>
        <div className="flex gap-4">
          <Button action="default" label="Cancel" onClick={closeModal} />
          <Button
            label={"Update"}
            loading={loading}
            disabled={loading}
            onClick={onSubmit}
          />
        </div>
      </div>
      <div className="flex">
        Are you sure you want to change this proforma to an invoice?
      </div>
    </div>
  );
};

export default ChangeToInvoice;
