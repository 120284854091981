import * as React from "react";
import SectionHeader from "../../components/SectionHeader";
import { GridInputs } from "../../../../../../components/Functional";
import useUser from "../../../../../../hooks/useUser";

const ConsignmentTerms = props => {
  const { formik } = props || {};
  const { hasPermission } = useUser();

  const inputs = {
    label: "",
    name: "terms",
    rows: 10,
    type: "textarea",
    submitOnBlur: true,
    disabled: !hasPermission("UPDATE_CONSIGNMENT"),
  };

  const inputProps = {
    formik,
    ...inputs,
  };

  return (
    <div className="w-full">
      <SectionHeader title="Terms" />
      <div className="w-full px-16 py-8">
        <GridInputs {...inputProps} />
      </div>
    </div>
  );
};

export default ConsignmentTerms;
