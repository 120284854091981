/* eslint-disable new-cap */
import { gql } from "@apollo/client";
import { List as list } from "../fragments";

const CREATE_LIST = props => gql`
  mutation CreateList($input: CreateListInput!) {
    createList(input: $input) {
      error
      list {
        ${list(props)}
      }
      success
    }
  }
`;

const DELETE_LIST = gql`
  mutation DeleteList($input: DeleteListInput!) {
    deleteList(input: $input) {
      error
      success
    }
  }
`;

const UPDATE_LIST = props => gql`
  mutation UpdateList($input: UpdateListInput!) {
    updateList(input: $input) {
      error
      list {
        ${list(props)}
      }
      success
    }
  }
`;

export { CREATE_LIST, DELETE_LIST, UPDATE_LIST };
