/* eslint-disable new-cap */
import { gql } from "@apollo/client";
import { Document as document } from "../fragments";

const FETCH_DOCUMENTS = props => gql`
  query FetchDocuments($input: DocumentFilterInput!) {
    documents(input: $input) {
      count
      edges {
        ${document(props)}
      }
    }
  }
`;

const FETCH_DOCUMENT = props => gql`
  query FetchDocument($id: Int) {
    document(id: $id) {
      ${document(props)}
    }
  }
`;

export { FETCH_DOCUMENT, FETCH_DOCUMENTS };
