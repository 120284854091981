import { useQuery, useMutation } from "@apollo/client";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import * as Yup from "yup";

import { FETCH_CONTACT_ADDRESS_TYPE } from "../../../../../../../../../graphql/query/ContactAddressType";
import { FETCH_COUNTRIES } from "../../../../../../../../../graphql/query/Country";
import { FETCH_COUNTIES } from "../../../../../../../../../graphql/query/County";
import {
  CREATE_CONTACT_ADDRESS as createContactAddressMutation,
  UPDATE_CONTACT_ADDRESS as updateContactAddressMutation,
} from "../../../../../../../../../graphql/mutation/ContactAddress";
import { Button, getInput } from "../../../../../../../../../components/ui";

const AddressesComponent = props => {
  const { closeModal, contact, contactAddress = {}, isOpen } = props;

  const [createContactAddress, { loading: loadingCreate }] = useMutation(
    createContactAddressMutation(),
    { refetchQueries: ["FetchContact"] },
  );
  const [updateContactAddress, { loading: loadingUpdate }] = useMutation(
    updateContactAddressMutation(),
    { refetchQueries: ["FetchContact"] },
  );

  const { data: { contactAddressTypes = [] } = {} } = useQuery(
    FETCH_CONTACT_ADDRESS_TYPE,
  );
  const { data: { countries = [] } = {} } = useQuery(FETCH_COUNTRIES);
  const { data: { counties = [] } = {} } = useQuery(FETCH_COUNTIES);

  const confirmAction = () => {
    const { values } = formik;

    delete values.__typename;

    const variables = {
      input: {
        ...values,
        contactId: contact?.id,
        id: contactAddress?.id,
      },
    };
    const action = contactAddress?.id
      ? updateContactAddress
      : createContactAddress;

    action({ variables }).then(() => {
      closeModal();
    });
  };

  const inputArr = [
    {
      fields: [
        {
          label: "Street Address 1",
          name: "address",
          placeholder: "Enter street address",
          type: "text",
        },
        {
          label: "Street Address 2",
          name: "address2",
          placeholder: "Enter street address 2",
          type: "text",
        },
        {
          label: "Street Address 3",
          name: "address3",
          placeholder: "Enter street address 3",
          type: "text",
        },
        {
          label: "Town / City",
          name: "town",
          placeholder: "Enter town",
          type: "text",
        },
        {
          label: "State / County",
          name: "county",
          options: counties?.map(item => ({
            label: item?.description,
            value: item?.description,
          })),
          placeholder: "Select a county",
          type: "multi-select",
        },
        {
          label: "Post Code",
          name: "postcode",
          placeholder: "Enter postcode",
          type: "text",
        },
        {
          label: "Country",
          name: "countryId",
          options: [
            ...countries.map(item => ({ label: item?.name, value: item?.id })),
          ],
          placeholder: "Select a country",
          type: "multi-select",
        },
      ],
    },
    {
      fields: [
        {
          label: "Type",
          name: "typeId",
          options: contactAddressTypes?.map(item => ({
            label: item?.description,
            value: item?.id,
          })),
          placeholder: "Select a type",
          type: "multi-select",
        },
        {
          name: "expired",
          options: [{ label: "Defunct", value: -1 }],
          singleCheckbox: true,
          type: "checkbox",
        },
        {
          name: "do_not_call",
          options: [{ label: "Do Not Contact", value: -1 }],
          singleCheckbox: true,
          type: "checkbox",
        },
      ],
    },
    {
      label: "Classification",
      fields: [
        {
          name: "use_default",
          options: [{ label: "Default", value: -1 }],
          singleCheckbox: true,
          type: "checkbox",
        },
        {
          name: "use_event",
          options: [{ label: "Events", value: -1 }],
          singleCheckbox: true,
          type: "checkbox",
        },
        {
          name: "use_billing",
          options: [{ label: "Billing", value: -1 }],
          singleCheckbox: true,
          type: "checkbox",
        },
        {
          name: "use_delivery",
          options: [{ label: "Delivery", value: -1 }],
          singleCheckbox: true,
          type: "checkbox",
        },
        {
          name: "use_statement",
          options: [{ label: "Statement", value: -1 }],
          singleCheckbox: true,
          type: "checkbox",
        },
        {
          name: "use_vat",
          options: [{ label: "VAT", value: -1 }],
          singleCheckbox: true,
          type: "checkbox",
        },
        {
          name: "use_catalogue",
          options: [{ label: "Catalogues", value: -1 }],
          singleCheckbox: true,
          type: "checkbox",
        },
      ],
    },
  ];

  const initialValues = {};

  inputArr?.forEach(inputs => {
    inputs?.fields?.forEach(field => {
      if (contactAddress[field?.name]) {
        initialValues[field.name] = contactAddress[field.name];
      }
    });
  });

  const formik = useFormik({
    initialValues: { ...initialValues } || {},
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: Yup.object({
      address: Yup.string().trim().required("Please enter an address."),
      typeId: Yup.string().trim().required("Please select a type."),
      countryId: Yup.string().trim().required("Please select a country."),
    }),
    onSubmit: confirmAction,
  });

  useEffect(() => {
    formik.resetForm();
  }, [isOpen]);

  return (
    <div className="flex w-full flex-col p-8">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          {`${formik.values?.id ? "Edit" : "Add"}`} Address for {contact?.name}
        </div>
        <div className="flex flex-col">
          <div>
            <Button
              action="default"
              className="mr-8"
              label="Cancel"
              onClick={() => {
                closeModal();
              }}
            />
            <Button
              label={loadingUpdate || loadingCreate ? "Confirming" : "Confirm"}
              disabled={loadingUpdate || loadingCreate}
              onClick={formik.submitForm}
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-x-20 pb-10 pt-20">
        {inputArr?.map((inputs, index) => {
          return (
            <div key={index} className="flex flex-col">
              {inputs?.label && (
                <div className="mb-8 text-xl font-bold">{inputs?.label}</div>
              )}
              <div className="flex flex-col gap-y-4">
                {inputs?.fields?.map((item, index) => {
                  const itemProps = {
                    ...item,
                    formik,
                  };
                  return (
                    <React.Fragment key={index}>
                      {getInput(itemProps)}
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AddressesComponent;
