import { ArrowDownTrayIcon, EyeIcon } from "@heroicons/react/24/outline";
import { IconButton } from "../../../../../../../components/ui";

const Actions = props => {
  const { id, values } = props || {};

  if (typeof id !== "number") {
    return (
      <div className="user-list-actions flex text-right text-sm font-medium">
        {id && (
          <div className="grid grid-cols-4 gap-4">
            <IconButton variant="clean" title="View Document">
              <EyeIcon
                className={`h-4 w-4 cursor-pointer text-gray-400`}
                onClick={() => {
                  window.open(values?.viewUrl, "_blank");
                }}
              />
            </IconButton>
            <IconButton variant="clean" title="Download Document">
              <ArrowDownTrayIcon
                className={`h-4 w-4 cursor-pointer text-gray-400`}
                onClick={() => {
                  window.open(values?.url, "_blank");
                }}
              />
            </IconButton>
          </div>
        )}
      </div>
    );
  } else {
    return false;
  }
};

export default Actions;
