import { gql } from "@apollo/client";
import { JobItem as jobItem } from "../fragments";

const CREATE_JOB_ITEM = props => gql`
  mutation CreateJobItem($input: CreateJobItemInput!) {
    createJobItem(input: $input) {
      error
      jobItem {
        ${jobItem(props)}
      }
      success
    }
  }
`;

const DELETE_JOB_ITEM = gql`
  mutation DeleteJobItem($input: DeleteJobItemInput!) {
    deleteJobItem(input: $input) {
      error
      success
    }
  }
`;

export { CREATE_JOB_ITEM, DELETE_JOB_ITEM };
