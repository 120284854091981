import { useMutation } from "@apollo/client";

import { EXPORT_DOCUMENT } from "../../graphql/mutation/Document";
import { Button, Modal } from "../ui";

const ExportPDFModal = props => {
  const { children, id } = props || {};
  const [exportDocument, { loading, data }] = useMutation(EXPORT_DOCUMENT);
  const { exportDocument: { url } = {} } = data || {};

  const handleExport = () => {
    exportDocument({
      variables: {
        input: { id: +id },
      },
    });
  };

  const modalProps = {
    title: "Exporting PDF",
    scale: "sm",
    description: "This might take a while, please wait...",
    closeOnBackdrop: false,
    body: ({ closeModal }) => {
      return (
        <div className="mt-4 flex">
          <Button
            label={loading ? "Processing" : "Download"}
            disabled={loading}
            loading={loading}
            onClick={() => {
              window.open(url, "_blank");
              closeModal?.();
            }}
          />
        </div>
      );
    },
  };

  return (
    <Modal {...modalProps}>
      <div onClick={handleExport}>{children}</div>
    </Modal>
  );
};

export default ExportPDFModal;
