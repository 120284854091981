/* eslint-disable new-cap */
import { gql } from "@apollo/client";
import { Role } from "../fragments";

const DELETE_ROLE = gql`
  mutation DeleteRole($input: DeleteRoleInput!) {
    deleteRole(input: $input) {
      success
      error
    }
  }
`;

const CREATE_ROLE = gql`
  mutation CreateRole($input: CreateRoleInput!) {
    createRole(input: $input) {
      success
      error
      role {
        ${Role}
      }
    }
  }
`;

const UPDATE_ROLE = gql`
  mutation UpdateRole($input: UpdateRoleInput!) {
    updateRole(input: $input) {
      success
      error
      role {
        ${Role}
      }
    }
  }
`;

export { CREATE_ROLE, DELETE_ROLE, UPDATE_ROLE };
