import { useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";

import { Button, getInput, Spinner } from "../../../../components/ui";
import { CREATE_ROLE, UPDATE_ROLE } from "../../../../graphql/mutation/Role";
import {
  FETCH_DEFAULT_PERMISSION_SET,
  FETCH_ROLE,
} from "../../../../graphql/query/Role";
import { getChangedFieldsV2 } from "../../../../util";

import PermissionsView from "../../components/PermissionsView";
import Header from "../../components/HeaderComponent";

const RightComponent = props => {
  const { id, values, dirty } = props || {};
  const navigate = useNavigate();
  const [createRole, { client, loading: createLoading }] =
    useMutation(CREATE_ROLE);
  const [updateRole, { loading: updateLoading }] = useMutation(UPDATE_ROLE);

  const handleClick = () => {
    id
      ? updateRole({ variables: { input: { id: +id, ...values } } }).then(
          resp => {
            const {
              data: { updateRole: { success } = {} },
            } = resp || {};
            success && navigate(`/admin/role`);
          },
        )
      : createRole({ variables: { input: { ...values } } }).then(resp => {
          const {
            data: { createRole: { success } = {} },
          } = resp || {};
          if (success) {
            // TODO: handle this through updating cache to improve performance
            client.resetStore();
            navigate(`/admin/role`);
          }
        });
  };

  return (
    <div>
      <Button
        label={"Back"}
        action="default"
        className="mr-4"
        onClick={() => navigate("/admin/role")}
      />
      <Button
        label={"Save"}
        disabled={createLoading || updateLoading || !dirty}
        action={"dark"}
        className="mr-4"
        onClick={handleClick}
      />
    </div>
  );
};

const EditRole = props => {
  const { id } = useParams();
  const { data: { role } = {}, loading } = useQuery(FETCH_ROLE, {
    skip: !id,
    variables: { id: +id },
  });
  const { data: { defaultPermissionSet: defaultPermissionsData } = {} } =
    useQuery(FETCH_DEFAULT_PERMISSION_SET);
  const {
    permissions: defaultPermissions,
    permissionSet: defaultPermissionSet,
  } = defaultPermissionsData || {};
  const { permissions: rolePermissions, permissionSet: rolePermissionSet } =
    role || {};
  const permissionSet = rolePermissionSet || defaultPermissionSet || {};

  const initialValues = rolePermissions || defaultPermissions || {};

  const inputs = [{ label: "Role Name", name: "name", type: "text" }];

  const formik = useFormik({
    initialValues: { name: role?.name || "", permissions: initialValues },
    enableReinitialize: true,
  });

  const onChange = ({ permissions }) => {
    formik?.setValues({ ...formik?.values, permissions });
  };

  const renderInputs = inputs?.map((item, index) => {
    const inputProps = {
      ...item,
      formik,
    };

    return <div key={index}>{getInput(inputProps)}</div>;
  });

  const permissionViewProps = {
    onChange,
    permissionSet,
    initialValues,
  };

  const rightComponentProps = {
    id,
    dirty:
      Object.keys(
        getChangedFieldsV2(formik?.values, {
          name: role?.name || "",
          permissions: initialValues,
        }),
      )?.length > 0,
    values: formik?.values,
  };

  return (
    <>
      {loading ? (
        <div className="absolute inset-0 flex items-center justify-center">
          <Spinner className="text-black" />
        </div>
      ) : (
        <>
          <Header
            leftText={id ? `Edit Role '${role?.name}'` : "Add a Role"}
            rightText="PDF Generator"
            rightComponent={() => <RightComponent {...rightComponentProps} />}
          />
          <div className="mb-9">{!id && "Create a New Role"}</div>
          <div className="relative mt-5 flex grid grid-cols-2 flex-row items-center gap-1">
            <div className="col-span-1">
              {renderInputs}
              <hr className="mb-8 mt-8" />
              <h1 className="mb-5">Assign Permissions to this Role</h1>
              <PermissionsView {...permissionViewProps} />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default EditRole;
