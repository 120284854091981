import React from "react";
import { Link } from "react-router-dom";

import { Table } from "../../../../../../components/Functional";
import { FETCH_CONDITIONS_V2 as fetchConditionsV2 } from "../../../../../../graphql/query/Condition";
import SectionHeader from "../../components/SectionHeader";
import ConditionStatus from "./component/ConditionStatus";

const ShipmentCondition = props => {
  const { shipmentId } = props || {};

  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "conditionsV2",
    headers: [
      { label: "Condition #", name: "id", type: "label" },
      { label: "Date", name: "date", type: "date" },
      { label: "User", name: "user", type: "label" },
      {
        label: "Ref",
        name: "instance.name",
        type: "custom",
        component: ({ values }) => (
          <Link
            className="underline"
            to={`/instance/${values?.instance?.id}/details`}
          >
            {values?.instance?.name}
          </Link>
        ),
      },
      { label: "Title", name: "instance.edition.artwork.title", type: "label" },
      {
        label: "Status",
        name: "status.description",
        type: "custom",
        component: ConditionStatus,
      },
      { label: "Description", name: "description", type: "label" },
    ],
    inputs: [],
    FETCH_QUERY: fetchConditionsV2({
      instance: { edition: { artwork: {} } },
      status: true,
    }),
    variables: {
      _instance: {
        _warehouseItem: {
          _warehouseMove: {
            _shipment: {
              id: {
                operator: "eq",
                value: shipmentId,
              },
            },
          },
        },
      },
    },
    className: "sm:px-0 lg:px-0",
  };

  return (
    <div className="w-full">
      <SectionHeader title="Condition" />
      <div className="flex w-full">
        <div className="flex-1">
          <Table {...tableProps} />
        </div>
      </div>
    </div>
  );
};

export default ShipmentCondition;
