import { gql } from "@apollo/client";
import { ArtworkClass } from "../fragments";

const FETCH_ARTWORK_CLASSES = gql`
  query FetchArtworkClasses {
    artworkClasses {
      ${ArtworkClass}
    }
  }
`;

export { FETCH_ARTWORK_CLASSES };
