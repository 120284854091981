import React from "react";
import { useQuery } from "@apollo/client";
import SectionHeader from "../../components/SectionHeader";
import { Table } from "../../../../../../components/Functional";
import { FETCH_PAYMENT_PLANS_V2 as fetchPaymentPlansV2 } from "../../../../../../graphql/query/PaymentPlan";
import { Button, Modal } from "../../../../../../components/ui";
import AddPaymentPlan from "./components/AddPaymentPlan";
import { UPDATE_PAYMENT_PLAN as updatePaymentPlanMutation } from "../../../../../../graphql/mutation/PaymentPlan";
import Actions from "./components/Actions";
import { FETCH_INVOICE } from "../../../../../../graphql/query/Invoice";
import useUser from "../../../../../../hooks/useUser";

const TotalAmount = props => {
  const { totalAmount = 0.0 } = props || {};
  return (
    <div className="border-b pb-4 pl-[205px] pt-4 font-bold">
      Total:{" "}
      {Number(totalAmount).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}
    </div>
  );
};

const PaymentPlan = props => {
  const { invoiceId } = props;
  const { hasPermission } = useUser();

  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "paymentPlansV2",
    headers: [
      {
        label: "Due Date",
        name: "dueDate",
        type: "input",
        inputType: "date",
        disabled: !hasPermission("UPDATE_PAYMENT_PLAN"),
      },
      {
        label: "Amount",
        name: "amount",
        type: "input",
        inputType: "number-currency",
        disabled: !hasPermission("UPDATE_PAYMENT_PLAN"),
      },
      {
        label: "Cancel",
        name: "cancel",
        type: "input",
        inputType: "checkbox",
        singleCheckbox: true,
        options: [{ label: "Cancel", value: -1 }],
        disabled: !hasPermission("UPDATE_PAYMENT_PLAN"),
      },
      {
        label: "Description",
        name: "description",
        type: "input",
        inputType: "text",
        disabled: !hasPermission("UPDATE_PAYMENT_PLAN"),
      },
      { label: "User", name: "createdBy.name", type: "label" },
      { label: "Date Modified", name: "updatedAt", type: "date" },
      { label: "", name: "action", type: "custom", component: Actions },
    ],
    inputs: [],
    footerComponent: TotalAmount,
    UPDATE_MUTATION: updatePaymentPlanMutation(),
    FETCH_QUERY: fetchPaymentPlansV2({ createdBy: {} }),
    variables: {
      _invoice: {
        id: {
          operator: "eq",
          value: +invoiceId,
        },
      },
    },
    className: "sm:px-0 lg:px-0",
  };

  const { data: { invoice } = {} } = useQuery(
    FETCH_INVOICE({
      bank: {},
      contact: {},
      company: { images: {} },
      country: {},
      currency: {},
      invoiceSalesPersons: { salesUser: {} },
      invoiceTotals: {},
      items: {
        instance: { edition: { artwork: { imagesSummary: { imgT: {} } } } },
      },
      type: {},
    }),
    { variables: { id: +invoiceId } },
  );

  const modalProps = {
    body: AddPaymentPlan,
    closeOnBackdrop: true,
    invoice,
    invoiceId,
    hideCloseButton: true,
    scale: "md",
    disabled: !hasPermission("UPDATE_PAYMENT_PLAN"),
  };

  const actions = [
    {
      component: () => {
        return (
          <Modal {...modalProps}>
            <Button
              label="Add Payment Plan"
              action="black"
              disabled={!hasPermission("UPDATE_PAYMENT_PLAN")}
            />
          </Modal>
        );
      },
    },
  ];

  return (
    <div className="w-full">
      <SectionHeader title="Payment Plan" actions={actions} />
      <div className="flex w-full">
        <div className="flex-1">
          <Table {...tableProps} />
        </div>
      </div>
    </div>
  );
};

export default PaymentPlan;
