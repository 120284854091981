import { useQuery } from "@apollo/client";
import { GridInputs } from "../../../../../components/Functional";
import { FETCH_CRM_CATEGORIES } from "../../../../../graphql/query/CRMCategory";
import { FETCH_CRM_TYPES } from "../../../../../graphql/query/CRMType";
import { FETCH_ENTITIES } from "../../../../../graphql/query/Entity";
import { FETCH_JOB_STATUSES } from "../../../../../graphql/query/JobStatus";

const Actions = props => {
  const { formik } = props || {};

  const { data: { crmCategories = [] } = {} } = useQuery(FETCH_CRM_CATEGORIES);
  const { data: { crmTypes = [] } = {} } = useQuery(FETCH_CRM_TYPES);
  const { data: { entities: { edges = [] } = {} } = {} } =
    useQuery(FETCH_ENTITIES);
  const { data: { jobStatuses = [] } = {} } = useQuery(FETCH_JOB_STATUSES);

  const inputs = {
    className: "grid grid-rows-2 gap-4",
    inputs: [
      {
        className: "grid grid-cols-4 gap-4",
        inputs: [
          {
            label: "Category",
            name: "_CRMRegistrar.categoryId",
            options: crmCategories?.map(type => ({
              label: type?.description,
              value: type?.id,
            })),
            type: "multi-select",
          },
          {
            label: "Date Start",
            name: "_CRMRegistrar.date.value.from",
            type: "date",
          },
          {
            label: "Date End",
            name: "_CRMRegistrar.date.value.to",
            type: "date",
          },
          {
            label: "Type",
            name: "_CRMRegistrar.typeId",
            options: crmTypes?.map(type => ({
              label: type?.description,
              value: type?.id,
            })),
            type: "multi-select",
          },
        ],
      },
      {
        className: "grid grid-cols-4 gap-4",
        inputs: [
          {
            label: "Action By",
            name: "_CRMRegistrar.userId",
            options: edges?.map(item => ({
              label: item?.name,
              value: item?.id,
            })),
            type: "multi-select",
          },
          {
            label: "Status",
            name: "_CRMRegistrar.statusId",
            options: jobStatuses?.map(type => ({
              label: type?.description,
              value: type?.id,
            })),
            type: "multi-select",
          },
          {
            label: "Description",
            name: "_CRMRegistrar.description",
            type: "text",
          },
          {
            label: "Setup By",
            name: "_CRMRegistrar.entityId",
            options: edges?.map(item => ({
              label: item?.name,
              value: item?.id,
            })),
            type: "multi-select",
          },
        ],
      },
    ],
  };

  const gridInputProps = {
    formik,
    ...inputs,
  };

  return <GridInputs {...gridInputProps} />;
};

export default Actions;
