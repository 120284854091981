import { Link } from "react-router-dom";
import SectionHeader from "../../components/SectionHeader";
import { Table } from "../../../../../../components/Functional";
import { FETCH_CONDITIONS_V2 as fetchConditionsV2 } from "../../../../../../graphql/query/Condition";

const Condition = ({ jobId }) => {
  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "conditionsV2",
    headers: [
      {
        label: "Instance",
        name: "instance.name",
        type: "custom",
        component: ({ values }) => (
          <Link
            className="underline"
            to={`/instance/${values?.instance?.id}/details`}
          >
            {values?.instance?.name}
          </Link>
        ),
      },
      { label: "Report #", name: "id", type: "label" },
      { label: "Date", name: "time", type: "date-time" },
      { label: "Status", name: "status.description", type: "label" },
      { label: "User", name: "user", type: "label" },
      { label: "Description", name: "description", type: "label" },
    ],
    inputs: [],
    FETCH_QUERY: fetchConditionsV2({ instance: {}, status: true }),
    variables: {
      jobId: {
        operator: "eq",
        value: jobId,
      },
    },
  };

  return (
    <div className="w-full">
      <SectionHeader title="Condition" />
      <div className="mb-6 w-full">
        <Table {...tableProps} />
      </div>
    </div>
  );
};

export default Condition;
