import { Button, getInput } from "../../../../../../../components/ui";
import { useFormik } from "formik";
import { useMutation, useQuery } from "@apollo/client";
import * as Yup from "yup";
import { useEffect } from "react";
import { FETCH_FABRICATION_STAGES } from "../../../../../../../graphql/query/FabricationStage";
import { FETCH_FABRICATION_TYPES } from "../../../../../../../graphql/query/FabricationType";
import { FETCH_CURRENCIES } from "../../../../../../../graphql/query/Currency";
import {
  CREATE_FABRICATION as createFabricationMutation,
  UPDATE_FABRICATION as updateFabricationMutation,
} from "../../../../../../../graphql/mutation/Fabrication";
import { FETCH_FABRICATION_CATEGORIES } from "../../../../../../../graphql/query/FabricationCategory";

const AddEditCost = props => {
  const { fabrication, closeModal, isOpen, consignment } = props;

  const { data: { fabricationStages = [] } = {} } = useQuery(
    FETCH_FABRICATION_STAGES,
  );
  const { data: { fabricationTypes = [] } = {} } = useQuery(
    FETCH_FABRICATION_TYPES,
    {
      variables: {
        input: {},
      },
    },
  );
  const { data: { currencies = [] } = {} } = useQuery(FETCH_CURRENCIES);
  const { data: { fabricationCategories = [] } = {} } = useQuery(
    FETCH_FABRICATION_CATEGORIES,
  );

  const [updateFabrication, { loading: updateLoading }] = useMutation(
    updateFabricationMutation(),
    { refetchQueries: ["FetchFabricationsV2"] },
  );
  const [createFabrication, { loading: createLoading }] = useMutation(
    createFabricationMutation(),
    { refetchQueries: ["FetchFabricationsV2"] },
  );

  const loading = updateLoading || createLoading;

  const handleClick = () => {
    const {
      id,
      date,
      stageId,
      typeId,
      jobId,
      categoryId,
      description,
      po,
      amount,
      currencyId,
      invoice,
      recharge,
      notes,
    } = formik.values;
    (fabrication
      ? updateFabrication({
          variables: {
            input: {
              id,
              date,
              stageId,
              typeId,
              jobId,
              categoryId,
              description,
              po,
              amount,
              currencyId,
              invoice,
              recharge,
              notes,
            },
          },
        })
      : createFabrication({
          variables: {
            input: {
              consignmentId: consignment?.id,
              date,
              stageId,
              typeId,
              jobId,
              categoryId,
              description,
              po,
              amount,
              currencyId,
              invoice,
              recharge,
              notes,
            },
          },
        })
    ).then(resp => {
      const {
        data: {
          createFabrication: { success } = {},
          updateFabrication: { success: updateSuccess } = {},
        },
      } = resp || {};
      if (success || updateSuccess) {
        closeModal();
      }
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...fabrication,
    },
    validationSchema: Yup.object({
      date: Yup.date().required("Please select a date."),
      typeId: Yup.number()
        .required("Please select a type.")
        .typeError("Required"),
      description: Yup.string().trim().required("Please enter a description."),
      currencyId: Yup.number()
        .required("Please select a currency.")
        .typeError("Required"),
      jobId: Yup.number()
        .integer("The job # must be an integer.")
        .min(0, "The job # cannot be negative."),
      recharge: Yup.number()
        .min(0, "The recharge cannot be negative.")
        .typeError("Invalid input"),
      amount: Yup.number()
        .min(1, "The amount must be greater than 0")
        .required("Please enter an amount.")
        .typeError("Invalid input"),
    }),
    onSubmit: handleClick,
  });

  const rightInputfields = [
    { label: "Date", name: "date", type: "date" },
    {
      label: "Stage",
      name: "stageId",
      type: "multi-select",
      options:
        fabricationStages?.map(item => ({
          label: item?.name,
          value: item?.id,
        })) || [],
    },
    {
      label: "Type",
      name: "categoryId",
      type: "multi-select",
      options:
        fabricationCategories?.map(item => ({
          label: item?.name,
          value: item?.id,
        })) || [],
    },
    { label: "Job #", name: "jobId", type: "number" },
    {
      label: "Service",
      name: "typeId",
      type: "multi-select",
      options:
        fabricationTypes?.map(item => ({
          label: item?.description,
          value: item?.id,
        })) || [],
    },
    { label: "Description", name: "description", type: "textarea" },
  ];

  const leftInputFields = [
    { label: "PO #", name: "po", type: "text" },
    { label: "Net Amount", name: "amount", type: "number-currency" },
    {
      label: "Currency",
      name: "currencyId",
      type: "multi-select",
      options:
        currencies?.map(item => ({
          label: item?.description,
          value: item?.id,
        })) || [],
    },
    { label: "Invoice #", name: "invoice", type: "text" },
    { label: "Recharge", name: "recharge", type: "number-currency" },
    { label: "Notes", name: "notes", type: "textarea" },
  ];

  useEffect(() => {
    formik?.resetForm();
  }, [isOpen]);

  return (
    <div className="flex w-full flex-col p-8">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          {fabrication ? "Update" : "Create"} Cost
        </div>
        <div className="flex flex-row">
          <Button
            action="default"
            className="mr-8"
            label="Cancel"
            onClick={() => {
              closeModal();
            }}
          />
          <Button
            label={
              loading
                ? fabrication
                  ? "Updating"
                  : "Creating"
                : fabrication
                  ? "Update Record"
                  : "Create Record"
            }
            disabled={loading}
            onClick={formik?.submitForm}
          />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-x-10 pb-10 pt-20">
        <div className="grid grid-cols-1 gap-y-4">
          {rightInputfields?.map((item, index) => {
            const inputProps = {
              ...item,
              formik,
            };
            return (
              <div key={index} className={`w-full ${item?.className}`}>
                {getInput(inputProps)}
              </div>
            );
          })}
        </div>
        <div className="grid w-full grid-cols-1 grid-rows-5 gap-x-20 gap-y-4">
          {leftInputFields?.map((item, index) => {
            const inputProps = {
              ...item,
              formik,
            };
            return (
              <div key={index} className={`w-full ${item?.className}`}>
                {getInput(inputProps)}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AddEditCost;
