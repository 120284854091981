import { gql } from "@apollo/client";
import { ArtworkCategory } from "../fragments";

const FETCH_ARTWORK_CATEGORIES = gql`
  query FetchArtworkCategories {
    artworkCategories {
      ${ArtworkCategory}
  }
}
`;

export { FETCH_ARTWORK_CATEGORIES };
