import { gql } from "@apollo/client";
import { InstanceAuction } from "../fragments";

const CREATE_INSTANCE_AUCTION = gql`
  mutation CreateInstanceAuction($input: CreateInstanceAuctionInput!) {
    createInstanceAuction(input: $input) {
      success
      error
      instanceAuction {
        ${InstanceAuction}
      }
    }
  }
`;

const DELETE_INSTANCE_AUCTION = gql`
  mutation DeleteInstanceAuction($input: DeleteInstanceAuctionInput!) {
    deleteInstanceAuction(input: $input) {
      success
      error
    }
  }
`;

const UPDATE_INSTANCE_AUCTION = gql`
  mutation UpdateInstanceAuction($input: UpdateInstanceAuctionInput!) {
    updateInstanceAuction(input: $input) {
      success
      error
      instanceAuction {
        ${InstanceAuction}
      }
    }
  }
`;

export {
  CREATE_INSTANCE_AUCTION,
  DELETE_INSTANCE_AUCTION,
  UPDATE_INSTANCE_AUCTION,
};
