import { useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { Tag } from "../../../../../../components/ui";

const DetailBar = props => {
  const { contact } = props;
  const [open, setOpen] = useState(false);

  const getContactNumberMobile = () => {
    const result = contact?.contactNumbers?.filter(
      item => (item.typeId === 7 || item.typeId === 8) && item.prefere === -1,
    );
    return result?.at(-1)?.number || "None";
  };

  const getContactEmail = () => {
    const result = contact?.contactNumbers
      ?.filter(item => item.typeId === 1 && item.prefere === -1)
      ?.at(-1);

    return contact?.email ? (
      <div className="flex flex-col">
        <div>{contact?.email}</div>
        {contact?.unsubscribed ? (
          <div>
            <Tag action="danger" label="unsubscribed" />
          </div>
        ) : null}
      </div>
    ) : (
      result?.number || "None"
    );
  };

  const defaultAddress = contact?.contactAddresses.filter(
    addr => addr.use_default === -1,
  );

  const addressDetailView = {
    contactAddress:
      defaultAddress && defaultAddress.length > 0
        ? {
            address: defaultAddress[0]?.address,
            address2: defaultAddress[0]?.address2,
            address3: defaultAddress[0]?.address3,
            country: defaultAddress[0]?.country,
            county: defaultAddress[0]?.county,
            postcode: defaultAddress[0]?.postcode,
            town: defaultAddress[0]?.town,
          }
        : {
            address: contact?.address,
            address2: contact?.address2,
            address3: contact?.address3,
            country: contact?.country,
            county: contact?.county,
            postcode: contact?.postcode,
            town: contact?.town,
          },
  };

  const AddressDetailView = props => {
    const { contactAddress } = props || {};

    return (
      <div className="group relative">
        <div className="flex flex-col">
          <div>{contactAddress?.address ?? ""}</div>
          <div>{contactAddress?.address2 ?? ""}</div>
          <div>{contactAddress?.address3 ?? ""}</div>
          <div>{`${contactAddress?.town ?? ""}${contactAddress?.town ? ", " : ""} ${contactAddress?.county ?? ""}`}</div>
          <div>{`${contactAddress?.postcode ?? ""}${contactAddress?.postcode ? ", " : ""} ${contactAddress?.country?.name ?? ""}`}</div>
        </div>
      </div>
    );
  };

  const contactMethods = [
    { label: "Mobile", value: getContactNumberMobile() },
    { label: "Email", value: getContactEmail() },
    { label: "Title", value: contact?.title || "None" },
    {
      label: "Primary Address",
      value: <AddressDetailView {...addressDetailView} />,
    },
  ];

  const internalReferences = [
    { label: "Types", value: contact?.type?.name || "None" },
    {
      label: "Primary Sales Contact",
      value: contact?.salesUser?.name || "None",
    },
    {
      label: "Secondary Sales Contact",
      value: contact?.salesUser2?.name || "None",
    },
    { label: "Exhibition Liaison", value: contact?.exhUser?.name || "None" },
  ];

  const blocksClassNames = {
    title: "text-xl font-bold pt-4 pb-6",
    label: "w-[100px] min-w-[100px] text-xs capitalise font-bold",
    block: "flex flex-row pb-8 flex-1",
    detail: "pl-4 text-base break-all w-full",
    mailing: "text-base break-all underline pb-7",
  };

  return (
    <>
      <div className="min-h-full border border-b-0 border-t-0 border-gray-200">
        {open ? (
          <div className="w-full p-12">
            <div className="flex w-full flex-row">
              <div className="flex-1">
                <div
                  className="image-area h-[150px] w-[150px] min-w-[150px] border border-gray-700 bg-neutral-300"
                  style={{
                    backgroundImage: `url(${contact?.img || ""})`,
                    backgroundSize: "cover",
                  }}
                />
              </div>
              <div>
                <ChevronLeftIcon
                  className="h-7 w-7 cursor-pointer"
                  onClick={() => setOpen(false)}
                />
              </div>
            </div>
            <div className="pt-7 text-2xl font-bold">
              {contact?.name || "User Name"}
            </div>
            <div className="pb-10 text-xl">{contact?.company || "Company"}</div>
            <div className={blocksClassNames.title}>
              Preferred Contact Method
            </div>
            {contactMethods?.map((item, index) => {
              return (
                <div className={blocksClassNames.block} key={index}>
                  <div className={blocksClassNames.label}>{item.label}</div>
                  <div className={blocksClassNames.detail}>{item.value}</div>
                </div>
              );
            })}
            <div className={blocksClassNames.title}>Internal Reference</div>
            {internalReferences?.map((item, index) => {
              return (
                <div className={blocksClassNames.block} key={index}>
                  <div className={blocksClassNames.label}>{item.label}</div>
                  <div className={blocksClassNames.detail}>{item.value}</div>
                </div>
              );
            })}
            <div className={blocksClassNames.title}>Mailing Lists</div>
            {contact?.contactMailingLists?.map((item, index) => {
              return (
                <div className={blocksClassNames.mailing} key={index}>
                  {item?.mailingList?.description}
                </div>
              );
            })}
          </div>
        ) : (
          <div className="flex min-h-full flex-col px-[14px] py-[52px]">
            <ChevronRightIcon
              className="h-5 w-5 cursor-pointer"
              onClick={() => setOpen(true)}
            />
            <div className="relative flex min-h-full">
              <div className="absolute left-2/4 top-[75px] w-[135px] w-full -translate-x-1/2 -translate-y-1/2 -rotate-90 transform text-lg font-bold">
                Contact Details
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DetailBar;
