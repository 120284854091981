import Entity from "./Entity";

const ContactActivity = ({ user = false } = {}) => `
  id
  contactId
  createdAt
  meta
  updatedAt
  ${
    user
      ? `user {
      ${Entity}
    }`
      : ""
  }
  userId
`;

export default ContactActivity;
