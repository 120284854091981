import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import { useEffect } from "react";

import { Table } from "../../../../../../components/Functional";
import { UPDATE_EXHIBITION_WORK } from "../../../../../../graphql/mutation/ExhibitionWork";
import { FETCH_EXHIBITIONS_V2 as fetchExhibitionsV2 } from "../../../../../../graphql/query/Exhibition";
import { getChangedFields, useDebounce } from "../../../../../../util";
import { getInput } from "../../../../../../components/ui";
import SectionHeader from "../../components/SectionHeader";

const DateComponent = props => {
  const { day, month, year } = props || {};
  const date = [day, month, year].filter(Boolean);

  return (
    <div className="flex items-center justify-center">{date?.join("/")}</div>
  );
};

const ExhibitionRemoved = props => {
  const formik = useFormik({
    initialValues: {
      removed: props?.values?.exhibitionWorks?.[0]?.removed,
    },
    enableReinitialize: true,
  });
  const [updateExhibitionWork] = useMutation(UPDATE_EXHIBITION_WORK);
  const debouncedValues = useDebounce(formik?.values, 500);

  const inputProps = {
    designType: "threeStateCheckbox",
    formik,
    name: "removed",
    type: "toggle",
  };

  useEffect(() => {
    const changedValues = getChangedFields(
      debouncedValues,
      formik?.initialValues,
    );
    if (Object.keys(changedValues).length > 0) {
      updateExhibitionWork({
        variables: {
          input: {
            id: props?.values?.exhibitionWorks?.[0]?.id,
            removed: debouncedValues?.removed,
          },
        },
      });
    }
  }, [debouncedValues]);

  return (
    <div className="flex items-center justify-center">
      {getInput(inputProps)}
    </div>
  );
};

const ExhibitedComponent = props => {
  const formik = useFormik({
    initialValues: {
      exhibited: props?.values?.exhibitionWorks?.[0]?.exhibited,
    },
    enableReinitialize: true,
  });
  const [updateExhibitionWork] = useMutation(UPDATE_EXHIBITION_WORK);
  const debouncedValues = useDebounce(formik?.values, 500);

  const inputProps = {
    formik,
    name: "exhibited",
    type: "multi-select",
    options: [
      { label: "Not Shown", value: 0 },
      { label: "Shown", value: -1 },
      { label: "1st Day", value: 1 },
      { label: "2nd Day", value: 2 },
      { label: "3rd Day", value: 3 },
      { label: "4th Day", value: 4 },
      { label: "5th Day", value: 5 },
      { label: "Viewing Room", value: 6 },
    ],
  };

  useEffect(() => {
    const changedValues = getChangedFields(
      debouncedValues,
      formik?.initialValues,
    );
    if (Object.keys(changedValues).length > 0) {
      updateExhibitionWork({
        variables: {
          input: {
            id: props?.values?.exhibitionWorks?.[0]?.id,
            exhibited: debouncedValues?.exhibited,
          },
        },
      });
    }
  }, [debouncedValues]);

  return (
    <div className="flex items-center justify-center">
      {getInput(inputProps)}
    </div>
  );
};

const ExhibitionUser = props => {
  return (
    <div className="flex items-center justify-center">
      {props?.values?.exhibitionWorks?.[0]?.user}
    </div>
  );
};

const InstanceArtfair = props => {
  const { instanceId } = props || {};

  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "exhibitionsV2",
    headers: [
      { label: "Removed", type: "custom", component: ExhibitionRemoved },
      { label: "Art Fair", name: "title", type: "label" },
      { label: "User", type: "custom", component: ExhibitionUser },
      {
        label: "Start Date",
        type: "custom",
        component: props => (
          <DateComponent
            day={props?.values?.startDay}
            month={props?.values?.startMonth}
            year={props?.values?.startYear}
          />
        ),
      },
      {
        label: "End Date",
        type: "custom",
        component: props => (
          <DateComponent
            day={props?.values?.endDay}
            month={props?.values?.endMonth}
            year={props?.values?.endYear}
          />
        ),
      },
      { label: "Exhibited", type: "custom", component: ExhibitedComponent },
      { label: "Notes", name: "notes", type: "label" },
      { label: "Updated", name: "updatedAt", type: "date" },
    ],
    inputs: [],
    FETCH_QUERY: fetchExhibitionsV2({ exhibitionWorks: true }),
    variables: {
      type: {
        operator: "eq",
        value: "Artfair",
      },
      _exhibitionWork: {
        _instance: {
          id: {
            operator: "eq",
            value: instanceId,
          },
        },
      },
    },
    className: "sm:px-0 lg:px-0",
  };

  return (
    <div className="w-full">
      <SectionHeader title="Artfair" />
      <div className="flex w-full">
        <div className="flex-1">
          <Table {...tableProps} />
        </div>
      </div>
    </div>
  );
};

export default InstanceArtfair;
