import { gql } from "@apollo/client";
import { ConsignmentWork as consignmentWork } from "../fragments";

const FETCH_CONSIGNMENT_WORK = props => gql`
  query FetchConsignment($id: Int!) {
    consignmentWork(id: $id) {
      ${consignmentWork(props)}
    }
  }
`;

const FETCH_CONSIGNMENT_WORKS = props => gql`
  query FetchConsignmentWorks($input: ConsignmentWorkFilterInput!) {
    consignmentWorks(input: $input) {
      count
      edges {
        ${consignmentWork(props)}
      }
    }
  }
`;

export { FETCH_CONSIGNMENT_WORK, FETCH_CONSIGNMENT_WORKS };
