// import { SectionHeader } from "../../../../components/ui"
import { Table } from "../../../../components/Functional";
import { FETCH_COMPANIES as fetchCompanies } from "../../../../graphql/query/Company";
import Actions from "./components/Actions";

const RenderImg = ({ value }) => {
  return (
    <div className="flex items-center">
      {value ? (
        <img className="h-24 w-80 rounded border" src={value}></img>
      ) : (
        "Not Uploaded"
      )}
    </div>
  );
};

const HeaderFooter = () => {
  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "companies",
    headers: [
      { label: "Company", name: "name", type: "label" },
      { label: "#", name: "id", type: "label" },
      {
        label: "Header",
        name: "images",
        type: "custom",
        component: props => <RenderImg value={props?.values?.images?.header} />,
      },
      {
        label: "Footer",
        name: "images",
        type: "custom",
        component: props => <RenderImg value={props?.values?.images?.footer} />,
      },
      { label: "", name: "action", type: "custom", component: Actions },
    ],
    inputs: [],
    FETCH_QUERY: fetchCompanies({ images: {} }),
  };

  return (
    <div className="w-full">
      {/* <SectionHeader title="Shipments" /> */}
      <div className="mb-6 w-full">
        <Table {...tableProps} />
      </div>
    </div>
  );
};

export default HeaderFooter;
