import { gql } from "@apollo/client";
import { MailingList } from "../fragments";

const FETCH_MAILING_LIST = gql`
  query FetchMailingList($id: Int) {
    mailingList(id: $id) {
      ${MailingList}
    }
  }
`;

const FETCH_MAILING_LISTS = gql`
  query FetchMailingList($input: MailingListFilterInput) {
    mailingLists(input: $input) {
      count
      edges {
        ${MailingList}
      }
    }
  }
`;

export { FETCH_MAILING_LIST, FETCH_MAILING_LISTS };
