import { gql } from "@apollo/client";
import { ArtworkTariff } from "../fragments";

const FETCH_ARTWORK_TARIFFS = gql`
  query FetchArtworkTariffs {
    artworkTariffs {
      ${ArtworkTariff}
    }
  }
`;

export { FETCH_ARTWORK_TARIFFS };
