import { useQuery } from "@apollo/client";
import { GridInputs } from "../../../../../components/Functional";
import { FETCH_ENTITIES } from "../../../../../graphql/query/Entity";
import { FETCH_CONSIGNMENTS_STATUS } from "../../../../../graphql/query/ConsignmentStatus";

const Schedule = props => {
  const { formik } = props || {};

  const { data: { entities } = {} } = useQuery(FETCH_ENTITIES);
  const { data: { consignmentStatus = [] } = {} } = useQuery(
    FETCH_CONSIGNMENTS_STATUS,
  );

  const inputs = {
    className: "grid grid-cols-4 gap-16 mt-5",
    inputs: [
      {
        className: "flex flex-col gap-4",
        inputs: [
          {
            label: "Consignment Sales User",
            name: "salesUserId",
            type: "multi-select",
            options:
              entities?.edges?.map(item => ({
                label: item?.name,
                value: item?.id,
              })) || [],
          },
        ],
      },
      {
        className: "flex flex-col gap-4",
        inputs: [
          {
            label: "Consignment Status",
            name: "statusId",
            type: "multi-select",
            options: consignmentStatus?.map(item => ({
              label: item?.description,
              value: item?.id,
            })),
          },
        ],
      },
    ],
  };

  const gridInputProps = {
    formik,
    ...inputs,
  };

  return (
    <div className="w-full">
      <GridInputs {...gridInputProps} />
    </div>
  );
};

export default Schedule;
