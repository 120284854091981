import { Link } from "react-router-dom";
import { Table } from "../../../../../../components/Functional";
import SectionHeader from "../../components/SectionHeader";
import { FETCH_JOB_REQUESTS_V2 as fetchJobsV2 } from "../../../../../../graphql/query/Job";

const ConsignmentJobs = props => {
  const { consignmentId } = props || {};

  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "jobsV2",
    headers: [
      {
        label: "Job #",
        name: "id",
        type: "custom",
        component: ({ values }) => (
          <Link className="underline" to={`/jobs/${values?.id}/details`}>
            {values?.id}
          </Link>
        ),
      },
      { label: "Type", name: "jobType.description", type: "label" },
      { label: "Status", name: "jobStatus.description", type: "label" },
      { label: "Date Requested", name: "date", type: "date-time" },
      { label: "Requested By", name: "requestUser.name", type: "label" },
      { label: "Due Date", name: "dueDate", type: "date" },
      { label: "Confirmed", name: "user.name", type: "label" },
      { label: "Cost of Job", name: "costOfJob", type: "currency" },
    ],
    inputs: [],
    FETCH_QUERY: fetchJobsV2({
      jobStatus: {},
      jobType: {},
      requestUser: {},
      user: {},
    }),
    variables: {
      consignmentId: {
        value: consignmentId,
        operator: "eq",
      },
    },
    className: "sm:px-0 lg:px-0",
  };

  return (
    <div className="w-full">
      <SectionHeader title="Jobs" />
      <div className="mb-6 w-full">
        <Table {...tableProps} />
      </div>
    </div>
  );
};

export default ConsignmentJobs;
