// import { useEffect, useState } from "react";
import { FETCH_ARTWORKS_V2 as fetchArtworksV2 } from "../../../../../graphql/query/Artwork";
import { artworkFilterStore } from "../../../components/useArtworkFilters";

const SearchedArtworks = props => {
  const tableProps = {
    dataKey: "artworksV2",
    FETCH_QUERY: fetchArtworksV2({
      artists: true,
      imagesSummary: { imgT: {} },
    }),
    variables: {
      ...artworkFilterStore.get(),
    },
  };

  return tableProps;
};

export default SearchedArtworks;
