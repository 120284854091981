import { useQuery } from "@apollo/client";
import { GridInputs } from "../../../../../components/Functional";
import { FETCH_CONTACT_ADDRESS_TYPE } from "../../../../../graphql/query/ContactAddressType";

const ContactDetails = props => {
  const { countryOptions, formik } = props || {};

  const { data: { contactAddressTypes = [] } = {} } = useQuery(
    FETCH_CONTACT_ADDRESS_TYPE,
  );

  const inputs = {
    className: "grid grid-cols-4 gap-4",
    inputs: [
      {
        className: "gap-4",
        inputs: [
          {
            className: "grid-cols-1",
            inputs: [
              {
                label: "Mailing Label Name",
                name: "mailingname",
                type: "text",
              },
            ],
          },
          {
            className: "p-4 border rounded gap-4",
            inputs: [
              {
                hasOperator: true,
                label: "Email",
                name: "email",
                type: "text",
              },
              // { hasOperator: true, isMulti: true, label: "Type", name: "typeId", options: contactTypeOptions, type: "multi-select" },
            ],
          },
          // {
          //   className: 'grid-cols-1',
          //   inputs: [
          //     { label: "Created By", name: "createdById", options: entityOptions, type: "multi-select" },
          //   ]
          // }
        ],
      },
      {
        className: "col-span-3 gap-4",
        inputs: [
          {
            className: "grid-flow-col grid-col-3 gap-4",
            inputs: [
              { label: "Salutation", name: "salutation", type: "text" },
              {
                label: "Nationality",
                name: "nationality",
                options: countryOptions,
                type: "multi-select",
              },
              { label: "Other contact details", name: "notes", type: "text" },
            ],
          },
          {
            className: "grid-flow-col grid-col-3 p-4 border rounded gap-4",
            inputs: [
              {
                className: "col-span-1 gap-4",
                inputs: [
                  {
                    label: "Address Type",
                    name: "_contactAddress.typeId",
                    options: contactAddressTypes?.map(item => ({
                      label: item?.description,
                      value: item?.id,
                    })),
                    type: "multi-select",
                  },
                  {
                    label: "Country",
                    name: "_contactAddress.countryId",
                    options: countryOptions,
                    type: "multi-select",
                  },
                ],
              },
              {
                inputs: [
                  { label: "Town", name: "_contactAddress.town", type: "text" },
                ],
              },
              // {
              //   className: 'col-span-1 grid-cols-3 p-4 border rounded gap-4',
              //   inputs: [
              //     { label: "Event", name: "is_event", type: "toggle" },
              //     { label: "Delivery", name: "is_delivery", type: "toggle" },
              //     { label: "Vat", name: "is_vat", type: "toggle" },
              //     { label: "Billing", name: "is_billing", type: "toggle" },
              //     { label: "Statement", name: "is_statement", type: "toggle" },
              //     { label: "Catalogue", name: "is_catalogue", type: "toggle" },
              //   ]
              // },
            ],
          },
          // {
          //   className: 'grid-cols-3 col-span-1',
          //   inputs: [
          //     { label: "Updated By", name: "updatedById", options: entityOptions, type: "multi-select" },
          //   ]
          // }
        ],
      },
    ],
  };

  const gridInputProps = {
    formik,
    ...inputs,
  };

  return <GridInputs {...gridInputProps} />;
};

export default ContactDetails;
