import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Fragment, useEffect, useState } from "react";

export const Modal = props => {
  const {
    isOpen: defaultOpen = false,
    children,
    disabled = false,
    title,
    description,
    body,
    scale = "fullscreen",
    closeOnBackdrop = true,
    onClose,
    onOpen,
    hideCloseButton = false,
  } = props || {};

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    defaultOpen && openModal();
  }, [defaultOpen]);

  const closeModal = () => {
    setIsOpen(false);
    onClose?.();
  };

  const openModal = () => {
    onOpen?.();
    setIsOpen(true);
  };

  const sizeMap = {
    sm: "inline-block w-full max-w-md",
    smd: "inline-block w-full max-w-[628px]",
    md: "inline-block w-full max-w-[1032px]",
    lg: "inline-block w-full max-w-[1250px]",
    fullscreen: "fixed inset-0 h-[100vh] overflow-y-auto",
  };
  const scaleClass = sizeMap[scale];

  return (
    <div
      onKeyDown={e => e.stopPropagation()}
      // onClick={(e) => e.stopPropagation()}
      onFocus={e => e.stopPropagation()}
      onMouseOver={e => e.stopPropagation()}
    >
      <div onClick={!disabled ? openModal : null}>{children}</div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeOnBackdrop ? closeModal : () => {}}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-80"
              leave="ease-in duration-200"
              leaveFrom="opacity-80"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-300 opacity-75" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className={`${scaleClass} transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all`}
                data-testid="modal-child"
              >
                <div className="flex h-full flex-col">
                  {!hideCloseButton && (
                    <div className="flex justify-between">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        {title}
                      </Dialog.Title>
                      <XMarkIcon
                        className="h-4 w-4 cursor-pointer"
                        onClick={closeModal}
                      />
                    </div>
                  )}
                  <div className="mt-2 flex">
                    <p className="text-sm text-gray-500">{description}</p>
                  </div>

                  {body?.({ closeModal, isOpen, ...props })}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};
