import { useMutation } from "@apollo/client";
import { Popover, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { Table } from "../../../../../../components/Functional";

import { Button, getInput } from "../../../../../../components/ui";
import { FETCH_CONTACTS as fetchContacts } from "../../../../../../graphql/query/Contact";
import { FETCH_EMAIL_CONTACTS } from "../../../../../../graphql/query/EmailContact";
import { CREATE_EMAIL_CONTACT } from "../../../../../../graphql/mutation/EmailContact";

const AddRecipient = props => {
  const { emailId, label = "Add Recipients" } = props;

  const [createEmailContact] = useMutation(CREATE_EMAIL_CONTACT, {
    refetchQueries: [fetchContacts(), FETCH_EMAIL_CONTACTS],
  });
  const toggleProps = props => {
    const { id } = props || {};
    return {
      type: "toggle",
      onChange: value => {
        createEmailContact({
          variables: {
            input: {
              emailId,
              contactId: id,
            },
          },
        });
      },
    };
  };

  const inputTableProps = {
    dataKey: "contacts",
    FETCH_QUERY: fetchContacts(),
    headers: [
      {
        label: "Apply",
        name: "ids",
        type: "custom",
        component: props => getInput(toggleProps(props)),
      },
      { label: "ID", name: "id", type: "label" },
      { label: "Email", name: "email", type: "label" },
      { label: "Forename", name: "forename", type: "label" },
      { label: "Company", name: "company", type: "label" },
    ],
    inputs: [
      { label: "Email", name: "email", type: "text", view: "partial" },
      { label: "From", name: "from", type: "date", view: "partial" },
      { label: "To", name: "to", type: "date", view: "partial" },
    ],
    limit: 10,
    pagination: {
      variant: "sm",
    },
    variables: {
      emailId,
    },
  };

  return (
    <Popover className="relative">
      <Popover.Button
        className={`inline-flex h-full items-center focus:outline-none`}
      >
        <Button label={label} action={"dark"} />
      </Popover.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute z-10 mt-3 w-screen max-w-2xl transform bg-white px-4 sm:px-0">
          <div className="overflow-hidden rounded-lg p-7 shadow-lg ring-1 ring-black ring-opacity-5">
            <p className="font-bold">Add contacts to send this email to</p>
            <p className="text-sm">
              The selected mailing list will not be affected
            </p>
            <Table {...inputTableProps} />
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default AddRecipient;
