import { useNavigate } from "react-router-dom";

import { Breadcrumb, Button, MenuButton, Modal } from "../../../components/ui";

const PrimaryNav = () => {
  const navigate = useNavigate();

  // prepare steps for breadcrumbs
  const steps = [{ label: "All Lists", onClick: () => navigate("/list") }];

  const breadcrumbProps = {
    currentStepIndex: steps?.length - 1,
    onChange: index => steps[index]?.onClick(),
    steps: steps?.map(item => item?.label),
  };

  const reportsMenuProps = {
    label: "Reports",
    options: [{ label: "Address Labels" }],
  };

  const actionMenuProps = {
    label: "Actions",
    options: [{ label: "New List" }],
  };

  return (
    <div className="flex items-center justify-between border-b px-8 py-5">
      <Breadcrumb {...breadcrumbProps} />
      <div className="flex justify-end gap-4">
        <Modal>
          <Button label="Select List Search" action="black" />
        </Modal>
        <MenuButton {...reportsMenuProps} />
        <MenuButton {...actionMenuProps} />
      </div>
    </div>
  );
};

export default PrimaryNav;
