import List from "./pages/List";

const routes = [
  {
    path: "*",
    element: List,
    permissions: ["SELECT_LIST_MODULE"],
  },
];

export default routes;
