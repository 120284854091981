import { useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Button } from "../../../../components/ui";
import { GridInputs } from "../../../../components/Functional";

import { CREATE_CONTACT_OFFERS as createContactOffersMutation } from "../../../../graphql/mutation/Offer";
import { FETCH_LISTS as fetchLists } from "../../../../graphql/query/List";

const CreateContactOffersBody = props => {
  const { closeModal, ids, listId, search, select } = props || {};

  const [createContactOffers, { loading, reset }] = useMutation(
    createContactOffersMutation({ contact: {}, currency: {}, status: {} }),
    { refetchQueries: ["FetchOffersV2", "FetchListItems"] },
  );
  const { data: { lists: { edges: contactLists = [] } = {} } = {} } = useQuery(
    fetchLists({}),
    {
      variables: {
        input: { typeId: 1 },
      },
    },
  );

  const formik = useFormik({
    initialValues: {
      ...(select === "all"
        ? { ...(listId ? { listId } : { search }) }
        : { offerIds: ids }),
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      contactListId: Yup.number()
        .required("Please select a contact list to merge with.")
        .typeError("Please select a contact."),
    }),
    onSubmit: values => {
      createContactOffers({
        variables: {
          input: values,
        },
      }).then(resp => {
        const {
          data: { createContactOffers: { success } = {} },
        } = resp || {};
        if (success) {
          closeModal?.();
          reset?.();
          formik?.resetForm();
        }
      });
    },
  });

  const inputs = {
    className: "grid grid-cols-2 gap-8",
    inputs: [
      {
        label: "Contact List",
        name: "contactListId",
        type: "multi-select",
        options: contactLists?.map(item => ({
          label: item?.name,
          value: item?.id,
        })),
      },
    ],
  };

  const inputProps = {
    formik,
    ...inputs,
  };

  return (
    <div className="flex w-full flex-col p-8">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          Create offers for selected Contact List?
        </div>
        <div className="flex flex-row">
          <Button
            action="default"
            className="mr-8"
            label="Cancel"
            onClick={() => {
              closeModal();
              formik?.resetForm();
            }}
          />
          <Button
            label={loading ? "Creating" : "Create"}
            disabled={loading || !formik?.isValid}
            onClick={formik.submitForm}
          />
        </div>
      </div>
      <div className="mt-8 pb-8">
        <GridInputs {...inputProps} />
      </div>
    </div>
  );
};

export default CreateContactOffersBody;
