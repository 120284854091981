import { useQuery } from "@apollo/client";
import { GridInputs } from "../../../../../components/Functional";
import { FETCH_ARTISTS as fetchArtists } from "../../../../../graphql/query/Artist";
import { FETCH_NOMINALS } from "../../../../../graphql/query/Nominal";
import { FETCH_ARTWORK_CATEGORIES } from "../../../../../graphql/query/ArtworkCategory";
import { FETCH_CONDITION_STATUS } from "../../../../../graphql/query/ConditionStatus";
import { FETCH_STATUS } from "../../../../../graphql/query/Status";
import { FETCH_PAY_TYPES } from "../../../../../graphql/query/PayType";

const TransactionData = ({ formik }) => {
  const { data: { payTypes = [] } = {} } = useQuery(FETCH_PAY_TYPES);
  const { data: { nominals = [] } = {} } = useQuery(FETCH_NOMINALS);

  const inputs = {
    className: "grid grid-cols-2 gap-16 mt-5",
    inputs: [
      {
        className: "flex flex-col gap-4",
        inputs: [
          {
            label: "Item Ref",
            name: "_item.ref",
            type: "text",
          },
          {
            hasOperator: true,
            isMulti: true,
            label: "Type",
            name: "_item.typeId",
            options: payTypes?.map(item => ({
              label: item.name,
              value: item.numberId,
            })),
            type: "multi-select",
          },
          {
            label: "Date From",
            name: "_item.date.value.from",
            type: "date",
          },
          {
            label: "Date To",
            name: "_item.date.value.to",
            type: "date",
          },
        ],
      },
      {
        className: "gap-4",
        inputs: [
          {
            isMulti: false,
            label: "Nominal",
            name: "_item.nominalId",
            options: nominals?.map(status => ({
              label: status.label,
              value: status.id,
            })),
            type: "multi-select",
          },
          {
            label: "Discount From",
            name: "_item.discountRate.value.from",
            type: "number",
          },
          {
            label: "Discount To",
            name: "_item.discountRate.value.to",
            type: "number",
          },
          {
            label: "Profit From",
            name: "grossProfit.value.from",
            type: "number",
          },
          {
            label: "Profit To",
            name: "grossProfit.value.to",
            type: "number",
          },
        ],
      },
    ],
  };

  const gridInputProps = {
    formik,
    ...inputs,
  };

  return (
    <div className="mb-8 mt-6">
      <div className="text-lg font-semibold">Transaction</div>
      <GridInputs {...gridInputProps} />
    </div>
  );
};

const Invoice = ({ formik }) => {
  const { data: { artists = [] } = {} } = useQuery(fetchArtists());
  const { data: { artworkCategories = [] } = {} } = useQuery(
    FETCH_ARTWORK_CATEGORIES,
  );
  const { data: { conditionStatus = [] } = {} } = useQuery(
    FETCH_CONDITION_STATUS,
  );
  const { data: { status = [] } = {} } = useQuery(FETCH_STATUS);

  const inputs = {
    className: "grid grid-cols-2 gap-16 mt-5",
    inputs: [
      {
        className: "flex flex-col gap-4",
        inputs: [
          {
            hasOperator: true,
            isMulti: true,
            label: "Artist",
            name: "_item._instance._edition._artwork._artist.id",
            options: artists?.map(type => ({
              label: type?.name,
              value: type?.id,
            })),
            type: "multi-select",
          },
          {
            hasOperator: true,
            isMulti: true,
            label: "Category",
            name: "_item._instance._edition._artwork.categoryId",
            options: artworkCategories?.map(type => ({
              label: type?.name,
              value: type?.id,
            })),
            type: "multi-select",
          },
          {
            isMulti: false,
            label: "Condition",
            name: "_item._instance._condition.statusId",
            options: conditionStatus?.map(status => ({
              label: status.description,
              value: status.id,
            })),
            type: "multi-select",
          },
        ],
      },
      {
        className: "gap-4",
        inputs: [
          {
            label: "Medium",
            name: "_item._instance._edition._artwork.medium",
            type: "text",
          },
          {
            label: "Status",
            name: "_item._instance.statusId",
            options: status?.map(item => ({
              label: item?.name,
              value: item?.id,
            })),
            type: "multi-select",
          },
          {
            designType: "threeStateCheckbox",
            label: "Fabricated",
            name: "_item._instance.fabricated",
            type: "toggle",
          },
        ],
      },
    ],
  };

  const gridInputProps = {
    formik,
    ...inputs,
  };

  return (
    <div className="mb-8 mt-6">
      <div className="text-lg font-semibold">Invoice</div>
      <GridInputs {...gridInputProps} />
    </div>
  );
};

const Transaction = props => {
  const { formik } = props || {};

  return (
    <div className="flex flex-row">
      <div className="w-full border-r-2 pr-8">
        <TransactionData formik={formik} />
      </div>
      <div className="ml-8 w-full">
        <Invoice formik={formik} />
      </div>
    </div>
  );
};

export default Transaction;
