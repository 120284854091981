import * as React from "react";

import { GridInputs } from "../../../../../../components/Functional";
import useUser from "../../../../../../hooks/useUser";

import SectionHeader from "../../components/SectionHeader";

const ArtworkPrecis = props => {
  const { formik } = props || {};
  const { hasPermission } = useUser();

  const inputs = {
    className: "grid grid-cols-4 gap-4",
    inputs: [
      {
        className: "col-span-1",
        inputs: [
          {
            disabled: !hasPermission("UPDATE_ARTWORK_PRECIS"),
            name: "precis",
            rows: 6,
            type: "textarea",
            submitOnBlur: true,
          },
        ],
      },
    ],
  };

  const detailInputProps = {
    formik,
    ...inputs,
  };

  return (
    <div className="w-full">
      <SectionHeader title="Precis" />
      <div className="w-full px-16 py-8">
        <GridInputs {...detailInputProps} />
      </div>
    </div>
  );
};

export default ArtworkPrecis;
