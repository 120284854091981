import React from "react";

import SectionHeader from "../../components/SectionHeader";
import { Table } from "../../../../../../components/Functional";
import { getInput } from "../../../../../../components/ui";
import { FETCH_RESERVATION_INSTANCES_V2 as fetchReservationInstancesV2 } from "../../../../../../graphql/query/ReservationInstance";
import { useFormik } from "formik";
import { RELEASE_RESERVATION_INSTANCE as releaseReservationInstanceMutation } from "../../../../../../graphql/mutation/ReservationInstance";
import { useMutation } from "@apollo/client";

const ReservationReleased = props => {
  const [releaseReservationInstance, { loading }] = useMutation(
    releaseReservationInstanceMutation({ instance: {} }),
  );

  const formik = useFormik({
    initialValues: {
      released: props?.values?.released || 0,
    },
    onSubmit: async values => {
      await releaseReservationInstance({
        variables: {
          input: {
            id: props?.values?.id,
          },
        },
      }).catch(e => {
        formik?.resetForm();
        throw e;
      });
    },
  });

  return (
    <div>
      {getInput({
        ...props,
        formik,
        name: "released",
        type: "toggle",
        designType: "twoStateCheckbox",
        submitOnBlur: true,
        // if not allocated, disable
        disabled:
          loading ||
          props?.values?.released === -1 ||
          props?.instance?.statusId !== 13,
        label: "",
      })}
    </div>
  );
};

const InstanceCondition = props => {
  const { instanceId, instance } = props || {};

  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "reservationInstancesV2",
    headers: [
      { label: "Reservation", name: "id", type: "label" },
      { label: "Date", name: "reservation.date", type: "date" },
      {
        label: "Reserved For",
        name: "reservation.reservationUser.name",
        type: "label",
      },
      { label: "Description", name: "reservation.description", type: "label" },
      {
        label: "Released",
        name: "released",
        type: "custom",
        component: props => (
          <ReservationReleased {...props} instance={instance} />
        ),
      },
    ],
    inputs: [],
    FETCH_QUERY: fetchReservationInstancesV2({
      reservation: { reservationUser: {} },
    }),
    variables: {
      _instance: {
        id: {
          operator: "eq",
          value: instanceId,
        },
      },
    },
    className: "sm:px-0 lg:px-0",
  };

  return (
    <div className="w-full">
      <SectionHeader title="Reservation" />
      <div className="flex w-full">
        <div className="flex-1">
          <Table {...tableProps} />
        </div>
      </div>
    </div>
  );
};

export default InstanceCondition;
