import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link, useLocation } from "react-router-dom";
import useUser from "../../../hooks/useUser";

const MenuItem = props => {
  const { active = false, href, icon, name, open } = props || {};

  const queryParams = new URLSearchParams(window.location.search);
  const status = queryParams.get("status");

  return (
    <Link
      className="flex h-5 cursor-pointer  items-center pl-4"
      to={`${href}${status ? `?status=${status}` : ""}`}
      title={name}
    >
      {icon && (
        <props.icon
          className={`mr-5 h-4 w-4 flex-shrink-0 ${active ? "text-black" : "text-gray-500"}`}
          aria-hidden="true"
        />
      )}
      {open && (
        <span
          className={`flex-1 text-sm ${active ? "text-black underline" : "text-gray-500"}`}
        >
          {name}
        </span>
      )}
    </Link>
  );
};

const NavBar = props => {
  const { navigation = [], open, path } = props || {};
  const location = useLocation();
  const { hasPermission } = useUser();
  const { pathname } = location || {};

  return (
    <div
      className={`flex flex-initial flex-col overflow-y-auto bg-white px-2 py-5 ${
        open ? "w-64" : "w-16"
      } min-h-full overflow-x-hidden`}
    >
      <nav className="flex flex-1 flex-col gap-6 bg-white" aria-label="Sidebar">
        {navigation?.map((item, index) => {
          const { permissions } = item;
          const hasPermissions = permissions
            ? permissions?.every?.(permission => hasPermission(permission))
            : true;
          const active = path
            ? path?.includes(item?.href)
            : pathname?.split("/")?.pop() == item?.href ||
              pathname?.split("/")?.[2] == item?.href; // Admin Module User and Roles
          return (
            hasPermissions && (
              <MenuItem key={index} {...item} open={open} active={active} />
            )
          );
        })}
      </nav>
    </div>
  );
};

const SimpleSidebar = props => {
  const { open, setOpen } = props || {};

  return (
    <div className="flex flex-col border-r border-gray-200">
      <div
        className={
          "flex cursor-pointer items-center rounded-md bg-white p-5 text-gray-400 hover:bg-gray-100 hover:text-gray-500" +
          "focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        }
        onClick={() => setOpen(!open)}
      >
        <span className="sr-only">Open side bar</span>
        {open ? (
          <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
        ) : (
          <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
        )}
      </div>
      <div id="sidebar"></div>
    </div>
  );
};

export { NavBar, SimpleSidebar };
