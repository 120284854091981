import { Route, Routes as RouterRoutes } from "react-router-dom";

import routes from "./routes";
import PrimaryNav from "./components/PrimaryNav";
import AuthRoute from "../../components/AuthRoute";
import { useFormik } from "formik";

const Body = props => {
  return (
    <RouterRoutes>
      {routes?.map((item, index) => {
        return (
          <Route
            key={index}
            path={item?.path}
            element={
              <AuthRoute permissions={item.permissions}>
                <item.element {...props} />
              </AuthRoute>
            }
          />
        );
      })}
    </RouterRoutes>
  );
};

const Instance = props => {
  const formik = useFormik({
    initialValues: {},
  });

  return (
    <>
      <PrimaryNav formik={formik} />
      <Body formik={formik} {...props} />
    </>
  );
};

// Switches to be used for the instance status globally
export const statusColor = value => {
  switch (value) {
    case "Available":
      return "text-sky-750";
    case "Sold":
      return "text-red-650";
    default:
      return "text-black";
  }
};

export default Instance;
