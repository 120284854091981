import { gql } from "@apollo/client";
import { ConsignmentStatus } from "../fragments";

const FETCH_CONSIGNMENTS_STATUS = gql`
  query consignmentStatus {
  consignmentStatus {
      ${ConsignmentStatus}
  }
}
`;

export { FETCH_CONSIGNMENTS_STATUS };
