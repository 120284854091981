import { useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import { useEffect } from "react";
import * as Yup from "yup";

import {
  GridInputs,
  Table,
  multiSelect,
} from "../../../../../../../components/Functional";
import { Button, Modal } from "../../../../../../../components/ui";
import { convertToFilter } from "../../../../../../../util";

import { FETCH_WAREHOUSE_ITEMS_V2 as fetchWarehouseItemsV2 } from "../../../../../../../graphql/query/WarehouseItem";
import { ADD_SHIPMENT_ITEMS as addShipmentItemsMutation } from "../../../../../../../graphql/mutation/Shipment";
import { FETCH_WAREHOUSE_CATEGORIES } from "../../../../../../../graphql/query/WarehouseCategory";

const FilterBar = props => {
  const { formik } = props || {};
  const { data: { warehouseCategories = [] } = {} } = useQuery(
    FETCH_WAREHOUSE_CATEGORIES,
  );

  const inputs = {
    className: "grid-cols-5 gap-4 my-6",
    inputs: [
      { label: "Storage #", name: "id", type: "number" },
      {
        label: "Category",
        name: "categoryId",
        type: "multi-select",
        options: warehouseCategories?.map(item => ({
          label: item.description,
          value: item.id,
        })),
      },
      { label: "Description", name: "description", type: "text" },
      {
        label: "Title",
        name: "_instance._edition._artwork.title",
        type: "text",
      },
      { label: "External Ref", name: "externalRef", type: "text" },
    ],
  };

  const inputProps = {
    formik,
    ...inputs,
  };

  return <GridInputs {...inputProps} />;
};

const AddItemModal = props => {
  const { closeModal, shipment } = props;

  const [
    addShipmentItems,
    { data: { addShipmentItems: { error } = {} } = {}, loading, reset },
  ] = useMutation(addShipmentItemsMutation(), {
    refetchQueries: ["FetchWarehouseMoveV2", "FetchWarehouseItemsV2"],
  });

  const handleAddItem = () => {
    addShipmentItems({
      variables: {
        input: {
          id: shipment?.id,
          items: selectedItems,
        },
      },
    }).then(resp => {
      const { data: { addShipmentItems: { success } = {} } = {} } = resp;

      if (success) {
        resetAll();
      }
    });
  };

  const formik = useFormik({
    initialValues: {},
    onSubmit: handleAddItem,
  });

  const filterFormik = useFormik({
    initialValues: {},
    validationSchema: Yup.object({
      id: Yup.number()
        .nullable()
        .integer()
        .positive()
        .typeError("Invalid input"),
    }),
  });

  const resetAll = () => {
    filterFormik?.resetForm();
    formik?.resetForm();
    reset?.();
    closeModal?.();
  };

  const selectedItems = Object.keys(
    formik?.values?.selectedWarehouseItems?.ids || {},
  )?.map(item => parseInt(item.replace("_", "")));

  useEffect(() => {
    reset();
  }, [formik?.values?.selectedWarehouseItems]);

  const multiSelectProps = {
    formik,
    label: "Warehouse Items",
    name: "selectedWarehouseItems",
    keyName: "id",
    showAll: false,
  };

  const tableProps = {
    dataKey: "warehouseItemsV2",
    FETCH_QUERY: fetchWarehouseItemsV2({
      artist: {},
      category: {},
      instance: { edition: { artwork: { artists: {} } } },
    }),
    headers: [
      multiSelect(multiSelectProps),
      { label: "Storage#", name: "id", type: "label" },
      { label: "Category", name: "category.description", type: "label" },
      { label: "Group", name: "mgroup", type: "label" },
      { label: "Description", name: "description", type: "label" },
      { label: "Instance", name: "instanceId", type: "label" },
      { label: "Title", name: "instance.edition.artwork.title", type: "label" },
      { label: "Year", name: "instance.edition.year", type: "label" },
      {
        label: "Artist",
        name: "instance.edition.artwork",
        type: "custom",
        component: props => {
          const combinedName = props?.label?.artists
            ?.map(artist => artist.name)
            .join(",");
          return (
            <div
              title={combinedName}
              className={`overflow-hidden text-ellipsis text-base font-medium leading-5 text-black`}
              style={{ width: 140 }}
            >
              {combinedName}
            </div>
          );
        },
      },
      { label: "External Ref", name: "externalRef", type: "label" },
    ],
    inputs: [],
    limit: 10,
    skip: () => !filterFormik?.isValid,
    variables: {
      warehouseMoveSiteId: {
        operator: "eq",
        value: shipment?.oriContact?.siteId, // only show items at origin siteId
      },
      ...convertToFilter({
        values: filterFormik?.values,
        skipKeys: ["limit", "offset"],
      }),
    },
    pagination: {
      variant: "sm",
    },
    variant: "dynamicHeight",
  };

  return (
    <div className="mt-6 flex w-full flex-col">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col">
          <div className="text-2xl font-bold">Add Storage Item(s)</div>
        </div>
        <div className="flex flex-col">
          <div>
            <Button
              action="default"
              className="mr-4"
              label="Cancel"
              onClick={() => {
                resetAll();
              }}
            />
            <Button
              label={loading ? "Adding" : "Add"}
              disabled={loading || !selectedItems?.length}
              onClick={formik?.submitForm}
            />
          </div>
        </div>
      </div>
      <FilterBar formik={filterFormik} />
      <p className="mt-4">
        {selectedItems?.length} Selected{" "}
        <span className="ml-8 text-red-500">{error}</span>
      </p>
      <Table {...tableProps} />
    </div>
  );
};

const AddItem = props => {
  const { shipment } = props || {};

  const modalProps = {
    body: AddItemModal,
    closeOnBackdrop: false,
    shipment,
    hideCloseButton: false,
    scale: "md",
  };

  return (
    <Modal {...modalProps}>
      <Button label="Add Item" action="black" />
    </Modal>
  );
};

export default AddItem;
