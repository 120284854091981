import { useQuery } from "@apollo/client";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useFormik } from "formik";
import { useEffect, useState } from "react";

import {
  useDebounce,
  convertToFilter,
  convertArrayToFilterInputObj,
  getChangedFields,
} from "../../../../util";
import Table from "../../../Table";
import { Button, IconButton, Modal, Pagination, SearchBar } from "../..";

import {
  FETCH_ITEM as fetchItem,
  FETCH_ITEMS_V2 as fetchItemsV2,
} from "../../../../graphql/query/Item";

const LIMIT = 5;

const TableCheck = props => {
  const { values, selectedValue, onChange } = props;
  return (
    <div className="relative flex w-[40px] items-start" key={values.id}>
      <div className="flex h-5 items-center">
        <input
          id={values.id}
          value={values.id}
          checked={selectedValue?.id === values.id}
          aria-describedby="comments-description"
          name="id"
          type="checkbox"
          className="h-4 w-4 rounded border-2 border-gray-800 text-gray-800 focus:ring-0"
          onChange={() => onChange(values)}
        />
      </div>
    </div>
  );
};

const InstanceSearchInput = props => {
  const {
    disabled,
    error,
    filterInput = [],
    label,
    loading,
    onChange: setValue,
    value,
  } = props;

  const filterInputObj = convertArrayToFilterInputObj(filterInput);

  const initialValues = { limit: LIMIT, ...filterInputObj };

  const [filters, setFilters] = useState(initialValues);
  const debouncedFilters = useDebounce(filters, 300);
  const skip = Object.keys(debouncedFilters).length === 0;
  const input = convertToFilter({
    values: debouncedFilters,
    filterKeys: [],
    skipKeys: ["limit", "offset"],
  });

  const {
    data: { itemsV2: { edges = [], count } = {} } = {},
    loading: fetching,
  } = useQuery(
    fetchItemsV2({
      instance: { edition: { artwork: { imagesSummary: { imgT: {} } } } },
      nominal: {},
      type: {},
    }),
    { skip, variables: { input } },
  );
  const { data: { item = {} } = {}, client } = useQuery(fetchItem(), {
    skip: !value,
    variables: { id: value },
  });

  const [selectedValue, setSelectedValue] = useState(null);

  useEffect(() => {
    if (!value) {
      setSelectedValue(null);
      client.writeQuery({
        query: fetchItem(),
        data: {},
      });
    } else {
      setSelectedValue({ id: value });
    }
  }, [value]);

  const formikSearch = useFormik({
    initialValues,
  });

  const onChange = values => {
    setFilters(prevState => ({ ...prevState, ...values }));
  };

  const handleSave = () => {
    setValue?.(selectedValue?.id || null);
  };

  const searchBarProps = {
    name: "name",
    placeholder: "Search",
    formik: formikSearch,
    variant: "simple",
  };

  const paginationProps = {
    count,
    limit: LIMIT,
    offset: filters?.offset,
    showSteps: false,
    onChange,
    variant: "simple",
  };

  const handleOnClick = value => {
    value?.id === selectedValue?.id
      ? setSelectedValue(null)
      : setSelectedValue(value);
  };

  const tableProps = {
    headers: [
      {
        label: "",
        width: "50px",
        name: "checkbox",
        type: "custom",
        component: props => (
          <TableCheck
            {...props}
            selectedValue={selectedValue}
            onChange={handleOnClick}
          />
        ),
      },
      { label: "Date", name: "date", type: "label" },
      { label: "Type", name: "type.name", type: "label" },
      props => ({
        label: "Nominal",
        name: props?.row?.nominal ? "nominal.description" : "nominalId",
        type: "label",
      }),
      {
        label: "Artwork Image",
        name: "instance?.edition?.artwork?.imagesSummary?.imgT",
        type: "image",
      },
      { label: "Reference", name: "ref", type: "label" },
      { label: "Description", name: "description", type: "label" },
    ],
    limit: LIMIT,
    loading: fetching,
    rows: edges,
    variant: "lg",
  };

  useEffect(() => {
    onChange(formikSearch?.values);
    const changedFields = getChangedFields(
      formikSearch?.values,
      formikSearch?.initialValues,
    );
    if (Object.keys(changedFields).length > 0) {
      setFilters(prevState => ({ ...prevState, offset: 0 }));
    }
  }, [formikSearch?.values]);

  const modalProps = {
    scale: "md",
    hideCloseButton: true,
    closeOnBackdrop: true,
    disabled,
    body: ({ closeModal }) => {
      return (
        <div className="flex w-full flex-col p-8">
          <div className="flex flex-row">
            <div className="flex flex-1 flex-col text-2xl font-bold">
              <div>Select {label}</div>
            </div>
            <div className="flex flex-col">
              <div>
                <Button
                  action="default"
                  className="mr-8"
                  label="Cancel"
                  onClick={() => {
                    closeModal();
                    setSelectedValue(item);
                  }}
                />
                <Button
                  action="default"
                  className="mr-8"
                  disabled={!selectedValue}
                  label="Clear Selection"
                  onClick={() => {
                    setSelectedValue(null);
                  }}
                />
                <Button
                  label={loading ? "SELECTING" : "SELECT"}
                  disabled={loading || fetching || (!value && !selectedValue)}
                  onClick={() => {
                    closeModal();
                    handleSave();
                  }}
                />
              </div>
            </div>
          </div>
          <div className="pt-12">
            <div className="grid grid-cols-2 gap-10 pb-12">
              <div>
                <SearchBar {...searchBarProps} />
              </div>
            </div>
            <Table {...tableProps} />
            <div>
              <Pagination {...paginationProps} />
            </div>
          </div>
        </div>
      );
    },
  };

  return (
    <Modal {...modalProps}>
      <div className={`flex flex-1 flex-col`}>
        <div className={`block text-xs font-medium text-black`}>{label}</div>
        <div
          className={`mt-1 flex h-[38px] w-full items-center rounded border border-black focus:border-black focus:ring-0 ${disabled ? "bg-gray-100" : ""}`}
        >
          <div className={` flex-1 px-3 py-2 text-base `}>{item?.ref}</div>
          <IconButton variant="clean" title="Search Instance">
            <MagnifyingGlassIcon
              className={`h-5 w-5 cursor-pointer text-gray-400`}
            />
          </IconButton>
        </div>
        {error && <small className="text-sm text-red-600">{error}</small>}
      </div>
    </Modal>
  );
};

export default InstanceSearchInput;
