import Currency from "./Currency";
import FabricationCategory from "./FabricationCategory";
import FabricationStage from "./FabricationStage";
import FabricationType from "./FabricationType";
import instanceFrag from "./Instance";

const Fabrication = ({
  category = false,
  currency = false,
  stage = false,
  type = false,
  instance = false,
} = {}) => `
  amount
  approved
  artworkId
  ${
    category
      ? `category {
      ${FabricationCategory}
    }`
      : ""
  }
  categoryId
  cause
  confirmed
  consignmentId
  ${
    currency
      ? `currency {
      ${Currency}
    }`
      : ""
  }
  currencyId
  date
  days
  description
  estimate
  exchange
  hours
  id
  instanceId
  ${
    instance
      ? `instance {
      ${instanceFrag(instance)}
    }`
      : ""
  }
  invoice
  issueId
  jobId
  notes
  passed
  po
  rate
  recharge
  rechargerate
  shipmentId
  ${
    stage
      ? `stage {
      ${FabricationStage}
    }`
      : ""
  }
  stageId
  tax
  technicians
  techniciansTotal
  ${
    type
      ? `type {
      ${FabricationType}
    }`
      : ""
  }
  typeId
  waived
  updatedAt
`;

export default Fabrication;
