import Entity from "./Entity";

const PaymentPlan = ({ createdBy = false } = {}) => `
  id
  dueDate
  amount
  cancel
  ${
    createdBy
      ? `createdBy {
      ${Entity}
    }`
      : ""
  }
  createdById
  invoiceId
  description
  updatedAt
`;

export default PaymentPlan;
