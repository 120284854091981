import bankAccount from "./BankAccount";
import companyFrag from "./Company";
import contactFrag from "./Contact";
import Country from "./Country";
import Currency from "./Currency";
import Entity from "./Entity";
import invoiceSalesPersonFrag from "./InvoiceSalesPerson";
import InvoiceType from "./InvoiceType";
import itemFrag from "./Item";

const Invoice = ({
  bank = false,
  company = false,
  contact = false,
  country = false,
  currency = false,
  items = false,
  invoiceTotals = false,
  invoiceSalesPersons = false,
  salesUser = false,
  type = false,
} = {}) => `
  id
  address
  address2
  address3
  approved
  analysisId
  analysisEventId
  artAdvisorId
  artAdvisorSale
  ${
    bank
      ? `bank {
      ${bankAccount(bank)}
    }`
      : ""
  }
  bankId
  channelId
  chargeStorage
  ${
    company
      ? `company {
      ${companyFrag(company)}
    }`
      : ""
  }
  companyId
  companyName
  conduit
  ${
    contact
      ? `contact {
      ${contactFrag(contact)}
    }`
      : ""
  }
  contactId
  county
  ${
    country
      ? `country {
      ${Country}
    }`
      : ""
  }
  countryId
  ${
    currency
      ? `currency {
      ${Currency}
    }`
      : ""
  }
  currencyId
  date
  debtChase
  deliveryAddress
  deliveryAddress2
  deliveryAddress3
  deliveryCompany
  deliveryCountryId
  deliveryCounty
  deliveryEmail
  deliveryExpectation
  deliveryHold
  deliveryName
  deliveryPhone
  deliveryPostcode
  deliveryTown
  destinationId
  dueDate
  ${
    items
      ? `items {
      ${itemFrag(items)}
    }`
      : ""
  }
  exchange
  immutable
  ${invoiceTotals ? "invoiceTotals" : ""}
  marginRef
  marginScheme
  name
  number
  notes
  postcode
  proofdate
  proforma
  purchaserId
  purchaserCountryId
  reference
  registrarId
  shedule
  ${
    salesUser
      ? `salesUser {
      ${Entity}
    }`
      : ""
  }
  sagecode
  ${
    invoiceSalesPersons
      ? `invoiceSalesPersons {
      ${invoiceSalesPersonFrag(invoiceSalesPersons)}
    }`
      : ""
  }
  taxareaId
  taxRate
  town
  terms
  termsback
  ${
    type
      ? `type {
      ${InvoiceType}
    }`
      : ""
  }
  typeId
  updatedAt
  user
  vatno
`;

export default Invoice;
