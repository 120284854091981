import { gql } from "@apollo/client";
import { Technical as technical } from "../fragments";

const FETCH_TECHNICALS = props => gql`
  query FetchTechnicals($input: TechnicalFilterInput!) {
    technicals(input: $input) {
      count
      edges {
        ${technical(props)}
      }
      sqlQuery
    }
  }
`;

const FETCH_TECHNICALS_V2 = props => gql`
  query FetchTechnicalsV2($input: AdvTechnicalFilterInput!) {
    technicalsV2(input: $input) {
      count
      edges {
        ${technical(props)}
      }
      sqlQuery
    }
  }
`;

export { FETCH_TECHNICALS, FETCH_TECHNICALS_V2 };
