import { PencilIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { IconButton } from "../../../../../components/ui";

const Actions = props => {
  const { values } = props || {};
  const { id } = values?.item || values || {};
  const navigate = useNavigate();

  const handleEdit = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const status = queryParams.get("status") || "all";
    const listId = queryParams.get("listId") || null;
    navigate(
      `${id}/details?status=${status}${listId ? `&listId=${listId}` : ""}`,
    );
  };

  if (typeof id == "number") {
    return (
      <div className="user-list-actions flex text-right text-sm font-medium">
        {id && (
          <div className="grid">
            <IconButton variant="clean" title="Edit Artwork">
              <PencilIcon
                className={`h-4 w-4 cursor-pointer text-gray-400`}
                onClick={handleEdit}
              />
            </IconButton>
          </div>
        )}
      </div>
    );
  } else {
    return false;
  }
};

export default Actions;
