import { Button } from "../../../../../../../components/ui";
import { useMutation } from "@apollo/client";
import { DELETE_CONTACT_MAILING_LIST } from "../../../../../../../graphql/mutation/ContactMailingList";

const DeleteContactMethod = ({ username, contactMailingList, closeModal }) => {
  const [deleteMailingList, { loading }] = useMutation(
    DELETE_CONTACT_MAILING_LIST,
  );
  const handleDelete = () => {
    deleteMailingList({
      variables: {
        input: { id: contactMailingList?.id },
      },
      update: (cache, response) => {
        if (response.data?.deleteContactMailingList?.success) {
          cache.evict({
            id: cache.identify({
              __typename: "ContactMailingList",
              id: contactMailingList?.id,
            }),
          });
          closeModal();
        }
      },
    });
  };
  return (
    <div className="p-8">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          Delete Contact MailingList Confirmation
        </div>
        <div className="flex flex-col">
          <div>
            <Button
              label="CANCEL"
              action="default"
              className="mr-4"
              onClick={closeModal}
            />
            <Button
              label={loading ? "DELETING" : "DELETE"}
              onClick={handleDelete}
              disabled={loading}
            />
          </div>
        </div>
      </div>
      <div className="mb-2 mt-8">
        You are about to delete the followoing mailinglist from {username}{" "}
        contact record:{" "}
        <span className="font-bold">
          {contactMailingList?.mailingList?.description}
        </span>
      </div>
    </div>
  );
};

export default DeleteContactMethod;
