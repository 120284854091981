import { Fragment } from "react";
import { Button } from "../index";

const SliderControls = props => {
  const {
    actions,
    images,
    setImageList,
    setSuccessOpModal,
    imageIndex,
    artworkId,
  } = props;

  return (
    <div className="absolute left-0 top-0 box-border hidden h-full w-full flex-col items-center justify-evenly group-hover:flex">
      {actions?.map((item, index) => {
        return item?.component ? (
          <Fragment key={index}>
            {item?.component({
              currentSlide: imageIndex,
              images,
              setImageList,
              setSuccessOpModal,
              artworkId,
            })}
          </Fragment>
        ) : (
          <Button
            action={item.type}
            key={index}
            className={`mb-4 ${item.className}`}
            label={item.label}
            onClick={item.action}
          />
        );
      })}
    </div>
  );
};

export default SliderControls;
