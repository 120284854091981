const makeObservable = target => {
  let listeners = [];
  let value = target;

  const get = () => {
    return value;
  };

  const set = newValue => {
    if (value === newValue) return;
    value = newValue;
    listeners.forEach(l => l(value));
  };

  const subscribe = listenerFunc => {
    listeners.push(listenerFunc);
    return () => unsubscribe(listenerFunc); // will be used inside React.useEffect
  };

  const unsubscribe = listenerFunc => {
    listeners = listeners.filter(l => l !== listenerFunc);
  };

  return {
    get,
    set,
    subscribe,
  };
};

export default makeObservable;
