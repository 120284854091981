import { useQuery } from "@apollo/client";

import { GridInputs } from "../../../../../../../components/Functional";
import { FETCH_INVOICE_CHANNELS } from "../../../../../../../graphql/query/InvoiceChannel";
import { FETCH_INVOICE_ANALYSIS_EVENTS } from "../../../../../../../graphql/query/InvoiceAnalysisEvent";
import { FETCH_INVOICE_DESTINATIONS } from "../../../../../../../graphql/query/InvoiceDestination";
import { FETCH_INVOICE_SAGECODES } from "../../../../../../../graphql/query/InvoiceSageCode";
import useUser from "../../../../../../../hooks/useUser";

const conduitOptions = [
  { label: "Default", value: 1 },
  { label: "Catalogue", value: 2 },
  { label: "Stand", value: 3 },
  { label: "Viewing Room", value: 4 },
  { label: "Back Room", value: 5 },
];

const Coding = props => {
  const { data: { invoiceChannels = [] } = {} } = useQuery(
    FETCH_INVOICE_CHANNELS,
  );
  const { data: { invoiceAnalysisEvents = [] } = {} } = useQuery(
    FETCH_INVOICE_ANALYSIS_EVENTS,
  );
  const { data: { invoiceDestinations = [] } = {} } = useQuery(
    FETCH_INVOICE_DESTINATIONS,
  );
  const { data: { invoiceSageCodes = [] } = {} } = useQuery(
    FETCH_INVOICE_SAGECODES,
  );

  const { formik, submitOnBlur = false } = props;
  const { hasPermission } = useUser();

  // calculates the invoice analysis code from channel/analysis/event
  function calculateInvoiceCoding(channel, analysis, event) {
    let coding = "";
    if (channel) {
      const ic = invoiceChannels.find(v => v.id == channel);
      if (ic?.sagecode) {
        coding += ic.sagecode;
        if (event) {
          coding += new Date().getFullYear() % 100;
        }
      }
    }
    if (analysis) {
      const isc = invoiceSageCodes.find(v => v.id == analysis);
      if (isc?.analysis) {
        coding += "-" + isc.analysis;
        if (!event) {
          coding += "-" + (new Date().getFullYear() % 100);
        }
      }
    }
    if (event) {
      const iae = invoiceAnalysisEvents.find(v => v.id == event);
      if (iae?.analysis) {
        coding += "-" + iae.analysis;
      }
    }
    return coding;
  }

  const inputs = {
    className: "grid grid-cols-2 gap-8 mt-5",
    inputs: [
      {
        className: "grid-cols-1 gap-4",
        inputs: [
          {
            label: "Channel",
            name: "channelId",
            type: "multi-select",
            options: invoiceChannels?.map(item => ({
              label: item.description,
              value: item.id,
            })),
            onChange: value => {
              const invoiceCoding = calculateInvoiceCoding(
                value,
                formik.values["analysisId"],
                formik.values["analysisEventId"],
              );
              formik?.getFieldHelpers("sagecode")?.setValue(invoiceCoding);
              formik?.getFieldHelpers("channelId")?.setValue(value);
              formik?.submitForm();
            },
            disabled: !hasPermission("UPDATE_INVOICE"),
          },
          {
            label: "Analysis",
            name: "analysisId",
            type: "multi-select",
            options: invoiceSageCodes?.map(item => ({
              label: item.description,
              value: item.id,
            })),
            onChange: value => {
              const invoiceCoding = calculateInvoiceCoding(
                formik.values["channelId"],
                value,
                formik.values["analysisEventId"],
              );
              formik?.getFieldHelpers("analysisId")?.setValue(value);
              formik?.getFieldHelpers("sagecode")?.setValue(invoiceCoding);
              formik?.submitForm();
            },
            disabled: !hasPermission("UPDATE_INVOICE"),
          },
          {
            label: "Analysis Event",
            name: "analysisEventId",
            type: "multi-select",
            options: invoiceAnalysisEvents?.map(item => ({
              label: item.description,
              value: item.id,
            })),
            onChange: value => {
              const invoiceCoding = calculateInvoiceCoding(
                formik.values["channelId"],
                formik.values["analysisId"],
                value,
              );
              formik?.getFieldHelpers("analysisEventId")?.setValue(value);
              formik?.getFieldHelpers("sagecode")?.setValue(invoiceCoding);
              formik?.submitForm();
            },
            disabled: !hasPermission("UPDATE_INVOICE"),
          },
        ],
      },
      {
        className: "grid-cols-1 gap-4",
        inputs: [
          {
            label: "Destination",
            name: "destinationId",
            type: "multi-select",
            options: invoiceDestinations?.map(item => ({
              label: item.description,
              value: item.id,
            })),
            submitOnBlur,
            disabled: !hasPermission("UPDATE_INVOICE"),
          },
          {
            label: "Conduit",
            name: "conduit",
            type: "multi-select",
            options: conduitOptions,
            submitOnBlur,
            disabled: !hasPermission("UPDATE_INVOICE"),
          },
        ],
      },
    ],
  };

  const inputProps = {
    formik,
    ...inputs,
  };

  return (
    <div className="w-full">
      <h5 className="font-bold">Coding</h5>
      <GridInputs {...inputProps} />
    </div>
  );
};

export default Coding;
