import Entity from "./Entity";
import JobStatus from "./JobStatus";
import JobType from "./JobType";

const Job = ({
  jobStatus = false,
  jobType = false,
  requestUser = false,
  user = false,
  artworkDescriptions = false,
  pickupGroup = false,
} = {}) => `
  id
  ${artworkDescriptions ? "artworkDescriptions" : ""}
  chargeOut
  consignmentId
  costOfJob
  date
  description
  dueDate
  duetime
  expenses
  hours
  hoursvan
  issueId
  ${
    jobStatus
      ? `jobStatus {
      ${JobStatus}
    }`
      : ""
  }
  ${
    jobType
      ? `jobType {
      ${JobType}
    }`
      : ""
  }
  link
  overtimehours
  permanentHours
  pickupId
  ${
    pickupGroup
      ? `pickupGroup {
      ${Entity}
    }`
      : ""
  }
  rate
  rateStaff
  rateOvertime
  requestGroupId
  ${
    requestUser
      ? `requestUser {
      ${Entity}
    }`
      : ""
  }
  requestUserId
  ratevan
  statusId
  technicians
  technicianNotes
  typeId
  ${
    user
      ? `user {
      ${Entity}
    }`
      : ""
  }
  userId
  updatedAt
  viewing
`;

export default Job;
