const Company = ({ images = false } = {}) => `
  id
  code
  crossInvoiceId
  ${images ? "images" : ""}
  name
  vatno
`;

export default Company;
