import moment from "moment";
import * as Yup from "yup";
import * as React from "react";
import { Button, getInput } from "../../../../../../../components/ui";
import { useFormik } from "formik";
import { useMutation, useQuery } from "@apollo/client";
import { FETCH_NOMINALS } from "../../../../../../../graphql/query/Nominal";
import { FETCH_CATALOGUES } from "../../../../../../../graphql/query/Catalogue";
import {
  CREATE_CONTACT_CATALOGUE as createContactCatalogueMutation,
  UPDATE_CONTACT_CATALOGUE as updateContactCatalogueMutation,
} from "../../../../../../../graphql/mutation/ContactCatalogue";

const AddMaterial = props => {
  const { closeModal, contact, contactCatalogue, isOpen } = props;
  const { data: { nominals = [] } = {} } = useQuery(FETCH_NOMINALS);
  const { data: { catalogues = [] } = {} } = useQuery(FETCH_CATALOGUES);
  const [createContactCatalogue, { loading: createLoading }] = useMutation(
    createContactCatalogueMutation(),
    { refetchQueries: ["FetchContactCatalogues"] },
  );
  const [updateContactCatalogue, { loading: updateLoading }] = useMutation(
    updateContactCatalogueMutation(),
  );

  const loading = createLoading || updateLoading;

  const handleClick = () => {
    (contactCatalogue
      ? updateContactCatalogue({
          variables: {
            input: {
              id: contactCatalogue?.id,
              ...formik.values,
            },
          },
        })
      : createContactCatalogue({
          variables: {
            input: {
              contactId: contact?.id,
              ...formik?.values,
            },
          },
        })
    ).then(resp => {
      const {
        data: {
          createContactCatalogue: { success } = {},
          updateContactCatalogue: { success: updateSuccess } = {},
        },
      } = resp || {};
      if (success || updateSuccess) {
        closeModal();
      }
    });
  };

  const inputs = [
    { label: "Date", name: "updatedAt", type: "date" },
    { label: "Note", name: "notes", type: "textarea", className: "row-span-3" },
    {
      label: "Catalogue",
      name: "catalogueId",
      options: catalogues?.map(item => ({
        label: item.title,
        value: item.id,
      })),
      type: "multi-select",
    },
    {
      label: "Source",
      name: "nominalId",
      options: nominals?.map(item => ({
        label: item.label,
        value: item.id,
      })),
      type: "multi-select",
    },
  ];

  const formik = useFormik({
    initialValues: {
      catalogueId: contactCatalogue?.catalogue?.id,
      nominalId: contactCatalogue?.nominal?.id,
      notes: contactCatalogue?.notes,
      updatedAt: moment(contactCatalogue?.updatedAt).format("YYYY-MM-DD"),
    },
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: Yup.object({
      catalogueId: Yup.number().required("Required"),
      nominalId: Yup.number().required("Required"),
      notes: Yup.string().trim().required("Required"),
    }),
    onSubmit: handleClick,
  });
  React.useEffect(() => {
    formik.resetForm();
  }, [isOpen]);
  return (
    <div className="flex w-full flex-col p-8">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          {contactCatalogue ? `Update` : `Record`} Catalogues sent for{" "}
          {contact?.name}
        </div>
        <div className="flex flex-row">
          <Button
            action="default"
            className="mr-8"
            label="Cancel"
            onClick={() => {
              closeModal();
            }}
          />
          <Button
            label={
              loading
                ? contactCatalogue
                  ? "Updating"
                  : "Adding"
                : contactCatalogue
                  ? "Update Material Sent"
                  : "Add Material Sent"
            }
            disabled={loading}
            onClick={formik.submitForm}
          />
        </div>
      </div>
      <div className="grid w-full grid-cols-2 gap-x-20 gap-y-4 pb-10 pt-20">
        {inputs?.map((item, index) => {
          const inputProps = {
            ...item,
            formik,
          };
          return (
            <div key={index} className={`w-full ${item?.className}`}>
              {getInput(inputProps)}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AddMaterial;
