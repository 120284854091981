import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { IconButton, Modal } from "../../../../../../../../../components/ui";

import DeleteMethodModal from "./DeleteMethodModal";
import AddMethodModal from "./AddMethodModal";

const DetailView = props => {
  const { contact, contactNumber, disabled = false } = props || {};
  const {
    donotcall,
    identifier,
    number,
    prefere,
    type = {},
  } = contactNumber || {};

  const editMethodModalProps = {
    body: AddMethodModal,
    contact,
    contactNumber,
    closeOnBackdrop: true,
    hideCloseButton: true,
    scale: "md",
  };

  const deleteMethodModalProps = {
    body: DeleteMethodModal,
    contact,
    contactNumber,
    closeOnBackdrop: true,
    hideCloseButton: true,
    scale: "md",
  };

  return (
    <div className="group flex flex-col">
      <div className="w-96 border-b border-gray-500 pb-2 pr-4">
        <div className="flex flex-col">
          {type.description === "Website" ? (
            <a
              className="underline"
              href={number.startsWith("http") ? number : `http://${number}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {number}
            </a>
          ) : type.description === "Email" ? (
            <a className="underline" href={`mailto:${number}`}>
              {number}
            </a>
          ) : (
            <div>{number}</div>
          )}
        </div>
        {!disabled && (
          <div className="absolute right-0 top-0 flex">
            <Modal {...editMethodModalProps}>
              <div className="hidden group-hover:block">
                <IconButton variant="clean" title="Edit Method">
                  <PencilIcon
                    className={`h-4 w-4 cursor-pointer text-gray-400`}
                  />
                </IconButton>
              </div>
            </Modal>
            <Modal {...deleteMethodModalProps}>
              <div className="hidden group-hover:block">
                <IconButton variant="clean" title="Delete Method">
                  <TrashIcon
                    className={`h-4 w-4 cursor-pointer text-gray-400`}
                  />
                </IconButton>
              </div>
            </Modal>
          </div>
        )}
      </div>
      <div className="flex w-96 justify-between pt-2 text-xs">
        <div className="text-xxs">
          {donotcall === -1 && (
            <div className={`flex text-red-600`}>DO NOT CONTACT</div>
          )}
          {prefere === -1 && <div className={`flex`}>PREFERRED</div>}
        </div>
        <div className="capitalise flex flex-col text-right font-bold">
          {type?.description} {identifier ? `(${identifier})` : ""}
        </div>
      </div>
    </div>
  );
};

export default DetailView;
