import { PencilIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { IconButton } from "../../../../../components/ui";

const Actions = props => {
  const { id } = props || {};
  const navigate = useNavigate();

  const handleEdit = () => {
    navigate(`${id}/details`);
  };

  if (typeof id == "number") {
    return (
      <div className="user-list-actions flex text-right text-sm font-medium">
        {id && (
          <div className="grid">
            <IconButton variant="clean" title="Edit Shipments">
              <PencilIcon
                className={`h-4 w-4 cursor-pointer text-gray-400`}
                onClick={handleEdit}
              />
            </IconButton>
          </div>
        )}
      </div>
    );
  } else {
    return false;
  }
};

export default Actions;
