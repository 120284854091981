import { FETCH_LISTS as fetchLists } from "../../../../../graphql/query/List";
import { multiSelect } from "../../../../../components/Functional";
import * as SelectList from "../../../../../components/Functional/SelectList";

const Actions = props => {
  const { formik } = props || {};
  const actionProps = {
    ...props,
    ids: Object.keys(formik?.values?.selectedList?.ids || {})?.map(item =>
      parseInt(item.replace("_", "")),
    ),
  };

  return (
    <div className="grid grid-flow-col gap-4">
      <SelectList.DeleteList {...actionProps} />
    </div>
  );
};

const tableProps = props => {
  const { formik } = props || {};

  const multiSelectProps = {
    formik,
    label: "Select List",
    name: "selectedList",
    keyName: "id",
  };

  const tableProps = {
    dataKey: "lists",
    headers: [
      multiSelect(multiSelectProps),
      { label: "List Name", name: "name", sortKey: "name", type: "label" },
      { label: "Invoice Count", name: "itemsCount", type: "label" },
      { label: "Date / Time Last Updated", name: "updatedAt", type: "date" },
      {
        name: "action",
        type: "custom",
        component: props => <SelectList.TableActions {...props} />,
      },
    ],
    FETCH_QUERY: fetchLists(),
    variables: {
      typeId: 9,
    },
  };

  return tableProps;
};

export { Actions, tableProps };
