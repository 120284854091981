import artworkFrag from "./Artwork";
import photoMaterialFrag from "./PhotoMaterial";

const Image = ({
  artwork = false,
  imgT = false,
  imgX = false,
  imgA = false,
  imgB = false,
  jpegUrls = false,
  photoMaterial = false,
  urls = false,
} = {}) => `
  ${
    artwork
      ? `artwork {
      ${artworkFrag(artwork)}
    }`
      : ""
  }
  artworkId
  archiveId
  channelDisplayOnline
  description
  downloadPath
  ${imgT ? `imgT` : ""}
  ${imgX ? `imgX` : ""}
  ${imgA ? `imgA` : ""}
  ${imgB ? `imgB` : ""}
  ${jpegUrls ? `jpegUrls` : ""}
  order
  photoMaterialId
  ${
    photoMaterial
      ? `photoMaterial {
      ${photoMaterialFrag(photoMaterial)}
    }`
      : ""
  }
  updatedAt
  uploadedAt
  ${urls ? `urls` : ""}
  xDownloadPath
`;

export default Image;
