import { useQuery } from "@apollo/client";

import { getInput } from "../../../../../../../components/ui";
import { FETCH_ENTITIES } from "../../../../../../../graphql/query/Entity";
import { FETCH_CONSIGNMENT_CHANNELS } from "../../../../../../../graphql/query/ConsignmentChannel";
import { FETCH_CONSIGNMENTS_STATUS } from "../../../../../../../graphql/query/ConsignmentStatus";
import useUser from "../../../../../../../hooks/useUser";

const Sales = props => {
  const { formik, submitOnBlur = false } = props;
  const { data: { entities } = {} } = useQuery(FETCH_ENTITIES);
  const { data: { consignmentChannels = [] } = {} } = useQuery(
    FETCH_CONSIGNMENT_CHANNELS,
  );
  const { data: { consignmentStatus = [] } = {} } = useQuery(
    FETCH_CONSIGNMENTS_STATUS,
  );
  const { hasPermission } = useUser();
  const inputs = [
    {
      name: "salesUserId",
      type: "multi-select",
      label: "Sales User",
      options:
        entities?.edges?.map(item => ({
          label: item?.name,
          value: item?.id,
        })) || [],
      submitOnBlur,
      disabled: !hasPermission("UPDATE_CONSIGNMENT"),
    },
    {
      label: "Artist Liasion",
      name: "artistUserId",
      type: "multi-select",
      options:
        entities?.edges?.map(item => ({
          label: item.name,
          value: item.id,
        })) || [],
      submitOnBlur,
      disabled: !hasPermission("UPDATE_CONSIGNMENT"),
    },
    {
      label: "Channel",
      name: "channelId",
      type: "multi-select",
      options: consignmentChannels?.map(item => ({
        label: item?.description,
        value: item?.id,
      })),
      submitOnBlur,
      disabled: !hasPermission("UPDATE_CONSIGNMENT"),
    },
    {
      label: "Status",
      name: "statusId",
      type: "multi-select",
      options: consignmentStatus?.map(item => ({
        label: item?.description,
        value: item?.id,
      })),
      submitOnBlur,
      disabled: !hasPermission("UPDATE_CONSIGNMENT"),
    },
    {
      label: "Shipment Notes",
      name: "shipmentNotes",
      type: "textarea",
      submitOnBlur,
      disabled: !hasPermission("UPDATE_CONSIGNMENT"),
    },
    {
      label: "Notes",
      name: "notes",
      type: "textarea",
      submitOnBlur,
      disabled: !hasPermission("UPDATE_CONSIGNMENT"),
    },
    {
      label: "Photo Material",
      name: "photoMaterial",
      type: "text",
      submitOnBlur,
      disabled: !hasPermission("UPDATE_CONSIGNMENT"),
    },
    {
      label: "Credit",
      name: "credit",
      type: "text",
      submitOnBlur,
      disabled: !hasPermission("UPDATE_CONSIGNMENT"),
    },
    {
      label: "Insured",
      name: "guarantee",
      type: "multi-select",
      submitOnBlur,
      options: [
        { label: "Null", value: 0 },
        { label: "Goverment Indemnity", value: -1 },
      ],
      disabled: !hasPermission("UPDATE_CONSIGNMENT"),
    },
  ];

  return (
    <div className="w-full">
      <div className="mt-2 grid h-fit grid-cols-1 gap-4">
        {inputs?.map((item, index) => {
          const inputProps = {
            formik,
            ...item,
          };

          return <div key={index}>{getInput(inputProps)}</div>;
        })}
      </div>
    </div>
  );
};

export default Sales;
