import { FETCH_SHIPMENTS_V2 as fetchShipmentsV2 } from "../../../../../graphql/query/Shipment";
import { shipmentFilterStore } from "../../../components/useShipmentFilters";

const SearchedShipments = props => {
  const tableProps = {
    dataKey: "shipmentsV2",
    FETCH_QUERY: fetchShipmentsV2({ currency: true }),
    limit: 50,
    variables: {
      ...shipmentFilterStore.get(),
    },
  };

  return tableProps;
};

export default SearchedShipments;
