import { useFormik } from "formik";
import { useEffect, useState } from "react";
import {
  Button,
  Pagination,
  SearchBar,
} from "../../../../../../../components/ui";
import Table from "../../../../../../../components/Table";
import { useQuery, useMutation } from "@apollo/client";
import { FETCH_MAILING_LISTS } from "../../../../../../../graphql/query/MailingList";
import { CREATE_CONTACT_MAILING_LIST as createContactMailingListMutation } from "../../../../../../../graphql/mutation/ContactMailingList";
import { useDebounce } from "../../../../../../../util";

const TableCheck = props => {
  const { values, selectedValues, onChange } = props;
  return (
    <div className="relative flex items-start" key={values.id}>
      <div className="flex h-5 items-center">
        <input
          id={values.id}
          value={values.id}
          checked={selectedValues.includes(values.id)}
          aria-describedby="comments-description"
          name="id"
          type="checkbox"
          className="h-4 w-4 rounded border-2 border-gray-800 text-gray-800 focus:ring-0"
          onChange={() => onChange(values.id)}
        />
      </div>
    </div>
  );
};

const MailingComponent = props => {
  const { closeModal, userName, mailinList, isOpen, contactId } = props;
  const [filters, setFilters] = useState({});
  const [selectedValues, handleSelectedValues] = useState();
  const debouncedFilters = useDebounce(filters, 300);
  const skip = Object.keys(debouncedFilters).length === 0;
  const { data: { mailingLists: { edges = [], count } = {} } = {} } = useQuery(
    FETCH_MAILING_LISTS,
    { skip, variables: { input: { ...debouncedFilters } } },
  );
  const [createContactMailingList, { loading }] = useMutation(
    createContactMailingListMutation(),
    {
      refetchQueries: ["FetchContact"],
    },
  );
  const limit = 12;
  const initialValues = { search: "", limit };
  const formikSearch = useFormik({
    initialValues,
  });
  const onChange = values => {
    setFilters({ ...filters, ...values });
  };
  const confirmAction = () => {
    selectedValues.forEach(item => {
      createContactMailingList({
        variables: {
          input: {
            contactId,
            mailingListId: item,
          },
        },
      }).then(() => {
        closeModal();
      });
    });
  };
  const searchBarProps = {
    name: "search",
    placeholder: "Search",
    formik: formikSearch,
    variant: "simple",
  };

  const paginationProps = {
    count,
    limit,
    offset: filters?.offset,
    showSteps: false,
    onChange,
    variant: "simple",
  };

  const handleOnClick = value => {
    if (selectedValues.includes(value)) {
      handleSelectedValues(selectedValues.filter(item => item !== value));
    } else {
      handleSelectedValues([...selectedValues, value]);
    }
  };

  const tableProps = {
    headers: [
      {
        label: "",
        name: "checkbox",
        type: "custom",
        component: props => (
          <TableCheck
            {...props}
            selectedValues={selectedValues}
            onChange={handleOnClick}
          />
        ),
      },
      { label: "Mailing List", name: "description", type: "label" },
      { label: "Subscribers", name: "subscribersCount", type: "label" },
    ],
    limit,
    loading,
    rows: edges,
    variant: "modal",
  };

  useEffect(() => {
    onChange(formikSearch.values);
  }, [formikSearch.values]);

  useEffect(() => {
    if (isOpen) {
      handleSelectedValues(mailinList ? mailinList : []);
    }
  }, [isOpen, mailinList]);
  return (
    <div className="flex w-full flex-col p-8">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          <div>Add {userName} to a Mailing List</div>
          <div className="pt-4 text-base font-normal">
            Search or scroll to add or remove this contact from mailing list
          </div>
        </div>
        <div className="flex flex-col">
          <div>
            <Button
              action="default"
              className="mr-8"
              label="Cancel"
              onClick={() => {
                closeModal();
              }}
            />
            <Button
              label={loading ? "Saving" : "Save"}
              disabled={loading || !selectedValues?.length}
              onClick={confirmAction}
            />
          </div>
        </div>
      </div>
      <div className="pt-12">
        <div className="grid grid-cols-2 gap-10 pb-12">
          <div>
            <SearchBar {...searchBarProps} />
          </div>
        </div>
        <Table {...tableProps} />
        <div>
          <Pagination {...paginationProps} />
        </div>
      </div>
    </div>
  );
};

export default MailingComponent;
