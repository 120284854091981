const Email = ({ analytics = false } = {}) => `
  id
  ${analytics ? `analytics` : ""}
  createdAt
  meta
  status
  title
  updatedAt
`;

export default Email;
