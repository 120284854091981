import { useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Button } from "../../../../../../../components/ui";
import { useEffect } from "react";
import { GridInputs } from "../../../../../../../components/Functional";

import {
  CREATE_PHOTO_MATERIAL as createPhotoMaterialMutation,
  UPDATE_PHOTO_MATERIAL as updatePhotoMaterialMutation,
} from "../../../../../../../graphql/mutation/PhotoMaterial";
import { FETCH_FORMATS } from "../../../../../../../graphql/query/Format";
import { FETCH_PHOTOGRAPHERS } from "../../../../../../../graphql/query/Photographers";
import { CREATE_PHOTOGRAPHER } from "../../../../../../../graphql/mutation/Photographer";

const addRecord = props => {
  const { artworkId, closeModal, values, isOpen } = props;
  // eslint-disable-next-line
  const { data: { formats = [] } = {} } = useQuery(FETCH_FORMATS);
  const { data: { photographers = [], loading: fetchLoading } = {} } =
    // eslint-disable-next-line
    useQuery(FETCH_PHOTOGRAPHERS);
  // eslint-disable-next-line
  const [createPhotoMaterial, { loading: loadingCreate }] = useMutation(
    createPhotoMaterialMutation({ format: true }),
    { refetchQueries: ["FetchPhotoMaterialsV2"] },
  );
  // eslint-disable-next-line
  const [updatePhotoMaterial, { loading: loadingUpdate }] = useMutation(
    updatePhotoMaterialMutation({ format: true }),
    { refetchQueries: ["FetchPhotoMaterialsV2"] },
  );

  // eslint-disable-next-line
  const [createPhotographer, { loading: createLoading }] = useMutation(
    CREATE_PHOTOGRAPHER,
    {
      refetchQueries: [FETCH_PHOTOGRAPHERS],
    },
  );

  const addRecordaddRecord = () => {
    const input = {
      formatId: formik.values.formatId,
      number: formik.values.number,
      date: formik.values.date,
      photographer: formik.values.photographer,
      credit: formik.values.credit,
      description: formik.values.description,
      original: formik.values.original,
    };
    if (values?.id) {
      input.id = values?.id;
    } else {
      input.artworkId = artworkId;
    }

    const action = values?.id ? updatePhotoMaterial : createPhotoMaterial;
    action({
      variables: {
        input,
      },
    }).then(() => {
      closeModal();
      formik.resetForm();
    });
  };

  // eslint-disable-next-line
  const formik = useFormik({
    initialValues: values
      ? {
          formatId: values.formatId,
          number: values?.number,
          date: values?.date,
          photographer: values?.photographer,
          credit: values?.credit,
          description: values?.description,
          original: values?.original,
        }
      : {},
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: Yup.object({
      formatId: Yup.number().required("Please select format."),
      number: Yup.number()
        .typeError("Please enter a valid number.")
        .positive("must be a positive value")
        .integer()
        .required("Please enter number of copies."),
    }),
    onSubmit: addRecordaddRecord,
  });

  const inputs = {
    className: "grid grid-cols-2 gap-12",
    inputs: [
      {
        className: "grid grid-cols-1 gap-4",
        inputs: [
          {
            label: "Format",
            name: "formatId",
            type: "multi-select",
            options:
              formats?.map(item => {
                return { label: item.description, value: item.id };
              }) || [],
          },
          { label: "Copies", name: "number", type: "number" },
          { label: "Date", name: "date", type: "date" },
          {
            isLoading: fetchLoading || createLoading,
            label: "Photographer",
            isCreatable: true,
            name: "photographer",
            type: "multi-select",
            options:
              photographers?.map(item => {
                return { label: item.description, value: item.description };
              }) || [],
            handleAction: (value, actionProps) => {
              const { action } = actionProps || {};

              if (action === "create-option") {
                return createPhotographer({
                  variables: {
                    input: {
                      description: value?.label,
                    },
                  },
                });
              }
            },
          },
        ],
      },
      {
        className: "grid grid-cols-1 gap-4",
        inputs: [
          { label: "Credit", name: "credit", rows: 4, type: "textarea" },
          {
            label: "Description",
            name: "description",
            rows: 4,
            type: "textarea",
          },
          {
            name: "original",
            options: [{ label: "Original", value: -1 }],
            singleCheckbox: true,
            type: "checkbox",
          },
        ],
      },
    ],
  };

  const inputProps = {
    ...inputs,
    formik,
  };

  const successLabel = values?.id ? "Edit" : "Add";

  // eslint-disable-next-line
  useEffect(() => {
    formik.handleReset();
  }, [isOpen]);

  return (
    <div className="flex w-full flex-col p-8">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          {values?.id ? "Edit" : "Add"} Photographic Material Record
        </div>
        <div className="flex flex-col">
          <div>
            <Button
              action="default"
              className="mr-8"
              label="Cancel"
              onClick={closeModal}
            />
            <Button
              label={`${successLabel}${
                loadingUpdate || loadingCreate ? "ing" : ""
              } Record`}
              disabled={loadingUpdate || loadingCreate}
              onClick={formik.submitForm}
            />
          </div>
        </div>
      </div>
      <div className="mt-12 pb-10">
        <GridInputs {...inputProps} />
      </div>
    </div>
  );
};

export default addRecord;
