import { useNavigate } from "react-router-dom";
import { FETCH_LIST_ITEMS as fetchListItems } from "../../../../../graphql/query/ListItems";
import { SelectList, multiSelect } from "../../../../../components/Functional";
import ExportImages from "./ExportImages";

const Actions = props => {
  const { formik, imageListId, imageWorkingListId } = props || {};
  const navigate = useNavigate();
  const listId = imageListId || imageWorkingListId;
  const ids = Object.keys(formik?.values?.selectedImages?.ids || {})?.map(
    item => parseInt(item.replace("_", "")),
  );

  return (
    <div className="grid grid-flow-col gap-4">
      <SelectList.RemoveSelected {...props} ids={ids} listId={listId} />
      <SelectList.ClearList {...props} listId={listId} />
      <ExportImages {...props} ids={ids} listId={listId} />
      {imageListId ? (
        <SelectList.ExitEditMode
          onSuccess={() => (
            props?.onSuccess?.(),
            navigate("/artworks?status=imageSelectListSaved")
          )}
        />
      ) : (
        <SelectList.SaveList
          {...{
            ...props,
            typeId: 15,
            onSuccess: () => (
              props?.onSuccess?.(),
              navigate("/artworks?status=imageSelectListSaved")
            ),
            workingListId: imageWorkingListId,
          }}
        />
      )}
    </div>
  );
};

const tableProps = props => {
  const { imageListId, imageWorkingListId, formik } = props || {};

  const multiSelectProps = {
    formik,
    label: "Instances",
    name: "selectedImages",
    keyName: "id",
  };

  const tableProps = {
    dataKey: "listItems",
    fetchPolicy: "network-only",
    FETCH_QUERY: fetchListItems({
      item: { artwork: { artists: {} }, imgT: true },
    }),
    headers: [
      multiSelect(multiSelectProps),
      { label: "Artwork Image", name: "item.imgT", type: "image" },
      { label: "Artwork Name", name: "item.artwork.title", type: "label" },
      {
        label: "Artist Name",
        name: "item.artwork.artists[0].name",
        type: "label",
      },
    ],
    skip: values => !values?.listId,
    variables: {
      // use working list if no listId is provided
      listId: imageListId || imageWorkingListId,
    },
  };

  return tableProps;
};

export { Actions, tableProps };
