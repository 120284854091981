import { gql } from "@apollo/client";
import { Currency } from "../fragments";

const FETCH_CURRENCIES = gql`
  query FetchCurrencies {
    currencies {
      ${Currency}
    }
  }
`;

export { FETCH_CURRENCIES };
