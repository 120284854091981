import { gql } from "@apollo/client";

const DELETE_CONTACT_DOCUMENT = gql`
  mutation DeleteContactDocument($input: DeleteContactDocumentInput!) {
    deleteContactDocument(input: $input) {
      success
      error
      response
    }
  }
`;

const UPLOAD_CONTACT_DOCUMENTS = gql`
  mutation UploadContactDocuments($contactId: Int!, $files: [Upload]!) {
    uploadContactDocuments(contactId: $contactId, files: $files) {
      success
      error
      response
    }
  }
`;

export { DELETE_CONTACT_DOCUMENT, UPLOAD_CONTACT_DOCUMENTS };
