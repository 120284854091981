/* eslint-disable max-len */
const WarningIcon = props => (
  <svg
    width={20}
    height={18}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>{"feather icon/warning"}</title>
    <defs>
      <path
        d="M2.917 18.333c-.417 0-.834-.083-1.25-.333C.5 17.333.083 15.75.75 14.583L7.833 2.75c.167-.333.5-.667.834-.833a2.553 2.553 0 0 1 1.916-.25c.667.166 1.167.583 1.584 1.166l7 11.75c.25.417.333.834.333 1.25 0 .667-.25 1.334-.75 1.75-.417.5-1 .75-1.667.75H2.917ZM9.25 3.667l-7 11.75c-.25.416-.083.916.333 1.166.084.084.25.084.334.084H17c.25 0 .417-.084.583-.25a.757.757 0 0 0 .25-.584c0-.166 0-.25-.083-.416l-7-11.75c-.25-.417-.75-.5-1.167-.334a.65.65 0 0 0-.333.334Zm1.583 7.166V7.5c0-.5-.333-.833-.833-.833-.5 0-.833.333-.833.833v3.333c0 .5.333.834.833.834.5 0 .833-.334.833-.834Zm-.25 3.917a.757.757 0 0 0 .25-.583c0-.084 0-.25-.083-.334 0-.083-.083-.166-.167-.25 0 0-.083-.083-.166-.083-.084 0-.084-.083-.167-.083s-.083 0-.167-.084c-.166 0-.333 0-.5.084-.083 0-.166.083-.25.166-.083.084-.166.167-.166.25 0 .084-.084.167-.084.334 0 .25.084.416.25.583.167.167.334.25.584.25.333 0 .5-.083.666-.25Z"
        id="a"
      />
    </defs>
    <g transform="translate(0 -1)" fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <use fill="#000" fillRule="nonzero" xlinkHref="#a" />
      <g mask="url(#b)" fill="#FFB822">
        <path d="M0 0h20v20H0z" />
      </g>
    </g>
  </svg>
);

export default WarningIcon;
