import { useMutation } from "@apollo/client";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Button, IconButton, Modal } from "../../../../../../../components/ui";
import AddEditCost from "./AddEditCost";
import { DELETE_FABRICATION } from "../../../../../../../graphql/mutation/Fabrication";
import useUser from "../../../../../../../hooks/useUser";

const Actions = props => {
  const { hasPermission } = useUser();
  const { id, values } = props || {};
  const [deleteFabrication, { loading }] = useMutation(DELETE_FABRICATION, {
    refetchQueries: ["FetchFabricationsV2"],
  });

  const editModalProps = {
    body: AddEditCost,
    closeOnBackdrop: true,
    fabrication: values?.rawFields,
    hideCloseButton: true,
    scale: "md",
  };

  const deleteModalProps = {
    title: "Are you sure?",
    scale: "sm",
    description: `Would you like to delete cost?`,
    closeOnBackdrop: true,
    disabled: !hasPermission("UPDATE_CONSIGNMENT"),
    body: ({ closeModal }) => {
      const handleDelete = () => {
        deleteFabrication({
          variables: {
            input: { id: +values?.rawFields?.id },
          },
          update: () => {
            closeModal();
          },
        });
      };

      return (
        <div className="mt-4 flex">
          <Button
            className="mr-3"
            label={"Cancel"}
            action={"default"}
            onClick={() => {
              closeModal?.();
            }}
          />
          <Button
            label={loading ? "Deleting" : "Delete"}
            disabled={loading}
            onClick={handleDelete}
          />
        </div>
      );
    },
  };

  if (typeof id == "number") {
    return (
      <div className="user-list-actions flex text-right text-sm font-medium">
        {id && (
          <div className="grid grid-cols-4 gap-4">
            <Modal {...editModalProps}>
              <IconButton variant="clean" title="Edit Cost">
                <PencilIcon
                  className={`h-4 w-4 cursor-pointer text-gray-400`}
                />
              </IconButton>
            </Modal>
            <Modal {...deleteModalProps}>
              <IconButton
                variant="clean"
                title="Delete Cost"
                disabled={!hasPermission("UPDATE_CONSIGNMENT")}
              >
                <TrashIcon className={`h-4 w-4 cursor-pointer text-gray-400`} />
              </IconButton>
            </Modal>
          </div>
        )}
      </div>
    );
  } else {
    return false;
  }
};

export default Actions;
