import { Table } from "../../../../../../components/Functional";
import { useQuery } from "@apollo/client";
import { FETCH_CONTACT as fetchContact } from "../../../../../../graphql/query/Contact";
import { FETCH_CONTACT_ATTRIBUTES as fetchContactAttributes } from "../../../../../../graphql/query/ContactAttribute";
import SectionHeader from "../../../Wizard/components/SectionHeader";
import AddAttribute from "./components/AddAttribute";
import { Button, Modal } from "../../../../../../components/ui";
import { FETCH_CONTACT_ATTRIBUTE_TYPES } from "../../../../../../graphql/query/ContactAttributeType";
import Actions from "./components/Actions";
import useUser from "../../../../../../hooks/useUser";

const Attributes = props => {
  const { contactId } = props || {};
  const { data: { contactAttributeTypes = [] } = {} } = useQuery(
    FETCH_CONTACT_ATTRIBUTE_TYPES,
  );
  const { data } = useQuery(fetchContact(), { variables: { id: +contactId } });
  const { hasPermission } = useUser();
  const { contact } = data || {};

  const disabled = !hasPermission("EDIT_CONTACT_ATTRIBUTE");
  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "contactAttributes",
    headers: [
      { label: "Type", name: "type.description", type: "label" },
      {
        label: "Description",
        name: "description",
        type: "label",
        width: "800px",
      },
      {
        label: "Timestamp",
        name: "updatedAt",
        type: "date",
      },
      {
        label: "",
        name: "actions",
        type: "custom",
        component: Actions,
        contact,
      },
    ],
    inputs: [
      {
        label: "Type",
        name: "typeId",
        type: "multi-select",
        options: contactAttributeTypes?.map(item => ({
          label: item.description,
          value: item.id,
        })),
      },
      {
        label: "Description",
        name: "description",
        type: "text",
        view: "partial",
      },
    ],
    FETCH_QUERY: fetchContactAttributes({ type: {} }),
    variables: {
      contactId,
    },
    className: "sm:px-0 lg:px-0",
  };

  const modalProps = {
    body: AddAttribute,
    contact,
    disabled,
    hideCloseButton: true,
    scale: "md",
  };

  const attributesActions = [
    {
      type: "black",
      label: "ADD RECORD",
      action: () => null,
      component: () => (
        <Modal {...modalProps}>
          <Button label="Add Attribute" action="dark" disabled={disabled} />
        </Modal>
      ),
    },
  ];

  return (
    <div className="user-admin w-full">
      <SectionHeader title="Attributes" actions={attributesActions} />
      <div className="mb-6 w-full">
        <Table {...tableProps} />
      </div>
    </div>
  );
};

export default Attributes;
