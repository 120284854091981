import { gql } from "@apollo/client";
import { ContactLink as contactLink } from "../fragments";

const FETCH_CONTACT_LINKS = props => gql`
  query FetchContactLinks($input: ContactLinkFilterInput!) {
    contactLinks(input: $input) {
      count
      edges {
        ${contactLink(props)}
      }
    }
  }
`;

export { FETCH_CONTACT_LINKS };
