import React from "react";

const Tabs = props => {
  const { formik, name, options } = props || [];
  const value = formik?.values?.[name] || "";

  const handleChange = value => {
    formik?.setFieldValue(name, value);
  };

  const selected = options?.find(option => option?.value === value);
  return (
    <div className="flex flex-col gap-4">
      <div className="flex gap-4 border-b">
        {options?.map((item, index) => {
          const isActive = item?.value === value;
          return (
            <div
              className={`${
                isActive ? "border-b border-black text-black" : "text-gray-400"
              } cursor-pointer py-2 text-sm font-bold`}
              key={index}
              onClick={() => handleChange(item?.value)}
            >
              {item?.label}
            </div>
          );
        })}
      </div>
      {selected?.component}
    </div>
  );
};

export default Tabs;
