const Event = `
  id
  artistId
  endDate
  endTime
  name
  notes
  startDate
  startTime
  updatedAt
  venueId
`;

export default Event;
