import {
  Designer as ActiveDesigner,
  Viewer,
} from "@grapecity/activereports-react";
import { useRef, useState } from "react";

import "@grapecity/activereports/styles/ar-js-ui.css";
import "@grapecity/activereports/styles/ar-js-designer.css";
import "@grapecity/activereports/styles/ar-js-viewer.css";

import { onExport } from "../../../../../../components/Functional/ActiveReport";

import { dataSources, defaultReportDefinition } from "./resources";
import {
  invoices,
  consignments,
  instances,
  shipments,
  artworks,
  contacts,
  warehouseItems,
  jobs,
  offers,
} from "./previewData";

const Designer = props => {
  const { updateReportTemplate, formik } = props || {};
  const { reportTemplate } = formik?.values || {};
  const [designerVisible, setDesignerVisible] = useState(true);
  // const [reportStorage, setReportStorage] = useState(new Map())

  // const counter = useRef(0);
  const viewerRef = useRef();

  const onDocumentChanged = info => {
    const { isDirty, definition } = info || {};

    // const reportId = info.id || `report_${reportTemplate?.id}_${counter.current++}`;
    // setReportStorage(new Map(reportStorage.set(reportId, definition)))

    if (isDirty) {
      updateReportTemplate({
        variables: {
          input: {
            id: reportTemplate?.id,
            meta: { ...reportTemplate?.meta, definition },
          },
        },
      });
    }
  };

  // const onSave = function (info) {
  //   const reportId = info.id || `report${counter.current++}`;
  //   setReportStorage(new Map(reportStorage.set(reportId, info.definition)));
  //   return Promise.resolve({ displayName: reportId });
  // }

  // const onSaveAs = function (info) {
  //   const reportId = info.id || `report${counter.current++}`;
  //   setReportStorage(new Map(reportStorage.set(reportId, info.definition)));
  //   return Promise.resolve({ id: reportId, displayName: reportId });
  // }

  const exportsSettings = {
    pdf: {
      title: reportTemplate?.name,
      author: "WhiteCube",
      // subject: "Web Reporting",
      // keywords: "reporting, sample",
      // userPassword: "pwd",
      // ownerPassword: "ownerPwd",
      printing: "none",
      copying: false,
      modifying: false,
      annotating: false,
      contentAccessibility: false,
      documentAssembly: false,
      pdfVersion: "1.7",
      autoPrint: false,
      filename: `${reportTemplate?.name}`,
    },
    // html: {
    //   title: "ActiveReportsJS Sample",
    //   filename: "ActiveReportsJS-Sample.html",
    //   autoPrint: true,
    //   multiPage: true,
    //   embedImages: "external",
    //   outputType: "html",
    // },
  };

  // remove items from this array to hide unnecessary Exports Types
  const availableExports = ["pdf"];

  const updateToolbar = () => {
    const designButton = {
      key: "$openDesigner",
      text: "Edit in Designer",
      iconCssClass: "mdi mdi-pencil",
      enabled: true,
      action: () => {
        setDesignerVisible(true);
      },
    };

    // add export button
    const exportButton = {
      key: "$export",
      text: "Export",
      iconCssClass: "mdi mdi-download",
      enabled: true,
      action: () => {
        onExport({
          format: "pdf",
          title: reportTemplate?.name,
          definition:
            reportTemplate?.meta?.definition || defaultReportDefinition,
        });
      },
    };

    viewerRef.current.toolbar.addItem(designButton);
    viewerRef.current.toolbar.addItem(exportButton);
    viewerRef.current.toolbar.updateLayout({
      default: [
        "$openDesigner",
        "$export",
        "$split",
        "$navigation",
        "$split",
        "$refresh",
        "$split",
        "$history",
        "$split",
        "$zoom",
        "$fullscreen",
        "$split",
        "$print",
        "$split",
        "$singlepagemode",
        "$continuousmode",
        "$galleymode",
      ],
    });
  };

  const onReportPreview = report => {
    updateToolbar();
    setDesignerVisible(false);
    viewerRef.current.open(report.definition);
    return Promise.resolve();
  };

  return (
    <>
      <div
        id="viewer-host"
        style={{ display: designerVisible ? "none" : "block" }}
      >
        <Viewer
          ref={viewerRef}
          exportsSettings={exportsSettings}
          availableExports={availableExports}
        />
      </div>
      <div
        id="designer-host"
        style={{ display: designerVisible ? "block" : "none" }}
      >
        <ActiveDesigner
          dataSources={dataSources([
            {
              name: "Invoice",
              connectString:
                "jsondata=" + JSON.stringify({ invoice: invoices }),
            },
            {
              name: "Consignment",
              connectString:
                "jsondata=" + JSON.stringify({ consignment: consignments }),
            },
            {
              name: "Instance",
              connectString:
                "jsondata=" + JSON.stringify({ instance: instances }),
            },
            {
              name: "WarehouseItem",
              connectString:
                "jsondata=" + JSON.stringify({ warehouseitem: warehouseItems }),
            },
            {
              name: "Shipment",
              connectString:
                "jsondata=" + JSON.stringify({ shipment: shipments }),
            },
            {
              name: "Artwork",
              connectString:
                "jsondata=" + JSON.stringify({ artwork: artworks }),
            },
            {
              name: "Contact",
              connectString:
                "jsondata=" + JSON.stringify({ contact: contacts }),
            },
            {
              name: "Job",
              connectString: "jsondata=" + JSON.stringify({ job: jobs }),
            },
            {
              name: "Offer",
              connectString: "jsondata=" + JSON.stringify({ offer: offers }),
            },
          ])}
          documentChanged={onDocumentChanged}
          onRender={onReportPreview}
          // onSave={onSave}
          // onSaveAs={onSaveAs}
          report={{
            definition:
              reportTemplate?.meta?.definition || defaultReportDefinition,
            displayName: reportTemplate?.name,
          }}
        />
      </div>
    </>
  );
};

export default Designer;
