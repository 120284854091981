import { gql } from "@apollo/client";
import { ContactAttributeType } from "../fragments";

const FETCH_CONTACT_ATTRIBUTE_TYPES = gql`
  query FetchContactAttributeTypes {
    contactAttributeTypes {
      ${ContactAttributeType}
    }
  }
`;

export { FETCH_CONTACT_ATTRIBUTE_TYPES };
