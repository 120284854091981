import { useMutation } from "@apollo/client";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";

import AddAction from "./AddAction";
import { Button, IconButton, Modal } from "../../../../../../../components/ui";
import { DELETE_CRM_REGISTRAR } from "../../../../../../../graphql/mutation/CRMRegistrar";

const Actions = props => {
  const { id, contact, values } = props || {};
  const [deleteCRMRegistrar, { loading }] = useMutation(DELETE_CRM_REGISTRAR);

  const editModalProps = {
    body: AddAction,
    closeOnBackdrop: true,
    contact,
    crmRegistrar: values?.rawFields,
    hideCloseButton: true,
    scale: "md",
  };

  const deleteModalProps = {
    title: "Are you sure?",
    scale: "sm",
    description: `Would you like to delete action id '${values?.id}'?`,
    closeOnBackdrop: true,
    body: ({ closeModal }) => {
      const handleDelete = () => {
        deleteCRMRegistrar({
          variables: {
            input: { id: +values?.rawFields?.id },
          },
          update: (cache, { data }) => {
            const { deleteCRMRegistrar: { success } = {} } = data;
            if (success) {
              closeModal();
              cache.evict({
                id: cache.identify({
                  __typename: "CRMRegistrar",
                  id,
                }),
              });
            }
          },
        });
      };

      return (
        <div className="mt-4 flex">
          <Button
            className="mr-3"
            label={"Cancel"}
            action={"default"}
            onClick={() => {
              closeModal?.();
            }}
          />
          <Button
            label={loading ? "Deleting" : "Delete"}
            disabled={loading}
            onClick={handleDelete}
          />
        </div>
      );
    },
  };

  if (typeof id == "number") {
    return (
      <div className="user-list-actions flex text-right text-sm font-medium">
        {id && (
          <div className="grid grid-cols-4 gap-4">
            <Modal {...editModalProps}>
              <IconButton variant="clean" title="Edit Action">
                <PencilIcon
                  className={`h-4 w-4 cursor-pointer text-gray-400`}
                />
              </IconButton>
            </Modal>
            {/* <IconButton variant="clean" title="View Action">
              <EyeIcon className={`h-4 w-4 cursor-pointer text-gray-400`} onClick={handleDetail} />
            </IconButton> */}
            <Modal {...deleteModalProps}>
              <IconButton variant="clean" title="Delete Action">
                <TrashIcon className={`h-4 w-4 cursor-pointer text-gray-400`} />
              </IconButton>
            </Modal>
          </div>
        )}
      </div>
    );
  } else {
    return false;
  }
};

export default Actions;
