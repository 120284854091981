import { gql } from "@apollo/client";
import { BankAccount as bankAccount } from "../fragments";

const FETCH_BANK_ACCOUNTS = props => gql`
  query FetchBankAccounts($input : BankAccountsFilterInput) {
    bankAccounts(input: $input) {
      ${bankAccount(props)}
    }
  }
`;

export { FETCH_BANK_ACCOUNTS };
