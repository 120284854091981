import { gql } from "@apollo/client";

const UPLOAD_INSTANCE_DOCUMENTS = gql`
  mutation UploadInstanceDocuments($instanceId: Int!, $files: [Upload]!) {
    uploadInstanceDocuments(instanceId: $instanceId, files: $files) {
      success
      error
      response
    }
  }
`;

export { UPLOAD_INSTANCE_DOCUMENTS };
