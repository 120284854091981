import { useMutation } from "@apollo/client";
import { CheckIcon } from "@heroicons/react/24/solid";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Table } from "../../../../../../components/Functional";
import { FETCH_BIBLIOGRAPHY_V2 } from "../../../../../../graphql/query/Bibliography";
import { UPDATE_BIBLIOGRAPHY } from "../../../../../../graphql/mutation/Bibliography";
import { validateDate } from "../../../../../../util";
import SectionHeader from "../../components/SectionHeader";
import AddBibliography from "./components/AddBibliography";
import { Button, getInput, Modal } from "../../../../../../components/ui";
import Actions from "./components/Actions";

const IllustratedComponent = props => {
  // show check icon if illustrated is -1
  return (
    <div className="flex items-center justify-center">
      {props?.values?.illustrated === -1 && (
        <CheckIcon className="h-5 w-5" aria-hidden="true" />
      )}
    </div>
  );
};

const DateComponent = props => {
  const { day, month, year } = props?.values || {};
  const [updateBibliography] = useMutation(UPDATE_BIBLIOGRAPHY);

  const formik = useFormik({
    initialValues: {
      day,
      month,
      year,
    },
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: Yup.object({
      day: Yup.number()
        .typeError("Invalid val")
        .test("valid-date", "Invalid val", function (value, context) {
          const { day, month, year } = context.parent;

          return validateDate(day, month, year);
        }),
      month: Yup.number()
        .min(1, "Invalid val")
        .max(12, "Invalid val")
        .typeError("Invalid val"),
      year: Yup.number()
        .typeError("Invalid val")
        .min(1000, "Invalid val")
        .max(9999, "Invalid val"),
    }),
    onSubmit: values => {
      updateBibliography({
        variables: {
          input: {
            id: props?.values?.id,
            ...values,
          },
        },
      });
    },
  });

  const inputs = [
    {
      name: "day",
      type: "number",
      placeholder: "DD",
      className: "w-16",
      submitOnBlur: true,
    },
    {
      name: "month",
      type: "number",
      placeholder: "MM",
      className: "w-16",
      submitOnBlur: true,
    },
    {
      name: "year",
      type: "number",
      placeholder: "YYYY",
      className: "w-20",
      submitOnBlur: true,
    },
  ];

  return (
    <div className="flex">
      {inputs?.map((input, index) => {
        const inputProps = {
          formik,
          ...input,
        };
        return (
          <div key={index} className="mr-2">
            {getInput(inputProps)}
          </div>
        );
      })}
    </div>
  );
};

const Bibliography = props => {
  const { artworkId, artwork } = props || {};
  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "bibliographysV2",
    headers: [
      { label: "Title", name: "title", type: "label" },
      { label: "Publication", name: "publication", type: "label" },
      {
        label: "Illustrated",
        name: "illustrated",
        type: "custom",
        component: IllustratedComponent,
      },
      { label: "Date", type: "custom", component: DateComponent },
      {
        label: "",
        name: "actions",
        type: "custom",
        component: Actions,
        artwork,
      },
    ],
    FETCH_QUERY: FETCH_BIBLIOGRAPHY_V2,
    variables: {
      _artwork: {
        id: {
          operator: "eq",
          value: artworkId,
        },
      },
    },
    className: "sm:px-0 lg:px-0",
  };

  const modalProps = {
    body: AddBibliography,
    artworkId,
    hideCloseButton: true,
    scale: "md",
  };

  const bibliographyActions = [
    {
      type: "black",
      label: "ADD RECORD",
      action: () => null,
      component: () => (
        <Modal {...modalProps}>
          <Button label="Add Bibliography" action="dark" />
        </Modal>
      ),
    },
  ];

  return (
    <div className="w-full">
      <SectionHeader title="Bibliography" actions={bibliographyActions} />
      <div className="mb-6 w-full">
        <Table {...tableProps} />
      </div>
    </div>
  );
};

export default Bibliography;
