import { Table } from "../../../../../../components/Functional";
import { Modal, Button } from "../../../../../../components/ui";
import AddDocuments from "./components/AddDocuments";
import Actions from "./components/Actions";
import { FETCH_SHIPMENT_DOCUMENTS } from "../../../../../../graphql/query/ShipmentDocument";
import Header from "./components/Header";
import SectionHeader from "../../components/SectionHeader";

const ShipmentDocuments = props => {
  const { shipmentId, shipment, formik } = props || {};

  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "shipmentDocuments",
    headers: [
      { label: "File Name", name: "fileName", type: "label" },
      { label: "File Type", name: "fileType", type: "label" },
      { label: "Date", name: "updatedAt", type: "date" },
      { label: "", name: "action", type: "custom", component: Actions },
    ],
    inputs: [{ label: "Name", name: "name", type: "text", view: "partial" }],
    FETCH_QUERY: FETCH_SHIPMENT_DOCUMENTS,
    variables: {
      shipmentId: +shipmentId,
    },
    className: "sm:px-0 lg:px-0",
  };

  const modalProps = {
    body: AddDocuments,
    closeOnBackdrop: true,
    shipment,
    hideCloseButton: true,
    scale: "md",
  };

  const actions = [
    {
      component: () => {
        return (
          <Modal {...modalProps}>
            <Button label="Upload" action="black" />
          </Modal>
        );
      },
    },
  ];

  return (
    <div className="w-full">
      <SectionHeader title="Documents" actions={actions} />
      <Header formik={formik} submitOnBlur={true} />
      <div className="mb-6 w-full">
        <Table {...tableProps} />
      </div>
    </div>
  );
};

export default ShipmentDocuments;
