import { Button, Modal } from "../../ui";

const ExitEditMode = props => {
  const { onSuccess } = props || {};

  const handleClick = closeModal => e => {
    e.preventDefault();
    onSuccess?.();
    closeModal();
  };

  const modalProps = {
    title: "",
    scale: "sm",
    hideCloseButton: true,
    closeOnBackdrop: false,
    body: ({ closeModal }) => {
      return (
        <div className={`p-4`}>
          <div className={`flex flex-row items-center justify-between`}>
            <div className={`text-lg`}>Exit Edit Mode</div>
            <div className={`flex gap-4`}>
              <Button
                className={`!w-22`}
                action="default"
                label="Cancel"
                onClick={closeModal}
              />
              <Button
                className={`!w-22 flex items-center`}
                label="Exit"
                onClick={handleClick(closeModal)}
              />
            </div>
          </div>
          <div className="mt-8 text-sm font-normal">
            All the changes were auto-saved. <b>Exit Edit?</b>
          </div>
        </div>
      );
    },
  };

  return (
    <Modal {...modalProps}>
      <Button label="Exit Edit Mode" />
    </Modal>
  );
};

export default ExitEditMode;
