import Artist from "./Artist";
import artworkFrag from "./Artwork";

const ArtistArtwork = ({ artist = false, artwork = false } = {}) => `
  id
  ${
    artist
      ? `
    artist {
      ${Artist}
    }`
      : ""
  }
  artistId
  ${
    artwork
      ? `
    artwork {
      ${artworkFrag(artwork)}
    }`
      : ""
  }
  artworkId
  createdAt
  online
`;

export default ArtistArtwork;
