import { gql } from "@apollo/client";
import { Item as item } from "../fragments";

const CREATE_ITEM = props => gql`
  mutation CreateItem($input: CreateItemInput!) {
    createItem(input: $input) {
      item {
        ${item(props)}
      }
      success
      error
    }
  }
`;
const CREATE_ITEM_CREDIT = props => gql`
  mutation CreateItemCredit($input: CreateItemCreditInput!) {
    createItemCredit(input: $input) {
      item {
        ${item(props)}
      }
      success
      error
    }
  }
`;

const EXPORT_SAGE_CSV_TRANSACTIONS = gql`
  mutation ExportSageCsvTransactions($input: ExportSageCsvInput!) {
    exportSageCsvTransactions(input: $input) {
      success
      url
      error
    }
  }
`;

const EXPORT_SAGE_CSV_ACCOUNTS = gql`
  mutation ExportSageCsvAccounts($input: ExportSageCsvInput!) {
    exportSageCsvAccounts(input: $input) {
      success
      url
      error
    }
  }
`;

const UPDATE_ITEM = props => gql`
  mutation UpdateItem($input: UpdateItemInput!) {
    updateItem(input: $input) {
      item {
        ${item(props)}
      }
      success
      error
    }
  }
`;

const DELETE_ITEM = gql`
  mutation DeleteItem($input: DeleteItemInput!) {
    deleteItem(input: $input) {
      success
      error
    }
  }
`;

export {
  CREATE_ITEM,
  CREATE_ITEM_CREDIT,
  EXPORT_SAGE_CSV_TRANSACTIONS,
  EXPORT_SAGE_CSV_ACCOUNTS,
  UPDATE_ITEM,
  DELETE_ITEM,
};
