import { gql } from "@apollo/client";
import { Role } from "../fragments";

const FETCH_ROLE = gql`
  query FetchRole($id: Int!) {
    role(id: $id) {
     ${Role}
    }
  }
`;

const FETCH_ROLES = gql`
  query FetchRoles($input: RoleFilterInput) {
    roles(input: $input) {
      count
      edges {
        ${Role}
      }
    }
  }
`;

const FETCH_DEFAULT_PERMISSION_SET = gql`
  query FetchDefaultPermissionSet {
    defaultPermissionSet
  }
`;

export { FETCH_DEFAULT_PERMISSION_SET, FETCH_ROLE, FETCH_ROLES };
