import DocumentPage from "./DocumentPage";

const document = ({ fieldsData = false, pages = false } = {}) => `
  id
  createdAt
  ${
    fieldsData
      ? `fieldsData {
      artist_name
      artwork_title
      image
      instance_code
    }`
      : ""
  }
  meta
  owner
  ${
    pages
      ? `pages { 
      ${DocumentPage} 
    }`
      : ""
  }
  status
  title
  updatedAt
`;

export default document;
