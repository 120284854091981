import { useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import * as Yup from "yup";

import { GridInputs, Table } from "../../../../../../../components/Functional";
import { Button, getInput, Modal } from "../../../../../../../components/ui";

import { FETCH_ARTISTS as fetchArtists } from "../../../../../../../graphql/query/Artist";
import { CREATE_CONSIGNMENT_WORK as createConsignmentWorkMutation } from "../../../../../../../graphql/mutation/ConsignmentWork";
import { FETCH_COMPANIES as fetchCompanies } from "../../../../../../../graphql/query/Company";
import { FETCH_INSTANCES_V2 as fetchInstancesV2 } from "../../../../../../../graphql/query/Instance";
import { convertToFilter } from "../../../../../../../util";
import { ValidWorkStatus } from "./ValidWorkStatus";
import useUser from "../../../../../../../hooks/useUser";

const FilterBar = props => {
  const { formik } = props || {};
  const { data: { artists = [] } = {} } = useQuery(fetchArtists());
  const { data: { companies = [] } = {} } = useQuery(fetchCompanies());

  const inputs = {
    className: "grid-cols-5 gap-4",
    inputs: [
      {
        label: "Company",
        name: "companyId",
        type: "multi-select",
        options: companies?.edges?.map(item => ({
          label: item?.name,
          value: item?.id,
        })),
      },
      { label: "Control Reference", name: "ref", type: "text" },
      {
        label: "Artist",
        name: "_edition._artwork._artist.id",
        type: "multi-select",
        options: artists?.map(item => ({ label: item?.name, value: item?.id })),
      },
      { label: "Title", name: "_edition._artwork.title", type: "text" },
      { label: "Edition #", name: "editionNumber", type: "text" },
      { label: "Name", name: "name", type: "text" },
      { label: "Artist Ref", name: "artistRef", type: "text" },
      { label: "Series", name: "_edition._artwork.series", type: "text" },
      {
        designType: "threeStateCheckbox",
        label: "Photographed",
        name: "_edition._artwork.photographed",
        type: "toggle",
      },
    ],
  };

  const inputProps = {
    formik,
    ...inputs,
  };

  return <GridInputs {...inputProps} />;
};

const AddWorkModal = props => {
  const { closeModal, consignment } = props;
  const [createConsignmentWork, { loading, error, reset }] = useMutation(
    createConsignmentWorkMutation({ user: {} }),
    { refetchQueries: ["FetchConsignmentWorks", "FetchConsignment"] },
  );

  const handleClick = () => {
    const { instanceId } = formik?.values || {};

    createConsignmentWork({
      variables: {
        input: {
          consignmentId: consignment?.id,
          instanceId,
        },
      },
    }).then(resp => {
      const { data: { createConsignmentWork: { success } = {} } = {} } = resp;

      if (success) {
        resetAll();
      }
    });
  };

  const formik = useFormik({
    initialValues: {},
    onSubmit: handleClick,
  });

  const filterFormik = useFormik({
    initialValues: {},
    validationSchema: Yup.object({
      editionNumber: Yup.number()
        .nullable()
        .integer()
        .positive()
        .typeError("Invalid input"),
    }),
  });

  const toggleProps = props => {
    const { id, name } = props || {};

    return {
      type: "toggle",
      name,
      ...formik.getFieldProps(name),
      value: formik?.values?.[name] === id,
      onChange: value => {
        formik.getFieldHelpers(name).setValue(value ? id : null);
      },
    };
  };

  const EditionNumber = ({ values }) => {
    if (values?.edition?.number === 1 || !values?.editionNumber) return null;
    return (
      <div className="flex flex-col">
        <span className="text-base font-medium leading-5 text-black">{`${values?.editionNumber}/${values?.edition?.number}`}</span>
      </div>
    );
  };

  const resetAll = () => {
    formik?.resetForm();
    reset?.();
    filterFormik?.resetForm();
    closeModal?.();
  };

  const tableProps = {
    dataKey: "instancesV2",
    FETCH_QUERY: fetchInstancesV2({
      edition: { artwork: { artists: {}, images: { imgT: {} } } },
      status: {},
    }),
    headers: [
      {
        name: "instanceId",
        type: "custom",
        component: props => getInput(toggleProps(props)),
      },
      {
        label: "Artwork Image",
        name: "edition.artwork.images[0].imgT",
        type: "image",
      },
      { label: "Reference", name: "name", type: "label" },
      {
        label: "Title",
        name: "edition.artwork.title",
        type: "label",
        widthInPx: 400,
      },
      {
        label: "Artist Name",
        name: "edition.artwork.artists.[0].name",
        type: "label",
      },
      { label: "Year", name: "edition.year", type: "label" },
      {
        label: "Edition",
        name: "editionNumber",
        type: "custom",
        component: EditionNumber,
      },
      { label: "Status", name: "status.name", type: "label" },
    ],
    inputs: [],
    limit: 10,
    skip: () => !filterFormik?.isValid,
    variables: {
      statusId: {
        operator: "in",
        value: ValidWorkStatus(consignment),
      },
      ...convertToFilter({
        values: filterFormik?.values,
        skipKeys: ["limit", "offset"],
      }),
    },
    pagination: {
      variant: "sm",
    },
    variant: "dynamicHeight",
  };

  if (error) {
    return (
      <div className="mt-6 flex w-full flex-col">
        <div className="flex flex-row">
          <div className="flex flex-1 flex-col">
            <div className="text-2xl font-bold">Error adding work</div>
          </div>
          <div className="flex flex-col">
            <div>
              <Button
                action="default"
                className="mr-4"
                label="Cancel"
                onClick={() => {
                  resetAll();
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="py-6">{error?.message}</div>
        </div>
      </div>
    );
  }

  return (
    <div className="work-error mt-6 flex w-full flex-col">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col">
          <div className="text-2xl font-bold">Add Work: Select an Instance</div>
        </div>
        <div className="mb-5 flex flex-col">
          <div>
            <Button
              action="default"
              className="mr-4"
              label="Cancel"
              onClick={() => {
                resetAll();
              }}
            />
            <Button
              label={loading ? "Adding" : "Add"}
              disabled={loading || !formik?.values?.instanceId}
              onClick={formik?.submitForm}
            />
          </div>
        </div>
      </div>
      <FilterBar formik={filterFormik} />
      <Table {...tableProps} />
    </div>
  );
};

const AddWork = props => {
  const { hasPermission } = useUser();
  const { consignment } = props || {};

  const modalProps = {
    body: AddWorkModal,
    closeOnBackdrop: false,
    consignment,
    hideCloseButton: true,
    scale: "md",
    disabled: !hasPermission("ADD_CONSIGNMENT_ITEM"),
  };

  return (
    <Modal {...modalProps}>
      <Button
        label="Add Work"
        action="black"
        disabled={!hasPermission("ADD_CONSIGNMENT_ITEM")}
      />
    </Modal>
  );
};

export default AddWork;
