import { useQuery } from "@apollo/client";
import { GridInputs } from "../../../../../components/Functional";
import { FETCH_CONSIGNMENTS_TYPES } from "../../../../../graphql/query/ConsignmentType";
import { useEffect } from "react";

const Consignments = props => {
  const { formik } = props || {};
  const { data: { consignmentTypes = [] } = {} } = useQuery(
    FETCH_CONSIGNMENTS_TYPES,
  );

  useEffect(() => {
    if (formik?.values?.secondaryMarket) {
      formik?.setFieldValue("_consignmentWork._consignment.typeId", [
        {
          operator: "eq",
          value: 1,
        },
        {
          operator: "eq",
          value: 3,
        },
      ]);
    } else if (formik?.values?.secondaryMarket === false) {
      formik?.setFieldValue("_consignmentWork._consignment.typeId", null);
    }
  }, [formik?.values?.secondaryMarket]);

  const inputs = {
    className: "grid grid-cols-4 gap-16 mt-5",
    inputs: [
      {
        className: "flex flex-col gap-4",
        inputs: [
          {
            label: "Type",
            name: "_consignmentWork._consignment.typeId",
            type: "multi-select",
            isMulti: false,
            options: consignmentTypes?.map(type => ({
              label: type.description,
              value: type.id,
            })),
          },
          {
            label: "#",
            name: "_consignmentWork._consignment.id",
            type: "number",
          },
        ],
      },
      {
        className: "flex flex-col gap-4",
        inputs: [
          {
            label: "Date - From",
            name: "_consignmentWork._consignment.date.value.from",
            type: "date",
          },
          {
            label: "Date - To",
            name: "_consignmentWork._consignment.date.value.to",
            type: "date",
          },
        ],
      },
      {
        className: "flex flex-col gap-4",
        inputs: [
          {
            label: "Due - From",
            name: "_consignmentWork._consignment.due.value.from",
            type: "date",
          },
          {
            label: "Due - To",
            name: "_consignmentWork._consignment.due.value.to",
            type: "date",
          },
        ],
      },
      {
        className: "flex flex-col gap-4",
        inputs: [
          {
            designType: "checkbox",
            label: "Secondary Market",
            name: "secondaryMarket",
            type: "toggle",
          },
        ],
      },
    ],
  };

  const gridInputProps = {
    formik,
    ...inputs,
  };

  return <GridInputs {...gridInputProps} />;
};

export default Consignments;
