import { useFormik } from "formik";
import { useEffect, useState } from "react";
import {
  Button,
  Pagination,
  SearchBar,
} from "../../../../../../../components/ui";
import Table from "../../../../../../../components/Table";
import { useQuery } from "@apollo/client";
import { useDebounce } from "../../../../../../../util";
import { FETCH_ENTITIES } from "../../../../../../../graphql/query/Entity";

const TableCheck = props => {
  const { values, selectedValue, onChange } = props;
  return (
    <div className="relative flex w-[40px] items-start" key={values.id}>
      <div className="flex h-5 items-center">
        <input
          id={values.id}
          value={values.id}
          checked={selectedValue?.id === values.id}
          aria-describedby="comments-description"
          name="id"
          type="checkbox"
          className="h-4 w-4 rounded border-2 border-gray-800 text-gray-800 focus:ring-0"
          onChange={() => onChange(values)}
        />
      </div>
    </div>
  );
};

const SalesUserComponent = props => {
  const {
    closeModal,
    userName,
    activeSalesUser,
    isOpen,
    loading,
    updateContact,
    isPrimarySalesUser,
  } = props;
  const [filters, setFilters] = useState({});
  const [showConfirmation, handleShowConfirmation] = useState(false);
  const [selectedValue, handleSelectedValue] = useState(null);
  const debouncedFilters = useDebounce(filters, 300);
  const skip = Object.keys(debouncedFilters).length === 0;
  const { data: { entities: { edges = [], count } = {} } = {} } = useQuery(
    FETCH_ENTITIES,
    { skip, variables: { input: { ...debouncedFilters } } },
  );
  const limit = 5;
  const initialValues = { search: "", limit };
  const formikSearch = useFormik({
    initialValues,
  });
  const onChange = values => {
    setFilters({ ...filters, ...values });
  };
  const confirmAction = () => {
    if (showConfirmation) {
      const key = isPrimarySalesUser ? "salesUserId" : "salesUser2Id";
      updateContact({ [key]: selectedValue.id }, closeModal);
    } else {
      handleShowConfirmation(true);
    }
  };
  const searchBarProps = {
    name: "search",
    placeholder: "Search",
    formik: formikSearch,
    variant: "simple",
  };

  const paginationProps = {
    count,
    limit,
    offset: filters?.offset,
    showSteps: false,
    onChange,
    variant: "simple",
  };

  const handleOnClick = value => {
    handleSelectedValue(value);
  };

  const tableProps = {
    headers: [
      {
        label: "",
        width: "100px",
        name: "checkbox",
        type: "custom",
        component: props => (
          <TableCheck
            {...props}
            selectedValue={selectedValue}
            onChange={handleOnClick}
          />
        ),
      },
      { label: "Sales Contact Name", name: "name", type: "label" },
    ],
    limit,
    loading,
    rows: edges,
    variant: "md",
  };

  useEffect(() => {
    onChange(formikSearch.values);
  }, [formikSearch.values]);

  useEffect(() => {
    if (isOpen) {
      handleSelectedValue(activeSalesUser || null);
      setFilters(initialValues);
      handleShowConfirmation(false);
    }
  }, [isOpen, activeSalesUser]);
  return (
    <div className="flex w-full flex-col p-8">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          <div>
            Add {isPrimarySalesUser ? "Primary Sales" : "2nd Sales"} Contact to{" "}
            {userName}
          </div>
          <div className="pt-4 text-base font-normal">
            This user will have full access to the contact record
          </div>
        </div>
        <div className="flex flex-col">
          <div>
            <Button
              action="default"
              className="mr-8"
              label="Cancel"
              onClick={() => {
                closeModal();
              }}
            />
            <Button
              label={showConfirmation ? "CONFIRM" : loading ? "SAVING" : "SAVE"}
              disabled={loading || !selectedValue}
              onClick={confirmAction}
            />
          </div>
        </div>
      </div>
      <div className="pt-12">
        {showConfirmation ? (
          <div className="font-bold">
            {`Confirm: You are about to assign "${selectedValue.name}" full access to 
            ${userName} contact record and grant them priviliges as a secondary
            sales contact.`}
          </div>
        ) : (
          <>
            <div className="grid grid-cols-2 gap-10 pb-12">
              <div>
                <SearchBar {...searchBarProps} />
              </div>
            </div>
            <Table {...tableProps} />
            <div>
              <Pagination {...paginationProps} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SalesUserComponent;
