import { gql } from "@apollo/client";
import { FabricationCategory } from "../fragments";

const FETCH_FABRICATION_CATEGORIES = gql`
  query FetchFabricationCategories {
    fabricationCategories {
      ${FabricationCategory}
    }
  }
`;

export { FETCH_FABRICATION_CATEGORIES };
