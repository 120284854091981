import { gql } from "@apollo/client";
import { ReportTemplate } from "../fragments";

const FETCH_REPORT_TEMPLATE = gql`
  query FetchReportTemplate($id: Int!) {
    reportTemplate(id: $id) {
      ${ReportTemplate}
    }
  }
`;

const FETCH_REPORT_TEMPLATES = gql`
  query FetchReportTemplates($input: ReportTemplateFilterInput!) {
    reportTemplates(input: $input) {
      count
      edges {
        ${ReportTemplate}
      }
    }
  }
`;
export { FETCH_REPORT_TEMPLATE, FETCH_REPORT_TEMPLATES };
