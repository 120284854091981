import { useQuery, useMutation } from "@apollo/client";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import * as Yup from "yup";

import { FETCH_CONTACT_NUMBER_TYPES } from "../../../../../../../../../graphql/query/ContactNumberType";
import {
  CREATE_CONTACT_NUMBER as createContactNumberMutation,
  UPDATE_CONTACT_NUMBER as updateContactNumberMutation,
} from "../../../../../../../../../graphql/mutation/ContactNumber";

import { Button, getInput } from "../../../../../../../../../components/ui";

const AddMethodModal = props => {
  const { closeModal, contact, contactNumber = {}, isOpen } = props;

  const [createContactNumber, { loading: loadingCreate }] = useMutation(
    createContactNumberMutation(),
    { refetchQueries: ["FetchContact"] },
  );
  const [updateContactNumber, { loading: loadingUpdate }] = useMutation(
    updateContactNumberMutation(),
  );

  const { data: { contactNumberTypes = [] } = {} } = useQuery(
    FETCH_CONTACT_NUMBER_TYPES,
  );

  const confirmAction = () => {
    const { values } = formik;

    const variables = {
      input: {
        ...values,
        ...(contactNumber?.id
          ? { id: contactNumber?.id }
          : { contactId: contact?.id }),
      },
    };
    const action = contactNumber?.id
      ? updateContactNumber
      : createContactNumber;

    action({ variables }).then(() => {
      closeModal();
    });
  };

  const inputArr = [
    {
      fields: [
        {
          label: "Type",
          name: "typeId",
          options: contactNumberTypes?.map(item => ({
            label: item?.description,
            value: item?.id,
          })),
          placeholder: "Select a type",
          type: "select",
        },
        {
          label: "Name / Location",
          name: "identifier",
          placeholder: "Enter Name / Location",
          type: "text",
        },
      ],
    },
    {
      fields: [
        {
          label: "Number / URL",
          name: "number",
          placeholder: "Enter Number / URL",
          type: "text",
        },
        {
          name: "prefere",
          options: [{ label: "Preferred", value: -1 }],
          singleCheckbox: true,
          type: "checkbox",
        },
        {
          name: "donotcall",
          options: [{ label: "Do Not Contact", value: -1 }],
          singleCheckbox: true,
          type: "checkbox",
        },
      ],
    },
  ];

  const initialValues = {};

  inputArr?.forEach(inputs => {
    inputs?.fields?.forEach(field => {
      if (contactNumber[field?.name]) {
        initialValues[field.name] = contactNumber[field.name];
      }
    });
  });

  const formik = useFormik({
    initialValues: { ...initialValues } || {},
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: Yup.object({
      identifier: Yup.string().trim().required("Please enter a name."),
      number: Yup.string().trim().required("Please enter a number."),
      typeId: Yup.number().required("Please select a type."),
    }),
    onSubmit: confirmAction,
  });

  useEffect(() => {
    formik.resetForm();
  }, [isOpen]);

  return (
    <div className="flex w-full flex-col p-8">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          {`${formik.values?.id ? "Edit" : "Add"}`} Method for {contact?.name}
        </div>
        <div className="flex flex-col">
          <div>
            <Button
              action="default"
              className="mr-8"
              label="Cancel"
              onClick={() => {
                closeModal();
              }}
            />
            <Button
              label={loadingUpdate || loadingCreate ? "Confirming" : "Confirm"}
              disabled={loadingUpdate || loadingCreate}
              onClick={formik.submitForm}
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-x-20 pb-10 pt-20">
        {inputArr?.map((inputs, index) => {
          return (
            <div key={index} className="flex flex-col">
              {inputs?.label && (
                <div className="mb-8 text-xl font-bold">{inputs?.label}</div>
              )}
              <div className="flex flex-col gap-y-4">
                {inputs?.fields?.map((item, index) => {
                  const itemProps = {
                    ...item,
                    formik,
                  };
                  return (
                    <React.Fragment key={index}>
                      {getInput(itemProps)}
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AddMethodModal;
