import artistFrag from "./Artist";
import Entity from "./Entity";
import RequestType from "./RequestType";

const Request = ({ artist = false, salesUser = false, type = false } = {}) => `
  id
  ${
    artist
      ? `artist {
      ${artistFrag(artist)}
    }`
      : ""
  }
  budgetFrom
  budgetTo
  contactId
  date
  dateClosed
  description
  notes
  ${
    salesUser
      ? `salesUser {
      ${Entity}
    }`
      : ""
  }
  salesUserId
  ${
    type
      ? `type {
      ${RequestType}
    }`
      : ""
  }
  typeId
`;

export default Request;
