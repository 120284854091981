import { useFormik } from "formik";
import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import Table from "../../../Table";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

import { getChangedFields, useDebounce } from "../../../../util";
import { Button, IconButton, Modal, Pagination, SearchBar } from "../..";
import {
  FETCH_EXHIBITION as fetchExhibition,
  FETCH_EXHIBITIONS as fetchExhibitions,
} from "../../../../graphql/query/Exhibition";

const LIMIT = 5;
const initialValues = { search: "", limit: LIMIT };

const TableCheck = props => {
  const { values, selectedValue, onChange } = props;
  return (
    <div className="relative flex w-[40px] items-start" key={values.id}>
      <div className="flex h-5 items-center">
        <input
          id={values.id}
          value={values.id}
          checked={selectedValue?.id === values.id}
          aria-describedby="comments-description"
          name="id"
          type="checkbox"
          className="h-4 w-4 rounded border-2 border-gray-800 text-gray-800 focus:ring-0"
          onChange={() => onChange(values)}
        />
      </div>
    </div>
  );
};

const ArtfairSearchInput = props => {
  const {
    disabled,
    error,
    excludeRemoved,
    label,
    loading,
    onChange: setValue,
    value,
  } = props;

  if (excludeRemoved) {
    initialValues.exhibitionWorkRemoved = 0;
  }

  const [filters, setFilters] = useState(initialValues);
  const [selectedValue, setSelectedValue] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const debouncedFilters = useDebounce(filters, 300);

  const skip = Object.keys(debouncedFilters).length === 0;

  const {
    data: { exhibitions: { edges = [], count } = {} } = {},
    loading: fetching,
  } = useQuery(fetchExhibitions(), {
    skip,
    variables: { input: { ...debouncedFilters, type: "Artfair" } },
  });

  const { data: { exhibition = {} } = {}, client } = useQuery(
    fetchExhibition(),
    { skip: !value, variables: { id: value } },
  );

  useEffect(() => {
    if (!value) {
      setSelectedValue(null);
      client.writeQuery({
        query: fetchExhibition(),
        data: {},
      });
    } else {
      setSelectedValue({ id: value });
    }
  }, [value]);

  const formikSearch = useFormik({
    initialValues,
  });

  const onChange = values => {
    setFilters(prevState => ({ ...prevState, ...values }));
  };

  const handleSave = () => {
    setValue?.(selectedValue?.id || null);
  };

  const searchBarProps = {
    name: "search",
    placeholder: "Search",
    formik: formikSearch,
    variant: "simple",
  };

  const paginationProps = {
    count,
    limit: LIMIT,
    offset: filters?.offset,
    showSteps: false,
    onChange,
    variant: "simple",
  };

  const handleOnClick = value => {
    value?.id === selectedValue?.id
      ? setSelectedValue(null)
      : setSelectedValue(value);
  };

  const tableProps = {
    headers: [
      {
        label: "",
        width: "100px",
        name: "checkbox",
        type: "custom",
        component: props => (
          <TableCheck
            {...props}
            selectedValue={selectedValue}
            onChange={handleOnClick}
          />
        ),
      },
      { label: label, name: "title", type: "label" },
      { label: "Notes", name: "notes", type: "label" },
    ],
    limit: LIMIT,
    loading: fetching,
    rows: edges,
    variant: "md",
  };

  useEffect(() => {
    onChange(formikSearch?.values);
    const changedFields = getChangedFields(
      formikSearch?.values,
      formikSearch?.initialValues,
    );
    if (Object.keys(changedFields).length > 0) {
      setFilters(prevState => ({ ...prevState, offset: 0 }));
    }
  }, [formikSearch?.values]);

  const modalProps = {
    scale: "md",
    hideCloseButton: true,
    closeOnBackdrop: true,
    disabled,
    onOpen: () => {
      setShowConfirmation(false);
    },
    body: ({ closeModal }) => {
      return (
        <div className="flex w-full flex-col p-8">
          <div className="flex flex-row">
            <div className="flex flex-1 flex-col text-2xl font-bold">
              <div>Select Artfairs</div>
            </div>
            <div className="flex flex-col">
              <div>
                <Button
                  action="default"
                  className="mr-8"
                  label="Cancel"
                  onClick={() => {
                    closeModal();
                    setSelectedValue(exhibition);
                  }}
                />
                <Button
                  action="default"
                  className="mr-8"
                  disabled={!selectedValue}
                  label="Clear Selection"
                  onClick={() => {
                    setSelectedValue(null);
                  }}
                />
                <Button
                  label={
                    showConfirmation ? "CONFIRM" : loading ? "SAVING" : "SAVE"
                  }
                  disabled={loading || fetching || (!value && !selectedValue)}
                  onClick={() => {
                    closeModal();
                    handleSave();
                  }}
                />
              </div>
            </div>
          </div>
          <div className="pt-12">
            {showConfirmation ? (
              <div className="font-bold">
                You are about to change the {label}.
              </div>
            ) : (
              <>
                <div className="grid grid-cols-2 gap-10 pb-12">
                  <div>
                    <SearchBar {...searchBarProps} />
                  </div>
                </div>
                <Table {...tableProps} />
                <div>
                  <Pagination {...paginationProps} />
                </div>
              </>
            )}
          </div>
        </div>
      );
    },
  };

  return (
    <Modal {...modalProps}>
      <div className={`flex flex-1 flex-col`}>
        <div className={`block text-xs font-medium text-black`}>{label}</div>
        <div
          className={`mt-1 flex h-[38px] w-full items-center rounded border border-black focus:border-black focus:ring-0 ${disabled ? "bg-gray-100" : ""}`}
        >
          <div className={` flex-1 px-3 py-2 text-base `}>
            {exhibition?.title}
          </div>
          <IconButton variant="clean" title="Search Art Fair">
            <MagnifyingGlassIcon
              className={`h-5 w-5 cursor-pointer text-gray-400`}
            />
          </IconButton>
        </div>
        {error && <small className="text-sm text-red-600">{error}</small>}
      </div>
    </Modal>
  );
};

export default ArtfairSearchInput;
