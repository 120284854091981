import { useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import {
  useNavigate,
  useParams,
  Route,
  Routes as RouterRoutes,
  useLocation,
} from "react-router-dom";

import Content from "./steps/Content";
import Review from "./steps/Review";

import {
  Breadcrumb,
  Button,
  getInput,
  Modal,
  Spinner,
} from "../../../../components/ui";
import {
  SEND_EMAIL as sendEmailMutation,
  SEND_TEST_EMAIL,
  UPDATE_EMAIL as updateEmailMutation,
} from "../../../../graphql/mutation/Email";

import { FETCH_EMAIL as fetchEmail } from "../../../../graphql/query/Email";
import { FETCH_EMAIL_CONTACTS } from "../../../../graphql/query/EmailContact";

import {
  checkFutureTimestamp,
  refineObject,
  useDebounce,
} from "../../../../util";
import { VALID_EMAIL_REGEX } from "../../../../util/email";
import RecipientSender from "./steps/RecipientSender";

import * as Yup from "yup";
import timeZones from "../../../../util/timeZones.json";

const steps = ["content", "recipients", "review"];

const routes = [
  {
    path: "content",
    element: Content,
  },
  {
    path: "recipients",
    element: RecipientSender,
  },
  {
    path: "review",
    element: Review,
  },
];

const Wizard = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const step = location?.pathname.split("/")[3];
  const {
    loading,
    error,
    data: { email = {} } = {},
  } = useQuery(fetchEmail(), { variables: { id: +id } });
  const { data: { emailContacts: { count } = {} } = {} } = useQuery(
    FETCH_EMAIL_CONTACTS,
    {
      skip: !email?.id,
      variables: {
        input: { limit: 50, offset: 0, emailId: +email?.id, isValid: true },
      },
    },
  );

  const [updateEmail] = useMutation(updateEmailMutation());
  const [sendEmail, { loading: sendEmailLoading }] =
    useMutation(sendEmailMutation());
  const [sendTestEmail] = useMutation(SEND_TEST_EMAIL);

  const currentStepIndex = steps.findIndex(item => item === step);
  const hasNextStep = id && currentStepIndex < steps.length - 1;
  const hasPreviousStep = id && currentStepIndex > 0;

  const defaultTZ = email?.meta?.schedule?.timeZone
    ? timeZones.find(item => email?.meta?.schedule?.timeZone == `${item?.name}`)
    : timeZones.find(item => item.name == "Europe/London");

  const defaultTZValue = `${defaultTZ?.name}`;

  const formik = useFormik({
    initialValues: {
      ...email,
    },
    validateOnChange: true,
    validationSchema: Yup.object({
      meta: Yup.object({
        body: Yup.string()
          .trim()
          .required("Please enter email content.")
          .min(8, "Please enter email content."),
        sender: Yup.string().required("Please select a sender.").nullable(),
        sendType: Yup.string().required("Please select sendType"),
        schedule: Yup.object().when("sendType", {
          is: "schedule",
          then: Yup.object({
            date: Yup.string().required(`Please enter schedule date`),
            time: Yup.string().required(`Please enter schedule time`),
            timeZone: Yup.string().required(`Please enter schedule timeZone`),
          }).test(
            "test-future",
            "Cannot schedule mailings to past timeline.",
            value =>
              checkFutureTimestamp({
                date: value?.date,
                time: value?.time,
                timeZone: value?.timeZone,
              }),
          ),
        }),
        subject: Yup.string().trim().required("Please enter a subject."),
      }),
      title: Yup.string().trim().required("Please enter a title."),
    }),
  });
  const debouncedValues = useDebounce(formik?.values, 500);

  const isValidAttachmentSize = () => {
    const { files = [] } = formik?.values?.meta || {};
    const totalSize = files.reduce((acc, item) => acc + item?.size, 0);
    return totalSize < 15000000;
  };

  // initialise formik state when email data is loaded
  useEffect(() => {
    const validate = async () => {
      if (
        Object.keys(formik?.values).length === 0 &&
        Object.keys(email).length > 0
      ) {
        await formik.resetForm({
          values: {
            ...email,
            meta: {
              ...email?.meta,
              sendType: email?.meta?.sendType || "immediate",
              schedule: {
                ...email?.meta?.schedule,
                timeZone: defaultTZValue,
              },
            },
          },
        });
        await formik.validateForm();
      }
      if (email?.meta?.recipientId !== formik?.values?.meta?.recipientId) {
        await formik.setFieldValue(
          "meta.recipientId",
          email?.meta?.recipientId,
        );
      }
    };
    validate();
  }, [email]);

  useEffect(() => {
    const meta = refineObject(formik?.values?.meta, formik?.errors?.meta);

    // TODO: just send values that were touched
    formik?.dirty &&
      updateEmail({
        variables: {
          input: {
            id: +id,
            ...(formik?.errors?.title ? {} : { title: formik?.values?.title }),
            meta,
          },
        },
      });
  }, [debouncedValues]);

  const nextStep = () => {
    if (hasNextStep) {
      const { path } =
        routes.find(item => item.path === steps[currentStepIndex + 1]) || {};
      navigate(path);
    }
  };

  const previousStep = () => {
    if (hasPreviousStep) {
      const { path } =
        routes.find(item => item.path === steps[currentStepIndex - 1]) || {};
      navigate(path);
    }
  };

  const goToStep = stepIndex => {
    if (stepIndex < 0 || stepIndex >= steps.length) return;
    const { path } = routes.find(item => item.path === steps[stepIndex]) || {};
    navigate(path);
  };

  const stepProps = {
    email,
    formik,
    isValidAttachmentSize: isValidAttachmentSize(),
    timeZones,
  };

  const modalProps = {
    title: "Email Preview",
    body: () => {
      return (
        <div
          className="mt-6 h-full overflow-auto border border-gray-400 p-6 sm:rounded-lg"
          dangerouslySetInnerHTML={{ __html: email?.meta?.body }}
        />
      );
    },
  };

  const testEmailModalProps = {
    title: "Test Email",
    scale: "sm",
    description: "Type a list of comma separated emails to send the test to",
    closeOnBackdrop: true,
    body: ({ closeModal, isOpen }) => {
      // eslint-disable-next-line
      const [testEmails, setTestEmails] = useState("");
      // eslint-disable-next-line
      const [errorMessage, setErrorMessage] = useState("");
      const inputProps = {
        name: "testEmails",
        onChange: e => setTestEmails(e.target.value),
        type: "text",
        value: testEmails,
      };
      const validateEmails = () => {
        const emails = testEmails.split(",");
        const valid = emails.filter(
          email => !email.trim().match(VALID_EMAIL_REGEX),
        );
        return valid;
      };
      const handleTestEmail = () => {
        if (validateEmails().length) {
          return setErrorMessage(validateEmails().join(", "));
        }
        sendTestEmail({
          variables: {
            input: {
              id: +id,
              to: testEmails.split(",")?.map(item => item.trim()),
            },
          },
        });
        setErrorMessage("");
        closeModal();
      };

      // eslint-disable-next-line
      useEffect(() => {
        setTestEmails("");
      }, [isOpen]);

      return (
        <div className="mt-4 flex-col">
          {getInput(inputProps)}
          <div className="pt-4 text-sm text-rose-600">
            {errorMessage &&
              `One or more of the emails you provided is invalid. Your test email has not been sent. Invalid Emails: ${errorMessage}`}
          </div>
          <Button
            className="mr-3"
            label={"Cancel"}
            action={"default"}
            onClick={() => {
              closeModal?.();
            }}
          />
          <Button
            className="mt-4"
            label={"Send Test"}
            onClick={handleTestEmail}
            loading={loading}
          />
        </div>
      );
    },
  };

  const sendEmailModalProps = {
    body: ({ closeModal }) => {
      const handleClick = () => {
        sendEmail({
          variables: {
            input: {
              id: +id,
            },
          },
        }).then(() => {
          navigate("/email");
          closeModal();
        });
      };

      return (
        <div className="mt-4 flex-col">
          <Button
            className="mr-3"
            label={"No"}
            action={"default"}
            onClick={closeModal}
          />
          <Button
            className="mt-4"
            loading={sendEmailLoading}
            label={"Yes"}
            onClick={handleClick}
          />
        </div>
      );
    },
    closeOnBackdrop: true,
    description: `Would you like to ${formik?.values?.meta?.sendType === "schedule" ? "schedule" : "send"} this email?`,
    disabled:
      Object.keys(formik?.errors)?.length > 0 ||
      !isValidAttachmentSize() ||
      count == 0
        ? true
        : false,
    scale: "sm",
    title: "Are you sure?",
  };

  const onClose = () => {
    navigate("/email");
  };

  const breadcrumbProps = {
    currentStepIndex,
    onChange: index => goToStep(index),
    steps,
  };

  return (
    <>
      {loading ? (
        <div className="absolute left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-gray-100 bg-opacity-75">
          <Spinner />
        </div>
      ) : (
        !error && (
          <div className="mx-auto py-5 sm:px-6 lg:px-8">
            {/* <h3 className="text-lg leading-6 font-medium text-gray-900">Email Wizard</h3> */}
            <div className="mb-4 flex items-center justify-between">
              <Breadcrumb {...breadcrumbProps} />
              <div className="flex justify-end">
                <Modal {...modalProps}>
                  <Button label={"Preview"} className="mr-4" action="default" />
                </Modal>
                {!hasNextStep && (
                  <Modal {...testEmailModalProps}>
                    <Button label={"Test Email"} className="mr-4" />
                  </Modal>
                )}
                <Button label="Close" className="mr-4" onClick={onClose} />
                {hasPreviousStep && (
                  <Button
                    label={"Prev"}
                    disabled={!hasPreviousStep}
                    className="mr-4"
                    onClick={previousStep}
                  />
                )}
                {hasNextStep && (
                  <Button
                    label={"Next"}
                    disabled={!hasNextStep}
                    className="mr-4"
                    onClick={nextStep}
                  />
                )}
                {steps[currentStepIndex] === "review" && (
                  <Modal {...sendEmailModalProps}>
                    <Button
                      label={
                        formik?.values?.meta?.sendType === "schedule"
                          ? "Schedule"
                          : "Send"
                      }
                      className="mr-4"
                      disabled={sendEmailModalProps?.disabled}
                    />
                  </Modal>
                )}
              </div>
            </div>
            <div>
              <RouterRoutes>
                {!loading &&
                  routes?.map((item, index) => {
                    return (
                      <Route
                        key={index}
                        path={item?.path}
                        element={<item.element {...stepProps} />}
                      />
                    );
                  })}
              </RouterRoutes>
            </div>
          </div>
        )
      )}
    </>
  );
};

export default Wizard;
