import { useState } from "react";
import { useQuery } from "@apollo/client";

import FilterBar from "./components/FilterBar";
import Actions from "./components/Actions";

import Table from "../../../../components/Table";
import { Tabs, Pagination } from "../../../../components/ui";
import { FETCH_DOCUMENTS as fetchDocuments } from "../../../../graphql/query/Document";
import { useDebounce } from "../../../../util";
import { useNavigate } from "react-router-dom";

const Body = () => {
  const limit = 50;
  const [filters, setFilters] = useState({
    limit,
    offset: 0,
    shared: false,
    search: "",
    status: "",
    title: "",
    from: "",
    to: "",
  });
  const debouncedFilters = useDebounce(filters, 300);
  const skip = Object.keys(debouncedFilters).length === 0;
  const { previousData, data, loading } = useQuery(fetchDocuments(), {
    skip,
    variables: { input: { ...debouncedFilters } },
  });
  const { documents } = data || previousData || {};
  const { count, edges = [] } = documents || {};
  const navigate = useNavigate();

  const tabProps = {
    active: filters.shared ? "shared" : "owned",
    onChange: name => {
      name === "shared"
        ? onChange({ shared: true })
        : onChange({ shared: false });
    },
    tabs: [
      { label: "My PDFs", name: "owned" },
      { label: "Shared PDFs", name: "shared" },
    ],
  };

  const tableProps = {
    headers: [
      {
        label: "Template",
        name: "title",
        type: "label",
        onClick: props => {
          navigate(`/create-pdf/title?document=${props?.formik?.values?.id}`);
        },
      },
      // { label: 'Shared By', name: 'sharedBy', type: 'label' },
      { label: "Instance #", name: "instanceNo", type: "label" },
      ...(filters?.shared
        ? [{ label: "Owner", name: "owner", type: "label" }]
        : []),
      { label: "Created At", name: "createdAt", type: "date" },
      { label: "Updated At", name: "updatedAt", type: "date" },
      // { label: 'Status', name: 'status', type: 'tag' },
      { label: "Action", name: "action", type: "custom", component: Actions },
    ],
    limit,
    loading,
    rows: edges?.map(item => ({
      ...item,
      instanceNo: item?.meta?.instances?.length,
    })),
  };

  const onChange = values => {
    setFilters({ ...filters, ...values });
  };

  const paginationProps = {
    count,
    limit,
    offset: filters?.offset,
    onChange,
  };

  const filterBarProps = {
    onChange: values => onChange({ ...values, offset: 0 }),
  };

  return (
    <div className="dashboard mx-auto flex-grow py-5 sm:px-6 lg:px-8">
      <header>
        <div className="flex flex-grow items-center justify-between">
          <Tabs {...tabProps} />
          <FilterBar {...filterBarProps} />
        </div>
      </header>
      <main>
        <div className="flex-col p-4 sm:px-0">
          <Table {...tableProps} />
          <Pagination {...paginationProps} />
        </div>
      </main>
    </div>
  );
};

const Dashboard = props => {
  return (
    <div className="flex">
      <Body />
    </div>
  );
};

export default Dashboard;
