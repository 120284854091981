import { useQuery } from "@apollo/client";
import * as React from "react";
import { Link } from "react-router-dom";

import { Table } from "../../../../../../components/Functional";
import { FETCH_SHIPMENTS as fetchShipments } from "../../../../../../graphql/query/Shipment";
import { FETCH_SHIPMENT_CATEGORIES } from "../../../../../../graphql/query/ShipmentCategory";

import SectionHeader from "../../components/SectionHeader";
import Actions from "./components/Actions";

const ShipmentIdComponent = props => {
  return (
    <Link className="underline" to={`/shipments/${props?.values?.id}/details`}>
      {props?.values?.id}
    </Link>
  );
};

const ContactShipments = props => {
  const { contact } = props || {};
  const { data: { shipmentCategories = [] } = {} } = useQuery(
    FETCH_SHIPMENT_CATEGORIES,
  );

  const tableProps = {
    className: "sm:px-0 lg:px-0",
    dataKey: "shipments",
    FETCH_QUERY: fetchShipments({ category: {} }),
    headers: [
      // { label: "Role", name: "role", type: "label" },
      { label: "Date", name: "date", sortKey: "date", type: "date" },
      {
        label: "#",
        name: "id",
        sortKey: "id",
        type: "custom",
        component: ShipmentIdComponent,
      },
      {
        label: "Shipped By",
        name: ["shipper.company", "shipper.name"],
        sortKey: "shipperId",
        type: "multi-label",
      },
      { label: "Origin", name: ["oriCompany", "oriName"], type: "multi-label" },
      {
        label: "Destination",
        name: ["delCompany", "delName"],
        type: "multi-label",
      },
      {
        label: "Category",
        name: "category.description",
        sortKey: "categoryId",
        type: "label",
      },
      {
        label: "Delivered",
        name: "delivered",
        sortKey: "delivered",
        type: "date",
      },
      { label: "", name: "action", type: "custom", component: Actions },
    ],
    inputs: [
      { label: "Date From", name: "from", type: "date", view: "partial" },
      { label: "Date To", name: "to", type: "date", view: "partial" },
      {
        label: "Category",
        name: "categoryId",
        type: "multi-select",
        view: "partial",
        options: shipmentCategories?.map(item => ({
          label: item.description,
          value: item.id,
        })),
      },
      { label: "ID", name: "id", type: "text", view: "partial" },
      {
        label: "Delivered Date From",
        name: "deliveredDateFrom",
        type: "date",
        view: "partial",
      },
      {
        label: "Delivered Date To",
        name: "deliveredDateTo",
        type: "date",
        view: "partial",
      },
    ],
    variant: "dynamicHeight",
  };

  const consignorTableProps = {
    ...tableProps,
    variables: {
      consignorId: contact?.id,
    },
  };

  const consigneeTableProps = {
    ...tableProps,
    variables: {
      consigneeId: contact?.id,
    },
  };

  return (
    <div className="w-full">
      <SectionHeader title="Shipments" />
      <div className="mb-6 w-full">
        <div className="mb-2 ml-2 text-xl">Consignor</div>
        <Table {...consignorTableProps} />
        <div className="mb-2 ml-2 text-xl">Consignee</div>
        <Table {...consigneeTableProps} />
      </div>
    </div>
  );
};

export default ContactShipments;
