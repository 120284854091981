import { useMutation } from "@apollo/client";
import {
  ArrowDownTrayIcon,
  PencilIcon,
  ShareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

import { DELETE_DOCUMENT } from "../../../../../graphql/mutation/Document";
import ExportPDFModal from "../../../../../components/Modals/ExportPDFModal";
import SharePDFModal from "../../../../../components/Modals/SharePDFModal";
import { Button, IconButton, Modal } from "../../../../../components/ui";

const Actions = props => {
  const { id, values } = props || {};
  const navigate = useNavigate();

  const [deleteDocument, { loading }] = useMutation(DELETE_DOCUMENT);

  const handleEdit = () => {
    navigate(`/create-pdf/title?document=${id}`);
  };

  const modalProps = {
    title: "Are you sure?",
    scale: "sm",
    description: `Would you like to delete document titled '${values?.title ?? "n/a"}'?`,
    closeOnBackdrop: true,
    body: ({ closeModal }) => {
      const handleDelete = () => {
        deleteDocument({
          variables: {
            input: { id },
          },
          update: (cache, { data }) => {
            const { deleteDocument: { success } = {} } = data;
            if (success) {
              closeModal();
              cache.evict({
                id: cache.identify({
                  __typename: "Document",
                  id,
                }),
              });
            }
          },
        });
      };

      return (
        <div className="mt-4 flex">
          <Button
            className="mr-3"
            label={"Cancel"}
            action={"default"}
            onClick={() => {
              closeModal?.();
            }}
          />
          <Button
            label={loading ? "Deleting" : "Delete"}
            disabled={loading}
            onClick={handleDelete}
          />
        </div>
      );
    },
  };

  return (
    <div className="list-actions flex text-right text-sm font-medium">
      {id && (
        <div className="grid grid-cols-4 gap-4">
          <IconButton variant="clean" title="Edit PDF">
            <PencilIcon
              className={`h-4 w-4 cursor-pointer text-gray-400`}
              onClick={handleEdit}
            />
          </IconButton>
          <SharePDFModal id={id}>
            <IconButton variant="clean" title="Share PDF">
              <ShareIcon className={`h-4 w-4 cursor-pointer text-gray-400`} />
            </IconButton>
          </SharePDFModal>
          <ExportPDFModal id={id}>
            <IconButton variant="clean" title="Download PDF">
              <ArrowDownTrayIcon
                className={`h-4 w-4 cursor-pointer text-gray-400`}
              />
            </IconButton>
          </ExportPDFModal>
          <Modal {...modalProps}>
            <IconButton variant="clean" title="Delete PDF">
              <TrashIcon className={`h-4 w-4 cursor-pointer text-gray-400`} />
            </IconButton>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default Actions;
