import { useQuery } from "@apollo/client";
import { GridInputs } from "../../../../../components/Functional";
import { FETCH_CURRENCIES } from "../../../../../graphql/query/Currency";
import { FETCH_VALUE_TYPES } from "../../../../../graphql/query/ValueType";

const History = props => {
  const { formik } = props || {};
  const { data: { currencies = [] } = {} } = useQuery(FETCH_CURRENCIES);
  const { data: { valueTypes = [] } = {} } = useQuery(FETCH_VALUE_TYPES);

  const inputs = {
    className: "grid grid-cols-4 gap-16 mt-5",
    inputs: [
      {
        className: "flex flex-col gap-4",
        inputs: [
          {
            label: "Type",
            name: "_value.typeId",
            type: "multi-select",
            isMulti: false,
            options: valueTypes?.map(item => ({
              label: item.description,
              value: item.id,
            })),
          },
          {
            name: "_value.currencyId",
            type: "text",
            label: "Currency",
            // type: "multi-select",
            isMulti: false,
            options: currencies?.map(currency => ({
              label: `${currency.description} (${currency.code})`,
              value: currency.id,
            })),
          },
        ],
      },
      {
        className: "gap-4",
        inputs: [
          {
            label: "From",
            name: "_value.date.value.from",
            type: "date",
          },
          {
            label: "To",
            name: "_value.date.value.to",
            type: "date",
          },
        ],
      },
      {
        className: "gap-4",
        inputs: [
          {
            label: "Sales Strategy",
            name: "salesStrategy",
            type: "text",
          },
          {
            designType: "threeStateCheckbox",
            label: "Return",
            name: "returns",
            type: "toggle",
          },
          {
            designType: "threeStateCheckbox",
            label: "Director Confirm (Return)",
            name: "returnsDirector",
            type: "toggle",
          },
        ],
      },
    ],
  };

  const gridInputProps = {
    formik,
    ...inputs,
  };

  return <GridInputs {...gridInputProps} />;
};

export default History;
