import { DragDropContext } from "react-beautiful-dnd";
import { reorder } from "../../../../../../util";

const DraggablePageTemplate = props => {
  const { document, setSelected, updateDocument, assignInstanceDocumentPage } =
    props;
  const selectedInstances =
    document.meta?.instances?.map((item, key) => ({
      ...item,
      fieldsData: document.fieldsData[key],
    })) || [];

  const reorderPages = result => {
    const { source, destination } = result;
    const newPageOrder = reorder(
      document.pages,
      source.index,
      destination.index,
    );
    updateDocument({
      variables: {
        input: {
          id: document.id,
          pages: newPageOrder?.map(item => ({ id: item.id })),
        },
      },
    });
  };

  const assignPage = async result => {
    const { destination, draggableId } = result;
    const pageId = destination.droppableId.split("-")[1];
    const instanceId = draggableId.split(":")[0];
    if (pageId && instanceId) {
      assignInstanceDocumentPage({
        variables: {
          input: {
            id: +pageId,
            instanceId: +instanceId,
          },
        },
      });
    }
  };

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }
    if (result.destination.droppableId === "documentPage-list") {
      reorderPages(result);
      return;
    }
    if (result.destination.droppableId.includes("pagePreview")) {
      assignPage(result);
      return;
    }
    const destinationRef = result.destination.droppableId.split("-");
    const destinationGroup = destinationRef[1];
    const groupColumns = destinationRef[2];
    const sourceGroup = result.source.droppableId.split("-")[1];
    const destinationGlobalIndex = destinationGroup * groupColumns;
    const sourceGlobalIndex = sourceGroup * groupColumns;
    const items = reorder(
      selectedInstances,
      result.source.index + sourceGlobalIndex,
      result.destination.index + destinationGlobalIndex,
    );
    setSelected(items);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>{props.children}</DragDropContext>
  );
};

export default DraggablePageTemplate;
