import { gql } from "@apollo/client";
import { ContactDocument } from "../fragments";

const FETCH_CONTACT_DOCUMENTS = gql`
  query FetchContactDocuments($input: ContactDocumentFilterInput!) {
    contactDocuments(input: $input) {
      count
      edges {
        ${ContactDocument}
      }
    }
  }
`;

export { FETCH_CONTACT_DOCUMENTS };
