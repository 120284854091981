import { gql } from "@apollo/client";
import { Consignment as consignment } from "../fragments";

const EXTEND_CONSIGNMENT = props => gql`
  mutation ExtendConsignment($input: ExtendConsignmentInput!) {
    extendConsignment(input: $input) {
      success
      error
      consignment {
        ${consignment(props)}
      }
    }
  }
`;

const CREATE_CONSIGNMENT = props => gql`
  mutation CreateConsignment($input: CreateConsignmentInput!) {
    createConsignment(input: $input) {
      success
      error
      consignment {
        ${consignment(props)}
      }
    }
  }
`;

const UPDATE_CONSIGNMENT = props => gql`
  mutation UpdateConsignment($input: UpdateConsignmentInput!) {
    updateConsignment(input: $input) {
      success
      error
      consignment {
        ${consignment(props)}
      }
    }
  }
`;

const EXPORT_ADHOC_REPORT_CONSIGNMENTS = gql`
  mutation ExportAdhocReportConsignments($input: ExportConsignmentsInput!) {
    exportAdhocReportConsignments(input: $input) {
      success
      error
      url
    }
  }
`;

const CREATE_CONSIGNMENT_JOB_REQUEST = gql`
  mutation CreateConsignmentJob($input: CreateConsignmentJobInput!) {
    createConsignmentJob(input: $input) {
      jobs {
        id
      }
      success
    }
  }
`;

const CREATE_CONSIGNMENT_SHIPMENT = gql`
  mutation CreateConsignmentShipment($input: CreateConsignmentShipmentInput!) {
    createConsignmentShipment(input: $input) {
      success
      error
      shipment {
        id
      }
    }
  }
`;

const CREATE_CONSIGNMENT_INVOICE = gql`
  mutation CreateConsignmentInvoice($input: CreateConsignmentInvoiceInput!) {
    createConsignmentInvoice(input: $input) {
      success
      error
      invoice {
        id
      }
    }
  }
`;

export {
  EXTEND_CONSIGNMENT,
  UPDATE_CONSIGNMENT,
  CREATE_CONSIGNMENT,
  EXPORT_ADHOC_REPORT_CONSIGNMENTS,
  CREATE_CONSIGNMENT_JOB_REQUEST,
  CREATE_CONSIGNMENT_SHIPMENT,
  CREATE_CONSIGNMENT_INVOICE,
};
