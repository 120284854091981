import { useQuery } from "@apollo/client";
import { ExportImage } from "../../../../../../components/Modals/ImageActions";
import { FETCH_LIST_ITEMS as fetchListItems } from "../../../../../../graphql/query/ListItems";

const ExportImages = props => {
  const { ids, listId } = props || {};
  const { data: { listItems: { edges = [] } = {} } = {} } = useQuery(
    fetchListItems({ item: {} }),
    {
      skip: !listId,
      variables: { input: { listId } },
    },
  );
  const images = edges?.map(item => item?.item);
  const selectedImages = edges
    ?.filter(item => ids?.includes(item?.id))
    ?.map(item => item?.item);
  const disabled = !ids?.length > 0;

  const allImageFilters = {
    artworkId: [],
    archiveId: [],
  };
  edges.forEach(item => {
    // eslint-disable-next-line
    !allImageFilters.archiveId.includes(parseInt(item?.item?.archiveId))
      ? allImageFilters.archiveId.push(parseInt(item?.item?.archiveId))
      : null;
    // eslint-disable-next-line
    !allImageFilters.artworkId.includes(parseInt(item?.item?.artworkId))
      ? allImageFilters.artworkId.push(parseInt(item?.item?.artworkId))
      : null;
  });

  const selectedImageFilters = {
    artworkId: [],
    archiveId: [],
  };
  selectedImages.forEach(item => {
    // eslint-disable-next-line
    !selectedImageFilters.archiveId.includes(parseInt(item?.archiveId))
      ? selectedImageFilters.archiveId.push(parseInt(item?.archiveId))
      : null;
    // eslint-disable-next-line
    !selectedImageFilters.artworkId.includes(parseInt(item?.artworkId))
      ? selectedImageFilters.artworkId.push(parseInt(item?.artworkId))
      : null;
  });

  return (
    <ExportImage
      disabled={disabled}
      images={images}
      selectedImages={selectedImages}
      selectedImageFilters={selectedImageFilters}
      allImageFilters={allImageFilters}
    />
  );
};

export default ExportImages;
