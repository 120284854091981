import { useEffect, useRef, useState } from "react";
import { Pagination } from "../../../../../components/ui/Pagination";
import { Spinner } from "../../../../../components/ui/Spinner";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import InstanceComponent from "./stepsSharedComponents/InstanceComponent";
import { reorder } from "../../../../../util";
import { IconButton } from "../../../../../components/ui";
import { PencilIcon } from "@heroicons/react/24/outline";
import { Modal } from "../../../../../components/ui/Modal";
import EditDocumentTitleModal from "./EditDocumentTitleModal";

const searchInstanceWH = 150;
const selectedInstanceWH = 75;
const searchItemsPadding = 8;
const selectedItemsPadding = 4;

const SelectImages = props => {
  const {
    instances,
    setVariables,
    variables,
    limit,
    setSelected,
    isLoading,
    document,
    formik,
    isLoadingUpdate,
  } = props;
  const [loading, setLoading] = useState(false);
  const [columnsPerRow, handleColumnsPerRow] = useState(3);
  const resultRowRef = useRef();
  const offset = variables?.offset;
  const selectedInstances =
    document.meta?.instances?.map((item, key) => ({
      ...item,
      fieldsData: document.fieldsData[key],
    })) || [];
  const paginationProps = {
    count: instances?.count,
    limit,
    onChange: values => setVariables({ ...variables, ...values }),
    offset,
    showSteps: false,
  };

  useEffect(() => {
    if (isLoadingUpdate == false) {
      setLoading(false);
    }
  }, [isLoadingUpdate]);

  const SearchResult = isLoading ? (
    <div className="flex h-[60px] w-full items-center justify-center">
      <Spinner />
    </div>
  ) : (
    <div>
      <div className="pb-4 font-bold">
        Search Results{" "}
        {instances?.edges?.length && (
          <span className="text-sm font-normal">
            showing {paginationProps.count > 1 ? offset + 1 : offset} to{" "}
            {offset + limit > paginationProps.count
              ? paginationProps.count
              : offset + limit}{" "}
            of {paginationProps.count} results
          </span>
        )}
      </div>
      {!!instances?.edges?.length && (
        <>
          <div className="-ml-2 flex flex-grow flex-wrap border-0 border-b border-gray-300">
            {instances?.edges?.map((item, key) => {
              const { id } = item || {};
              const { artwork } = item?.edition || {};
              const { imagesSummary } = artwork || {};
              let itemIndex = null;
              const isSelected = selectedInstances.find((item, key) => {
                const isSelected =
                  item?.archiveId === imagesSummary?.archiveId &&
                  item?.instanceId === id;
                if (isSelected) {
                  itemIndex = key;
                }
                return isSelected;
              })?.archiveId;
              return (
                <div
                  className="column flex"
                  key={key}
                  style={{ padding: `0 ${searchItemsPadding}px` }}
                >
                  <InstanceComponent
                    isSelected={!!isSelected}
                    item={imagesSummary}
                    onSelectImage={setSelected}
                    selectedInstances={selectedInstances}
                    instanceId={item.id}
                    name={item.name}
                    styles={{
                      width: searchInstanceWH,
                      height: searchInstanceWH,
                    }}
                    instanceKey={itemIndex !== null && itemIndex + 1}
                    zoomImage={true}
                    itemsPadding={searchItemsPadding}
                    loading={isLoadingUpdate}
                  />
                </div>
              );
            })}
          </div>
          <Pagination {...paginationProps} />
        </>
      )}
    </div>
  );

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }
    const destinationGroupRow = result.destination.droppableId.split("-")[1];
    const sourceGroupRow = result.source.droppableId.split("-")[1];
    const destinationIndex =
      destinationGroupRow > 0
        ? result.destination.index + destinationGroupRow * columnsPerRow
        : result.destination.index;
    const sourceIndex =
      sourceGroupRow > 0
        ? result.source.index + sourceGroupRow * columnsPerRow
        : result.source.index;

    const items = reorder(selectedInstances, sourceIndex, destinationIndex);
    setLoading(true);
    setSelected(items);
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    background: isDragging ? "#efefef" : "transparent",
    boxShadow: isDragging ? "1px 1px 10px rgba(0,0,0,.3)" : "none",
    borderRadius: isDragging ? 5 : 0,
    ...draggableStyle,
  });

  const getListStyle = isDraggingOver => ({
    border: isDraggingOver ? "2px dotted #9f9f9f" : "2px solid transparent",
  });

  const handleResize = () => {
    const rowWidth = resultRowRef?.current.offsetWidth;
    const columnsCount = Math.floor(
      rowWidth / (selectedInstanceWH + selectedItemsPadding),
    );
    handleColumnsPerRow(columnsCount > 1 ? columnsCount - 1 : 1);
  };

  const gridRow = selectedInstances.length
    ? Math.ceil(selectedInstances.length / columnsPerRow)
    : null;

  const modalProps = {
    title: "Edit pdf name",
    scale: "sm",
    closeOnBackdrop: true,
    body: EditDocumentTitleModal,
    pdfTitle: formik?.values?.title,
    documentId: document.id,
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      <div className="grid grid-cols-3">
        <div className="col-span-3">
          <div className="full-width" ref={resultRowRef}>
            <div className="mb-10 flex flex-row text-lg font-bold">
              <span>
                {formik?.values?.title && `${formik?.values?.title}.pdf`}
              </span>
              <span className="pl-2">
                <Modal {...modalProps}>
                  <IconButton
                    variant="clean"
                    title="Edit Role"
                    className="h-[28px]"
                  >
                    <PencilIcon
                      className={`h-4 w-4 cursor-pointer text-gray-400`}
                    />
                  </IconButton>
                </Modal>
              </span>
            </div>
            {!!selectedInstances.length && (
              <div className="sticky top-0 z-[100] bg-white">
                <div className="font-bold">
                  Selections{" "}
                  <span className="text-sm font-normal">
                    drag and drop to re-order
                  </span>
                </div>
                <DragDropContext onDragEnd={onDragEnd}>
                  {[...Array(gridRow).keys()]?.map((columnKey, parentKey) => {
                    const initialCount = columnKey * columnsPerRow;
                    const elements = selectedInstances.filter(
                      (_item, key) =>
                        key >= initialCount &&
                        key < initialCount + columnsPerRow,
                    );
                    const groupKey = `group-${columnKey}`;
                    const accumulatorKey = parentKey * columnsPerRow;
                    return (
                      <Droppable
                        droppableId={groupKey}
                        direction="horizontal"
                        key={`drop-${columnKey}`}
                      >
                        {(provided, snapshot) => (
                          <div
                            className={`row -ml-1 flex rounded-lg pb-4 pt-4 ${loading && "loading-instances-image"}`}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                            {...provided.droppableProps}
                          >
                            {elements.map((item, index) => {
                              const keyId = `instance-${index}-${columnKey}`;
                              return (
                                <Draggable
                                  key={keyId}
                                  draggableId={keyId}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      className="column flex"
                                      style={{
                                        padding: `0 ${selectedItemsPadding}px`,
                                        ...getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style,
                                        ),
                                      }}
                                    >
                                      <InstanceComponent
                                        isSelected={
                                          !!selectedInstances.find(
                                            s =>
                                              s?.archiveId === item?.archiveId,
                                          )?.archiveId
                                        }
                                        item={item}
                                        onSelectImage={setSelected}
                                        selectedInstances={selectedInstances}
                                        instanceId={item?.instanceId}
                                        styles={{
                                          width: selectedInstanceWH,
                                          height: selectedInstanceWH,
                                        }}
                                        hideSummary={true}
                                        instanceKey={index + 1 + accumulatorKey}
                                        itemsPadding={selectedItemsPadding}
                                        smallActions={true}
                                        loading={isLoadingUpdate}
                                      />
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    );
                  })}
                </DragDropContext>
              </div>
            )}
            <div className="full-width">{SearchResult}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectImages;
