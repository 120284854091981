const Focus = `
  id
  createdById
  createdOn
  deleted
  description
  metaDescription
  metaKeyword
  metaTitle
  name
  notes
  online
  rank
  reference
  translationId
  updatedAt
  updatedById
  updatedOn
  validFrom
  validTo
  versionId
`;

export default Focus;
