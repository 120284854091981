import { gql } from "@apollo/client";
import { PhotoMaterial as photoMaterial } from "../fragments";

const FETCH_PHOTO_MATERIAL_V2 = props => gql`
  query FetchPhotoMaterialsV2($input: AdvPhotoMaterialFilterInput!) {
    photoMaterialsV2(input: $input) {
      count
      edges {
        ${photoMaterial(props)}
      }
      sqlQuery
    }
  }
`;

export { FETCH_PHOTO_MATERIAL_V2 };
