import "./style.css";

const ThreeStateCheckbox = props => {
  const { checked, onChange, disabled = false } = props;
  const handleChange = () => {
    if (!disabled) {
      onChange(checked === -1 ? 0 : checked === 0 ? null : -1);
    }
  };

  return (
    <label
      className={`checkbox-container ${disabled ? "!border-gray-400 !bg-white !text-gray-400" : ""}`}
    >
      <input
        disabled={disabled}
        checked={checked === -1}
        onChange={handleChange}
        type="checkbox"
      />
      {checked !== 0 && (
        <span className={checked === -1 ? "checkmark" : "grayblock"} />
      )}
    </label>
  );
};

export default ThreeStateCheckbox;
