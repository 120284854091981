import { gql } from "@apollo/client";
import { ExhibitionWork } from "../fragments";

const UPDATE_EXHIBITION_WORK = gql`
  mutation UpdateExhibitionWork($input: UpdateExhibitionWorkInput!) {
    updateExhibitionWork(input: $input) {
      exhibitionWork {
        ${ExhibitionWork}
      }
      error
      success
    }
  }
`;

export { UPDATE_EXHIBITION_WORK };
