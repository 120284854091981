import React from "react";
import { useFormik } from "formik";
import {
  FETCH_CONTACT as fetchContact,
  FETCH_CONTACTS as fetchContacts,
} from "../../../../graphql/query/Contact";
import { MERGE_CONTACT as mergeContactMutation } from "../../../../graphql/mutation/Contact";
import { Table } from "../../../../components/Functional";
import { Button, getInput, Modal } from "../../../../components/ui";
import { useMutation } from "@apollo/client";

const MergeContact = props => {
  const { contact, closeModal } = props || {};
  const [mergeContact, { loading }] = useMutation(mergeContactMutation(), {
    refetchQueries: [fetchContact()],
  });

  const formik = useFormik({
    initialValues: {
      contact: null,
      step: "selectContact",
    },
  });
  const contactToMerge = formik?.values?.contact || {};

  const handleMerge = props => {
    const { closeModal: closeConfirmationModal } = props || {};
    mergeContact({
      variables: {
        input: {
          contactId: contact?.id,
          mergeContactId: contactToMerge?.id,
        },
      },
    })
      .then(() => {
        closeConfirmationModal();
        closeModal();
      })
      .catch(error => {
        console.log("error", error);
      });
  };

  const toggleProps = props => {
    const { id, values } = props || {};

    return {
      type: "toggle",
      name: "contact",
      value: formik?.values?.contact?.id === id,
      onChange: value => {
        formik?.setFieldValue(
          "contact",
          value
            ? {
                createdAt: values?.createdAt,
                id,
                invoicesLength: values?.invoicesLength,
                name: values?.name,
              }
            : null,
        );
      },
    };
  };

  const inputTableProps = {
    dataKey: "contacts",
    FETCH_QUERY: fetchContacts(),
    headers: [
      {
        label: "Apply",
        name: "ids",
        type: "custom",
        component: props => getInput(toggleProps(props)),
      },
      { label: "ID", name: "id", type: "label" },
      { label: "Email", name: "email", type: "label" },
      { label: "Name", name: "name", type: "label" },
      { label: "Company", name: "companyName", type: "label" },
    ],
    inputs: [
      { label: "Email", name: "email", type: "text", view: "partial" },
      { label: "From", name: "from", type: "date", view: "partial" },
      { label: "To", name: "to", type: "date", view: "partial" },
    ],
    pagination: {
      variant: "sm",
    },
    variables: {
      excludeContactIds: [contact?.id],
    },
  };

  const modalActions = {
    warningInvoiceLength: {
      title: "Warning",
      description: `Source contact ${contactToMerge?.name} has more invoices than the recieving contact ${contact?.name}. 
      Are you sure you want to merge?`,
    },
    warningCreatedDate: {
      title: "Warning",
      description: `Source contact ${contactToMerge?.name} has a created date that is older than the recieving contact ${contact?.name}.`,
    },
    confirmation: {
      title: "Are you sure?",
      description: `You are about to merge ${contactToMerge?.name} contact record and all associated content with ${contact?.name} contact record.`,
    },
  };

  const modalAction =
    contact?.invoicesLength < contactToMerge?.invoicesLength
      ? modalActions?.warningInvoiceLength
      : new Date(contact?.createdAt).getTime() >
          new Date(contactToMerge?.createdAt).getTime()
        ? modalActions?.warningCreatedDate
        : modalActions?.confirmation;

  const confirmModalProps = {
    ...modalAction,
    scale: "sm",
    closeOnBackdrop: true,
    body: ({ closeModal }) => {
      return (
        <div className="mt-4 flex">
          <Button
            className="mr-3"
            label={"Cancel"}
            action={"default"}
            onClick={() => {
              closeModal?.();
            }}
          />
          <Button
            label={loading ? "Merging" : "Merge"}
            disabled={loading}
            onClick={() => {
              handleMerge({ closeModal });
            }}
          />
        </div>
      );
    },
  };

  return (
    <div className="flex w-full flex-col">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          <div>Select a Contact to Merge</div>
        </div>
        <div className="flex gap-4">
          <Button
            action="default"
            label="Cancel"
            onClick={() => {
              closeModal();
            }}
          />
          <Modal {...confirmModalProps}>
            <Button label={"Merge"} />
          </Modal>
        </div>
      </div>
      <Table {...inputTableProps} />
    </div>
  );
};

export default MergeContact;
