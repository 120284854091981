import Role from "./Role";

const User = ({
  deltaPermissions = false,
  permissions = false,
  permissionsSet = false,
  roles = false,
} = {}) => `
  id
  ${deltaPermissions ? "deltaPermissions" : ""}
  email
  entityId
  isActive
  lastActive
  meta
  ${permissions ? "permissions" : ""}
  ${permissionsSet ? "permissionSet" : ""}
  ${
    roles
      ? `roles {
      ${Role}
    }`
      : ""
  }
  status
`;

export default User;
