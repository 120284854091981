import { useMutation } from "@apollo/client";

import { Button, IconButton, Modal } from "../../../../../../../components/ui";
import { DELETE_PAYMENT_PLAN } from "../../../../../../../graphql/mutation/PaymentPlan";
import { TrashIcon } from "@heroicons/react/24/outline";
import useUser from "../../../../../../../hooks/useUser";

const Actions = props => {
  const { id } = props || {};
  const [deletePaymentPlan, { loading }] = useMutation(DELETE_PAYMENT_PLAN, {
    refetchQueries: ["FetchPaymentPlans"],
  });
  const { hasPermission } = useUser();

  const modalProps = {
    title: "Are you sure?",
    scale: "sm",
    description: `Would you like to delete this payment plan?`,
    closeOnBackdrop: true,
    disabled: !hasPermission("UPDATE_PAYMENT_PLAN"),
    body: ({ closeModal }) => {
      const handleDelete = () => {
        deletePaymentPlan({
          variables: {
            input: { id },
          },
          update: () => {
            closeModal();
          },
        });
      };

      return (
        <div className="mt-4 flex">
          <Button
            className="mr-3"
            label={"Cancel"}
            action={"default"}
            onClick={() => {
              closeModal?.();
            }}
          />
          <Button
            label={loading ? "Deleting" : "Delete"}
            disabled={loading}
            onClick={handleDelete}
          />
        </div>
      );
    },
  };

  if (typeof id == "number") {
    return (
      <div className="user-list-actions flex text-right text-sm font-medium">
        {id && (
          <div className="grid">
            <Modal {...modalProps}>
              <IconButton
                variant="clean"
                title="Delete Payment Plan"
                disabled={!hasPermission("UPDATE_PAYMENT_PLAN")}
              >
                <TrashIcon className={`h-4 w-4 cursor-pointer text-gray-400`} />
              </IconButton>
            </Modal>
          </div>
        )}
      </div>
    );
  } else {
    return false;
  }
};

export default Actions;
