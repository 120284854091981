/* eslint-disable max-len */
const InfoIcon = props => (
  <svg
    width={20}
    height={20}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>{"feather icon/info"}</title>
    <defs>
      <path
        d="M10 .833A9.14 9.14 0 0 0 .833 10 9.14 9.14 0 0 0 10 19.167 9.14 9.14 0 0 0 19.167 10 9.14 9.14 0 0 0 10 .833ZM10 17.5A7.469 7.469 0 0 1 2.5 10c0-4.167 3.333-7.5 7.5-7.5s7.5 3.333 7.5 7.5-3.333 7.5-7.5 7.5Zm.833-7.5v3.333c0 .5-.333.834-.833.834-.5 0-.833-.334-.833-.834V10c0-.5.333-.833.833-.833.5 0 .833.333.833.833Zm-.25-3.917c.167.167.25.334.25.584 0 .25-.083.416-.25.583A.757.757 0 0 1 10 7.5h-.167c-.083 0-.083 0-.166-.083-.084 0-.084-.084-.167-.084s-.083-.083-.083-.083a.757.757 0 0 1-.25-.583c0-.25.083-.417.25-.584L9.5 6c.083 0 .083-.083.167-.083.083 0 .083 0 .166-.084.25 0 .584.084.75.25Z"
        id="a"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <use fill="#000" fillRule="nonzero" xlinkHref="#a" />
      <g mask="url(#b)" fill="#36A3F7">
        <path d="M0 0h20v20H0z" />
      </g>
    </g>
  </svg>
);

export default InfoIcon;
