const ConsignmentDocument = `
  id
  fileName
  fileType
  consignmentId
  updatedAt
  url
  viewUrl
`;

export default ConsignmentDocument;
