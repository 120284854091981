import {
  Core,
  PdfExport,
  // HtmlExport,
  // TabularDataExport,
} from "@grapecity/activereports";

const exportPdf = async ({ document, title }) => {
  const pdfSettings = {
    info: {
      fileName: `${title}.pdf`,
      title,
    },
  };
  const result = await PdfExport.exportDocument(document, pdfSettings);

  result.download(title);
};

// const exportHtml = async (document) => {
//   const htmlSettings = {
//     title: "Product List",
//     multiPage: false
//   }
//   const result = await HtmlExport.exportDocument(document, htmlSettings);
//   result.download("products.html")
// }

// const exportCSV = async (document) => {
//   const csvSettings = {
//     csvSettings: {
//       colSeparator: ","
//     }
//   }
//   const result = await TabularDataExport.exportDocument(document, csvSettings);
//   result.download("products.csv")
// }

export const onExport = async ({
  data = null,
  definition,
  name,
  title,
  viewerRef,
}) => {
  const DataSources = [
    {
      Name: name,
      ConnectionProperties: {
        DataProvider: "JSON",
        ConnectString: "jsondata=" + JSON.stringify(data),
      },
    },
  ];

  const newDefinition = {
    ...definition,
    ...(data ? { DataSources } : null),
  };

  const report = new Core.PageReport();
  await report.load(newDefinition);
  viewerRef?.current?.Viewer?.open(report);

  const document = await report.run();
  exportPdf({ document, title });
};
