import * as React from "react";

import { Button, Modal } from "../../../../../../../../components/ui";
import AddMethodModal from "./components/AddMethodModal";
import DetailView from "./components/DetailView";
import useUser from "../../../../../../../../hooks/useUser";

const Methods = props => {
  const { contact } = props || {};
  const { contactNumbers = [] } = contact || {};
  const { hasPermission } = useUser();
  const disabled = !hasPermission("EDIT_CONTACT_NUMBER");

  const addMethodModalProps = {
    body: AddMethodModal,
    closeOnBackdrop: true,
    contact,
    disabled,
    hideCloseButton: true,
    scale: "md",
  };

  return (
    <div className="mt-10 w-full">
      <div className="mb-6 flex flex-row justify-between">
        <div className="flex flex-row items-center">
          <h1 className="mr-4 mt-1 text-xl font-bold">Contact Methods</h1>
        </div>
        <Modal {...addMethodModalProps}>
          <div>
            <Button
              label="Add Method"
              action="default"
              className="ml-20"
              disabled={disabled}
            />
          </div>
        </Modal>
      </div>
      <div className="flex flex-col gap-4">
        {contactNumbers?.map((item, index) => {
          const detailViewProps = {
            contactNumber: item,
            contact,
            disabled,
          };

          return (
            <div key={index} className="group relative mb-2 flex flex-row">
              <DetailView {...detailViewProps} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Methods;
