const exportModule = {
  name: "Contact",
  sections: [
    {
      fields: [
        { label: "Name", name: "name" },
        { label: "First Name", name: "forename" },
        { label: "Surname", name: "surname" },
        { label: "Company", name: "company" },
        { label: "Job title", name: "title" },
        { label: "Address", name: "address" },
        { label: "Town", name: "town" },
        { label: "County", name: "county" },
        { label: "Postcode", name: "postcode" },
        { label: "Country", name: "Country.name" },
        { label: "Primary Sales Contact", name: "SalesUser.name" },
        { label: "Secondary Sales Contact", name: "SalesUser2.name" },
        { label: "Exhibition Liason", name: "ExhUser.name" },
        { label: "ID", name: "id" },
        {
          label: "Mailing List",
          name: "ContactMailingLists.MailingList.description",
        },
        { label: "Type", name: "ContactType.name" },
        { label: "Type 2", name: "ContactType2.name" },
      ],
      label: "",
      name: "contact",
    },
    {
      fields: [
        { label: "Mailing Label Name", name: "mailingname" },
        { label: "Email", name: "email" },
        { label: "Created By", name: "CreatedBy.name" },
        { label: "Notes", name: "notes" },
        { label: "Updated By", name: "UpdatedBy.name" },
        { label: "Import Info", name: "prefered" },
        { label: "Greeting", name: "greeting" },
        { label: "Salutation", name: "salutation" },
        { label: "Nationality", name: "Nationality.name" },
        { label: "Other Contact Details", name: "details" },
      ],
      label: "Details",
      name: "details",
    },
    {
      fields: [
        {
          label: "Address Type",
          name: "ContactAddresses.ContactAddressType.description",
        },
        { label: "Country", name: "ContactAddresses.Country.name" },
        { label: "Town", name: "ContactAddresses.town" },
      ],
      label: "Addresses",
      name: "addresses",
    },
    {
      fields: [
        { label: "Date Of Birth", name: "dob" },
        { label: "Confidentiality", name: "confidential" },
        { label: "Rating", name: "level" },
        { label: "Level", name: "rating" },
        { label: "Type", name: "FirstContact.description" },
        { label: "Location", name: "firstcontactlocation" },
        { label: "Staff", name: "firstUserId" },
        { label: "Date", name: "firstcontactdate" },
        { label: "Biography", name: "biography" },
      ],
      label: "Client Information",
      name: "clientInformation",
    },
    {
      fields: [
        { label: "Artist", name: "ContactArtists.Artist.name" },
        { label: "Focus", name: "ContactFocus.Focus.description" },
        { label: "Collection Notes", name: "collection" },
        { label: "Collection Status", name: "collectionActive" },
      ],
      label: "Collection",
      name: "collection",
    },
    {
      fields: [
        { label: "CRM", name: "crm" },
        { label: "Catalogues", name: "ContactCatalogues.Catalogue.title" },
      ],
      label: "Material Sent",
      name: "materialSent",
    },
    {
      fields: [
        {
          label: "Purchased Artist",
          name: "Invoices.Items.Instance.Edition.Artwork.Artists.name",
        },
        {
          label: "Category",
          name: "Invoices.Items.Instance.Edition.Artwork.ArtworkCategory.name",
        },
        { label: "Value", name: "Invoices.Items.price" },
        { label: "Invoice Date", name: "Invoices.date" },
        { label: "Total Spent", name: "totalSpent" },
        { label: "Units", name: "units" },
        {
          label: "Analysis",
          name: "Invoices.InvoiceAnalysisEvent.description",
        },
      ],
      label: "Sales",
      name: "sales",
    },
    {
      fields: [
        { label: "Updated At", name: "Offers.updatedAt" },
        { label: "Date", name: "Offers.date" },
        { label: "Artist", name: "Offers.Artwork.Artists.name" },
        { label: "Title", name: "Offers.Artwork.title" },
        { label: "Status", name: "Offers.OfferStatus.description" },
        { label: "Currency", name: "Offers.Currency.code" },
        { label: "Amount", name: "Offers.tbiAmount" },
        { label: "Closed", name: "Offers.dateClosed" },
        { label: "Notes", name: "Offers.notes" },
        { label: "User", name: "Offers.user" },
      ],
      label: "Offers",
      name: "offers",
    },
    {
      fields: [
        { label: "Type Id", name: "Requests.typeId" },
        { label: "Artist", name: "Requests.Artist.name" },
        { label: "Date", name: "Requests.dateClosed" },
        { label: "Budget From", name: "Requests.budgetFrom" },
        { label: "Budget To", name: "Requests.budgetTo" },
        { label: "Description", name: "Requests.description" },
        { label: "Notes", name: "Requests.notes" },
      ],
      label: "Requests",
      name: "requests",
    },
    {
      fields: [
        { label: "Affiliated Contact", name: "ContactLinks.linkId" },
        { label: "Affiliated Description", name: "ContactLinks.description" },
        {
          label: "Affiliated Type",
          name: "ContactLinks.ContactLinkType.description",
        },
        { label: "Expired", name: "ContactLinks.expired" },
      ],
      label: "Affiliations",
      name: "affiliations",
    },
    {
      fields: [
        { label: "Event Id", name: "EventGuests.eventId" },
        { label: "Event", name: "EventGuests.Event.name" },
        { label: "Date", name: "EventGuests.Event.startDate" },
        { label: "RSVP", name: "EventGuests.rsvpId" },
        { label: "Invited By", name: "EventGuests.invitedBy" },
      ],
      label: "Events",
      name: "events",
    },
    {
      fields: [
        { label: "Date", name: "CRMRegistrars.date" },
        { label: "Handover From", name: "CRMRegistrars.validFrom" },
        { label: "Handover To", name: "CRMRegistrars.validTo" },
        { label: "Notes", name: "CRMRegistrars.notes" },
        { label: "Status", name: "CRMRegistrars.JobStatus.description" },
      ],
      label: "Handover",
      name: "handover",
    },
    {
      fields: [
        { label: "Category", name: "CRMRegistrars.CRMCategory.description" },
        { label: "Type", name: "CRMRegistrars.Type.description" },
        { label: "Action By Id", name: "CRMRegistrars.User.name" },
        { label: "Description", name: "CRMRegistrars.description" },
        { label: "Setup By", name: "CRMRegistrars.Entity.name" },
      ],
      label: "Actions",
      name: "actions",
    },
    {
      fields: [
        {
          label: "Type",
          name: "ContactAttributes.ContactAttributeType.description",
        },
      ],
      label: "Attributes",
      name: "attributes",
    },
  ],
};

export default exportModule;
