import Entity from "./Entity";

const InvoiceSalesPerson = ({ salesUser = false } = {}) => `
  id
  ${
    salesUser
      ? `salesUser {
      ${Entity}
    }`
      : ""
  }
  salesUserId
  split
  timestamp
`;

export default InvoiceSalesPerson;
