import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import { Button, getInput } from "../../../../components/ui";
import { CREATE_CONSIGNMENT_INVOICE } from "../../../../graphql/mutation/Consignment";
import { FETCH_BANK_ACCOUNTS as fetchBankAccountsQuery } from "../../../../graphql/query/BankAccount";
import { FETCH_COMPANIES as fetchCompanies } from "../../../../graphql/query/Company";
import { FETCH_INVOICE_TYPES } from "../../../../graphql/query/InvoiceType";
import { FETCH_TAX_AREAS } from "../../../../graphql/query/TaxArea";
import { useEffect } from "react";

const CreateInvoice = props => {
  const { closeModal, consignmentId } = props || {};
  const navigate = useNavigate();
  const [createConsignmentInvoice, { loading }] = useMutation(
    CREATE_CONSIGNMENT_INVOICE,
  );
  const [fetchBankAccounts, { data: { bankAccounts = [] } = {} }] =
    useLazyQuery(fetchBankAccountsQuery({ currency: {} }));
  const { data: { companies = [] } = {} } = useQuery(fetchCompanies());
  const { data: { invoiceTypes = [] } = {} } = useQuery(FETCH_INVOICE_TYPES);
  const { data: { taxAreas = [] } = {} } = useQuery(FETCH_TAX_AREAS);

  const formik = useFormik({
    initialValues: { consignmentId },
    validationSchema: Yup.object({
      bankId: Yup.number().required("Please select a bank account"),
      companyId: Yup.number().required("Please select a company"),
      contactId: Yup.number().required("Please select a client"),
      // deliveryCountryId: Yup.number().required("Please select a delivery country"),
      taxareaId: Yup.number().required("Please select a tax area"),
      typeId: Yup.number().required("Please select a type"),
    }),
    onSubmit: async (values, { setErrors, resetForm }) => {
      const error = {};

      if (Object.keys(error).length <= 0) {
        createConsignmentInvoice({
          variables: {
            input: values,
          },
        }).then(resp => {
          const {
            data: { createConsignmentInvoice: { invoice, success } = {} },
          } = resp || {};
          if (success) {
            navigate(`/invoice/${invoice?.id}/details`);
          }
        });
      } else {
        setErrors(error);
      }
    },
  });

  // fetch accounts when company is selected
  useEffect(() => {
    if (formik?.values?.companyId) {
      fetchBankAccounts({
        variables: { input: { companyId: formik?.values?.companyId } },
      });
    }
  }, [formik?.values?.companyId]);

  const inputs = [
    {
      label: "Type",
      name: "typeId",
      options: invoiceTypes?.map(item => ({
        label: item.description,
        value: item.id,
      })),
      type: "multi-select",
    },
    {
      label: "Tax Area",
      name: "taxareaId",
      options: taxAreas?.map(item => ({
        label: item?.name,
        value: item?.id,
      })),
      type: "multi-select",
    },
    {
      label: "Company",
      name: "companyId",
      options: companies?.edges?.map(item => ({
        label: item.name,
        value: item.id,
      })),
      type: "multi-select",
    },
    { label: "Client", name: "contactId", type: "contactSearch" },
    {
      label: "Bank Account",
      name: "bankId",
      options: bankAccounts?.map(item => ({
        label: `${item?.bank}, ${item?.number}, ${item?.currency?.code}`,
        value: item.id,
      })),
      type: "multi-select",
    },
  ];

  const hasErrors = Object.keys(formik?.errors || {}).length > 0;

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          <div>Create Invoice</div>
        </div>
        <div className="flex gap-4">
          <Button
            action="default"
            label="Cancel"
            onClick={() => {
              closeModal();
              formik.resetForm();
            }}
          />
          <Button
            label={"Create"}
            loading={loading}
            disabled={loading || hasErrors}
            onClick={formik.submitForm}
          />
        </div>
      </div>
      <div className="w-50 grid grid-cols-2 gap-4">
        {inputs?.map((item, index) => {
          const inputProps = {
            ...item,
            formik,
          };
          return <div key={index}>{getInput(inputProps)}</div>;
        })}
      </div>
    </div>
  );
};

export default CreateInvoice;
