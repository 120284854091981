import { useQuery } from "@apollo/client";
import { GridInputs } from "../../../../../../components/Functional";
import { FETCH_MAILING_LISTS } from "../../../../../../graphql/query/MailingList";

const MailingLists = props => {
  const { formik } = props || {};
  const { data: { mailingLists: { edges: mailingLists = [] } = {} } = {} } =
    useQuery(FETCH_MAILING_LISTS);

  const inputs = {
    className: "grid grid-cols-2 gap-16",
    inputs: [
      {
        isMulti: true,
        label: "Add to Mailing List",
        name: "mailingListId",
        options: mailingLists?.map(item => ({
          label: item?.description,
          value: item?.id,
        })),
        type: "multi-select",
      },
    ],
  };

  const gridInputProps = {
    formik,
    ...inputs,
  };

  return <GridInputs {...gridInputProps} />;
};

export default MailingLists;
