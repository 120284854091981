const Tag = props => {
  const { label, action } = props || {};
  const actionMap = {
    primary: "bg-black",
    success: "bg-success",
    danger: "bg-danger",
  };
  const bgColor = actionMap[action] || actionMap?.primary;
  return (
    <span
      className={`inline-flex rounded-full px-2 text-xs font-semibold leading-5 ${bgColor} capitalize text-white`}
    >
      {label}
    </span>
  );
};

export { Tag };
