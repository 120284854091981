import { gql } from "@apollo/client";
import CRMCategory from "../fragments/CRMCategory";

const FETCH_CRM_CATEGORIES = gql`
  query FetchCRMCategories {
    crmCategories {
      ${CRMCategory}
    }
  }
`;

export { FETCH_CRM_CATEGORIES };
