import { GridInputs } from "../../../../../components/Functional";

const Sort = props => {
  const { formik } = props || {};

  const sortOptions = [
    { label: "Artist", value: "_edition._artwork._artist.name" },
    { label: "Company", value: "_company.name" },
    { label: "Reference", value: "reference" },
    { label: "Title", value: "_edition._artwork.title" },
    { label: "Year", value: "_edition.year" },
    { label: "Series", value: "_edition._artwork.series" },
    { label: "Artist Reference", value: "artistRef" },
    { label: "Category", value: "_edition._artwork._artworkCategory.name" },
    { label: "Status", value: "_status.name" },
    {
      label: "Location",
      value: "_warehouseItem._warehouseMove._warehouseLocation.description",
    },
    { label: "Sales Price", value: "salePrice" },
    { label: "Activity", value: "_activity.points" },
    {
      label: "Site",
      value: "_warehouseItem._warehouseMove._warehouseSite.description",
    },
  ];

  const inputs = {
    className: "grid grid-rows-2 gap-4",
    inputs: [
      {
        className: "grid grid-cols-4 gap-4",
        inputs: [
          {
            hasOperator: true,
            label: "Sort By 1st",
            name: "sort1",
            options: sortOptions.filter(
              option =>
                ![
                  formik?.values?.sort2?.value,
                  formik?.values?.sort3?.value,
                ].includes(option.value),
            ),
            operatorOptions: [
              { label: "Ascending", value: "ASC" },
              { label: "Descending", value: "DESC" },
            ],
            type: "multi-select",
          },
          {
            hasOperator: true,
            label: "Sort By 2nd",
            name: "sort2",
            options: sortOptions.filter(
              option =>
                ![
                  formik?.values?.sort1?.value,
                  formik?.values?.sort3?.value,
                ].includes(option.value),
            ),
            operatorOptions: [
              { label: "Ascending", value: "ASC" },
              { label: "Descending", value: "DESC" },
            ],
            type: "multi-select",
          },
          {
            hasOperator: true,
            label: "Sort By 3rd",
            name: "sort3",
            options: sortOptions.filter(
              option =>
                ![
                  formik?.values?.sort1?.value,
                  formik?.values?.sort2?.value,
                ].includes(option.value),
            ),
            operatorOptions: [
              { label: "Ascending", value: "ASC" },
              { label: "Descending", value: "DESC" },
            ],
            type: "multi-select",
          },
          {
            label: "limit",
            name: "limit",
            onChange: e => {
              const { value } = e.target;
              formik.setFieldValue("limit", parseInt(value) || 10);
            },
            type: "number",
          },
        ],
      },
    ],
  };

  const gridInputProps = {
    formik,
    ...inputs,
  };

  return <GridInputs {...gridInputProps} />;
};

export default Sort;
