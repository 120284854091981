import * as React from "react";
import { Link, useParams } from "react-router-dom";

import SectionHeader from "../../components/SectionHeader";
import { Table } from "../../../../../../components/Functional";
import { FETCH_JOB_REQUESTS_V2 as fetchJobsV2 } from "../../../../../../graphql/query/Job";

const mapColor = status => {
  switch (status) {
    case "Canceled":
      return "text-red-500";
    case "Complete":
      return "text-green-500";
    case "Pending":
      return "text-yellow-500";
    default:
      return "text-black-500";
  }
};
const InstanceJobs = props => {
  const { id: instanceId } = useParams();

  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "jobsV2",
    headers: [
      {
        label: "Job#",
        name: "id",
        type: "custom",
        component: ({ values }) => (
          <Link className="underline" to={`/jobs/${values?.id}/details`}>
            {values?.id}
          </Link>
        ),
      },
      { label: "Type", name: "jobType.description", type: "label" },
      {
        label: "Status",
        type: "custom",
        component: ({ values }) => (
          <span className="flex gap-1">
            <span
              className={`overflow-hidden text-ellipsis text-base leading-5 ${mapColor(
                values?.jobStatus?.description,
              )}`}
            >
              {values?.jobStatus?.description}
            </span>
          </span>
        ),
      },
      { label: "Date Requested", name: "date", type: "date" },
      { label: "Requested By", name: "requestUser.name", type: "label" },
      { label: "Due Date", name: "dueDate", type: "date" },
      { label: "Confirmed", name: "user.name", type: "label" },
      {
        label: "Cost of the Job",
        type: "custom",
        component: ({ values }) => (
          <span className="flex gap-1">
            <span className="overflow-hidden text-ellipsis text-base font-medium leading-5 text-black">
              {values?.hoursvan * values?.ratevan +
                values?.hours * values?.rate +
                values?.overtimehours * values?.rateOvertime +
                values?.expenses}
            </span>
          </span>
        ),
      },
    ],
    inputs: [],
    FETCH_QUERY: fetchJobsV2({
      jobType: {},
      requestUser: {},
      user: {},
      jobStatus: {},
    }),
    className: "sm:px-0 lg:px-0",
    variables: {
      _jobItem: {
        _instance: {
          id: {
            operator: "eq",
            value: +instanceId,
          },
        },
      },
    },
  };

  return (
    <div className="w-full">
      <SectionHeader title="Jobs" />
      <div className="mb-6 w-full">
        <Table {...tableProps} />
      </div>
    </div>
  );
};

export default InstanceJobs;
