import { Table } from "../../../../../../components/Functional";
import Actions from "./components/Actions";
import SectionHeader from "../../components/SectionHeader";
import { Modal, Button } from "../../../../../../components/ui";
import AddDocuments from "./components/AddDocuments";
import { FETCH_INSTANCE_DOCUMENTS } from "../../../../../../graphql/query/InstanceDocument";

const InstanceDocuments = props => {
  const { instanceId, instance } = props || {};

  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "instanceDocuments",
    headers: [
      { label: "File Name", name: "fileName", type: "label" },
      { label: "File Type", name: "fileType", type: "label" },
      { label: "Date", name: "updatedAt", type: "date" },
      { label: "", name: "action", type: "custom", component: Actions },
    ],
    inputs: [{ label: "Name", name: "name", type: "text", view: "partial" }],
    FETCH_QUERY: FETCH_INSTANCE_DOCUMENTS,
    variables: {
      instanceId: +instanceId,
    },
    className: "sm:px-0 lg:px-0",
  };

  const modalProps = {
    body: AddDocuments,
    closeOnBackdrop: true,
    instance,
    hideCloseButton: true,
    scale: "md",
  };

  const actions = [
    {
      component: () => {
        return (
          <Modal {...modalProps}>
            <Button label="Upload" action="black" />
          </Modal>
        );
      },
    },
  ];

  return (
    <div className="w-full">
      <SectionHeader title="Documents" actions={actions} />
      <div className="mb-6 w-full">
        <Table {...tableProps} />
      </div>
    </div>
  );
};

export default InstanceDocuments;
