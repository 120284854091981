import { gql } from "@apollo/client";
import { ShipmentDocument } from "../fragments";

const FETCH_SHIPMENT_DOCUMENTS = gql`
  query FetchShipmentDocuments($input: ShipmentDocumentFilterInput!) {
    shipmentDocuments(input: $input) {
      count
      edges {
        ${ShipmentDocument}
      }
    }
  }
`;

export { FETCH_SHIPMENT_DOCUMENTS };
