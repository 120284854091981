import { gql } from "@apollo/client";
import { ContactLinkType } from "../fragments";

const FETCH_CONTACT_LINK_TYPES = gql`
  query ContactLinkTypes {
    contactLinkTypes {
      ${ContactLinkType}
    }
  }
`;

export { FETCH_CONTACT_LINK_TYPES };
