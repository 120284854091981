import { useMutation } from "@apollo/client";

import { Button, Modal } from "../../../../components/ui";
import { EXPORT_EMAILS } from "../../../../graphql/mutation/Contact";

const ExportEmailsBody = props => {
  const { closeModal, ids, listId, search, select } = props || {};
  const [exportEmails, { loading }] = useMutation(EXPORT_EMAILS);

  const handleClick = () => {
    exportEmails({
      variables: {
        input: {
          ...(select === "all"
            ? { ...(listId ? { listId } : { search }) }
            : { ids }),
        },
      },
    }).then(resp => {
      const { data: { exportEmails: { success, url } = {} } = {} } = resp;

      if (success) {
        window.open(url, "_blank");
        closeModal();
      }
    });
  };

  return (
    <div className="mt-6 flex w-full flex-col">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col">
          <div className="text-2xl font-bold">Export Emails:</div>
          <div className="pt-4 text-base font-normal">
            This will export emails of the selected contacts. Are you sure you
            want to continue?
          </div>
        </div>
        <div className="flex flex-col">
          <div>
            <Button
              action="default"
              className="mr-4"
              label="Cancel"
              onClick={() => {
                closeModal();
              }}
            />
            <Button
              label={loading ? "Exporting" : "Export"}
              disabled={loading}
              onClick={handleClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const ExportEmailsModal = props => {
  const { ids } = props || {};

  const disabled = ids?.length == 0;
  const modalProps = {
    body: ExportEmailsBody,
    closeOnBackdrop: false,
    disabled,
    hideCloseButton: true,
    scale: "md",
    ...props,
  };

  return (
    <Modal {...modalProps}>
      <Button label="Export Emails" disabled={disabled} action="dark" />
    </Modal>
  );
};

export { ExportEmailsBody, ExportEmailsModal };
