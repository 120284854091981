import { gql } from "@apollo/client";
import { PhotoMaterial as photoMaterial } from "../fragments";

const CREATE_PHOTO_MATERIAL = props => gql`
  mutation CreatePhotoMaterial($input: CreatePhotoMaterialInput!) {
    createPhotoMaterial(input: $input) {
      error
      photoMaterial {
        ${photoMaterial(props)}
      }
      success
    }
  }
`;

const UPDATE_PHOTO_MATERIAL = props => gql`
  mutation UpdatePhotoMaterial($input: UpdatePhotoMaterialInput!) {
    updatePhotoMaterial(input: $input) {
      error
      photoMaterial {
        ${photoMaterial(props)}
      }
      success
    }
  }
`;

export { CREATE_PHOTO_MATERIAL, UPDATE_PHOTO_MATERIAL };
