import { gql } from "@apollo/client";
import { ContactArtist as contactArtist } from "../fragments";

const CREATE_CONTACT_ARTIST = props => gql`
  mutation CreateContactArtist($input: CreateContactArtistInput!) {
    createContactArtist(input: $input) {
      success
      error
      contactArtist {
        ${contactArtist(props)}
      }
    }
  }
`;

const DELETE_CONTACT_ARTIST = gql`
  mutation DeleteContactArtist($input: DeleteContactArtistInput!) {
    deleteContactArtist(input: $input) {
      success
      error
    }
  }
`;

export { CREATE_CONTACT_ARTIST, DELETE_CONTACT_ARTIST };
