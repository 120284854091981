import { useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import { useEffect } from "react";
import * as Yup from "yup";

import { Button, getInput } from "../../../../../../../components/ui";
import { FETCH_CONTACT_LINK_TYPES } from "../../../../../../../graphql/query/ContactLinkType";
import {
  CREATE_CONTACT_LINK as createContactLinkMutation,
  UPDATE_CONTACT_LINK as updateContactLinkMutation,
} from "../../../../../../../graphql/mutation/ContactLink";

const AddAffiliation = props => {
  const { closeModal, contact, affiliation, isOpen } = props;
  const { data: { contactLinkTypes = [] } = {} } = useQuery(
    FETCH_CONTACT_LINK_TYPES,
  );
  const [createContactLink, { loading: createLoading }] = useMutation(
    createContactLinkMutation(),
    { refetchQueries: ["FetchContactLinks"] },
  );
  const [updateContactLink, { loading: updateLoading }] = useMutation(
    updateContactLinkMutation(),
  );
  const loading = createLoading || updateLoading;

  const handleClick = () => {
    const { typeId, linkId, contactId, description, expired } = formik.values;

    (affiliation
      ? updateContactLink({
          variables: {
            input: {
              id: affiliation?.id,
              description,
              expired,
              linkId,
              typeId,
            },
          },
        }).then(resp => {
          const {
            data: { updateContactLink: { success } = {} },
          } = resp || {};
          if (success) {
            closeModal();
          }
        })
      : createContactLink({
          variables: {
            input: {
              contactId,
              description,
              expired,
              linkId,
              typeId,
            },
          },
        })
    ).then(resp => {
      const {
        data: {
          createContactLink: { success } = {},
          updateContactCatalogue: { success: updateSuccess } = {},
        } = {},
      } = resp || {};
      if (success || updateSuccess) {
        closeModal();
      }
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      typeId: affiliation?.type?.id,
      linkId: affiliation?.link?.id,
      contactId: contact?.id,
      description: affiliation?.description || "",
      expired: affiliation?.expired,
      search: "",
    },
    onSubmit: handleClick,
    validationSchema: Yup.object({
      description: Yup.string().required("Required"),
      linkId: Yup.number().required("Required"),
      typeId: Yup.number().required("Required"),
    }),
  });

  useEffect(() => {
    formik?.resetForm();
  }, [isOpen]);

  const inputs = [
    {
      label: "Contact Search",
      name: "linkId",
      type: "contactSearch",
    },
    {
      label: "Description",
      name: "description",
      type: "text",
      className: "row-span-1",
    },
    {
      label: "Relationship Type",
      name: "typeId",
      options: contactLinkTypes?.map(item => ({
        label: item.description,
        value: item.id,
      })),
      type: "multi-select",
    },
    {
      singleCheckbox: true,
      label: "Expired",
      name: "expired",
      type: "checkbox",
      options: [{ label: "Expired", value: -1 }],
      className: "pt-4",
    },
  ];

  return (
    <div className="flex w-full flex-col p-8">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          {affiliation ? "Update" : "Create"} Add Affiliation record for{" "}
          {contact?.name}
        </div>
        <div className="flex flex-row">
          <Button
            action="default"
            className="mr-8"
            label="Cancel"
            onClick={() => {
              closeModal();
            }}
          />
          <Button
            label={
              loading
                ? affiliation
                  ? "Updating"
                  : "Creating"
                : affiliation
                  ? "Update Record"
                  : "Create Record"
            }
            disabled={loading}
            onClick={formik?.submitForm}
          />
        </div>
      </div>
      <div className="grid w-full grid-cols-2 gap-x-20 gap-y-4 pb-10 pt-20">
        {inputs?.map((item, index) => {
          const inputProps = {
            ...item,
            formik,
          };
          return (
            <div key={index} className={`w-full ${item?.className}`}>
              {getInput(inputProps)}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AddAffiliation;
