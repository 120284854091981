import React from "react";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";

const Carousel = props => {
  const {
    data,
    visibleSlides = 3,
    sliderWidth = 150,
    sliderHeight = 180,
    onClickChangeSlide,
  } = props;
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [currentDataName, setCurrentDataName] = React.useState(
    data?.[0]?.name || "",
  );
  const handleClickChangeSlide = index => {
    setCurrentSlide(index);
    onClickChangeSlide?.(data[index]);
  };

  React.useEffect(() => {
    if (data?.[0]?.name && data?.[0]?.name !== currentDataName) {
      setCurrentDataName(data?.[0]?.name || "");
      setCurrentSlide(0);
    }
  }, [data]);
  return (
    <div className="carousel-component relative h-full w-full">
      <CarouselProvider
        naturalSlideWidth={sliderWidth + 20}
        naturalSlideHeight={sliderHeight + 15}
        totalSlides={data.length}
        visibleSlides={visibleSlides}
        currentSlide={currentSlide}
        className="h-full"
      >
        <Slider className="h-full">
          {data?.map((item, index) => (
            <Slide index={index} key={index}>
              {item?.component || item}
            </Slide>
          ))}
        </Slider>
        {data.length > visibleSlides && currentSlide > 0 && (
          <div className="slider-button absolute left-0 top-[calc(50%-15px)]">
            <ButtonBack
              onClick={() => handleClickChangeSlide(currentSlide - 1)}
            >
              <ChevronLeftIcon className="h-5 w-5 cursor-pointer" />
            </ButtonBack>
          </div>
        )}
        {data.length > visibleSlides &&
          currentSlide + (visibleSlides + 1) <= data.length && (
            <div className="slider-button absolute right-0 top-[calc(50%-15px)]">
              <ButtonNext
                onClick={() => handleClickChangeSlide(currentSlide + 1)}
              >
                <ChevronRightIcon className="h-5 w-5 cursor-pointer" />
              </ButtonNext>
            </div>
          )}
      </CarouselProvider>
    </div>
  );
};

export default Carousel;
