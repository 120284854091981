import { gql } from "@apollo/client";
import { CRMRegistrar as crmRegistrar } from "../fragments";

const FETCH_CRM_REGISTRARS = props => gql`
  query FetchCRMRegistrars($input: CRMRegistrarFilterInput!) {
    crmRegistrars(input: $input) {
      count
      edges {
        ${crmRegistrar(props)}
      }
    }
  }
`;

const FETCH_CRM_REGISTRARS_V2 = props => gql`
  query FetchCRMRegistrarsV2($input: AdvCRMRegistrarFilterInput!) {
    crmRegistrarsV2(input: $input) {
      count
      edges {
        ${crmRegistrar(props)}
      }
      sqlQuery
    }
  }
`;

export { FETCH_CRM_REGISTRARS, FETCH_CRM_REGISTRARS_V2 };
