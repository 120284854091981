import { gql } from "@apollo/client";
import { Invoice as invoice, Shipment as shipment } from "../fragments";

const CREATE_INVOICE = props => gql`
  mutation CreateInvoice($input: CreateInvoiceInput!) {
    createInvoice(input: $input) {
      success
      error
      invoice {
        ${invoice(props)}
      }
    }
  }
`;

const CREATE_INVOICE_SHIPMENT_WORK = props => gql`
  mutation CreateInvoiceShipmentWork($input: CreateInvoiceShipmentWorkInput!) {
    createInvoiceShipmentWork(input: $input) {
      success
      error
      shipment {
        ${shipment(props)}
      }
    }
  }
`;

const EXPORT_ADHOC_REPORT_INVOICES = gql`
  mutation ExportAdhocReportInvoices($input: ExportInvoicesInput!) {
    exportAdhocReportInvoices(input: $input) {
      error
      success
      url
    }
  }
`;

const EXPORT_INVOICES = props => gql`
  mutation ExportInvoices($input: ExportInvoicesInput!) {
    exportInvoices(input: $input) {
      count
      edges {
        ${invoice(props)}
      }
      error
    }
  }
`;

const RE_INVOICE = props => gql`
  mutation ReInvoice($input: ReInvoiceInput!) {
    reInvoice(input: $input) {
      success
      error
      invoice {
        ${invoice(props)}
      }
    }
  }
`;

const REMOVE_INVOICE_ITEM = props => gql`
  mutation RemoveInvoiceItem($input: RemoveInvoiceItemInput!) {
    removeInvoiceItem(input: $input) {
      success
      error
      invoice {
        ${invoice(props)}
      }
    }
  }
`;

const UPDATE_INVOICE = props => gql`
  mutation UpdateInvoice($input: UpdateInvoiceInput!) {
    updateInvoice(input: $input) {
      success
      error
      invoice {
        ${invoice(props)}
      }
    }
  }
`;

export {
  CREATE_INVOICE,
  CREATE_INVOICE_SHIPMENT_WORK,
  EXPORT_ADHOC_REPORT_INVOICES,
  EXPORT_INVOICES,
  RE_INVOICE,
  REMOVE_INVOICE_ITEM,
  UPDATE_INVOICE,
};
