import { gql } from "@apollo/client";
import { PayType } from "../fragments";

const FETCH_PAY_TYPES = gql`
  query FetchPayTypes {
    payTypes {
      ${PayType}
    }
  }
`;

export { FETCH_PAY_TYPES };
