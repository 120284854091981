import { gql } from "@apollo/client";
import { ContactLink as contactLink } from "../fragments";

const CREATE_CONTACT_LINK = props => gql`
  mutation CreateContactLink($input: CreateContactLinkInput!) {
    createContactLink(input:$input) {
      success
      error
      contactLink {
        ${contactLink(props)}
      }
    }
  }
`;

const DELETE_CONTACT_LINK = gql`
  mutation DeleteContactLink($input: DeleteContactLinkInput!) {
    deleteContactLink(input: $input) {
      success
      error
    }
  }
`;

const UPDATE_CONTACT_LINK = props => gql`
  mutation UpdateContactLink($input: UpdateContactLinkInput!) {
    updateContactLink(input: $input) {
      success
      error
      contactLink {
        ${contactLink(props)}
      }
    }
  }
`;

export { CREATE_CONTACT_LINK, DELETE_CONTACT_LINK, UPDATE_CONTACT_LINK };
