import { gql } from "@apollo/client";
import { JobItem as jobItem } from "../fragments";

const FETCH_JOB_ITEMS = props => gql`
  query FetchJobItems($input: JobItemFilterInput!) {
    jobItems(input: $input) {
      count
      edges {
        ${jobItem(props)}
      }
      error
      sqlQuery
    }
  }
`;

const FETCH_JOB_ITEMS_V2 = props => gql`
  query FetchJobItemsV2($input: AdvJobItemFilterInput!) {
    jobItemsV2(input: $input) {
      count
      edges {
        ${jobItem(props)}
      }
      error
      sqlQuery
    }
  }
`;

export { FETCH_JOB_ITEMS, FETCH_JOB_ITEMS_V2 };
