import { useQuery } from "@apollo/client";
import { useEffect } from "react";

import { GridInputs } from "../../../../../components/Functional";
import { FETCH_ARTISTS as fetchArtists } from "../../../../../graphql/query/Artist";
import { FETCH_ENTITIES } from "../../../../../graphql/query/Entity";

const Items = props => {
  const { formik } = props || {};
  const { data: { artists } = {} } = useQuery(fetchArtists());
  const { data: { entities } = {} } = useQuery(FETCH_ENTITIES);

  useEffect(() => {
    if (formik?.values?._unlocated) {
      formik.setFieldValue("_warehouseMove.locationId", {
        value: null,
        operator: "eq",
      });
    } else {
      formik.setFieldValue("_warehouseMove.locationId", null);
    }
  }, [formik?.values?._unlocated]);

  const inputs = {
    className: "grid grid-cols-4 gap-16 mt-5",
    inputs: [
      {
        className: "grid-cols-1 gap-4",
        inputs: [
          {
            label: "Item #",
            name: "_warehouseMove._warehouseItem.id",
            type: "number",
          },
        ],
      },
      {
        className: "grid-cols-1 gap-4",
        inputs: [
          {
            label: "User",
            name: "_warehouseMove.user",
            type: "multi-select",
            options:
              entities?.edges?.map(item => ({
                label: item.name,
                value: item.name,
              })) || [],
          },
        ],
      },
      {
        className: "grid-cols-1 gap-4",
        inputs: [
          {
            label: "Artist",
            name: "_warehouseMove._warehouseItem.artistId",
            type: "multi-select",
            options: artists?.map(artist => ({
              label: artist?.name,
              value: artist?.id,
            })),
          },
        ],
      },
      {
        className: "grid-cols-1 gap-4",
        inputs: [
          {
            label: "Unlocated",
            name: "_unlocated",
            type: "toggle",
            designType: "checkbox",
          },
          {
            label: "Customs Controlled",
            name: "_warehouseMove.ti",
            type: "toggle",
            designType: "threeStateCheckbox",
          },
        ],
      },
    ],
  };

  const gridInputProps = {
    formik,
    ...inputs,
  };

  return (
    <div className="pb-4">
      <GridInputs {...gridInputProps} />
    </div>
  );
};

export default Items;
