import React, { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useMutation } from "@apollo/client";

import { Button, getInput, Modal } from "../../../../../components/ui";
import { UPDATE_DOCUMENT_PAGE } from "../../../../../graphql/mutation/DocumentPage";
import { UPLOAD_FILE } from "../../../../../graphql/mutation/User";
import { useGetQueryParams } from "../../../../../hooks/queryParams";

const ImageField = props => {
  const { setValue } = props || {};
  const [uploadFile, { loading }] = useMutation(UPLOAD_FILE);
  // const [getSignedUrl] = useMutation(GET_SIGNED_URL)
  const handleUpload = e => {
    e.preventDefault();
    const file = e.target.files[0];
    // Code for uploading file to AWS S3 client side
    // getSignedUrl({
    //   variables: {
    //     input: {
    //       name,
    //       type
    //     }
    //   }
    // }).then(resp => {
    //   const { data } = resp
    //   const { getSignedUrl } = data || {}
    //   const { url, signedRequest } = getSignedUrl || {}

    //   console.log('url***',url, signedRequest)

    //   const xhr = new XMLHttpRequest()
    //   xhr.open('PUT', signedRequest)
    //   xhr.onload = () => {
    //     if (xhr.status === 200) {
    //       console.log('file uploaded')
    //     }
    //   }
    //   xhr.send(file)
    // })

    // Code for uploading file to AWS S3 server side
    uploadFile({
      variables: {
        file,
      },
    }).then(resp => {
      setValue?.(resp?.data?.uploadFile?.url);
    });
  };

  const inputProps = {
    ...props,
    loading,
    onChange: handleUpload,
    w: "w-96",
    h: "h-128",
  };

  return <div className="flex">{getInput(inputProps)}</div>;
};

// Input fields structure
// const meta = {
//   "fields": [
//     { "label": "Artist Image", "name": "image_1", "type": "img", "value": "https://cdn.pixabay.com/photo/2012/12/27/19/40/chain-link-72864_1280.jpg" },
//     { "label": "Artist Title", "name": "text_1", "type": "text", "value": "Title", "enabled": true }
//   ]
// }
// const{ fields } = meta || {}

const EditPage = props => {
  const { document } = props || {};
  const queryParams = useGetQueryParams();
  const documentId = queryParams.get("document");
  const pageId = queryParams.get("pageid");
  const [updateDocumentPage, updateData] = useMutation(UPDATE_DOCUMENT_PAGE);
  const page = document?.pages?.find(item => item.id === +pageId);
  const navigate = useNavigate();
  const { meta: { fields = [] } = {} } = page || {};
  const parentPage = `/create-pdf/page-template?document=${documentId}`;

  console.log("fields***", fields);

  const formik = useFormik({
    initialValues: {
      fields,
    },
    onSubmit: values => {
      const formikValues = formik?.values?.fields?.map(item => {
        if (item.type === "img") {
          const splitUrl = item?.value?.split("/");
          const replaceUrl = splitUrl?.splice(0, 3).join("/");
          const replacedImage = item?.value
            ?.replace(`${replaceUrl}/`, "")
            .split("?")[0];
          return {
            ...item,
            value: replacedImage,
          };
        }
        return item;
      });
      updateDocumentPage({
        variables: {
          input: {
            id: page?.id,
            meta: { fields: formikValues },
          },
        },
      }).then(resp => {
        if (resp.data.updateDocumentPage.success) {
          navigate(parentPage);
        }
      });
    },
  });

  useEffect(() => {
    // reinitialise formil values if field values update
    if (fields && fields.length) {
      formik.resetForm({ values: { fields } });
    }
  }, [fields]);

  const renderFields = fields?.map((item, index) => {
    const inputProps = {
      ...item,
      className: "max-w-[450px] w-full",
      ...formik?.getFieldProps(`fields[${index}].value`),
    };

    const richTextProps = {
      ...item,
      className: "max-w-[450px] w-full",
      type: "richtext",
      ...formik?.getFieldProps(`fields[${index}].value`),
      onChange: formik?.getFieldHelpers(`fields[${index}].value`)?.setValue,
    };

    const toggleProps = {
      label: "Show on PDF",
      type: "toggle",
      ...formik?.getFieldProps(`fields[${index}].enabled`),
      onChange: formik?.getFieldHelpers(`fields[${index}].enabled`)?.setValue,
    };

    const textComponent =
      item?.type === "richText"
        ? getInput(richTextProps)
        : getInput(inputProps);

    const toggleInput =
      item?.type === "text" || item?.type === "richText"
        ? getInput(toggleProps)
        : null;

    return (
      <Fragment key={index}>
        {item?.type !== "img" && (
          <div className="mb-6 flex flex-row items-center">
            {textComponent}
            <div className="ml-8 mt-4">{toggleInput}</div>
          </div>
        )}
      </Fragment>
    );
  });

  const renderImages = fields
    .filter(item => item?.type === "img")
    ?.map((item, index) => {
      const fieldIndex = fields.indexOf(item);
      const inputProps = {
        ...item,
        ...formik?.getFieldProps(`fields[${fieldIndex}].value`),
        ...formik?.getFieldHelpers(`fields[${fieldIndex}].value`),
      };
      return <ImageField key={index} {...inputProps} />;
    });

  const cancelModalprops = {
    title: "Are You Sure?",
    description:
      "There are some unsaved changes that will be lost if you leave this page.",
    scale: "sm",
    disabled: !formik.dirty,
    body: ({ closeModal }) => {
      return (
        <div className="mt-4 flex grid grid-cols-2 gap-4">
          <Button
            label={"Continue"}
            onClick={() => {
              navigate(parentPage);
              closeModal?.();
            }}
          />
          <Button
            label={"Cancel"}
            action={"default"}
            onClick={() => {
              closeModal?.();
            }}
          />
        </div>
      );
    },
  };

  return (
    <div className="py-8">
      <div className="mb-8 flex items-center justify-between">
        <div>
          <h2 className="mb-2 text-lg font-bold text-gray-700">
            Page {page?.position + 1}
          </h2>
          <div className="mb-4 font-medium text-black">
            Template: {page?.name}
          </div>
        </div>
        <div className="flex flex-row">
          <Button
            label="Reset"
            type="submit"
            disabled={!formik.dirty}
            onClick={formik.resetForm}
            className="mr-4"
          />
          <Button
            label="Update"
            type="submit"
            disabled={!formik.dirty || updateData.loading}
            onClick={formik.handleSubmit}
            className="mr-4"
          />

          <Modal {...cancelModalprops}>
            <Button
              label="Cancel"
              action={"default"}
              onClick={() => !formik.dirty && navigate(parentPage)}
            />
          </Modal>
        </div>
      </div>
      <div className="lg:flex lg:flex-row">
        <div className="mb-8 mr-16 lg:mb-0">{renderImages}</div>
        <div className="w-full">{renderFields}</div>
      </div>
    </div>
  );
};

export default EditPage;
