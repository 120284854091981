import React from "react";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { Button } from "../../components/ui";
import { useHandleLogout } from "../../util";
import LogoIcon from "../../assets/icons/LogoIcon";
import MicrosoftIcon from "../../assets/icons/MicrosoftIcon";
import { LOGIN } from "../../graphql/mutation/User";
import { useMutation } from "@apollo/client";

const Login = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const MicrosoftLogo = <MicrosoftIcon className="mr-3 h-5" />;
  const handleLogout = useHandleLogout();
  const [login] = useMutation(LOGIN);

  const handleLogin = async () => {
    await instance
      .loginPopup(loginRequest)
      .then(e => {
        setTimeout(() => login(), 1000);
      })
      .catch(e => {
        console.error(e);
      });
  };

  return (
    <>
      <div className="flex grid min-h-full min-w-full grid-cols-5 flex-col justify-center">
        <div className="col-span-3 hidden flex-col items-end justify-center border-r border-gray-200 lg:flex lg:px-[10%] lg:py-14">
          <LogoIcon className="mb-6 h-6" />
          <div className="text-lg font-medium tracking-wider text-gray-400">
            New Inventory System
          </div>
        </div>
        <div className="col-span-5 mx-auto flex w-full max-w-screen-sm flex-col justify-center px-4 py-8 lg:col-span-2 lg:px-14 lg:py-14">
          <div className="flex flex-col justify-center">
            {!isAuthenticated && (
              <div className="text-lg font-medium tracking-wider text-gray-900">
                Sign In
              </div>
            )}
          </div>
          <div className="mt-4">
            <div>
              <div>
                {isAuthenticated ? (
                  <p className="mb-4 text-lg font-medium tracking-wider text-gray-900">
                    Signed in as{" "}
                    <span className="text-sky-700">
                      {accounts?.[0]?.username}
                    </span>
                  </p>
                ) : null}
                <div className="mt-1 grid">
                  {isAuthenticated ? (
                    <Button
                      label="Log Out"
                      action="default"
                      onClick={() => handleLogout()}
                      icon={MicrosoftLogo}
                      className="flex flex-row items-center py-5"
                    />
                  ) : (
                    <Button
                      label="Microsoft"
                      action="default"
                      onClick={() => handleLogin(instance)}
                      icon={MicrosoftLogo}
                      className="flex flex-row items-center py-5"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
