import { gql } from "@apollo/client";
import { Partner } from "../fragments";

const FETCH_PARTNER = gql`
  query FetchPartner {
    partner {
      ${Partner}
    }
  }
`;

export { FETCH_PARTNER };
