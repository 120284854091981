import { useQuery } from "@apollo/client";
import { useEffect } from "react";

import { GridInputs } from "../../../../../../../../../components/Functional";
import { FETCH_ARTWORK_CATEGORIES } from "../../../../../../../../../graphql/query/ArtworkCategory";
import { FETCH_ARTWORK_CLASSES } from "../../../../../../../../../graphql/query/ArtworkClass";
import { FETCH_ARTWORK_STANDARDS } from "../../../../../../../../../graphql/query/ArtworkStandard";
import { FETCH_ARTWORK_TARIFFS } from "../../../../../../../../../graphql/query/ArtworkTariff";
import { FETCH_COUNTRIES } from "../../../../../../../../../graphql/query/Country";

import useUser from "../../../../../../../../../hooks/useUser";

const ArtworkDetailCol3 = ({ disabled, formik, submitOnBlur = false }) => {
  const { data: { artworkCategories = [] } = {} } = useQuery(
    FETCH_ARTWORK_CATEGORIES,
  );
  const { data: { artworkClasses = [] } = {} } = useQuery(
    FETCH_ARTWORK_CLASSES,
  );
  const { data: { artworkStandards = [] } = {} } = useQuery(
    FETCH_ARTWORK_STANDARDS,
  );
  const { data: { artworkTariffs = [] } = {} } = useQuery(
    FETCH_ARTWORK_TARIFFS,
  );
  const { data: { countries = [] } = {} } = useQuery(FETCH_COUNTRIES);

  const { hasPermission } = useUser();

  useEffect(() => {
    formik?.values?.categoryId &&
      formik?.setFieldValue(
        "goodsCode",
        artworkCategories?.find(item => item?.id == formik?.values?.categoryId)
          ?.goodsCode,
      );
  }, [formik?.values?.categoryId]);

  const inputs = {
    className: "grid grid-cols-4 gap-4",
    inputs: [
      {
        className: "col-span-3 gap-4",
        inputs: [
          {
            disabled: !hasPermission("UPDATE_ARTWORK_CLASS"),
            label: "Class",
            name: "classId",
            type: "multi-select",
            options: [
              ...artworkClasses.map(item => ({
                label: item?.name,
                value: item?.id,
              })),
            ],
            submitOnBlur,
          },
          {
            disabled: !hasPermission("UPDATE_ARTWORK_STANDARD"),
            label: "Handling",
            name: "standardId",
            type: "multi-select",
            options: [
              ...artworkStandards.map(item => ({
                label: item?.name,
                value: item?.id,
              })),
            ],
            submitOnBlur,
          },
          {
            disabled: !hasPermission("UPDATE_ARTWORK_GOODS_CODE"),
            label: "Category",
            name: "categoryId",
            type: "multi-select",
            options: [
              ...artworkCategories.map(item => ({
                label: item?.name,
                value: item?.id,
              })),
            ],
            submitOnBlur,
          },
          {
            disabled: !hasPermission("UPDATE_ARTWORK_GOODS_CODE"),
            label: "Goods Code",
            name: "goodsCode",
            options: artworkTariffs?.map(tariff => ({
              label: tariff?.code,
              value: tariff?.code,
            })),
            type: "multi-select",
            submitOnBlur,
          },
          {
            disabled,
            label: "Country of Origin",
            name: "countryOfOriginId",
            type: "multi-select",
            options:
              countries?.map(item => ({
                label: item?.name,
                value: item?.id,
              })) || [],
            submitOnBlur,
          },
        ],
      },
      {
        className: "col-span-1 gap-4",
        inputs: [
          {
            disabled,
            label: "",
            name: "confidential",
            type: "toggle",
            designType: "threeStateCheckbox",
            className: "h-14 pt-8",
            submitOnBlur,
          },
        ],
      },
    ],
  };

  const detailInputProps = {
    formik,
    ...inputs,
  };

  return <GridInputs {...detailInputProps} />;
};

export default ArtworkDetailCol3;
