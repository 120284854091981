import { gql } from "@apollo/client";
import ContactAddressType from "../fragments/ContactAddressType";

const FETCH_CONTACT_ADDRESS_TYPE = gql`
  query FetchContactAddressTypes {
    contactAddressTypes {
        ${ContactAddressType}
    }
  }
`;

export { FETCH_CONTACT_ADDRESS_TYPE };
