import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import { useEffect } from "react";
import * as Yup from "yup";

import { Button } from "../../../../../../../components/ui";
import { GridInputs } from "../../../../../../../components/Functional";

import { FETCH_BIBLIOGRAPHY_V2 } from "../../../../../../../graphql/query/Bibliography";
import {
  CREATE_BIBLIOGRAPHY,
  UPDATE_BIBLIOGRAPHY,
} from "../../../../../../../graphql/mutation/Bibliography";
import { validateDate } from "../../../../../../../util";

const AddBibliography = props => {
  const { artworkId, closeModal, values, isOpen } = props;

  const [createBibliography, { loading: loadingCreate }] = useMutation(
    CREATE_BIBLIOGRAPHY,
    {
      refetchQueries: [FETCH_BIBLIOGRAPHY_V2],
    },
  );

  const [updateBibliography, { loading: loadingUpdate }] = useMutation(
    UPDATE_BIBLIOGRAPHY,
    {
      refetchQueries: [FETCH_BIBLIOGRAPHY_V2],
    },
  );

  const addBibliography = () => {
    const input = {
      day: formik.values.day || null,
      month: formik.values.month || null,
      year: formik.values.year || null,
      title: formik.values.title,
      publication: formik.values.publication,
      illustrated: formik.values.illustrated,
    };

    if (values?.id) {
      input.id = values?.id;
    } else {
      input.artworkId = artworkId;
    }

    const action = values?.id ? updateBibliography : createBibliography;

    action({
      variables: {
        input,
      },
    }).then(() => {
      closeModal();
      formik.resetForm();
    });
  };

  const formik = useFormik({
    initialValues: values
      ? {
          day: values.day,
          month: values.month,
          year: values.year,
          title: values.title,
          publication: values.publication,
          illustrated: values.illustrated,
        }
      : {},
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: Yup.object({
      title: Yup.string().trim().required("Please enter a title."),
      day: Yup.number()
        .integer()
        .typeError("Please enter a day")
        .test("valid-date", "Invalid day", function (value, context) {
          const { day, month, year } = context.parent;

          return validateDate(day, month, year);
        }),
      month: Yup.number()
        .integer()
        .min(1, "Month must be between 1 and 12")
        .max(12, "Month must be between 1 and 12")
        .typeError("Please enter a month"),
      year: Yup.number()
        .integer()
        .typeError("Please enter a year")
        .min(1000, "Year must have 4 digit")
        .max(9999, "Year must be less than 9999"),
    }),
    onSubmit: addBibliography,
  });

  const inputs = {
    className: "grid grid-cols-2 gap-12",
    inputs: [
      {
        className: "grid grid-cols-1 gap-4",
        inputs: [
          {
            className: "grid grid-cols-3 gap-4",
            inputs: [
              { label: "Day", name: "day", type: "number" },
              { label: "Month", name: "month", type: "number" },
              { label: "Year", name: "year", type: "number" },
            ],
          },
          { label: "Title", name: "title", type: "text" },
        ],
      },
      {
        className: "grid grid-cols-1 gap-4",
        inputs: [
          { label: "Publication", name: "publication", type: "text" },
          {
            name: "illustrated",
            options: [{ label: "Illustrated", value: -1 }],
            singleCheckbox: true,
            type: "checkbox",
          },
        ],
      },
    ],
  };

  const inputProps = {
    ...inputs,
    formik,
  };

  const successLabel = values?.id ? "Edit" : "Add";

  useEffect(() => {
    formik.handleReset();
  }, [isOpen]);

  return (
    <div className="flex w-full flex-col p-8">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          {values?.id ? "Edit" : "Add"} Bibliography Record
        </div>
        <div className="flex flex-col">
          <div>
            <Button
              action="default"
              className="mr-8"
              label="Cancel"
              onClick={closeModal}
            />
            <Button
              label={`${successLabel}${
                loadingUpdate || loadingCreate ? "ing" : ""
              } Bibliography`}
              disabled={loadingUpdate || loadingCreate}
              onClick={formik.submitForm}
            />
          </div>
        </div>
      </div>
      <div className="mt-12 pb-10">
        <GridInputs {...inputProps} />
      </div>
    </div>
  );
};

export default AddBibliography;
