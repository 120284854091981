import { gql } from "@apollo/client";
import { Artist as artist } from "../fragments";

const FETCH_ARTISTS = props => gql`
  query FetchArtists {
    artists {
      ${artist(props)}  
    }
  }
`;

export { FETCH_ARTISTS };
