import { useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";

import { FETCH_SUPPORT_ARTICLE } from "../../../../../../graphql/query/SupportArticle";
import {
  CREATE_SUPPORT_ARTICLE,
  UPDATE_SUPPORT_ARTICLE,
} from "../../../../../../graphql/mutation/SupportArticle";
import { Button, getInput, Spinner } from "../../../../../../components/ui";
import { VALID_URL_REGEX } from "../../../../../../util/url";

const EditArticle = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { loading, data: { supportArticle = {} } = {} } = useQuery(
    FETCH_SUPPORT_ARTICLE,
    {
      variables: { id: +id },
      skip: id === "new",
    },
  );
  const [
    createSupportArticle,
    { client, loading: createSupportArticleLoading },
  ] = useMutation(CREATE_SUPPORT_ARTICLE);
  const [updateSupportArticle] = useMutation(UPDATE_SUPPORT_ARTICLE);

  const saveArticle = () => {
    if (id !== "new") {
      // update action
      updateSupportArticle({
        variables: {
          input: {
            id: +id,
            meta: formik?.values?.meta,
          },
        },
      }).then(() => {
        navigate("/admin/support");
      });
    } else {
      createSupportArticle({
        variables: {
          input: {
            meta: formik?.values?.meta,
          },
        },
      }).then(resp => {
        const {
          data: { createSupportArticle: { success } = {} },
        } = resp || {};
        if (success) {
          // TODO: handle this through updating cache to improve performance
          client.resetStore();
          navigate("/admin/support");
        }
      });
      // save action
    }
  };

  const formik = useFormik({
    initialValues: {},
    validationSchema: Yup.object({
      meta: Yup.object({
        summary: Yup.string().trim().required("Please enter a summary"),
        title: Yup.string().trim().required("Please enter a title."),
        module: Yup.string().trim().required("Please select a module."),
        documentLink: Yup.string().matches(
          VALID_URL_REGEX,
          "Enter a valid url",
        ),
      }),
    }),
    onSubmit: saveArticle,
  });

  const pageOptions = {
    pdf: [
      { label: "Dashboard", value: "" },
      { label: "Title", value: "create-pdf/title" },
      { label: "Search & Select", value: "create-pdf/select-images" },
      { label: "Format Pages", value: "create-pdf/page-template" },
      { label: "Edit Page", value: "create-pdf/edit-page" },
    ],
    email: [
      { label: "Dashboard", value: "email" },
      { label: "Content", value: "email/:id/content" },
      { label: "Recipients", value: "email/:id/recipients" },
      { label: "Review", value: "email/:id/review" },
    ],
    admin: [
      { label: "Changelog Dashboard", value: "admin/changelog" },
      { label: "Users Dashboard", value: "admin/users" },
      { label: "Edit User", value: "admin/users/:id/edit" },
      {
        label: "Edit User Permission",
        value: "admin/users/:id/edit/permission",
      },
      { label: "Roles Dashboard", value: "admin/role" },
      { label: "Edit Role", value: "admin/role/:id/edit" },
      { label: "Support Articles Dashboard", value: "admin/support" },
      { label: "Edit Support Article", value: "admin/support/:id/edit" },
    ],
    contact: [
      { label: "Contacts Dashboard", value: "contacts" },
      { label: "Contact Detail", value: "contacts/:id/details" },
    ],
  };

  const articleFormFields = {
    title: {
      bottomLabel: true,
      label: "Title",
      name: "meta.title",
      type: "text",
      variant: "simpleRight",
      inputClassName: "w-full",
      formik,
      placeholder: "Text Entry",
    },
    module: {
      bottomLabel: true,
      label: "Module",
      name: "meta.module",
      type: "select",
      variant: "simpleRight",
      inputClassName: "w-full",
      formik,
      placeholder: "Select a Module",
      options: [
        { label: "Admin", value: "admin" },
        { label: "Contact", value: "contact" },
        { label: "Email", value: "email" },
        { label: "PDF Generator", value: "pdf" },
      ],
    },
    documentLink: {
      bottomLabel: true,
      label: "Document Link",
      name: "meta.documentLink",
      type: "text",
      variant: "simpleRight",
      inputClassName: "w-full",
      formik,
      placeholder: "Sharepoint Document URL Here",
    },
    page: {
      bottomLabel: true,
      name: "meta.page",
      type: "select",
      variant: "simpleRight",
      inputClassName: "w-full",
      formik,
      placeholder: "Select a Page",
      options: formik?.values?.meta?.module
        ? pageOptions[formik?.values?.meta?.module]
        : [],
    },
    summary: {
      label: "Summary",
      name: "meta.summary",
      type: "textarea",
      inputClassName: "w-full",
      formik,
      variant: "simpleRight",
      placeholder: "Enter a summary",
    },
  };

  useEffect(async () => {
    if (
      Object.keys(formik?.values).length === 0 &&
      Object.keys(supportArticle).length > 0
    ) {
      await formik.resetForm({
        values: {
          ...supportArticle,
          meta: {
            ...supportArticle?.meta,
          },
        },
      });
      await formik.validateForm();
    }
  }, [supportArticle]);

  return (
    <>
      {loading && (
        <div className="absolute left-0 top-0 z-40 flex h-full w-full items-center justify-center bg-gray-50/[.7]">
          <Spinner />
        </div>
      )}
      <div className="flex w-full flex-row pb-10 font-bold">
        <div className="flex-1 text-2xl">
          {`${id !== "new" ? "Edit" : "Add"} Support Article`}
        </div>
        <div className="flex">
          <Button
            action="default"
            className="mr-8"
            label="Cancel"
            onClick={() => navigate("/admin/support")}
          />
          <Button
            action="black"
            disabled={createSupportArticleLoading}
            label={id === "new" ? "Create" : "Save"}
            loading={createSupportArticleLoading}
            onClick={formik?.submitForm}
          />
        </div>
      </div>
      <div className="w-full max-w-[1100px]">
        <div className="grid grid-cols-7 gap-x-20 gap-y-8 pt-12">
          <div className="col-span-4">{getInput(articleFormFields.title)}</div>
          <div className="col-span-3">{getInput(articleFormFields.module)}</div>
          <div className="col-span-4">
            {getInput(articleFormFields.documentLink)}
          </div>
          <div className="col-span-3">{getInput(articleFormFields.page)}</div>
        </div>
        <div className="w-full pt-8">{getInput(articleFormFields.summary)}</div>
      </div>
    </>
  );
};

export default EditArticle;
