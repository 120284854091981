const ContentZoomView = props => {
  const { customContent, contentAction } = props || {};

  if (customContent) {
    return (
      <div className="group relative h-full w-full">
        {customContent}
        <div className="absolute left-0 top-0 z-[100] hidden rounded bg-gray-300 group-hover:flex">
          <div className="relative scale-[2]">{customContent}</div>
          {contentAction}
        </div>
      </div>
    );
  }
};

export { ContentZoomView };
