import { useQuery } from "@apollo/client";
import { GridInputs } from "../../../../../components/Functional";
import { FETCH_ARTISTS as fetchArtists } from "../../../../../graphql/query/Artist";
import { FETCH_COMPANIES as fetchCompanies } from "../../../../../graphql/query/Company";
import { useEffect } from "react";

const Artworks = props => {
  const { formik } = props || {};

  const { data: { artists = [] } = {} } = useQuery(fetchArtists());
  const { data: { companies = [] } = {} } = useQuery(fetchCompanies());

  useEffect(() => {
    if (formik?.values?._consignmentWork?.null) {
      formik.setFieldValue("noInstance", {
        value: null,
        operator: "isNull",
      });
    }
  }, [formik?.values?._consignmentWork?.null]);

  const inputs = {
    className: "grid grid-cols-4 gap-16 mt-5",
    inputs: [
      {
        className: "flex flex-col gap-4",
        inputs: [
          {
            label: "Artist",
            name: "_consignmentWork._instance._edition._artwork._artist.id",
            type: "multi-select",
            options: artists?.map(item => ({
              label: item.name,
              value: item.id,
            })),
          },
          {
            label: "Ref",
            name: "_consignmentWork._instance.ref",
            type: "text",
          },
        ],
      },
      {
        className: "flex flex-col gap-4",
        inputs: [
          {
            label: "Title",
            name: "_consignmentWork._instance._edition._artwork.title",
            type: "text",
          },
        ],
      },
      {
        className: "flex flex-col gap-4",
        inputs: [
          {
            label: "Company",
            name: "_consignmentWork._instance.companyId",
            type: "multi-select",
            options: companies?.edges?.map(item => ({
              label: item.name,
              value: item.id,
            })),
          },
        ],
      },
      {
        className: "flex flex-col gap-4",
        inputs: [
          {
            label: "Null",
            name: "_consignmentWork.null",
            type: "toggle",
            designType: "twoStateCheckbox",
          },
        ],
      },
    ],
  };

  const gridInputProps = {
    formik,
    ...inputs,
  };

  return (
    <div className="w-full">
      <span className="flex items-center justify-between">
        <h2 className="text-2xl font-bold">Instance</h2>
      </span>
      <GridInputs {...gridInputProps} />
    </div>
  );
};

export default Artworks;
