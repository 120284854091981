import { gql } from "@apollo/client";
import { Offer as offer } from "../fragments";

const CREATE_CONTACT_OFFERS = () => gql`
  mutation CreateContactOffers($input: CreateContactOffersInput!) {
    createContactOffers(input: $input) {
      error
      success
    }
  }
`;

const CREATE_OFFER = props => gql`
  mutation CreateOffer($input: CreateOfferInput!) {
    createOffer(input: $input) {
      error
      offer {
        ${offer(props)}
      }
      success
    }
  }
`;

const CREATE_OFFERS = () => gql`
  mutation CreateOffers($input: CreateOffersInput!) {
    createOffers(input: $input) {
      error
      success
    }
  }
`;

const MOVE_OFFERS = gql`
  mutation MoveOffers($input: MoveOffersInput!) {
    moveOffers(input: $input) {
      error
      success
    }
  }
`;

const REFUSE_OFFERS = gql`
  mutation RefuseOffers($input: RefuseOffersInput!) {
    refuseOffers(input: $input) {
      error
      success
    }
  }
`;

const UPDATE_OFFER = props => gql`
  mutation UpdateOffer($input: UpdateOfferInput!) {
    updateOffer(input: $input) {
      error
      offer {
        ${offer(props)}
      }
      success
    }
  }
`;

export {
  CREATE_CONTACT_OFFERS,
  CREATE_OFFER,
  CREATE_OFFERS,
  MOVE_OFFERS,
  REFUSE_OFFERS,
  UPDATE_OFFER,
};
