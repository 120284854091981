import { gql } from "@apollo/client";
import { InstanceProvenance as instanceProvenance } from "../fragments";

const FETCH_INSTANCE_PROVENANCES = props => gql`
  query FetchInstanceProvenances($input: InstanceProvenanceFilterInput!) {
    instanceProvenances(input: $input) {
      count
      edges {
        ${instanceProvenance(props)}
      }
    }
  }
`;

const FETCH_INSTANCE_PROVENANCE = props => gql`
  query FetchInstanceProvenance($id: Int) {
    instanceProvenance(id: $id) {
      ${instanceProvenance(props)}
    }
  }
`;

export { FETCH_INSTANCE_PROVENANCE, FETCH_INSTANCE_PROVENANCES };
