import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import {
  Table,
  multiSelect,
  SelectList,
} from "../../../../components/Functional";
import { FETCH_LISTS as fetchLists } from "../../../../graphql/query/List";

import Actions from "./components/Actions";
import Header from "../../components/HeaderComponent";
import searchedWarehouses from "./components/SearchedWarehouses";
import * as SelectListWorking from "./components/SelectListWorking";
import * as SelectListSaved from "./components/SelectListSaved";

const List = props => {
  const { formik } = props;
  const navigate = useNavigate();

  const { data: { lists: { edges = [] } = {} } = {} } = useQuery(fetchLists(), {
    variables: {
      // fetch warehouseitem_working list
      input: { typeId: 14 },
    },
  });

  const workingList = edges?.length > 0 ? edges[0] : null;
  const workingListId = workingList?.id;
  const queryParams = new URLSearchParams(window.location.search);
  const status = queryParams?.get("status") || "all";
  const listId = +queryParams.get("listId");
  const ids = Object.keys(formik?.values?.selected?.ids || {}).map(item =>
    parseInt(item.replace("_", "")),
  );

  const multiSelectProps = {
    formik,
    label: "Warehouses",
    name: "selected",
    keyName: "id",
  };

  const actionProps = {
    formik,
    ids,
    listId,
    workingListId,
    onSuccess: () => formik?.resetForm(),
  };

  const handleChange = value => {
    navigate(`/warehouse?status=${value}${listId ? `&listId=${listId}` : ""}`);
  };

  const GeneralTabActions = () => {
    const createListProps = {
      ...actionProps,
      onSuccess: () => (
        formik?.resetForm(), navigate(`/warehouse?status=selectListWorking`)
      ),
      typeId: 14,
    };

    return <SelectList.CreateList {...createListProps} />;
  };

  const tabProps = {
    active: status,
    onChange: handleChange,
    tabs: [
      {
        actions: GeneralTabActions,
        label: "All Warehouses",
        name: "all",
        tableProps: searchedWarehouses(),
      },
      {
        actions: SelectListWorking.Actions,
        label: "Select List (Working)",
        name: "selectListWorking",
        tableProps: SelectListWorking.tableProps(actionProps),
      },
      {
        actions: SelectListSaved.Actions,
        label: "Select List (Saved)",
        name: "selectListSaved",
        tableProps: SelectListSaved.tableProps(actionProps),
      },
    ],
  };

  const { tableProps: tabTableProps, actions: tabActions } =
    tabProps?.tabs?.find(tab => tab.name === status) || {};

  // function fixes header.name for select lists when status = selectListWorking
  const fixHeadersName = ({ status, headers }) =>
    headers?.map(header => {
      return {
        ...header,
        name:
          status === "selectListWorking"
            ? `item.${header?.name}`
            : header?.name,
      };
    });

  const tableProps = {
    headers: fixHeadersName({
      status,
      headers: [
        multiSelect(multiSelectProps),
        { label: "Id", name: "id", sortKey: "id", type: "label" },
        {
          label: "Category",
          name: "category.description",
          type: "label",
        },
        {
          label: "Description",
          name: "description",
          type: "label",
        },
        {
          label: "Destroyed",
          name: "destroyed",
          type: "input",
          inputType: "toggle",
          designType: "threeStateCheckbox",
        },
        {
          label: "Weight Type",
          name: "weightType.name",
          type: "label",
        },
        {
          label: "Weight",
          name: "weight",
          sortKey: "weight",
          type: "label",
        },
        { label: "", name: "action", type: "custom", component: Actions },
      ],
    }),
    tabProps,
    variant: "dynamicHeight",
    ...tabTableProps,
  };

  return (
    <>
      <div className="px-8">
        <Header
          leftText="All Warehouse"
          rightComponent={() => {
            return (
              <div className="flex gap-4">{tabActions?.(actionProps)}</div>
            );
          }}
        />
      </div>
      <div className="mt-6 w-full">
        <Table {...tableProps} />
      </div>
    </>
  );
};

export default List;
