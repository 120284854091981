import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { Button, getInput } from "../../../../components/ui";
import { currentLocaleISODate } from "../../../../util";
import { CREATE_INVOICE_SHIPMENT_WORK as createInvoiceShipmentWorkMutation } from "../../../../graphql/mutation/Invoice";

const CreateShipmentWork = props => {
  const { closeModal, invoice } = props || {};
  const navigate = useNavigate();
  const [
    createInvoiceShipmentWork,
    {
      data: {
        createInvoiceShipmentWork: { error: shipmentWorkError } = {},
      } = {},
      loading,
      error,
      reset,
    },
  ] = useMutation(createInvoiceShipmentWorkMutation());

  const formik = useFormik({
    initialValues: {
      date: currentLocaleISODate(),
    },
    validationSchema: Yup.object({
      date: Yup.date().required("required"),
      jobId: Yup.number().nullable().positive(),
      consignorId: Yup.number()
        .required("Consigner is required")
        .typeError("Consigner is required"),
      consigneeId: Yup.number()
        .required("Consignee is required")
        .typeError("Consignee is required"),
    }),
    onSubmit: async values => {
      createInvoiceShipmentWork({
        variables: {
          input: {
            id: invoice?.id,
            ...values,
          },
        },
      }).then(resp => {
        const {
          data: { createInvoiceShipmentWork: { success, shipment } = {} },
        } = resp || {};
        if (success) {
          formik.resetForm();
          closeModal();
          navigate(`/shipments/${shipment?.id}/details`);
        }
      });
    },
  });

  const inputs = [
    { label: "Date - Filled", name: "date", type: "date" },
    {
      label: "Shipper",
      name: "shipperId",
      type: "contactSearch",
      fetchType: "shipper",
    },
    { label: "Shipping Ref", name: "shippingRef", type: "text" },
    { label: "Job Reference", name: "jobId", type: "number" },
    { label: "Consigner", name: "consignorId", type: "contactSearch" },
    { label: "Consignee", name: "consigneeId", type: "contactSearch" },
  ];

  const hasErrors = Object.keys(formik?.errors || {}).length > 0;

  if (error) {
    return (
      <div className="flex w-full flex-col gap-4">
        <div className="flex flex-col">
          <div className="flex flex-1 flex-col text-2xl font-bold">
            <div>Create Shipment Work</div>
          </div>
          <div className="flex gap-4 py-4">
            <div className="">{error?.message}</div>
          </div>
          <div className="flex gap-4">
            <Button
              action="default"
              label="Cancel"
              onClick={() => {
                closeModal();
                reset();
                formik.resetForm();
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          <div>Create Shipment Work</div>
          <p>
            <span className="text-sm text-red-500">{shipmentWorkError}</span>
          </p>
        </div>
        <div className="flex gap-4">
          <Button action="default" label="Cancel" onClick={closeModal} />
          <Button
            label={"Create"}
            loading={loading}
            disabled={loading || hasErrors}
            onClick={formik.submitForm}
          />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4">
        {inputs?.map((item, index) => {
          const inputProps = {
            ...item,
            formik,
          };
          return <div key={index}>{getInput(inputProps)}</div>;
        })}
      </div>
    </div>
  );
};

export default CreateShipmentWork;
