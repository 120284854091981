/* eslint-disable new-cap */
import { gql } from "@apollo/client";
import { Job as job } from "../fragments";

const CLONE_JOB_REQUEST = props => gql`
  mutation CloneJob($id: Int!) {
    cloneJob(id: $id) {
      error
      job {
        ${job(props)}
      }
      success
    }
  }
`;

const CREATE_JOB_REQUEST = props => gql`
  mutation CreateJob($input: CreateJobInput!) {
    createJob(input: $input) {
      error
      job {
        ${job(props)}
      }
      success
    }
  }
`;

const EXPORT_ADHOC_REPORT_JOB_REQUESTS = gql`
  mutation ExportAdhocReportJobs($input: ExportJobsInput!) {
    exportAdhocReportJobs(input: $input) {
      error
      success
      url
    }
  }
`;

const EXPORT_TIME_SHEET = gql`
  mutation ExportTimeSheet($input: ExportJobsInput!) {
    exportTimeSheet(input: $input) {
      error
      success
      url
    }
  }
`;

const UPDATE_JOB_REQUEST = props => gql`
  mutation UpdateJob($input: UpdateJobInput!) {
    updateJob(input: $input) {
      error
      job {
        ${job(props)}
      }
      success
    }
  }
`;

export {
  CLONE_JOB_REQUEST,
  CREATE_JOB_REQUEST,
  EXPORT_ADHOC_REPORT_JOB_REQUESTS,
  EXPORT_TIME_SHEET,
  UPDATE_JOB_REQUEST,
};
