import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Button } from "../../../../components/ui";
import { GridInputs } from "../../../../components/Functional";
import LeftListView from "../../../../components/Modals/AdhocReport/components/LeftListView";
import RightListView from "../../../../components/Modals/AdhocReport/components/RightListView";
import { EXPORT_ADHOC_REPORT_INSTANCES } from "../../../../graphql/mutation/Instance";

const AdhocReportBody = props => {
  const { closeModal, exportModule, ids, listId, search, select } = props || {};
  const [exportAdhocReportInstances, { loading }] = useMutation(
    EXPORT_ADHOC_REPORT_INSTANCES,
  );

  const formik = useFormik({
    initialValues: {
      exportType: "excel",
      fileName: `${exportModule?.name} Report`,
    },
    validationSchema: Yup.object({
      fileName: Yup.string().required("Please enter file name"),
    }),
    onSubmit: async values => {
      const { exportType, fileName } = values || {};
      const columns = selectedFields?.map?.(({ field, section }) => ({
        ...field,
        label: `${exportModule?.name} ${
          section?.label ? `- ${section?.label} -` : "-"
        } ${field?.label}`,
      }));

      await exportAdhocReportInstances({
        variables: {
          input: {
            columns,
            exportType,
            fileName,
            ...(select === "all"
              ? { ...(listId ? { listId } : { search }) }
              : { ids }),
          },
        },
      }).then(res => {
        const { exportAdhocReportInstances: { success, url } = {} } =
          res?.data || {};
        if (success) {
          window.open(url, "_blank");
          formik?.resetForm?.();
          closeModal?.();
        }
      });
    },
  });

  const { selectedFields = [] } = formik?.values || {};

  const inputs = {
    className: "grid grid-cols-2 gap-4",
    inputs: [
      {
        label: "File Name",
        name: "fileName",
        type: "text",
      },
      {
        label: "Export Type",
        name: "exportType",
        options: [
          { label: "XLS", value: "excel" },
          { label: "CSV", value: "csv" },
        ],
        type: "select",
      },
    ],
  };

  const gridInputProps = {
    formik,
    ...inputs,
  };

  const viewProps = {
    exportModule,
    formik,
  };

  const hasErrors = Object.keys(formik?.errors || {}).length > 0;

  return (
    <div className={`p-4`}>
      <div className={`flex flex-row items-center justify-between`}>
        <div className="flex flex-col">
          <div className={`text-lg`}>Create an Adhoc Report</div>
          <div className={`text-sm`}>{`Exporting ${
            select === "all" ? "All" : ids?.length
          } Records`}</div>
        </div>
        <div className={`flex gap-4`}>
          <Button
            className={`!w-22`}
            action="default"
            label="Cancel"
            onClick={() => (formik?.resetForm?.(), closeModal())}
          />
          <Button
            loading={loading}
            className={`!w-22 flex items-center`}
            label={loading ? "Exporting..." : "Export"}
            disabled={loading || hasErrors}
            onClick={formik.submitForm}
          />
        </div>
      </div>
      <div className="my-5 grid grid-cols-2">
        <GridInputs {...gridInputProps} />
      </div>
      <div className="grid grid-cols-2 gap-8">
        <div className="flex flex-col">
          <div className="flex flex-col">
            <div className="text-sm">Available Fields</div>
            <div className="text-xs">Click to Add to Export</div>
          </div>
          <LeftListView {...viewProps} />
        </div>
        <div className="flex flex-col">
          <div className="flex flex-col">
            <div className="text-sm">
              Selected Fields{" "}
              {selectedFields?.length && `(${selectedFields?.length})`}
            </div>
            <div className="text-xs">Drag to Re Order</div>
          </div>
          <RightListView {...viewProps} />
        </div>
      </div>
    </div>
  );
};

export default AdhocReportBody;
