import { gql } from "@apollo/client";
import { Photographer } from "../fragments";

const CREATE_PHOTOGRAPHER = gql`
  mutation CreatePhotographer($input: CreatePhotographerInput!) {
    createPhotographer(input: $input) {
      success
      error
      photographer {
        ${Photographer}
      }
    }
  }
`;

export { CREATE_PHOTOGRAPHER };
