import { ChevronRightIcon } from "@heroicons/react/24/solid";

const Step = props => {
  const { active, isLastStep, label, onClick, type, disabled } = props || {};

  const typeStyle = {
    tabs: {
      className: "font-bold capitalize text-xs",
      displayName: label?.label,
    },
    default: {
      className: "font-medium capitalize text-sm",
      displayName: label,
    },
  };

  const { className, displayName } = typeStyle[type] || typeStyle.default;

  return (
    <li>
      <div className="flex items-center">
        <a
          onClick={(!disabled && onClick) || null}
          className={`mr-4 ${className} ${
            active ? "text-gray-800" : "text-lightgrey-secondary"
          } ${!disabled && "cursor-pointer hover:text-gray-800"}`}
          aria-current={active ? "page" : undefined}
        >
          {displayName}
        </a>
        {isLastStep && (
          <ChevronRightIcon
            className="h-5 w-5 flex-shrink-0 text-gray-400"
            aria-hidden="true"
          />
        )}
      </div>
    </li>
  );
};

const Breadcrumb = props => {
  const {
    steps = [],
    onChange,
    currentStepIndex,
    type,
    disabled,
  } = props || {};

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol role="list" className="flex items-center space-x-4">
        {steps?.map((item, index) => {
          return (
            <Step
              disabled={disabled}
              type={type}
              key={index}
              active={currentStepIndex === index}
              isLastStep={index < steps.length - 1}
              label={item}
              onClick={() => onChange(index)}
            />
          );
        })}
      </ol>
    </nav>
  );
};

export { Breadcrumb };
