import { useLazyQuery, useMutation } from "@apollo/client";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";

import { GridInputs } from "../../Functional";
import { Button, IconButton, Modal } from "../../ui";
import { FETCH_ARTWORK as fetchArtwork } from "../../../graphql/query/Artwork";
import { UPDATE_IMAGE as updateImageMutation } from "../../../graphql/mutation/Image";
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Image,
  Slide,
  Slider,
} from "pure-react-carousel";
import { FETCH_PHOTO_MATERIAL_V2 as fetchPhotoMaterialV2 } from "../../../graphql/query/PhotoMaterial";

const disableConfig = {
  disabled: true,
  inputClassName: "!bg-white !text-gray-400 !border-gray-400",
};
const UpdateImageInfo = props => {
  const { images: propsImages = [], currentSlide, artworkId } = props;
  const [updateImage, { loading, error, reset }] = useMutation(
    updateImageMutation(),
    {
      refetchQueries: [
        fetchArtwork({
          artists: true,
          images: { imgT: {}, imgX: {}, photoMaterial: {} },
          editions: { availableNumbers: {} },
        }),
      ],
    },
  );
  const [selectedImage, setSelectedImage] = useState(null);
  const [fetchPhotoMaterialQuery, { data: { photoMaterialsV2 } = {} }] =
    useLazyQuery(fetchPhotoMaterialV2({ format: true }), {
      fetchPolicy: "network-only",
    });

  useEffect(() => {
    if (artworkId) {
      fetchPhotoMaterialQuery({
        variables: {
          input: {
            _artwork: {
              id: {
                operator: "eq",
                value: artworkId,
              },
            },
          },
        },
      });
    }
  }, [artworkId]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...propsImages?.[currentSlide],
      totalImages: propsImages.length,
    },
    onSubmit: async values => {
      try {
        const {
          archiveId,
          artworkId,
          channelDisplayOnline,
          photoMaterialId,
          description,
          order,
        } = values;
        await updateImage({
          variables: {
            input: {
              archiveId,
              artworkId,
              channelDisplayOnline,
              photoMaterialId,
              description,
              order,
            },
          },
        });
        reset();
      } catch (e) {
        console.error(e);
      }
    },
  });

  const inputs = {
    className: "grid grid-cols-3 gap-8",
    inputs: [
      {
        className: "grid-cols-1 gap-4",
        inputs: [
          {
            label: "Photographic Material",
            name: "photoMaterialId",
            type: "multi-select",
            options:
              photoMaterialsV2?.edges?.map(item => {
                return {
                  label: `${item?.description}(${item?.format?.description})`,
                  value: item?.id,
                };
              }) || [],
            submitOnBlur: true,
          },
          {
            label: "Credit",
            name: "photoMaterial.credit",
            type: "text",
            ...disableConfig,
          },
          {
            label: "Description",
            name: "photoMaterial.description",
            type: "textarea",
            ...disableConfig,
          },
        ],
      },
      {
        className: "grid-cols-1 gap-4",
        inputs: [
          {
            label: "Photographer",
            name: "photoMaterial.photographer",
            type: "text",
            ...disableConfig,
          },
          {
            label: "Archive ID",
            name: "archiveId",
            type: "text",
            ...disableConfig,
          },
          {
            name: "channelDisplayOnline",
            type: "select",
            options: [
              { label: "Display Online", value: 1 },
              { label: "Not for Display Online", value: 0 },
            ],
          },
          {
            className: "grid-cols-2 gap-4",
            inputs: [
              { label: "Order", name: "order", type: "number" },
              {
                label: "Of",
                name: "totalImages",
                type: "text",
                ...disableConfig,
              },
            ],
          },
        ],
      },
      {
        className: "grid-cols-1 gap-4",
        inputs: [
          {
            label: "Image Caption",
            name: "description",
            type: "textarea",
            disabled: false,
          },
        ],
      },
    ],
  };
  const manageImageInputs = {
    formik,
    ...inputs,
  };

  const handleImageSelection = index => e => {
    e.preventDefault();
    formik.setValues({
      ...propsImages[index],
      totalImages: propsImages.length,
    });
    setSelectedImage(index);
  };

  const handleModalOpen = () => {
    setSelectedImage(currentSlide);
  };

  const modalProps = {
    scale: "md",
    closeOnBackdrop: false,
    hideCloseButton: true,
    onOpen: handleModalOpen,
    body: ({ closeModal }) => {
      return (
        <div className="flex w-full flex-col px-6">
          <div className="flex flex-row">
            <div className="flex flex-1 flex-col text-xl">
              Manage Artwork Images
              <div className={`mt-2 text-sm font-normal`}>
                {propsImages.length} images
              </div>
              {error && (
                <div className={`text-sm text-red-500`}>{error?.message}</div>
              )}
            </div>
            <div className="flex flex-row gap-4">
              <Button
                className={`h-9 !w-32`}
                action="default"
                label="Cancel"
                onClick={() => {
                  closeModal();
                  reset();
                }}
              />
              <Button
                className="flex h-9 !w-32 items-center"
                label={loading ? "Saving" : "Save"}
                disabled={loading}
                onClick={formik.handleSubmit}
              />
            </div>
          </div>
          <div className="mt-4">
            <div className="border-b border-gray-100 pb-6">
              {propsImages?.length > 0 ? (
                <CarouselProvider
                  visibleSlides={4}
                  totalSlides={propsImages.length}
                  naturalSlideWidth={275}
                  naturalSlideHeight={200}
                  hasMasterSpinner={true}
                  className={"flex w-full justify-between"}
                >
                  <ButtonBack>
                    <IconButton variant="clean" title="Back">
                      <ChevronLeftIcon className={`h-5 w-5 cursor-pointer`} />
                    </IconButton>
                  </ButtonBack>
                  <Slider className={"w-11/12 gap-16"}>
                    {propsImages?.map((image, index) => {
                      const imageSelected = selectedImage === index;
                      const firstImage = index === 0;

                      return (
                        <Slide
                          index={`dvc_image_${index}`}
                          key={index}
                          className={`group relative`}
                        >
                          <div
                            className={`grid h-full w-full grid-rows-6 gap-1 px-4`}
                          >
                            <div
                              className={`relative row-span-5 w-full cursor-pointer
                               ${firstImage ? "bg-gray-200 px-4 py-3" : ""}
                                ${imageSelected ? "border-4 border-black" : ""}`}
                              onClick={handleImageSelection(index)}
                            >
                              <Image
                                src={image?.imgT}
                                className={`box-border object-cover ${firstImage ? "shadow-md shadow-gray-500" : ""}`}
                              />
                            </div>
                            <div
                              className={`row-span-1 flex justify-${imageSelected || firstImage ? "between" : "end"} block text-sm font-medium text-black`}
                            >
                              {imageSelected ? (
                                <span>Selected</span>
                              ) : firstImage ? (
                                <span className={`text-gray-400`}>Primary</span>
                              ) : (
                                ""
                              )}
                              {index + 1}
                            </div>
                          </div>
                        </Slide>
                      );
                    })}
                  </Slider>
                  <ButtonNext>
                    <IconButton variant="clean" title="Next">
                      <ChevronRightIcon className={`h-5 w-5 cursor-pointer`} />
                    </IconButton>
                  </ButtonNext>
                </CarouselProvider>
              ) : (
                <div
                  className={`text-md flex h-full w-full items-center justify-center text-gray-400`}
                >
                  NO IMAGES TO SHOW
                </div>
              )}
            </div>
            <div className={`my-6 text-xl`}>
              Update Information for Selected Image
            </div>
            <div>
              <GridInputs {...manageImageInputs} />
            </div>
          </div>
        </div>
      );
    },
  };

  return (
    <Modal {...modalProps}>
      <Button label="Update Info" action="dark" className={"!w-32"} />
    </Modal>
  );
};

export default UpdateImageInfo;
