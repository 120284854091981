import { statusColor } from "../../..";

const InstanceStatus = props => {
  const value = props.label || "";

  return (
    <div
      title={value}
      className={`max-w-200 overflow-hidden text-ellipsis text-base font-medium leading-5 ${statusColor(value)}`}
    >
      {value}
    </div>
  );
};

export default InstanceStatus;
