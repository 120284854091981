import { Route, Routes as RouterRoutes } from "react-router-dom";
import { NavBar } from "../../components/ui";
import useUser from "../../hooks/useUser";
import AuthRoute from "../../components/AuthRoute";
import routes from "./routes";
import { Portal } from "../../util";

const Body = props => (
  <RouterRoutes>
    {routes
      ?.filter?.(item => item?.element)
      ?.map((item, key) => (
        <Route
          key={key}
          path={item.href}
          element={
            <AuthRoute permissions={item.permissions}>
              <item.element {...props} />
            </AuthRoute>
          }
        />
      ))}
  </RouterRoutes>
);

const Admin = props => {
  const { user } = useUser();

  return (
    <div className="relative flex min-h-full">
      <Portal id="sidebar">
        <NavBar
          navigation={routes?.filter?.(item => item?.icon)}
          open={props?.open}
        />
      </Portal>
      <div className="m-8 flex w-full flex-1 overflow-x-auto">
        <div className="container mx-auto">
          <Body {...props} user={user} />
        </div>
      </div>
    </div>
  );
};

export default Admin;
