import { useQuery } from "@apollo/client";
import { GridInputs } from "../../../../../../components/Functional";
import { FETCH_JOB_STATUSES } from "../../../../../../graphql/query/JobStatus";

const ConfirmHandover = props => {
  const { formik } = props || {};
  const { data: { jobStatuses = [] } = {} } = useQuery(FETCH_JOB_STATUSES);

  const inputs = {
    className: "grid grid-cols-2 gap-16",
    inputs: [
      {
        label: "Status",
        name: "CRMRegistrar.statusId",
        options: jobStatuses?.map(type => ({
          label: type?.description,
          value: type?.id,
        })),
        type: "multi-select",
      },
    ],
  };

  const gridInputProps = {
    formik,
    ...inputs,
  };

  return <GridInputs {...gridInputProps} />;
};

export default ConfirmHandover;
