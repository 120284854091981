import { gql } from "@apollo/client";
import { Condition as condition } from "../fragments";

const FETCH_CONDITIONS_V2 = props => gql`
  query FetchConditionsV2($input: AdvConditionFilterInput!) {
    conditionsV2(input: $input) {
      count
      edges {
        ${condition(props)}
      }
      sqlQuery
    }
  }
`;

export { FETCH_CONDITIONS_V2 };
