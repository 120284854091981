import React from "react";

import SectionHeader from "../../components/SectionHeader";
import { GridInputs, Table } from "../../../../../../components/Functional";
import { FETCH_CRM_REGISTRARS_V2 as fetchCRMRegistrarsV2 } from "../../../../../../graphql/query/CRMRegistrar";
import AddCOA from "./components/AddCOA";
import { Button, Modal } from "../../../../../../components/ui";
import Actions from "./components/Actions";
import useUser from "../../../../../../hooks/useUser";

const InstanceCOA = props => {
  const { formik, instanceId } = props || {};
  const { hasPermission } = useUser();
  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "crmRegistrarsV2",
    headers: [
      { label: "Date", name: "date", type: "date" },
      { label: "Type", name: "type.description", type: "label" },
      { label: "Description", name: "description", type: "label" },
      { label: "User", name: "salesUser.name", type: "label" },
      {
        label: "",
        name: "actions",
        type: "custom",
        component: Actions,
        instanceId,
      },
    ],
    inputs: [],
    FETCH_QUERY: fetchCRMRegistrarsV2({ salesUser: {}, type: {} }),
    variables: {
      categoryId: {
        operator: "eq",
        value: 9,
      },
      _instance: {
        id: {
          value: +instanceId,
          operator: "eq",
        },
      },
    },
    className: "sm:px-0 lg:px-0",
  };

  const inputs = {
    inputs: [
      {
        className: "grid grid-cols-4 gap-4 mt-5 items-end",
        inputs: [
          {
            disabled: !hasPermission("UPDATE_INSTANCE_CERTIFICATE"),
            label: "Certificate",
            name: "certificate",
            type: "textarea",
            submitOnBlur: true,
          },
          {
            designType: "threeStateCheckbox",
            label: "Sent",
            name: "certificateSent",
            type: "toggle",
            submitOnBlur: true,
          },
        ],
      },
    ],
  };

  const modalProps = {
    body: AddCOA,
    disabled: !hasPermission("CREATE_CONDITION"),
    instanceId,
    hideCloseButton: true,
    scale: "md",
  };

  const coaActions = [
    {
      type: "black",
      label: "ADD RECORD",
      action: () => null,
      component: () => (
        <Modal {...modalProps}>
          <Button
            disabled={!hasPermission("CREATE_CONDITION")}
            label="Add COA"
            action="dark"
          />
        </Modal>
      ),
    },
  ];

  const detailInputProps = {
    formik,
    ...inputs,
  };

  return (
    <div className="w-full">
      <SectionHeader title="COA" actions={coaActions} />
      <div className="w-full border-b px-16 py-8">
        <GridInputs {...detailInputProps} />
      </div>
      <div className="mb-6 w-full">
        <Table {...tableProps} />
      </div>
    </div>
  );
};

export default InstanceCOA;
