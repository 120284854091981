import { useQuery } from "@apollo/client";

import useUser from "../../../../../../hooks/useUser";
import { getInput } from "../../../../../../components/ui/Input";
import SectionHeader from "../../components/SectionHeader";
import { FETCH_CONTACT_LOCATIONS } from "../../../../../../graphql/query/Contact";
import { FETCH_ENTITIES } from "../../../../../../graphql/query/Entity";
import { FETCH_FIRST_CONTACTS } from "../../../../../../graphql/query/FirstContact";

const ClientInformation = props => {
  const { formik } = props || {};
  const { data: { firstContacts = [] } = {} } = useQuery(FETCH_FIRST_CONTACTS);
  const { data: { entities = {} } = {} } = useQuery(FETCH_ENTITIES);
  const { data: { contactLocations = [] } = {} } = useQuery(
    FETCH_CONTACT_LOCATIONS,
  );
  const { hasPermission } = useUser();

  const inputs = [
    {
      fields: [
        {
          label: "Date Of Birth",
          name: "dob",
          type: "date",
          submitOnBlur: true,
        },
        {
          label: "Confidentiality",
          name: "confidential",
          options: [
            { label: "Confidential", value: -1 },
            { label: "Not Confidential", value: 0 },
          ],
          placeholder: "Select Confidentiality",
          type: "multi-select",
          submitOnBlur: true,
        },
        {
          label: "Rating",
          name: "level",
          options: [
            {
              label: "A+",
              value: 1,
              ...(!hasPermission("SET_CONTACT_LEVEL_A") && { disabled: true }),
            },
            {
              label: "A",
              value: 2,
              ...(!hasPermission("SET_CONTACT_LEVEL_A") && { disabled: true }),
            },
            { label: "B", value: 3 },
            { label: "C", value: 4 },
            { label: "G", value: 5 },
          ],
          placeholder: "Select Rating",
          type: "multi-select",
          submitOnBlur: true,
        },
        {
          disabled: true,
          label: "Level",
          name: "rating",
          options: [
            { label: "1", value: 1 },
            { label: "2", value: 2 },
            { label: "3", value: 3 },
            { label: "4", value: 4 },
            { label: "5", value: 5 },
            { label: "6", value: 6 },
            { label: "7", value: 7 },
            { label: "8", value: 8 },
          ],
          placeholder: "System assigned",
          type: "multi-select",
          submitOnBlur: true,
        },
      ],
    },
    {
      header: "First Met",
      fields: [
        {
          label: "Type",
          name: "firstcontacttype",
          type: "multi-select",
          options: firstContacts?.map(item => ({
            label: item.description,
            value: item.id,
          })),
          submitOnBlur: true,
        },
        {
          label: "Location",
          name: "firstcontactlocation",
          type: "multi-select",
          options: contactLocations?.map(item => ({
            label: item,
            value: item,
          })),
          submitOnBlur: true,
        },
        {
          label: "Staff",
          name: "firstUserId",
          type: "multi-select",
          options: entities?.edges?.map(item => ({
            label: item?.name,
            value: item?.id,
          })),
          submitOnBlur: true,
        },
        {
          label: "Date",
          name: "firstcontactdate",
          type: "date",
          submitOnBlur: true,
        },
      ],
    },
    {
      fields: [
        {
          label: "Biography",
          name: "biography",
          type: "textarea",
          submitOnBlur: true,
        },
        { label: "Notes", name: "notes", type: "textarea", submitOnBlur: true },
      ],
    },
  ];

  return (
    <div className="w-full">
      <SectionHeader title="Client Information" />
      <div className="flex w-full gap-24">
        {inputs?.map((item, index) => {
          const { fields } = item || {};

          return (
            <div key={index} className="flex flex-1 flex-col gap-4">
              <div className="text-xl font-bold">{item?.header}</div>
              {fields?.map((input, index) => {
                const inputProps = {
                  ...input,
                  formik,
                };
                return (
                  <div key={index} className="w-full">
                    {getInput(inputProps)}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ClientInformation;
