const statusColor = value => {
  switch (value) {
    case "Available":
      return "text-sky-750";
    case "Sold":
      return "text-red-650";
    default:
      return "text-black";
  }
};

const Status = props => {
  const value = props.label || "";

  return (
    <div
      title={value}
      className={`max-w-200 overflow-hidden text-ellipsis text-base font-medium leading-5 ${statusColor(
        value,
      )}`}
    >
      {value}
    </div>
  );
};

export default Status;
