/* eslint-disable new-cap */
import { gql } from "@apollo/client";
import { Document as document } from "../fragments";

const CREATE_DOCUMENT = props => gql`
  mutation CreateDocument($input: CreateDocumentInput!) {
    createDocument(input: $input) {
      success
      error
      document {
        ${document(props)}
      }
    }
  }
`;

const DELETE_DOCUMENT = gql`
  mutation DeleteDocument($input: DeleteDocumentInput!) {
    deleteDocument(input: $input) {
      success
      error
    }
  }
`;

const EXPORT_DOCUMENT = gql`
  mutation ExportDocument($input: ExportDocumentInput!) {
    exportDocument(input: $input) {
      size
      success
      url
      error
      filename
    }
  }
`;

const UPDATE_DOCUMENT = props => gql`
  mutation UpdateDocument($input: UpdateDocumentInput!) {
    updateDocument(input: $input) {
      success
      error
      document {
        ${document(props)}
      }
    }
  }
`;

const SHARE_DOCUMENT = props => gql`
  mutation ShareDocument($input: ShareDocumentInput!) {
    shareDocument(input: $input) {
      success
      document {
        ${document(props)}
      }
      error
    }
  }
`;

export {
  CREATE_DOCUMENT,
  DELETE_DOCUMENT,
  EXPORT_DOCUMENT,
  SHARE_DOCUMENT,
  UPDATE_DOCUMENT,
};
