const InstanceAuction = `
  id
  auctionId
  auctioneer
  city
  comparable
  contactId
  currencyId
  estimateh
  estimatel
  event
  hammer
  instanceId
  lot
  notes
  saleDate
  saleRef
  total
  updatedAt
  url
`;

export default InstanceAuction;
