import { useQuery } from "@apollo/client";
import { GridInputs } from "../../../../../components/Functional";
import { FETCH_WAREHOUSE_CATEGORIES } from "../../../../../graphql/query/WarehouseCategory";

const Warehouse = props => {
  const { formik } = props || {};
  const { data: { warehouseCategories = [] } = {} } = useQuery(
    FETCH_WAREHOUSE_CATEGORIES,
  );

  const inputs = {
    className: "grid grid-cols-4 gap-16 mt-5",
    inputs: [
      {
        className: "flex flex-col gap-4",
        inputs: [
          {
            label: "Storage #",
            name: "_warehouseItem.id",
            type: "number",
          },
        ],
      },
      {
        className: "gap-4",
        inputs: [
          {
            label: "Group",
            name: "_warehouseItem.mgroup",
            type: "number",
          },
        ],
      },
      {
        className: "gap-4",
        inputs: [
          {
            label: "Type",
            name: "_warehouseItem.categoryId",
            type: "multi-select",
            isMulti: false,
            options: warehouseCategories?.map(type => ({
              label: type.description,
              value: type.id,
            })),
          },
        ],
      },
      {
        className: "gap-4",
        inputs: [
          {
            label: "Description",
            name: "_warehouseItem.description",
            type: "text",
          },
        ],
      },
    ],
  };

  const gridInputProps = {
    formik,
    ...inputs,
  };

  return <GridInputs {...gridInputProps} />;
};

export default Warehouse;
