import { FETCH_INSTANCES_V2 as fetchInstancesV2 } from "../../../../../graphql/query/Instance";
import { instanceFilterStore } from "../../../components/useInstanceFilters";

const SearchedInstances = props => {
  const tableProps = {
    dataKey: "instancesV2",
    FETCH_QUERY: fetchInstancesV2({
      edition: { artwork: { artists: {}, imagesSummary: { imgT: {} } } },
      status: {},
    }),
    limit: 50,
    variables: {
      ...instanceFilterStore.get(),
    },
  };

  return tableProps;
};

export default SearchedInstances;
