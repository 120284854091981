import { useQuery } from "@apollo/client";

import { GridInputs } from "../../../../../../../../../components/Functional";
import { FETCH_CURRENCIES } from "../../../../../../../../../graphql/query/Currency";
import { FETCH_CONSIGNMENTS_TYPES } from "../../../../../../../../../graphql/query/ConsignmentType";
import { useEffect, useState } from "react";
import useUser from "../../../../../../../../../hooks/useUser";

const DetailFormCol1 = ({ formik, submitOnBlur = false, consignment }) => {
  const { data: { currencies = [] } = {} } = useQuery(FETCH_CURRENCIES);
  const { data: { consignmentTypes = [] } = {} } = useQuery(
    FETCH_CONSIGNMENTS_TYPES,
  );
  const [minDueDate, setMinDueDate] = useState("");
  const { hasPermission } = useUser();

  useEffect(() => {
    if (formik?.values?.date && formik?.values?.date !== "0000-00-00") {
      // add a day onto date to get min due date
      let dueDate = new Date(
        new Date(formik?.values?.date).getTime() + 60 * 60 * 24 * 1000,
      );
      // subtract timezone offset to standardise the min due date to UTC+0
      const offset = dueDate.getTimezoneOffset();
      dueDate = new Date(dueDate.getTime() - offset * 60 * 1000);
      setMinDueDate(dueDate.toISOString().split("T")[0]);
    } else {
      setMinDueDate("");
    }
  }, [formik?.values?.date]);

  const inputs = {
    formik,
    className: "grid-cols-1 gap-6",
    inputs: [
      {
        name: "typeId",
        label: "Type",
        type: "multi-select",
        isMulti: false,
        options: consignmentTypes?.map(type => ({
          label: type.description,
          value: type.id,
        })),
        submitOnBlur,
        disabled:
          !hasPermission("UPDATE_CONSIGNMENT") ||
          consignment?.noOfConsignmentWorks > 0,
      },
      {
        name: "date",
        label: "Date",
        placeholder: "DD/MM/YYYY",
        type: "date",
        submitOnBlur,
        disabled: !hasPermission("UPDATE_CONSIGNMENT"),
      },
      {
        name: "currencyId",
        label: "Currency",
        type: "multi-select",
        isMulti: false,
        options: currencies?.map(currency => ({
          label: `${currency.description} (${currency.code})`,
          value: currency.id,
        })),
        submitOnBlur,
        disabled: !hasPermission("UPDATE_CONSIGNMENT"),
      },
      {
        name: "due",
        label: "Due",
        placeholder: "DD/MM/YYYY",
        type: "date",
        submitOnBlur,
        // force due date to be after date
        min: minDueDate,
        disabled: !hasPermission("UPDATE_CONSIGNMENT"),
      },
      {
        name: "terms",
        label: "Terms",
        type: "textarea",
        rows: 5,
        submitOnBlur,
        disabled: !hasPermission("UPDATE_CONSIGNMENT"),
      },
      {
        name: "notes",
        label: "Notes",
        type: "textarea",
        rows: 5,
        submitOnBlur,
        disabled: !hasPermission("UPDATE_CONSIGNMENT"),
      },
    ],
  };

  return <GridInputs {...inputs} />;
};

export default DetailFormCol1;
