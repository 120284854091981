const DocumentPage = `
  id
  name
  compiledHtml
  position
  meta
  createdAt
  updatedAt
  artworkId
  archiveId
`;

export default DocumentPage;
