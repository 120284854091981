import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useFormik } from "formik";

import Header from "../../components/HeaderComponent";
import Actions from "./components/Actions";
import Filters from "./components/Filters";

import Table from "../../../../components/Table";
import { Pagination, Button, SearchBar } from "../../../../components/ui";
import { FETCH_ROLES } from "../../../../graphql/query/Role";
import { useDebounce } from "../../../../util";

const RightComponent = () => {
  const navigate = useNavigate();
  return (
    <Button
      label={"Add a Role"}
      action={"dark"}
      className="mr-4"
      onClick={() => navigate("/admin/role/new")}
    />
  );
};

const AdminManageRoles = () => {
  const navigate = useNavigate();
  const limit = 10;
  const [filters, setFilters] = useState({});
  const debouncedFilters = useDebounce(filters, 300);
  const skip = Object.keys(debouncedFilters).length === 0;
  const { previousData, data, loading } = useQuery(FETCH_ROLES, {
    skip,
    variables: { input: { ...debouncedFilters } },
  });
  const { roles } = data || previousData || {};
  const { count, edges = [] } = roles || {};

  const onChange = values => {
    setFilters({ ...filters, ...values });
  };

  const paginationProps = {
    count,
    limit,
    offset: filters?.offset,
    onChange,
  };

  const inputs = [{ label: "Role Name", name: "name", type: "text" }];

  const tableProps = {
    headers: [
      { label: "Role Name", name: "name", type: "label" },
      {
        label: "User Assigned",
        name: "usersCount",
        type: "label",
        onClick: props =>
          navigate(`/admin/users?roleId=${props?.formik?.values?.id}`),
      },
      { label: "", name: "action", type: "custom", component: Actions },
    ],
    limit,
    loading,
    rows: edges,
  };

  const initialValues = {
    search: "",
    limit,
    ...inputs
      ?.map(item => ({ ...item, value: "" }))
      .reduce((acc, item) => ({ ...acc, [item.name]: item.value }), {}),
  };
  const formik = useFormik({
    initialValues,
  });

  const filterProps = {
    inputs,
    formik,
    type: "simple",
    onChange,
  };

  const searchBarProps = {
    name: "search",
    formik,
  };

  useEffect(() => {
    onChange(formik.values);
  }, [formik.values]);

  return (
    <div className="manage-roles">
      <Header
        leftText="Roles"
        rightText="PDF Generator"
        rightComponent={RightComponent}
      />
      <div>Manage Roles</div>
      <div className="flex flex-grow items-center justify-end">
        <div className="mr-4">
          <SearchBar {...searchBarProps} />
        </div>
        <Filters {...filterProps} />
      </div>
      <div className="flex-col p-4 sm:px-0">
        <Table {...tableProps} />
        <Pagination {...paginationProps} />
      </div>
    </div>
  );
};

export default AdminManageRoles;
