import { gql } from "@apollo/client";
import { Artwork as artwork } from "../fragments";

const BATCH_UPDATE_ARTWORK = props => gql`
  mutation BatchUpdateArtwork($input: BatchUpdateArtworkInput!) {
    batchUpdateArtwork(input: $input) {
      success
      error
      artworks {
        ${artwork(props)}
      }
    }
  }
`;

const CREATE_ARTWORK = props => gql`
  mutation CreateArtwork($input: CreateArtworkInput!) {
    createArtwork(input: $input) {
      success
      error
      artwork {
        ${artwork(props)}
      }
    }
  }
`;

const UPDATE_ARTWORK = props => gql`
  mutation UpdateArtwork($input: UpdateArtworkInput!) {
    updateArtwork(input: $input) {
      success
      error
      artwork {
        ${artwork(props)}
      }
    }
  }
`;

const DELETE_ARTWORK = gql`
  mutation DeleteArtwork($input: DeleteArtworkInput!) {
    deleteArtwork(input: $input) {
      success
      error
    }
  }
`;

const DUPLICATE_ARTWORK = props => gql`
  mutation DuplicateArtwork($input: DuplicateArtworkInput!) {
    duplicateArtwork(input: $input) {
      success
      error
      artworks {
        ${artwork(props)}
      }
    }
  }
`;

const EXPORT_ADHOC_REPORT_ARTWORKS = gql`
  mutation ExportAdhocReportArtworks($input: ExportArtworksInput!) {
    exportAdhocReportArtworks(input: $input) {
      error
      success
      url
    }
  }
`;

export {
  BATCH_UPDATE_ARTWORK,
  CREATE_ARTWORK,
  UPDATE_ARTWORK,
  DELETE_ARTWORK,
  DUPLICATE_ARTWORK,
  EXPORT_ADHOC_REPORT_ARTWORKS,
};
