import { gql } from "@apollo/client";
import { Edition as edition } from "../fragments";

const FETCH_EDITIONS_V2 = props => gql`
  query FetchEditionsV2($input: AdvEditionFilterInput!) {
    editionsV2(input: $input) {
      count
      edges {
        ${edition(props)}
      }
      sqlQuery
    }
  }
`;

export { FETCH_EDITIONS_V2 };
