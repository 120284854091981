import { useQuery } from "@apollo/client";
import { useEffect } from "react";
import { GridInputs } from "../../../../../components/Functional";
import { FETCH_CRM_TYPES } from "../../../../../graphql/query/CRMType";
import { FETCH_ENTITIES } from "../../../../../graphql/query/Entity";
import { shippingCostOptions } from "../../../../../util";

const Registrar = props => {
  const { formik } = props || {};
  const { data: { crmTypes = [] } = {} } = useQuery(FETCH_CRM_TYPES, {
    variables: { input: { categoryId: 1 } },
  });
  const { data: { entities = [] } = {} } = useQuery(FETCH_ENTITIES);

  useEffect(() => {
    if (formik?.values?.proofDateCheck) {
      formik?.setFieldValue("proofdate", {
        operator: "ne",
        value: null,
      });
    } else {
      if (
        formik?.values?.proofdate?.value?.from ||
        formik?.values?.proofdate?.value?.to
      ) {
        delete formik?.values?.proofdate?.operator;
        formik?.setFieldValue("proofdate", {
          value: formik?.values?.proofdate?.value,
        });
      } else {
        formik?.setFieldValue("proofdate", null);
      }
    }
  }, [formik?.values?.proofDateCheck]);

  useEffect(() => {
    if (
      formik?.values?.proofdate?.value?.from ||
      formik?.values?.proofdate?.value?.to
    ) {
      if (formik?.values?.proofDateCheck) {
        formik?.setFieldValue("proofDateCheck", false);
      }
      formik?.setFieldValue("proofdate", {
        value: formik?.values?.proofdate?.value,
      });
    }
  }, [
    formik?.values?.proofdate?.value?.from,
    formik?.values?.proofdate?.value?.to,
  ]);

  const inputs = {
    className: "grid grid-cols-4 gap-16",
    inputs: [
      {
        className: "grid grid-rows-2 gap-4",
        inputs: [
          {
            hasOperator: true,
            label: "Registrar",
            name: "registrarId",
            options: entities?.edges?.map(item => {
              return { label: item.name, value: item.id };
            }),
            type: "multi-select",
          },
          {
            label: "Shipping Cost",
            name: "chargeStorage",
            type: "multi-select",
            options: shippingCostOptions,
          },
        ],
      },
      {
        className: "grid grid-rows-2 gap-4",
        inputs: [
          {
            hasOperator: true,
            label: "Activity Type",
            name: "_CRMRegistrar.typeId",
            options: crmTypes?.map(({ id, description }) => ({
              label: description,
              value: id,
            })),
            type: "multi-select",
          },
          {
            label: "Date Proof of Export Recieved From",
            name: "proofdate.value.from",
            type: "date",
          },
          {
            label: "Date Proof of Export Recieved To",
            name: "proofdate.value.to",
            type: "date",
          },
          {
            designType: "checkbox",
            label: "Proof of Export Recieved",
            name: "proofDateCheck",
            type: "toggle",
          },
        ],
      },
      {
        className: "grid grid-rows-2 gap-4 mt-5",
        inputs: [
          {
            designType: "threeStateCheckbox",
            label: "On Hold",
            name: "deliveryHold",
            type: "toggle",
          },
          {
            designType: "threeStateCheckbox",
            label: "Unshipped Works",
            name: "works",
            type: "toggle",
          },
        ],
      },
    ],
  };

  const gridInputProps = {
    formik,
    ...inputs,
  };

  return <GridInputs {...gridInputProps} />;
};

export default Registrar;
