import contactFrag from "./Contact";
import CRMCategory from "./CRMCategory";
import CRMType from "./CRMType";
import Entity from "./Entity";
import JobStatus from "./JobStatus";

const CRMRegistrar = ({
  category = false,
  contact = false,
  entity = false,
  salesUser = false,
  status = false,
  subType = false,
  type = false,
  user = false,
} = {}) => `
  id
  ${
    category
      ? `category {
      ${CRMCategory}
    }`
      : ""
  }
  categoryId
  consignmentId
  ${
    contact
      ? `contact {
      ${contactFrag(contact)}
    }`
      : ""
  }
  contactId
  createdById
  createdOn
  date
  deleted
  description
  ${
    entity
      ? `entity {
      ${Entity}
    }`
      : ""
  }
  entityId
  eventId
  instanceId
  invoiceId
  issueId
  metaDescription
  metaKeyword
  metaTitle
  name
  nodeId
  notes
  online
  priority
  reference
  ${
    salesUser
      ? `salesUser {
      ${Entity}
    }`
      : ""
  }
  salesUserId
  ${
    status
      ? `status {
      ${JobStatus}
    }`
      : ""
  }
  statusId
  ${
    subType
      ? `subType {
      ${CRMType}
    }`
      : ""
  }
  subTypeId
  translationId
  ${
    type
      ? `type {
      ${CRMType}
    }`
      : ""
  }
  typeId
  updatedAt
  updatedById
  updatedOn
  ${
    user
      ? `user {
      ${Entity}
    }`
      : ""
  }
  userId
  validFrom
  validTo
  versionId
`;

export default CRMRegistrar;
