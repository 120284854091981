import { useLazyQuery, useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import { GridInputs } from "../../../../components/Functional";
import { Button } from "../../../../components/ui";

import { FETCH_ARTWORKS_V2 as fetchArtworksV2Query } from "../../../../graphql/query/Artwork";
import { FETCH_ARTISTS as fetchArtists } from "../../../../graphql/query/Artist";
import { FETCH_ARTWORK_CATEGORIES } from "../../../../graphql/query/ArtworkCategory";
import { FETCH_ARTWORK_STANDARDS } from "../../../../graphql/query/ArtworkStandard";
import { artworkFilterStore, useArtworkFilters } from "../useArtworkFilters";

import Exhibition from "./components/Exhibition";
import Instance from "./components/Instance";
import Query from "./components/Query";
import Tabs from "./components/Tabs";

const ArtworkSearch = props => {
  const { closeModal } = props || {};
  const [fetchArtworksV2, { loading, data: { artworksV2 } = {} }] =
    useLazyQuery(fetchArtworksV2Query());
  const { data: { artists = [] } = {} } = useQuery(fetchArtists());
  const { data: { artworkCategories = [] } = {} } = useQuery(
    FETCH_ARTWORK_CATEGORIES,
  );
  const { data: { artworkStandards = [] } = {} } = useQuery(
    FETCH_ARTWORK_STANDARDS,
  );
  const navigate = useNavigate();
  const { formik } = useArtworkFilters();
  const input = artworkFilterStore.get();

  const inputs = {
    className: "grid grid-cols-4 gap-16 mt-5",
    inputs: [
      {
        className: "gap-4",
        inputs: [
          {
            label: "Artist",
            name: "_artist.id",
            options: artists?.map(item => ({
              label: item.name,
              value: item.id,
            })),
            type: "multi-select",
          },
          { label: "Title", name: "title", type: "textarea" },
          {
            hasOperator: true,
            isMulti: true,
            label: "Category",
            name: "_artworkCategory.id",
            options: artworkCategories?.map(item => ({
              label: item.name,
              value: item.id,
            })),
            type: "multi-select",
          },
          {
            hasOperator: true,
            label: "Medium",
            name: "medium",
            type: "textarea",
          },
        ],
      },
      {
        className: "grid-cols-1 gap-4",
        inputs: [
          { label: "Year", name: "_edition.year", type: "text" },
          { label: "Series", name: "series", type: "text" },
          { label: "Dimensions", name: "dimension", type: "textarea" },
          { label: "Description", name: "description", type: "textarea" },
        ],
      },
      {
        className: "grid-cols-1 gap-4",
        inputs: [
          { label: "Id", name: "id", type: "number" },
          { label: "Image", name: "_images.archiveId", type: "number" },
          { label: "Timestamp", name: "updatedAt", type: "text" },
          {
            label: "Handling",
            name: "standardId",
            options: artworkStandards?.map(item => ({
              label: item.name,
              value: item.id,
            })),
            type: "multi-select",
          },
        ],
      },
      {
        className: "grid-cols-1 gap-4 pt-4",
        inputs: [
          {
            designType: "threeStateCheckbox",
            label: "Photographed",
            name: "photographed",
            type: "toggle",
          },
          {
            designType: "threeStateCheckbox",
            label: "Travel Pack",
            name: "travelPack",
            type: "toggle",
          },
          {
            designType: "threeStateCheckbox",
            label: "Confidential",
            name: "confidential",
            type: "toggle",
          },
        ],
      },
    ],
  };

  const inputProps = {
    formik,
    ...inputs,
  };

  const searchButtonProps = {
    action: "black",
    label: loading ? "Searching" : "Search",
    disabled: loading,
    onClick: e =>
      fetchArtworksV2({
        variables: {
          input,
        },
      }).then(resp => {
        navigate("/artworks?status=all");
        closeModal();
      }),
  };

  const resetButtonProps = {
    action: "default",
    label: "Reset",
    onClick: formik?.resetForm,
  };

  const tabComponentProps = {
    artworksV2,
    formik,
  };

  const tabsProps = {
    formik,
    name: "tab",
    options: [
      {
        label: "Instance",
        value: "instance",
        component: <Instance {...tabComponentProps} />,
      },
      {
        label: "Exhibitions",
        value: "exhibitions",
        component: <Exhibition {...tabComponentProps} />,
      },
      {
        label: "Query",
        value: "query",
        component: <Query {...tabComponentProps} />,
      },
    ],
  };

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between">
        <div className="text-xl font-bold">Artwork Search</div>
        <div className="flex gap-4">
          <Button {...resetButtonProps} />
          <Button {...searchButtonProps} />
        </div>
      </div>
      <div className="flex flex-col gap-8">
        <GridInputs {...inputProps} />
        <Tabs {...tabsProps} />
      </div>
    </div>
  );
};

export default ArtworkSearch;
