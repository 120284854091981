import company from "./Company";
import contactAddress from "./ContactAddress";
import contactArtist from "./ContactArtist";
import contactFocus from "./ContactFocus";
import contactMailingList from "./ContactMailingList";
import contactNumber from "./ContactNumber";
import ContactType from "./ContactType";
import Country from "./Country";
import Entity from "./Entity";
import item from "./Item";
import offer from "./Offer";
import request from "./Request";

const Contact = ({
  contactAddresses = false,
  contactArtists = false,
  contactCompany = false,
  contactFocuses = false,
  contactMailingLists = false,
  contactNumbers = false,
  country = false,
  documents = false,
  exhUser = false,
  firstUser = false,
  purchases = false,
  lastOffer = false,
  requests = false,
  salesUser = false,
  salesUser2 = false,
  type = false,
  type2 = false,
} = {}) => `
  id
  address
  address2
  address3
  affiliations
  biography
  collection
  collectionActive
  company
  confidential
  ${
    contactAddresses
      ? `contactAddresses {
      ${contactAddress(contactAddresses)}
    }`
      : ""
  }
  ${
    contactArtists
      ? `contactArtists {
      ${contactArtist(contactArtists)}
    }`
      : ""
  }
  ${
    contactCompany
      ? `contactCompany {
      ${company(contactCompany)}
    }`
      : ""
  }
  ${
    contactFocuses
      ? `contactFocuses {
      ${contactFocus(contactFocuses)}
    }`
      : ""
  }
  ${
    contactMailingLists
      ? `contactMailingLists {
      ${contactMailingList(contactMailingLists)}
    }`
      : ""
  }
  ${
    contactNumbers
      ? `contactNumbers {
      ${contactNumber(contactNumbers)}
    }`
      : ""
  }
  contactSecondary
  countryId
  ${
    country
      ? `country {
      ${Country}
    }`
      : ""
  }
  county
  createdAt
  details
  dob
  ${documents ? "documents" : ""}
  email
  eori
  ${
    exhUser
      ? `exhUser {
      ${Entity}
    }`
      : ""
  }
  exhUserId
  firstcontactdate
  firstcontactlocation
  firstcontacttype
  ${
    firstUser
      ? `firstUser {
      ${Entity}
    }`
      : ""
  }
  firstUserId
  forename
  greeting 
  img
  invoicesLength
  ${
    lastOffer
      ? `lastOffer {
      ${offer(lastOffer)}
    }`
      : ""
  }
  level
  mailingname
  middlename
  name
  nationality
  notes
  postcode
  prefered
  ${
    purchases
      ? `purchases {
      ${item(purchases)}
    }`
      : ""
  }
  rating
  ${
    requests
      ? `requests {
      ${request(requests)}
    }`
      : ""
  }
  sagecode
  ${
    salesUser
      ? `salesUser {
      ${Entity}
    }`
      : ""
  }
  salesUserId
  ${
    salesUser2
      ? `salesUser2 {
      ${Entity}
    }`
      : ""
  }
  salesUser2Id
  salutation
  siteId
  storageCharge
  surname
  title
  town
  ${
    type
      ? `type {
      ${ContactType}
    }`
      : ""
  }
  typeId
  ${
    type2
      ? `type2 {
      ${ContactType}
    }`
      : ""
  }
  type2Id
  unsubscribed
  updatedAt
  vat
`;

// const ContactTable = `

//   companyName: company
//   country {
//     ${Country}
//   }
//   county
//   email
//   img
//   name
//   salesUser {
//     ${Entity}
//   }
//   title
//   type {
//     ${ContactType}
//   }
//   unsubscribed
// `

export default Contact;
