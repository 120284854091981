import { useMutation, useQuery } from "@apollo/client";
import { Button, getInput } from "../../../../components/ui";
import { REMOVE_INVOICE_ITEM as removeInvoiceItemMutation } from "../../../../graphql/mutation/Invoice";
import { FETCH_ITEMS_V2 as fetchItemsV2 } from "../../../../graphql/query/Item";
import { useFormik } from "formik";

const RemoveItem = props => {
  const { invoice, closeModal } = props || {};

  const { data } = useQuery(
    fetchItemsV2({
      instance: { edition: { artwork: { imagesSummary: { imgT: {} } } } },
      type: {},
    }),
    {
      variables: {
        input: {
          invoiceId: {
            operator: "eq",
            value: invoice?.id,
          },
        },
      },
    },
  );

  const [removeInvoiceItem, { loading, error, reset }] = useMutation(
    removeInvoiceItemMutation({
      instance: { edition: { artwork: { imagesSummary: { imgT: {} } } } },
      type: {},
    }),
    {
      refetchQueries: [
        fetchItemsV2({
          instance: { edition: { artwork: { imagesSummary: { imgT: {} } } } },
          type: {},
        }),
      ],
    },
  );

  const formik = useFormik({
    initialValues: {},
    onSubmit: values => {
      removeInvoiceItem({
        variables: {
          input: {
            id: invoice?.id,
            ...values,
          },
        },
      }).then(res => {
        if (res?.data?.removeInvoiceItem?.success) {
          closeModal();
          reset();
        }
      });
    },
  });

  const inputProps = {
    formik,
    label: "Select item to remove",
    name: "itemId",
    type: "multi-select",
    options:
      data?.itemsV2?.edges?.map(item => ({
        label: item?.description,
        value: item?.id,
      })) || [],
  };

  if (error) {
    return (
      <div className="flex w-full flex-col gap-4">
        <div className="flex flex-row">
          <div className="flex flex-1 flex-col text-2xl font-bold">
            <div>Remove Item?</div>
          </div>
          <div className="flex gap-4">
            <Button
              action="default"
              label="Cancel"
              onClick={() => {
                closeModal();
                reset();
                formik?.resetForm();
              }}
            />
          </div>
        </div>
        <div className="flex flex-col p-8">{error.message}</div>
      </div>
    );
  }

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          <div>Remove Item?</div>
        </div>
        <div className="flex gap-4">
          <Button action="default" label="Cancel" onClick={closeModal} />
          <Button
            label={loading ? "Removing" : "Remove"}
            loading={loading}
            disabled={loading}
            onClick={formik.handleSubmit}
          />
        </div>
      </div>
      <div className="flex flex-col p-8">
        <div className="grid grid-cols-2">
          <div className="grid-cols-1">{getInput(inputProps)}</div>
        </div>
      </div>
    </div>
  );
};

export default RemoveItem;
