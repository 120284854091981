const Catalogue = `
  id
  artistId
  barcode
  carton
  ISBN
  nominal
  price
  publisher
  purchaseinfo,
  reference
  sagecode
  tax
  title
  updatedAt
  year
`;

export default Catalogue;
