import { gql } from "@apollo/client";
import { JobStatus } from "../fragments";

const FETCH_JOB_STATUSES = gql`
  query FetchJobStatuses($input: JobStatusInput) {
    jobStatuses(input: $input) {
      ${JobStatus}
    }
  }
`;

export { FETCH_JOB_STATUSES };
