import { useQuery } from "@apollo/client";

import { GridInputs } from "../../../../../../../components/Functional";
import { FETCH_TAX_AREAS } from "../../../../../../../graphql/query/TaxArea";
import { FETCH_CURRENCIES } from "../../../../../../../graphql/query/Currency";
import { FETCH_INVOICE_TYPES } from "../../../../../../../graphql/query/InvoiceType";
import { FETCH_BANK_ACCOUNTS as fetchBankAccounts } from "../../../../../../../graphql/query/BankAccount";
import { FETCH_COMPANIES as fetchCompanies } from "../../../../../../../graphql/query/Company";
import { formattedLocaleISODate } from "../../../../../../../util";
import useUser from "../../../../../../../hooks/useUser";

const Information = props => {
  const { hasPermission } = useUser();
  const { formik, disableConfig, submitOnBlur = false } = props;

  const { data: { currencies = [] } = {} } = useQuery(FETCH_CURRENCIES);
  const { data: { taxAreas = [] } = {} } = useQuery(FETCH_TAX_AREAS);
  const { data: { invoiceTypes = [] } = {} } = useQuery(FETCH_INVOICE_TYPES);
  const { data: { bankAccounts = [] } = {} } = useQuery(
    fetchBankAccounts({ currency: {} }),
    { variables: { input: { companyId: formik?.values?.companyId } } },
  );
  const { data: { companies: { edges: companyList = [] } = {} } = {} } =
    useQuery(fetchCompanies());

  const salesPersonInputs = formik?.values?.invoiceSalesPersons?.length
    ? formik?.values?.invoiceSalesPersons?.map((_, index) => {
        return {
          label: `Split ${index + 1}`,
          name: `invoiceSalesPersons[${index}].salesUser.name`,
          type: "text",
          postLabelInput: {
            label: formattedLocaleISODate(
              formik?.values?.invoiceSalesPersons[index].timestamp,
            ),
            type: "label",
            className: "!text-xs !font-normal !pb-0",
          },
          ...disableConfig,
        };
      })
    : [];

  const splitRatioInputs = formik?.values?.invoiceSalesPersons?.length
    ? formik?.values?.invoiceSalesPersons?.map((_, index) => ({
        label: "Split Ratio",
        name: `invoiceSalesPersons[${index}].split`,
        type: "text",
        ...disableConfig,
      }))
    : [];

  const inputs = {
    className: "grid grid-cols-4 gap-16 mt-8",
    inputs: [
      {
        className: "grid-cols-1 gap-4",
        inputs: [
          {
            label: "Client Name",
            name: "contactId",
            type: "contactSearch",
            redirect: true,
            submitOnBlur,
            ...(!hasPermission("UPDATE_INVOICE") ? disableConfig : {}),
          },
          {
            label: "Company",
            name: "contact.company",
            type: "text",
            ...disableConfig,
            submitOnBlur,
          },
          {
            label: "Client VAT",
            name: "vatno",
            type: "text",
            submitOnBlur,
            ...(!hasPermission("UPDATE_INVOICE") ? disableConfig : {}),
          },
          ...(formik?.values?.invoiceSalesPersons?.length
            ? [
                {
                  label: "Sales Person",
                  type: "label",
                  className: "mt-4 !text-base",
                },
                {
                  className: "grid grid-cols-4 gap-4",
                  inputs: [
                    {
                      className: "grid-cols-1 col-span-3 gap-4",
                      inputs: salesPersonInputs,
                    },
                    {
                      className: "grid-cols-1 gap-4",
                      inputs: splitRatioInputs,
                    },
                  ],
                },
              ]
            : []),
        ],
      },
      {
        className: "grid-cols-1 gap-4",
        inputs: [
          {
            label: "Invoiced From",
            name: "companyId",
            type: "multi-select",
            disabled: true,
            options: companyList?.map(item => ({
              label: item.name,
              value: item.id,
            })),
            submitOnBlur,
          },
          {
            label: "Date",
            name: "date",
            type: "date",
            ...disableConfig,
            submitOnBlur,
          },
          {
            label: "Payment Date",
            name: "dueDate",
            type: "date",
            submitOnBlur,
            ...(!hasPermission("UPDATE_INVOICE") ? disableConfig : {}),
          },
          {
            label: "VAT Deadline",
            name: "vatDeadline",
            type: "date",
            ...disableConfig,
            submitOnBlur,
          },
          {
            name: "approved",
            options: [{ label: "Approved", value: -1 }],
            singleCheckbox: true,
            type: "checkbox",
            submitOnBlur,
            ...(!hasPermission("UPDATE_INVOICE") ? disableConfig : {}),
          },
          {
            label: "Analysis Code",
            name: "sagecode",
            type: "text",
            submitOnBlur,
            ...(!hasPermission("UPDATE_INVOICE") ? disableConfig : {}),
          },
        ],
      },
      {
        className: "grid-cols-1 gap-4",
        inputs: [
          {
            label: "Invoice #",
            name: "number",
            type: "text",
            ...disableConfig,
            submitOnBlur,
          },
          {
            label: "Proforma #",
            name: "proforma",
            type: "text",
            ...disableConfig,
            submitOnBlur,
          },
          {
            disabled: true,
            label: "Type",
            name: "type.id",
            type: "multi-select",
            options: invoiceTypes?.map(item => ({
              label: item.description,
              value: item.id,
            })),
            submitOnBlur,
            ...(!hasPermission("UPDATE_INVOICE") ? disableConfig : {}),
          },
          {
            label: "Bank",
            name: "bankId",
            type: "multi-select",
            options: bankAccounts?.map(item => ({
              label: `${item?.bank}, ${item?.number}, ${item?.currency?.code}`,
              value: item.id,
            })),
            submitOnBlur,
            ...(!hasPermission("UPDATE_INVOICE") ? disableConfig : {}),
          },
          {
            label: "Currency",
            name: "currencyId",
            type: "multi-select",
            options: currencies?.map(item => ({
              label: item.description,
              value: item.id,
            })),
            submitOnBlur,
            ...(!hasPermission("UPDATE_INVOICE") ? disableConfig : {}),
          },
        ],
      },
      {
        className: "grid-cols-1 gap-4",
        inputs: [
          {
            label: "Tax Area",
            name: "taxareaId",
            type: "select",
            options: taxAreas?.map(item => ({
              label: item.name,
              value: item.id,
            })),
            submitOnBlur,
            ...(!hasPermission("UPDATE_INVOICE") ||
            (formik?.values?.items && formik.values?.items?.length > 0)
              ? disableConfig
              : {}),
          },
          {
            label: "Tax Rate",
            name: "taxRate",
            type: "text-3decimal",
            ...disableConfig,
            submitOnBlur,
            ...(!hasPermission("UPDATE_INVOICE") ? disableConfig : {}),
          },
          {
            label: "Margin Scheme",
            name: "marginScheme",
            type: "toggle",
            designType: "threeStateCheckbox",
            submitOnBlur,
            ...(!hasPermission("UPDATE_INVOICE") ? disableConfig : {}),
          },
          {
            label: "Margin Scheme #",
            name: "marginRef",
            type: "text",
            ...disableConfig,
            submitOnBlur,
          },
          {
            label: "User",
            name: "user",
            type: "text",
            ...disableConfig,
            submitOnBlur,
          },
          {
            label: "ID",
            name: "id",
            type: "text",
            ...disableConfig,
            submitOnBlur,
          },
        ],
      },
    ],
  };

  const detailInputProps = {
    formik,
    ...inputs,
  };

  return (
    <>
      <h5 className="font-bold">Client Information</h5>
      <GridInputs {...detailInputProps} />
    </>
  );
};

export default Information;
