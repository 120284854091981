import { useMutation } from "@apollo/client";
import { TrashIcon } from "@heroicons/react/24/outline";
import { Button, IconButton, Modal } from "../../../../../../../components/ui";
import { DELETE_INSTANCE } from "../../../../../../../graphql/mutation/Instance";

const Actions = props => {
  const { id } = props || {};
  const [deleteInstance, { loading }] = useMutation(DELETE_INSTANCE);

  const deleteModalProps = {
    title: "ARE YOU SURE?",
    scale: "sm",
    description: `Would you like to delete this instance?`,
    closeOnBackdrop: true,
    body: ({ closeModal }) => {
      const handleDelete = () => {
        deleteInstance({
          variables: {
            input: {
              id: +id,
            },
          },
          update: (cache, { data }) => {
            const { deleteInstance: { success } = {} } = data;
            if (success) {
              closeModal();
              cache.evict({
                id: cache.identify({
                  __typename: "Instance",
                  id,
                }),
              });
            }
          },
        });
      };
      return (
        <div className="mt-4 flex justify-center">
          <Button
            className="mr-3"
            label="Cancel"
            onClick={() => {
              closeModal();
            }}
          />
          <Button
            label={loading ? "Deleting" : "Delete"}
            disabled={loading}
            onClick={handleDelete}
          />
        </div>
      );
    },
  };

  if (typeof id == "number") {
    return (
      <div className="user-list-actions flex text-right text-sm font-medium">
        {id && (
          <div className="grid grid-cols-4 gap-4">
            {
              <Modal {...deleteModalProps}>
                <IconButton variant="clean" title="Delete Support">
                  <TrashIcon
                    className={`h-4 w-4 cursor-pointer text-gray-400`}
                  />
                </IconButton>
              </Modal>
            }
          </div>
        )}
      </div>
    );
  } else {
    return false;
  }
};

export default Actions;
