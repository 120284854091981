import { getInput } from "../../../../../../../components/ui";

const ImageField = props => {
  const { image, loading, updateWarehouseItem, warehouseItemId } = props || {};

  const handleUpload = async (e, closeModal) => {
    const files = e.files;
    if (files.length) {
      updateWarehouseItem({
        variables: {
          input: {
            id: warehouseItemId,
            image: files[0],
          },
        },
        update: () => {
          closeModal();
        },
      });
    }
  };

  const inputProps = {
    ...props,
    onChange: handleUpload,
    className: "border-neutral-400",
    variant: "modal",
    value: image,
    loading,
  };

  return (
    <div className="flex items-center bg-neutral-200">
      {getInput(inputProps)}
    </div>
  );
};

const ManageImage = props => {
  const {
    disabled,
    formik,
    updateLoading,
    updateWarehouseItem,
    warehouseItem,
  } = props;

  const imageInput = {
    disabled,
    handleDelete: () => {
      formik.setFieldValue("image", null);
      formik?.submitForm();
    },
    name: "image",
    label: "",
    type: "img",
    isMultiSelect: false,
    uploadType: "file(s)",
    text: "Add Image",
    h: "h-[320px]",
    w: "w-full",
    textClassName:
      "bg-white border border-gray-400 px-6 py-2 rounded-md text-[12px]",
    uploadButtonClassName: "px-8 py-2 rounded-md text-[12px]",
    image: warehouseItem?.image,
    loading: updateLoading,
    warehouseItemId: warehouseItem?.id,
    updateWarehouseItem,
  };

  return <ImageField {...imageInput} />;
};

export default ManageImage;
