import { useQuery } from "@apollo/client";

import { Table } from "../../../../../../components/Functional";
import { UPDATE_ITEM as updateItemMutation } from "../../../../../../graphql/mutation/Item";
// import { FETCH_CONSIGNMENT_CHANNELS } from "../../../../../../graphql/query/ConsignmentChannel";
import { FETCH_ITEMS_V2 as fetchItemsV2 } from "../../../../../../graphql/query/Item";
import { FETCH_NOMINALS } from "../../../../../../graphql/query/Nominal";

import SectionHeader from "../../components/SectionHeader";

import AddSalesPerson from "./components/AddSalesPerson";
import { AddTransactionModal } from "./components/AddTransaction";
import Information from "./components/Information";
import Total from "./components/Total";
import Actions from "./components/Actions";
import AddToSelectList from "./components/AddToSelectList";
import useUser from "../../../../../../hooks/useUser";

const disableConfig = {
  disabled: true,
  inputClassName: "!bg-white !text-gray-400 !border-gray-400",
};

const isInputField = (props, obj) => {
  const { formik } = props || {};
  const { values } = formik || {};

  // if Credit note, then don't allow update
  if (values?.typeId === 6) {
    return { ...obj, type: "currency" };
  }

  // Disable the field if the transaction has been exported
  if (values?.exported === 1) {
    return { ...obj, ...disableConfig };
  }

  return obj;
};

const InvoiceDetails = (props, field) => {
  const { hasPermission } = useUser();
  const { invoice, formik } = props;

  // const { data: { consignmentChannels = [] } = {}} = useQuery(FETCH_CONSIGNMENT_CHANNELS)
  const { data: { nominals = [] } = {} } = useQuery(FETCH_NOMINALS);

  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "itemsV2",
    headers: [
      {
        label: "Date",
        name: "date",
        type: "label",
        labelClass: props => {
          let labelColor = "";
          // Editable
          if (props?.row?.typeId !== 6) {
            labelColor = "text-sky-750";
          }
          // Immutable
          if (props?.row?.invoice?.immutable === -1) {
            labelColor = "text-pink";
          }
          // Exported
          if (props?.row?.exported === 1) {
            labelColor = "text-grey-550";
          }
          return labelColor;
        },
      },
      { label: "Type", name: "type.name", type: "label" },
      {
        label: "Nominal",
        name: "nominalId",
        inputType: "multi-select",
        options: nominals?.map(item => ({ label: item.label, value: item.id })),
        appendMissingOption: true,
        type: "input",
        ...(!hasPermission("UPDATE_ITEM") ? disableConfig : {}),
      },
      {
        label: "",
        name: "instance?.edition?.artwork?.imagesSummary?.imgT",
        type: "image",
      },
      props => ({
        inputType: "href",
        label: "Reference",
        name: "ref",
        rowClass: "w-[150px]",
        type: "input",
        hrefLink:
          props?.row?.instance?.id &&
          `/instance/${props?.row?.instance?.id}/details`,
        disabledHref: props?.row?.instance?.id ? false : true,
        ...(!hasPermission("UPDATE_ITEM") ? disableConfig : {}),
      }),
      {
        inputType: "textarea",
        label: "Description",
        name: "description",
        type: "input",
        ...(!hasPermission("UPDATE_ITEM") ? disableConfig : {}),
      },
      props =>
        isInputField(props, {
          inputType: "number",
          label: "Discount",
          name: "discount",
          rowClass: "w-[150px]",
          type: "input",
          disabled: true,
        }),
      props =>
        isInputField(props, {
          inputType: "number",
          label: "%",
          name: "discountRate",
          rowClass: "w-[90px]",
          type: "input",
          disabled: true,
        }),
      {
        designType: "threeStateCheckbox",
        inputType: "toggle",
        label: "Hide",
        name: "showDiscount",
        rowClass: "w-[30px]",
        type: "input",
        ...(!hasPermission("UPDATE_ITEM") ? disableConfig : {}),
      },
      props =>
        isInputField(props, {
          inputType: "number",
          label: "Amount",
          name: "amount",
          rowClass: "w-[150px]",
          type: "input",
          disabled: true,
        }),
      props =>
        isInputField(props, {
          inputType: "number-currency",
          label: "Tax",
          name: "taxAmount",
          rowClass: "w-[150px]",
          type: "input",
          allowNegativeInput: false,
          disabled: true,
        }),
      {
        label: "Advisor Commission",
        name: "commission",
        inputType: "number-currency",
        type: "input",
        allowNegativeInput: false,
        rowClass: "w-[150px]",
        ...(!hasPermission("UPDATE_ITEM") ? disableConfig : {}),
      },
      {
        inputType: "number-currency",
        label: "%",
        name: "commissionRate",
        rowClass: "w-[90px]",
        type: "input",
        allowNegativeInput: false,
        ...(!hasPermission("UPDATE_ITEM") ? disableConfig : {}),
      },
      {
        inputType: "number-currency",
        label: "Consignor Split",
        name: "consigneeSplit",
        type: "input",
        allowNegativeInput: false,
        ...(!hasPermission("UPDATE_ITEM") ? disableConfig : {}),
      },
      {
        inputType: "number-currency",
        label: "%",
        name: "consigneeSplitRate",
        rowClass: "w-[90px]",
        type: "input",
        allowNegativeInput: false,
        ...(!hasPermission("UPDATE_ITEM") ? disableConfig : {}),
      },
      {
        label: "",
        name: "action",
        type: "custom",
        component: item =>
          !hasPermission("UPDATE_ITEM") ? null : (
            <Actions item={item} invoiceId={invoice?.id} />
          ),
      },
      // { inputType: 'multi-select', label: 'Source', name: 'sourceId', options: consignmentChannels?.map(item => ({ label: item.description, value: item.id })), type: 'input' },
      // { designType: 'threeStateCheckbox', inputType: 'toggle', label: 'Customs', name: 'custom', type: 'input' },
    ],
    inputs: [],
    FETCH_QUERY: fetchItemsV2({
      instance: { edition: { artwork: { imagesSummary: { imgT: {} } } } },
      invoice: {},
      type: {},
    }),
    UPDATE_MUTATION: updateItemMutation(),
    REFETCH_QUERIES: ["FetchInvoice"],
    variables: {
      invoiceId: {
        operator: "eq",
        value: invoice.id,
      },
      sort1: { value: "date", operator: "ASC" },
    },
  };

  const invoiceActions = [
    {
      component: () => {
        return (
          <>
            <AddToSelectList invoiceId={invoice?.id} />
            <AddTransactionModal invoice={invoice} />
            <AddSalesPerson invoice={invoice} />
          </>
        );
      },
    },
  ];

  return (
    <>
      <SectionHeader
        title={
          <div className="flex flex-col">
            <div className="flex">{`${invoice?.type?.description || ""} ${invoice?.type?.id === 1 ? invoice?.reference : ""} ${invoice?.type?.id === 1 ? invoice?.number : invoice?.reference || ""}:`}</div>
            <div className="flex">
              {`${
                invoice?.invoiceTotals?.total
                  ? parseFloat(invoice?.invoiceTotals?.total).toLocaleString(
                      "en-US",
                      { minimumFractionDigits: 2, maximumFractionDigits: 2 },
                    )
                  : "0.00"
              } ${invoice?.currency?.code || ""}`}
            </div>
          </div>
        }
        actions={invoiceActions}
      />
      <div className="w-full">
        <Information
          formik={formik}
          disableConfig={disableConfig}
          submitOnBlur={true}
        />
        <Total formik={formik} disableConfig={disableConfig} />
        <div className="relative w-max min-w-full overflow-x-auto">
          <h5 className="absolute top-5 font-bold sm:left-6 lg:left-8">
            Transactions
          </h5>
          <Table {...tableProps} />
        </div>
      </div>
    </>
  );
};

export default InvoiceDetails;
