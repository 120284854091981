import { useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import { Route, Routes as RouterRoutes, useParams } from "react-router-dom";

import AuthRoute from "../../../../components/AuthRoute";
import { NavBar, Spinner } from "../../../../components/ui";
import { FETCH_REPORT_TEMPLATE } from "../../../../graphql/query/ReportTemplate";
import { UPDATE_REPORT_TEMPLATE } from "../../../../graphql/mutation/ReportTemplate";
import { Portal } from "../../../../util";

import routes from "./routes";
import { useEffect } from "react";

const Body = props => {
  const { loading, ...remainingProps } = props;

  return loading ? (
    <div className="h-100% flex w-full items-center justify-center">
      <Spinner />
    </div>
  ) : (
    <RouterRoutes>
      {routes
        ?.filter?.(item => item?.element)
        ?.map((item, index) => {
          return (
            <Route
              key={index}
              path={item?.href}
              element={
                <AuthRoute permissions={item.permissions}>
                  <item.element {...remainingProps} />
                </AuthRoute>
              }
            />
          );
        })}
    </RouterRoutes>
  );
};

const Wizard = props => {
  const { id } = useParams();
  const { data: { reportTemplate } = {}, loading } = useQuery(
    FETCH_REPORT_TEMPLATE,
    {
      variables: { id: +id },
    },
  );
  const [updateReportTemplate] = useMutation(UPDATE_REPORT_TEMPLATE);

  const formik = useFormik({
    initialValues: { reportTemplate },
  });

  useEffect(() => {
    formik?.setFieldValue("reportTemplate", reportTemplate);
  }, [loading]);

  const bodyProps = {
    ...props,
    formik,
    reportTemplate,
    reportTemplatId: +id,
    updateReportTemplate,
  };

  return (
    <div className="relative flex min-h-full">
      <Portal id="sidebar">
        <NavBar
          navigation={routes?.filter?.(item => item?.icon)}
          open={props?.open}
        />
      </Portal>
      <div className="flex min-h-full flex-1 flex-col overflow-x-auto p-8">
        <Body loading={loading} {...bodyProps} />
      </div>
    </div>
  );
};

export default Wizard;
