import { useMutation } from "@apollo/client";

import { Button } from "../../ui";
import { CREATE_LIST as createListMutation } from "../../../graphql/mutation/List";

const CreateList = props => {
  const {
    formik,
    ids,
    isSearch,
    label,
    listId,
    onSuccess,
    primary = false,
    search,
    typeId,
  } = props || {};
  const { selected: { ids: formikIds, select } = {} } = formik?.values || {};
  const [createList, { loading }] = useMutation(createListMutation(), {
    refetchQueries: ["FetchLists", "FetchListItems"],
  });
  const formikListItemIds = Object.keys(formikIds || {}).map(item =>
    parseInt(item.replace("_", "")),
  );
  const listItemIds = ids || formikListItemIds || [];

  const handleClick = async e => {
    e.preventDefault();
    const res = await createList({
      variables: {
        input: {
          name: `select_list_working_${Date.now()}`,
          ...(select === "all" || isSearch ? { search } : { listItemIds }),
          ...(listId ? { listId } : { typeId }),
          primary,
        },
      },
    });
    if (res?.data?.createList?.success) {
      onSuccess?.();
    }
  };

  return (
    <Button
      loading={loading}
      label={loading ? "Adding..." : label ? label : "Add to Select List"}
      disabled={listItemIds?.length === 0 || loading}
      onClick={handleClick}
      className={`flex items-center`}
    />
  );
};

export default CreateList;
