import { Table } from "../../../../../../components/Functional";
import { FETCH_CONTACT_DOCUMENTS } from "../../../../../../graphql/query/ContactDocument";
import Actions from "./components/Actions";
import SectionHeader from "../../components/SectionHeader";
import { Modal, Button } from "../../../../../../components/ui";
import AddDocuments from "./components/AddDocuments";

const Documents = props => {
  const { contact, contactId } = props || {};

  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "contactDocuments",
    headers: [
      {
        label: "Thumbnail",
        type: "custom",
        component: props => (
          <img src={props?.values?.meta?.url} className="h-12 w-12 rounded" />
        ),
      },
      { label: "Date", name: "updatedAt", type: "date" },
      { label: "Filename", name: "meta.name", type: "label" },
      {
        label: "Size",
        name: "meta.size",
        type: "custom",
        component: props =>
          `${(props?.values?.meta?.size / 1024).toFixed(1)} KB`,
      },
      { label: "", name: "action", type: "custom", component: Actions },
    ],
    inputs: [{ label: "Name", name: "name", type: "text", view: "partial" }],
    FETCH_QUERY: FETCH_CONTACT_DOCUMENTS,
    variables: {
      contactId,
    },
    className: "sm:px-0 lg:px-0",
  };

  const modalProps = {
    body: AddDocuments,
    closeOnBackdrop: true,
    contact,
    hideCloseButton: true,
    scale: "md",
  };

  const actions = [
    {
      component: () => {
        return (
          <Modal {...modalProps}>
            <Button label="Add Document" action="black" />
          </Modal>
        );
      },
    },
  ];

  return (
    <div className="w-full">
      <SectionHeader title="Documents" actions={actions} />
      <div className="mb-6 w-full">
        <Table {...tableProps} />
      </div>
    </div>
  );
};

export default Documents;
