import { gql } from "@apollo/client";
import { ReservationInstance as reservationInstance } from "../fragments";

const FETCH_RESERVATION_INSTANCES_V2 = props => gql`
  query FetchReservationInstancesV2($input: AdvReservationInstancesFilterInput!) {
    reservationInstancesV2(input: $input) {
      count
      edges {
        ${reservationInstance(props)}
      }
      sqlQuery
    }
  }
`;

export { FETCH_RESERVATION_INSTANCES_V2 };
