import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import Actions from "./components/Actions";
import HeaderComponent from "./components/HeaderComponent";
// import searchedInvoices from "./components/SearchedInvoices";
import * as SelectListWorking from "./components/SelectListWorking";
import * as SelectListSaved from "./components/SelectListSaved";

import {
  Table,
  multiSelect,
  SelectList,
} from "../../../../components/Functional";
import { FETCH_OFFERS as fetchOffers } from "../../../../graphql/query/Offer";
import { FETCH_LISTS as fetchLists } from "../../../../graphql/query/List";

const List = props => {
  const navigate = useNavigate();
  const { formik } = props || {};
  const { data: { lists: { edges = [] } = {} } = {} } = useQuery(fetchLists(), {
    variables: {
      // fetch invoice_working list
      input: { typeId: 12 },
    },
  });

  const workingListId = edges?.length > 0 ? edges[0]?.id : null;
  const queryParams = new URLSearchParams(window.location.search);
  const status = queryParams?.get("status") || "all";
  const listId = +queryParams.get("listId");

  const ids = Object.keys(formik?.values?.selected?.ids || {})?.map(item =>
    parseInt(item.replace("_", "")),
  );

  useEffect(() => {
    if (workingListId) {
      queryParams.set("workingListId", workingListId);
      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      // eslint-disable-next-line
      history.pushState(null, null, newUrl);
    }
  }, [workingListId]);

  const multiSelectProps = {
    formik,
    label: "Offers",
    name: "selected",
    keyName: "id",
  };

  const actionProps = {
    formik,
    ids,
    listId,
    workingListId,
    onSuccess: () => formik?.resetForm(),
  };

  const handleChange = value => {
    navigate(
      `/offer?status=${value}${listId ? `&listId=${listId}` : ""}${workingListId ? `&workingListId=${workingListId}` : ""}`,
    );
  };

  const GeneralTabActions = () => {
    const createListProps = {
      ...actionProps,
      onSuccess: () => (
        formik?.resetForm(),
        navigate(
          `/offer?status=selectListWorking${listId ? `&listId=${listId}` : ""}`,
        )
      ),
      typeId: 12,
    };

    return <SelectList.CreateList {...createListProps} />;
  };

  const tabProps = {
    active: status,
    onChange: handleChange,
    tabs: [
      { actions: GeneralTabActions, label: "All Offers", name: "all" },
      // { actions: GeneralTabActions, label: 'Searched Invoices', name: 'searched', tableProps: searchedInvoices() },
      {
        actions: SelectListWorking.Actions,
        label: "Select List (Working)",
        name: "selectListWorking",
        tableProps: SelectListWorking.tableProps({ listId, workingListId }),
      },
      {
        actions: SelectListSaved.Actions,
        label: "Select List (Saved)",
        name: "selectListSaved",
        tableProps: SelectListSaved.tableProps({ formik }),
      },
    ],
  };
  const { tableProps: tabTableProps, actions: tabActions } =
    tabProps?.tabs?.find(tab => tab.name === status) || {};

  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "offers",
    headers: [
      multiSelect(multiSelectProps),
      {
        label: "Image",
        name:
          status === "selectListWorking"
            ? "item.artwork.imagesSummary.imgT"
            : "artwork.imagesSummary.imgT",
        type: "image",
      },
      {
        label: "Updated At",
        name: status === "selectListWorking" ? "item.updatedAt" : "updatedAt",
        type: "date",
      },
      {
        label: "Date",
        name: status === "selectListWorking" ? "item.date" : "date",
        sortKey: "date",
        type: "date",
      },
      {
        label: "Artist",
        name: status === "selectListWorking" ? "item.artwork" : "artwork",
        type: "custom",
        component: props => (
          <div
            className={`overflow-hidden text-ellipsis text-base font-medium leading-5 text-black`}
            style={{ maxWidth: 250 }}
          >
            {(props?.label?.artists?.map(artist => artist?.name) || [])?.join(
              ", ",
            )}
          </div>
        ),
      },
      {
        label: "Title",
        name:
          status === "selectListWorking"
            ? "item.artwork.title"
            : "artwork.title",
        type: "label",
      },
      {
        label: "Status",
        name:
          status === "selectListWorking"
            ? "item.status.description"
            : "status.description",
        sortKey: "statusId",
        type: "label",
      },
      {
        label: "Currency",
        name:
          status === "selectListWorking"
            ? "item.currency.code"
            : "currency.code",
        sortKey: "currencyId",
        type: "label",
      },
      {
        label: "Amount",
        name: status === "selectListWorking" ? "item.tbiAmount" : "tbiAmount",
        sortKey: "tbiAmount",
        type: "label",
      },
      {
        label: "Closed",
        name: status === "selectListWorking" ? "item.dateClosed" : "dateClosed",
        sortKey: "dateClosed",
        type: "date",
      },
      {
        label: "Notes",
        name: status === "selectListWorking" ? "item.notes" : "notes",
        sortKey: "notes",
        type: "label",
        width: "400px",
      },
      {
        label: "User",
        name: status === "selectListWorking" ? "item.user" : "user",
        sortKey: "user",
        type: "label",
      },
      { label: "", type: "custom", component: Actions },
    ],
    inputs: [],
    FETCH_QUERY: fetchOffers({
      artwork: { artists: {}, imagesSummary: { imgT: {} } },
      currency: {},
      status: {},
    }),
    tabProps,
    ...tabTableProps,
  };

  return (
    <>
      <div className={"px-8"}>
        <HeaderComponent
          leftText="All Offers"
          rightComponent={() => {
            return (
              <div className="flex gap-4">{tabActions?.(actionProps)}</div>
            );
          }}
        />
      </div>
      <div className="mt-6 w-full">
        <Table {...tableProps} />
      </div>
    </>
  );
};

export default List;
