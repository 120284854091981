import { XMarkIcon } from "@heroicons/react/24/outline";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { IconButton } from "../../../ui";

// eslint-disable-next-line
const DraggableItem = ({ item, onClick, provided, snapshot }) => {
  const { field, module, section } = item || {};
  const { client, offset } = snapshot || {};

  // eslint-disable-next-line
  const style = snapshot.isDragging
    ? {
        // eslint-disable-next-line
        ...provided.draggableProps.style,
        position: "relative",
        top: client?.y - offset?.y,
        left: client?.x - offset?.x,
      }
    : // eslint-disable-next-line
      provided.draggableProps.style;

  return (
    <div
      className="flex justify-between rounded-md border border-black px-4 py-2"
      // eslint-disable-next-line
      ref={provided.innerRef}
      // eslint-disable-next-line
      {...provided.draggableProps}
      // eslint-disable-next-line
      {...provided.dragHandleProps}
      // onClick={onClick}
      style={style}
    >
      <div className="flex-col">
        <div className="text-sm">{field?.label}</div>
        <div className="text-xs">{`${module} - ${section?.label}`}</div>
      </div>
      <IconButton variant="clean" title="Remove Field">
        <XMarkIcon
          className={`h-5 w-5 cursor-pointer rounded-full border border-gray-400 p-1  text-gray-400`}
          onClick={onClick}
        />
      </IconButton>
    </div>
  );
};

const RightListView = props => {
  const { formik } = props || {};
  const { selectedFields = [] } = formik?.values || {};

  const onClick = item => {
    const { field, section } = item || {};
    // remove from selected fields
    const newSelectedFields = selectedFields?.filter(
      item =>
        !(
          item?.field?.name === field?.name &&
          item?.section?.name === section?.name
        ),
    );
    formik?.setFieldValue?.("selectedFields", newSelectedFields);
  };

  const onDragStart = () => {
    // Set the position of the dragging item to fixed to prevent it from affecting its original layout
    document.body.style.cursor = "grabbing";
  };

  const onDragEnd = result => {
    document.body.style.cursor = "auto";
    // Check if the item was dropped outside the droppable area
    if (!result.destination) {
      return;
    }

    // Reorder the fields in the state based on the drag result
    const reorderedFields = Array.from(selectedFields);
    const [removedField] = reorderedFields.splice(result.source.index, 1);
    reorderedFields.splice(result.destination.index, 0, removedField);

    // Update the formik state with the reordered fields
    formik?.setFieldValue?.("selectedFields", reorderedFields);
  };

  return (
    <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
      <Droppable droppableId="adhoc-right-list-view">
        {provided => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="my-5 flex h-96 overflow-y-scroll rounded-md border border-black"
          >
            <div className="m-5 flex flex-grow flex-col gap-2">
              {selectedFields?.map((item, index) => {
                const { field } = item || {};

                return (
                  <Draggable
                    key={field?.name} // Use a unique key for each draggable item
                    draggableId={field?.name} // Use a unique identifier for each draggable item
                    index={index}
                  >
                    {(provided, snapshot) => {
                      const props = {
                        item,
                        onClick: () => onClick(item),
                        provided,
                        snapshot,
                      };
                      return <DraggableItem {...props} />;
                    }}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default RightListView;
