import { useQuery } from "@apollo/client";

import useUser from "../../../../../../../../../hooks/useUser";
import { statusColor } from "../../../../../../..";
import InstanceInputRender from "./InstanceInputRender";
import { FETCH_STATUS } from "../../../../../../../../../graphql/query/Status";
import { FETCH_EDITION_TYPES } from "../../../../../../../../../graphql/query/EditionType";

const InstanceDetailCol3 = ({
  disableConfig,
  formik,
  isDisabled,
  submitOnBlur = false,
}) => {
  const { hasPermission } = useUser();

  const { data: { status = [] } = {} } = useQuery(FETCH_STATUS);
  const selectedStatusName = status?.find(
    item => item.id === formik?.values?.statusId,
  )?.name;

  const { data: { editionTypes = [] } = {} } = useQuery(FETCH_EDITION_TYPES);

  const inputs = [
    {
      row: [
        {
          disabled: true,
          name: "editionNumber",
          label: "Edition",
          type: "number",
          submitOnBlur,
        },
        {
          disabled: true,
          name: "edition.number",
          label: "Of",
          type: "number",
          submitOnBlur,
        },
      ],
    },
    {
      label: "Edition Type",
      name: "edition.ap",
      type: "select",
      options: editionTypes?.map(item => ({
        label: `${item?.description} (${item?.code})`,
        value: item?.id,
      })),
      disabled: isDisabled,
      submitOnBlur,
    },
    {
      name: "statusId",
      label: "Status",
      type: "select",
      options: status?.map(obj => ({ label: obj.name, value: obj.id })),
      selectedTextColor: statusColor(selectedStatusName),
      disabled: true,
      submitOnBlur,
    },
    {
      name: "notes",
      label: "Notes",
      type: "textarea",
      rows: 5,
      disabled: !hasPermission("UPDATE_INSTANCE_NOTES"),
      submitOnBlur,
    },
    {
      name: "edition.artwork.description",
      label: "Description",
      type: "textarea",
      rows: 5,
      disabled: isDisabled,
      submitOnBlur,
    },
  ];

  return (
    <div className={`grid h-fit grid-cols-1 gap-4`}>
      <InstanceInputRender inputs={inputs} formik={formik} />
    </div>
  );
};

export default InstanceDetailCol3;
