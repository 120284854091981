import { gql } from "@apollo/client";
import { ShipmentCode } from "../fragments";

const FETCH_SHIPMENT_CODES = gql`
  query FetchShipmentCodes {
    shipmentCodes {
      ${ShipmentCode}
    }
  }
`;

export { FETCH_SHIPMENT_CODES };
