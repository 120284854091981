const ExhibitionWork = `
  artworkCat
  exhibited
  exhibitionId
  id
  instanceId
  notes
  removed
  technicalNotes
  updatedAt
  user
`;

export default ExhibitionWork;
