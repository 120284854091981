import { gql } from "@apollo/client";

const UPLOAD_SHIPMENT_DOCUMENTS = gql`
  mutation UploadShipmentDocuments($shipmentId: Int!, $files: [Upload]!) {
    uploadShipmentDocuments(shipmentId: $shipmentId, files: $files) {
      success
      error
      response
    }
  }
`;

const DELETE_SHIPMENT_DOCUMENT = gql`
  mutation DeleteShipmentDocument($input: DeleteShipmentDocumentInput!) {
    deleteShipmentDocument(input: $input) {
      success
      error
    }
  }
`;

export { UPLOAD_SHIPMENT_DOCUMENTS, DELETE_SHIPMENT_DOCUMENT };
