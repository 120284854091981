import { gql } from "@apollo/client";
import { SupportArticle } from "../fragments";

const CREATE_SUPPORT_ARTICLE = gql`
  mutation CreateSupportArticle($input: CreateSupportArticleInput!) {
    createSupportArticle(input: $input) {
      success
      error
      supportArticle {
        ${SupportArticle}
      }
    }
  }
`;

const DELETE_SUPPORT_ARTICLE = gql`
  mutation DeleteSupportArticle($input: DeleteSupportArticleInput!) {
    deleteSupportArticle(input: $input) {
      success
      error
    }
  }
`;

const UPDATE_SUPPORT_ARTICLE = gql`
  mutation UpdateSupportArticle($input: UpdateSupportArticleInput!) {
    updateSupportArticle(input: $input) {
      success
      error
      supportArticle {
        ${SupportArticle}
      }
    }
  }
`;

export {
  CREATE_SUPPORT_ARTICLE,
  DELETE_SUPPORT_ARTICLE,
  UPDATE_SUPPORT_ARTICLE,
};
