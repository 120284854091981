import { GridInputs } from "../../../../../components/Functional";

const Customs = props => {
  const { formik } = props || {};

  const inputs = {
    className: "grid grid-cols-4 gap-16 mt-5",
    inputs: [
      {
        className: "flex flex-col gap-4",
        inputs: [
          {
            label: "EORI",
            name: "eori",
            type: "text",
          },
        ],
      },
      {
        className: "flex flex-col gap-4",
        inputs: [
          {
            label: "CDS",
            name: "cds",
            type: "text",
          },
        ],
      },
      {
        className: "flex flex-col gap-4",
        inputs: [
          {
            label: "Declarant",
            name: "declarant",
            type: "text",
          },
        ],
      },
    ],
  };

  const gridInputProps = {
    formik,
    ...inputs,
  };

  return <GridInputs {...gridInputProps} />;
};

export default Customs;
