import { gql } from "@apollo/client";
import { ConsignmentWork as consignmentWork } from "../fragments";

const CREATE_CONSIGNMENT_WORK = props => gql`
  mutation CreateConsignmentWork($input: CreateConsignmentWorkInput!) {
    createConsignmentWork(input: $input) {
      success
      error
      consignmentWork {
        ${consignmentWork(props)}
      }
    }
  }
`;

const UPDATE_CONSIGNMENT_WORK = props => gql`
  mutation UpdateConsignmentWork($input: UpdateConsignmentWorkInput!) {
    updateConsignmentWork(input: $input) {
      success
      error
      consignmentWork {
        ${consignmentWork(props)}
      }
    }
  }
`;
const DELETE_CONSIGNMENT_WORK = gql`
  mutation DeleteConsignmentWork($input: DeleteConsignmentWorkInput!) {
    deleteConsignmentWork(input: $input) {
      success
      error
    }
  }
`;

export {
  CREATE_CONSIGNMENT_WORK,
  DELETE_CONSIGNMENT_WORK,
  UPDATE_CONSIGNMENT_WORK,
};
